import {Service} from "@onlog-public/additional-services-types";
import {ServiceOrder} from "@pages/AdditionalServices/containers/services/servicesOrder/types";
import {
  ServicesServicePriceCalculationMutationProps
} from "@services/requests/servicesService/servicesServicesService/interface";
import {VariantFilter} from "../../types";

/**
 * BaseServiceOrder реализует сервис сортировки услуг.
 */
export class BaseServiceOrder implements ServiceOrder {
  /**
   * Метод генерирует функцию сортировки услуг по переданным параметрам.
   * Функцию можно использовать для стандартной функции sort.
   *
   * @param filter
   * @param servicesPrices
   */
  Order(
    filter: VariantFilter,
    servicesPrices: ServicesServicePriceCalculationMutationProps[],
  ): (a: Service, b: Service) => number {
    return (a, b) => {
      if (filter.IsSortByProperty) {
        return this.sortByProperty(filter, a, b)
      }

      return this.sortByPrice(filter.SortDirection, servicesPrices, a, b)
    }
  }

  /**
   * sortByPrice реализует сортировку по стоимости
   * @param direction
   * @param servicesPrices
   * @param a
   * @param b
   * @protected
   */
  protected sortByPrice(
    direction: "asc" | "desc",
    servicesPrices: ServicesServicePriceCalculationMutationProps[],
    a: Service,
    b: Service,
  ): number {
    const aPrice = this.getServicePrice(a, servicesPrices)
    const bPrice = this.getServicePrice(b, servicesPrices)

    if (aPrice > 0 && bPrice > 0) {
      if (direction === "asc") {
        return aPrice > bPrice ? 1 : -1
      }

      return aPrice < bPrice ? 1 : -1
    }

    if (aPrice > 0) {
      return -1
    } else if (bPrice > 0) {
      return 1
    }

    // Цены включено двигаем в середину, цены по запросу в самый низ
    if (aPrice === bPrice && aPrice === 0) {
      return a.id > b.id ? 1 : -1
    }

    return aPrice < bPrice ? 1 : -1
  }

  /**
   * getServicePrice возвращает общую стоимость услуги по расчетам.
   * Возвращает -1 если услуга считается
   * @param service
   * @param servicesPrices
   * @protected
   */
  protected getServicePrice(
    service: Service,
    servicesPrices: ServicesServicePriceCalculationMutationProps[],
  ): number {
    const prices = servicesPrices
      .filter(p => p.serviceId === service.id)
      .map(p => p.result.result.fullPriceInTargetCurrency)

    const price = prices
      .filter(p => p > 0)
      .reduce((v, p) => v + p, 0)

    if (price > 0) {
      return price
    }

    const isIncluded = prices.includes(0)
    if (isIncluded) {
      return 0
    }

    return -1
  }

  /**
   * sortByProperty реализует сортировку по значению свойства
   * @param filter
   * @param a
   * @param b
   * @protected
   */
  protected sortByProperty(
    filter: VariantFilter,
    a: Service,
    b: Service,
  ): number {
    const aVal = this.getPropertyValue(a, filter.PropertyToSort)
    const bVal = this.getPropertyValue(b, filter.PropertyToSort)

    if (filter.SortDirection === "asc") {
      return aVal > bVal ? 1 : -1
    }

    return aVal < bVal ? 1 : -1
  }

  /**
   * getPropertyValue возвращает текущее значение свойства для сортировки
   * у переданной услуги.
   * @param service
   * @param property
   */
  protected getPropertyValue(service: Service, property: string): number {
    const value = service.property_values.find(v => {
      return String(v.property_id) === property
    })

    return value?.value ?? 99999999999
  }
}