import { RouteWidgetBaseParameters } from '@pages/AdditionalServices/tabs/DeliveryForm/types';
import { allowanceLoader } from '@services/requests/searchLoaders/allowanceLoader';
import { carriersLoader } from '@services/requests/searchLoaders/carriersLoader';
import { taxLoader } from '@services/requests/taxLoader';
import { containersLoader } from '@services/requests/searchLoaders/containersLoader';
import { containerTypeLoader } from '@services/requests/searchLoaders/containerTypesLoader';
import { contractorLoader } from '@services/requests/searchLoaders/contractorLoader';
import { currencyLoader } from '@services/requests/searchLoaders/currencyLoader';
import { endTransportingConditionsLoader } from '@services/requests/searchLoaders/endTransportingConditionsLoader';
import { loadUnloadConditionsLoader } from '@services/requests/searchLoaders/LoadUnloadConditionsLoader';
import { shoulderTypesLoader } from '@services/requests/searchLoaders/shouldeTypesLoader';
import { startTransportingConditionsLoader } from '@services/requests/searchLoaders/startTransportingConditionsLoader';
import { unitGroupsLoader } from '@services/requests/searchLoaders/unitGroupsLoader';
import { unitLoader } from '@services/requests/searchLoaders/unitLoader';
import { $error } from '@settings/errorContext';
import { LoadCollection } from '@settings/services/helpers/LoadCollection';
import { loggerFactory } from '@settings/services/logger';

import { filesService } from '../../requests/filesService';
import { BaseDataServiceInterface } from './interface';

/**
 * сервис по загрузке базовых параметров для расчета маршрута
 */
export default class BaseDataService implements BaseDataServiceInterface {
  private readonly logger = loggerFactory().make(`BaseDataService`);

  /**
   * Загрузка основных параметром
   */
  async LoadBaseData(): Promise<RouteWidgetBaseParameters> {
    try {
      this.logger.Debug('Get useBaseDataContext');
      const partOfBaseData = await LoadCollection<Omit<RouteWidgetBaseParameters, 'files'>>({
        currencies: currencyLoader().Load(),
        containers: containersLoader().Load(),
        containerTypes: containerTypeLoader().Load(),
        endTransportingConditions: endTransportingConditionsLoader().Load(),
        startTransportingConditions: startTransportingConditionsLoader().Load(),
        carriers: carriersLoader().Load(),
        contractors: contractorLoader().Load(),
        shoulderTypes: shoulderTypesLoader().Load(),
        allowancesData: allowanceLoader().Load(),
        units: unitLoader().Load(),
        unitGroups: unitGroupsLoader().Load(),
        loadingTransportConditions: loadUnloadConditionsLoader().getLoadingConditions(),
        unloadingTransportConditions: loadUnloadConditionsLoader().getUnloadingConditions(),
		taxes: taxLoader().Load(),
      });

      // Получаем список id файлов для перевозчиков и подрядчиков
      const filesIdsList = [...partOfBaseData.contractors, ...partOfBaseData.carriers]
        .map((item) => item.files)
        .flat();

      return {
        ...partOfBaseData,
        files: await filesService().LoadFilesById(filesIdsList),
      };
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }
}
