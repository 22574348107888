import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const ErrorBoundaryStyledContainer = styled(Paper)`
  padding: 64px 32px;
  text-align: center;
  max-width: clamp(33vw, 768px, 95vw);
  margin: 0 auto 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default ErrorBoundaryStyledContainer;
