import {ServiceFieldType, ServiceField} from "@onlog-public/additional-services-types";
import {
  optionsGeneratorProcessor,
  SelectOption
} from "@pages/AdditionalServices/containers/services/optionsGenerator/types";
import {OptionsCache} from "../optionsLoader/types";
import {BranchItem} from "@services/requests/routeCalculator/locationsBranchSearchService/interfaces";
import getLocalizationByArgs from "@helpers/getLocalizationByArgs";

/**
 * LocationProcessor реализует процессор генерации опций для локаций
 */
export class LocationProcessor implements optionsGeneratorProcessor {
  /**
   * getType возвращает тип поля, для которого реализован процессор
   */
  getType(): ServiceFieldType {
    return "location"
  }

  /**
   * getOptions генерирует опции для селектора поля дополнительных услуг
   * @param field
   * @param langID
   * @param cache
   */
  getOptions(field: ServiceField, langID: string, cache: OptionsCache<any>): SelectOption[] {
    const optionID = [...field.handbook_limitations]
    if (optionID.length === 0) {
      optionID.push(String(field.value))
    }

    const locationsData: BranchItem[] = optionID
      .map(id => cache.cache[id])
      .filter(v => !!v)

    return locationsData.map(d => ({
      value: parseInt(d.id),
      label: getLocalizationByArgs(langID, d.defaultName ?? "", d.localizedNamesArray),
      description: d.subItems
        .sort((a, b) => a.depthLevel < b.depthLevel ? 1 : -1)
        .map(v => getLocalizationByArgs(langID, v.visibleName, v.localizedNamesArray))
        .join(", ")
      ,
      origin: d,
    }))
  }
}