import { LocalizedMessage } from '@services/requests/localizedMessagesService/interfaces';

/**
 * Получение локализованного сообщения для переданного языка
 * @param defaultName
 * @param localization
 * @param langId
 */
export const getLocalization = (
  defaultName: string,
  localization: LocalizedMessage[],
  langId: string
): string => {
  const message = localization.find((l) => l.lang_id === langId);
  if (!message) {
    return defaultName;
  }

  if (message.message.length === 0) {
    return defaultName;
  }

  return message.message;
};
