import { useBitrix } from '@context/useBitrixUser/useBitrix';
import { styled } from '@mui/material';
// @flow
import * as React from 'react';
import { PropsWithChildren } from 'react';

const Guard = styled('div')`
  position: relative;

  &::after {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    content: '';
    cursor: pointer;
  }

  > * {
    point-events: none;
  }
`;
/*
Страж по запрету взаимодействию с интерфесом для не авторизованных пользователей
 */
export const BitrixDisableUserGuard: React.FC<PropsWithChildren> = ({ children }) => {
  const { actions, useBitrixUser } = useBitrix();
  const [{ bitrixUser }] = useBitrixUser().useState();
  const { showLogin } = actions;

  const guardHandler = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();
    showLogin();
  };

  if (bitrixUser) {
    return <>{children}</>;
  }

  return (
    <Guard onClick={guardHandler} onClickCapture={guardHandler}>
      {children}
    </Guard>
  );
};

/*
Страж по Сокрытию UI для не авторизованных пользователей
 */
export const BitrixHiddeUserGuard: React.FC<PropsWithChildren> = ({ children }) => {
  const [{ bitrixUser }] = useBitrix().useBitrixUser().useState();

  if (!bitrixUser) {
    return null;
  }

  return <>{children}</>;
};
