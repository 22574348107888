import { LocalizedMessagesServiceInterfaces } from './interfaces';
import { LocalizedMessagesService } from './LocalizedMessagesService';

/**
 * Фабрика сервиса
 * @param token
 */
export const localizedMessagesService: {
  (token?: string): LocalizedMessagesServiceInterfaces;
} = (token) => new LocalizedMessagesService(token);
