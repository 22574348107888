import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const ArrowIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} fill={'none'}>
    <path stroke="currentColor" fill={'none'} d="M19 17.357 11.5 12 4 17.357" />
  </SvgIcon>
);

export default ArrowIcon;
