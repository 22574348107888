import React, {FC} from "react";
import {ServiceDirectoryProps} from "@pages/AdditionalServices/components/ServiceDirectory/types";
import ListTemplateFilter
  from "@pages/AdditionalServices/components/ServiceDirectory/ListTypeTemplate/ListTemplateFilter";
import SortContainer from "@pages/AdditionalServices/components/ServiceDirectory/ListTypeTemplate/SortContainer";
import ServiceComp from "@pages/AdditionalServices/components/Service";
import LinearProgress from "@material-ui/core/LinearProgress";

/**
 * ListTypeTemplate реализует вывод услуг в виде листинга с фильтром
 */
const ListTypeTemplate: FC<ServiceDirectoryProps> = props => {
  const {
    langID,
    currencyID,
    variant,
    services,
    isServicesCalculating,
    globalProperties,
    serviceProperties,
    propertyValues,
    propertyOptions,
    serviceDataCache,
    serviceCalculations,
    propertyFilterAndOrder,
    onChangeFilterAndOrder,
    onChangeServiceFieldValue,
    ...baseServiceProps
  } = props

  const servicesToDisplay = services
    .filter(s => s.is_active && !s.is_broken)
    .filter(s => propertyFilterAndOrder.ServiceVisibility[s.id])
    .sort((a, b) => {
      const aPriority = propertyFilterAndOrder.ServicePriority[a.id]
      const bPriority = propertyFilterAndOrder.ServicePriority[b.id]

      return aPriority > bPriority ? 1 : -1
    })

  if (!propertyFilterAndOrder) {
    return null
  }

  return (
    <div className="a-service-template-list">
      <ListTemplateFilter
        langID={langID}
        currencyID={currencyID}
        serviceDataCache={serviceDataCache}
        filter={propertyFilterAndOrder}
        onChangeFilter={onChangeFilterAndOrder}
      />
      <div>
        <SortContainer
          filter={propertyFilterAndOrder}
          templateSettings={variant.settings.TemplateSettings.ListTemplate}
          onChangeFilter={onChangeFilterAndOrder}
        />
        <div className="a-service-template-list-progress">
          {isServicesCalculating && (
            <LinearProgress/>
          )}
        </div>
        <div className="a-service-template-list-content">
          {servicesToDisplay.map(service => (
            <ServiceComp
              key={service.id}
              {...baseServiceProps}
              langID={langID}
              currencyID={currencyID}
              variant={variant}
              service={service}
              isServicesCalculating={isServicesCalculating}
              properties={[...globalProperties, ...serviceProperties]}
              propertyValues={propertyValues}
              propertyOptions={propertyOptions}
              serviceDataCache={serviceDataCache}
              serviceCalculations={serviceCalculations.filter(c => c.serviceId === service.id)}
              onChangeServiceFieldValue={(f, v) => onChangeServiceFieldValue(variant.id, f, v)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ListTypeTemplate