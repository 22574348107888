import React, {FC} from "react";
import {Service, VerticalTableTemplate} from "@onlog-public/additional-services-types";
import {useTranslation} from "react-i18next";
import getLocalizationByArgs from "@helpers/getLocalizationByArgs";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

export interface TableHeaderProps {
  langID: string
  settings: VerticalTableTemplate
  columns: Service[]
}

const TableHeader: FC<TableHeaderProps> = props => {
  const {settings, columns, langID} = props
  const {t} = useTranslation()

  const nameColumn = t(settings.PositionColumnTitle)
  const costColumn = t(settings.CostColumnName)
  const courseColumn = t(settings.CourseColumnName)
  const columnTitle = columns.map(s => ({
    id: s.id,
    name: getLocalizationByArgs(langID, s.name, s.localized_names),
    description: getLocalizationByArgs(langID, s.description, s.localized_descriptions)
  }))
  const sumColumn = t('AdditionalService:Templates.verticalTable.SumColumn')
  const taxColumn = t('AdditionalService:Templates.verticalTable.TaxColumn')
  const totalColumn = t('AdditionalService:Templates.verticalTable.TotalColumn')

  return <>
    <div className="header no-left-border">{nameColumn}</div>
    <div className="header">{costColumn}</div>
    {!settings.IsCourseColumnHidden && (
      <div className="header">{courseColumn}</div>
    )}
    {columnTitle.map(col => {
      if (col.description.length > 0) {
        return (
          <div key={col.id} className="header">
            <div className="header-with-description">
              <Tooltip
                placement="top"
                title={(<div dangerouslySetInnerHTML={{__html: col.description}} className="a-html-content"/>)}
              >
                <IconButton className="table-header-description-icon">
                  <QuestionMarkIcon sx={{fontSize: 8, color: "#707070"}}/>
                </IconButton>
              </Tooltip>
              <Tooltip
                placement="top"
                title={(<div dangerouslySetInnerHTML={{__html: col.description}} className="a-html-content"/>)}
              >
                <span>{col.name}</span>
              </Tooltip>
            </div>
          </div>
        )
      }

      return (
        <div key={col.id} className="header">{col.name}</div>
      )
    })}
    <div className="header">{sumColumn}</div>
    <div className="header">{taxColumn}</div>
    <div className="header">{totalColumn}</div>
  </>
}

export default TableHeader