import React, {FC} from "react";
import {FilterPropertyData} from "@pages/AdditionalServices/containers/types";
import Tab from "@mui/material/Tab";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Tooltip from "@mui/material/Tooltip";

export interface SortTabByPropertyProps {
  isSelected: boolean
  isArrowEnabled: boolean
  sortDirection: "asc" | "desc"
  position: number
  property: FilterPropertyData<"number">
  onSelect: () => void
}

const SortTabByProperty: FC<SortTabByPropertyProps> = React.forwardRef<HTMLDivElement, SortTabByPropertyProps>((props, ref) => {
  const {
    isSelected,
    isArrowEnabled,
    sortDirection,
    position,
    property,
    onSelect,
  } = props
  const {t} = useTranslation('AdditionalService')

  let title = property.Property.settings.Sort.AscDirectionCaption
  if (property.Property.settings.Sort.AvailableDirection === "desc") {
    title = property.Property.settings.Sort.DescDirectionCaption
  }

  if (isSelected && property.Property.settings.Sort.AvailableDirection === "both" && sortDirection === "desc") {
    title = property.Property.settings.Sort.DescDirectionCaption
  }

  const isNeedDisplayArrow = isArrowEnabled && isSelected

  return (
    <Tooltip placement="top" title={t('AdditionalService:Templates.list.changeDirection')}>
      <Tab
        ref={ref}
        label={(
          <div className="a-service-template-list-content--sort-tab">
            {isArrowEnabled && (
              <div
                className={clsx("arrow", {
                  "revert": sortDirection === "asc"
                })}
              >
                {isNeedDisplayArrow && (
                  <ArrowDownwardIcon/>
                )}
              </div>
            )}
            <div>{t(title)}</div>
          </div>
        )}
        onClick={onSelect}
        value={position}
        className={clsx("a-service-template-list-content-tab", {
          "a-service-template-list-content-tab--selected": isSelected,
        })}
      />
    </Tooltip>
  )
})

export default SortTabByProperty