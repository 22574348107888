import {
  CurrencyResponse,
  ExportItem,
  ImportItem,
} from '@services/requests/customsTaxService/interface';

import { CustomsPaymentProduct } from '../components/CustomsPaymentsForm/context/types';
import { getCalculation } from '../components/CustomsPaymentsForm/helpers/calculation';

interface GetFeeCalcProps {
  selectedFee: ImportItem;
  selectedExportFee: ExportItem;
  localizedMobileHeaderTableLabels: LocalizedLabels;
  currencies: CurrencyResponse;
  contractCurrency: CurrencyResponse[number];
  product: CustomsPaymentProduct;
  title: string;
}

interface LocalizedLabels {
  rateLabel: string;
  rublesAmountLabel: string;
  currencyAmountLabel: string;
}

function getFeeCalc({
  selectedFee,
  selectedExportFee,
  localizedMobileHeaderTableLabels,
  currencies,
  contractCurrency,
  product,
  title,
}: GetFeeCalcProps) {
  const { productsCount, contractCurrencyCost } = product;

  let feeRowData: any = null;
  if (selectedFee?.Value === '0' || !selectedFee?.ValueDetail) {
    feeRowData = {
      id: 'fee',
      name: title,
      rate: '0%',
      rublesAmount: '0',
      currencyAmount: '0',
      contractCurrency,
      ...localizedMobileHeaderTableLabels,
    };
  }

  const foundCurrency =
    currencies.find((currency) => currency.encode === selectedFee?.ValueDetail.ValueCurrency) ||
    contractCurrency;

  const count = +selectedFee?.ValueDetail.ValueCount;
  const unit = selectedFee?.ValueDetail.ValueUnit;
  const feeBase = +productsCount;
  const cost = +contractCurrencyCost;
  const currencyRate = +foundCurrency?.rate;
  const rubCost = cost * currencyRate;
  const targetCurrecyRate = contractCurrency?.rate;

  const result = getCalculation(count, unit, '', currencyRate, rubCost, feeBase);
  const sumCurrency = result / targetCurrecyRate;

  feeRowData = {
    id: 'fee',
    name: title,
    rate: selectedFee?.Value,
    rublesAmount: result,
    currencyAmount: sumCurrency,
    contractCurrency,
    ...localizedMobileHeaderTableLabels,
  };

  let exportFeeRowData: any = null;
  if (selectedExportFee?.Value === '0' || !selectedExportFee?.ValueDetail) {
    exportFeeRowData = {
      id: 'fee',
      name: title,
      rate: '0%',
      rublesAmount: '0',
      currencyAmount: '0',
      contractCurrency,
      ...localizedMobileHeaderTableLabels,
    };
  }

  const foundExportCurrency =
    currencies.find(
      (currency) => currency.encode === selectedExportFee?.ValueDetail.ValueCurrency
    ) || contractCurrency;

  const countExport = +selectedExportFee?.ValueDetail.ValueCount;
  const unitExport = selectedExportFee?.ValueDetail?.ValueUnit || '';
  const feeBaseExport = +contractCurrencyCost;
  const costExport = +contractCurrencyCost;
  const currencyExportRate = +foundExportCurrency?.rate;
  const rubExportCost = costExport * currencyExportRate;
  const targetCurrecyExportRate = contractCurrency?.rate;

  const resultExport = getCalculation(
    countExport,
    unitExport,
    '',
    targetCurrecyExportRate,
    rubExportCost,
    feeBaseExport
  );
  const sumCurrencyExport = resultExport / targetCurrecyExportRate;

  exportFeeRowData = {
    id: 'fee',
    name: title,
    rate: selectedExportFee?.Value,
    rublesAmount: resultExport,
    currencyAmount: sumCurrencyExport,
    contractCurrency,
    ...localizedMobileHeaderTableLabels,
  };

  return [feeRowData, exportFeeRowData];
}

export { getFeeCalc };
