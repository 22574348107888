import React, {FC} from "react";
import {ServiceProps} from "@pages/AdditionalServices/components/Service/types";
import getLocalizationByArgs from "@helpers/getLocalizationByArgs";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import TotalPriceColumn from "@pages/AdditionalServices/components/Service/CargoDeclarationTemplate/TotalPriceColumn";
import {CircularProgress} from "@material-ui/core";
import TableHeader from "@pages/AdditionalServices/components/Service/CargoDeclarationTemplate/TableHeader";
import ServiceFieldsList from "@pages/AdditionalServices/components/Service/ServiceFieldsList";
import ServiceComp from "@pages/AdditionalServices/components/Service";
import serviceSortFunc from "@pages/AdditionalServices/components/Service/serviceSortFunc";

/**
 * CargoDeclarationTemplate реализует шаблон вывода таблицы - Декларирование грузов
 * @param props
 * @constructor
 */
const CargoDeclarationTemplate: FC<ServiceProps> = props => {
  const {
    langID,
    currencyID,
    isServicesCalculating,
    service,
    serviceCalculations,
    serviceDataCache,
  } = props

  const serviceName = getLocalizationByArgs(langID, service.name, service.localized_names)
  const serviceDescription = getLocalizationByArgs(langID, service.description, service.localized_descriptions)
  const servicesToDisplay = service.additionServices
    .filter(s => s.template === "serviceInCargoDeclaration")
    .sort(serviceSortFunc)

  return (
    <div className="a-service-template-cargoDeclaration">
      <div className="a-service-template-cargoDeclaration-title">
        <div>
          {serviceDescription.length > 0 && (
            <Tooltip
              placement="top"
              title={(<div dangerouslySetInnerHTML={{__html: serviceDescription}} className="a-html-content"/>)}
            >
              <IconButton sx={{width: 16, height: 16, backgroundColor: '#EFF3FB'}}>
                <QuestionMarkIcon sx={{fontSize: 8, color: "#707070"}}/>
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div>
          <Tooltip
            placement="top"
            title={serviceDescription.length > 0 && (
              <div dangerouslySetInnerHTML={{__html: serviceDescription}} className="a-html-content"/>
            )}
          >
            <span>{serviceName}</span>
          </Tooltip>
        </div>
      </div>
      <div className="a-service-template-cargoDeclaration-fields">
        <ServiceFieldsList {...props} />
        <div className="progress-container">
          {isServicesCalculating && (
            <CircularProgress color="inherit" size={20}/>
          )}
        </div>
      </div>
      <TotalPriceColumn
        currency={serviceDataCache.currency.cache[currencyID]}
        settings={service.settings}
        services={serviceCalculations}
      />
      <div className="a-service-template-cargoDeclaration-list">
        <div className="a-service-template-cargoDeclaration-scroll">
          <TableHeader settings={service.settings.TemplateSettings.CargoDeclaration}/>
          {servicesToDisplay.map(s => {
            const {service: _, serviceCalculations, ...baseProps} = props
            const calculations = serviceCalculations.filter(
              c => c.serviceId === s.id
            )

            return (
              <ServiceComp
                key={s.id}
                service={s}
                serviceCalculations={calculations}
                {...baseProps}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default CargoDeclarationTemplate