import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

export default function InputLabel(theme: Theme) {
  return {
    MuiInputLabel: {
      defaultProps: {
        container: document.getElementById('onlog-widget-container'),
      },
      styleOverrides: {
        root: {
          left: '6px',
          fontSize: 14,
          fontWeight: 600,
          lineHeight: 1.7,
          color: theme.palette.grey[300],
          letterSpacing: '0.01px',
          '&.MuiInputLabel-shrink': {
            fontSize: 12,
            transform: 'translate(11px, -10px) scale(1)',
          },
        },
      },
    },
  };
}
