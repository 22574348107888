import OfferDetailTableHeaderData from '@components/pages/Products/components/RouteProduct/components/ShoulderItemHeaderData';
import ShoulderItemTableBody from '@components/pages/Products/components/RouteProduct/components/ShoulderItemTable/components/ShoulderItemTableBody';
import TerminalsInfoGroupTitle from '@components/pages/Products/components/RouteProduct/components/TerminalsInfo/components/TerminalsInfoGroupTitle';
import TerminalsInfoRowIncludedAllowance from '@components/pages/Products/components/RouteProduct/components/TerminalsInfo/components/TerminalsInfoRowIncludedAllowance';
import { getFullPriceFromTerminals } from '@components/pages/Products/components/RouteProduct/components/TerminalsInfo/helpers';
import { WithRouteProductContextData } from '@components/pages/Products/components/RouteProduct/types';
import WithRouteProductContext from '@components/pages/Products/components/RouteProduct/WithRouteProductContext';
import { getLocalizationById } from '@context/useMyRouting/helpers';
import { TaxNameProps } from '@helpers/getTaxNames';
import { MappedTerminalData } from '@helpers/getTerminalsFromStep';
import useBasket from '@pages/Basket/useBasket';
import ShoulderItemDetailsDTO, {
  RowProps,
} from '@services/collectors/ProductService/ShoulderItemDetailsDTO';
import { BranchItem } from '@services/requests/routeCalculator/locationsBranchSearchService/interfaces';
import { CurrencyData } from '@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery';
import React from 'react';
import { useTranslation } from 'react-i18next';

type TerminalsInfoWithAllowanceProps = WithRouteProductContextData<{
  terminalGroup: MappedTerminalData[];
  terminals: BranchItem[];
  taxNames: TaxNameProps[];
  allowancesRowsData: RowProps[];
}>;

/**
 * Компонент выводит данные по одному терминалу. На вход принимает все
 * расходы, которые были по этому терминалу.
 */
const TerminalsInfoWithAllowance = WithRouteProductContext(
  (props: TerminalsInfoWithAllowanceProps) => {
    const { currencyRoute, terminalGroup, terminals, allowancesRowsData, taxNames } = props;
    const [order] = useBasket().useOrder().useState();
    const langCode = getLocalizationById(order.language_id).countryCode;
    const column = OfferDetailTableHeaderData(currencyRoute);
    const { t } = useTranslation(['Common', 'OfferDetail'], { lng: langCode });
    const [terminal] = terminalGroup;
    const totalPrice = getFullPriceFromTerminals(terminalGroup);

    const terminalGroupRows = [];
    let isIncludedAllowanceUnload: boolean = true;
    let isIncludedAllowanceLoad: boolean = true;
    let terminalLoadingType: string = terminal.loadingType;

    terminalGroup.forEach((terminal) => {
      const { terminalPrice, loadingType, isFirst, isLast } = terminal;
      terminalLoadingType = loadingType;
      const baseCurrency = props.productData.baseData.currencies.find(
        (c) => c.id === terminalPrice?.currency_id
      ) as CurrencyData;

      let terminalGroupRowName =
        loadingType === 'load'
          ? t('OfferDetail:LoadingTerminal')
          : t('OfferDetail:UnloadingTerminal');

      if (
        terminal.terminalLoadingUnloadingOffer &&
        terminal.terminalLoadingUnloadingOffer.service_type === 'loading_and_unloading'
      ) {
        terminalGroupRowName = t('OfferDetail:Unloading/loading');
        isIncludedAllowanceLoad = false;
        isIncludedAllowanceUnload = false;
      }

      if (terminalPrice && baseCurrency) {
        terminalGroupRows.push({
          price: terminal.terminalCalculatedPrice,
          name: terminalGroupRowName,
          baseCurrency,
          taxName: taxNames.length
            ? taxNames.find((i) => +i.taxId === terminal.terminalPrice.tax_id)?.localizedName || ''
            : '',
          targetCurrency: currencyRoute,
        });
      }
      if (terminal.loadingType === 'load' && terminal.loadingCondition.code !== 'Loading Only') {
        terminalLoadingType = 'unload';
        isIncludedAllowanceLoad = false;
      }
      if (
        terminal.loadingType === 'unload' &&
        terminal.unloadingCondition.code !== 'Unloading Only'
      ) {
        terminalLoadingType = 'load';
        isIncludedAllowanceUnload = false;
      }

      if (
        terminal.terminalLoadingUnloadingOffer &&
        terminal.terminalLoadingUnloadingOffer.service_type === 'loading_and_unloading'
      ) {
        terminalLoadingType = 'loading_and_unloading';
      }

      if (isFirst) {
        const { terminalAllowances, loadingCondition } = terminal;

        isIncludedAllowanceUnload = false;
        terminalLoadingType = 'load';

        isIncludedAllowanceLoad = !!terminalAllowances.length && loadingCondition.id === '2';
      }

      if (isLast) {
        const { terminalAllowances, unloadingCondition } = terminal;

        isIncludedAllowanceLoad = false;
        terminalLoadingType = 'unload';

        isIncludedAllowanceUnload = !!terminalAllowances.length && unloadingCondition.id === '2';
      }
    });

    const rowsData = [...ShoulderItemDetailsDTO([...terminalGroupRows, ...allowancesRowsData])];

    return (
      <>
        <TerminalsInfoGroupTitle
          id={terminal.terminal.id}
          currencyCode={currencyRoute.code}
          branchItem={terminals.find((t) => t.id === terminal.terminal.id)}
          totalPrice={totalPrice}
        />
        {isIncludedAllowanceUnload && (
          <TerminalsInfoRowIncludedAllowance
            type={terminalLoadingType}
            isIncluded={true}
            borderBottom={rowsData.length > 0}
          />
        )}
        {rowsData.length > 0 && <ShoulderItemTableBody rows={rowsData} columns={column} />}
        {isIncludedAllowanceLoad && (
          <TerminalsInfoRowIncludedAllowance type={terminalLoadingType} isIncluded={true} />
        )}
      </>
    );
  }
);

export default TerminalsInfoWithAllowance;
