import AirTypeIcon from '@components/icons/CargoTypeIcons/AirTypeIcon';
import AutoTypeIcon from '@components/icons/CargoTypeIcons/AutoTypeIcon';
import ContainerTypeIcon from '@components/icons/CargoTypeIcons/ContainerIcon';
import CustomTypeIcon from '@components/icons/CargoTypeIcons/CustomTypeIcon';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { CargoType } from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/interfaces';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

// Пропсы компонента
export type CargoTypeSelectProps = {
  cargoType: CargoType;
  onChangeCargoType: { (type: 'container' | 'custom' | 'auto' | 'air'): void };
};

// Карта типа и ее иконки
const IconByTypeMap: {
  [k in 'container' | 'custom' | 'auto' | 'air']: () => React.ReactNode;
} = {
  air: () => <AirTypeIcon />,
  auto: () => <AutoTypeIcon />,
  container: () => <ContainerTypeIcon />,
  custom: () => <CustomTypeIcon />,
};

/**
 * Компонент выбора типа отправления
 * @param props
 */
const CargoTypeSelect = (props: CargoTypeSelectProps) => {
  const { cargoType, onChangeCargoType } = props;
  const { t } = useTranslation('Common');
  const handlerChange = useCallback(
    (event: any) => {
      onChangeCargoType(event.target.value);
    },
    [onChangeCargoType]
  );

  const options: {
    type: 'container' | 'custom' | 'auto' | 'air';
    label: string;
  }[] = [
    {
      type: 'container',
      label: 'FCL',
    },
    {
      type: 'custom',
      label: 'LCL',
    },
    /*    {
      type: 'auto',
      label: t('Common:Auto'),
    },*/
    {
      type: 'air',
      label: t('Common:Air'),
    },
  ];

  const GetIcon = (type: 'container' | 'custom' | 'auto' | 'air') => IconByTypeMap[type]();

  return (
    <>
      <FormControl variant="outlined" fullWidth={true}>
        <InputLabel id="cargo-type-select">{t('Common:Type')}</InputLabel>
        <Select
          value={cargoType || ''}
          MenuProps={{
            disableScrollLock: true,
            disablePortal: true,
          }}
          id={'cargo-type-select'}
          className={'cargo-type-select'}
          onChange={handlerChange}
          label={t('Common:Type')}
          defaultValue={options[0].type}
          renderValue={(value) => (
            <div className={'select-input'}>
              {GetIcon(value as any)}
              <span>{options.find((o) => o.type === value)?.label}</span>
            </div>
          )}
        >
          {options.map((option) => (
            <MenuItem value={option.type} key={option.type}>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '16px 1fr',
                  gap: '8px',
                  alignItems: 'center',
                }}
              >
                {GetIcon(option.type)}
                <span>{option.label}</span>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default CargoTypeSelect;
