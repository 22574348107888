import { GraphQLQuery } from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

export interface BitrixUser {
  id: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  email: string;
  phone: string;
  transport_requests_per_month: number; // лимит запроса в месяц
}

export interface LoadUserQueryResponse {
  BitrixUserProfile: BitrixUser;
}

/**
 * Запрос загрузки данных по валюте
 */
export class LoadBitrixUserQuery implements GraphQLQuery<null> {
  readonly query: any;

  readonly variables: null;

  constructor() {
    this.variables = null;
    this.query = gql`
      query __LOAD_USER__ {
        BitrixUserProfile {
          id
          first_name
          last_name
          middle_name
          email
          phone
          transport_requests_per_month
        }
      }
    `;
  }
}
