import {Service} from "@onlog-public/additional-services-types";
import {
  serviceToCalculateGeneratorProcessor
} from "@pages/AdditionalServices/containers/services/serviceToCalculateGenerator/interface";
import {ServicePriceCalculationItemsProps} from "@services/requests/servicesService/servicesServicesService/interface";

/**
 * ServiceInVerticalTableProcessor реализует заглушку для генератора ЦП
 * на расчет для столбцов вертикальных таблиц
 */
export class ServiceInVerticalTableProcessor implements serviceToCalculateGeneratorProcessor {
  /**
   * isAvailable проверяет доступность процессора для переданной услуги.
   * @param service
   */
  isAvailable(service: Service): boolean {
    return service.template === "serviceInVerticalTable"
  }


  /**
   * Generate выполняет генерацию запросов на расчет услуг
   * по переданным данным. Позволяет сформировать для услуги
   * сразу несколько запросов, например с разными значениями
   * полей.
   *
   * @param _
   * @param __
   * @param ___
   * @param ____
   * @param _____
   */
  Generate(
    _: Service,
    __: string,
    ___: { [x: string]: number; },
    ____: { [x: string]: number; },
    _____: any,
  ): ServicePriceCalculationItemsProps[] {
    return []
  }
}