import {BehaviorSubject, Subject} from "rxjs";
import {locationsBranchService} from "@services/requests/routeCalculator/locationsBranchSearchService";
import {useEffect, useMemo, useState} from "react";
import getLocalizationByArgs from "@helpers/getLocalizationByArgs";
import {SelectOption} from "@pages/AdditionalServices/containers/services/optionsGenerator/types";
import {v4} from "uuid";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {BranchItem} from "@services/requests/routeCalculator/locationsBranchSearchService/interfaces";

/**
 * LocationSearchContext описывает типизацию контекста поля поиска локаций
 */
export interface LocationSearchContext {
  /**
   * Язык локализации системы. Нужен для вычисления опций.
   */
  LangID: string

  /**
   * Найденные локации после поиска.
   */
  LoadedOptions: { [T in string]: SelectOption[] }
}

const locationSearchContext$ = new BehaviorSubject<LocationSearchContext>({
  LangID: "",
  LoadedOptions: {},
})

/**
 * UserLocationSearch содержит текущий стейт результатов поиска для пользовательского
 * компонента. Он уникален для каждого компонента в отдельности и содержит именно
 * его данные.
 */
export interface UserLocationSearch {
  /**
   * SearchString содержит строку поиска. При изменении запускается автоматический
   * поиск локаций, на основе подписки.
   */
  SearchString: string

  /**
   * Идентификатор последнего запроса на поиск. Именно он будет актуален.
   * Запросы могут проходить с разной скоростью и более ранние могут вернуться
   * позже, чем крайний, поэтому их необходимо будет отбросить, оставив только
   * результаты актуального.
   */
  LastSearchID: string

  /**
   * Выбранная опция. Нужная для повторяющихся поисков после выбора опций.
   * Ее наличие отключает такие глюки.
   */
  SelectedOption?: SelectOption

  /**
   * Загруженные опции поиска для конкретного компонента.
   */
  Options: SelectOption[]
}

/**
 * Обработчик изменения строки поиска. Записывает изменение в стейт, возвращает
 * найденные опции по запросу.
 * @param searchString
 */
const onSearchByString = async (searchString: string): Promise<SelectOption[]> => {
  if (searchString.length < 3) {
    return []
  }

  const state = locationSearchContext$.getValue()
  if (!!state.LoadedOptions[searchString]) {
    return state.LoadedOptions[searchString]
  }

  const options = await locationsBranchService().SearchBranches(searchString)

  const loadedOptions = mapOptions(options, state.LangID)
  const newState = locationSearchContext$.getValue()
  locationSearchContext$.next({
    ...newState,
    LoadedOptions: {
      ...newState.LoadedOptions,
      [searchString]: loadedOptions,
    },
  })

  return loadedOptions
}

/**
 * mapOptions возвращает смапленные локации в опции селектора
 * @param options
 * @param langID
 */
const mapOptions = (options: BranchItem[], langID: string): SelectOption[] => {
  return options
    .filter(o => o.type === "location")
    .map(d => ({
      value: parseInt(d.id),
      label: getLocalizationByArgs(langID, d.defaultName ?? "", d.localizedNamesArray),
      description: d.subItems
        .sort((a, b) => a.depthLevel < b.depthLevel ? 1 : -1)
        .map(v => getLocalizationByArgs(langID, v.visibleName, v.localizedNamesArray))
        .join(", "),
      origin: d,
    }))
    .filter(o => !!o)
    .filter((d, i, data) => data.map(o => o.value).indexOf(d.value) === i)

}

/**
 * Инициализация компонента и контекста. Содержит все подписки на поиск и т.д.
 * @param langID
 */
const init = (langID: string) => {
  locationSearchContext$.next({
    ...locationSearchContext$.getValue(),
    LangID: langID,
  })
}

/**
 * makeOptionLabel генерирует название опции для строки поиска по
 * переданной опции.
 * @param option
 */
const makeOptionLabel = (option?: SelectOption) => {
  if (!option) {
    return ""
  }

  return option.label + ", " + option.description
}

/**
 * Стейты пользователей. По сути заранее заведенные стейты, которые
 * извлекаются по ID
 */
const userStates: { [T in string]: BehaviorSubject<UserLocationSearch> } = {}
const getState = (id: string) => {
  if (!!userStates[id]) {
    return userStates[id]
  }

  userStates[id] = new BehaviorSubject<UserLocationSearch>({
    LastSearchID: "",
    SearchString: "",
    SelectedOption: undefined,
    Options: []
  })

  return userStates[id]
}

/**
 * useUserSearch реализует хук для конечного компонента поиска локаций.
 * Позволяет задействовать индивидуальный стейт для поиска по локациям.
 * @param initialOptions
 * @param optionValue
 * @param selectedOption
 * @param langID
 */
const useUserSearch = (
  langID: string,
  optionValue: number,
  initialOptions: SelectOption[] = [],
  selectedOption?: SelectOption,
) => {

  /**
   * Генерируем стейт для пользователя или подтягиваем имеющийся
   */
  const stateID = useMemo(() => v4(), [])
  const [state, setState] = useState(getState(stateID).getValue())
  useEffect(() => {
    const subscription = getState(stateID).pipe().subscribe({
      next: state => setState(state),
    })

    return () => {
      try {
        subscription.unsubscribe()
      } catch (e) {
      }
    }
  }, [])

  useEffect(() => {
    const data = getState(stateID).getValue()
    if (data.Options.length > 0) {
      return
    }

    getState(stateID).next({
      ...data,
      Options: initialOptions,
    })
  }, [initialOptions])

  useEffect(() => {
    const data = getState(stateID).getValue()
    if (data.SelectedOption?.value === selectedOption?.value) {
      return
    }

    getState(stateID).next({
      ...data,
      SelectedOption: selectedOption,
      SearchString: makeOptionLabel(selectedOption)
    })
  }, [selectedOption])

  useEffect(() => {
    onInitializeOption(optionValue)
  }, []);

  /**
   * onInitializeOption позволяет инициировать загрузку опций компонента по
   * базовому идентификатору опции
   * @param id
   */
  const onInitializeOption = async (id: number) => {
    try {
      const options = mapOptions(await locationsBranchService().GetItemsByValue([String(id)], "location"), langID)
      const state = getState(stateID).getValue()

      if (state.Options.length !== 0) {
        return
      }

      getState(stateID).next({
        ...state,
        SelectedOption: options.find(o => o.value === id),
        Options: options,
        SearchString: makeOptionLabel(selectedOption)
      })
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * Шина поиска локаций с тротлингом. Тротлит пол секунды до поиска и ищет
   * локации, когда есть изменения в отправленных запросах.
   */
  const [bus] = useState(new Subject<string[]>())
  useEffect(() => {
    const subscribe = bus.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe({
        next: async data => {
          const [searchString, lastSearchID] = data
          if (0 === searchString.length) {
            return
          }

          try {
            const options = await onSearchByString(searchString)

            const data = getState(stateID).getValue()
            if (data.LastSearchID !== lastSearchID) {
              return
            }

            getState(stateID).next({
              ...data,
              LastSearchID: "",
              Options: options,
            })
          } catch (e) {
            console.error(e)
          } finally {
            const data = getState(stateID).getValue()
            if (data.LastSearchID === lastSearchID) {
              getState(stateID).next({
                ...data,
                LastSearchID: "",
              })
            }
          }
        }
      })

    return () => {
      try {
        subscribe.unsubscribe()
      } catch (e) {
      }
    }
  }, []);

  /**
   * onSelectOption сохраняет выбранную опцию в стейт.
   * @param option
   */
  const onSelectOption = (option?: SelectOption) => {
    getState(stateID).next({
      ...getState(stateID).getValue(),
      SelectedOption: option,
      SearchString: makeOptionLabel(option),
    })
  }

  /**
   * onChangeSearch реализует callback для обработки изменения строки поиска и
   * загрузки локаций для нее.
   * @param searchString
   */
  const onChangeSearch = (searchString: string) => {
    const data = getState(stateID).getValue()
    const currentLabel = makeOptionLabel(data.SelectedOption)
    if (searchString == currentLabel && searchString.length !== 0) {
      return
    }

    const lastSearchID = v4()
    getState(stateID).next({
      ...data,
      LastSearchID: 0 === searchString.length ? "" : lastSearchID,
      SearchString: searchString,
    })

    bus.next([searchString, lastSearchID])
  }

  return {
    state,
    makeOptionLabel,
    onChangeSearch,
    onSelectOption,
  }
}

/**
 * Хук для подключения к компоненту. Автоматически выгружает текущий стейт,
 * а так же прокидывает callback изменений.
 */
const useLocationSearchContext = () => {
  return {
    init,
    useUserSearch,
  }
}

export default useLocationSearchContext