import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const TrainIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M18.9 19.2L16.5 17c1-.3 1.6-1.2 1.6-2.3V8.2c0-1.2-1-2.3-2.3-2.3H15l-.5-1.1c-.3-.5-.7-.8-1.3-.8h-2.6c-.5 0-1 .3-1.2.8l-.6 1.1h-.6C7 6 5.9 7 5.9 8.2v6.6c0 1.1.7 2 1.7 2.3l-2.4 2a.5.5 0 10.6.8l1-.8h10.5l1 .8a.5.5 0 10.6-.7zM7.1 8.7H17l-1.2 2.9c-.2.5-.7.9-1.3.9h-5c-.5 0-1-.4-1.3-1L7.1 8.8zm1.2 5.7h1a.5.5 0 110 1h-1a.5.5 0 110-1zM7.8 18l1.1-1h1.7v-1.3a1.4 1.4 0 012.8 0v1.4h1.8l1 1H7.8zm8-2.8h-1a.5.5 0 110-1h1a.5.5 0 110 1z"
      fill={'currentColor'}
    />
  </SvgIcon>
);

export default TrainIcon;
