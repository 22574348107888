import { styled } from '@mui/material';

const Container = styled('div')`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  @font-face {
    font-family: 'Manrope';
    font-weight: normal;
    font-style: normal;
    src: local('Manrope Regular'), url('/fonts/Manrope-Regular.ttf') format('truetype');
  }
  font-family: 'Manrope', sans-serif;
`;

export default Container;
