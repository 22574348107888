import {serviceCalculationBus$, serviceContext$} from "@pages/AdditionalServices/containers/bus";
import {clone} from "@pages/AdditionalServices/containers/methods/clone";
import {calculateFieldValues} from "@pages/AdditionalServices/containers/methods/calculateFieldValues";
import {makeOptionsForLocationChanges} from "@pages/AdditionalServices/containers/additionServicesOptionsContext";

/**
 * onChangeServiceFieldValue выполняет изменение значения поля в стейте.
 * Метод изменяет значения только для полей услуг. Если значение не
 * изменилось, метод пропускает его установку. После установки
 * значения, метод выполняет пересчет значений по формулам.
 *
 * @param variant
 * @param field
 * @param value
 */
const onChangeServiceFieldValue = (
  variant: string,
  field: string,
  value: number,
) => {
  const currentState = serviceContext$.getValue()
  if (!currentState.VariantFieldValues[variant]) {
    return
  }

  if (currentState.VariantFieldValues[variant][field] === value) {
    return
  }

  const state = clone(currentState)
  state.VariantFieldValues[variant][field] = value
  state.IsServicesCalculating = true

  state.VariantFieldValues[variant] = calculateFieldValues(
    state.VariantFields[variant],
    state.VariantFieldValues[variant],
    field,
    state.FieldValues,
  )

  serviceContext$.next(state)
  serviceCalculationBus$.next({isFieldChanges: false, isNeedChooseVariant: false})

  // Отправляем дополнительную загрузку опций для локаций.
  // В этом поле используется поиск с подсказкой, поэтому опций изначально нет.
  // Этот метод их загрузит. После этого опции станут доступны для экспорта в
  // продукт заказа.
  const fieldData = state.VariantFields[variant].find(f => f.code === field)
  if (fieldData) {
    makeOptionsForLocationChanges(fieldData, value)
  }
}

export default onChangeServiceFieldValue