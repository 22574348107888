import React, {FC, useState} from "react";
import {ServiceDirectoryProps} from "@pages/AdditionalServices/components/ServiceDirectory/types";
import ServiceComp from "@pages/AdditionalServices/components/Service";
import serviceSortFunc from "@pages/AdditionalServices/components/Service/serviceSortFunc";
import {Collapse} from "@mui/material";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import CollapseIcon from "@pages/AdditionalServices/components/ServiceDirectory/CollapseIcon";
import isServiceInAdditionGroup from "@pages/AdditionalServices/components/ServiceDirectory/isServiceInAdditionGroup";
import {Service, ServiceFileData} from "@onlog-public/additional-services-types";
import FileList from "@pages/AdditionalServices/components/Service/FileList";
import GroupTotalPrice from "@pages/AdditionalServices/components/ServiceDirectory/GroupTotalPrice";
import restoreServiceData from "@pages/AdditionalServices/containers/methods/basket/restoreServiceData";
import {CircularProgress} from "@material-ui/core";

/**
 * getFilesFromServices возвращает все файлы, добавленные в дочерние услуги
 * @param service
 */
const getFilesFromServices = (service: Service): ServiceFileData[] => {
  return [
    ...service.file_id,
    ...(service.additionServices?.map(s => getFilesFromServices(s)) ?? []).flat(1)
  ]
}

/**
 * GroupTypeTemplate реализует шаблон вывода групп услуг
 * @param props
 */
const GroupTypeTemplate: FC<ServiceDirectoryProps> = props => {
  const {
    variant,
    services,
    serviceCalculations,
    globalFiles,
    globalProperties,
    serviceProperties,
    propertyValues,
    propertyOptions,
    onChangeServiceFieldValue,
    onAddGroupToCart,
    order,
    selectedProductUUID,
    isOrderDisplay,
    ...baseServiceProps
  } = props

  const {t} = useTranslation('AdditionalService')

  const files: { [K in string]: ServiceFileData } = {}
  Array.from([
    ...globalFiles,
    ...variant.file_id,
    ...services.map(s => getFilesFromServices(s)).flat(1)
  ]).map(f => files[f.id] = f)
  const filesToDisplay = Object.values(files)
    .sort((a, b) => a.name_original > b.name_original ? 1 : -1)

  const primaryServices = services
    .filter(s => !isServiceInAdditionGroup(s))
    .sort(serviceSortFunc)
  const additionServices = services
    .filter(s => isServiceInAdditionGroup(s))
    .sort(serviceSortFunc)

  const [isCollapsed, setIsCollapsed] = useState(true)
  const buttonText = !isCollapsed
    ? t('AdditionalService:Templates.group.showServices')
    : t('AdditionalService:Templates.group.hideServices')

  const orderProducts = [...order?.products ?? [], ...order?.pre_order_products ?? []]
      ?.map(p => restoreServiceData(p)).filter(d => !!d)
    ?? []

  const existProduct = orderProducts.find(p => p.setUpService.SelectedProductUUID === selectedProductUUID)
  const orderButtonText = !!existProduct || isOrderDisplay
    ? t('AdditionalService:Templates.group.alreadyInOrder')
    : t('AdditionalService:Templates.group.addToOrder')

  const [isAddToOrderClicked, setIsAddToOrderClicked] = useState(false)

  return (
    <div className="a-service-template-group">
      {primaryServices.length > 0 && (
        <div className="a-service-template-group-block">
          {primaryServices.map(s => {
            const servicesID = [s.id, ...s.additionServices.map(s => s.id)]
            return (
              <ServiceComp
                key={s.id}
                {...baseServiceProps}
                variant={variant}
                service={s}
                globalFiles={globalFiles}
                properties={[...globalProperties, ...serviceProperties]}
                propertyValues={propertyValues}
                propertyOptions={propertyOptions}
                order={order}
                isOrderDisplay={isOrderDisplay}
                serviceCalculations={serviceCalculations.filter(c => servicesID.includes(c.serviceId))}
                onChangeServiceFieldValue={(f, v) => onChangeServiceFieldValue(variant.id, f, v)}
              />
            )
          })}
        </div>
      )}
      {additionServices.length > 0 && (
        <div>
          <div
            onClick={() => setIsCollapsed(!isCollapsed)}
            className={clsx("a-service-template-group-collapse-button", {
              collapsed: !isCollapsed,
            })}
          >
            <div><CollapseIcon/></div>
            <div>{buttonText}</div>
          </div>
          <Collapse in={isCollapsed}>
            <div className="a-service-template-group-block">
              {additionServices.map(s => {
                const servicesID = [s.id, ...s.additionServices.map(s => s.id)]
                return (
                  <ServiceComp
                    key={s.id}
                    {...baseServiceProps}
                    variant={variant}
                    service={s}
                    globalFiles={globalFiles}
                    properties={[...globalProperties, ...serviceProperties]}
                    propertyValues={propertyValues}
                    propertyOptions={propertyOptions}
                    order={order}
                    isOrderDisplay={isOrderDisplay}
                    serviceCalculations={serviceCalculations.filter(c => servicesID.includes(c.serviceId))}
                    onChangeServiceFieldValue={(f, v) => onChangeServiceFieldValue(variant.id, f, v)}
                  />
                )
              })}
            </div>
          </Collapse>
        </div>
      )}
      {!isOrderDisplay && (
        <>
          <GroupTotalPrice
            langID={props.langID}
            currencyID={props.currencyID}
            serviceCalculations={serviceCalculations}
            mainDirectory={props.mainDirectory}
            serviceDataCache={props.serviceDataCache}
          />
          <div className="a-service-template-group-buttons">
            <div
              className={clsx("button", {
                "in-order": !!existProduct || isOrderDisplay,
                "loading": isAddToOrderClicked,
              })}
              onClick={() => {
                if (isAddToOrderClicked || !!existProduct || isOrderDisplay) {
                  return
                }

                setIsAddToOrderClicked(true)
                onAddGroupToCart()
                  .catch(() => null)
                  .finally(() => {
                    setIsAddToOrderClicked(false)
                  })
              }}
            >
              <div><CircularProgress color="inherit" size={16}/></div>
              <div>{orderButtonText}</div>
            </div>
          </div>
          <FileList files={filesToDisplay}/>
        </>
      )}
    </div>
  )
}

export default GroupTypeTemplate