import { $error } from '@settings/errorContext';
import { graphQLClient } from '@settings/services/graphQLClient';
import { GraphQLClient } from '@settings/services/graphQLClient/GraphQLClient';
import { loggerFactory } from '@settings/services/logger';
import { Logger } from '@settings/services/logger/Logger';

import {
  OrderBaseData,
  _OrderBaseDataLoaderProcessorInterface,
  _OrderQueryResult,
} from './interface';
import { OrderBaseDataLoaderProcessorQuery } from './OrderBaseDataLoaderProcessorQuery';

// Процессор загрузки данных заказов
export class OrderBaseDataLoaderProcessor implements _OrderBaseDataLoaderProcessorInterface {
  private readonly client: GraphQLClient;

  private readonly logger: Logger;

  /**
   * Конструктор процессора
   */
  constructor(token: string) {
    this.client = graphQLClient(token);
    this.logger = loggerFactory().make('OrderBaseDataLoaderProcessor');
  }

  /**
   * Проверка доступности процессора
   * @param type
   */
  IsAvailable(type: 'order' | 'preOrder'): boolean {
    return type === 'order';
  }

  /**
   * Загрузка данных заказа
   * @param orderId
   */
  async Load(orderId: string): Promise<OrderBaseData | undefined> {
    try {
      const resp = await this.client.Query<{ id: string }, _OrderQueryResult>(
        new OrderBaseDataLoaderProcessorQuery(orderId),
        {}
      );

      this.logger.Debug(`Loaded order data`, resp);
      if ((resp?.data || []).length === 0) {
        $error.next('Empty data response');
      }

      return resp.data[0];
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }
}
