/**
 * Хук по работе с настройками темы приложения
 */
function useSettings() {
  const { themeMode, themeDirection } = {
    themeMode: 'light',
    themeDirection: 'ltr',
  };

  return {
    // Mode
    themeMode,
    // Direction
    themeDirection: themeDirection as 'ltr' | 'rtl',
  };
}

export default useSettings;
