import {$error} from '@settings/errorContext';
import {graphQLClient} from '@settings/services/graphQLClient';
import {GraphQLClient} from '@settings/services/graphQLClient/GraphQLClient';
import {LoaderInterface} from '../interface';
import {
  HandbookVersionLoaderQuery, HandbookVersionLoaderQueryResponse,
} from './HandbookVersionLoaderQuery';
import {HandbookVersion} from "@onlog-public/additional-services-types"
import {localizedMessagesLoader} from "@services/requests/searchLoaders/localizedMessagesLoader";

/**
 * Загрузчик данных по подрядчикам
 */
export class HandbookVersionLoader implements LoaderInterface<HandbookVersion> {
  private readonly client: GraphQLClient;

  constructor(token?: string) {
    this.client = graphQLClient(token);
  }

  async Load(primaryKeys?: any[]): Promise<HandbookVersion[]> {
    try {
      const resp = await this.client.Query<null, HandbookVersionLoaderQueryResponse>(
        new HandbookVersionLoaderQuery(primaryKeys),
        {}
      );

      const locID = resp.items.map(i => {
        return i.items.map(it => it.localized_names)
      }).flat(2)

      const locales = await localizedMessagesLoader().Load(locID)

      return resp.items.map(item => ({
        ...item,
        items: item.items.map(i => ({
          ...i,
          localized_names: locales.filter(l => i.localized_names.includes(l.id))
        }))
      }));
    } catch (e) {
      $error.next(e);
    }
  }
}
