import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

export default function Stepper(theme: Theme) {
  return {
    MuiStepConnector: {
      defaultProps: {
        container: document.getElementById('onlog-widget-container'),
      },
      styleOverrides: {
        line: {
          borderColor: theme.palette.divider,
        },
      },
    },
    MuiStepContent: {
      defaultProps: {
        container: document.getElementById('onlog-widget-container'),
      },
      styleOverrides: {
        root: {
          borderColor: theme.palette.divider,
        },
      },
    },
  };
}
