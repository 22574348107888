import {
  BaseOptionsGenerator
} from "@pages/AdditionalServices/containers/services/propertyOptionsGenerator/BaseOptionsGenerator";
import {
  ContractorProcessor
} from "@pages/AdditionalServices/containers/services/propertyOptionsGenerator/contractorProcessor";
import {
  CurrencyProcessor
} from "@pages/AdditionalServices/containers/services/propertyOptionsGenerator/currencyProcessor";
import {
  HandbookProcessor
} from "@pages/AdditionalServices/containers/services/propertyOptionsGenerator/handbookProcessor";
import {
  LocationProcessor
} from "@pages/AdditionalServices/containers/services/propertyOptionsGenerator/LocationProcessor";
import {TaxProcessor} from "@pages/AdditionalServices/containers/services/propertyOptionsGenerator/taxProcessor";

// propertyOptionsGenerator реализует фабрику сервиса
const propertyOptionsGenerator = () => new BaseOptionsGenerator([
  new CurrencyProcessor,
  new HandbookProcessor,
  new LocationProcessor,
  new TaxProcessor,
  new ContractorProcessor('certification_contractor'),
  new ContractorProcessor('consulting_contractor'),
  new ContractorProcessor('insurance_contractor'),
  new ContractorProcessor('inspection_contractor'),
  new ContractorProcessor('customs_contractor'),
])

export default propertyOptionsGenerator