import { getRouteInformationList } from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesListComponent/RouteListItem/helpers';
import { OrderProduct } from '@services/requests/orderService/orderProductsLoader/interface';
import { RouteResult } from '@services/requests/orderService/types';
import { ContractorData } from '@services/requests/searchLoaders/contractorLoader/queries/ContractorLoaderQuery';

export const getServicesBySection = (services: OrderProduct[]) => {
  return services.reduce((acc, service) => {
    return acc[service.id].push(service);
  }, {});
};

/**
 * Сбор всех справок о маршрутах подрядчиков cо всех
 * всех шагов для каждого продукта и фильтрация пустых
 * значений, которые содержат только теги и пробелы.
 * Возвращает массив строк.
 * @param contractors
 * @param orderProducts
 */
export const getRouteInformationListFromProducts = (
  contractors: ContractorData[],
  orderProducts: OrderProduct[]
): string[] => {
  return orderProducts
    .map((product) => {
      const productData: RouteResult = JSON.parse(product?.data || '{}');
      const routeInformations = getRouteInformationList(contractors, productData.route);

      return routeInformations;
    })
    .flat();
};
