import {serviceContext$} from "@pages/AdditionalServices/containers/bus";
import {clone} from "@pages/AdditionalServices/containers/methods/clone";

/**
 * onChangeServiceSelectionState выполняет изменение списка включенных услуг
 * по переданному модификатору
 * @param variantID
 * @param callback
 */
const onChangeServiceSelectionState = (
  variantID: string,
  callback: { (currentSelections: string[]): string[] },
) => {
  const state = serviceContext$.getValue()
  serviceContext$.next({
    ...clone(state),
    VariantSelectedServices: {
      ...state.VariantSelectedServices,
      [variantID]: callback(state.VariantSelectedServices[variantID])
    }
  })
}

export default onChangeServiceSelectionState