import breakpoints from '@assets/theme/breakpoints';
import { styled } from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;

export const SwipeableThanksDrawerStyledContainer = styled(SwipeableDrawer)`
  &.MuiModal-hidden {
    .card-wrapper {
      overflow: inherit;
    }
  }

  .card-wrapper {
    border-radius: 20px 20px 0 0;
    padding: 20px 30px 67px;
    max-height: 90vh;
    position: relative;
    overflow: auto;
    box-shadow: 0 0 20px 0 rgba(0, 5, 115, 0.1);

    .drawer-control {
      display: flex;
      justify-content: flex-end;
    }
  }

  .section-title {
    font-size: 30px;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    max-width: 100%;
    margin: 32px 0 0 0;

    @media (max-width: ${breakpoints.values.md}px) {
      max-width: 190px;
      margin: 32px auto 0;
    }
  }

  .section-info {
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
  }

  .calculation-link {
    margin-top: 40px;
    border-radius: 18px;
    background: #fff;
    border: none;
    box-shadow: 0px 0px 20px 0px rgba(0, 5, 115, 0.07);

    &:hover {
      box-shadow: 0px 0px 20px 0px rgba(138, 141, 255, 0.28);
    }
  }

  .close-drawer {
    transform: rotate(180deg);
  }

  .MuiDrawer-paper {
    background: transparent;
    height: max-content;
    overflow: visible;
    -webkit-overflow-scrolling: touch;
    border-radius: 20px 20px 0 0;
  }

  .range-date {
    .terminal-location-paper,
    .location-selector__list-wrapper {
      max-height: 225px;
    }
  }
`;

export default Container;
