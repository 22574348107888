import LocationSelectorItem from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/LocationSelector/components/LocationSelectorItem';
import { LocationSelectorItemList } from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/LocationSelector/components/LocationSelectorItem/types';
import { BranchItem } from '@services/requests/routeCalculator/locationsBranchSearchService/interfaces';
import React from 'react';

/**
 * Пропсы компонента вывода найденных локаций по запросу
 */
export type LocationSelectorListProps = {
  onSelect: (item: BranchItem | undefined, closeDialog: boolean) => void;
  items: LocationSelectorItemList;
  value?: BranchItem | undefined;
  primaryLangId: string; // Основной язык
  secondaryLangId: string; // Дополнительный язык
  onMouseEnter?: (item: BranchItem) => void;
  onMouseLeave?: (item?: BranchItem) => void;
  onListBlockChange?: (state: boolean) => void;
  hoveredItem?: BranchItem | null;
  keyPrefix?: string;
};

/**
 * Компонент вывода найденных локаций по запросу
 */
const LocationSelectorList = (props: LocationSelectorListProps) => {
  const {
    keyPrefix = '-',
    items,
    value,
    onSelect,
    onMouseEnter,
    onMouseLeave,
    ...otherProps
  } = props;
  const onSelectHandle = (location: BranchItem | undefined) => {
    const isLocation = location.type === 'location';
    onSelect(location, isLocation);
  };

  const mouseEnterHandler = (item: BranchItem) => {
    if (item.type === 'terminal') {
      onMouseEnter(item);
    }
  };

  return (
    <ul className={'location-list'}>
      {items.map(({ option, ...props }, index) => (
        <LocationSelectorItem
          {...otherProps}
          {...props}
          onSelect={onSelectHandle}
          key={`location-list__item--${index}${
            option && `--${option.id}--${option.type}`
          }${keyPrefix}`}
          option={option}
          selected={value}
          onMouseEnter={() => mouseEnterHandler(option)}
          onMouseLeave={onMouseLeave}
        />
      ))}
    </ul>
  );
};

export default LocationSelectorList;
