import {Service} from "@onlog-public/additional-services-types";

/**
 * isServiceInAdditionGroup возвращает статус, относится ли услуга
 * к дополнительным, выводимым внизу группы.
 * @param s
 */
const isServiceInAdditionGroup = (s: Service) => {
  switch (s.template) {
    case "cargoDeclaration":
      return s.settings.TemplateSettings.CargoDeclaration.IsAdditionService
    case "additionList":
      return s.settings.TemplateSettings.AdditionList.IsAdditionService
    case "verticalTable":
      return s.settings.TemplateSettings.VerticalTable.IsAdditionService
  }

  return true
}

export default isServiceInAdditionGroup