import { GraphQLQuery } from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

/**
 * Запрос получения терминала по переданному cписку родительских локаций ID
 */
export class TerminalInParentLocationIdQuery implements GraphQLQuery<{ id: string[] }> {
  readonly query: any;

  readonly variables: { id: string[] };

  constructor(parentLocationId: string[]) {
    this.variables = { id: parentLocationId };
    this.query = gql`
      query SearchTerminal($id: [ID]) {
        transport_terminal_list(where: { location_id: { _in: $id } }, limit: 100000) {
          id
          default_name
          location_id
          localized_names
          iso_localization: localized_abbreviations
          iso: symbol_code
          lat: latitude
          lng: longitude
        }
      }
    `;
  }
}
