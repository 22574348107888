import Box from '@material-ui/core/Box/Box';
import { styled } from '@mui/material';

const Container = styled(Box)`
  .wrapper-slider {
    width: calc(100% - 12px);
    margin: 0 6px;
  }

  .container-slider {
    display: flex;
    justify-content: space-between;

    .value {
      color: #000;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
    }
  }
`;

export default Container;
