import breakpoints from '@assets/theme/breakpoints';
import { styled } from '@mui/material/styles';

const OrderListStyledContainer = styled('section')`
  display: grid;
  gap: 20px;

  @media (max-width: ${breakpoints.values.xsm}px) {
    gap: 15px;
  }

  .close-button {
    position: absolute;
    top: 8px;
    right: 8px;
  }
`;

export default OrderListStyledContainer;

export const Container = styled('div')`
  position: relative;

  .close-button {
    position: absolute;
    top: 8px;
    right: 8px;
  }
`;
