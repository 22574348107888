import React, { ComponentType } from 'react';

import { RouteProductContext } from './index';
import { RouteProductContextData, WithRouteProductContextData } from './types';

/**
 * Компонент подключения контекста вывода информации об одном маршруте
 */
const WithRouteProductContext: {
  <Props extends RouteProductContextData>(
    Component: React.ComponentType<Props>
  ): React.ComponentType<Props>;
} = (Component) => (props) =>
  (
    <RouteProductContext.Consumer>
      {(routeBaseData) => {
        if (!routeBaseData) {
          return null;
        }

        return <Component {...routeBaseData} {...props} />;
      }}
    </RouteProductContext.Consumer>
  );

type WithRouteProductContextHOC = {
  <T>(Component: ComponentType<WithRouteProductContextData<T>>): ComponentType<T>;
};

export default WithRouteProductContext as WithRouteProductContextHOC;
