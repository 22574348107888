import '../assets/styles/styles.scss';

import MyRouting from '@context/useMyRouting';
import React from 'react';

import ThemeConfig from '../assets/theme';

function PointWidget() {
  return (
    <ThemeConfig>
      <MyRouting />
    </ThemeConfig>
  );
}

export default PointWidget;
