import {ServiceFieldType} from "@onlog-public/additional-services-types";
import {
  optionLoaderProcessor,
  OptionsCache,
} from "@pages/AdditionalServices/containers/services/optionsLoader/types";
import {taxLoader} from "@services/requests/taxLoader";
import {TaxData} from "@services/requests/taxLoader/TaxLoaderQuery";

/**
 * TaxProcessor реализует процессор для загрузки налогов
 */
export class TaxProcessor implements optionLoaderProcessor {
  /**
   * GetType возвращает тип поля, для которого собран процессор
   */
  GetType(): ServiceFieldType {
    return "tax"
  }

  /**
   * LoadOptionsCache выполняет загрузку кэша определенного типа
   * В качестве аргумента принимает тип поля и идентификаторы для загрузки.
   *
   * @param id
   */
  async LoadOptionsCache(id?: string[]): Promise<OptionsCache<any>> {
    const cache: OptionsCache<TaxData> = {isPartialLoading: !!id, cache: {}}
    const versions = await taxLoader().Load(id)
    versions.map(v => cache.cache[v.id] = v)

    return cache
  }
}