import { styled } from '@mui/material';

const ShoulderItemTableStyledContainer = styled('div')`
  font-size: 14px;
  max-width: 100%;
  overflow: hidden;

  .MuiDataGrid-columnHeaders {
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }

  .default-table-container {
    &--full-width {
      max-width: 100%;
    }

    &--no-padding {
      padding: 0;
    }

    &__header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 29px 25px 25px 20px;
      border-radius: 20px 20px 0 0;
    }
  }

  &:not(.default) {
    .MuiDataGrid-row,
    .MuiDataGrid-columnHeadersInner > div {
      width: 100% !important;
      display: grid !important;
      grid-template-columns:
        minmax(285px, 1fr)
        minmax(130px, 1fr)
        minmax(83px, 1fr)
        minmax(130px, 1fr)
        minmax(90px, 1fr)
        minmax(110px, 1fr)
        minmax(90px, 1fr)
        minmax(128px, 1fr)
        0 !important;
    }

    .MuiDataGrid-row .MuiDataGrid-cell {
      line-height: normal;
      width: unset !important;
      max-width: unset !important;
      min-width: unset !important;

      &:nth-of-type(n + 2) {
        padding: 10px 5px;
      }
    }

    .MuiDataGrid-root .MuiDataGrid-columnHeader {
      padding: 0 5px;
      width: unset !important;
      max-width: unset !important;
      min-width: unset !important;
      color: ${({ theme }) => theme.palette.grey[900]};
    }
  }

  .MuiDataGrid-row:last-child .MuiDataGrid-cell {
    border-bottom: 0;
  }
  .default-table-container__last-cell.MuiDataGrid-cell--withRightBorder {
    border-right: 0;
  }

  .MuiDataGrid-root .MuiDataGrid-columnHeader,
  .MuiDataGrid-root .MuiDataGrid-cell {
    padding: 10px 15px 10px 20px;
    justify-content: center;
    align-items: center;
  }

  .MuiDataGrid-root .MuiDataGrid-columnHeaders,
  .MuiDataGrid-root .MuiDataGrid-columnHeader {
    width: 100%;
    line-height: normal !important;
    border-radius: 0;
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeader > div {
    width: 100%;
  }

  .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:last-child {
    border-right: none !important;
  }
  .MuiDataGrid-root .MuiDataGrid-columnSeparator {
    display: none;
  }

  .MuiDataGrid-virtualScrollerRenderZone {
    width: 100% !important;
  }
  .MuiDataGrid-columnHeadersInner {
    width: 100%;
  }

  .default-table-container__last-cell.MuiDataGrid-cell--withRightBorder {
    border-right: 0;
  }

  .MuiDataGrid-virtualScrollerRenderZone {
    width: 100% !important;
  }
  .MuiDataGrid-columnHeadersInner {
    width: 100%;
  }
  &:not(.default) {
    .MuiDataGrid-columnHeadersInner > div {
      width: 100%;
      display: grid !important;
      grid-template-columns:
        minmax(285px, 1fr) minmax(130px, 1fr) minmax(83px, 1fr) minmax(130px, 1fr)
        minmax(90px, 1fr) minmax(110px, 1fr) minmax(90px, 1fr) minmax(128px, 1fr) 0 !important;
    }
  }
  .default-table-container__last-cell.MuiDataGrid-cell--withRightBorder {
    border-right: 0;
  }
  &:not(.default) {
    .MuiDataGrid-row {
      width: 100% !important;
      display: grid !important;
      grid-template-columns:
        minmax(285px, 1fr) minmax(130px, 1fr) minmax(83px, 1fr) minmax(130px, 1fr)
        minmax(90px, 1fr) minmax(110px, 1fr) minmax(90px, 1fr) minmax(128px, 1fr) 0 !important;
    }
  }

  .MuiDataGrid-root .MuiDataGrid-withBorderColor {
    .MuiDataGrid-cellContent {
      color: black;
    }
    .MuiDataGrid-root {
      border-radius: 0;
      border-bottom: 0;
    }
  }

  .MuiDataGrid-columnHeaderTitle {
    text-overflow: initial;
    white-space: normal;
    color: black;
    text-align: center;
  }
`;

export default ShoulderItemTableStyledContainer;
