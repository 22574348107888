import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AnchorIcon from '@mui/icons-material/Anchor';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import React from 'react';

import AirIcon from './AirIcon';
import BuildingIcon from './BuildingIcon';
import TrainIcon from './TrainIcon';

/**
 * Получение иконки по символьному коду
 * @param symbolCode
 * @param type
 */
export const getIconByType = (symbolCode: string, type: 'location' | 'terminal') => {
  if (type === 'location') {
    return <LocationOnIcon />;
  }

  switch (symbolCode.toUpperCase()) {
    case 'PRT':
      return <AnchorIcon />;
    case 'AIRP':
      return <AirIcon />;
    case 'RSTN':
      return <TrainIcon />;
    case 'NULLABLE':
      return <BuildingIcon />;
    case 'AUTO':
      return <LocalShippingIcon />;
    default:
      return <BuildingIcon />;
  }
};
