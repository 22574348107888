import React, {FC} from "react";
import {ServiceProps} from "@pages/AdditionalServices/components/Service/types";
import {useTranslation} from "react-i18next";
import getCostConfiguration from "@pages/AdditionalServices/components/Service/getCostConfiguration";
import CostColumnFields from "@pages/AdditionalServices/components/Service/CostColumnFields";

/**
 * CostColumn выводит колонку стоимости по настройкам шаблона
 * @param props
 * @constructor
 */
const CostColumn: FC<ServiceProps> = props => {
  const {
    service,
    globalFieldValues,
    servicesFieldValues,
    options,
  } = props
  const {t} = useTranslation('AdditionalService')
  const settings = getCostConfiguration(service)

  if (!settings) {
    return (
      <div className="a-service-template-costConfiguration center">-</div>
    )
  }

  const displayText = settings.DisplayText.length > 0 ? t(settings.DisplayText) : '-'
  if (!settings.IsNeedDisplayFieldValues) {
    return (
      <div className="a-service-template-costConfiguration center">
        {t(displayText)}
      </div>
    )
  }

  const pricePrefix = settings.PricePrefix.length > 0 ? t(settings.PricePrefix) : ""
  const price = servicesFieldValues[settings.PriceFieldCode] ?? globalFieldValues[settings.PriceFieldCode]
  const currencyID = servicesFieldValues[settings.CurrencyFieldCode] ?? globalFieldValues[settings.CurrencyFieldCode] ?? 0
  const currency = options[settings.CurrencyFieldCode]?.find(o => o.value === currencyID)

  if (price === undefined || !currency) {
    return (
      <div className="a-service-template-costConfiguration center">
        {t(displayText)}
      </div>
    )
  }

  if (!settings.IsPriceFieldsEditable) {
    return (
      <div className="a-service-template-costConfiguration center">
        {pricePrefix} {price.toLocaleString()} {currency.label}
      </div>
    )
  }

  return <CostColumnFields {...props} />
}

export default CostColumn