import {ServiceFieldType} from "@onlog-public/additional-services-types";
import {optionsGeneratorProcessor} from "@pages/AdditionalServices/containers/services/propertyOptionsGenerator/types";
import {SelectOption} from "../optionsGenerator/types";
import {OptionsCache} from "../optionsLoader/types";
import {BranchItem} from "@services/requests/routeCalculator/locationsBranchSearchService/interfaces";
import getLocalizationByArgs from "@helpers/getLocalizationByArgs";

/**
 * LocationProcessor реализует процессор генерации опций для
 * свойств услуг типа "Гео-объект"
 */
export class LocationProcessor implements optionsGeneratorProcessor {
  /**
   * getType возвращает тип справочника, для которого реализован генератор
   */
  getType(): ServiceFieldType {
    return "location"
  }

  /**
   * getOptions генерирует опции для вывода по переданным идентификаторам
   * и кэшу.
   * @param langID
   * @param items
   * @param _
   * @param cache
   */
  getOptions(
    langID: string,
    items: string[],
    _: string,
    cache: OptionsCache<any>,
  ): SelectOption[] {
    const locationsData: BranchItem[] = items
      .map(i => cache.cache[i])
      .filter(v => !!v)

    return locationsData.map(d => ({
      value: parseInt(d.id),
      label: getLocalizationByArgs(langID, d.defaultName ?? "", d.localizedNamesArray),
      description: d.subItems
        .sort((a, b) => a.depthLevel < b.depthLevel ? 1 : -1)
        .map(v => getLocalizationByArgs(langID, v.visibleName, v.localizedNamesArray))
        .join(", ")
      ,
      origin: d,
    }))
  }
}