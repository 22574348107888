import {GraphQLQuery} from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

/**
 * Полученная локация
 */
interface Location {
  id: string;
  default_name: string;
  parent: number | null;
  is_user_searchable: boolean;
  localized_names: string[];
  depth_level: number;
  iso: string;
  lat: number;
  lng: number;
}

/**
 * Результат выполнения запроса
 */
export interface BranchForLocationQueryResult {
  getLocationsWithParents: {
    location: Location;
  }[];
}

/**
 * Запрос получения ветки локации для переданной локации
 */
export class BranchForLocationQuery implements GraphQLQuery<{ id: string[] }> {
  readonly query: any;

  readonly variables: { id: string[] };

    constructor(locationIds: string[]) {
      this.variables = {id: locationIds};
      this.query = gql`
      query getLocationsWithParents($id: [ID]!) {
        getLocationsWithParents(locations: $id) {
          location {
            id
            default_name
            parent
            is_user_searchable
            localized_names
            depth_level
            iso: symbol_code
            lat: latitude
            lng: longitude
          }
          toLocations
        }
      }
    `;
    }
}
