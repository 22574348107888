import { getIconByType } from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/LocationIcon';
import { LocationSelectorItemOptionProps } from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/LocationSelector/components/LocationSelectorItem/types';
import {
  getLocationLocalizedNames,
  getLocationsPath,
} from '@services/requests/localizedMessagesService/helpers/getLocationsLocalizedPath';
import { isEqualsForMemo } from '@settings/services/types';
import clsx from 'clsx';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * контролируем перерендеринг
 * @param prevProps
 * @param nextProps
 */
const isEquals: isEqualsForMemo<LocationSelectorItemOptionProps> = (prevProps, nextProps) =>
  JSON.stringify([
    prevProps.option,
    prevProps.primaryLangId,
    prevProps.secondaryLangId,
    prevProps.selected,
  ]) ===
  JSON.stringify([
    nextProps.option,
    nextProps.primaryLangId,
    nextProps.secondaryLangId,
    nextProps.selected,
  ]);

// Компонент опции для выбора локации
const LocationSelectorItemOption = (props: LocationSelectorItemOptionProps) => {
  const { t } = useTranslation('LocationSelector');
  const { onSelect, option, selected, primaryLangId, secondaryLangId, onMouseEnter, onMouseLeave } =
    props;

  if (!option) {
    return <></>;
  }

  const names = getLocationLocalizedNames(option, primaryLangId, secondaryLangId);

  const getTextValue = () => {
    if (option.main) {
      return t(`LocationSelector:${option.visibleName}`);
    }

    if (option.isWarehouse) {
      return t(`LocationSelector:${option.visibleName}`);
    }

    if (option.symbolCode === 'ANY') {
      return t(`LocationSelector:${option.visibleName}`);
    }

    return `${names[primaryLangId]}`;
  };

  const path = getLocationsPath(option, primaryLangId);
  const name = getTextValue();
  const icon = getIconByType(option.iso, option.type);
  const onClick = () => onSelect(option);
  const isSelected = selected?.id === option.id && option.type === selected.type;

  return (
    <li
      className={clsx('location-selector__option', { '--is-selected': isSelected })}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-id={option.id}
    >
      {icon}
      <div className={'wrapper'}>
        <div className={'title'}>{name}</div>
        <div className={'country'}>{path}</div>
      </div>
    </li>
  );
};

export default memo(LocationSelectorItemOption, isEquals);
