import {useYaHit} from '@context/useYaHit';
import AdditionalForm from '@pages/AdditionalServices/tabs/AdditionalForm';
import DeliveryForm from '@pages/AdditionalServices/tabs/DeliveryForm';
import React from 'react';
import useWidgetTabsContext from "@pages/AdditionalServices/containers/widgetTabsContext";

interface TabPanelProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const FormWidget = () => {
  const {tabs} = useWidgetTabsContext()
  const activeTab = tabs.ActiveTabData.type === "delivery" ? 0 : 1

  useYaHit({});

  return (
    <div id={'my-global-portal-container'}>
      <CustomTabPanel value={activeTab} index={0}>
        <DeliveryForm/>
      </CustomTabPanel>
      <CustomTabPanel value={activeTab} index={1}>
        <AdditionalForm/>
      </CustomTabPanel>
    </div>
  );
};

export default FormWidget;
