import {ServiceFieldType} from "@onlog-public/additional-services-types";
import {optionsGeneratorProcessor} from "@pages/AdditionalServices/containers/services/propertyOptionsGenerator/types";
import {SelectOption} from "../optionsGenerator/types";
import {OptionsCache} from "../optionsLoader/types";
import {CurrencyData} from "@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery";

/**
 * CurrencyProcessor реализует процессор генерации опций для свойств
 * типа "Валюта".
 */
export class CurrencyProcessor implements optionsGeneratorProcessor {
  /**
   * getType возвращает тип справочника, для которого реализован генератор
   */
  getType(): ServiceFieldType {
    return "currency"
  }

  /**
   * getOptions генерирует опции для вывода по переданным идентификаторам
   * и кэшу.
   * @param _
   * @param items
   * @param __
   * @param cache
   */
  getOptions(_: string, items: string[], __: string, cache: OptionsCache<any>): SelectOption[] {
    const currencyData: CurrencyData[] = items
      .map(i => cache.cache[i])
      .filter(v => !!v)

    return currencyData.map(c => ({
      value: parseInt(c.id),
      label: c.code,
      origin: c,
    }))
  }
}