import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';

import { loggerFactory } from '../logger';
import { RequestClient } from './RequestClient';
import { GraphQLClient } from './GraphQLClient';
import { Link } from './Link';
import { MainClient } from './MainClient';
import { RetryClient } from './RetryClient';

export const graphQLClient: { (token?: string): GraphQLClient } = (token?: string) => {
  return new MainClient(
    new RetryClient(
      loggerFactory(),
      new RequestClient(
        new ApolloClient<NormalizedCacheObject>({
          link: ApolloLink.from([Link]),
          cache: new InMemoryCache({
            addTypename: false,
          }),
          defaultOptions: {
            watchQuery: {
              fetchPolicy: 'no-cache',
              errorPolicy: 'ignore',
            },
            query: {
              fetchPolicy: 'no-cache',
              errorPolicy: 'all',
            },
          },
        })
      )
    ),
    token
  );
};
