import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const QuestionIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M20.9298 19.2999L19.6941 15.7037C20.2884 14.5023 20.6028 13.1631 20.6049 11.8157C20.6084 9.49527 19.7107 7.30124 18.0768 5.63776C16.4427 3.97399 14.2651 3.03749 11.9453 3.00068C10.73 2.98117 9.55061 3.20512 8.43827 3.66556C7.3654 4.10965 6.40328 4.75298 5.57866 5.57764C4.754 6.40226 4.11067 7.36438 3.66658 8.43725C3.20607 9.54959 2.98237 10.7295 3.00163 11.9443C3.03837 14.2641 3.9749 16.4417 5.63863 18.0758C7.29885 19.7064 9.48726 20.6039 11.8027 20.6039C11.8072 20.6039 11.812 20.6039 11.8165 20.6039C13.164 20.6018 14.5031 20.2875 15.7046 19.6931L19.3008 20.9288C19.4391 20.9764 19.5809 20.9995 19.7211 20.9995C20.0542 20.9995 20.3781 20.8686 20.6238 20.6229C20.973 20.2738 21.0902 19.7669 20.9298 19.2999ZM11.716 16.8196C11.4169 16.8196 11.1954 16.575 11.1824 16.2859C11.1695 15.9978 11.4369 15.7523 11.716 15.7523C12.0152 15.7523 12.2367 15.9969 12.2496 16.2859C12.2626 16.5741 11.9951 16.8196 11.716 16.8196ZM12.2496 12.7438V14.4404C12.2496 14.7351 12.0107 14.974 11.716 14.974C11.4213 14.974 11.1824 14.7351 11.1824 14.4404V12.2641C11.1824 11.9694 11.4213 11.7305 11.716 11.7305C12.5914 11.7305 13.3036 11.0182 13.3036 10.1429C13.3036 9.26746 12.5914 8.55523 11.716 8.55523C10.8406 8.55523 10.1284 9.26746 10.1284 10.1429C10.1284 10.4375 9.88948 10.6765 9.5948 10.6765C9.30012 10.6765 9.0612 10.4375 9.0612 10.1429C9.0612 8.67902 10.2522 7.48803 11.716 7.48803C13.1799 7.48803 14.3708 8.67898 14.3708 10.1429C14.3708 11.424 13.4587 12.4961 12.2496 12.7438Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default QuestionIcon;
