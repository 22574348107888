import {ServiceFieldType, ServiceField} from "@onlog-public/additional-services-types";
import {
  optionsGeneratorProcessor,
  SelectOption
} from "@pages/AdditionalServices/containers/services/optionsGenerator/types";
import {OptionsCache} from "../optionsLoader/types";
import {TaxData} from "@services/requests/taxLoader/TaxLoaderQuery";

/**
 * TaxProcessor реализует процессор генерации опций налогов
 */
export class TaxProcessor implements optionsGeneratorProcessor {
  /**
   * getType возвращает тип поля, для которого реализован процессор
   */
  getType(): ServiceFieldType {
    return "tax"
  }

  /**
   * getOptions генерирует опции для селектора поля дополнительных услуг
   * @param field
   * @param _
   * @param cache
   */
  getOptions(field: ServiceField, _: string, cache: OptionsCache<any>): SelectOption[] {
    const taxData: TaxData[] = field.handbook_limitations.length > 0
      ? field.handbook_limitations.map(id => cache.cache[id])
        .filter(v => !!v)
      : Object.values(cache.cache)

    return taxData.map(c => ({
      value: parseInt(c.id),
      label: c.default_name,
    }))
  }
}