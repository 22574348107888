import { SxProps } from '@mui/system/styleFunctionSx';
import React from 'react';

import Container from './StyledComponents';

interface Props {
  children: React.ReactNode;
  sx?: SxProps;
  className?: string;
}

// TODO сделать самостоятельным компонентом
const CardWrapper = (props: Props) => {
  const { sx = {}, className = '' } = props;

  return (
    <Container className={className} sx={sx}>
      {props.children}
    </Container>
  );
};

export default CardWrapper;
