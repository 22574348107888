import { BranchItem } from '@services/requests/routeCalculator/locationsBranchSearchService/interfaces';

interface GetLocationValidationProps {
  location: BranchItem;
  isTouched: boolean;
  isOpen: boolean;
  value: string;
}

export function getLocationValidation({
  isOpen,
  isTouched,
  location,
  value,
}: GetLocationValidationProps) {
  const isCityNotSelected = location === undefined;
  const isTerminalNotSelected = !!location && location.type !== 'terminal';
  const isWarehouseNotSelected =
    !!location && location.type === 'location' && !location.isWarehouse;
  const isCarrierTerminalNotSelected =
    !!location && location.type === 'location' && location.isWarehouse === false;
  const isError =
    isTouched &&
    !!value.length &&
    (isCityNotSelected ||
      (isTerminalNotSelected && isWarehouseNotSelected && isCarrierTerminalNotSelected && !isOpen));

  return {
    isCityNotSelected,
    isTerminalNotSelected,
    isError,
  };
}
