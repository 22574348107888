// ----------------------------------------------------------------------

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    xsm: 900,
    md: 1080,
    lg: 1280,
    miniLaptop: 1439,
    laptop: 1600,
    xl: 1920,
  },
};

export default breakpoints;
