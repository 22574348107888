import React, {FC, useState} from "react";
import {ServiceProps} from "@pages/AdditionalServices/components/Service/types";
import {useTranslation} from "react-i18next";
import GradeIcon from '@material-ui/icons/Grade';
import getLocalizationByArgs from "@helpers/getLocalizationByArgs";
import ServicePriceColumn
  from "@pages/AdditionalServices/components/Service/ServiceInListingTemplate/ServicePriceColumn";
import ServiceFieldsList from "@pages/AdditionalServices/components/Service/ServiceFieldsList";
import ServicePropertyValue
  from "@pages/AdditionalServices/components/Service/ServiceInListingTemplate/ServicePropertyValue";
import {Collapse} from "@mui/material";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {ServiceFileData} from "@onlog-public/additional-services-types";
import FileList from "@pages/AdditionalServices/components/Service/FileList";
import restoreServiceData from "@pages/AdditionalServices/containers/methods/basket/restoreServiceData";
import clsx from "clsx";

const ServiceInListingTemplate: FC<ServiceProps> = props => {
  const {
    langID,
    currencyID,
    service,
    globalFiles,
    serviceCalculations,
    serviceDataCache,
    properties,
    propertyOptions,
    order,
    onAddToCart,
    isOrderDisplay,
  } = props

  const {t} = useTranslation('AdditionalService')
  const name = getLocalizationByArgs(langID, service.name, service.localized_names)
  const description = getLocalizationByArgs(langID, service.description, service.localized_descriptions)

  const [isExpanded, setIsExpanded] = useState(false)
  const showBtn = t('AdditionalService:Templates.serviceInList.collapse')
  const hideBtn = t('AdditionalService:Templates.serviceInList.expand')

  const files: { [K in string]: ServiceFileData } = {}
  Array.from([...globalFiles, ...service.file_id]).map(f => files[f.id] = f)
  const filesToDisplay = Object.values(files).sort(
    (a, b) => a.name_original > b.name_original ? 1 : -1
  )

  const visibleProperties = properties
    .filter(p => !p.settings.Visuality.IsShowValueInDescription)
    .sort((a, b) => {
      const aPriority = a.settings.Visuality.PropertyPriority
      const bPriority = b.settings.Visuality.PropertyPriority

      return aPriority > bPriority ? 1 : -1
    })
  const descriptionProperties = properties
    .filter(p => p.settings.Visuality.IsShowValueInDescription)
    .sort((a, b) => {
      const aPriority = a.settings.Visuality.PropertyPriority
      const bPriority = b.settings.Visuality.PropertyPriority

      return aPriority > bPriority ? 1 : -1
    })

  const settings = service.settings.TemplateSettings.ServiceInList

  const orderProducts = [...order?.products ?? [], ...order?.pre_order_products ?? []]
      ?.map(p => restoreServiceData(p)).filter(d => !!d)
    ?? []

  const existProduct = orderProducts.find(p => {
    return p.setUpService.ServiceData.id === service.id
  })

  const orderButtonText = !!existProduct || isOrderDisplay
    ? t('AdditionalService:Templates.serviceInList.alreadyInOrder')
    : t('AdditionalService:Templates.serviceInList.addToOrder')

  const [isAddToOrderClicked, setIsAddToOrderClicked] = useState(false)
  const calculatedPrice = serviceCalculations.find(s => s.serviceId === service.id)

  return (
    <div className="a-service-template-serviceInList">
      <div className="a-service-template-serviceInList-buttons">
        <div
          className={clsx("a-service-template-serviceInList-buttons-button", "prime", {
            "in-order": !!existProduct || isOrderDisplay,
            "loading": isAddToOrderClicked,
          })}
          onClick={() => {
            if (isAddToOrderClicked || !!existProduct || !calculatedPrice || isOrderDisplay) {
              return
            }

            setIsAddToOrderClicked(true)
            onAddToCart([calculatedPrice])
              .catch(() => null)
              .finally(() => {
                setIsAddToOrderClicked(false)
              })
          }}
        >
          {orderButtonText}
        </div>
        <div
          className="a-service-template-serviceInList-buttons-button disabled"
          onClick={() => null}
        >
          {t('AdditionalService:Templates.serviceInList.print')}
        </div>
        <div
          className="a-service-template-serviceInList-buttons-button no-border disabled"
          onClick={() => null}
        >
          <GradeIcon/>
          {t('AdditionalService:Templates.serviceInList.toFavourites')}
        </div>
      </div>
      <div className="a-service-template-serviceInList-content">
        <div className="title">{name}</div>
        <div className="field">
          <ServiceFieldsList {...props} />
        </div>
        <ServicePriceColumn
          settings={settings}
          priceByRequestText={service.settings.PriceByRequestText}
          currency={serviceDataCache.currency.cache[currencyID]}
          services={serviceCalculations}
        />
        {visibleProperties.map(p => (
          <ServicePropertyValue
            key={p.id}
            langID={langID}
            property={p}
            service={service}
            propertyOptions={propertyOptions}
          />
        ))}
      </div>
      <Collapse in={isExpanded}>
        <div className="a-service-template-serviceInList-description">
          {descriptionProperties.map(p => (
            <ServicePropertyValue
              key={p.id}
              langID={langID}
              property={p}
              service={service}
              propertyOptions={propertyOptions}
            />
          ))}
          {description.length > 0 && (
            <div className="description-container a-html-content" dangerouslySetInnerHTML={{__html: description}}/>
          )}
          {filesToDisplay.length > 0 && (
            <div className="files-container">
              <FileList files={filesToDisplay}/>
            </div>
          )}
        </div>
      </Collapse>
      <div
        className="a-service-template-serviceInList-collapse"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div>{isExpanded ? showBtn : hideBtn}</div>
        {isExpanded && (
          <ExpandLessIcon/>
        )}
        {!isExpanded && (
          <ExpandMoreIcon/>
        )}
      </div>
    </div>
  )
}

export default ServiceInListingTemplate