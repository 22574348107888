import breakpoints from '@assets/theme/breakpoints';
import palette from '@assets/theme/palette';
import Box from '@material-ui/core/Box/Box';
import { styled } from '@mui/material';

export const MAPSETTINGS = {
  width: `430px`,
  height: `309px`,
};

const shadow = 'rgba(0, 5, 115, 0.07)';

const Container = styled(Box)`
  .location-selector {
    &__list-wrapper {
      max-height: ${MAPSETTINGS.height};
      overflow: hidden;
      padding: 0;
      display: flex;
      flex-direction: column;

      &--empty {
        justify-content: center;
      }

      &--blocked {
        &::after {
          background-color: white;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          opacity: 0.8;
          content: '';
          cursor: not-allowed;
        }
      }
    }

    &__cluster-list {
      position: absolute;
      width: 368px;
      height: 100%;
      z-index: 7;
    }

    &__cluster-overlay {
      background-color: white;
      position: absolute;
      opacity: 0.9;
      width: 368px;
      height: 100%;
      z-index: 6;
    }
  }

  .terminal-location-paper {
    display: grid;
    grid-template-columns: 100%;
    position: absolute;
    z-index: 5;
    height: ${MAPSETTINGS.height};
    width: 100%;
    overflow: hidden;
    box-shadow: 0 0 20px 0 ${shadow};

    &.--second-step {
      grid-template-columns: 320px 368px;
      width: unset;

      @media (max-width: ${breakpoints.values.md}px) {
        width: 100%;
        grid-template-columns: 1fr;
      }
    }

    @media (max-width: ${breakpoints.values.md}px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .loader {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 40px 0;
    }

    .description {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 20px 0;
    }
  }

  .location-selector {
    &__list-map {
      position: relative;
      overflow: hidden;
      border-radius: 0 7px 7px 0;
    }
  }

  &.--is-error {
    border: 1px solid red;
  }

  &.--is-reverse {
    .terminal-location-paper {
      width: 100%;
      right: 0;

      @media (max-width: ${breakpoints.values.sm}px) {
        left: 0;
      }

      &.--second-step {
        grid-template-columns: 368px 320px;
        width: unset;

        @media (max-width: ${breakpoints.values.md}px) {
          width: 100%;
          grid-template-columns: 1fr;
        }
      }

      @media (max-width: ${breakpoints.values.md}px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .location-selector {
      &__list-map {
        order: -1;
        overflow: hidden;
        border-radius: 7px 0 0 7px;
      }
    }
  }

  .no-result {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .subtitle {
    border-radius: 4px;
    background: #f1f1f1;
    display: grid;

    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    grid-template-columns: 1fr !important;
    padding: 4px 30px !important;

    pointer-events: none;

    &:hover {
      background-color: #f1f1f1;
    }
  }

  .location-list {
    max-height: 100%;
    overflow: auto;
    padding: 10px 5px 10px 7px;

    &::-webkit-scrollbar {
      width: 10px;
      padding: 10px 2px;

      &-track {
        background-color: #edf5fd;
      }

      &-thumb {
        border-radius: 6px;
        background-color: ${({ theme }) => theme.palette.grey[200]};
        border: 2px solid #edf5fd;
        background-clip: content-box;
      }
    }

    li {
      display: grid;
      grid-template-columns: 20px 1fr;
      padding: 8px 14px 8px;
      gap: 10px;
      align-items: center;
      cursor: pointer;
      transition: 400ms;
      border-radius: 4px;
      direction: ltr;
      text-align: left;

      svg {
        font-size: 20px;
        color: #b8bfcd;
      }

      .wrapper {
        display: flex;
        flex-direction: column;

        .title {
          margin-bottom: 5px;
          font-size: 14px;
          line-height: 16px;
          font-style: normal;
          font-weight: 500;
          transition: color 400ms;
          display: flex;
          align-items: center;
          color: ${({ theme }) => theme.palette.grey[900]};
        }

        .country {
          display: flex;
          align-items: center;
          font-size: 11px;
          line-height: 11px;
          font-style: normal;
          font-weight: 500;
          transition: color 400ms;
          color: #9198a7;
        }
      }

      &:hover:not(.subtitle) {
        background-color: ${({ theme }) => theme.palette.grey[100]};

        .title {
          color: ${palette.light.blue[400]};
        }

        .country {
          color: ${({ theme }) => theme.palette.grey[300]};
        }
      }
    }

    .--is-selected {
      background: ${({ theme }) => theme.palette.grey[100]};

      .title {
        color: ${palette.light.blue[400]};
      }
    }

    .subtitle {
      margin-bottom: 8px;
      &:nth-of-type(n + 2) {
        margin-top: 8px;
      }
    }
  }
`;

export default Container;
