import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
    <path
      d="M14.5028 3.00589C14.2004 1.26991 12.7 0.00248089 10.9444 0H8.05556C6.30001 0.00248089 4.79962 1.26991 4.49717 3.00589C3.60508 3.2983 3.00112 4.133 3 5.07506V12.3251C3 13.5264 3.97006 14.5002 5.16667 14.5002H5.44255L4.52026 16.3511L5.81231 17L7.05743 14.5002H11.9426L13.1877 16.9993L14.4797 16.3504L13.5575 14.5002H13.8333C15.0299 14.5002 16 13.5264 16 12.3251V5.07506C15.9989 4.133 15.3949 3.2983 14.5028 3.00589ZM10.9444 2.90003H12.3889V4.35006H10.9444V2.90003ZM6.6111 2.90003H8.05556V4.35006H6.6111V2.90003ZM6.6111 12.3252C6.21223 12.3252 5.88889 12.0006 5.88889 11.6002C5.88889 11.1998 6.21223 10.8752 6.6111 10.8752C7.00997 10.8752 7.33331 11.1998 7.33331 11.6002C7.33331 12.0006 7.00997 12.3252 6.6111 12.3252ZM8.77777 9.42512H5.88889V5.80009H8.77777V9.42512ZM8.77777 2.17503H10.2222V4.35006H8.77777V2.17503ZM11.6667 12.3252C11.2678 12.3252 10.9444 12.0006 10.9444 11.6002C10.9444 11.1998 11.2678 10.8752 11.6667 10.8752C12.0655 10.8752 12.3889 11.1998 12.3889 11.6002C12.3889 12.0006 12.0655 12.3252 11.6667 12.3252ZM13.1111 9.42512H10.2222V5.80009H13.1111V9.42512Z"
      fill="#B9C0CE"
    />
  </svg>
);
