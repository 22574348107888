import { useYaHit } from '@context/useYaHit';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import React from 'react';
import { useTranslation } from 'react-i18next';


const RoutesCalculationLoader = () => {
  const { t } = useTranslation(['RoutesListComponent', 'PageNames']);

  useYaHit({});

  return (
    <>
      <LinearProgress />
      <Alert color={'info'} severity={'info'}>
        <AlertTitle>{t('RoutesListComponent:routesList.isProcess_1')}</AlertTitle>
        {t('RoutesListComponent:routesList.isProcess_2')}
      </Alert>
    </>
  );
};

export { RoutesCalculationLoader };
