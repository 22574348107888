import {ServiceFieldType} from "@onlog-public/additional-services-types";
import {
  optionLoaderProcessor,
  OptionsCache,
} from "@pages/AdditionalServices/containers/services/optionsLoader/types";
import {BranchItem} from "@services/requests/routeCalculator/locationsBranchSearchService/interfaces";
import {locationsBranchService} from "@services/requests/routeCalculator/locationsBranchSearchService";

/**
 * LocationProcessor реализует процессор для загрузки локаций
 */
export class LocationProcessor implements optionLoaderProcessor {
  /**
   * GetType возвращает тип поля, для которого собран процессор
   */
  GetType(): ServiceFieldType {
    return "location"
  }

  /**
   * LoadOptionsCache выполняет загрузку кэша определенного типа
   * В качестве аргумента принимает тип поля и идентификаторы для загрузки.
   *
   * @param id
   */
  async LoadOptionsCache(id?: string[]): Promise<OptionsCache<any>> {
    if (!id) {
      return {isPartialLoading: true, cache: {}}
    }

    const cache: OptionsCache<BranchItem> = {isPartialLoading: true, cache: {}}
    const versions = await locationsBranchService().GetItemsByValue(id, "location")
    versions.map(v => cache.cache[v.id] = v)

    return cache
  }
}