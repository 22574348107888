import ActiveMarker from '@components/icons/ActiveMarker';
import SimpleMarker from '@components/icons/SimpleMarker';
import {
  Clusterer,
  FullscreenControl,
  Map,
  Placemark,
  YMaps,
  ZoomControl,
} from '@pbe/react-yandex-maps';
import type { BranchItem } from '@services/requests/routeCalculator/locationsBranchSearchService/interfaces';
import getEnv from '@settings/getEnv';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Y_MAP_LANGS } from './constants';
import LocationSelectorList, { LocationSelectorListProps } from './LocationSelectorList';

// пропсы компонента вывода точек на карте
export type LocationSelectorListMap = LocationSelectorListProps;
interface Coords {
  lat: number;
  lng: number;
}
const mapSize = {
  width: '368px',
  height: '100%',
};

const getTruncedCoord = (coord: number) => +coord.toString().match(/\d+\.\d{4}/g)?.[0] || 0;

const MAX_ZOOM_NUMBER = 21;
/**
 * Компонент вывода карты с маркерами терминалов
 */
const LocationsYMap = (props: LocationSelectorListMap) => {
  const map = useRef(null);
  const clusterer = useRef<any>(null);
  const {
    items,
    onSelect,
    value,
    hoveredItem,
    onMouseEnter,
    onMouseLeave,
    primaryLangId,
    secondaryLangId,
    onListBlockChange,
  } = props;

  const [hoveredPoint, setHoveredPoint] = useState<BranchItem | null>(null);
  const [selecterClusterCoords, setSelecterClusterCoords] = useState<Coords | null>(null);

  const [_, lang] =
    Object.entries(Y_MAP_LANGS).find(([key]) => key === primaryLangId) ||
    (['default', Y_MAP_LANGS.default] as any);

  const center = useRef<number[]>([55.751574, 37.573856]);
  const zoom = useRef<number>(1);

  const { REACT_APP_YANDEX_MAP_API_KEY } = getEnv();

  const locations = items
    .filter((i) => !!i.option)
    .filter((i) => i.type !== 'subtitle')
    .filter((i) => i.option?.coords?.lng !== 0 && i.option?.coords?.lat !== 0)
    .map((i) => i.option)
    .reduce((acc, item) => {
      const hasDuplicate = acc.find((e) => e.id === item.id);
      if (hasDuplicate === undefined) {
        acc.push(item);
      }
      return acc;
    }, []);

  /**
   * При изменении hoveredItem
   * центрируем карту по hoveredItem.coords
   */
  useEffect(() => {
    if (hoveredItem) {
      center.current = [hoveredItem.coords.lat, hoveredItem.coords.lng];
      zoom.current = 10;
    }

    if (map.current) {
      map.current.panTo([center.current]);
    }
  }, [hoveredItem]);

  useEffect(() => {
    const location = locations.find((l) => l.type === 'location' && l.main);
    if (!location || !location.coords) {
      return;
    }

    zoom.current = 7;
    center.current = [location.coords.lat, location.coords.lng];
  }, [items]);

  useEffect(() => {
    if (map.current) {
      map.current.setZoom(zoom.current, { duration: 300 });
    }
  }, [zoom.current]);

  useEffect(() => {
    if (map.current && value) {
      map.current.panTo([value.coords]);
      map.current.setZoom(7);
    }
  }, [value]);

  const filteredLocationsByCluster = useMemo(() => {
    if (!selecterClusterCoords) {
      return [];
    }

    const { lat, lng } = selecterClusterCoords;

    return locations.filter((location) => {
      const truncedLat: number = getTruncedCoord(location.coords.lat);
      const truncedLng: number = getTruncedCoord(location.coords.lng);

      return truncedLat === getTruncedCoord(lat) && truncedLng === getTruncedCoord(lng);
    });
  }, [locations]);

  useEffect(() => {
    if (!!filteredLocationsByCluster.length) {
      onListBlockChange(true);
    }
  }, [filteredLocationsByCluster]);

  const mappedLocations = filteredLocationsByCluster.map((location) => ({
    type: location.type,
    option: location,
    defaultOption: location,
  }));

  return (
    <>
      {!!filteredLocationsByCluster.length && (
        <>
          <div className="location-selector__cluster-overlay"></div>
          <div className="location-selector__cluster-list">
            <LocationSelectorList
              items={mappedLocations}
              onSelect={onSelect}
              primaryLangId={primaryLangId}
              secondaryLangId={secondaryLangId}
              value={value}
              onMouseEnter={() => {}}
              onMouseLeave={() => {}}
              keyPrefix="cluster"
            />
          </div>
        </>
      )}
      <YMaps query={{ lang: lang, apikey: REACT_APP_YANDEX_MAP_API_KEY }}>
        <Map
          instanceRef={map}
          defaultState={{
            center: center.current,
            zoom: zoom.current,
          }}
          state={{
            center: center.current,
            zoom: zoom.current,
          }}
          options={{
            restrictMapArea: true,
            autoFitToViewport: 'always',
          }}
          width={mapSize.width}
          height={mapSize.height}
        >
          <ZoomControl />
          <FullscreenControl />
          <Clusterer
            instanceRef={clusterer}
            options={{
              preset: 'islands#invertedVioletClusterIcons',
              groupByCoordinates: false,
              gridSize: 32,
            }}
            onClick={() => {
              const currentZoom = map.current.getZoom();
              const clusters = clusterer.current.getClusters();

              if (currentZoom >= MAX_ZOOM_NUMBER && clusters.length === 1) {
                const [lat, lng] = clusters[0].geometry._coordinates;

                setSelecterClusterCoords({ lat, lng });
              }
            }}
          >
            {locations
              .filter((l) => l?.coords)
              .map((location) => {
                if (!location) {
                  return null;
                }

                if (!location.coords) {
                  return null;
                }

                let url: string = SimpleMarker;

                if (value && value.id === location.id) {
                  url = ActiveMarker;
                } else if (
                  (hoveredItem && hoveredItem.id === location.id) ||
                  (hoveredPoint && hoveredPoint.id === location.id)
                ) {
                  url = ActiveMarker;
                }

                return (
                  <Placemark
                    options={{
                      iconLayout: 'default#image',
                      iconImageHref: url,
                    }}
                    key={`location-marker--${location.id}--${location.type}`}
                    geometry={[location.coords.lat, location.coords.lng]}
                    onClick={(e) => {
                      e.stopPropagation();
                      onSelect(location, false);
                    }}
                    onMouseEnter={() => {
                      if (onMouseEnter) {
                        onMouseEnter(location);
                        setHoveredPoint(location);
                      }
                    }}
                    onMouseLeave={() => {
                      setHoveredPoint(null);
                      onMouseLeave(location);
                    }}
                  />
                );
              })}
          </Clusterer>
        </Map>
      </YMaps>
    </>
  );
};

export default LocationsYMap;
