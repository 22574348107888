import useBaseDataContext from '@context/useBaseDataContext';
import { useBitrix } from '@context/useBitrixUser/useBitrix';
import useMyRouting from '@context/useMyRouting/useMyRouting';
import { useYaMetrik } from '@context/useYaMetrik';
import useAdditionServicesContext from '@pages/AdditionalServices/containers/additionServicesContext';
import useWidgetTabsContext from '@pages/AdditionalServices/containers/widgetTabsContext';
import useDelivery from '@pages/AdditionalServices/tabs/DeliveryForm/useDelivery';
import useBasket from '@pages/Basket/useBasket';
import React, { useEffect, useState } from 'react';

import { useBasketCounter } from './Basket/hooks/useBasketCounter';

const InitWidget = () => {
  const [isInit, setIsInit] = useState(false);

  const [settings] = useMyRouting().useSettings().useState();
  const initBaseData = useBaseDataContext().actions.init;
  const initBasket = useBasket().actions.init;
  const initDelivery = useDelivery().actions.init;
  const [page] = useMyRouting().usePage().useState();
  const initYaCounterId = useYaMetrik().actions.init;
  const initLocalization = useMyRouting().actions.init;
  const { initialization } = useAdditionServicesContext();
  const { init: initTabs } = useWidgetTabsContext();
  const initBitrix = useBitrix().actions.init;

  useBasketCounter();

  const isDisableRoutesCalculation = page === 'basket';

  const isSettingsNotInited = Object.values(settings).some((setting) => !setting);

  useEffect(() => {
    initYaCounterId();
    initLocalization();
  }, []);

  useEffect(() => {
    if (!isInit && !isSettingsNotInited) {
      setIsInit(true);

      initBitrix();
      initBasket(settings.primaryLanguageId, settings.currencyId);
      initDelivery(
        settings.primaryLanguageId,
        settings.secondaryLanguageId,
        settings.currencyId,
        isDisableRoutesCalculation
      );
      initBaseData(settings.primaryLanguageId, settings.secondaryLanguageId, settings.currencyId);
      initTabs();
      initialization(settings.primaryLanguageId, settings.currencyId);
    }
  }, [settings, isSettingsNotInited]);

  return <></>;
};

export default InitWidget;
