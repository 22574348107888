import { $error } from '@settings/errorContext';
import { graphQLClient } from '@settings/services/graphQLClient';
import { GraphQLClient } from '@settings/services/graphQLClient/GraphQLClient';
import { loggerFactory } from '@settings/services/logger';
import { Logger } from '@settings/services/logger/Logger';

import {
  OrderProduct,
  _OrderProductLoaderProcessorInterface,
  _OrderProductLoaderResponse,
} from './interface';
import { PreOrderProductLoaderProcessorQuery } from './PreOrderProductLoaderProcessorQuery';

// Процессора загрузки товаров пред заказов
export class PreOrderProductLoaderProcessor implements _OrderProductLoaderProcessorInterface {
  private readonly client: GraphQLClient;

  private readonly logger: Logger;

  /**
   * Конструктор процессора
   */
  constructor() {
    this.client = graphQLClient();
    this.logger = loggerFactory().make('PreOrderProductLoaderProcessor');
  }

  /**
   * Проверка доступности процессора
   * @param type
   */
  IsAvailable(type: 'order' | 'preOrder'): boolean {
    return type === 'preOrder';
  }

  /**
   * Загрузка товаров заказа
   * @param orderId
   */
  async Load(orderId: string): Promise<OrderProduct[]> {
    try {
      const resp = await this.client.Query<{ id: string }, _OrderProductLoaderResponse>(
        new PreOrderProductLoaderProcessorQuery(orderId),
        {}
      );

      this.logger.Debug(`Loaded pre order products data`, resp);
      if ((resp?.data || []).length === 0) {
        return [];
      }

      return resp.data;
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }
}
