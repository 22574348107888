import {
  optionLoaderProcessor,
  OptionsCache,
} from "@pages/AdditionalServices/containers/services/optionsLoader/types";
import {ServiceFieldType} from "@onlog-public/additional-services-types";
import {ContractorData} from "@services/requests/searchLoaders/contractorLoader/queries/ContractorLoaderQuery";
import {contractorLoader} from "@services/requests/searchLoaders/contractorLoader";

/**
 * ContractorProcessor реализует универсальный процессор для загрузки данных
 * полей для подрядчиков.
 */
export class ContractorProcessor implements optionLoaderProcessor {
  protected type: ServiceFieldType;
  protected processingDivision: string;

  /**
   * Конструктор процессора
   * @param type
   * @param processingDivision
   */
  constructor(type: ServiceFieldType, processingDivision: string) {
    this.type = type
    this.processingDivision = processingDivision
  }

  /**
   * GetType возвращает тип поля, для которого собран процессор
   */
  GetType(): ServiceFieldType {
    return this.type
  }

  /**
   * LoadOptionsCache выполняет загрузку кэша определенного типа
   * В качестве аргумента принимает тип поля и идентификаторы для загрузки.
   *
   * @param id
   */
  async LoadOptionsCache(id?: string[]): Promise<OptionsCache<any>> {
    const cache: OptionsCache<ContractorData> = {isPartialLoading: !!id, cache: {}}
    const versions = await contractorLoader()
      .LoadDivisionContractorsByID(this.processingDivision, id)

    versions.map(v => cache.cache[v.id] = v)

    return cache
  }
}