import {ServicesProperty} from "@onlog-public/additional-services-types";
import {
  PropertyFilterGenerator,
  propertyFilterGeneratorProcessor
} from "@pages/AdditionalServices/containers/services/propertyFilterGenerator/types";
import {FilterPropertyData} from "../../types";
import {SelectOption} from "../optionsGenerator/types";

/**
 * BasePropertyFilterGenerator реализует базовый сервис генерации
 * параметров фильтрации по свойствам услуг.
 */
export class BasePropertyFilterGenerator implements PropertyFilterGenerator {
  protected processors: propertyFilterGeneratorProcessor<any>[]

  /**
   * Конструктор сервиса
   * @param processors
   */
  constructor(processors: propertyFilterGeneratorProcessor<any>[]) {
    this.processors = processors
  }

  /**
   * Выполняет генерацию параметров фильтра по переданным данным.
   * Внутри используется строгая типизация для каждого типа свойства.
   * @param property
   * @param propertyValues
   * @param propertyOptions
   */
  Generate(
    property: ServicesProperty,
    propertyValues: number[],
    propertyOptions: SelectOption[],
  ): FilterPropertyData<any> {
    const processor = this.processors.find(p => p.getType() === property.type)
    if (!processor) {
      throw new Error(`Unknown property type for filter generation`)
    }

    return {
      IsFilterAvailable: processor.isFilterAvailable(property, propertyValues, propertyOptions),
      FilterData: processor.generate(property, propertyValues, propertyOptions),
      Property: property,
      PropertyType: property.type
    }
  }
}