import { Theme } from '@material-ui/core/styles';

export default function SvgIcon(theme: Theme) {
  return {
    MuiSvgIcon: {
      defaultProps: {
        container: document.getElementById('onlog-widget-container'),
      },
      styleOverrides: {
        root: {
          color: theme.palette.grey[400],
        },
        fontSizeSmall: {
          width: 20,
          height: 20,
          fontSize: 'inherit',
        },
        fontSizeLarge: {
          width: 32,
          height: 32,
          fontSize: 'inherit',
        },
      },
    },
  };
}
