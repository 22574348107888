import {BehaviorSubject, Subject} from "rxjs";
import {AdditionServicesState} from "@pages/AdditionalServices/containers/types";
import {servicesServicesService} from "@services/requests/servicesService/servicesServicesService";
import {
  Service,
  ServiceField,
  ServicesProperty
} from "@onlog-public/additional-services-types";
import {
  ServicesServicePriceCalculationMutationProps
} from "@services/requests/servicesService/servicesServicesService/interface";
import {calculateFieldValues} from "@pages/AdditionalServices/containers/methods/calculateFieldValues";
import {makeOptionsForFields} from "@pages/AdditionalServices/containers/additionServicesOptionsContext";
import {loadServiceDataCache} from "@pages/AdditionalServices/containers/methods/loadServiceDataCache";
import {
  loadServicePropertyValueCache
} from "@pages/AdditionalServices/containers/methods/loadServicePropertyValueCache";
import makeFilterAndOrderData from "@pages/AdditionalServices/containers/methods/makeFilterAndOrderData";
import {v4} from "uuid";
import serviceInitialStateGenerator from "@pages/AdditionalServices/containers/services/serviceInitialStateGenerator";
import getServiceIncludeState from "@pages/AdditionalServices/containers/methods/getServiceIncludeState";

/**
 * serviceLoadingSubscriber содержит реализацию подписки на загрузку
 * услуг для разделов. Автоматически формирует список разделов для
 * загрузки на основе вариантов, а так же выполняет загрузку данных.
 * После загрузки услуг генерируются все необходимые стейты для полей.
 * После генерации всех стейтов запускается автоматический пересчет
 * стоимости услуги.
 *
 * @param serviceContext$
 * @param serviceCalculationBus$
 */
export const serviceLoadingSubscriber = (
  serviceContext$: BehaviorSubject<AdditionServicesState>,
  serviceCalculationBus$: Subject<{ isFieldChanges: boolean, isNeedChooseVariant: boolean }>
) => async (
  buffer: boolean[]
) => {
  if (buffer.length === 0) {
    return
  }

  const state = serviceContext$.getValue()
  if (!state.LastServiceInPath || !state.IsNeedCalculateServices) {
    return
  }

  let servicesToLoad = [state.LastServiceInPath]
  if (state.LastServiceInPath.is_variants) {
    servicesToLoad = [...state.LastServiceInPath.children]
  }

  const loadedServices = await Promise.all(servicesToLoad.map(async s => ({
    directoryID: s.id,
    services: await servicesServicesService().LoadServiceByDirectoryID(parseInt(s.id))
  })))

  const actualState = serviceContext$.getValue()
  const variantsServices: { [T in string]: Service[] } = {}
  const fields: { [T in string]: ServiceField[] } = {}
  const values: { [T in string]: { [T in string]: number } } = {}
  const calculationResult: { [T in string]: ServicesServicePriceCalculationMutationProps[] } = {}
  const properties: { [T in string]: ServicesProperty[] } = {}
  const propertyValues = {...actualState.PropertyValues}
  const variantSelectedServices: { [T in string]: string[] } = {}

  // Для крайней директории поля/свойства выгружаются в глобальный стейт,
  // поэтому подтягивать необходимо только поля и свойства из вариантов.
  if (state.LastServiceInPath.is_variants) {
    servicesToLoad.map(variant => {
      fields[variant.id] = [...variant.fields]
      properties[variant.id] = [...variant.properties]
    })
  }

  // Формируем базовый стейт значений для свойств для всех вариантов
  servicesToLoad.map(variant => {
    propertyValues[variant.id] = {}

    state.PropertiesToDisplay.map(prop => {
      propertyValues[variant.id][prop.id] = []
    })

    const props = properties[variant.id] ?? []
    props.map(prop => {
      propertyValues[variant.id][prop.id] = []
    })
  })

  loadedServices.map(r => {
    variantsServices[r.directoryID] = r.services
    calculationResult[r.directoryID] = []

    // Заполняем поля для услуг и добавляем заглушку для свойств, если они
    // не собраны из вариантов.
    properties[r.directoryID] = properties[r.directoryID] ?? []
    fields[r.directoryID] = [
      ...(fields[r.directoryID] ?? []),
      ...r.services.map(s => [
        ...s.fields,
        ...s.additionServices.map(s => s.fields).flat(1),
      ]).flat(1),
    ]

    values[r.directoryID] = {}
    fields[r.directoryID].map(f => values[r.directoryID][f.code] = f.value ?? 0)
    values[r.directoryID] = calculateFieldValues(
      fields[r.directoryID],
      values[r.directoryID],
      undefined,
      actualState.FieldValues,
    )

    // Заполняем значения свойств для каждого варианта
    r.services.map(s => {
      s.property_values.map(value => {
        if (!propertyValues[r.directoryID][String(value.property_id)]) {
          return
        }

        propertyValues[r.directoryID][String(value.property_id)].push(value.value)
      })
    })

    r.services.map(s => {
      if (!variantSelectedServices[r.directoryID]) {
        variantSelectedServices[r.directoryID] = []
      }

      if (getServiceIncludeState(s)) {
        variantSelectedServices[r.directoryID].push(s.id, ...s.additionServices.map(s => s.id))
      }
    })
  })

  await Promise.all([
    loadServiceDataCache(state.CurrencyID, servicesToLoad, variantsServices, calculationResult),
    loadServicePropertyValueCache(state.PropertiesToDisplay, properties, propertyValues),
  ])

  await makeFilterAndOrderData(
    state.PropertiesToDisplay,
    properties, propertyValues,
    true,
  )

  const newState = serviceContext$.getValue()

  serviceContext$.next({
    ...newState,
    IsServicesLoading: false,
    IsServicesCalculating: true,
    Variants: servicesToLoad,
    SelectedVariant: servicesToLoad[0].id,
    SelectedProductUUID: v4(),
    VariantServices: variantsServices,
    VariantFields: fields,
    VariantFieldValues: values,
    VariantsCalculationResult: calculationResult,
    VariantSelectedServices: variantSelectedServices,
    ServiceTemplateStore: serviceInitialStateGenerator().Generate(
      variantsServices,
      newState.FieldsToDisplay,
      newState.FieldValues,
      fields,
      values,
    ),
    PropertyValues: propertyValues,
    VariantProperties: properties,
  })

  makeOptionsForFields(Object.values(fields).flat(1))
  serviceCalculationBus$.next({isFieldChanges: true, isNeedChooseVariant: true})
}