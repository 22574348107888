import { $error } from '@settings/errorContext';
import { graphQLClient } from '@settings/services/graphQLClient';
import { GraphQLClient } from '@settings/services/graphQLClient/GraphQLClient';

import { localizedMessagesService } from '../../localizedMessagesService';
import { LocalizedMessagesServiceInterfaces } from '../../localizedMessagesService/interfaces';
import { LoaderInterface } from '../interface';
import { CarrierData, CarrierLoaderQuery, CarrierLoaderQueryResponse } from './CarrierLoaderQuery';

/**
 * Загрузчик данных по подрядчикам
 */
export class CarrierLoader implements LoaderInterface<CarrierData> {
  private readonly client: GraphQLClient;

  private readonly localizationLoader: LocalizedMessagesServiceInterfaces;

  constructor(token?: string) {
    this.client = graphQLClient(token);
    this.localizationLoader = localizedMessagesService(token);
  }

  async Load(primaryKeys?: any[]): Promise<CarrierData[]> {
    try {
      const resp = await this.client.Query<null, CarrierLoaderQueryResponse>(
        new CarrierLoaderQuery(primaryKeys),
        {}
      );

      // Формируем общий список локализаций (ID)
      const localizedMessageIds = resp.items.reduce(
        (result: string[], item: CarrierData): string[] => [...result, ...item.localized_names],
        []
      );

      // Загружаем все локализованные тексты
      const messages = await this.localizationLoader.GetMessagesArray(localizedMessageIds);
      resp.items.map((item) => {
        item.localized_names_data = messages.filter(
          (m) => item.localized_names.indexOf(m.id) !== -1
        );
      });

      return resp.items;
    } catch (e) {
      $error.next(e);
    }
  }
}
