import {Service} from "@onlog-public/additional-services-types";

/**
 * getDuplicateFieldsConfiguration возвращает настройки дублирования полей
 * по переданным параметрам шаблона.
 * @param s
 */
const getDuplicateFieldsConfiguration = (s: Service) => {
  switch (s.template) {
    case "serviceInAdditionList":
      return s.settings.TemplateSettings.ServiceInAdditionList.DuplicateFields
    case "serviceInCargoDeclaration":
      return s.settings.TemplateSettings.ServiceInCargoDeclaration.DuplicateFields
    case "additionList":
      return s.settings.TemplateSettings.AdditionList.DuplicateFields
    case "cargoDeclaration":
      return s.settings.TemplateSettings.CargoDeclaration.DuplicateFields
    case "serviceInListing":
      return s.settings.TemplateSettings.ServiceInList.DuplicateFields
    case "verticalTable":
      return s.settings.TemplateSettings.VerticalTable.DuplicateFields
    case "serviceInVerticalTable":
      return s.settings.TemplateSettings.ServiceInVerticalTable.DuplicateFields
  }

  return undefined
}

export default getDuplicateFieldsConfiguration