import React, {FC} from "react";
import {
  ServicesServicePriceCalculationMutationProps
} from "@services/requests/servicesService/servicesServicesService/interface";
import {ServiceSettings} from "@onlog-public/additional-services-types";
import getServiceTotalPrice from "@pages/AdditionalServices/components/Service/getServiceTotalPrice";
import getServiceTotalTax from "@pages/AdditionalServices/components/Service/getServiceTotalTax";
import {useTranslation} from "react-i18next";
import {CurrencyData} from "@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery";

export interface TotalPriceColumnProps {
  currency: CurrencyData
  settings: ServiceSettings
  services: ServicesServicePriceCalculationMutationProps[]
}

/**
 * TotalPriceColumn выводит колонку итоговой стоимости группы услуг.
 * Реализует логику вывода текста, когда цена = 0 или по запросу.
 *
 * @param props
 * @constructor
 */
const TotalPriceColumn: FC<TotalPriceColumnProps> = props => {
  const {
    currency,
    services,
    settings,
  } = props

  const {t} = useTranslation()

  const currencyGlyph = currency?.glyph.length > 0 ? currency?.glyph : currency?.code
  const totalPrice = getServiceTotalPrice(services)
  const totalTax = getServiceTotalTax(services)

  if (totalPrice < 0) {
    const text = t(
      settings.PriceByRequestText.length > 0
        ? t(settings.PriceByRequestText)
        : t('AdditionalService:CalculationOnRequest')
    )

    return (
      <div className="a-service-template-cargoDeclaration-price">
        <div className="text-only">{text}</div>
      </div>
    )
  }

  if (totalPrice === 0 && settings.TemplateSettings.CargoDeclaration.IsNeedDisplayTextForZeroValues) {
    const text = t(
      settings.TemplateSettings.CargoDeclaration.DisplayTextForZeroValues.length > 0
        ? t(settings.TemplateSettings.CargoDeclaration.DisplayTextForZeroValues)
        : 'AdditionalService:IncludedInTheBasicService'
    )

    return (
      <div className="a-service-template-cargoDeclaration-price">
        <div className="text-only">{text}</div>
      </div>
    )
  }

  if (!currency) {
    return null
  }

  return (
    <div className="a-service-template-cargoDeclaration-price">
      <div>{totalPrice.toLocaleString()} {currency.code}</div>
      <div>{t('AdditionalService:ServicePriceTaxDescription')} {totalTax.toLocaleString()} {currencyGlyph}</div>
    </div>
  )
}

export default TotalPriceColumn