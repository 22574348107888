import {ServiceDirectory} from "@onlog-public/additional-services-types";
import React, {FC} from "react";
import DirectorySelectorField from "@pages/AdditionalServices/components/DirectorySelector/DirectorySelector";
import getLocalizationByArgs from "@helpers/getLocalizationByArgs";
import {CircularProgress} from "@material-ui/core";

interface DirectorySelectorProps {
  isLoading: boolean
  langID: string
  selectedPath: { [T in string]: ServiceDirectory }
  onChange: (level: number, directory: ServiceDirectory) => void
}

/**
 * sortDirectories реализует функцию сортировки директорий в селекторе.
 * Если для директорий задан одинаковый приоритет, то они сортируются по ID.
 * @param a
 * @param b
 */
const sortDirectories = (a: ServiceDirectory, b: ServiceDirectory) => {
  if (a.settings.PositionInSelect === b.settings.PositionInSelect) {
    return parseInt(a.id) > parseInt(b.id) ? 1 : -1
  }

  return a.settings.PositionInSelect < b.settings.PositionInSelect ? 1 : -1
}

const DirectorySelector: FC<DirectorySelectorProps> = props => {
  const {
    langID,
    isLoading,
    selectedPath,
    onChange,
  } = props

  return <>
    {Object.values(selectedPath).map((d, i) => {
      const nextLevel = selectedPath[String(i + 1)]?.id
      const options = d.children
        .filter(c => c.is_active)
        .sort(sortDirectories)
        .map(c => ({
          value: c.id,
          label: getLocalizationByArgs(langID, c.name, c.localized_names),
        }))

      if (options.length === 0 || d.is_variants) {
        return null
      }

      return (
        <DirectorySelectorField
          key={d.id}
          code={d.id}
          value={nextLevel}
          options={options}
          settings={d.settings}
          onChange={value => {
            const dir = d.children.find(c => c.id === value)
            if (!dir) {
              return
            }

            onChange(i + 1, dir)
          }}
        />
      )
    })}
    {isLoading && (
      <div className="a-service-widget-form-loading">
        <CircularProgress color="inherit" size={24}/>
      </div>
    )}
  </>
}

export default DirectorySelector