import {BehaviorSubject} from "rxjs";
import {AdditionServicesState} from "@pages/AdditionalServices/containers/types";
import {clone} from "@pages/AdditionalServices/containers/methods/clone";

/**
 * isLastLevelSelectedCheckSubscriber реализует подписчик, который вычисляет
 * значение флага IsLastLevelSelected. Если значение флага изменилось -
 * обновляет стейт.
 *
 * @param serviceContext$
 */
export const isLastLevelSelectedCheckSubscriber = (
  serviceContext$: BehaviorSubject<AdditionServicesState>,
) => (
  states: AdditionServicesState[]
) => {
  if (states.length === 0) {
    return
  }

  // Берем только последний стейт, интересен только он
  const state = states[states.length - 1]

  // Если уровень еще не подгружен, то нет смысла проверять
  if (state.IsLoadingDirectoriesForPath) {
    return
  }

  let isLastLevelSelected = false
  if (!!state.LastServiceInPath) {
    isLastLevelSelected = state.LastServiceInPath.children.length === 0

    if (state.LastServiceInPath.is_variants) {
      isLastLevelSelected = state.LastServiceInPath.children.filter(c => c.is_active).length > 0
    }
  }

  if (state.IsLastLevelSelected === isLastLevelSelected) {
    return
  }

  serviceContext$.next({
    ...clone(state),
    IsLastLevelSelected: isLastLevelSelected,
    FieldValues: {},
    FieldsToDisplay: [],
  })
}