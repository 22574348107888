import React from 'react';
import AServiceWidgetContainer from "@pages/AdditionalServices/containers/AServiceWidgetContainer";
import AServiceDataContainer from "@pages/AdditionalServices/containers/AServiceDataContainer";

const AdditionalForm = () => {
  return (
    <>
      <AServiceWidgetContainer/>
      <AServiceDataContainer />
    </>
  );
};

export default AdditionalForm;
