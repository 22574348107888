import {BaseOptionsLoader} from "@pages/AdditionalServices/containers/services/optionsLoader/BaseOptionsLoader";
import {HandbookProcessor} from "@pages/AdditionalServices/containers/services/optionsLoader/HandbookProcessor";
import {CurrencyProcessor} from "@pages/AdditionalServices/containers/services/optionsLoader/CurrencyProcessor";
import {TaxProcessor} from "@pages/AdditionalServices/containers/services/optionsLoader/TaxProcessor";
import {LocationProcessor} from "@pages/AdditionalServices/containers/services/optionsLoader/LocationProcessor";
import {ContractorProcessor} from "@pages/AdditionalServices/containers/services/optionsLoader/ContractorProcessor";

// optionsLoader реализует фабрику сервиса.
const optionsLoader = () => new BaseOptionsLoader([
  new HandbookProcessor,
  new CurrencyProcessor,
  new TaxProcessor,
  new LocationProcessor,
  new ContractorProcessor("consulting_contractor", 'consulting'),
  new ContractorProcessor("inspection_contractor", 'inspection'),
  new ContractorProcessor("certification_contractor", 'certification'),
  new ContractorProcessor("customs_contractor", 'customs'),
  new ContractorProcessor("insurance_contractor", 'insurance'),
]);

export default optionsLoader