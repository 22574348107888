import CalculateDeliveryIcon from '@components/icons/CalculateDeliveryIcon';
import CardWrapper from '@components/UI/CardWrapper';
import { useMediaQuery } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import setLocationText from '@pages/AdditionalServices/tabs/DeliveryForm/helpers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import RoutesCalculationWidget from './components/RoutesCalculationWidget';
import RoutesListComponent from './components/RoutesListComponent';
import Container, {
  DrawerButton,
  DrawerButtonInner,
  SwipeableDrawerStyledContainer,
} from './StyledComponents';
import useDelivery from './useDelivery';
import DeliveryFormSkeleton from './DeliveryFormSkeletont';

const DeliveryForm = () => {
  const theme = useTheme();
  const { useRouteParams, useSettings } = useDelivery();
  const [settings] = useSettings().useState();
  const [routeParams] = useRouteParams().useState();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation(['Actions', 'LocationSelector']);
  const [isVisibleMobileForm, setIsVisibleMobileForm] = useState<boolean>(false);
  const { useAggregationData, useBaseDate, useIsLoading } = useDelivery();
  const [aggregationData] = useAggregationData().useState();
  const [isLoading] = useIsLoading().useState();
  const [startLocationTitle, setStartLocationTitle] = useState<string>('');
  const [endLocationTitle, setEndLocationTitle] = useState<string>('');
  const [baseData] = useBaseDate().useState();
  const startTransportingCondition = baseData?.startTransportingConditions.find(
    (c) => c.id === routeParams.startTransportingCondition
  );

  useEffect(() => {
    setLocationText({
      location: routeParams.startLocation,
      setValue: setStartLocationTitle,
      secondaryLangId: settings.secondaryLanguageId,
      primaryLangId: settings.primaryLanguageId,
      isFrom: true,
      shippersWarehouse: t('LocationSelector:ShippersWarehouse'),
      consigneesWarehouse: t('LocationSelector:ConsigneesWarehouse'),
      terminal: t('Common:Terminal'),
    });

    setLocationText({
      location: routeParams.endLocation,
      setValue: setEndLocationTitle,
      secondaryLangId: settings.secondaryLanguageId,
      primaryLangId: settings.primaryLanguageId,
      isFrom: false,
      shippersWarehouse: t('LocationSelector:ShippersWarehouse'),
      consigneesWarehouse: t('LocationSelector:ConsigneesWarehouse'),
      terminal: t('Common:Terminal'),
    });
  }, [routeParams]);

  const handleShowFormButton = () => {
    setIsVisibleMobileForm((isVisibleMobileForm) => !isVisibleMobileForm);
  };

  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const FormContent =
    !baseData || !startTransportingCondition ? (
      <DeliveryFormSkeleton isMobile={isMobile} />
    ) : (
      <CardWrapper className={'card-wrapper'}>
        {isMobile && (
          <DrawerButtonInner onClick={handleShowFormButton}>
            {!isVisibleMobileForm && (
              <div className={'header'}>
                <div className={'from-to'}>
                  {startLocationTitle} - {endLocationTitle}
                </div>
                <div className={'title'}>{t('RoutesCalculationWidget:ChangeSearchSettings')}</div>
              </div>
            )}
            <IconButton color="primary" size="small">
              <CloseIcon fontSize={'small'} />
            </IconButton>
          </DrawerButtonInner>
        )}
        <RoutesCalculationWidget
          handleShowFormButton={handleShowFormButton}
          openDrawer={isVisibleMobileForm}
        />
      </CardWrapper>
    );

  return (
    <Container>
      {!isLoading && isMobile && !aggregationData && (
        <CardWrapper className={'card-wrapper'}>
          <Button
            variant={'contained'}
            fullWidth
            size={'large'}
            startIcon={<CalculateDeliveryIcon />}
            className={'show-form'}
            onClick={handleShowFormButton}
          >
            {t('Actions:CalculateDelivery')}
          </Button>
        </CardWrapper>
      )}
      {!isMobile && FormContent}
      {isMobile && !isVisibleMobileForm && startLocationTitle && endLocationTitle && (
        <DrawerButton onClick={handleShowFormButton}>
          <div className={'header'}>
            <div className={'from-to'}>
              {startLocationTitle} - {endLocationTitle}
            </div>
            <div className={'title'}>{t('RoutesCalculationWidget:ChangeSearchSettings')}</div>
          </div>
          <IconButton color="primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="7"
              viewBox="0 0 17 7"
              fill="none"
              stroke="none"
            >
              <path d="M16 6L8.5 0.642856L1 6" stroke="black" fill="none" />
            </svg>
          </IconButton>
        </DrawerButton>
      )}
      {isMobile && (
        <SwipeableDrawerStyledContainer
          anchor="bottom"
          SwipeAreaProps={{
            disablePortal: true,
          }}
          open={isVisibleMobileForm}
          onClose={handleShowFormButton}
          onOpen={handleShowFormButton}
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          swipeAreaWidth={aggregationData ? 70 : undefined}
        >
          {FormContent}
        </SwipeableDrawerStyledContainer>
      )}
      <RoutesListComponent />
    </Container>
  );
};

export default DeliveryForm;
