import React from 'react';

const SettingIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
    <path
      d="M6.71429 10.0714C6.99043 10.0714 7.21429 9.84757 7.21429 9.57143C7.21429 9.29529 6.99043 9.07143 6.71429 9.07143V10.0714ZM1 9.07143C0.723858 9.07143 0.5 9.29529 0.5 9.57143C0.5 9.84757 0.723858 10.0714 1 10.0714L1 9.07143ZM6.71429 1.92857C6.43814 1.92857 6.21429 2.15243 6.21429 2.42857C6.21429 2.70471 6.43814 2.92857 6.71429 2.92857V1.92857ZM12.4286 2.92857C12.7047 2.92857 12.9286 2.70471 12.9286 2.42857C12.9286 2.15243 12.7047 1.92857 12.4286 1.92857V2.92857ZM6.71429 9.07143L1 9.07143L1 10.0714H6.71429V9.07143ZM6.71429 2.92857L12.4286 2.92857V1.92857L6.71429 1.92857V2.92857ZM0.5 2.42857C0.5 3.49369 1.36345 4.35714 2.42857 4.35714V3.35714C1.91574 3.35714 1.5 2.94141 1.5 2.42857H0.5ZM2.42857 4.35714C3.49369 4.35714 4.35714 3.49369 4.35714 2.42857H3.35714C3.35714 2.94141 2.94141 3.35714 2.42857 3.35714V4.35714ZM4.35714 2.42857C4.35714 1.36345 3.49369 0.5 2.42857 0.5V1.5C2.94141 1.5 3.35714 1.91574 3.35714 2.42857H4.35714ZM2.42857 0.5C1.36345 0.5 0.5 1.36345 0.5 2.42857H1.5C1.5 1.91574 1.91574 1.5 2.42857 1.5V0.5ZM12.9286 9.57143C12.9286 8.50631 12.0651 7.64286 11 7.64286V8.64286C11.5128 8.64286 11.9286 9.05859 11.9286 9.57143L12.9286 9.57143ZM11 7.64286C9.93488 7.64286 9.07143 8.50631 9.07143 9.57143L10.0714 9.57143C10.0714 9.05859 10.4872 8.64286 11 8.64286V7.64286ZM9.07143 9.57143C9.07143 10.6365 9.93488 11.5 11 11.5V10.5C10.4872 10.5 10.0714 10.0843 10.0714 9.57143L9.07143 9.57143ZM11 11.5C12.0651 11.5 12.9286 10.6365 12.9286 9.57143L11.9286 9.57143C11.9286 10.0843 11.5128 10.5 11 10.5V11.5Z"
      fill="#2D264B"
    />
  </svg>
);

export default SettingIcon;
