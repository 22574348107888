import breakpoints from '@assets/theme/breakpoints';
import Box from '@mui/material/Box';
import {green} from '@mui/material/colors';
import Paper from '@mui/material/Paper';
import {styled} from '@mui/material/styles';

const SWIPEABLEDRAWERSETTINGS = {
  height: 70,
};

const CartComponentStyledContainer = styled(Box)`
    display: grid;
    position: relative;
    margin-bottom: 40px;
    grid-template-columns: 1fr 310px;
    grid-gap: 30px;
    color: ${({theme}) => theme.palette.grey[700]};
    letter-spacing: normal;
    padding: 0;
    width: 100%;

    @media (max-width: ${breakpoints.values.lg}px) {
        grid-gap: 20px;
        padding: 0 20px 90px 20px;
    }
    @media (max-width: ${breakpoints.values.md}px) {
        grid-template-columns: 1fr;
        padding: 0;
    }
    @media (max-width: ${breakpoints.values.xsm}px) {
        grid-template-columns: 1fr;
        padding: 0;
    }

    .title {
        margin: 7px 0 50px;
        color: ${({theme}) => theme.palette.common.black};
        font-size: 40px;
        font-weight: 400;
        line-height: normal;

        @media (max-width: ${breakpoints.values.md}px) {
            margin: 6px 0 30px;
            font-size: 25px;
        }
    }

    .section-title {
        margin: 0 0 30px;

        @media (max-width: ${breakpoints.values.md}px) {
            margin: 0 0 20px;
        }
    }

    .services {
        padding: 30px 50px;
        background-color: ${({theme}) => theme.palette.common.white};

        @media (max-width: ${breakpoints.values.lg}px) {
            padding: 30px 20px;
        }

        @media (max-width: ${breakpoints.values.xsm}px) {
            padding: 30px 15px 40px;
        }
    }

    .summary {
        margin-top: 40px;
    }

    .reset-cart {
        margin-top: 30px;
    }
`;

export const SwipeableDrawerStyledContainer = styled(Box)`
    top: -${SWIPEABLEDRAWERSETTINGS.height}px;
    position: relative;
    visibility: visible;
    left: 0;
    right: 0;

    &.--open {
        position: relative;
        top: 0;
    }

    &.--ios {
        .arrow {
            top: 0;
            left: 0;
            width: 100%;
            height: 63px;
            padding: 0 20px;
            justify-content: flex-end;
        }

        &.--open {
            .arrow {
                justify-content: flex-start;
            }
        }
    }

    .arrow {
        position: absolute;
        top: 25px;
        right: 25px;
        z-index: 10;
        will-change: transform;
        transition: transform 0.2s;
        padding: 0;
        width: 24px;
        pointer-events: all;

        &:hover {
            background-color: transparent;
        }

        svg {
            color: ${({theme}) => theme.palette.common.black};
            font-size: 24px;
        }

        &--close {
            transform: rotate(180deg);
        }
    }
`;

export const NotServiceInBasketStyledContainer = styled(Paper)`
    padding: ${({theme}) => theme.spacing(2)};

    .to-calculate {
        color: ${green[500]};

        &:hover {
            text-decoration: underline;
        }
    }
`;

export default CartComponentStyledContainer;
