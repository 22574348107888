import { styled } from '@mui/material';

const ShoulderItemStyledContainer = styled('div')`
  max-width: 100%;
  overflow: auto;
  border-radius: 18px;

  .offer-footer-info {
    border-radius: 0 0 18px 18px;
    padding: 20px;
    border: 1px solid ${({ theme }) => theme.palette.grey[200]};
  }
`;

export default ShoulderItemStyledContainer;
