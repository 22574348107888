import {VariantFilter} from "@pages/AdditionalServices/containers/types";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import PropertyFilterGroup
  from "@pages/AdditionalServices/components/ServiceDirectory/ListTypeTemplate/PropertyFilterGroup";
import getResetStateForPropertyField
  from "@pages/AdditionalServices/components/ServiceDirectory/ListTypeTemplate/getResetStateForPropertyField";
import PriceFilter from "@pages/AdditionalServices/components/ServiceDirectory/ListTypeTemplate/PriceFilter";
import {ServiceFieldType} from "@onlog-public/additional-services-types";
import {OptionsCache} from "@pages/AdditionalServices/containers/services/optionsLoader/types";

export interface ListTemplateFilterProps {
  langID: string
  filter: VariantFilter
  currencyID: string
  serviceDataCache: { [T in ServiceFieldType]: OptionsCache<any> }
  onChangeFilter: (c: { (state: VariantFilter): VariantFilter }) => void
}

const ListTemplateFilter: FC<ListTemplateFilterProps> = props => {
  const {langID, filter, onChangeFilter} = props
  const {t} = useTranslation('AdditionalService')

  const handleResetAll = () => {
    onChangeFilter(c => {
      const result = {...c}
      Object.keys(result.Data).map(propertyID => {
        result.Data[propertyID] = getResetStateForPropertyField(result.Data[propertyID])
      })

      return result
    })
  }

  const propertiesList = Object.values(filter.Data)
    .sort((a, b) => a.Property.ordinal > b.Property.ordinal ? 1 : -1)


  return (
    <div className="a-service-template-list-filter">
      <div className="a-service-template-list-filter-title">
        {t('AdditionalService:Templates.list.filterCaption')}
      </div>
      <PriceFilter {...props}/>
      {propertiesList.map(data => (
        <PropertyFilterGroup
          key={data.Property.id}
          data={data}
          langID={langID}
          onChange={data => onChangeFilter(v => ({
            ...v,
            Data: {
              ...v.Data,
              [data.Property.id]: data,
            }
          }))}
        />
      ))}
      <div>
        <div
          className="a-service-template-list-filter-resetAll"
          onClick={handleResetAll}
        >
          {t('AdditionalService:Templates.list.resetAll')}
        </div>
      </div>
    </div>
  )
}

export default ListTemplateFilter