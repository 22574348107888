import {
  BaseServiceToCalculateGenerator
} from "@pages/AdditionalServices/containers/services/serviceToCalculateGenerator/baseServiceToCalculateGenerator";
import {
  DefaultProcessor
} from "@pages/AdditionalServices/containers/services/serviceToCalculateGenerator/defaultProcessor";

// serviceToCalculateGenerator реализует фабрику сервиса
const serviceToCalculateGenerator = () => new BaseServiceToCalculateGenerator([
  new DefaultProcessor,
])

export default serviceToCalculateGenerator