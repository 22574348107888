import React from 'react';

export default () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.53139 6.7763C7.26103 6.50594 6.82269 6.50594 6.55232 6.7763C6.28196 7.04666 6.28196 7.48501 6.55232 7.75537L8.02093 9.22398L6.55232 10.6926C6.28196 10.9629 6.28196 11.4013 6.55232 11.6717C6.82269 11.942 7.26103 11.942 7.53139 11.6717L9 10.203L10.4686 11.6717C10.739 11.942 11.1773 11.942 11.4477 11.6717C11.718 11.4013 11.718 10.9629 11.4477 10.6926L9.97907 9.22398L11.4477 7.75537C11.718 7.48501 11.718 7.04666 11.4477 6.7763C11.1773 6.50594 10.739 6.50594 10.4686 6.7763L9 8.2449L7.53139 6.7763Z"
      fill="#3460B4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1202 0.114211C12.0659 -1.3411e-05 10.737 -7.41554e-06 9.04177 1.22157e-07H8.95823C7.26297 -7.41554e-06 5.9341 -1.3411e-05 4.87982 0.114211C3.80394 0.230775 2.93221 0.472915 2.18791 1.01368C1.73731 1.34106 1.34106 1.73731 1.01368 2.18791C0.472915 2.93221 0.230775 3.80394 0.114211 4.87982C-1.3411e-05 5.9341 -7.41554e-06 7.26297 1.22157e-07 8.95823V9.04177C-7.41554e-06 10.737 -1.3411e-05 12.0659 0.114211 13.1202C0.230775 14.1961 0.472915 15.0678 1.01368 15.8121C1.34106 16.2627 1.73731 16.6589 2.18791 16.9863C2.93221 17.5271 3.80394 17.7692 4.87982 17.8858C5.93408 18 7.26292 18 8.95814 18H9.04179C10.737 18 12.0659 18 13.1202 17.8858C14.1961 17.7692 15.0678 17.5271 15.8121 16.9863C16.2627 16.6589 16.6589 16.2627 16.9863 15.8121C17.5271 15.0678 17.7692 14.1961 17.8858 13.1202C18 12.0659 18 10.7371 18 9.04186V8.95821C18 7.26299 18 5.93409 17.8858 4.87982C17.7692 3.80394 17.5271 2.93221 16.9863 2.18791C16.6589 1.73731 16.2627 1.34106 15.8121 1.01368C15.0678 0.472915 14.1961 0.230775 13.1202 0.114211ZM3.00177 2.13386C3.47069 1.79316 4.07089 1.59457 5.02896 1.49077C5.99999 1.38557 7.25393 1.38462 9 1.38462C10.7461 1.38462 12 1.38557 12.971 1.49077C13.9291 1.59457 14.5293 1.79316 14.9982 2.13386C15.3313 2.37583 15.6242 2.66872 15.8661 3.00177C16.2068 3.47069 16.4054 4.07089 16.5092 5.02896C16.6144 5.99999 16.6154 7.25393 16.6154 9C16.6154 10.7461 16.6144 12 16.5092 12.971C16.4054 13.9291 16.2068 14.5293 15.8661 14.9982C15.6242 15.3313 15.3313 15.6242 14.9982 15.8661C14.5293 16.2068 13.9291 16.4054 12.971 16.5092C12 16.6144 10.7461 16.6154 9 16.6154C7.25393 16.6154 5.99999 16.6144 5.02896 16.5092C4.07089 16.4054 3.47069 16.2068 3.00177 15.8661C2.66872 15.6242 2.37583 15.3313 2.13386 14.9982C1.79316 14.5293 1.59457 13.9291 1.49077 12.971C1.38557 12 1.38462 10.7461 1.38462 9C1.38462 7.25393 1.38557 5.99999 1.49077 5.02896C1.59457 4.07089 1.79316 3.47069 2.13386 3.00177C2.37583 2.66872 2.66872 2.37583 3.00177 2.13386Z"
      fill="#3460B4"
    />
  </svg>
);
