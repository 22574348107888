import CellModesModel from '@components/UI/DefaultTable/helpers';
import { DataGrid, GridCellModesModel, GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { FC, MouseEvent, useCallback, useState } from 'react';

import DefaultTableStyledContainer from './StyledComponents';

export type AdditionalServicesTableBodyProps = {
  rows: any[];
  columns: GridColDef[];
};

const DefaultTable: FC<AdditionalServicesTableBodyProps> = ({ rows, columns }) => {
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});

  const handleCellClick = useCallback((params: GridCellParams, event: MouseEvent) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if (!event.currentTarget.contains(event.target as Element)) {
      return;
    }

    setCellModesModel((prevModel) => CellModesModel(prevModel, params));
  }, []);

  const handleCellModesModelChange = useCallback((newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  }, []);

  return (
    <DefaultTableStyledContainer>
      <DataGrid
        rows={rows}
        columns={columns}
        hideFooter
        disableColumnMenu
        getRowHeight={() => 'auto'}
        showCellVerticalBorder
        showColumnVerticalBorder
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
      />
    </DefaultTableStyledContainer>
  );
};

export default DefaultTable;
