import { FileData } from '@services/requests/filesService/interface';
import React from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { filesService } from '@services/requests/filesService';

import FileListStyledContainer from './StyledComponents';

/**
 * Компонент вывода одного файла
 * @param file
 */
const FileItem = (file: FileData) => {
  const { name, name_original } = file;

  const parts = name.split('.');
  const fileExtension = parts[parts.length - 1];

  const filePath = filesService().GetFileUrl(file);

  return (
    <li className={'file-item'}>
      <a href={filePath} target="_blank" rel="noreferrer" className={'link'}>
        <FileIcon
          id={file.name}
          size={48}
          extension={fileExtension}
          // @ts-ignore
          {...(defaultStyles[fileExtension] || {})}
        />
        <span>{name_original}</span>
      </a>
    </li>
  );
};

export type FileListComponentProps = {
  files: FileData[];
};

/**
 * Компонент вывода списка файлов
 * @param files
 */
const FileListComponent = ({ files }: FileListComponentProps) => {
  return (
    <FileListStyledContainer>
      {files.map((file) => (
        <FileItem {...file} key={`file--${file.id}`} />
      ))}
    </FileListStyledContainer>
  );
};

export default FileListComponent;
