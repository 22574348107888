import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const EmailIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M19.3934 19.1429C19.798 19.1429 20.1486 19.0093 20.4466 18.7455L15.3469 13.6457C15.2246 13.7333 15.106 13.8185 14.994 13.8995C14.6123 14.1807 14.3026 14.4001 14.0647 14.5573C13.8269 14.7149 13.5105 14.8755 13.1155 15.0395C12.7203 15.2037 12.3521 15.2855 12.0105 15.2855H12.0005H11.9905C11.6489 15.2855 11.2807 15.2037 10.8855 15.0395C10.4903 14.8755 10.1739 14.7149 9.93633 14.5573C9.69852 14.4001 9.38894 14.1807 9.00711 13.8995C8.9007 13.8215 8.78269 13.7359 8.65511 13.6443L3.55444 18.7455C3.85245 19.0093 4.20327 19.1429 4.60785 19.1429H19.3934V19.1429Z"
      fill="currentColor"
    />
    <path
      d="M4.01462 10.4343C3.63301 10.1799 3.29461 9.88851 3 9.5603V17.3194L7.49485 12.8245C6.59562 12.1967 5.43702 11.4009 4.01462 10.4343Z"
      fill="currentColor"
    />
    <path
      d="M19.9959 10.4343C18.6278 11.3603 17.4649 12.1575 16.5073 12.8263L21.0003 17.3196V9.5603C20.7123 9.88191 20.3775 10.1731 19.9959 10.4343Z"
      fill="currentColor"
    />
    <path
      d="M19.3928 5H4.60729C4.09146 5 3.69489 5.17418 3.41708 5.52219C3.13904 5.87037 3.00024 6.3058 3.00024 6.82799C3.00024 7.24979 3.18443 7.70682 3.55263 8.19921C3.92084 8.69141 4.31264 9.07801 4.72784 9.35922C4.95546 9.52004 5.64187 9.99723 6.78708 10.7906C7.40528 11.219 7.9429 11.5924 8.4049 11.9147C8.7987 12.1891 9.13831 12.4267 9.4187 12.6239C9.45089 12.6464 9.50151 12.6827 9.5687 12.7307C9.64109 12.7827 9.73269 12.8487 9.8457 12.9303C10.0633 13.0877 10.2441 13.2149 10.3881 13.3121C10.5319 13.4093 10.7061 13.5179 10.9105 13.6385C11.1147 13.7589 11.3073 13.8495 11.4881 13.9097C11.6689 13.9699 11.8363 14.0001 11.9903 14.0001H12.0003H12.0103C12.1642 14.0001 12.3317 13.9699 12.5125 13.9097C12.6933 13.8495 12.8857 13.7591 13.0901 13.6385C13.2943 13.5179 13.4683 13.4091 13.6125 13.3121C13.7565 13.2149 13.9373 13.0877 14.155 12.9303C14.2677 12.8487 14.3593 12.7827 14.4317 12.7309C14.4989 12.6826 14.5495 12.6466 14.5819 12.6239C14.8004 12.4719 15.1407 12.2352 15.5984 11.9174C16.4312 11.3388 17.6576 10.4872 19.2828 9.35922C19.7716 9.01781 20.18 8.60582 20.5082 8.12382C20.8358 7.64182 21 7.13623 21 6.6072C21 6.16521 20.8408 5.787 20.523 5.472C20.2048 5.15739 19.828 5 19.3928 5Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default EmailIcon;
