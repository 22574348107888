import { $error } from '@settings/errorContext';
import { graphQLClient } from '@settings/services/graphQLClient';
import { GraphQLClient } from '@settings/services/graphQLClient/GraphQLClient';

import { LoaderInterface } from '../interface';
import {
  CurrencyData,
  CurrencyLoaderQuery,
  CurrencyLoaderQueryResponse,
} from './CurrencyLoaderQuery';

/**
 * Загрузчик данных по подрядчикам
 */
export class CurrencyLoader implements LoaderInterface<CurrencyData> {
  private readonly client: GraphQLClient;

  constructor(token?: string) {
    this.client = graphQLClient(token);
  }

  async Load(primaryKeys?: any[]): Promise<CurrencyData[]> {
    try {
      const resp = await this.client.Query<null, CurrencyLoaderQueryResponse>(
        new CurrencyLoaderQuery(primaryKeys),
        {}
      );
      return resp.items;
    } catch (e) {
      $error.next(e);
    }
  }
}
