import React, {FC} from "react";
import {AdditionServiceFieldProps} from "@pages/AdditionalServices/components/FieldDisplay/AdditionServiceField/types";
import FieldCaption from "@pages/AdditionalServices/components/FieldDisplay/AdditionServiceField/FieldCaption";
import FieldCollectionComponent
  from "@pages/AdditionalServices/components/FieldDisplay/AdditionServiceField/FieldCollectionComponent";
import clsx from "clsx";

const AdditionServiceField: FC<AdditionServiceFieldProps> = props => {
  const {
    field,
    langID,
    templateSettings,
  } = props

  const settings = templateSettings ?? field.settings.DefaultSettings
  if (field.type === "hidden" || settings.IsFieldHidden) {
    return null
  }

  return <>
    <div className={clsx("addition-service-block", `block-size-${settings.FieldBlockSize}`)}>
      <div className={clsx("addition-service-field", `field-size-${settings.FieldWidth}`)}>
        <FieldCaption
          fieldName={field.name}
          fieldLocalization={field.localized_names}
          langID={langID}
          fieldSettings={settings}
        />
        <div className="a-service-field-component">
          <FieldCollectionComponent {...props} />
        </div>
      </div>
    </div>
  </>
}

export default AdditionServiceField