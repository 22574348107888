import {
  Service,
  ServiceDirectory,
  ServiceFieldType,
  ServiceContractorType
} from "@onlog-public/additional-services-types";
import {
  ServicesServicePriceCalculationMutationProps
} from "@services/requests/servicesService/servicesServicesService/interface";
import {serviceCacheContext$} from "@pages/AdditionalServices/containers/bus";
import optionsLoader from "@pages/AdditionalServices/containers/services/optionsLoader";
import {OptionsCache} from "@pages/AdditionalServices/containers/services/optionsLoader/types";

// EntityWithContractor описывает универсальную сущность с подрядчиком
type EntityWithContractor = {
  contractor_type?: ServiceContractorType
  contractor_id?: string
}

// EntityID описывает сборочный тип идентификаторов сущностей для загрузки
type EntityID = { [T in ServiceFieldType]: string[] }

/**
 * getInitialEntityID возвращает первоначальный стейт для сбора идентификаторов
 * сущностей.
 */
const getInitialEntityID = () => {
  return {
    tax: [],
    currency: [],
    consulting_contractor: [],
    inspection_contractor: [],
    customs_contractor: [],
    certification_contractor: [],
    insurance_contractor: [],
  } as EntityID
}

/**
 * exportContractors метод позволяет выгрузить из сущности идентификаторы
 * подрядчиков различных типов. В качестве аргумента для метода подходит
 * как директория, так и услуга.
 *
 * @param d
 * @param id
 */
const exportContractors = (d: EntityWithContractor[], id: EntityID): EntityID => {
  const result = {...id}
  d.map(d => {
    if (!d.contractor_id) {
      return
    }

    switch (d.contractor_type) {
      case "CertificationContractor":
        return result.certification_contractor.push(d.contractor_id)
      case "ConsultingContractor":
        return result.consulting_contractor.push(d.contractor_id)
      case "CustomsContractor":
        return result.customs_contractor.push(d.contractor_id)
      case "InspectionContractor":
        return result.inspection_contractor.push(d.contractor_id)
      case "InsuranceContractor":
        return result.insurance_contractor.push(d.contractor_id)
    }
  })

  return result
}

/**
 * filterData очищает пустые ID из переданного массива.
 * Метод так же возвращает только уникальные ключи для загрузки данных.
 *
 * @param data
 */
const filterData = (data: string[]): string[] => {
  return data.filter(d => !!d && d.length > 0 && d !== "0")
    .filter((d, i, data) => data.indexOf(d) === i)
}

/**
 * getUnloadedEntityID возвращает идентификаторы, которые еще не
 * загружены в кэш данных.
 * @param entities
 */
const getUnloadedEntityID = (entities: EntityID): EntityID => {
  const result = {...entities} as EntityID
  const cache = serviceCacheContext$.getValue().Cache

  Object.keys(entities).map(type => {
    result[type] = result[type].filter((id: string) => !cache[type].cache[id])
  })

  return result
}

/**
 * loadEntityData выполняет загрузку данных в кэш по сформированному
 * набору идентификаторов. Метод не проверяет, загружены ли уже данные.
 * Если требуется только добавить новые, то перед вызовом используем
 * getUnloadedEntityID()
 *
 * @param entities
 */
const loadEntityData = async (entities: EntityID) => {
  const result = await Promise.all(
    Object.keys(entities).map(async (type: ServiceFieldType) => {
      if (entities[type].length === 0) {
        return {type, items: {isPartialLoading: true, cache: {}} as OptionsCache<any>}
      }

      return {
        type,
        items: await optionsLoader().LoadOptionsCache(type, entities[type])
      }
    })
  )

  const state = serviceCacheContext$.getValue()
  const newCache = {...state.Cache}
  let isModified = false

  result.map(r => {
    if (Object.keys(r.items.cache).length === 0) {
      return
    }

    isModified = true
    newCache[r.type] = {
      ...newCache[r.type],
      cache: {...newCache[r.type].cache, ...r.items.cache}
    }
  })

  if (!isModified) {
    return
  }

  serviceCacheContext$.next({
    ...state,
    Cache: newCache,
  })
}

/**
 * getCacheData возвращает кэшированные данные для переданной услуги и ее
 * рассчитанному ЦП.
 * @param service
 * @param price
 */
export const getCacheData = (
  service: Service,
  price: ServicesServicePriceCalculationMutationProps,
): { [T in ServiceFieldType]: OptionsCache<any> } => {
  // Добавляем данные услуг
  const allServices = [service, ...(service.additionServices ?? [])]
  const dataToResolve = exportContractors(allServices, getInitialEntityID())

  // Добавляем данные ЦП
  dataToResolve.tax.push(String(price?.result?.data?.tax_id ?? ""))
  dataToResolve.currency.push(String(price?.result?.data?.currency_id ?? ""))
  dataToResolve.currency.push(String(price?.currencyId ?? ""))

  // Чистим данные от мусора, если он есть
  Object.keys(dataToResolve).map(k => {
    dataToResolve[k] = filterData(dataToResolve[k])
  })

  const result: { [T in ServiceFieldType]: OptionsCache<any> } = {
    number: {isPartialLoading: false, cache: {}},
    switch: {isPartialLoading: false, cache: {}},
    hidden: {isPartialLoading: false, cache: {}},
    tax: {isPartialLoading: false, cache: {}},
    location: {isPartialLoading: false, cache: {}},
    handbook: {isPartialLoading: false, cache: {}},
    currency: {isPartialLoading: false, cache: {}},
    consulting_contractor: {isPartialLoading: false, cache: {}},
    inspection_contractor: {isPartialLoading: false, cache: {}},
    customs_contractor: {isPartialLoading: false, cache: {}},
    certification_contractor: {isPartialLoading: false, cache: {}},
    insurance_contractor: {isPartialLoading: false, cache: {}}
  }

  const serviceCache = serviceCacheContext$.getValue()
  Object.keys(dataToResolve).map((k: ServiceFieldType) => {
    dataToResolve[k].map(id => {
      if (!serviceCache.Cache[k].cache[id]) {
        throw new Error(`no currency cache data for: ${id}`)
      }

      result[k].cache[id] = serviceCache.Cache[k].cache[id]
    })
  })

  return result
}

/**
 * loadServiceDataCache реализует внутренний метод для полноценной загрузки
 * данных для отображения дополнительных услуг. Позволяет синхронно с
 * загрузкой базовых данных получить и зависимые сущности.
 *
 * @param currencyID
 * @param directories
 * @param services
 * @param prices
 */
export const loadServiceDataCache = async (
  currencyID: string,
  directories: ServiceDirectory[],
  services: { [T in string]: Service[] },
  prices: { [T in string]: ServicesServicePriceCalculationMutationProps[] }
) => {
  // Добавляем данные директорий
  let serviceDataToLoad = exportContractors(directories, getInitialEntityID())

  // Добавляем данные услуг
  const allServices = Object.values(services)
    .map(s => s.map(serv => [serv, ...serv.additionServices]))
    .flat(2)

  serviceDataToLoad = exportContractors(allServices, serviceDataToLoad)

  // Добавляем данные ЦП
  Object.values(prices).flat(1)
    .map(p => {
      serviceDataToLoad.tax.push(String(p?.result?.data?.tax_id ?? ""))
      serviceDataToLoad.currency.push(String(p?.result?.data?.currency_id ?? ""))
    })

  // Чистим данные от мусора, если он есть
  Object.keys(serviceDataToLoad).map(k => {
    serviceDataToLoad[k] = filterData(serviceDataToLoad[k])
  })

  serviceDataToLoad.currency.push(currencyID)

  await loadEntityData(getUnloadedEntityID(serviceDataToLoad))
}