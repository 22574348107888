import { GridCellModes, GridCellModesModel, GridCellParams } from '@mui/x-data-grid';

const CellModesModel = (prevModel: GridCellModesModel, params: GridCellParams) => {
  return {
    // Revert the mode of the other cells from other rows
    ...Object.keys(prevModel).reduce(
      (acc, id) => ({
        ...acc,
        [id]: Object.keys(prevModel[id]).reduce(
          (acc2, field) => ({
            ...acc2,
            [field]: { mode: GridCellModes.View },
          }),
          {}
        ),
      }),
      {}
    ),
    [params.id]: {
      // Revert the mode of other cells in the same row
      ...Object.keys(prevModel[params.id] || {}).reduce(
        (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
        {}
      ),
      [params.field]: { mode: GridCellModes.Edit },
    },
  };
};

export default CellModesModel;
