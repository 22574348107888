interface Node {
  title: string;
  description: string;
}

export const getFormmatedNodes = (htmlString: string) => {
  const notesResult: Node[] = [];

  const wrapNode = document.createElement('div');

  wrapNode.innerHTML = htmlString;

  const foundNoteTitles = wrapNode.querySelectorAll('.black.bold');

  foundNoteTitles.forEach((title) => {
    const titleText = title.textContent;

    let nextElem: Element = title.nextElementSibling;

    const note: Node = { title: titleText, description: '' };
    while (nextElem && !nextElem.classList.contains('black')) {
      note.description += nextElem.innerHTML;

      nextElem = nextElem.nextElementSibling;
    }

    notesResult.push(note);
  });

  return notesResult;
};
