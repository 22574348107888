import {BehaviorSubject} from "rxjs";
import {useEffect, useState} from "react";

/**
 * WidgetTab описывает типизацию данных вкладок для виджета
 */
export type WidgetTab = {
  label: string
  data: any
  type: "delivery" | "service"
}

/**
 * WidgetTabsContext описывает контекст для работы с вкладками
 * виджета.
 */
export interface WidgetTabsContext {
  ActiveTab: number // Активная вкладка
  ActiveTabData: WidgetTab // Данные активной вкладки
  Tabs: WidgetTab[] // Все доступные вкладки
}

// Инициализация стейта табулятора виджета.
// Данные по умолчанию подтягиваются из кэша в localStorage.
const cache = localStorage.getItem("___ONLOG_WIDGET___TABS_CTX")
const tabsContext$ = new BehaviorSubject<WidgetTabsContext>({
  ActiveTab: 0,
  ActiveTabData: {
    label: "TubsContainer:Delivery",
    data: undefined,
    type: "delivery",
  },
  Tabs: [{
    label: "TubsContainer:Delivery",
    data: undefined,
    type: "delivery",
  }],

  ...(JSON.parse(cache?.length > 0 ? cache : "{}")) as Partial<WidgetTabsContext>,
})

/**
 * onChangeTab выполняет изменение активной вкладки по ее номеру
 * @param tab
 */
export const onChangeTab = (tab: number) => {
  const state = tabsContext$.getValue()
  const tabData = state.Tabs[tab]
  if (!tabData) {
    return
  }

  if (state.ActiveTab === tab) {
    return
  }

  tabsContext$.next({
    ...state,
    ActiveTab: tab,
    ActiveTabData: tabData,
  })
}

/**
 * pushTabs добавляет вкладки в стейт. Заменяет данные, если они уже
 * есть в стейте.
 * @param tabs
 */
export const pushTabs = (tabs: WidgetTab[]) => {
  const state = tabsContext$.getValue()
  const newTabs: WidgetTab[] = [
    {
      label: "TubsContainer:Delivery",
      data: undefined,
      type: "delivery",
    },
    ...tabs,
  ]

  let selectedTab = newTabs.findIndex(tab => tab.label === state.ActiveTabData.label)
  let selectedTabData = state.ActiveTabData

  if (selectedTab == -1) {
    selectedTab = 0
    selectedTabData = newTabs[0]
  }

  tabsContext$.next({
    ...state,
    Tabs: newTabs,
    ActiveTabData: selectedTabData,
    ActiveTab: selectedTab,
  })
}

/**
 * getTabsPipe возвращает subscriber для возможности подписки
 * на изменение активной вкладки и других данных.
 */
export const getTabsPipe = () => {
  return tabsContext$.pipe()
}

/**
 * init выполняет инициализацию контекста для работы с вкладками.
 */
const init = () => {
  // Сохранение контекста в localStorage
  tabsContext$.pipe().subscribe({
    next: state => {
      localStorage.setItem("___ONLOG_WIDGET___TABS_CTX", JSON.stringify(state))
    }
  })
}

const useWidgetTabsContext = () => {
  const [tabs, setTabs] = useState(tabsContext$.getValue())
  useEffect(() => {
    const subscription = tabsContext$.pipe().subscribe({
      next: state => setTabs({...state})
    })

    return () => {
      try {
        subscription.unsubscribe()
      } catch (e) {
      }
    }
  }, []);

  return {
    tabs,
    init,
    onChangeTab,
  }
}

export default useWidgetTabsContext