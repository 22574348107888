import React, {FC} from "react";
import {PropertyFieldDisplayProps} from "@pages/AdditionalServices/components/ServiceDirectory/ListTypeTemplate/types";
import ListFieldFilter from "@pages/AdditionalServices/components/ServiceDirectory/ListTypeTemplate/ListFieldFilter";
import SwitchFieldFilter
  from "@pages/AdditionalServices/components/ServiceDirectory/ListTypeTemplate/SwitchFieldFilter";
import NumberFilterField
  from "@pages/AdditionalServices/components/ServiceDirectory/ListTypeTemplate/NumberFilterField";

const PropertyFieldDisplay: FC<PropertyFieldDisplayProps<any>> = props => {
  const {data} = props
  switch (data.Property.type) {
    case "number":
      return <NumberFilterField {...props} />
    case "switch":
      return <SwitchFieldFilter {...props} />
    case "tax":
    case "location":
    case "handbook":
    case "currency":
    case "consulting_contractor":
    case "inspection_contractor":
    case "customs_contractor":
    case "certification_contractor":
    case "insurance_contractor":
      return <ListFieldFilter {...props} />
  }

  return null
}

export default PropertyFieldDisplay