import { getLocalizedName } from '@services/requests/localizedMessagesService/helpers/getLocalizedName';
import { taxService } from '@services/requests/taxService';

export interface TaxNameProps {
  taxId: string;
  localizedName: string;
}

const getTaxNames = async (langId: string): Promise<TaxNameProps[]> => {
  const requestResult = await taxService().Load({});
  let taxes: TaxNameProps[] = [];

  if (requestResult && requestResult.length > 0) {
    taxes = requestResult.map((tax) => {
      const item: TaxNameProps = {
        taxId: tax.id,
        localizedName: `${
          getLocalizedName(tax.localized_names_array, tax.default_name, [langId])[langId]
        } ${tax.amount}%`,
      };
      return item;
    });
  }

  return taxes;
};

export default getTaxNames;
