import React, {FC} from "react";
import {AdditionServiceFieldProps} from "@pages/AdditionalServices/components/FieldDisplay/AdditionServiceField/types";
import CheckboxField from "@pages/AdditionalServices/components/FieldDisplay/AdditionServiceField/CheckboxField";
import NumberField from "@pages/AdditionalServices/components/FieldDisplay/AdditionServiceField/NumberField";
import getLocalizationByArgs from "@helpers/getLocalizationByArgs";
import SelectField from "@pages/AdditionalServices/components/FieldDisplay/AdditionServiceField/SelectField";
import LocationSearchField
  from "@pages/AdditionalServices/components/FieldDisplay/AdditionServiceField/LocationSearchField";

/**
 * FieldCollectionComponent реализует общий компонент вывода поля по переданному типу и настройкам
 * @param props
 */
const FieldCollectionComponent: FC<AdditionServiceFieldProps> = props => {
  const {
    field,
    templateSettings,
    langID,
    value = field.value ?? 0,
    onChange,
    options,
    isOptionsLoading,
    isFieldsDisabled,
    error,
  } = props

  const settings = templateSettings ?? field.settings.DefaultSettings
  const tooltip = getLocalizationByArgs(langID, field.helper, field.localized_helpers)
  const placeholderText = settings.IsNeedHidePlaceholder
    ? ""
    : getLocalizationByArgs(langID, field.placeholder, field.localized_placeholders)

  switch (field.type) {
    case "hidden":
      return null
    case "switch":
      return <CheckboxField
        checked={value === 1}
        settings={settings}
        tooltip={tooltip}
        isFieldsDisabled={isFieldsDisabled}
        placeholder={placeholderText}
        onChange={() => onChange(value === 1 ? 0 : 1)}
      />
    case "number":
      return <NumberField
        value={value || 0}
        settings={settings}
        tooltip={tooltip}
        isFieldsDisabled={isFieldsDisabled}
        placeholder={placeholderText}
        onChange={v => onChange(v)}
      />
    case "currency":
    case "handbook":
    case "tax":
    case "consulting_contractor":
    case "customs_contractor":
    case "certification_contractor":
    case "inspection_contractor":
    case "insurance_contractor":
      return <SelectField
        fieldCode={field.code}
        value={value}
        settings={settings}
        tooltip={tooltip}
        isFieldsDisabled={isFieldsDisabled}
        error={error}
        placeholder={placeholderText}
        onChange={v => onChange(v)}
        options={options}
        isOptionsLoading={isOptionsLoading}
      />
    case "location":
      if (field.handbook_limitations.length == 0) {
        return <LocationSearchField
          fieldCode={field.code}
          value={value}
          settings={settings}
          tooltip={tooltip}
          error={error}
          isFieldsDisabled={isFieldsDisabled}
          placeholder={placeholderText}
          onChange={v => onChange(v)}
          options={options}
          isOptionsLoading={isOptionsLoading}
        />
      }

      return <SelectField
        fieldCode={field.code}
        value={value}
        settings={settings}
        tooltip={tooltip}
        error={error}
        isFieldsDisabled={isFieldsDisabled}
        placeholder={placeholderText}
        onChange={v => onChange(v)}
        options={options}
        isOptionsLoading={isOptionsLoading}
      />
  }

  return <></>
}

export default FieldCollectionComponent