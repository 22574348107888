import useMyRouting from '@context/useMyRouting/useMyRouting';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import {ServiceSectionDetailProps} from './types';
import ServiceData from "@components/pages/Products/components/ServiceSectionDetail/ServiceData";
import ServiceFileList from "@components/pages/Products/components/ServiceSectionDetail/ServiceFileList";
import ServiceSummaryComp from "@components/pages/Products/components/ServiceSummary";
import getSummaryForServices from "@pages/AdditionalServices/containers/methods/basket/getSummaryForServices";
import {useTranslation} from "react-i18next";

const ServiceSectionDetail: FC<ServiceSectionDetailProps> = props => {
  const {
    section: {services, sectionName},
  } = props
  const {t} = useTranslation('Common')
  const [settings] = useMyRouting().useSettings().useState();

  // Выводим наименование корневой директории услуг в качестве заголовка секции услуг
  const serviceSectionName =
    sectionName.localized_names.find((l) => l.lang_id === settings.primaryLanguageId)?.message ||
    sectionName.name;

  return (
    <>
      <Typography variant={'h2'} color={'common.black'} className={'section-title'}>
        {serviceSectionName}
      </Typography>
      {services.map(service => (
        <ServiceData
          key={service.id}
          langID={settings.primaryLanguageId}
          product={service}
        />
      ))}
      <ServiceSummaryComp
        title={`${t('Common:Total')} ${serviceSectionName}`}
        summary={getSummaryForServices(services, true)}
      />
      <ServiceFileList products={services}/>
    </>
  );
};

export default ServiceSectionDetail;
