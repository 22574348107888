import { YaTargetWrapper } from '@components/YaTargetWrapper/YaTargetWrapper';
import { Tabs } from '@material-ui/core';
import Tab from '@mui/material/Tab';
import { SortData } from '@services/requests/routeCalculator/routesCalculationService/interfaces';
import {
  AggregationData,
  RouteWidgetResult,
} from '@services/requests/routeCalculator/routesCalculationService/types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Container from './StyledComponents';

// Свойства компонента
export interface RouteListOrderTabsProps {
  sortData: SortData;
  baseFilterData: AggregationData | undefined;
  routes: RouteWidgetResult | undefined;
  disabled: boolean;
  onChangeSortData: { (sort: SortData): void };
}

const RouteListOrderTabs: React.FC<RouteListOrderTabsProps> = ({
  sortData,
  baseFilterData,
  disabled,
  onChangeSortData,
}) => {
  const { t } = useTranslation(['RoutesListComponent', 'Common']);
  const [isRefreshTriggered, setIsRefreshTriggered] = useState(false);

  useEffect(() => {
    if (!isRefreshTriggered) {
      setIsRefreshTriggered(true);
    }
  }, [isRefreshTriggered]);

  const handleChangeSort = (sort: 'price' | 'deliveryTime') => {
    if (sortData.by === sort) {
      return;
    }

    onChangeSortData({
      ...sortData,
      by: sort,
    });
  };

  if (!baseFilterData) {
    return null;
  }

  return (
    <Container>
      <YaTargetWrapper target="calculator_sorting" noAuthTarget="calculator_sorting_noreg">
        <Tabs
          value={sortData.by}
          variant="scrollable"
          scrollButtons="auto"
          onChange={(_, newValue) => handleChangeSort(newValue)}
        >
          <Tab
            value={'deliveryTime'}
            disabled={disabled}
            label={<span className={'item'}>{t('RoutesListComponent:TheFastest')}</span>}
          />
          <Tab
            value={'price'}
            disabled={disabled}
            label={<span className={'item'}>{t('RoutesListComponent:TheCheapest')}</span>}
          />
        </Tabs>
      </YaTargetWrapper>
    </Container>
  );
};

export default RouteListOrderTabs;
