import {ServiceFileData} from "@onlog-public/additional-services-types";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import FileItem from "@pages/AdditionalServices/components/Service/FileItem";

export interface FileListProps {
  files: ServiceFileData[]
}

const FileList: FC<FileListProps> = props => {
  const {files} = props
  const {t} = useTranslation('AdditionalService')

  return (
    <div className="a-service-file-list">
      <div className="header">{t('AdditionalService:FileListCaption')}</div>
      {files.map(file => (
        <FileItem key={file.id} file={file}/>
      ))}
    </div>
  )
}

export default FileList