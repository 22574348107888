import DefaultTable from '@components/UI/DefaultTable';
import ContainerTypeTableHeaderData from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/CargoTypeInformation/mocks';
import CargoTypeInformationStyledContainer from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/CargoTypeInformation/StyledComponents';
import { ContainersData } from '@services/requests/searchLoaders/containersLoader/ContainersLoaderQuery';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface CargoTypeInformationProps {
  containers: ContainersData[];
}
const CargoTypeInformation: FC<CargoTypeInformationProps> = ({ containers }) => {
  const { t } = useTranslation('RoutesCalculationWidget');
  const rows = containers.map((container) => ({
    id: container.id,
    default_name: container.default_name,
    estimated_volume: container.estimated_volume,
    use_full_volume: container.use_full_volume,
    gross_weight: container.gross_weight,
    net_weight: container.net_weight,
    boxing: container.boxing,
  }));
  return (
    <CargoTypeInformationStyledContainer>
      <div className={'title'}>{t('RoutesCalculationWidget:SpecificationsContainers')}</div>
      <DefaultTable rows={rows} columns={ContainerTypeTableHeaderData()} />
    </CargoTypeInformationStyledContainer>
  );
};

export default CargoTypeInformation;
