import React, {FC} from "react";
import {PropertyFieldDisplayProps} from "@pages/AdditionalServices/components/ServiceDirectory/ListTypeTemplate/types";
import SliderField from "@pages/AdditionalServices/components/ServiceDirectory/ListTypeTemplate/SliderField";
import ListDisplay from "@pages/AdditionalServices/components/ServiceDirectory/ListTypeTemplate/ListDisplay";

const NumberFilterField: FC<PropertyFieldDisplayProps<"number">> = props => {
  const {data, onChange} = props
  const {
    SliderSettings,
    ListSettings,
    Variant,
  } = props.data.Property.settings.Filter.NumberSettings

  if (Variant === "list") {
    return (
      <ListDisplay
        selected={data.FilterData.List.Selected}
        options={data.FilterData.List.Options}
        settings={ListSettings}
        onChange={v => onChange({
          ...data,
          FilterData: {
            ...data.FilterData,
            List: {
              ...data.FilterData.List,
              Selected: v,
            }
          }
        })}
      />
    )
  }

  return (
    <SliderField
      data={data.FilterData.Slider}
      settings={SliderSettings}
      onChange={v => onChange({
        ...data,
        FilterData: {
          ...data.FilterData,
          Slider: v
        }
      })}
    />
  )
}

export default NumberFilterField