import {ServiceField} from "@onlog-public/additional-services-types";

/**
 * calculateFieldValues выполняет расчет значений полей по формулам,
 * если они заданы для какого либо поля.
 * Если поле само является источником изменений, то вычисление значения
 * для него пропускается.
 *
 * @param fields
 * @param values
 * @param source
 * @param directoryValues
 */
export const calculateFieldValues = (
  fields: ServiceField[],
  values: { [T in string]: number },
  source?: string,
  directoryValues: { [T in string]: number } = {},
): { [T in string]: number } => {
  const result = {...values}

  let iframe = null;
  for (let i = window.frames.length - 1; i >= 0; i--) {
    if (window.frames[i].frameElement.id == 'a-service-iframe') {
      iframe = window.frames[i];
      break;
    }
  }

  if (!iframe) {
    return result
  }

  fields.map(field => {
    if (field.code === source || !field.settings.IsFieldValueShouldCalculate) {
      return
    }

    Object.keys(values).map(key => iframe[key] = values[key])
    Object.keys(directoryValues).map(key => iframe[key] = values[key])
    result[field.code] = iframe.eval(field.settings.FieldCalculationFormula)

    if (field.type !== "number") {
      result[field.code] = field.value
    }

    const settings = field.settings.DefaultSettings.NumberFieldSettings
    if (settings.LimitMaxValue !== undefined && result[field.code] > settings.LimitMaxValue) {
      result[field.code] = settings.LimitMaxValue
    }

    if (settings.LimitMinValue !== undefined && result[field.code] < settings.LimitMinValue) {
      result[field.code] = settings.LimitMinValue
    }
  })

  return result
}