import { Order } from '@services/requests/orderService/interface';

const BASKET_CONSTANTS = {
  order: {
    pre_order_id: '',
    currency_id: '1',
    currency_nominal: 0,
    currency_rate: 0,
    customer_email: '',
    customer_name: '',
    customer_phone: '',
    date: '',
    id: '',
    language_id: '1',
    order_price: 0,
    products: [],
    pre_order_products: [],
  } as Order,
};
export default BASKET_CONSTANTS;
