import { BitrixUser } from '@services/AuthService/loadUserQuery';

/**
 * Дефолтный пользователь для случая
 * когда получаемого нет и выключена
 * обязательная авторизация в env
 */
export class DefaultUser implements BitrixUser {
  public id: string = '0';
  public first_name: string = '';
  public last_name: string = 'default';
  public middle_name: string = 'user';
  public email: string = '';
  public phone: string = '';
  public transport_requests_per_month: number = 0;
}
