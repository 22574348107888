import { $isCalculationLimitExceeded } from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget';
import { localizedMessagesService } from '@services/requests/localizedMessagesService';
import { LocalizedMessage } from '@services/requests/localizedMessagesService/interfaces';
import { RoutesGroup } from '@services/requests/orderService/types';
import { $error } from '@settings/errorContext';
import { graphQLClient } from '@settings/services/graphQLClient';
import { GraphQLClient } from '@settings/services/graphQLClient/GraphQLClient';
import { isTooManyRequestsErrorPredicate } from '@settings/services/helpers/predicates';
import { loggerFactory } from '@settings/services/logger';
import { Logger } from '@settings/services/logger/Logger';

import { locationsBranchService } from '../locationsBranchSearchService';
import {
  BranchItem,
  LocationsBranchServiceInterface,
  SearchItem,
} from '../locationsBranchSearchService/interfaces';
import { ContainerRoutesAggregationQuery } from './ContainerRoutesAggregationQuery';
import { ContainerRoutesListQuery } from './ContainerRoutesListQuery';
import { CustomCargoRoutesAggregationQuery } from './CustomCargoRoutesAggregationQuery';
import { CustomCargoRoutesListQuery } from './CustomCargoRoutesListQuery';
import {
  FilterData,
  RouteCalculationParams,
  RoutesCalculationServiceInterface,
  SortData,
} from './interfaces';
import { AggregationData, AggregationResponse, RouteResponse, RouteWidgetResult } from './types';

const getLocalizedTerminals = (terminals: BranchItem[], messages: LocalizedMessage[]) =>
  terminals.reduce((acc: BranchItem[], t: BranchItem) => {
    const subItems: SearchItem[] = t.subItems.map((e) => {
      const localizedNamesArray: LocalizedMessage[] = e.localizedNames.reduce((acc, l) => {
        const locItem = messages.find((m) => m.id === l);
        if (locItem) {
          acc.push(locItem);
        }
        return acc;
      }, []);
      return {
        ...e,
        localizedNamesArray,
      };
    });
    const newItem: BranchItem = {
      ...t,
      subItems: [...subItems],
    };

    acc.push(newItem);
    return acc;
  }, []);

const getTerminalsFromShoulderSteps = (groups: RoutesGroup[]): string[] => {
  const terminalIds: string[] = [];
  groups.forEach((group) =>
    group.routes.forEach((route) =>
      route.route.steps.forEach((step) => {
        const startTerminal = step.startTerminal;
        const endTerminal = step.endTerminal;

        if (startTerminal) {
          terminalIds.push(startTerminal.id);
        }
        if (endTerminal) {
          terminalIds.push(endTerminal.id);
        }

        step.shoulder.shoulderSteps.forEach((s) => {
          if (s.startTerminal) {
            terminalIds.push(s.startTerminal.id);
          }
          if (s.endTerminal) {
            terminalIds.push(s.endTerminal.id);
          }
        });
      })
    )
  );
  return terminalIds;
};

/**
 * Сервис расчета списка маршрутов по переданным параметрам
 */
export class RoutesCalculationService implements RoutesCalculationServiceInterface {
  private readonly client: GraphQLClient;

  private readonly logger: Logger;

  private readonly locationsBranchService: LocationsBranchServiceInterface;

  /**
   * Конструктор сервиса
   * @param token
   */
  constructor(token?: string) {
    this.client = graphQLClient(token);
    this.logger = loggerFactory().make(`RoutesCalculationService`);
    this.locationsBranchService = locationsBranchService(token);
  }

  /**
   * Проверка переданных параметров на предмет того, что запрашивается сборный груз
   * @param params
   */
  private static isCustomCargo(params: RouteCalculationParams): boolean {
    return !!params.cargoType && ['container', 'auto'].indexOf(params.cargoType) === -1;
  }

  /**
   * Получение аггрегированных данных по листингу маршрутов для построения фильтра
   *
   * @param params
   * @param isArchive
   */
  async GetRoutesAggregation(
    params: RouteCalculationParams,
    isArchive: boolean
  ): Promise<AggregationData | undefined> {
    try {
      const resp = await this.client.Query<null, AggregationResponse>(
        RoutesCalculationService.isCustomCargo(params)
          ? new CustomCargoRoutesAggregationQuery(params, isArchive)
          : new ContainerRoutesAggregationQuery(params, isArchive),
        {},
        false
      );

      if (isTooManyRequestsErrorPredicate(resp.data)) {
        $isCalculationLimitExceeded.next(true);
        return;
      }

      const baseResult = resp.data;
      this.logger.Debug(`Loaded routes aggregation data`, baseResult);

      baseResult.containerRentType = ['COC', 'SOC_RENT'];

      return baseResult;
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  /**
   * Получение списка маршрутов
   *
   * @param params
   * @param isArchive
   * @param filterParameters
   * @param sort
   * @param isNeedLoadTerminals
   */
  async GetRoutesList(
    params: RouteCalculationParams,
    isArchive: boolean,
    filterParameters: Partial<FilterData> | undefined,
    sort: SortData,
    isNeedLoadTerminals: boolean
  ): Promise<
    | {
        routes: RouteWidgetResult;
        terminals: BranchItem[];
        startTerminals: BranchItem[];
        endTerminals: BranchItem[];
      }
    | undefined
  > {
    try {
      const resp = await this.client.Query<null, RouteResponse>(
        RoutesCalculationService.isCustomCargo(params)
          ? new CustomCargoRoutesListQuery(params, isArchive, filterParameters, sort)
          : new ContainerRoutesListQuery(params, isArchive, filterParameters, sort),
        {},
        false
      );

      if (isTooManyRequestsErrorPredicate(resp.data)) {
        $isCalculationLimitExceeded.next(true);
        return;
      }

      const baseResult = resp.data;
      this.logger.Debug(`Loaded routes aggregation data`, baseResult);

      const editableGroups = baseResult.groups.map((group) => {
        const editableRoutes = group.routes.map((route) => {
          const editableRoute = route.route.steps.map((step) => {
            const allowances = [
              ...step.shoulderAllowances.filter(
                (a) =>
                  a.allowanceOffer.allowance.allowance_group === '4' ||
                  a.allowanceOffer.allowance.allowance_group === '5'
              ),
              ...step.startTerminalAllowances,
              ...step.endTerminalAllowances,
            ];
            return {
              ...step,
              shoulderAllowances: step.shoulderAllowances.filter(
                (a) =>
                  a.allowanceOffer.allowance.allowance_group !== '4' &&
                  a.allowanceOffer.allowance.allowance_group !== '5'
              ),
              startTerminalAllowances: allowances.filter(
                (a) => a.allowanceOffer.allowance.allowance_group === '4'
              ),
              endTerminalAllowances: allowances.filter(
                (a) => a.allowanceOffer.allowance.allowance_group === '5'
              ),
            };
          });

          return {
            ...route,
            steps: editableRoute,
          };
        });
        return {
          ...group,
          routes: editableRoutes,
        };
      });

      const editableBaseResult = {
        ...baseResult,
        groups: editableGroups,
      };

      const terminalIds: string[] = filterParameters?.terminals?.map((t) => t.toString()) ?? [];
      const startTerminalIds: string[] =
        filterParameters?.startTerminals?.map((t) => t.toString()) ?? [];
      const endTerminalIds: string[] =
        filterParameters?.endTerminals?.map((t) => t.toString()) ?? [];

      const terminalsFromShoulderSteps = getTerminalsFromShoulderSteps(resp.data.groups);
      terminalIds.push(...terminalsFromShoulderSteps);

      if (!!params.startLocation && params.startLocation.type === 'terminal') {
        startTerminalIds.push(params.startLocation.id);
      }

      if (!!params.endLocation && params.endLocation.type === 'terminal') {
        endTerminalIds.push(params.endLocation.id);
      }

      this.logger.Debug(`Computed terminal ID's`, terminalIds);

      let terminals: BranchItem[] = [];
      let startTerminals: BranchItem[] = [];
      let endTerminals: BranchItem[] = [];
      if (terminalIds.length !== 0 && isNeedLoadTerminals) {
        terminals = await this.locationsBranchService.GetItemsByValue(terminalIds, 'terminal');
      }

      if (startTerminalIds.length !== 0 && isNeedLoadTerminals) {
        startTerminals = await this.locationsBranchService.GetItemsByValue(
          startTerminalIds,
          'terminal'
        );
      }
      if (endTerminalIds.length !== 0 && isNeedLoadTerminals) {
        endTerminals = await this.locationsBranchService.GetItemsByValue(
          endTerminalIds,
          'terminal'
        );
      }

      const localizedMessageIds = [...terminals, ...startTerminals, ...endTerminals].reduce(
        (result: string[], item: BranchItem): string[] => [
          ...result,
          ...item.localizedNames,
          ...item.localizedIso,
          ...item.subItems.map((s) => [...s.localizedNames]).flat(),
        ],
        []
      );

      // Загружаем все локализованные тексты
      const messages = await localizedMessagesService().GetMessagesArray(localizedMessageIds);

      this.logger.Debug(`Loaded terminals`, terminals);
      const transitLocalizedTerminals = getLocalizedTerminals(terminals, messages);
      const startLocalizedTerminals = getLocalizedTerminals(startTerminals, messages);
      const endLocalizedTerminals = getLocalizedTerminals(endTerminals, messages);

      return {
        routes: editableBaseResult,
        terminals: transitLocalizedTerminals,
        startTerminals: startLocalizedTerminals,
        endTerminals: endLocalizedTerminals,
      };
    } catch (e) {
      $error.next(e);
    }
  }
}
