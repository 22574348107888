import {ServiceDirectory} from "@onlog-public/additional-services-types";
import {clone} from "@pages/AdditionalServices/containers/methods/clone";
import {serviceContext$} from "@pages/AdditionalServices/containers/bus";
import {v4} from "uuid";

/**
 * setSelectedDirectory выполняет установку выбранной директории в каком либо
 * селекторе виджета.
 * @param level
 * @param directory
 */
export const setSelectedDirectory = (level: number, directory: ServiceDirectory) => {
  const state = clone(serviceContext$.getValue())

  const servicePathToSet: { [T in string]: ServiceDirectory } = {}
  for (let i = 0; i < level; i++) {
    servicePathToSet[String(i)] = state.ServicesPath[String(i)]
  }

  const directoryToSet = state.LoadedDirectoriesCache[directory.id] ?? directory
  const isNeedLoadData = !state.LoadedDirectoriesCache[directory.id]

  serviceContext$.next({
    ...state,
    IsNeedCalculateServices: false,
    IsLastLevelSelected: false,
    Variants: [],
    SelectedVariant: "",
    SelectedProductUUID: v4(),
    VariantServices: {},
    VariantFields: {},
    VariantFieldValues: {},
    ServiceTemplateStore: {},
    VariantsCalculationResult: {},
    LastServiceInPath: directoryToSet,
    ServicesPath: {
      ...servicePathToSet,
      [String(level)]: directoryToSet,
    },
    IsLoadingDirectoriesForPath: isNeedLoadData, // Отправляем текущую директорию на загрузку данных.
  })
}