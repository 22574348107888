import {GraphQLQuery} from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';
import {ServiceField, Service} from "@onlog-public/additional-services-types";

export type ServiceFieldResponse = Omit<
  ServiceField,
  "localized_helpers" |
  "localized_names" |
  "localized_placeholders" |
  "settings"
> & {
  localized_helpers: string[]
  localized_names: string[]
  localized_placeholders: string[]
  settings: string
}

export type ServiceResponse = Omit<
  Service,
  "additionServices" |
  "fields" |
  "localized_descriptions" |
  "localized_names" |
  "settings" |
  "file_id"
> & {
  localized_descriptions: string[]
  localized_names: string[]
  childs: ServiceResponse[]
  fields: ServiceFieldResponse[]
  settings: string
  file_id: string[]
}


export interface ServicesByDirectoryQueryResponse {
  services: ServiceResponse[]
}

/**
 * Запрос списка директорий дополнительных услуг по идентификатору
 * родительской директории.
 */
export class ServicesByDirectoryQuery implements GraphQLQuery<null> {
  readonly query: any;

  readonly variables: null;

    constructor(directory: number | null) {
      this.variables = null;
      this.query = gql`
        query ServicesServicesQuery {
            services: service_list(where:{parent:{_equals: ${directory ? String(directory) : `"null"`}}}) {
                description
                id
                localized_descriptions
                localized_names
                is_active
                is_broken
                contractor_id
                contractor_type
                file_id
                name
                parent
                settings
                template
                fields {
                    code
                    handbook_id
                    handbook_limitations
                    helper
                    id
                    localized_helpers
                    localized_names
                    localized_placeholders
                    name
                    placeholder
                    type
                    value
                    settings
                }
                price_offers {
                    currency_id
                    fields_with_limitations_ids
                    formula
                    is_active
                    is_default
                    id
                    is_include_tax
                    service_id
                    tax_id
                    fields_with_limitations {
                        id
                        is_maximal_value_limited
                        is_minimal_value_limited
                        is_switch_active
                        limitations
                        maximal_value
                        minimal_value
                        price_offer_id
                        services_field_id
                    }
                }
                property_values {
                    id
                    property_id
                    service_id
                    value
                }
                childs {
                    description
                    id
                    localized_descriptions
                    localized_names
                    is_active
                    is_broken
                    contractor_id
                    contractor_type
                    file_id
                    name
                    parent
                    settings
                    template
                    fields {
                        code
                        handbook_id
                        handbook_limitations
                        helper
                        id
                        localized_helpers
                        localized_names
                        localized_placeholders
                        name
                        placeholder
                        type
                        value
                        settings
                    }
                    price_offers {
                        currency_id
                        fields_with_limitations_ids
                        formula
                        is_active
                        is_default
                        id
                        is_include_tax
                        service_id
                        tax_id
                        fields_with_limitations {
                            id
                            is_maximal_value_limited
                            is_minimal_value_limited
                            is_switch_active
                            limitations
                            maximal_value
                            minimal_value
                            price_offer_id
                            services_field_id
                        }
                    }
                    property_values {
                        id
                        property_id
                        service_id
                        value
                    }
                }
            }
        }
    `;
    }
}
