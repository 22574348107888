import {ServiceField} from "@onlog-public/additional-services-types";
import getLocalizationByArgs from "@helpers/getLocalizationByArgs";
import React, {FC} from "react";
import {ServiceData, SetUpServiceData} from "@pages/AdditionalServices/containers/methods/basket/types";
import getFieldValuesFromData from "@pages/AdditionalServices/containers/methods/basket/getFieldValuesFromData";

export interface ServiceFieldsListProps {
  langID: string
  fields: ServiceField[]
  setUpService: SetUpServiceData
  otherServices?: ServiceData[]
}

const ServiceFieldsList: FC<ServiceFieldsListProps> = props => {
  const {
    langID,
    fields,
    setUpService,
    otherServices = [],
  } = props

  const {
    fields: fieldsToDisplay,
    values
  } = getFieldValuesFromData(langID, fields, setUpService, otherServices)

  if (0 === fields.length) {
    return null
  }

  return (
    <div className="a-service-basket-fields">
      <div className="list">
        {fieldsToDisplay.map(field => {
          let displayFieldName = getLocalizationByArgs(langID, field.name, field.localized_names)
          if (field.type !== "switch") {
            if (
              field.settings.DefaultSettings.IsNeedHideName ||
              displayFieldName.length === 0
            ) {
              displayFieldName = getLocalizationByArgs(langID, field.placeholder, field.localized_placeholders)
            }
          }

          return (
            <div key={field.code}>
              <div>{displayFieldName}</div>
              <div>{values[field.code]}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ServiceFieldsList