import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';

import Container from './StyledComponents';

// Свойства компонента
export interface HiddenContentProps {
    title: React.ReactNode;
    defaultVisibility?: boolean;
    indicator?: boolean;
    children: React.ReactNode;

    onReset(): void;
}

// Компонент вывода скрываемой вкладки
const HiddenContent: React.FC<HiddenContentProps> = (props) => {
    const {
        title,
        defaultVisibility,
        indicator = false,
        children,
        onReset,
    } = props;
    const [isVisible, setIsVisible] = useState(!!defaultVisibility);

	const { t } = useTranslation('FilterComponent');

    // Обработка переключения состояния видимости
    const handleToggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    return (
        <Container>
            <div className={'hidden__content__wrapper'} onClick={handleToggleVisibility}>
                <div className={'hidden__content-title'}>{title}</div>
                {indicator && <div className={'point'}/>}
                {isVisible && <KeyboardArrowDownIcon sx={{fontSize: '18px', color: '#707070'}}/>}
                {!isVisible && <KeyboardArrowUpIcon sx={{fontSize: '18px', color: '#707070'}}/>}
            </div>
            {isVisible && <>{children}</>}
            <div className={'reset'} onClick={onReset}>
				{t('FilterComponent:Reset')}
            </div>
        </Container>
    );
};

// Рендеринг компонента
export default HiddenContent;
