import breakpoints from '@assets/theme/breakpoints';
import palette from '@assets/theme/palette';
import Box from '@material-ui/core/Box/Box';
import { styled } from '@mui/material';

const RoutesCalculationWidgetStyledContainer = styled(Box)`
  .cargo-type-select {
    height: 56px;
    #arrow-select {
      font-size: 24px !important;
      color: ${({ theme }) => theme.palette.grey[700]} !important;
    }
  }

  .wrapper-actions-form {
    display: grid;
    grid-template-columns: 1fr 40px;
    gap: 20px;
  }

  .share-wrapper {
	margin-top: 24px;
	display: flex;
	gap: 24px;
	justify-content: center;
  }

  .reset {
    padding: 2px;
    background: grey;
    color: white;
  }

  .error-massage {
    position: absolute;
    bottom: 0;
    right: 0;
    color: #e22727;
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;

    @media (max-width: ${breakpoints.values.sm}px) {
      position: static;
      text-align: center;
      padding: 20px 0 0;
    }
  }

  .form-wrapper {
    position: relative;
    display: flex;
    gap: 16px;
    justify-content: space-between;
	align-items: flex-start;

    @media (max-width: ${breakpoints.values.lg}px) {
      gap: 20px;
    }

    @media (max-width: ${breakpoints.values.md}px) {
      display: grid;
      grid-template-columns: 1fr 50px;
    }

    @media (max-width: ${breakpoints.values.sm}px) {
      gap: 0;
      grid-template-columns: 1fr;
    }

    &__fields {
      flex: 1 1 100%;
      position: relative;
      display: grid;
      align-items: flex-start;
      grid-template-columns: 4.5fr 1fr 1fr;
      gap: 20px;
      padding-bottom: 15px;

      @media (max-width: ${breakpoints.values.miniLaptop}px) {
        grid-template-columns: 4.5fr minmax(140px, 1fr) minmax(120px, 0.8fr);
      }

      @media (max-width: ${breakpoints.values.md}px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: ${breakpoints.values.sm}px) {
        grid-template-columns: 1fr 1fr;
        margin-bottom: 20px;
      }

      .craco-date input {
        padding: 16.5px 0 16.5px 0;
      }

      .range-date {
        display: grid;
        grid-template-columns: 1fr 16px 1fr;
        gap: 5px;
        position: relative;

		.location-error {
			background-color: white;
			font-size: 12px;
			color: ${({ theme }) => theme.palette.error.main};
			font-weight: 600;
			padding: 6px 0;
			pointer-events: none;

			width: calc(100% - 60px);
			position: absolute;
			top: 50%;
			left: 14px;
			transform: translate(0, -50%);
			z-index: 2;
		}

        @media (max-width: ${breakpoints.values.md}px) {
          grid-column: span 2;
        }

        .MuiSwitch-root {
          & ~ .MuiTypography-root {
            color: ${({ theme }) => theme.palette.grey[700]};
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            margin-left: -2px;
            letter-spacing: normal;
          }
        }

        .MuiSelect-select {
          height: 56px;
        }
        .MuiInputBase-input {
          font-size: 14px;
          height: 56px;
          padding: 0 14px;
        }

        @media (max-width: ${breakpoints.values.sm}px) {
          grid-column: span 2;
          grid-template-columns: 1fr;
          gap: 20px;
        }

        .replace {
          margin-top: 20px;
          padding: 0;
        }
      }
    }

    &__search {
      margin-top: 3px;
      flex: 0 0 50px;
    }
  }

  .video-instruction {
    margin-left: auto;
    font-size: 14px;

    @media (max-width: ${breakpoints.values.sm}px) {
      font-size: 14px;
      text-align: center;
      margin: 20px auto 0;
      line-height: 14px;
    }

    &-block {
      position: absolute;
      bottom: 0;
      right: 0;

      @media (max-width: ${breakpoints.values.md}px) {
        position: static;
        grid-column: span 2;
        text-align: right;
      }
    }
  }

  .cargo-list {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-top: 15px;

    @media (max-width: ${breakpoints.values.laptop}px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 17px 30px;
    }

    @media (max-width: 700px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
      align-items: unset;
    }
  }

  .cargo-item {
    border-radius: 8px;
    border: 1px solid #dbe0ec;
    background: #fff;
    padding: 15px 17px;

    .MuiTypography-subtitle2 {
      margin-bottom: 15px;
    }

    @media (max-width: ${breakpoints.values.sm}px) {
      padding: 15px;
    }
  }

  .select-input {
    display: grid;
    grid-template-columns: 16px 1fr;
    gap: 8px;
    font-size: 14px;
    align-items: center;

    svg {
      font-size: 16px;
    }
  }

  .cargo-types {
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: center;
    gap: 7px;
    color: ${({ theme }) => theme.palette.grey[700]};
    margin: 15px 0 19px;

    @media (max-width: ${breakpoints.values.lg}px) {
      gap: 12px 7px;
      margin: 10px 0 19px;
    }

    @media (max-width: ${breakpoints.values.sm}px) {
      display: flex;
      gap: 4px;
      flex-wrap: wrap;
      margin: 6px 0 10px;
    }

    &__title {
      letter-spacing: 0.001px;
      white-space: nowrap;

      @media (max-width: ${breakpoints.values.sm}px) {
        flex: 0 0 100%;
        margin-top: 6px;
      }
    }

    button {
      white-space: nowrap;
    }

    .not-selected {
      color: #383838;
    }
  }

  .cargo-inputs-block-grid {
    display: grid;
    grid-template-areas:
      'width width length length height height'
      'weight weight weight seats seats seats';
    flex-wrap: wrap;
    gap: 13px 22px;

    .width {
      grid-area: width;
    }

    .length {
      grid-area: length;
    }

    .height {
      grid-area: height;
    }

    .weight {
      grid-area: weight;
    }

    .seats {
      grid-area: seats;
    }

    @media (max-width: ${breakpoints.values.sm}px) {
      gap: 14px;
    }

    &__text-field {
      display: flex;
      gap: 7px;
      align-items: center;

      .MuiTooltip-tooltip {
        max-width: max-content;
      }
    }

    &__tooltip {
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: all;
      cursor: pointer;
      font-size: 8px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.palette.grey[100]};
    }

    > .MuiTextField-root {
      flex: 1;
      min-width: clamp(80px, calc(100% / 3 - 60px), 100%);

      @media (max-width: ${breakpoints.values.sm}px) {
        &:not(.cargo-inputs-block__half-mobile) {
          min-width: fit-content;
        }
      }
    }

    &__half-mobile {
      @media (max-width: ${breakpoints.values.sm}px) {
        min-width: calc(50% - 5px);
      }
    }
  }

  .cargo-inputs-block {
    display: flex;
    flex-wrap: wrap;
    gap: 13px 22px;
    @media (max-width: ${breakpoints.values.sm}px) {
      gap: 14px;
    }

    &__text-field {
      display: flex;
      gap: 7px;
      align-items: center;

      .MuiTooltip-tooltip {
        max-width: max-content;
      }
    }

    &__tooltip {
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: all;
      cursor: pointer;
      font-size: 8px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.palette.grey[100]};
    }

    > .MuiTextField-root {
      flex: 1;
      min-width: clamp(80px, calc(100% / 3 - 60px), 100%);

      @media (max-width: ${breakpoints.values.sm}px) {
        &:not(.cargo-inputs-block__half-mobile) {
          min-width: fit-content;
        }
      }
    }

    &__half-mobile {
      @media (max-width: ${breakpoints.values.sm}px) {
        min-width: calc(50% - 5px);
      }
    }
  }

  .pallet-type {
    @media (max-width: ${breakpoints.values.sm}px) {
      margin-top: 8px;
    }
  }

  .MuiSelect-icon {
    font-size: 18px !important;
    fill: #383838;
  }

  .MuiInput-input {
    color: rgb(56, 56, 56) !important;
    text-align: right !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    padding: 4px 5px;
  }

  .base-params {
    display: flex;
    flex-wrap: wrap;
    gap: 18px 10px;
    @media (max-width: ${breakpoints.values.sm}px) {
      gap: 10px;
      flex-direction: column;
    }

    .MuiFormControlLabel-root {
      margin: 0;
      display: flex;
      align-items: center;
      gap: 10px;

      & .MuiTypography-root {
		font-size: 14px;
      }
    }

    .MuiCheckbox-root {
      padding: 0;
    }
  }

  .MuiFormControlLabel-root {
    margin: 10px 0 0 0;

    @media (max-width: ${breakpoints.values.sm}px) {
      gap: 10px;
    }

    .MuiFormControlLabel-label {
      color: #383838;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      white-space: nowrap;

      @media (max-width: ${breakpoints.values.sm}px) {
        font-weight: 600;
      }

      &,
      &.Mui-disabled {
        //color: ${({ theme }) => theme.palette.grey[300]};
      }
    }
  }

  .footer {
    margin-top: 30px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
    min-height: 28px;

    .right {
      display: flex;
      align-items: center;
      margin-left: auto;
      justify-content: center;
      gap: 20px;
    }

    @media (max-width: ${breakpoints.values.sm}px) {
      margin-top: 25px;
      font-size: 14px;
      flex-direction: row;
      display: flex;
      flex-wrap: nowrap;
    }

    .MuiFormControlLabel-label {
      @media (max-width: ${breakpoints.values.sm}px) {
        font-size: 14px;
      }
    }

    .MuiCheckbox-root {
      @media (max-width: ${breakpoints.values.sm}px) {
        padding: 0;
      }
    }
  }

  .add-new-cargo {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed rgba(65, 91, 117, 0.32);
    border-radius: 8px;
    height: 100%;
    width: 100%;
    min-height: 134px;
    background-color: transparent;

    @media (max-width: ${breakpoints.values.sm}px) {
      min-height: 0;
    }

    svg {
      font-size: 24px;
      color: ${palette.light.green[300]};
    }
  }

  .reset-button {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.palette.grey[800]};
    margin-left: auto;
    margin-bottom: 15px;
    display: flex;

    svg {
      width: 24px;
      fill: ${({ theme }) => theme.palette.grey[800]};
    }
  }

  .search-button {
    padding: 15px 15px 16px;
    font-size: 14px;

    &.Mui-disabled {
      background-color: ${({ theme }) => theme.palette.success.main};
      color: ${({ theme }) => theme.palette.common.white};
    }

    .MuiButton-startIcon {
      margin-right: 4px;
      margin-left: 0;
    }

    svg {
      font-size: 18px;

      @media (max-width: ${breakpoints.values.sm}px) {
        font-size: 12px;
      }
    }
  }

  .drawer-visible-part {
    pointer-events: all;
    display: grid;
    gap: 3px;
    line-height: 1;
    font-size: 12px;
    color: ${({ theme }) => theme.palette.common.black};
    font-weight: 400;
    margin-bottom: 30px;

    &__settings {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.28px;
      text-decoration-line: underline;
    }
  }

  .copy-success-alert {
	position: absolute;
	right: 0;
	bottom: 30px;
	display: flex;
	align-items: center;

	.MuiAlert-message {
		white-space: nowrap;
	}
  }

  .routes-calculation-widget {
    &__footer-reset-container {
		position: relative;
		display: flex;

      svg {
        font-size: 18px;
      }
    }

	&__share-container {
		display: flex;
		position: absolute;
		bottom: 0;
		right: 0;
	}
  }

  .date-picker {
	min-width: 152px;
    .MuiInputBase-input {
      font-size: 14px;
      height: 56px;
      padding: 0;
    }

    &__button {
      .MuiSvgIcon-root {
        font-size: 18px;
      }
    }
  }
`;

export default RoutesCalculationWidgetStyledContainer;
