import {Service, ServicesProperty} from "@onlog-public/additional-services-types";
import {servicesFilterProcessor} from "@pages/AdditionalServices/containers/services/servicesFilter/types";
import {FilterPropertyData, ListFilterData, NumberFilterData} from "../../types";

/**
 * NumberFilter реализует процессор фильтрации услуг по числовым свойствам.
 */
export class NumberFilter implements servicesFilterProcessor<"number"> {
  /**
   * Возвращает тип свойства, для которого реализован процессор
   */
  getType(): "number" {
    return "number"
  }

  /**
   * Выполняет фильтрацию услуг по переданным параметрам фильтрации.
   * Если запрошена фильтрация по стоимости, то считает ее, исходя
   * из переданных расчетов по услугам.
   *
   * @param services
   * @param filterParams
   */
  Filter(
    services: Service[],
    filterParams: FilterPropertyData<"number">,
  ): Service[] {
    const isNeedFilterBySlider =
      filterParams.FilterData.Slider.CurrentMax !== filterParams.FilterData.Slider.Max ||
      filterParams.FilterData.Slider.CurrentMin !== filterParams.FilterData.Slider.Min

    const isNeedFilterByList = filterParams.FilterData.List.Selected.length > 0

    let result = [...services]
    if (isNeedFilterByList) {
      result = this.filterByList(filterParams.Property, result, filterParams.FilterData.List)
    }

    if (isNeedFilterBySlider) {
      result = this.filterBySlider(filterParams.Property, result, filterParams.FilterData.Slider)
    }

    return result
  }

  /**
   * filterByList выполняет фильтрацию услуг по листингу опций
   * @param property
   * @param services
   * @param data
   * @protected
   */
  protected filterByList(
    property: ServicesProperty,
    services: Service[],
    data: ListFilterData,
  ): Service[] {
    return services.filter(service => {
      const value = service.property_values.find(v => {
        return String(v.property_id) === property.id
      })

      if (!value) {
        return false
      }

      return data.Selected.includes(value.value)
    })
  }

  /**
   * filterBySlider выполняет фильтрацию по минимальному и максимальному значению фильтра
   * @param property
   * @param services
   * @param data
   * @protected
   */
  protected filterBySlider(
    property: ServicesProperty,
    services: Service[],
    data: NumberFilterData,
  ): Service[] {
    return services.filter(service => {
      const value = service.property_values.find(v => {
        return String(v.property_id) === property.id
      })

      if (!value) {
        return false
      }

      return value.value <= data.CurrentMax && value.value >= data.CurrentMin
    })
  }
}