import {CurrencyData} from "@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery";
import {
  ServicesServicePriceCalculationMutationProps
} from "@services/requests/servicesService/servicesServicesService/interface";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import getServiceTotalPrice from "@pages/AdditionalServices/components/Service/getServiceTotalPrice";
import getServiceTotalTax from "@pages/AdditionalServices/components/Service/getServiceTotalTax";
import {ServiceInListingTemplate} from "@onlog-public/additional-services-types";

export interface ServicePriceColumnProps {
  settings: ServiceInListingTemplate
  priceByRequestText: string
  currency: CurrencyData
  services: ServicesServicePriceCalculationMutationProps[]
}

const ServicePriceColumn: FC<ServicePriceColumnProps> = props => {
  const {
    currency,
    services,
    settings,
    priceByRequestText,
  } = props

  const {t} = useTranslation('AdditionalService')

  const currencyGlyph = currency?.glyph.length > 0 ? currency?.glyph : currency?.code
  const totalPrice = getServiceTotalPrice(services)
  const totalTax = getServiceTotalTax(services)

  if (totalPrice < 0) {
    const text = priceByRequestText.length > 0
      ? t(priceByRequestText)
      : t('AdditionalService:CalculationOnRequest')

    return (
      <div className="price">
        <div className="header-text-only">{text}</div>
      </div>
    )
  }

  if (totalPrice === 0 && settings.IsNeedDisplayTextForZeroValues) {
    const text = settings.DisplayTextForZeroValues.length > 0
      ? t(settings.DisplayTextForZeroValues)
      : t('AdditionalService:IncludedInTheBasicService')

    return (
      <div className="price">
        <div className="header-text-only">{text}</div>
      </div>
    )
  }

  return (
    <div className="price">
      <div>{totalPrice.toLocaleString()} {currency?.code}</div>
      <div>{t('AdditionalService:ServicePriceTaxDescription')} {totalTax.toLocaleString()} {currencyGlyph}</div>
    </div>
  )
}

export default ServicePriceColumn