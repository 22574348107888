import { Client } from './Client';
import { FetchClient } from './interface';

let client: FetchClient | null = null;

export const fetchClientFactory = (baseUrl?: string) => {
  if (!client) {
    client = new Client(baseUrl);
  }

  return client;
};
