import {Service} from "@onlog-public/additional-services-types";
import {ServicePriceCalculationItemsProps} from "@services/requests/servicesService/servicesServicesService/interface";
import {
  ServiceToCalculateGenerator, serviceToCalculateGeneratorProcessor
} from "@pages/AdditionalServices/containers/services/serviceToCalculateGenerator/interface";

/**
 * BaseServiceToCalculateGenerator реализует сервис генерации запросов
 * расчета стоимости услуг.
 */
export class BaseServiceToCalculateGenerator implements ServiceToCalculateGenerator {
  protected processors: serviceToCalculateGeneratorProcessor[]

  /**
   * Конструктор сервиса
   * @param processors
   */
  constructor(processors: serviceToCalculateGeneratorProcessor[]) {
    this.processors = processors
  }

  /**
   * Generate выполняет генерацию запросов на расчет услуг
   * по переданным данным. Позволяет сформировать для услуги
   * сразу несколько запросов, например с разными значениями
   * полей.
   *
   * @param service
   * @param currency
   * @param fieldValues
   * @param serviceValues
   * @param serviceState
   */
  Generate(
    service: Service,
    currency: string,
    fieldValues: { [x: string]: number; },
    serviceValues: { [x: string]: number; },
    serviceState: any,
  ): ServicePriceCalculationItemsProps[] {
    const processor = this.processors.find(f => f.isAvailable(service))
    if (!processor) {
      return []
    }

    return processor.Generate(service, currency, fieldValues, serviceValues, serviceState)
  }
}