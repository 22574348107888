import { loggerFactory } from '@settings/services/logger';

import { localizedMessagesService } from '../../localizedMessagesService';
import { locationsAndTerminalSearchService } from '../locationsAndTerminalSearchService';
import { terminalByLocationLoader } from '../TerminalByLocationLoader';
import { LocationsBranchServiceInterface } from './interfaces';
import { LoadByLocationBranchProcessor } from './LoadByLocationBranchProcessor';
import { LoadByTerminalProcessor } from './LoadByTerminalProcessor';
import { LocationsBranchService } from './LocationsBranchService';

// Фабрика сервиса
export const locationsBranchService: {
  (token?: string): LocationsBranchServiceInterface;
} = (token) =>
  new LocationsBranchService(
    locationsAndTerminalSearchService(),
    loggerFactory(),
    localizedMessagesService(token),
    terminalByLocationLoader(token),
    new LoadByLocationBranchProcessor(token),
    new LoadByTerminalProcessor(token)
  );
