import { GraphQLQuery } from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

import { RouteCalculationParams } from './interfaces';

// Запрос аггрегации данных сборных грузов
export class CustomCargoRoutesAggregationQuery implements GraphQLQuery<null> {
  readonly query: any;

  readonly variables: null;

  constructor(params: RouteCalculationParams, isArchive: boolean) {
    this.variables = null;

    const customCargoParameters: string[] =
      params.customCargoParameters?.customCargoParameters.map((cargo) => {
        const type =
          cargo.parametersCargoCalcType === 'base'
            ? cargo.parametersType
            : cargo.parametersCargoCalcType;
        return `{
              parametersType: ${type},
              baseParameters: {
                quantity: ${cargo.baseParameters.quantity},
                length: ${cargo.baseParameters.length},
                width: ${cargo.baseParameters.width},
                height: ${cargo.baseParameters.height},
                weight: ${cargo.baseParameters.weight}
              },
              palletParameters:{
                type: ${cargo.palletParameters.type}
                quantity: ${cargo.palletParameters.quantity}
                normativeHeightOfStacking: ${cargo.palletParameters.normativeHeightOfStacking}
                weight: ${cargo.palletParameters.weight}
              },
              volumeParameters:{
                volume: ${cargo.volumeParameters.volume}
                weight: ${cargo.volumeParameters.weight}
              }
            }`;
      }) || [];

    const toLocationType =
      params.endLocation?.type === 'terminal' && params.endLocation?.symbolCode === 'ANY'
        ? 'location'
        : params.endLocation?.type;
    const fromLocationType =
      params.startLocation?.type === 'terminal' && params.startLocation?.symbolCode === 'ANY'
        ? 'location'
        : params.startLocation?.type;
    this.query = gql`
            query __CUSTOM_CARGO_AGGREGATION_QUERY__ {
                data: customCargoRoutesAggregation(
                    routePoints: {
                        from: {
                    ${fromLocationType}: ${params.startLocation?.id}
                    },
                    to: {
                    ${toLocationType}: ${params.endLocation?.id}
                    }
                    },
                    date: "${params.startExpeditionDate?.toISOString()}",
                    customCargoParameters: [
                        ${customCargoParameters.join(',')}
                    ],
                    startTransportingConditionId: ${params.startTransportingCondition},
                    endTransportingConditionId: ${params.endTransportingCondition},
                    isDangerousCargo: ${
                      params.customCargoParameters?.isDangerousCargo ? 'true' : 'false'
                    },
                    isArchiveCalculation: ${isArchive ? 'true' : 'false'},
                    targetCurrencyId: ${params.customCargoParameters?.targetCurrencyId},
                    isNeedPrekeridge: ${params.isNeedPrekeridge ? 'true' : 'false'},
                    requiredTransportingTypes: [${params.requiredTransportingTypes.join(',')}],  
                ) {
                    carriers
                    contractors
                    deliveryTime {
                        max
                        min
                    }
                    price {
                        max
                        min
                    }
                    transportingTypes
                    terminals
                    startTerminals
                    endTerminals
                }
            }
        `;
  }
}
