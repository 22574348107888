import { RouteStep } from '@services/requests/orderService/types';

import { EntityIdProcessorInterface, ProductEntityIdList } from '../interface';

/**
 * Процессор получения списка id сущностей участвующих в шаге маршрута
 */
export class StepsEntityIdProcessor implements EntityIdProcessorInterface {
  GetEntityIds(entityList: ProductEntityIdList, steps: RouteStep[]): ProductEntityIdList {
    steps.forEach((step) => {
      const { carrier_id, contractor_id, shoulder_type } = step.shoulder;

      const {
        startTerminalPrice,
        endTerminalPrice,
        shoulderOfferPriceCondition,
        shoulderAllowances,
        endTerminalAllowances,
        startTerminalAllowances,
        shoulderOffer,
        shoulder,
      } = step;

      const { shoulderSteps } = shoulder;

      const shoulderFiles: string[] = [];

      shoulderSteps.forEach((s) => {
        if (s.startTerminal && s.startTerminal.files) {
          shoulderFiles.push(...s.startTerminal.files);
        }
        if (s.endTerminal && s.endTerminal.files) {
          shoulderFiles.push(...s.endTerminal.files);
        }
      });

      // все типы налогов
      const taxes: string[] = [];

      startTerminalAllowances.forEach((a) => {
        taxes.push(a.allowanceOfferCondition.tax_id.toString());
      });

      endTerminalAllowances.forEach((a) => {
        taxes.push(a.allowanceOfferCondition.tax_id.toString());
      });

      taxes.push(shoulderOfferPriceCondition.tax_id.toString());

      // все типы валют для доп надбавок в плече
      const allowancesCurrencyIds = [
        ...endTerminalAllowances,
        ...startTerminalAllowances,
        ...shoulderAllowances,
      ]
        .filter((i) => !!i.allowanceOfferCondition)
        .map((i) => i.allowanceOfferCondition.currency_id);
      entityList.currencies.push(shoulderOfferPriceCondition.currency_id);
      entityList.currencies.push(...allowancesCurrencyIds);

      // Условия погрузки и разгрузки
      entityList.loadingTransportConditions.push(shoulderOffer.loading_condition_id);
      entityList.unloadingTransportConditions.push(shoulderOffer.unloading_condition_id);

      if (startTerminalPrice) {
        // Валюта терминала отправки
        entityList.currencies.push(startTerminalPrice.currency_id);
      }

      if (endTerminalPrice) {
        // Валюта терминала прибытия
        entityList.currencies.push(endTerminalPrice.currency_id);
      }

      entityList.carriers.push(carrier_id);
      entityList.contractors.push(contractor_id);
      entityList.shoulderTypes.push(shoulder_type);
      entityList.files.push(...shoulderFiles);
      entityList.taxes.push(...taxes);
    });

    return entityList;
  }
}
