import useMyRouting from '@context/useMyRouting/useMyRouting';
import { Order } from '@services/requests/orderService/interface';
import { BranchItem } from '@services/requests/routeCalculator/locationsBranchSearchService/interfaces';
import { GetLocalizedNamesForLocationsOrTerminals } from '@settings/services/helpers/GetLocalizedNamesForLocationsOrTerminals';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Пропсы компонента вывода списка маршрута
 */
export type Props = {
  value: BranchItem[];
  langCode?: string;
  order?: Order;
};

/**
 * Компонент вывода строки маршрута
 * @param props
 */
const RoutePathString = (props: Props) => {
  const { value, order, langCode } = props;

  const { t } = useTranslation('Common', { lng: langCode });
  const [settings] = useMyRouting().useSettings().useState();

  const { primaryLanguageId, secondaryLanguageId } = settings;

  let primaryLangId = primaryLanguageId;
  let secondaryLangId = secondaryLanguageId;

  if (order) {
    primaryLangId = order.language_id;
    secondaryLangId = order.language_id;
  }

  const path = value
    .map((data) => {
      // Получение локализованных названий по переданным данным
      const { primaryNames } = GetLocalizedNamesForLocationsOrTerminals(
        data,
        primaryLangId,
        secondaryLangId
      );

      const [primaryName, ...parentPrimaryNames] = primaryNames;
	  const country = parentPrimaryNames[parentPrimaryNames.length - 1];

      const pointType =
        data.type === 'terminal' ? `(${t('Common:Terminal')})` : `(${t('Common:Warehouse')})`;

      return `${country}, ${parentPrimaryNames[0]}, ${primaryName} ${pointType}`;
    })
    .join(' - ');

  return <>{path}</>;
};

export default RoutePathString;
