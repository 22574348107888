import {BehaviorSubject, Subject} from "rxjs";
import {
  ServiceDirectorySettings, ServiceFieldType,
} from "@onlog-public/additional-services-types";
import {
  AdditionServicesState,
  AServiceCache,
  AServicePropertyCache,
  AServicePropertyFilterAndOrder
} from "@pages/AdditionalServices/containers/types";
import {OptionsCache} from "@pages/AdditionalServices/containers/services/optionsLoader/types";
import {v4} from "uuid";

// Загрузка данных стейта из кэша, если он есть.
const localStorageData = JSON.parse(localStorage.getItem('__ONLOG_A_SERVICES_DATA__') ?? '{}') ?? {}
localStorageData?.RootServices?.map(s => {
  s.settings = ServiceDirectorySettings.RestoreSettings(s.settings)
})

// Контекст дополнительных услуг
export const serviceContext$ = new BehaviorSubject<AdditionServicesState>({
  RootServices: [],
  ServicesPath: {},
  IsLastLevelSelected: false,
  IsLoadingDirectoriesForPath: false,
  LoadedDirectoriesCache: {},
  LastServiceInPath: undefined,
  FieldsToDisplay: [],
  FieldValues: {},
  IsNeedCalculateServices: false,
  IsServicesLoading: false,
  Variants: [],
  SelectedVariant: "",
  SelectedProductUUID: v4(),
  VariantServices: {},
  VariantFields: {},
  VariantFieldValues: {},
  VariantSelectedServices: {},
  IsServicesCalculating: false,
  LastServiceCalculationID: "",
  ServiceTemplateStore: {},
  CurrencyID: "",
  LanguageID: "",
  VariantsCalculationResult: {},
  VariantProperties: {},
  PropertyValues: {},
  PropertiesToDisplay: [],

  ...localStorageData as Partial<AdditionServicesState>,
})

/**
 * serviceCacheContext$ содержит шину данных дополнительных услуг.
 * Содержит все данные, необходимые для корректного отображения услуг.
 * Пополняется при пересчете услуг, а так же при их первоначальной
 * загрузке.
 */
export const serviceCacheContext$ = new BehaviorSubject<AServiceCache>({
  Cache: {
    tax: {isPartialLoading: true, cache: {}},
    currency: {isPartialLoading: true, cache: {}},
    consulting_contractor: {isPartialLoading: true, cache: {}},
    inspection_contractor: {isPartialLoading: true, cache: {}},
    customs_contractor: {isPartialLoading: true, cache: {}},
    certification_contractor: {isPartialLoading: true, cache: {}},
    insurance_contractor: {isPartialLoading: true, cache: {}},
  } as { [T in ServiceFieldType]: OptionsCache<any> }
})

/**
 * servicePropertyValueCacheContext$ содержит шину данных для свойств
 * дополнительных услуг. Содержит все сп
 */
export const servicePropertyValueCacheContext$ = new BehaviorSubject<AServicePropertyCache>({
  Cache: {
    tax: {isPartialLoading: true, cache: {}},
    currency: {isPartialLoading: true, cache: {}},
    location: {isPartialLoading: true, cache: {}},
    handbook: {isPartialLoading: true, cache: {}},
    consulting_contractor: {isPartialLoading: true, cache: {}},
    inspection_contractor: {isPartialLoading: true, cache: {}},
    customs_contractor: {isPartialLoading: true, cache: {}},
    certification_contractor: {isPartialLoading: true, cache: {}},
    insurance_contractor: {isPartialLoading: true, cache: {}},
  } as { [T in ServiceFieldType]: OptionsCache<any> },
  Options: {},
  LangID: "",
})

/**
 * serviceFilterAndOrderContext$ содержит шину данных для фильтра
 * и сортировки дополнительных услуг по свойствам.
 */
export const serviceFilterAndOrderContext$ = new BehaviorSubject<AServicePropertyFilterAndOrder>({
  Variants: {},
})

/**
 * Шина пересчета значений стоимости услуг. Запускается при изменении полей
 * директорий и услуг.
 *
 * Состояние, передаваемое в шину имеет значение:
 *  - Если передан true, то происходит полный пересчет стейта фильтра свойств
 *  - Если передан false, то стейт фильтра свойств пересчитывается частично, т.е.
 *    считаются только фильтры и сортировки, при этом рассчитанный порядок услуг
 *    сохраняется. Это необходимо чтоб услуги не прыгали, когда пользователь в них
 *    меняет значения.
 */
export const serviceCalculationBus$ = new Subject<{isFieldChanges: boolean, isNeedChooseVariant: boolean}>()

/**
 * Шина загрузки данных услуг. Запускает получение списка услуг и их перерасчет
 * по завершению загрузки.
 */
export const serviceLoadingBus$ = new Subject<boolean>()