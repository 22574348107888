import React, {FC} from "react";
import {
  ServicesServicePriceCalculationMutationProps
} from "@services/requests/servicesService/servicesServicesService/interface";
import {ServiceDirectory, ServiceFieldType} from "@onlog-public/additional-services-types";
import {OptionsCache} from "@pages/AdditionalServices/containers/services/optionsLoader/types";
import {useTranslation} from "react-i18next";
import getLocalizationByArgs from "@helpers/getLocalizationByArgs";

export interface GroupTotalPriceProps {
  langID: string
  currencyID: string
  mainDirectory: ServiceDirectory
  serviceCalculations: ServicesServicePriceCalculationMutationProps[]
  selectedServices: string[]
  serviceDataCache: { [T in ServiceFieldType]: OptionsCache<any> }
}

const GroupTotalPrice: FC<GroupTotalPriceProps> = props => {
  const {
    langID,
    currencyID,
    mainDirectory,
    serviceCalculations,
    selectedServices,
    serviceDataCache,
  } = props
  const {t} = useTranslation('AdditionalService')

  const currency = serviceDataCache.currency.cache[currencyID]
  if (!currency) {
    return null
  }

  const directoryName = getLocalizationByArgs(langID, mainDirectory.name, mainDirectory.localized_names)

  let totalPrice = 0
  let totalTax = 0
  let totalSum = 0

  serviceCalculations
    .filter(c => selectedServices.includes(c.serviceId))
    .map(calc => {
      if (calc.result.result.fullPriceInTargetCurrency <= 0) {
        return
      }

      totalPrice += calc.result.result.priceInTargetCurrency
      totalTax += calc.result.result.taxInTargetCurrency
      totalSum += calc.result.result.fullPriceInTargetCurrency
    })

  return (
    <div className="a-service-template-group-total-price">
      <div>{t('AdditionalService:Templates.group.price')}</div>
      <div>{totalPrice.toLocaleString()} {currency.code}</div>
      <div>{t('AdditionalService:Templates.group.tax')}</div>
      <div>{totalTax.toLocaleString()} {currency.code}</div>
      <div className="total">
        <div>{t('AdditionalService:Templates.group.total')} {directoryName}:</div>
        <div>{totalSum.toLocaleString()} {currency.code}</div>
      </div>
    </div>
  )
}

export default GroupTotalPrice