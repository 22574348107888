import { getFormattedPrice } from '@pages/AdditionalServices/tabs/CustomsPayments/helpers/price';
import {
  CountriesResponse,
  CurrencyResponse,
} from '@services/requests/customsTaxService/interface';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CustomsPaymentProduct } from '../../context/types';

interface CustomsPaymentsDetailingMainProps {
  product: CustomsPaymentProduct;
  countries: CountriesResponse;
  currencies: CurrencyResponse;
  index: number;
  contractCurrency: CurrencyResponse[number];
  isExport: boolean;
}

function CustomsPaymentsDetailingMain({
  product,
  index,
  countries,
  currencies,
  contractCurrency,
  isExport,
}: CustomsPaymentsDetailingMainProps) {
  const { t } = useTranslation(['CustomsPayments']);
  const {
    country,
    code,
    codeDescription,
    weight,
    contractCurrencyCost,
    originCertificateExisting,
    selectedExcise,
    selectedExportFee,
    selectedFee,
    selectedVat,
  } = product;

  const foundCountry = countries.find((c) => c.Code === country);
  const foundCurrency = currencies.find((currency) => currency.code === contractCurrency.code);

  const formattedVatValue = selectedVat?.Value.split('|')[0] || '-';
  const formattedExciseValue = selectedExcise?.Value.split('|')[0] || '-';
  const formattedFeeValue = selectedFee?.Value.split('|')[0] || '-';
  const formattedExportFeeValue = selectedExportFee?.Value;

  const certificateText = originCertificateExisting
    ? t('CustomsPayments:Yes')
    : t('CustomsPayments:No');
  const countryText = isExport
    ? t('CustomsPayments:DestinationCountryPlaceholder')
    : t('CustomsPayments:OriginCountryPlaceholder');

  return (
    <div className="product-wrapper">
      <div className="title">
        {t('CustomsPayments:ProductTitle')} #{index + 1}
      </div>
      <div className="product-main-wrap">
        <div className="product-main-col">
          <div className="product-main">
            <div className="product-main__row">
              <div className="product-main__col">
                <span className="product-main__text">{countryText}:</span>
              </div>
              <div className="product-main__col">
                <span className="product-main__text">{foundCountry?.Name}</span>
              </div>
            </div>
            <div className="product-main__row">
              <div className="product-main__col">
                <span className="product-main__text">{t('CustomsPayments:HSCodeLabel')}:</span>
              </div>
              <div className="product-main__col">
                <span className="product-main__text">{code}</span>
              </div>
            </div>
            <div className="product-main__row">
              <div className="product-main__col">
                <span className="product-main__text">{t('CustomsPayments:ProductNameLabel')}:</span>
              </div>
              <div className="product-main__col">
                <span className="product-main__text">{codeDescription}</span>
              </div>
            </div>
            <div className="product-main__row">
              <div className="product-main__col">
                <span className="product-main__text">
                  {t('CustomsPayments:GrossWeightPlaceholder')}:
                </span>
              </div>
              <div className="product-main__col">
                <span className="product-main__text">{weight}</span>
              </div>
            </div>
            <div className="product-main__row">
              <div className="product-main__col">
                <span className="product-main__text">{t('CustomsPayments:PricePlaceholder')}:</span>
              </div>
              <div className="product-main__col">
                <span className="product-main__text">
                  {getFormattedPrice(+contractCurrencyCost, 2)} {foundCurrency.encode}
                </span>
              </div>
            </div>
            <div className="product-main__row">
              <div className="product-main__col">
                <span className="product-main__text">
                  {t('CustomsPayments:CertificateOriginAvailable')}:
                </span>
              </div>
              <div className="product-main__col">
                <span className="product-main__text">{certificateText}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="product-main-col product-main-additional-tax">
          <div className="product-main">
            <div className="product-main__row">
              <div className="product-main__col">
                <span className="product-main__text">{t('CustomsPayments:VatGroupTitle')}:</span>
              </div>
              <div className="product-main__col">
                <span className="product-main__text">{formattedVatValue}</span>
              </div>
            </div>
            <div className="product-main__row">
              <div className="product-main__col">
                <span className="product-main__text">{t('CustomsPayments:ExciseGroupTitle')}:</span>
              </div>
              <div className="product-main__col">
                <span className="product-main__text">{formattedExciseValue}</span>
              </div>
            </div>
            <div className="product-main__row">
              <div className="product-main__col">
                <span className="product-main__text">{t('CustomsPayments:DutyGroupTitle')}:</span>
              </div>
              <div className="product-main__col">
                <span className="product-main__text">
                  {isExport ? formattedExportFeeValue : formattedFeeValue}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { CustomsPaymentsDetailingMain };
