import React, {FC} from "react";
import {ServiceDirectory, ServiceFieldType} from "@onlog-public/additional-services-types";
import {OptionsCache} from "@pages/AdditionalServices/containers/services/optionsLoader/types";
import getContractorFromDirectory
  from "@pages/AdditionalServices/components/ServiceDescription/getContractorFromDirectory";
import {useTranslation} from "react-i18next";
import getLocalizationByArgs from "@helpers/getLocalizationByArgs";

export interface ServiceDescriptionProps {
  langID: string
  variant: string
  variants: ServiceDirectory[]
  servicesPath: { [T in string]: ServiceDirectory }
  contractorsCache: { [T in ServiceFieldType]: OptionsCache<any> }
}

/**
 * ServiceDescription выводит описание для дополнительных услуг, если оно есть.
 * Так же выводит текст с подрядчиком первой строкой, если он задан.
 *
 * @param props
 */
const ServiceDescription: FC<ServiceDescriptionProps> = props => {
  const {
    langID,
    variant,
    variants,
    servicesPath,
    contractorsCache,
  } = props

  const {t} = useTranslation(['AdditionalService']);

  const selectedVariant = variants.find(v => v.id === variant)
  if (!selectedVariant) {
    return null
  }

  const contractor = getContractorFromDirectory(langID, selectedVariant, contractorsCache)
  const descriptions = [...Object.values(servicesPath), selectedVariant]
    .map(d => {
      return getLocalizationByArgs(langID, d.description, d.localized_descriptions)
    })
    .filter(d => d.length > 0)

  return (
    <div className="a-service-data-description">
      {contractor.length > 0 && (
        <div>{t('AdditionalService:ServiceDescriptionContractor')}: {contractor}</div>
      )}
      {descriptions.map((d, i) => (
        <div key={i} dangerouslySetInnerHTML={{__html: d}}/>
      ))}
    </div>
  )
}

export default ServiceDescription