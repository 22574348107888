import { RouteInGroup } from '@services/requests/orderService/types';

import { EntityIdProcessorInterface, ProductEntityIdList } from '../interface';
import { StepsEntityIdProcessor } from './StepsEntityIdProcessor';

/**
 * Процессор получения списка id сущностей участвующих в маршруте
 */
export class RoutesEntityIdProcessor implements EntityIdProcessorInterface {
  GetEntityIds(entityIdList: ProductEntityIdList, routes: RouteInGroup[]): ProductEntityIdList {
    routes.forEach((route) => {
      const { containerRent, pickOn, dropOff } = route.route;

      const {
        carrier_id: containerRentCarrierId,
        contractor_id: containerRentContractorId,
        currency_id: containerRentCurrencyId,
      } = containerRent || { currency_id: '', contractor_id: '', carrier_id: '' };

      const {
        carrier_id: pickOnCarrierId,
        contractor_id: pickOnContractorId,
        currency_id: pickOnCurrencyId,
      } = pickOn || { currency_id: '', contractor_id: '', carrier_id: '' };

      const {
        carrier_id: dropOffCarrierId,
        contractor_id: dropOffContractorId,
        currency_id: dropOffCurrencyId,
      } = dropOff || { currency_id: '', contractor_id: '', carrier_id: '' };

      // Добавляем всех перевозчиков
      entityIdList.carriers.push(containerRentCarrierId, pickOnCarrierId, dropOffCarrierId);

      // Добавляем всех подрядчиков
      entityIdList.contractors.push(
        containerRentContractorId,
        pickOnContractorId,
        dropOffContractorId
      );

      // Добавляем все валюты
      entityIdList.currencies.push(containerRentCurrencyId, pickOnCurrencyId, dropOffCurrencyId);

      const { steps } = route.route;

      new StepsEntityIdProcessor().GetEntityIds(entityIdList, steps);
    });

    return entityIdList;
  }
}
