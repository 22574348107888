import { getUserId } from '@context/useBitrixUser/useBitrix';
import { getDaysInMonth, getMonth, getYear, isBefore, set } from 'date-fns';

import { SavedCart } from './interfaces';
import { LOCALSTORAGE_CART_KEY } from './useBasket';

export const LOCALSTORAGE_CART_EXPIRE_DATE_KEY = '__CART_EXPIRE_DATE__';

export const checkValidityCartState = (cartState: unknown[]) => {
  return cartState.every(
    (state) =>
      typeof state === 'object' &&
      'userId' in state &&
      typeof state.userId === 'string' &&
      'orderId' in state &&
      typeof state.orderId === 'string'
  );
};

export const getSavedCartState = () => {
  const savedCartState: SavedCart[] = JSON.parse(
    localStorage.getItem(LOCALSTORAGE_CART_KEY) || `[]`
  );

  if (!Array.isArray(savedCartState) || !checkValidityCartState(savedCartState)) {
    return [];
  }

  return savedCartState;
};

export const getSavedCartStateByUserId = (cartState: SavedCart[]) => {
  const id = getUserId();
  return cartState.find((cart) => cart.userId === id);
};

export const removeSavedCartStateByUserId = (cartState: SavedCart[]) => {
  const id = getUserId();
  const filteredSavedCarts = cartState.filter((cart) => cart.userId !== id);
  localStorage.setItem(LOCALSTORAGE_CART_KEY, JSON.stringify(filteredSavedCarts));
};

export const setSavedCartState = (orderId: string) => {
  const savedCartState = getSavedCartState();
  const id = getUserId();
  const foundUserCartIndex = savedCartState.findIndex((state) => state.userId === id);
  const isUserAlreadyExist = foundUserCartIndex !== -1;

  if (isUserAlreadyExist) {
    const copyCartState = savedCartState.slice();
    copyCartState.splice(foundUserCartIndex, 1);

    localStorage.setItem(
      LOCALSTORAGE_CART_KEY,
      JSON.stringify([...copyCartState, { userId: id, orderId }])
    );
    return;
  }

  localStorage.setItem(
    LOCALSTORAGE_CART_KEY,
    JSON.stringify([...savedCartState, { userId: id, orderId }])
  );
};

const getNextExpireDate = () => {
  const currentDate = new Date();
  const startTime = { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 };

  const daysInCurrentMonth = getDaysInMonth(currentDate);
  const currentMonthFirstDate = set(currentDate, {
    date: 15,
    ...startTime,
  });
  const currentMonthSecondDate = set(currentDate, {
    date: daysInCurrentMonth < 30 ? daysInCurrentMonth : 30,
    ...startTime,
  });

  if (isBefore(currentDate, currentMonthFirstDate)) {
    return currentMonthFirstDate;
  } else if (isBefore(currentDate, currentMonthSecondDate)) {
    return currentMonthSecondDate;
  } else {
    const currentYear = getYear(currentDate);
    const currentMonth = getMonth(currentDate);

    const isLastMonth = currentMonth === 11;
    const nextYear = isLastMonth ? currentYear + 1 : currentYear;
    const nextMonth = isLastMonth ? 0 : currentMonth + 1;
    const nextMonthFirstDate = set(currentDate, {
      year: nextYear,
      month: nextMonth,
      date: 15,
      ...startTime,
    });

    return nextMonthFirstDate;
  }
};

export const checkCartExpireDate = () => {
  const cartExpireDate = localStorage.getItem(LOCALSTORAGE_CART_EXPIRE_DATE_KEY);
  const parsedExpireDateTimestamp = JSON.parse(cartExpireDate ?? '0');
  const nextExpireDate = getNextExpireDate();

  if (!cartExpireDate || typeof parsedExpireDateTimestamp !== 'number') {
    localStorage.removeItem(LOCALSTORAGE_CART_KEY);
    localStorage.setItem(LOCALSTORAGE_CART_EXPIRE_DATE_KEY, nextExpireDate.getTime().toString());
    return;
  }

  const expireTargetDate = new Date(parsedExpireDateTimestamp);
  const now = new Date();

  if (now.getTime() > expireTargetDate.getTime()) {
    localStorage.removeItem(LOCALSTORAGE_CART_KEY);
    localStorage.setItem(LOCALSTORAGE_CART_EXPIRE_DATE_KEY, nextExpireDate.getTime().toString());
  }
};
