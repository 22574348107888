import { getLocalizationById } from '@context/useMyRouting/helpers';
import Typography from '@mui/material/Typography';
import useBasket from '@pages/Basket/useBasket';
import clsx from 'clsx';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  type: string;
  isIncluded: boolean;
  borderBottom?: boolean;
}

/**
 * Компонент выводит данные при отсутствии надбавок
 */
const TerminalsInfoRowIncludedAllowance: FC<Props> = ({ type, isIncluded, borderBottom }) => {
  const [order] = useBasket().useOrder().useState();
  const langCode = getLocalizationById(order.language_id).countryCode;
  const { t } = useTranslation(['OfferDetail'], { lng: langCode });

  let unloadingLoadingName = t('OfferDetail:Unloading/loading');

  if (type === 'load') {
    unloadingLoadingName = t('OfferDetail:LoadingTerminal');
  }

  if (type === 'unload') {
    unloadingLoadingName = t('OfferDetail:UnloadingTerminal');
  }

  return (
    <div className={clsx('unloading-loading', { '--border-bottom': borderBottom })}>
      <Typography variant={'body2'} component={'div'} className={'unloading-loading__section'}>
        <span
          dangerouslySetInnerHTML={{
            __html: unloadingLoadingName,
          }}
        />
      </Typography>
      <Typography variant={'body2'} component={'div'} className={'unloading-loading__section'}>
        {isIncluded ? t('OfferDetail:Included') : t('OfferDetail:NotIncluded')}
      </Typography>
    </div>
  );
};

export default TerminalsInfoRowIncludedAllowance;
