import Button from '@mui/material/Button';
import ErrorBoundaryStyledContainer from '@settings/ErrorBoundary/StyledComponent';
import { $error } from '@settings/errorContext';
import { loggerFactory } from '@settings/services/logger';
import React, { Component, ErrorInfo, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface ErrorBoundaryState {
  hasError: boolean;
}
interface Props {
  children: React.ReactNode;
  needJSX: boolean;
}

const ErrorBoundaryTitle: FC<{ back: () => void }> = ({ back }) => {
  const { t } = useTranslation('Common');

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <ErrorBoundaryStyledContainer elevation={4}>
      <h1>{t('Common:SomethingWrong')}</h1>
      <Button onClick={back}>{t('Common:Reload')}</Button>
    </ErrorBoundaryStyledContainer>
  );
};

class ErrorBoundary extends Component<Props, ErrorBoundaryState> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    const logger = loggerFactory().make(`ErrorBoundary`);

    logger.Error(`Error:`, error, info.componentStack);
  }

  back = () => {
    localStorage.clear();
    history.pushState(null, '', window.location.pathname);
    $error.next(undefined);
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      // Выполните редирект на специальную страницу обработки ошибок
      // Например, используя React Router: this.props.history.push('/error-page');

      if (!this.props.needJSX) {
        return <></>;
      }

      return <ErrorBoundaryTitle back={this.back} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
