import {HandbookVersion, ServiceFieldType} from "@onlog-public/additional-services-types";
import {
  optionLoaderProcessor,
  OptionsCache,
} from "@pages/AdditionalServices/containers/services/optionsLoader/types";
import {handbookVersionLoader} from "@services/requests/searchLoaders/handbookVersionLoader";

/**
 * HandbookProcessor реализует процессор для загрузки данных
 * для полей дополнительных справочников.
 */
export class HandbookProcessor implements optionLoaderProcessor {
  /**
   * GetType возвращает тип поля, для которого собран процессор
   */
  GetType(): ServiceFieldType {
    return "handbook"
  }

  /**
   * LoadOptionsCache выполняет загрузку кэша определенного типа
   * В качестве аргумента принимает тип поля и идентификаторы для загрузки.
   *
   * @param id
   */
  async LoadOptionsCache(id?: string[]): Promise<OptionsCache<any>> {
    if (!id) {
      return {isPartialLoading: true, cache: {}}
    }

    const cache: OptionsCache<HandbookVersion> = {isPartialLoading: true, cache: {}}
    const versions = await handbookVersionLoader().Load(id)
    versions.map(v => cache.cache[v.id] = v)

    return cache
  }
}