import getEnv from '@settings/getEnv';
import { loggerFactory } from '@settings/services/logger';

import { FilesService } from './FilesService';
import { FilesServiceInterface } from './interface';

// Фабрика сервиса
export const filesService: { (): FilesServiceInterface } = () => {
  const { REACT_APP_FILE_UPLOADING_ENDPOINT, REACT_APP_FILE_VIEW_ENDPOINT } = getEnv();
  return new FilesService(
    loggerFactory(),
    REACT_APP_FILE_UPLOADING_ENDPOINT,
    REACT_APP_FILE_VIEW_ENDPOINT
  );
};
