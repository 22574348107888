import { $error } from '@settings/errorContext';
import { graphQLClient } from '@settings/services/graphQLClient';
import { GraphQLClient } from '@settings/services/graphQLClient/GraphQLClient';

import { SearchResult } from '../locationsAndTerminalSearchService/types';
import {
  BranchItem,
  _LocationsBranchServiceProcessorInterface,
} from '../locationsBranchSearchService/interfaces';
import { LoadByLocationBranchProcessor } from '../locationsBranchSearchService/LoadByLocationBranchProcessor';
import { TerminalByIdQueryResult } from '../locationsBranchSearchService/TerminalByIdQuery';
import { TerminalByLocationLoaderInterface } from './interface';
import { TerminalInParentLocationIdQuery } from './TerminalInParentLocationIdQuery';

/**
 * Процессор получения ветки для терминала по ID родительской локации
 */
export class TerminalByLocationLoader implements TerminalByLocationLoaderInterface {
  private readonly client: GraphQLClient;

  private readonly loadByLocationProcessor: _LocationsBranchServiceProcessorInterface;

  /**
   * Конструктор сервиса
   * @param token
   */
  constructor(token?: string) {
    this.client = graphQLClient(token);
    this.loadByLocationProcessor = new LoadByLocationBranchProcessor(token);
  }

  /**
   * Поиск ветки локаций процессором
   * @param locationIds
   */
  async Load(locationIds: string[], extraLocations?: string[]): Promise<BranchItem[]> {
    try {
      const resp = await this.client.Query<{ id: string[] }, TerminalByIdQueryResult>(
        new TerminalInParentLocationIdQuery(locationIds),
        {}
      );

      const parentBranch = await this.loadByLocationProcessor.GetBranch([
        ...resp.transport_terminal_list.map(
          (term) =>
            ({
              id: term.location_id,
              type: 'location',
            } as SearchResult)
        ),
        ...((extraLocations?.map((location) => ({ id: location, type: 'location' })) ||
          []) as SearchResult[]),
      ]);

      const terminals = resp.transport_terminal_list.map(
        (term) =>
          ({
            id: term.id,
            type: 'terminal',
            isUserSearchable: true,
            visibleName: term.default_name,
            iso: term.iso,
            symbolCode: '',
            localizedNames: term.localized_names,
            localizedIso: term.iso_localization,
            localizedNamesArray: [],
            localizedIsoArray: [],
            parentId: term.location_id,
            subItems: [],
            files: [],
            coords: {
              lat: term.lat,
              lng: term.lng,
            },
          } as BranchItem)
      );

      return [...parentBranch, ...terminals];
    } catch (e) {
      $error.next(e);
    }
  }
}
