import Box from '@material-ui/core/Box/Box';
import { styled } from '@mui/material';

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .MuiFormControlLabel-root {
    margin-left: unset;
  }

  .choose-all {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.grey[700]};
    transition: 400ms;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;

    &:hover {
      color: #444444;
    }
  }

  .MuiButtonBase-root {
    padding: 0;
    margin-right: 5px;
  }

  .show-all {
    cursor: pointer;
    margin-top: 6px;
    color: #707070;
    transition: 400ms;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    &:hover {
      color: #444444;
    }
  }
`;

export const ShowHideAll = styled('div')`
  cursor: pointer;
  margin-top: 6px;
  color: ${({ theme }) => theme.palette.grey[700]};
  transition: 400ms;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  &:hover {
    color: #444444;
  }
`;

export default Container;
