import React, {FC} from "react";
import {CargoDeclarationTemplate} from "@onlog-public/additional-services-types";
import {useTranslation} from "react-i18next";

export interface TableHeaderProps {
  settings: CargoDeclarationTemplate
}

const TableHeader: FC<TableHeaderProps> = props => {
  const {settings} = props
  const {t} = useTranslation('AdditionalService')

  const nameColumn = t('AdditionalService:Templates.CargoDeclaration.NameColumn')
  const quantityColumnName = t(settings.QuantityColumnName)
  const priceColumn = t('AdditionalService:Templates.CargoDeclaration.PriceColumn')
  const sumColumn = t('AdditionalService:Templates.CargoDeclaration.SumColumn')
  const courseColumn = t('AdditionalService:Templates.CargoDeclaration.CourseColumn')
  const conversionColumn = t('AdditionalService:Templates.CargoDeclaration.ConversionColumn')
  const sumInTargetColumn = t('AdditionalService:Templates.CargoDeclaration.SumInTargetColumn')
  const taxColumn = t('AdditionalService:Templates.CargoDeclaration.TaxColumn')
  const totalColumn = t('AdditionalService:Templates.CargoDeclaration.TotalColumn')

  return <>
    <div className="header no-left-border">{nameColumn}</div>
    <div className="header">{priceColumn}</div>
    <div className="header">{quantityColumnName}</div>
    <div className="header">{sumColumn}</div>
    <div className="header">{courseColumn}</div>
    <div className="header">{conversionColumn}</div>
    <div className="header">{sumInTargetColumn}</div>
    <div className="header tax">{taxColumn}</div>
    <div className="header">{totalColumn}</div>
  </>
}

export default TableHeader