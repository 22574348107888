import { currencyLoader } from '@services/requests/searchLoaders/currencyLoader';
import { useStateSubscriber } from '@settings/core/stateContexts/useContextSubscriber';
import i18n from 'i18next';
import { BehaviorSubject } from 'rxjs';

import { LOCALIZATION_DEPENDENCY } from './consts';
import { getOuterCurrencyCode, getOuterLanguage } from './helpers';
import { Pages } from './index';

interface Settings {
  primaryLanguageId: string;
  secondaryLanguageId: string;
  currencyId: string;
  countryCode: string;
}

interface LocalizationDependencies {
  id: string;
  currencyCode: string;
  countryCode: string;
  secondaryLanguageId: string;
  currencyId: string;
}

const getPath = () => {
  const urlPath = window.location.pathname;

  if (urlPath.includes('basket')) {
    return 'basket';
  }

  if (urlPath.includes('pre-order')) {
    return 'pre-order';
  }

  if (urlPath.includes('order')) {
    return 'order';
  }
  return 'widget';
};

const path = getPath();
const $path = new BehaviorSubject<Pages>(path as Pages);
const $history = new BehaviorSubject<Pages[]>(['widget']);
const $settings = new BehaviorSubject<Settings>({
  primaryLanguageId: '',
  secondaryLanguageId: '',
  currencyId: '',
  countryCode: '',
});
const $localizationDeps = new BehaviorSubject<LocalizationDependencies[]>([]);

const init = async () => {
  const lang = getOuterLanguage();
  const currencyCode = getOuterCurrencyCode();

  i18n.changeLanguage(lang);

  const currencies = await currencyLoader().Load();

  const targetCurrencies = LOCALIZATION_DEPENDENCY.map((local) => local.currencyCode);

  const filteredCurrencies = currencies.filter((currency) =>
    targetCurrencies.includes(currency.code)
  );

  const localizationDepsWithCurrencies = LOCALIZATION_DEPENDENCY.map((local) => {
    const localCurrencyCode = local.currencyCode;

    const foundCurrency = filteredCurrencies.find(
      (currency) => currency.code === localCurrencyCode
    );

    return {
      ...local,
      currencyId: foundCurrency.id || '1',
    };
  });

  const foundLocalization = localizationDepsWithCurrencies.find(
    (local) => local.countryCode === lang
  );

  const foundCurrencyLocalization = localizationDepsWithCurrencies.find(
    (local) => local.currencyCode === currencyCode
  );

  if (!foundLocalization || !foundCurrencyLocalization) {
    $settings.next({
      primaryLanguageId: '1',
      secondaryLanguageId: '2',
      currencyId: '1',
      countryCode: 'ru',
    });
    return;
  }

  $settings.next({
    primaryLanguageId: foundLocalization.id,
    secondaryLanguageId: foundLocalization.secondaryLanguageId,
    currencyId: foundCurrencyLocalization.currencyId,
    countryCode: foundLocalization.countryCode,
  });

  $localizationDeps.next(localizationDepsWithCurrencies);
};

const setPage = (value: Pages, isMakeInstantTransition: boolean = false) => {
  setToLocalStore(value);

  if (isMakeInstantTransition) {
    $path.next(value);
    return;
  }

  setTimeout(() => {
    $path.next(value);
  }, 1000);
};
const actions = {
  setPage,
  init,
};
const setToLocalStore = (value: Pages) => {
  localStorage.setItem('widget-page', value);
};

const useMyRouting = () => {
  const usePage = () => useStateSubscriber<Pages>($path);
  const useHistory = () => useStateSubscriber<Pages[]>($history);
  const useSettings = () => useStateSubscriber<Settings>($settings);
  const useLocalizationDeps = () =>
    useStateSubscriber<LocalizationDependencies[]>($localizationDeps);

  return {
    usePage,
    useHistory,
    useSettings,
    useLocalizationDeps,
    actions,
  };
};

export default useMyRouting;
