import {ServiceFieldType} from "@onlog-public/additional-services-types";
import {
  optionLoaderProcessor,
  OptionsCache,
} from "@pages/AdditionalServices/containers/services/optionsLoader/types";
import {currencyLoader} from "@services/requests/searchLoaders/currencyLoader";
import {CurrencyData} from "@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery";

/**
 * CurrencyProcessor реализует процессор для загрузки валют
 */
export class CurrencyProcessor implements optionLoaderProcessor {
  /**
   * GetType возвращает тип поля, для которого собран процессор
   */
  GetType(): ServiceFieldType {
    return "currency"
  }

  /**
   * LoadOptionsCache выполняет загрузку кэша определенного типа
   * В качестве аргумента принимает тип поля и идентификаторы для загрузки.
   *
   * @param id
   */
  async LoadOptionsCache(id?: string[]): Promise<OptionsCache<any>> {
    const cache: OptionsCache<CurrencyData> = {isPartialLoading: !!id, cache: {}}
    const versions = await currencyLoader().Load(id)
    versions.map(v => cache.cache[v.id] = v)

    return cache
  }
}