import { $error } from '@settings/errorContext';
import { graphQLClient } from '@settings/services/graphQLClient';
import { GraphQLClient } from '@settings/services/graphQLClient/GraphQLClient';
import { loggerFactory } from '@settings/services/logger';
import { Logger } from '@settings/services/logger/Logger';

import {
  GenerateShortLinkMutationParams,
  GenerateShortLinkResponse,
  LoadShortLinkQueryParams,
  LoadShortLinkResponse,
  ShortLink,
  ShortLinkServiceProps,
} from './interface';
import { GenerateShortLinkMutation } from './mutation/GenerateShortLinkMutation';
import { LoadShortLinkQuery } from './query/LoadShortLinkQuery';

// Сервис работы с короткими ссылками
class ShortLinkService implements ShortLinkServiceProps {
  private readonly logger: Logger;

  private readonly client: GraphQLClient;

  /**
   * Конструктор сервиса
   */
  constructor(token?: string) {
    this.logger = loggerFactory().make(`ShortLinkService`);
    this.client = graphQLClient(token);
  }

  /**
   * Запрос на получение укороченной ссылки
   */
  async LoadShortLink(params: LoadShortLinkQueryParams): Promise<ShortLink | null> {
    if (params.id === '') {
      return null;
    }

    try {
      const response = await this.client.Query<LoadShortLinkQueryParams, LoadShortLinkResponse>(
        new LoadShortLinkQuery(params),
        {},
      );

      this.logger.Debug(`Loaded short link`, response);

      return response?.getShortLink;
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  /**
   * Запрос на расчет стоимости доп. услуг.
   * @param params
   */
  async GenerateShortLink(params: GenerateShortLinkMutationParams): Promise<string> {
    try {
      const response = await this.client.Query<
        GenerateShortLinkMutationParams,
        GenerateShortLinkResponse
      >(new GenerateShortLinkMutation(params), {});

      return response.createShortLink;
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }
}

export default ShortLinkService;
