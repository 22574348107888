import {Service} from "@onlog-public/additional-services-types";
import {
  ServicesFilter,
  servicesFilterProcessor
} from "@pages/AdditionalServices/containers/services/servicesFilter/types";
import {
  ServicesServicePriceCalculationMutationProps
} from "@services/requests/servicesService/servicesServicesService/interface";
import {VariantFilter} from "../../types";

/**
 * BaseServicesFilter реализует сервис фильтрации дополнительных услуг.
 */
export class BaseServicesFilter implements ServicesFilter {
  protected processors: servicesFilterProcessor<any>[]

  /**
   * Конструктор сервиса
   * @param processors
   */
  constructor(processors: servicesFilterProcessor<any>[]) {
    this.processors = processors
  }

  /**
   * Выполняет фильтрацию услуг по переданным параметрам фильтрации.
   * Если запрошена фильтрация по стоимости, то считает ее, исходя
   * из переданных расчетов по услугам.
   *
   * @param services
   * @param servicesPrices
   * @param filterParams
   */
  Filter(
    services: Service[],
    servicesPrices: ServicesServicePriceCalculationMutationProps[],
    filterParams: VariantFilter,
  ): Service[] {
    if (services.length === 0) {
      return services
    }

    const result = this.filterByPrice(services, servicesPrices, filterParams)
    if (result.length === 0) {
      return services
    }

    return this.filterByProps(result, filterParams)
  }

  /**
   * filterByProps выполняет фильтрацию услуг по переданным фильтрам свойств.
   * @param services
   * @param filterParams
   * @protected
   */
  protected filterByProps(
    services: Service[],
    filterParams: VariantFilter,
  ): Service[] {
    return Object.keys(filterParams.Data).reduce((services, propertyID) => {
      if (services.length === 0) {
        return []
      }

      const filter = filterParams.Data[propertyID]
      const processor = this.processors.find(p => p.getType() === filter.PropertyType)
      if (!processor) {
        return []
      }

      return processor.Filter(services, filter)
    }, services)
  }

  /**
   * filterByPrice реализует фильтрацию услуг по их стоимости
   * @param services
   * @param servicesPrices
   * @param filterParams
   * @protected
   */
  protected filterByPrice(
    services: Service[],
    servicesPrices: ServicesServicePriceCalculationMutationProps[],
    filterParams: VariantFilter,
  ): Service[] {
    const priceFilter = filterParams.PriceFilter
    const isNotNeed = !filterParams.IsPriceFilterAvailable || (
      priceFilter.Min === priceFilter.CurrentMin &&
      priceFilter.Max === priceFilter.CurrentMax
    )

    if (isNotNeed) {
      return services
    }

    return services.filter(service => {
      const price = servicesPrices
        .filter(p => p.serviceId === service.id)
        .map(p => p.result.result.fullPriceInTargetCurrency)
        .filter(p => p > 0)
        .reduce((v, p) => v + p, 0)

      return price >= priceFilter.CurrentMin && price <= priceFilter.CurrentMax
    })
  }
}