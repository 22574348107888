import RouteProduct from '@components/pages/Products/components/RouteProduct';
import ServiceSectionDetail from '@components/pages/Products/components/ServiceSectionDetail';
import {getLocalizationById} from '@context/useMyRouting/helpers';
import getServicesBySection, {getCustomsPaymentsServices} from '@helpers/getServicesBySection';
import {TaxNameProps} from '@helpers/getTaxNames';
import {
  CustomsPaymentsDetailingShort
} from '@pages/AdditionalServices/tabs/CustomsPayments/components/CustomsPaymentsForm/components/СustomsPaymentsDetailingShort.tsx';
import {RouteWidgetBaseParameters} from '@pages/AdditionalServices/tabs/DeliveryForm/types';
import {FileData} from '@services/requests/filesService/interface';
import {Order} from '@services/requests/orderService/interface';
import {OrderProduct} from '@services/requests/orderService/orderProductsLoader/interface';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import FileListComponent from 'src/pages/OfferDetail/components/FileItem';
import OfferTipsTextBottom from './components/OfferTipsTextBottom';
import ServiceSummaryComp from "@components/pages/Products/components/ServiceSummary";
import getTotalSummary from "@components/pages/Products/components/getTotalSummary";

/**
 * Пропсы компонента
 */
type ProductListProps = {
  order: Order;
  files: FileData[];
  taxNames: TaxNameProps[];
  realTimeBaseData: RouteWidgetBaseParameters;
};

/**
 * Компонент вывода списка продуктов в заказе
 * @param props
 * @constructor
 */
const ProductList = (props: ProductListProps) => {
  const langCode = getLocalizationById(props.order.language_id).countryCode;
  const {t} = useTranslation(['Common', 'OfferTipsText'], {lng: langCode});

  const {order, realTimeBaseData, files, taxNames} = props;

  if (order.products.length === 0) {
    return <h3>{t('Common:NoItemsSelected')}</h3>;
  }

  const currencyRoute = useMemo(
    () => realTimeBaseData.currencies.find((c) => c.id === order.currency_id),
    [realTimeBaseData]
  );

  const routes: OrderProduct[] = [];
  const services: OrderProduct[] = [];
  order.products.forEach((p) => {
    switch (p.product_type) {
      case 'route':
        routes.push(p);
        break;
      case 'service':
      case 'complex':
        services.push(p);
        break;
    }
  });
  if (order.pre_order_products && order.pre_order_products.length > 0) {
    order.pre_order_products.forEach((p) => {
      switch (p.product_type) {
        case 'service':
        case 'complex':
          services.push(p);
          break;
      }
    });
  }

  const servicesBySection = getServicesBySection(services);
  const customsPaymentProducts = getCustomsPaymentsServices(services);

  return (
    <>
      {routes.map((p) => (
        <RouteProduct
          key={`product--${p.id}`}
          product={p}
          taxNames={taxNames}
          order={order}
          realTimeBaseData={realTimeBaseData}
        />
      ))}
      {routes.length !== 0 && (
        <>
          {files.length > 0 && (
            <section className={'table-container'}>
              <FileListComponent files={files}/>
            </section>
          )}
          <div>
            <OfferTipsTextBottom/>
          </div>
        </>
      )}
      {servicesBySection.map((section) => (
        <ServiceSectionDetail
          key={section.sectionName.id}
          section={section}
          taxNames={taxNames}
          order={order}
          realTimeBaseData={realTimeBaseData}
        />
      ))}
      {customsPaymentProducts.map((product, index) => (
        <CustomsPaymentsDetailingShort key={'customs-payment-detail-' + index} product={product}/>
      ))}
      <ServiceSummaryComp
        isTotal={true}
        summary={getTotalSummary([...order.products, ...(order?.pre_order_products ?? [])], currencyRoute.code)}
        title={t('OfferDetail:OfferSummaryCostPlank')}
      />
    </>
  );
};

export default ProductList;
