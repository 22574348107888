import {SetUpServiceData} from "@pages/AdditionalServices/containers/methods/basket/types";
import getLocalizationByArgs from "@helpers/getLocalizationByArgs";

/**
 * getServicePathFromData возвращает полный путь из директорий до услуги,
 * добавленной в корзину. Включает в себя выбранный вариант
 * @param langId
 * @param setUpService
 */
const getServicePathFromData = (
  langId: string,
  setUpService: SetUpServiceData,
): string => {
  const lastLevelDirectory = setUpService.ServicesPath[String(Object.values(setUpService.ServicesPath).length - 1)]

  return [
    ...Object.values({...setUpService.ServicesPath, "0": undefined}),
    setUpService.Variant.id != lastLevelDirectory?.id ? setUpService.Variant : undefined,
  ]
    .filter(v => !!v)
    .map(s => getLocalizationByArgs(langId, s.name, s.localized_names))
    .filter(s => s.length > 0)
    .join(" - ")
}

export default getServicePathFromData