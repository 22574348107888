import {ServicesProperty} from "@onlog-public/additional-services-types";
import {
  propertyFilterGeneratorProcessor
} from "@pages/AdditionalServices/containers/services/propertyFilterGenerator/types";
import {SwitchFilterData} from "../../types";
import {SelectOption} from "../optionsGenerator/types";

/**
 * SwitchProcessor реализует процессор генерации параметров фильтрации по
 * свойствам тип "Переключатель".
 */
export class SwitchProcessor implements propertyFilterGeneratorProcessor<"switch"> {
  /**
   * Возвращает тип свойства, для которого реализована генерация.
   */
  getType(): "switch" {
    return "switch"
  }

  /**
   * Возвращает статус доступности фильтрации по свойству. Если фильтр
   * пустой, то будет false.
   * @param __
   * @param propertyValues
   * @param _
   */
  isFilterAvailable(
    __: ServicesProperty,
    propertyValues: number[],
    _: SelectOption[],
  ): boolean {
    return propertyValues.filter(
      (d, i, data) => data.indexOf(d) === i
    ).length > 1
  }

  /**
   * generate выполняет генерацию строго типизированных данных для фильтра
   * по переданному свойству известного типа.
   * @param property
   * @param propertyValues
   * @param __
   */
  generate(
    property: ServicesProperty,
    propertyValues: number[],
    __: SelectOption[],
  ): SwitchFilterData {
    const {
      SingleVariantInitialState,
      Variant,
    } = property.settings.Filter.SwitchSettings

    const result: SwitchFilterData = {
      Current: Variant === "single" ? SingleVariantInitialState : "both",
      Type: []
    }

    if (0 === propertyValues.length) {
      return result
    }

    const isDisabled = propertyValues.find(v => v === 0) !== undefined
    const isEnabled = propertyValues.find(v => v === 1) !== undefined

    if (isDisabled) {
      result.Type.push("disabled")
    }

    if (isEnabled) {
      result.Type.push("enabled")
    }

    if (isEnabled && isDisabled) {
      result.Type.push("both")
    }

    if (Variant !== "single") {
      result.Current = isEnabled && isDisabled ? "both" : result.Type[0]
    }

    return result
  }
}