import {MappedTerminalData} from '@helpers/getTerminalsFromStep';

/**
 * Функция выполняет расчет полной стоимости для группы услуг по терминалу
 * @param terminals
 */
export const getFullPriceFromTerminals = (terminals: MappedTerminalData[]) =>
  terminals
    .map((terminal) => [
      ...terminal.terminalAllowances.map(
        (allowance) =>
          allowance.calculatedPrice.priceInTargetCurrency +
          allowance.calculatedPrice.taxInTargetCurrency
      ),
      terminal.terminalCalculatedPrice.taxInTargetCurrency +
        terminal.terminalCalculatedPrice.priceInTargetCurrency,
    ])
    .flat()
    .reduce((sum, val) => sum + val, 0);

/**
 * Функция вычисляет полную стоимости расходов на терминалах
 * @param terminals
 */
export const totalTerminalsPrice = (terminals: MappedTerminalData[][]) =>
  terminals.map(getFullPriceFromTerminals).reduce((sum, val) => sum + val, 0);
