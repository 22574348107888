import {
  BaseServiceToCalculateGenerator
} from "@pages/AdditionalServices/containers/services/serviceToCalculateGenerator/baseServiceToCalculateGenerator";
import {
  DefaultProcessor
} from "@pages/AdditionalServices/containers/services/serviceToCalculateGenerator/defaultProcessor";
import {
  ServiceInVerticalTableProcessor
} from "@pages/AdditionalServices/containers/services/serviceToCalculateGenerator/serviceInVerticalTableProcessor";
import {
  VerticalTableProcessor
} from "@pages/AdditionalServices/containers/services/serviceToCalculateGenerator/verticalTableProcessor";

// serviceToCalculateGenerator реализует фабрику сервиса
const serviceToCalculateGenerator = () => new BaseServiceToCalculateGenerator([
  new ServiceInVerticalTableProcessor,
  new VerticalTableProcessor,
  new DefaultProcessor,
])

export default serviceToCalculateGenerator