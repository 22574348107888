import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {
  a11yProps,
} from '@pages/AdditionalServices/components/TubsContainer/helpers';
import {COUNT_STATIC_TABS} from '@pages/consts';
import React from 'react';
import {useTranslation} from 'react-i18next';
import Container from './StyledComponents';
import useWidgetTabsContext from "@pages/AdditionalServices/containers/widgetTabsContext";

const TubsContainer = () => {
  const {tabs, onChangeTab} = useWidgetTabsContext()
  const {t} = useTranslation('TubsContainer');

  return (
    <Container>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={tabs.ActiveTab}
        onChange={(_, v) => onChangeTab(v)}
        className={'root-services-tab'}
      >
        {tabs.Tabs.map((tab, index) => (
          <Tab
            key={index}
            label={t(tab.label)}
            {...a11yProps(index + COUNT_STATIC_TABS)}
          />
        ))}
      </Tabs>
    </Container>
  );
};

export default TubsContainer;
