import {ServiceFieldType} from "@onlog-public/additional-services-types";
import {
  _optionsCacheCheckerAndLoaderProcessor
} from "@pages/AdditionalServices/containers/services/optionsCacheCheckerAndLoader/types";
import {OptionsCache} from "@pages/AdditionalServices/containers/services/optionsLoader/types";
import optionsLoader from "@pages/AdditionalServices/containers/services/optionsLoader";

/**
 * TotalLoadingProcessor реализует универсальный процессор,
 * который загружает полный стейт когда он требуется.
 * Процессор дозагружает данные, если нужен не полный стейт сущностей.
 */
export class TotalLoadingProcessor implements _optionsCacheCheckerAndLoaderProcessor {
  protected type: ServiceFieldType

  constructor(type: ServiceFieldType) {
    this.type = type
  }

  /**
   * Возвращает тип поля, для которого реализован процессор.
   */
  GetType(): ServiceFieldType {
    return this.type
  }

  /**
   * LoadCacheForNewData выполняет проверку, есть ли данные в кэше
   * и выполняет загрузку недостающих данных.
   *
   * @param isFullStateLoaded
   * @param existInStateKeys
   * @param loadingID
   */
  async LoadCacheForNewData(
    isFullStateLoaded: boolean,
    existInStateKeys: string[],
    loadingID?: string[],
  ): Promise<OptionsCache<any>> {
    if (isFullStateLoaded) {
      return {isPartialLoading: true, cache: {}}
    }

    if (!loadingID) {
      return optionsLoader().LoadOptionsCache(this.type)
    }

    const needLoad = loadingID.filter(l => !existInStateKeys.includes(l))
    if (needLoad.length === 0) {
      return {isPartialLoading: true, cache: {}}
    }

    return optionsLoader().LoadOptionsCache(this.type, needLoad)
  }
}