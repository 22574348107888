import { styled } from '@mui/material';

const FileListStyledContainer = styled('ul')`
  border-radius: 18px;
  border: 1px solid ${({ theme }) => theme.palette.grey[200]};
  list-style: none;

  .file-item {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]};
    font-size: 14px;
    line-height: normal;

    &:last-child {
      border-bottom: 0;
    }
  }

  .link {
    padding: 15px 20px 13px;
    display: grid;
    gap: 25px;
    grid-template-columns: 31px 1fr;
    align-items: center;
    text-decoration: none;
    color: ${({ theme }) => theme.palette.common.black};

    svg {
      width: 31px;
      height: 31px;
    }
  }
`;

export default FileListStyledContainer;
