import {BehaviorSubject} from "rxjs";
import {AdditionServicesState} from "@pages/AdditionalServices/containers/types";
import {servicesDirectoriesService} from "@services/requests/servicesService/servicesDirectoriesService";
import {clone} from "@pages/AdditionalServices/containers/methods/clone";

/**
 * directoryLoadingSubscriber реализует подписчик, загружающий актуальные
 * данные выбранных в слекторах директорий. На момент загрузки подгружает
 * только текущий и следующий уровень услуг. Кэширует данные.
 * Подписчик заменяет данные в стейте на актуальные.
 *
 * @param serviceContext$
 */
export const directoryLoadingSubscriber = (
  serviceContext$: BehaviorSubject<AdditionServicesState>,
) => async (
  oldState: AdditionServicesState
) => {
  // Если данные уже были в кэше, то нет смысла еще раз за ними ходить.
  if (!oldState.IsLoadingDirectoriesForPath) {
    return
  }

  if (!oldState.LastServiceInPath) {
    return
  }

  const keys = Object.keys(oldState.ServicesPath)
  const keyToChange = keys[keys.length - 1];
  try {
    const loadedDirectory = await servicesDirectoriesService()
      .GetServiceByID(oldState.LastServiceInPath.id)

    const newState = clone(serviceContext$.getValue())
    if (!loadedDirectory) {
      serviceContext$.next({
        ...newState,
        ServicesPath: {}
      })
      return
    }

    newState.LoadedDirectoriesCache[loadedDirectory.id] = loadedDirectory

    if (newState.ServicesPath[keyToChange].id === loadedDirectory.id) {
      newState.ServicesPath[keyToChange] = loadedDirectory
    }

    if (newState?.LastServiceInPath.id === loadedDirectory.id) {
      newState.LastServiceInPath = loadedDirectory
      newState.IsLoadingDirectoriesForPath = false
    }

    serviceContext$.next(newState)
  } catch (e) {
    console.log(e)
  }
}