import { $error } from '@settings/errorContext';
import { graphQLClient } from '@settings/services/graphQLClient';
import { GraphQLClient } from '@settings/services/graphQLClient/GraphQLClient';

import { LoaderInterface } from '../interface';
import {
  LocalizedMessagesData,
  LocalizedMessagesLoaderQuery,
  LocalizedMessagesLoaderQueryResponse,
} from './LocalizedMessagesLoaderQuery';

export class Loader implements LoaderInterface<LocalizedMessagesData> {
  private readonly client: GraphQLClient;

  constructor(token?: string) {
    this.client = graphQLClient(token);
  }

  async Load(primaryKeys?: any[]): Promise<LocalizedMessagesData[]> {
    try {
      if (!primaryKeys || primaryKeys.length === 0) {
        return [];
      }

      const resp = await this.client.Query<null, LocalizedMessagesLoaderQueryResponse>(
        new LocalizedMessagesLoaderQuery(primaryKeys),
        {}
      );
      return resp.items;
    } catch (e) {
      $error.next(e);
    }
  }
}
