import clsx from 'clsx';
import React, { ReactNode, useState } from 'react';

/**
 * Пропсы компонента
 */
type OfferAccordionGroupProps = {
  header: ReactNode;
  additionalInfo?: ReactNode;
  children?: ReactNode;
};

/**
 * Компонент вывода разворачивающейся группы информации для офферты
 */
const OfferAccordionGroup = (props: OfferAccordionGroupProps) => {
  const { header, additionalInfo, children } = props;
  const [isFull, setFull] = useState(true);

  return (
    <>
      <section className={clsx('offer-accordion-group', 'kendo-pdf--prevent-split', { '--is-full': isFull })}>
        <header
          className={'offer-accordion-group__header'}
          onClick={() => setFull((state) => !state)}
        >
          <span className={'offer-accordion-group__status'} />
          {header}
        </header>
        <div className={'offer-accordion-group__body'}>{additionalInfo}</div>
        <footer>{children}</footer>
      </section>
    </>
  );
};

export default OfferAccordionGroup;
