import { getFormattedPrice } from '@pages/AdditionalServices/tabs/CustomsPayments/helpers/price';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Cost } from '../../context/types';

interface CustomsPaymentsDetailingOverallProps {
  customsRegime: string;
  contractCurrency: string;
  borderDelivery: Cost;
  otherExpenses: Cost;
}

function CustomsPaymentsDetailingOverall({
  contractCurrency,
  customsRegime,
  borderDelivery,
  otherExpenses,
}: CustomsPaymentsDetailingOverallProps) {
  const { t } = useTranslation(['CustomsPayments']);

  return (
    <div className="overall-wrap">
      <div className="overall-col">
        <div className="overall-item">
          <div className="overall-item__title">
            {t('CustomsPayments:CustomsRegimePlaceholder')}:
          </div>
          <div className="overall-item__value">{customsRegime}</div>
        </div>
        <div className="overall-item">
          <div className="overall-item__title">
            {t('CustomsPayments:ContractCurrencyPlaceholder')}:
          </div>
          <div className="overall-item__value">{contractCurrency}</div>
        </div>
      </div>

      <div className="overall-col">
        <div className="overall-item">
          <div className="overall-item__title">{t('CustomsPayments:BorderShippingCosts')}:</div>
          <div className="overall-item__value">
            {getFormattedPrice(+borderDelivery.cost, 2)} {borderDelivery.currency?.encode}
          </div>
        </div>
        <div className="overall-item">
          <div className="overall-item__title">{t('CustomsPayments:OtherExpenses')}:</div>
          <div className="overall-item__value">
            {getFormattedPrice(+otherExpenses.cost, 2)} {otherExpenses.currency?.encode}
          </div>
        </div>
      </div>
    </div>
  );
}

export { CustomsPaymentsDetailingOverall };
