import {OrderProduct} from "@services/requests/orderService/orderProductsLoader/interface";
import React, {FC} from "react";
import restoreServiceData from "@pages/AdditionalServices/containers/methods/basket/restoreServiceData";
import FileList from "@pages/AdditionalServices/components/Service/FileList";

export interface ServiceFileListProps {
  products: OrderProduct[]
}

const ServiceFileList: FC<ServiceFileListProps> = props => {
  const {products} = props

  const services = products
    .map(d => restoreServiceData(d))
    .filter(d => !!d)

  const directoryFiles = services
    .map(s => s.setUpService)
    .map(s => [s.Variant, ...Object.values(s.ServicesPath)])
    .flat(1)
    .map(d => d.file_id)
    .flat(1)

  const serviceFiles = services
    .map(s => [s.setUpService, ...s.otherServices])
    .flat(1)
    .map(s => s.ServiceData.file_id)
    .flat(1)

  const filesToDisplay = [...directoryFiles, ...serviceFiles]
    .filter((d, i, data) => data.map(d => d.id).indexOf(d.id) === i)

  if (filesToDisplay.length === 0) {
    return null
  }

  return <FileList files={filesToDisplay}/>
}

export default ServiceFileList
