import { CustomCargoParametersItemProps } from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/interfaces';
import React from 'react';

import CustomCargoBase from './CustomCargoBase';
import CustomCargoPallet from './CustomCargoPallet';
import CustomCargoVolume from './CustomCargoVolume';

type Type = {
  [key in 'base' | 'volume' | 'pallet']: React.FC<CustomCargoParametersItemProps>;
};

const CustomCargoParametersItem: React.FC<CustomCargoParametersItemProps> = (props) => {
  const { Type } = props;
  const components: Type = {
    base: CustomCargoBase,
    pallet: CustomCargoPallet,
    volume: CustomCargoVolume,
  };

  const Component = components[Type];

  return <Component {...props} />;
};

export default CustomCargoParametersItem;
