import React, {FC} from "react";
import {ServiceProps} from "@pages/AdditionalServices/components/Service/types";
import {useTranslation} from "react-i18next";
import {CurrencyData} from "@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery";
import {round} from "lodash";

/**
 * PriceColumnsList выводит колонки стоимости в таблице декларирования грузов.
 * Определяет, надо ли отображать заглушки при 0 или когда расчет по заявке.
 *
 * @param props
 */
const PriceColumnsList: FC<ServiceProps> = props => {
  const {
    service,
    currencyID,
    serviceCalculations,
    serviceDataCache,
  } = props

  const {t} = useTranslation()
  const isPriceByRequest = serviceCalculations.length === 0
    || serviceCalculations[0].result.result.fullPriceInTargetCurrency < 0

  if (isPriceByRequest) {
    const text = t(
      service.settings.PriceByRequestText.length > 0
        ? t(service.settings.PriceByRequestText)
        : 'AdditionalService:CalculationOnRequest'
    )

    return (
      <div className="a-service-template-serviceInCargoDeclaration-single-price-column">
        {text}
      </div>
    )
  }

  const settings = service.settings.TemplateSettings.ServiceInCargoDeclaration
  const price = serviceCalculations[0].result
  if (price.result.fullPriceInTargetCurrency === 0 && settings.IsNeedDisplayTextForZeroValues) {
    const text = t(
      settings.DisplayTextForZeroValues.length > 0
        ? t(settings.DisplayTextForZeroValues)
        : 'AdditionalService:IncludedInTheBasicService'
    )

    return (
      <div className="a-service-template-serviceInCargoDeclaration-single-price-column">
        {text}
      </div>
    )
  }

  const targetCurrency: CurrencyData | undefined = serviceDataCache.currency.cache[currencyID]
  const sourceCurrency: CurrencyData | undefined = price?.data?.currency_id
    ? serviceDataCache.currency.cache[price.data.currency_id]
    : undefined

  let course = "-"
  if (!!targetCurrency && !!sourceCurrency) {
    const convertedCourse = sourceCurrency.course / sourceCurrency.nominal * targetCurrency.nominal / targetCurrency.course
    course = round(convertedCourse, 2).toLocaleString()
  }


  const targetCurrencyCode = targetCurrency?.code ?? ""
  const sourceCurrencyCode = sourceCurrency?.code ?? ""

  const tax = price?.data?.tax_id
    ? (serviceDataCache.tax.cache[price.data.tax_id]?.amount ?? 0)
    : 0

  return <>
    <div className="center">{price.result.basePrice.toLocaleString()} {sourceCurrencyCode}</div>
    <div className="center">{course}</div>
    <div className="center">{price.result.conversionFee.toLocaleString()} {targetCurrencyCode}</div>
    <div className="center">{price.result.priceInTargetCurrency.toLocaleString()} {targetCurrencyCode}</div>
    <div className="center">{tax}%</div>
    <div className="center">{price.result.taxInTargetCurrency.toLocaleString()} {targetCurrencyCode}</div>
    <div className="center">{price.result.fullPriceInTargetCurrency.toLocaleString()} {targetCurrencyCode}</div>
  </>
}

export default PriceColumnsList