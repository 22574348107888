import {ServiceContractorType, ServiceFieldType, ServicePropertyType} from "@onlog-public/additional-services-types"
import {GraphQLQuery} from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

export interface ServiceDirectoryLoadByParentVariables {
  parentID: string | null
}

export interface ServiceDirectoryLoadProperty {
  description: string
  id: string
  handbook_id: number
  localized_descriptions: string[]
  localized_names: string[]
  name: string
  ordinal: number
  settings: string
  type: ServicePropertyType
}

export interface ServiceDirectoryLoadField {
  id: string
  name: string
  localized_names: string[]
  placeholder: string
  localized_placeholders: string[]
  helper: string
  localized_helpers: string[]
  code: string
  type: ServiceFieldType
  value: number
  handbook_limitations: string[]
  handbook_id: number
  settings: string
}

export interface ServiceDirectoryLoadItem {
  id: string
  contractor_id?: string
  contractor_type?: ServiceContractorType
  description: string
  file_id: string[]
  is_active: boolean
  is_variants: boolean
  localized_descriptions: string[]
  localized_names: string[]
  name: string
  parent?: string
  settings: string
  template: string
  fields: ServiceDirectoryLoadField[]
  childs: ServiceDirectoryLoadItem[]
  properties: ServiceDirectoryLoadProperty[]
}

export interface ServiceDirectoryLoadByParentResponse {
  result: ServiceDirectoryLoadItem[]
}

/**
 * Запрос списка директорий дополнительных услуг по переданному идентификатору
 * родительской директории.
 */
export class ServiceDirectoryLoadByParentQuery implements GraphQLQuery<ServiceDirectoryLoadByParentVariables> {
  readonly query: any;

  readonly variables: null;

    constructor(params: ServiceDirectoryLoadByParentVariables) {
      this.variables = null;
      this.query = gql`
        query LoadServiceDirectoryByParentID {
            result: servicedirectory_list(
                where: {parent:{_equals:${String(params.parentID ?? '"null"')}}}
                order: [{ by: id, direction: asc, priority: 1 }]
            ) {
                id
                contractor_id
                contractor_type
                description
                file_id
                is_active
                is_variants
                localized_descriptions
                localized_names
                name
                parent
                settings
                template
                fields {
                    id
                    name
                    localized_names
                    placeholder
                    localized_placeholders
                    helper
                    localized_helpers
                    code
                    type
                    value
                    handbook_limitations
                    handbook_id
                    settings
                }
                childs {
                    id
                    contractor_id
                    contractor_type
                    description
                    file_id
                    is_active
                    is_variants
                    localized_descriptions
                    localized_names
                    name
                    parent
                    settings
                    template
                    fields {
                        id
                        name
                        localized_names
                        placeholder
                        localized_placeholders
                        helper
                        localized_helpers
                        code
                        type
                        value
                        handbook_limitations
                        handbook_id
                        settings
                    }
                    properties {
                        description
                        id
                        handbook_id
                        localized_descriptions
                        localized_names
                        name
                        ordinal
                        settings
                        type
                    }
                }
                properties {
                    description
                    id
                    handbook_id
                    localized_descriptions
                    localized_names
                    name
                    ordinal
                    settings
                    type
                }
            }
        }
    `;
    }
}
