import React, {FC, useEffect} from "react";
import {SelectFieldProps} from "@pages/AdditionalServices/components/FieldDisplay/AdditionServiceField/SelectField";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Autocomplete from "@mui/material/Autocomplete";
import TextDisplayField from "@pages/AdditionalServices/components/FieldDisplay/AdditionServiceField/TextDisplayField";
import clsx from "clsx";
import {CircularProgress} from "@material-ui/core";
import useLocationSearchContext
  from "@pages/AdditionalServices/components/FieldDisplay/AdditionServiceField/useLocationSearchContext";
import useMyRouting from "@context/useMyRouting/useMyRouting";

const LocationSearchField: FC<SelectFieldProps> = props => {
  const {
    value,
    settings,
    tooltip,
    placeholder,
    options = [],
    isOptionsLoading,
    isFieldsDisabled,
    onChange,
    error = "",
  } = props

  const [s] = useMyRouting().useSettings().useState();
  const {init, useUserSearch} = useLocationSearchContext()
  const {
    state,
    makeOptionLabel,
    onChangeSearch,
    onSelectOption,
  } = useUserSearch(
    s.primaryLanguageId,
    value,
    options,
    options.find(o => o.value === value),
  )

  useEffect(() => {
    return init(s.primaryLanguageId)
  }, []);

  const isLoading = isOptionsLoading || state.LastSearchID.length > 0
  const fieldSize = settings.FieldSize === "small" ? "small" : "medium"
  const loaderSize = settings.FieldSize === "small" ? 16 : 24

  if (settings.IsNeedDisplayOnlyTextInfo) {
    return (
      <TextDisplayField
        tooltip={tooltip}
        value={String(state.SelectedOption?.label ?? "")}
      />
    )
  }

  return (
    <Tooltip
      placement="top"
      title={tooltip.length > 0 && (
        <div dangerouslySetInnerHTML={{__html: tooltip}} className="a-html-content"/>
      )}
    >
      <Autocomplete
        fullWidth
        size={fieldSize}
        loading={isLoading}
        value={state.SelectedOption || null}
        disabled={isFieldsDisabled || settings.IsNeedDisableField}
        disableClearable
        freeSolo={true}
        autoSelect={false}
        autoComplete={false}
        autoHighlight={false}
        className={clsx("a-service-field-loc-search", fieldSize)}
        filterOptions={o => o}
        renderInput={(params) => (
          <TextField
            {...params}
            size={fieldSize}
            fullWidth
            label={placeholder}
            error={error.length > 0}
            helperText={error}
            InputProps={{
              ...params.InputProps,
              endAdornment: isLoading && <>
                {params.InputProps.endAdornment}
                  <div className={clsx("a-service-field-loc-search-loader", fieldSize)}>
                      <CircularProgress color="inherit" size={loaderSize}/>
                  </div>
              </>,
            }}
          />
        )}
        renderOption={(props, option) => {
          return (
            <li
              {...props}
              key={option.value}
              onClick={e => {
                onSelectOption(option)
                onChange(option.value)
                props.onClick(e)
              }}
              className={clsx("a-service-field-loc-search-option", {
                "checked": state.SelectedOption?.value === option.value
              })}
            >
              <div className="option-label">{option.label}</div>
              <div className="option-description">{option.description}</div>
            </li>
          )
        }}
        onInputChange={(_, v) => onChangeSearch(v)}
        isOptionEqualToValue={(a, b) => a.value === b.value}
        getOptionLabel={o => typeof o === "object" ? makeOptionLabel(o) : o}
        options={state.Options}
      />
    </Tooltip>
  )
}

export default LocationSearchField