import React, {FC} from "react";
import {PropertyFieldDisplayProps} from "@pages/AdditionalServices/components/ServiceDirectory/ListTypeTemplate/types";
import Checkbox from "@mui/material/Checkbox";
import {FormControlLabel, Radio} from "@material-ui/core";
import {SwitchFilterType} from "@pages/AdditionalServices/containers/types";
import {useTranslation} from "react-i18next";
import getLocalizationByArgs from "@helpers/getLocalizationByArgs";

const SwitchFieldFilter: FC<PropertyFieldDisplayProps<"switch">> = props => {
  const {langID, data, onChange} = props
  const {Current, Type} = data.FilterData
  const {t} = useTranslation('AdditionalService')
  const settings = data.Property.settings.Filter.SwitchSettings

  const setState = (state: SwitchFilterType) => {
    onChange({
      ...data,
      FilterData: {
        Current: state,
        Type,
      }
    })
  }

  const singleItemText = settings.SingleVariantText.length > 0
    ? t(settings.SingleVariantText)
    : getLocalizationByArgs(langID, data.Property.name, data.Property.localized_names)
  const disabled = settings.BothVariantDisabledText.length > 0
    ? t(settings.BothVariantDisabledText)
    : t('AdditionalService:Templates.list.switchDisabled')
  const enabled = settings.BothVariantEnabledText.length > 0
    ? t(settings.BothVariantEnabledText)
    : t('AdditionalService:Templates.list.switchEnabled')
  const both = settings.BothVariantBothText.length > 0
    ? t(settings.BothVariantBothText)
    : t('AdditionalService:Templates.list.switchBoth')

  if (settings.Variant === "single") {
    return (
      <div className="a-service-template-list-filter-list-field">
        <div className="a-service-template-list-filter-list-field-options">
          <FormControlLabel
            className="a-service-template-list-filter-list-field-option-checkbox"
            checked={Current === "enabled"}
            onChange={() => Current === "enabled" ? setState("disabled") : setState("enabled")}
            classes={{
              label: "checkbox-label"
            }}
            control={<Checkbox size="small" checked={Current === "enabled"}/>}
            label={singleItemText}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="a-service-template-list-filter-list-field">
      <div className="a-service-template-list-filter-list-field-options">
        <FormControlLabel
          className="a-service-template-list-filter-list-field-option-checkbox"
          checked={Current === "both"}
          disabled={!Type.includes("both")}
          onChange={() => setState("both")}
          classes={{
            label: "checkbox-label"
          }}
          control={settings.BothVariantCheckboxStyle === "checkbox" ? (
            <Checkbox size="small" checked={Current === "both"}/>
          ) : (
            <Radio size="small" checked={Current === "both"}/>
          )}
          label={both}
        />
        <FormControlLabel
          className="a-service-template-list-filter-list-field-option-checkbox"
          checked={Current === "enabled"}
          disabled={!Type.includes("enabled")}
          onChange={() => setState("enabled")}
          classes={{
            label: "checkbox-label"
          }}
          control={settings.BothVariantCheckboxStyle === "checkbox" ? (
            <Checkbox size="small" checked={Current === "enabled"}/>
          ) : (
            <Radio size="small" color="primary" checked={Current === "enabled"}/>
          )}
          label={enabled}
        />
        <FormControlLabel
          className="a-service-template-list-filter-list-field-option-checkbox"
          checked={Current === "disabled"}
          disabled={!Type.includes("disabled")}
          onChange={() => setState("disabled")}
          classes={{
            label: "checkbox-label"
          }}
          control={settings.BothVariantCheckboxStyle === "checkbox" ? (
            <Checkbox size="small" checked={Current === "disabled"}/>
          ) : (
            <Radio size="small" color="primary" checked={Current === "disabled"}/>
          )}
          label={disabled}
        />
      </div>
    </div>
  )
}

export default SwitchFieldFilter