import { OrderBaseDataLoaderInterface } from './interface';
import { OrderBaseDataLoader } from './OrderBaseDataLoader';
import { OrderBaseDataLoaderProcessor } from './OrderBaseDataLoaderProcessor';
import { PreOrderBaseDataLoaderProcessor } from './PreOrderBaseDataLoaderProcessor';

// Фабрика сервиса
export const orderBaseDataLoader: { (token?: string): OrderBaseDataLoaderInterface } = (token) =>
  new OrderBaseDataLoader(
    new OrderBaseDataLoaderProcessor(token),
    new PreOrderBaseDataLoaderProcessor()
  );
