import { LoadUnloadConditionsLoaderInterface } from './interface';
import { LoadUnloadConditionsLoader } from './LoadUnloadConditionsLoader';

/**
 * Фабрика загрузчика
 * @param token
 */
export const loadUnloadConditionsLoader: {
  (token?: string): LoadUnloadConditionsLoaderInterface;
} = (token?: string) => new LoadUnloadConditionsLoader(token);
