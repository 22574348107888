function getAntiDumpingCalc() {
  return null;

  // return {
  //   id: 'antidumping-duty',
  //   name: t('CustomsPayments:AntiDumpingDuty'),
  //   rate: '0%',
  //   rublesAmount: '0',
  //   currencyAmount: '0',
  //   ...localizedMobileHeaderTableLabels,
  // };
}

export { getAntiDumpingCalc };
