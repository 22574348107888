import checkmarkSquare2Fill from '@iconify/icons-eva/checkmark-square-2-fill';
import minusSquareFill from '@iconify/icons-eva/minus-square-fill';
import squareFill from '@iconify/icons-eva/square-fill';
import { Icon } from '@iconify/react';
import { Theme } from '@material-ui/core/styles';
import React from 'react';

// ----------------------------------------------------------------------

const ICON_MEDIUM = { width: 24, height: 24 };
const ICON_SMALL = { width: 20, height: 20 };

export default function Checkbox(theme: Theme) {
  return {
    MuiCheckbox: {
      defaultProps: {
        container: document.getElementById('onlog-widget-container'),
        icon: <Icon color={'#707070'} icon={squareFill} {...ICON_MEDIUM} />,
        checkedIcon: <Icon color={'#0EAB70'} icon={checkmarkSquare2Fill} {...ICON_MEDIUM} />,
        indeterminateIcon: <Icon icon={minusSquareFill} {...ICON_MEDIUM} />,
      },

      styleOverrides: {
        root: {
          padding: theme.spacing(1),
          '& svg[font-size="small"]': {
            ...ICON_SMALL,
            [theme.breakpoints.down('sm')]: {
              ...ICON_MEDIUM,
            },
          },
          '&.Mui-checked.Mui-disabled, &.Mui-disabled': {
            color: theme.palette.action.disabled,
          },
        },
      },
    },
  };
}
