import { CurrencyItem } from '@services/requests/customsTaxService/interface';

interface GetTotalCalcProps {
  isExport: boolean;
  localizedMobileHeaderTableLabels: LocalizedLabels;
  feeRowData: Calculation;
  exciseRowData: Calculation;
  vatRowData: Calculation;
  antiDumpingRowData: Calculation;
  exportFeeRowData: Calculation;
  contractCurrency: CurrencyItem;
  title: string;
}

interface LocalizedLabels {
  rateLabel: string;
  rublesAmountLabel: string;
  currencyAmountLabel: string;
}

interface Calculation {
  rateLabel: string;
  rublesAmountLabel: string;
  currencyAmountLabel: string;
  id: string;
  name: string;
  rate: string;
  rublesAmount: string;
  currencyAmount: string;
}

function getTotalCalc({
  isExport,
  feeRowData,
  exciseRowData,
  antiDumpingRowData,
  vatRowData,
  exportFeeRowData,
  localizedMobileHeaderTableLabels,
  contractCurrency,
  title,
}: GetTotalCalcProps) {
  const antidumpingRublesSum = +antiDumpingRowData?.rublesAmount || 0;
  const antidumpingCurrencySum = +antiDumpingRowData?.currencyAmount || 0;

  const vatRublesSum = +vatRowData?.rublesAmount || 0;
  const vatCurrencySum = +vatRowData?.currencyAmount || 0;

  const exciseRublesSum = +exciseRowData?.rublesAmount || 0;
  const exciseCurrencySum = +exciseRowData?.currencyAmount || 0;

  const feeRublesSum = +feeRowData?.rublesAmount || 0;
  const feeCurrencySum = +feeRowData?.currencyAmount || 0;

  const totalRowData = {
    id: 'total',
    name: title,
    rate: '0%',
    rublesAmount: (feeRublesSum + vatRublesSum + exciseRublesSum + antidumpingRublesSum).toString(),
    currencyAmount: (
      feeCurrencySum +
      vatCurrencySum +
      exciseCurrencySum +
      antidumpingCurrencySum
    ).toString(),
    contractCurrency,
    ...localizedMobileHeaderTableLabels,
  };

  const resultFee = isExport ? exportFeeRowData : feeRowData;

  const result = [resultFee, vatRowData, exciseRowData, antiDumpingRowData, totalRowData].filter(
    (row) => Boolean(row)
  );

  return result;
}

export { getTotalCalc };
