import setLocationTextProps, {
  RouteWidgetParams,
} from '@pages/AdditionalServices/tabs/DeliveryForm/types';
import { getLocationLocalizedNames } from '@services/requests/localizedMessagesService/helpers/getLocationsLocalizedPath';

import { routeParamsSchemas } from './schemas';

const setLocationText = (props: setLocationTextProps) => {
  const {
    location,
    setValue,
    primaryLangId,
    secondaryLangId,
    isFrom,
    shippersWarehouse,
    consigneesWarehouse,
    terminal,
  } = props;
  if (!location) {
    setValue('');

    return;
  }

  let newValue = getLocationLocalizedNames(location, primaryLangId, secondaryLangId)[
    secondaryLangId
  ];
  if (location.isWarehouse) {
    newValue = isFrom
      ? `${newValue} (${shippersWarehouse})`
      : `${newValue} (${consigneesWarehouse})`;
    setValue(newValue);

    return;
  }

  if (location.symbolCode === 'ANY') {
    newValue = `${newValue} (${terminal})`;
  }

  if (location.symbolCode !== 'ANY') {
    let { symbolCode, type } = location;
    if (type === 'terminal') {
      symbolCode = `${location.subItems[0].symbolCode}` as any;
    }

    newValue = `${newValue} (${symbolCode})`;
  }

  setValue(newValue);
};

export const getformattedFloatNumber = (value: string) => {
  const onlyNumbersValue = value.replace(/[^0-9\.,]*/g, '');
  const commaReplacedValue = onlyNumbersValue.replace(/,/g, '.');
  const isAlreadyFloat = Array.from(commaReplacedValue).filter((char) => char === '.').length > 1;

  let parsedNumber = commaReplacedValue;

  if (isAlreadyFloat) {
    parsedNumber = parseFloat(commaReplacedValue).toString();
  }

  const firstChar = parsedNumber[0];
  if (firstChar === '.') {
    parsedNumber = '0' + parsedNumber;
  }

  return parsedNumber;
};

export const checkEmptyInputValue = <T extends number | string>(value: T) => {
  if (typeof value === 'string') {
    return !value.length;
  }

  if (typeof value === 'number') {
    return !Number.isFinite(value);
  }
};

export const getShareCalculationParams = (parsedShortLinkData: unknown) => {
  const parsedRouteParams: Partial<
    Omit<RouteWidgetParams, 'startLocation' | 'endLocation'> & {
      startLocation?: Pick<
        RouteWidgetParams['startLocation'],
        'id' | 'parentId' | 'type' | 'main' | 'isWarehouse' | 'visibleName'
      >;
      endLocation?: Pick<
        RouteWidgetParams['startLocation'],
        'id' | 'parentId' | 'type' | 'main' | 'isWarehouse' | 'visibleName'
      >;
    }
  > = {};

  if (typeof parsedShortLinkData !== 'object') {
    return [false, {}] as const;
  }

  const isRouteSearchParamsValid = Object.entries(routeParamsSchemas).every(([key, schema]) => {
    const value = parsedShortLinkData[key];
    let parsedValue: unknown;

    if (key.toLowerCase().includes('date')) {
      parsedValue = new Date(value);
    } else {
      try {
        parsedValue = JSON.parse(value);
      } catch (e) {}
    }

    parsedRouteParams[key] = parsedValue;

    const validatedResult = schema.safeParse(parsedValue);

    return validatedResult.success;
  });

  return [isRouteSearchParamsValid, parsedRouteParams] as const;
};

export const isShareParamsEqual = (a: string, b: string) => {
  try {
    const aParams = JSON.parse(a);
    const bParams = JSON.parse(b);

    return Object.entries(aParams).every(([key, value]) => {
      const bValue = bParams[key] ?? null;
      return !!bValue && value === bValue;
    });
  } catch {
    return false;
  }
};

export default setLocationText;
