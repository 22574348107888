import {
  ServicesServicePriceCalculationMutationProps
} from "@services/requests/servicesService/servicesServicesService/interface";
import {NumberFilterData} from "@pages/AdditionalServices/containers/types";
import {serviceFilterAndOrderContext$} from "@pages/AdditionalServices/containers/bus";
import calculateServiceOrderAndFilter
  from "@pages/AdditionalServices/containers/subscribers/calculateServiceOrderAndFilter";

/**
 * calculateDataForVariant выполняет расчет стоимости услуг для отдельного
 * варианта отображения. Возвращает результат, готовый для отображения в фильтре.
 * @param services
 */
const calculateDataForVariant = (
  services: ServicesServicePriceCalculationMutationProps[]
): NumberFilterData => {
  // Группируем расчеты по каждой отдельной услуге на тот случай, если
  // для расчета итоговой стоимости услуги было выполнено несколько расчетов
  // одновременно.
  const servicePrices: { [T in string]: ServicesServicePriceCalculationMutationProps[] } = {}
  services.map(s => {
    if (!servicePrices[s.serviceId]) {
      servicePrices[s.serviceId] = []
    }

    servicePrices[s.serviceId].push(s)
  })

  // Собираем все стоимости услуг, исключая те, которые включены и по запросу
  const servicePriceSum: number[] = []
  Object.values(servicePrices).map(prices => {
    servicePriceSum.push(prices.reduce(
      (v, s) => s.result.result.fullPriceInTargetCurrency <= 0
        ? v
        : s.result.result.fullPriceInTargetCurrency
      ,
      0,
    ))
  })

  const value = servicePriceSum.sort(
    (a, b) => a > b ? 1 : -1
  )

  if (value.length === 0) {
    return {
      CurrentMax: 0,
      CurrentMin: 0,
      Max: 0,
      Min: 0
    }
  }

  return {
    CurrentMax: value[value.length - 1],
    CurrentMin: value[0],
    Max: value[value.length - 1],
    Min: value[0]
  }
}

/**
 * calculateFilterPriceData выполняет расчет фильтра по стоимости для каждого
 * варианта услуг. Запускается автоматически, после пересчета стоимости услуг.
 *
 * @param data
 * @param isNeedRecalculateFilterState
 * @param variantID
 */
const calculateFilterPriceData = (
  data: { [T in string]: ServicesServicePriceCalculationMutationProps[] },
  isNeedRecalculateFilterState: boolean = true,
  variantID?: string,
) => {
  const state = {...serviceFilterAndOrderContext$.getValue()}

  Object.keys(state.Variants).map(variantID => {
    const variantPrice = calculateDataForVariant(
      data[variantID] ?? [],
    )

    state.Variants[variantID] = {
      ...state.Variants[variantID],
      IsPriceFilterAvailable: variantPrice.Min !== variantPrice.Max,
      PriceFilter: variantPrice,
    }
  })

  serviceFilterAndOrderContext$.next(state)

  calculateServiceOrderAndFilter(isNeedRecalculateFilterState, variantID, data)
}

export default calculateFilterPriceData