import {useEffect, useState} from "react";
import {AServicePropertyCache} from "@pages/AdditionalServices/containers/types";
import {servicePropertyValueCacheContext$} from "@pages/AdditionalServices/containers/bus/index";

/**
 * useServicePropertyValueCache реализует хук для подключения шины
 * загрузки данных свойств услуг.
 *
 * Шина содержит предварительно загруженные данные для справочников.
 * По сути все зависимые сущности, а так же заранее сгенерированные
 * опции (финальные отображения) для каждого из значений свойств.
 *
 * Шина запускается автоматически, при загрузке услуг. Загрузка
 * данных происходит до первого отображения услуг.
 *
 * Для изменения данных фильтра необходимо использовать "onChangeFilterData".
 * Данный метод принимает на вход ID варианта и callback изменения его стейта.
 */
const useServicePropertyValueCache = () => {
  const [propertyValueOptions, setState] = useState<AServicePropertyCache>(servicePropertyValueCacheContext$.getValue())
  useEffect(() => {
    const subscription = servicePropertyValueCacheContext$.pipe().subscribe({
      next: nState => setState(nState),
    })

    return () => {
      try {
        subscription?.unsubscribe()
      } catch (e) {
      }
    }
  }, [])

  return {propertyValueOptions}
}

export default useServicePropertyValueCache