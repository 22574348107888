import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Свойства компонента
export interface RouteParametersProps {
  isNeedPrekeridge: boolean;
  isNeedContainerRent: boolean;
  isPrekeridgeDisabled: boolean;
  isContainerRentDisabled: boolean;
  onChangeIsNeedPrekeridge: { (status: boolean): void };
  onChangeIsNeedContainerRent: { (status: boolean): void };
}

// Компонент вывода параметров контейнерной перевозки для маршрута
const RouteParameters: FC<RouteParametersProps> = ({
  isNeedContainerRent,
  isContainerRentDisabled,
  isNeedPrekeridge,
  isPrekeridgeDisabled,
  onChangeIsNeedContainerRent,
  onChangeIsNeedPrekeridge,
}) => {
  const { t } = useTranslation('RoutesCalculationWidget');

  return (
    <>
      <FormControlLabel
        label="SOC"
        control={
          <Checkbox
            size={'small'}
            checked={!isNeedContainerRent}
            disabled={isContainerRentDisabled}
            onChange={() => onChangeIsNeedContainerRent(!isNeedContainerRent)}
          />
        }
      />
      {<FormControlLabel
        label={t('RoutesCalculationWidget:OptionsPreCarriage')}
        control={
          <Checkbox
            size={'small'}
            checked={isNeedPrekeridge}
            disabled={isPrekeridgeDisabled}
            onChange={() => onChangeIsNeedPrekeridge(!isNeedPrekeridge)}
          />
        }
      />}
    </>
  );
};

// Экспортируем компонент
export default RouteParameters;
