import {$error} from '@settings/errorContext';
import {graphQLClient} from '@settings/services/graphQLClient';
import {GraphQLClient} from '@settings/services/graphQLClient/GraphQLClient';

import {SearchResult} from '../locationsAndTerminalSearchService/types';
import {BranchForLocationQuery, BranchForLocationQueryResult} from './BranchForLocationQuery';
import {BranchItem, _LocationsBranchServiceProcessorInterface} from './interfaces';

/**
 * Процессор подбора ветки локаций по дочерней найденной локации
 */
export class LoadByLocationBranchProcessor implements _LocationsBranchServiceProcessorInterface {
  private readonly client: GraphQLClient;

  /**
   * Конструктор сервиса
   * @param token
   */
  constructor(token?: string) {
    this.client = graphQLClient(token);
  }

  /**
   * Поиск ветки локаций процессором
   * @param items
   */
  async GetBranch(items: SearchResult[]): Promise<BranchItem[]> {
    try {
      const resp = await this.client.Query<{ id: string[] }, BranchForLocationQueryResult>(
        new BranchForLocationQuery(items.map((i) => i.id)),
        {}
      );
      return resp.getLocationsWithParents.map(
        (value) =>
          ({
            id: value.location.id,
            type: 'location',
            isUserSearchable: value.location.is_user_searchable,
            visibleName: value.location.default_name,
            defaultName: value.location.default_name,
            iso: value.location.iso,
            symbolCode: value.location.iso,
            localizedNames: value.location.localized_names,
            localizedIso: [],
            localizedNamesArray: [],
            localizedIsoArray: [],
            parentId: value.location.parent !== null ? `${value.location.parent}` : null,
            subItems: [],
            files: [],
            depthLevel: value.location.depth_level,
            coords: {
              lat: value.location.lat,
              lng: value.location.lng,
            },
          } as BranchItem)
      );
    } catch (e) {
      $error.next(e);
    }
  }

  /**
   * Проверка доступности процессора
   * @param type
   */
  isAvailable(type: 'location' | 'terminal' | 'childrenTerminal'): boolean {
    return type === 'location';
  }
}
