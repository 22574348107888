import { Pages } from '@context/useMyRouting';
import useMyRouting from '@context/useMyRouting/useMyRouting';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';

export interface BreadCrumbProps {
  id: number | string;
  name: string;
  url?: Pages;
}

interface Props {
  crumbs: BreadCrumbProps[];
}

/** *
 * Компонент вывода хлебных крошек
 */
const BreadCrumbsHOC: FC<Props> = ({ crumbs }) => {
  const { setPage } = useMyRouting().actions;

  const handleClick = (url: Pages) => {
    url === 'widget' && window.open('/online-logistics', '_self');
    setPage(url);
  };

  const breadcrumbsData = crumbs.map((item, index) =>
    item.url ? (
      <div style={{ cursor: 'pointer' }} onClick={() => handleClick(item.url)} key={index}>
        {item.name}
      </div>
    ) : (
      <Typography variant={'body1'} key={index}>
        {item.name}
      </Typography>
    )
  );

  return (
    <Breadcrumbs
      className={'breadcrumbs'}
      color={'grey.800'}
      separator={<ArrowForwardIosIcon sx={{ color: 'grey.800', fontSize: 12 }} />}
    >
      {breadcrumbsData}
    </Breadcrumbs>
  );
};

export default BreadCrumbsHOC;
