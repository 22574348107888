import { GraphQLQuery } from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

import { LocalizedMessage } from '../../localizedMessagesService/interfaces';

// Данные контейнеров
export interface ContainersData {
  id: string;
  default_name: string;
  default_weight: number;
  container_type: string;
  is_default_for_container_type: boolean;
  localized_names: string[];
  localized_names_data: LocalizedMessage[];
  estimated_volume: string;
  use_full_volume: string;
  gross_weight: string;
  net_weight: string;
  boxing: string;
  containerType: {
    id: number;
  };
}

export interface ContainersLoaderQueryResponse {
  items: ContainersData[];
}

/**
 * Запрос загрузки данных по контейнерам
 */
export class ContainersLoaderQuery implements GraphQLQuery<null> {
  readonly query: any;

  readonly variables: null;

  constructor(ids?: any[]) {
    this.variables = null;
    this.query = gql`
            query LoadContainers {
                items: transport_container_list(
                    limit: 1000000
                    ${
                      ids && ids.length > 0
                        ? `where:{id:{_in: [${ids.map((id) => `"${id}"`).join(',')}]}}`
                        : ``
                    }
                ) {
                    id
                    default_name
                    is_default_for_container_type
                    default_weight
                    localized_names
                    container_type
                    estimated_volume
                    use_full_volume
                    gross_weight
                    net_weight
                    boxing
					containerType {
						id
					}
                }
            }
        `;
  }
}
