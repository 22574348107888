import {
  Service,
  ServiceFieldType,
  ServiceFieldParameters,
  ServiceSettings,
} from '@onlog-public/additional-services-types';
import {
  ServicesAggregateProps,
  ServicesQueryParamsProps,
} from '@services/requests/servicesService/interface';
import {
  LoadAllServicesProps,
  ServicePriceCalculationItemsProps,
  ServicePriceCalculationParametersProps,
  ServicesAggregateQueryResponseProps,
  ServicesProps,
  ServicesServicePriceCalculationMutationProps,
  ServicesServicePriceCalculationMutationResponse,
  ServicesServicesQueryResponseProps,
  ServicesServicesServiceProps,
} from '@services/requests/servicesService/servicesServicesService/interface';
import {
  ServicesServicePriceCalculationMutation
} from '@services/requests/servicesService/servicesServicesService/muitation/ServicesServicePriceCalculationQuery';
import {
  ServicesServicesAggregateQuery
} from '@services/requests/servicesService/servicesServicesService/requests/ServicesServicesAggregateQuery';
import {
  ServicesServicesQuery
} from '@services/requests/servicesService/servicesServicesService/requests/ServicesServicesQuery';
import {$error} from '@settings/errorContext';
import {graphQLClient} from '@settings/services/graphQLClient';
import {GraphQLClient} from '@settings/services/graphQLClient/GraphQLClient';
import {loggerFactory} from '@settings/services/logger';
import {Logger} from '@settings/services/logger/Logger';
import {
  ServiceResponse,
  ServicesByDirectoryQuery,
  ServicesByDirectoryQueryResponse
} from "@services/requests/servicesService/servicesServicesService/requests/ServicesByDirectoryQuery";
import {Collection} from "@settings/services/types";
import {LocalizedMessage} from "@services/requests/localizedMessagesService/interfaces";
import {localizedMessagesService} from "@services/requests/localizedMessagesService";
import {filesService} from "@services/requests/filesService";
import {FileData} from "@services/requests/filesService/interface";

// Сервис загрузки дополнительных услуг
class ServicesServicesService implements ServicesServicesServiceProps {
  private readonly logger: Logger;

  private readonly client: GraphQLClient;

  /**
   * Конструктор сервиса
   */
  constructor(token?: string) {
    this.logger = loggerFactory().make(`ServicesServicesLoader`);
    this.client = graphQLClient(token);
  }

  /**
   * Агрегирующий запрос сущностей дополнительных услуг
   */
  async LoadAggregate(): Promise<ServicesAggregateProps> {
    try {
      const response = await this.client.Query<null, ServicesAggregateQueryResponseProps>(
        new ServicesServicesAggregateQuery(),
        {}
      );

      this.logger.Debug(`Loaded aggregate info of services`, response);

      return response.servicesservice_aggregate[0];
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  /**
   * Загрузка массива услуг по переданному фильтру params
   * @param params
   */
  async Load(params: ServicesQueryParamsProps): Promise<ServicesProps[]> {
    try {
      const response = await this.client.Query<
        ServicesQueryParamsProps,
        ServicesServicesQueryResponseProps
      >(new ServicesServicesQuery(params), {});

      this.logger.Debug(`Loaded list of services`, response);

      return response.service_list;
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  /**
   * Загрузка всех доступных услуг.
   * Сначала мы получаем агрегацию, это дает нам общее количество. Затем мы должны установить лимит в запросе
   * @param limit
   * @param offset
   */
  async LoadAll(limit?: number, offset?: number): Promise<LoadAllServicesProps> {
    try {
      const params: ServicesQueryParamsProps = {
        limit,
        offset,
      };

      const allServicesCount = await this.LoadAggregate();
      const response = await this.Load(params);

      return {
        count: allServicesCount.count,
        items: response,
      };
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  /**
   * Запрос на расчет стоимости доп. услуг.
   * @param params
   */
  async ServicePriceCalculation(
    params: ServicePriceCalculationItemsProps[]
  ): Promise<ServicesServicePriceCalculationMutationProps[]> {
    try {
      const response = await this.client.Query<
        ServicePriceCalculationParametersProps,
        ServicesServicePriceCalculationMutationResponse
      >(new ServicesServicePriceCalculationMutation({services: params}), {});

      return response.servicesServicePriceCalculation;
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  /**
   * Метод выполняет загрузку услуг по переданному идентификатору директорий
   * @param directory
   */
  async LoadServiceByDirectoryID(directory?: number): Promise<Service[]> {
    try {
      const response = await this.client.Query<
        null,
        ServicesByDirectoryQueryResponse
      >(new ServicesByDirectoryQuery(directory), {});

      const [locales, files] = await Promise.all([
        await this.getLocales(response.services),
        await filesService().LoadFilesById(this.getFileID(response.services)),
      ])

      return this.mapServices(response.services, locales, files)
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  /**
   * mapServices преобразует базовый ответ от сервера в итоговый тип
   * данных услуги.
   *
   * @param items
   * @param locales
   * @param files
   * @protected
   */
  protected mapServices(
    items: ServiceResponse[],
    locales: Collection<LocalizedMessage>,
    files: FileData[]
  ): Service[] {
    return items.map(s => {
      const {childs, ...other} = s
      return {
        ...other,
        localized_names: s.localized_names.map(m => locales[m]),
        localized_descriptions: s.localized_descriptions.map(m => locales[m]),
        fields: s.fields.map(f => ({
          ...f,
          localized_names: f.localized_names.map(m => locales[m]),
          localized_helpers: f.localized_helpers.map(m => locales[m]),
          localized_placeholders: f.localized_placeholders.map(m => locales[m]),
          settings: ServiceFieldParameters.RestoreSettings(f.settings),
          type: f.type as ServiceFieldType,
        })),
        file_id: files.filter(file => other.file_id.includes(file.id)),
        additionServices: this.mapServices(childs ?? [], locales, files),
        settings: ServiceSettings.RestoreSettings(s.settings),
      }
    }).filter(s => s.is_active)
  }

  /**
   * getFileID возвращает все идентификаторы файлов, которые установлены в директориях
   * @param items
   * @protected
   */
  protected getFileID(items: ServiceResponse[]): string[] {
    if (!items || 0 === items.length) {
      return []
    }

    return items.map(i => [
      ...i.file_id,
      ...this.getFileID(i.childs)
    ]).flat(1)
  }

  /**
   * Метод выполняет загрузку коллекции локализованных сообщений для
   * переданного базового ответа по услугам.
   * @param items
   * @protected
   */
  protected async getLocales(items: ServiceResponse[]): Promise<Collection<LocalizedMessage>> {
    const ids = this.getLocalizationID(items)
    const messages = await localizedMessagesService().GetMessagesArray(ids)

    const result: Collection<LocalizedMessage> = {}
    messages.map(m => result[m.id] = m)

    return result
  }

  /**
   * getLocalizationID возвращает все идентификаторы локализованных сообщений
   * для базового ответа по услугам.
   * @param service
   * @protected
   */
  protected getLocalizationID(service: ServiceResponse[]): string[] {
    return service.map(s => [
      ...s.localized_names,
      ...s.localized_descriptions,
      ...s.fields.map(f => [
        ...f.localized_names,
        ...f.localized_placeholders,
        ...f.localized_helpers,
      ]).flat(1),
      ...this.getLocalizationID(s.childs ?? []),
    ]).flat(1)
  }
}

export default ServicesServicesService;
