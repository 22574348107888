import {ServiceFieldType} from "@onlog-public/additional-services-types";
import {
  OptionsGenerator,
  optionsGeneratorProcessor
} from "@pages/AdditionalServices/containers/services/propertyOptionsGenerator/types";
import {SelectOption} from "../optionsGenerator/types";
import {OptionsCache} from "../optionsLoader/types";

/**
 * BaseOptionsGenerator реализует генератор опций свойств дополнительных услуг
 */
export class BaseOptionsGenerator implements OptionsGenerator {
  protected processors: optionsGeneratorProcessor[]

  /**
   * Конструктор сервиса
   * @param processors
   */
  constructor(processors: optionsGeneratorProcessor[]) {
    this.processors = processors
  }

  /**
   * getOptions генерирует опции для вывода по переданным идентификаторам
   * и кэшу.
   * @param type
   * @param langID
   * @param handbookID
   * @param items
   * @param cache
   */
  getOptions(
    type: ServiceFieldType,
    langID: string,
    handbookID: string,
    items: string[],
    cache: OptionsCache<any>,
  ): SelectOption[] {
    const processor = this.processors.find(p => p.getType() === type)
    if (!processor) {
      return []
    }

    return processor.getOptions(langID, items, handbookID, cache)
  }
}