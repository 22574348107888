import { GraphQLQuery } from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

import { OrderProduct } from '../orderProductsLoader/interface';

// Мутация на изменение состава продуктов предзаказа
export class UpdatePreOrderMutation
  implements
    GraphQLQuery<{
      id: number;
      preOrderProducts: OrderProduct[];
    }>
{
  readonly query: any;

  readonly variables: {
    preOrderProducts: OrderProduct[];
    id: number;
  };

  constructor(id: string, products: OrderProduct[]) {
    this.variables = {
      id: Number(id),
      preOrderProducts: products.map(({ id: _, ...otherProps }) => ({
        product_type: otherProps.product_type,
        price: otherProps.price,
        amount: otherProps.amount,
        data: otherProps.data,
        token: otherProps.token,
      })),
    };

    this.query = gql`
      mutation __UPDATE_PRE_ORDER__(
        $preOrderProducts: [Pre_Order_Product_Complete_Data]
        $id: ID!
      ) {
        data: pre_order_products_update(pre_order_id: $id, pre_order_products: $preOrderProducts) {
          currency_id
          currency_nominal
          currency_rate
          date
          id
          language_id
          order_price
          pre_order_products {
            amount
            data
            id
            pre_order_id
            price
            product_type
            token
          }
        }
      }
    `;
  }
}
