import React, {FC} from "react";
import {
  ServiceSummary
} from "@pages/AdditionalServices/containers/methods/basket/getSummaryForServices";
import {useTranslation} from "react-i18next";
import clsx from "clsx";

export interface ServiceSummaryProps {
  summary: ServiceSummary
  title: string
  isTotal?: boolean
}

const ServiceSummaryComp: FC<ServiceSummaryProps> = props => {
  const {summary, title, isTotal = false} = props
  const {t} = useTranslation(['OfferDetail', 'Common']);

  return (
    <div className={clsx("a-service-basket--summary", "kendo-pdf--prevent-split", {"total-summary": isTotal})}>
      <div className="a-service-basket--summary-tax">
        <div>
          <div>{t('CartSummary:VAT')}:</div>
          <div>{summary.Tax.toLocaleString()} {summary.TargetCurrency}</div>
        </div>
        <div>
          <div>{t('CartSummary:WithoutVAT')}:</div>
          <div>{summary.Price.toLocaleString()} {summary.TargetCurrency}</div>
        </div>
      </div>
      <div className="a-service-basket--summary-conversions">
        <div>{t('CartSummary:CurrencyDetail')}:</div>
        <div className="a-service-basket--summary-conversions-list">
          {Object.keys(summary.Currencies).map(currency => {
            if (summary.Currencies[currency] === 0) {
              return null
            }

            return (
              <div key={currency}>
                <div>{currency}:</div>
                <div>{summary.Currencies[currency].toLocaleString()}</div>
              </div>
            )
          })}
        </div>
        <div>
          <div>{t('CartSummary:ForeignExchangeExpenses')}:</div>
          <div>{summary.Conversion.toLocaleString()} {summary.TargetCurrency}</div>
        </div>
      </div>
      <div className="a-service-basket--summary--total">
        <div>{title}:</div>
        <div>{summary.Total.toLocaleString()} {summary.TargetCurrency}</div>
      </div>
    </div>
  )
}

export default ServiceSummaryComp