import { GridColDef } from '@mui/x-data-grid';

import { CurrencyCostCell } from './CurrencyCostCell';
import { RubbleCostCell } from './RubbleCostCell';

interface OverallRow {
  name: string;
  rate: string;
  rublesAmount: string;
  currencyAmount: string;
  rateLabel: string;
  rublesAmountLabel: string;
  currencyAmountLabel: string;
}

interface NameRow extends OverallRow {
  id: 'name';
}

interface RateRow extends OverallRow {
  id: 'rate';
}

interface RublesAmountRow extends OverallRow {
  id: 'rublesAmount';
}

interface CurrencyAmountRow extends OverallRow {
  id: 'currencyAmount';
}

interface TotalRow extends OverallRow {
  id: 'total';
}

export type Row = NameRow | RateRow | RublesAmountRow | CurrencyAmountRow | TotalRow;

export const getResultTableColumns = (
  type: string,
  rate: string,
  rubPrice: string,
  currencyPrice: string
) => {
  const columns: GridColDef<Row>[] = [
    {
      field: 'name',
      headerName: type,
      headerAlign: 'center',
      width: 150,
      sortable: false,
      flex: 1,
      colSpan: ({ row }) => {
        if (row.id === 'total') {
          return 2;
        }
        return 1;
      },
      valueGetter: ({ value, row }) => {
        if (row.id === 'total') {
          return row.name;
        }
        return value;
      },
    },
    {
      field: 'rate',
      sortable: false,
      headerName: rate,
      headerAlign: 'center',
      minWidth: 90,
      flex: 1,
      align: 'center',
    },
    {
      field: 'rublesAmount',
      sortable: false,
      headerName: rubPrice,
      headerAlign: 'center',
      minWidth: 160,
      flex: 1,
      align: 'center',
      renderCell: RubbleCostCell,
    },
    {
      field: 'currencyAmount',
      sortable: false,
      headerName: currencyPrice,
      headerAlign: 'center',
      minWidth: 140,
      flex: 1,
      align: 'center',
      cellClassName: 'customs-payments-table--last-cell',
      renderCell: CurrencyCostCell,
    },
  ];

  return columns;
};
