import { Dialog, Toolbar } from '@material-ui/core';
import { styled } from '@mui/material';

const Container = styled(Toolbar)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  .mobile__title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
`;

export const DialogContainer = styled(Dialog)`
  .test {
    overflow-x: hidden;
  }
`;

export default Container;
