import {ServiceDirectory, ServiceFieldType} from "@onlog-public/additional-services-types";
import {OptionsCache} from "@pages/AdditionalServices/containers/services/optionsLoader/types";
import {ContractorData} from "@services/requests/searchLoaders/contractorLoader/queries/ContractorLoaderQuery";
import getLocalizationByArgs from "@helpers/getLocalizationByArgs";

/**
 * getContractorFromDirectory возвращает название подрядчика, локализовнное
 * по переданному языку, если он установлен в директории.
 * Если подрядчик не найден, возвращается пустая строка.
 *
 * @param langID
 * @param directory
 * @param contractorsCache
 */
const getContractorFromDirectory = (
  langID: string,
  directory: ServiceDirectory,
  contractorsCache: { [T in ServiceFieldType]: OptionsCache<any> },
) => {
  const hasContractorID = !!directory.contractor_type && !!directory.contractor_id
  if (!hasContractorID) {
    return ""
  }

  let contractor: ContractorData | undefined = undefined
  switch (directory.contractor_type) {
    case "CertificationContractor":
      contractor = contractorsCache.certification_contractor.cache[directory.contractor_id]
      break
    case "ConsultingContractor":
      contractor = contractorsCache.consulting_contractor.cache[directory.contractor_id]
      break
    case "CustomsContractor":
      contractor = contractorsCache.customs_contractor.cache[directory.contractor_id]
      break
    case "InspectionContractor":
      contractor = contractorsCache.inspection_contractor.cache[directory.contractor_id]
      break
    case "InsuranceContractor":
      contractor = contractorsCache.insurance_contractor.cache[directory.contractor_id]
      break
  }

  if (!contractor) {
    return ""
  }

  return getLocalizationByArgs(langID, contractor.default_name, contractor.localized_names_data)
}

export default getContractorFromDirectory