import PlusIcon from '@components/icons/PlusIcon';
import { useMediaQuery } from '@material-ui/core';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check'
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import VideoInstruction from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/VideoInstruction';
import CargoSpecialConditional from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/сargoSpecialConditional';
import {
  CustomCargoParameters,
  CustomCargoRouteCalculationParameters,
  RouteWidgetBaseParameters,
} from '@pages/AdditionalServices/tabs/DeliveryForm/types';
import useDelivery from '@pages/AdditionalServices/tabs/DeliveryForm/useDelivery';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import CustomCargoParametersItem from './CustomCargoParametersItem';

// Свойства компонента
export interface CustomCargoParametersComponentProps {
  BaseData: RouteWidgetBaseParameters;
  customCargoParameters: CustomCargoRouteCalculationParameters;
  mainLanguage: string; // Текущий основной язык
  systemLanguage: string; // Системный язык
  handleDeleteCustomParameters(index: number): void;
  baseConditionsSlot?: React.ReactNode;
  resetSlot?: React.ReactNode;
  onChangeCargoFieldsFilled: { (validityResults: boolean[]): void };
  copyNotificationVisible: boolean;
}

// Компонент вывода конфигурации сборных грузов для расчета параметров
const CustomCargoParametersComponent: FC<CustomCargoParametersComponentProps> = (props) => {
  const {
    baseConditionsSlot,
    resetSlot,
    copyNotificationVisible,
    handleDeleteCustomParameters,
    customCargoParameters: {
      customCargoParameters: cargoParameters,
      isCustomConditionsOpen,
      isDangerousCargo,
    },
    onChangeCargoFieldsFilled,
  } = props;
  const { useRouteParams } = useDelivery();
  const setRouteParameter = useRouteParams().setState;
  const { t } = useTranslation('Actions');
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up('sm'));

  /**
   * Обработка изменения одного из параметров сборного груза
   * @param customCargoParameters
   * @param index
   */
  const handleChangeCustomCargoParameters = (
    customCargoParameters: CustomCargoParameters,
    index: number
  ) => {
    setRouteParameter((current) => {
      // Создание копии массива customCargoParameters
      const updatedCustomCargos = [...current.customCargoParameters.customCargoParameters];

      // Обновление элемента в скопированном массиве
      updatedCustomCargos[index] = customCargoParameters;

      // Возвращение обновленного состояния
      return {
        ...current,
        customCargoParameters: {
          ...current.customCargoParameters,
          customCargoParameters: updatedCustomCargos,
        },
      };
    });
  };

  /**
   * Обработка изменения состояния показа доп условий перевозки
   */
  const handleToggleCustomConditionsState = () => {
    setRouteParameter((current) => ({
      ...current,
      customCargoParameters: {
        ...current.customCargoParameters,
        isDangerousCargo: false,
        isCustomConditionsOpen: !current.customCargoParameters.isCustomConditionsOpen,
      },
    }));
  };

  /**
   * Обработка добавления нового контейнера в общий пулл
   */
  const handleAddNewContainer = () => {
    const defaultPalletType = props.BaseData.units.filter((u) => u.unit_group === '4')[0].id || '0';
    setRouteParameter((current) => ({
      ...current,
      customCargoParameters: {
        ...current.customCargoParameters,
        customCargoParameters: [
          ...current.customCargoParameters.customCargoParameters,
          {
            parametersType: 'base',
            parametersCargoCalcType: 'base',
            baseParameters: {
              quantity: 1,
              length: '1',
              width: '1',
              height: '1',
              weight: '10',
            },
            palletParameters: {
              type: defaultPalletType,
              quantity: 1,
              normativeHeightOfStacking: '1',
              weight: 1000,
            },
            volumeParameters: {
              volume: '1',
              weight: '10',
              height: 1,
            },
          },
        ],
      },
    }));
  };

  /**
   * Обработка изменения состояния флага "Опасный груз"
   * @param isDangerousCargo
   */
  const handleChangeDangerousCargoStatus = (isDangerousCargo: boolean) => {
    setRouteParameter((current) => ({
      ...current,
      customCargoParameters: {
        ...current.customCargoParameters,
        isDangerousCargo,
      },
    }));
  };

  return (
    <>
      <section className="cargo-list">
        {cargoParameters.map((cargo, i) => {
          const type: 'base' | 'volume' | 'pallet' =
            cargo.parametersCargoCalcType === 'base'
              ? cargo.parametersType
              : cargo.parametersCargoCalcType;
          return (
            <div key={`list--item-${i}`} className={'cargo-item'}>
              <CustomCargoParametersItem
                Position={i + 1}
                BaseData={props.BaseData}
                Type={type}
                customCargoParameters={cargo}
                mainLanguage={props.mainLanguage}
                systemLanguage={props.systemLanguage}
                isDeletable={cargoParameters.length > 1}
                onChangeCustomCargoParameters={(params) =>
                  handleChangeCustomCargoParameters(params, i)
                }
                onDelete={() => handleDeleteCustomParameters(i)}
                onChangeCargoFieldsFilled={onChangeCargoFieldsFilled}
              />
            </div>
          );
        })}
        <IconButton
          title={`+ ${t('Actions:Add')}`}
          className={'add-new-cargo'}
          onClick={handleAddNewContainer}
        >
          <PlusIcon />
        </IconButton>
      </section>
      <div className="footer">
        <div className={'base-params'}>
          {baseConditionsSlot}
          <CargoSpecialConditional
            isNeedCustomCondition={isCustomConditionsOpen}
            onToggleCustomCondition={handleToggleCustomConditionsState}
            isDangerousCargo={isDangerousCargo}
            onChangeDangerousCargo={(status) => handleChangeDangerousCargoStatus(status)}
          />
        </div>
        <div className={'right'}>
          {isNotMobile && <VideoInstruction />}
          {resetSlot && (
            <div className={'routes-calculation-widget__footer-reset-container'}>
              <>
                {copyNotificationVisible && (
                  <Alert
                    icon={<CheckIcon fontSize="inherit" />}
                    severity="success"
                    className="copy-success-alert"
                  >
                    {t('RoutesCalculationWidget:CopyCalculationLinkSuccess')}
                  </Alert>
                )}
                {resetSlot}
              </>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

// Экспортируем компонент
export default CustomCargoParametersComponent;
