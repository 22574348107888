import React, {FC} from "react";
import {ServiceProps} from "@pages/AdditionalServices/components/Service/types";
import {useTranslation} from "react-i18next";
import {CurrencyData} from "@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery";

/**
 * PriceColumnsList выводит колонки стоимости в таблице доп. услуг.
 * Определяет, надо ли отображать заглушки при 0 или когда расчет по заявке.
 *
 * @param props
 */
const PriceColumnsList: FC<ServiceProps> = props => {
  const {
    service,
    currencyID,
    serviceCalculations,
    serviceDataCache,
  } = props

  const {t} = useTranslation()
  const isPriceByRequest = serviceCalculations.length === 0
    || serviceCalculations[0].result.result.fullPriceInTargetCurrency < 0

  if (isPriceByRequest) {
    const text = t(
      service.settings.PriceByRequestText.length > 0
        ? t(service.settings.PriceByRequestText)
        : 'AdditionalService:CalculationOnRequest'
    )

    return (
      <div className="a-service-template-serviceInAdditionList-single-price-column">
        {text}
      </div>
    )
  }

  const settings = service.settings.TemplateSettings.ServiceInAdditionList
  const price = serviceCalculations[0].result
  if (price.result.fullPriceInTargetCurrency === 0 && settings.IsNeedDisplayTextForZeroValues) {
    const text = t(
      settings.DisplayTextForZeroValues.length > 0
        ? t(settings.DisplayTextForZeroValues)
        : 'AdditionalService:IncludedInTheBasicService'
    )

    return (
      <div className="a-service-template-serviceInAdditionList-single-price-column">
        {text}
      </div>
    )
  }

  const targetCurrency: CurrencyData | undefined = serviceDataCache.currency.cache[currencyID]
  const targetCurrencyCode = targetCurrency?.code ?? ""
  const tax = price?.data?.tax_id
    ? (serviceDataCache.tax.cache[price.data.tax_id]?.amount ?? 0)
    : 0

  return <>
    <div className="center">{price.result.priceInTargetCurrency.toLocaleString()} {targetCurrencyCode}</div>
    <div className="center">{tax}%</div>
    <div className="center">{price.result.taxInTargetCurrency.toLocaleString()} {targetCurrencyCode}</div>
    <div className="center">{price.result.fullPriceInTargetCurrency.toLocaleString()} {targetCurrencyCode}</div>
  </>
}

export default PriceColumnsList