import { TaxCellStyledContainer } from '@components/pages/Products/components/RouteProduct/StyledComponents';
import { getLocalizationById } from '@context/useMyRouting/helpers';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import useBasket from '@pages/Basket/useBasket';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useBaseDataContext from '@context/useBaseDataContext';
import { CurrencyData } from '@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery';

const GetTaxValue = (
  props: GridRenderCellParams<
    Date & {
      tax: {
        price: string;
        name: string;
      };
    }
  >
) => {
  return (
    <TaxCellStyledContainer>
      {props.row.tax.name !== '' && <span>{props.row.tax.name}</span>}
      {props.row.tax.price !== '' && <span>{props.row.tax.price}</span>}
    </TaxCellStyledContainer>
  );
};

const OfferDetailTableHeaderData = (currency: CurrencyData): GridColDef[] => {
  const [order] = useBasket().useOrder().useState();
  const langCode = getLocalizationById(order.language_id).countryCode;
  const { t } = useTranslation(['OfferDetail', 'Common'], { lng: langCode });

  return [
    {
      field: 'name',
      headerName: '',
      width: 285,
      headerAlign: 'center',
      cellClassName: 'default-table-container__text-left',
    },
    {
      field: 'price',
      headerName: t('OfferDetail:CostInPaymentCurrency'),
      sortable: false,
      flex: 2,
      minWidth: 130,
      headerAlign: 'center',
      cellClassName: 'default-table-container__text-center',
    },
    {
      field: 'quantity',
      type: 'number',
      headerName: t('Common:Qty'),
      sortable: false,
      flex: 1,
      minWidth: 83,
      headerAlign: 'center',
      cellClassName: 'default-table-container__text-center',
    },
    {
      field: 'sum',
      headerName: t('Common:Sum'),
      type: 'number',
      sortable: false,
      flex: 2,
      minWidth: 130,
      headerAlign: 'center',
      cellClassName: 'default-table-container__text-center',
    },
    {
      field: 'course',
      headerName: t('Common:Rate'),
      type: 'string',
      sortable: false,
      flex: 1,
      minWidth: 90,
      headerAlign: 'center',
      cellClassName: 'default-table-container__text-center',
    },
    {
      field: 'costsTotal',
      headerName: `${t('OfferDetail:CostsIn')} ${currency?.code}`,
      flex: 2,
      sortable: false,
      minWidth: 110,
      headerAlign: 'center',
      cellClassName: 'default-table-container__text-center',
    },
    {
      field: 'taxPrice',
      headerName: t('Common:Tax'),
      sortable: false,
      flex: 2,
      minWidth: 90,
      headerAlign: 'center',
      cellClassName: 'default-table-container__text-center',
      renderCell: GetTaxValue,
    },
    {
      field: 'total',
      headerName: t('Common:Total'),
      flex: 2,
      sortable: false,
      minWidth: 128,
      headerAlign: 'center',
      cellClassName: 'default-table-container__text-center default-table-container__last-cell',
    },
  ];
};

export default OfferDetailTableHeaderData;

// display: grid;
// grid-template-columns:
// minmax(285px,1fr)
// minmax(130px,1fr)
// minmax(83px,1fr) minmax(130px,1fr) minmax(90px,1fr) minmax(110px,1fr) minmax(90px,1fr) minmax(128px,1fr);
