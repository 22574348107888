export const getLocalizedMobileResultTableLabels = (
  rate: string,
  rubAmount: string,
  currencyAmount: string
) => ({
  rateLabel: rate,
  rublesAmountLabel: rubAmount,
  currencyAmountLabel: currencyAmount,
});

export const MOCK_RESULT_TABLE_DATA = [
  {
    id: 'fee',
    name: 'Пошлина',
    rate: '0.05',
    rublesAmount: '92 377.79 RUB',
    currencyAmount: '986.00 USD',
    rateLabel: 'Ставка',
    rublesAmountLabel: 'Сумма в рублях',
    currencyAmountLabel: 'Сумма в валюте',
  },
  {
    id: 'vat',
    name: 'НДС',
    rate: '20%',
    rublesAmount: '18 252.00 RUB',
    currencyAmount: '986.00 USD',
    rateLabel: 'Ставка',
    rublesAmountLabel: 'Сумма в рублях',
    currencyAmountLabel: 'Сумма в валюте',
  },
  {
    id: 'excise',
    name: 'Акциз',
    rate: '0%',
    rublesAmount: '0.00 RUB',
    currencyAmount: '0.00 USD',
    rateLabel: 'Ставка',
    rublesAmountLabel: 'Сумма в рублях',
    currencyAmountLabel: 'Сумма в валюте',
  },
  {
    id: 'antidumping-duty',
    name: 'Антидемпинговая пошлина',
    rate: '20%',
    rublesAmount: '18 252.00 RUB',
    currencyAmount: '986.00 USD',
    rateLabel: 'Ставка',
    rublesAmountLabel: 'Сумма в рублях',
    currencyAmountLabel: 'Сумма в валюте',
  },
  {
    id: 'total',
    name: 'ИТОГО таможенные платежи по товару',
    rate: '20%',
    rublesAmount: '18 252.00 RUB',
    currencyAmount: '986.00 USD',
    rateLabel: 'Ставка',
    rublesAmountLabel: 'Сумма в рублях',
    currencyAmountLabel: 'Сумма в валюте',
  },
];

export type ResultTableItem = (typeof MOCK_RESULT_TABLE_DATA)[number];

export const MOCK_ADDITIONAL_TERMS_FILES = Array.from({ length: 2 }, (_, i) => i + 1);
