// ----------------------------------------------------------------------

import { Palette } from '@material-ui/core/styles/createPalette';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { createTheme } from '@mui/material';

function responsiveFontSizes({ sm, md, lg }: { sm: number; md: number; lg: number }) {
  return {
    '@media (min-width:600px)': {
      fontSize: sm,
    },
    '@media (min-width:960px)': {
      fontSize: md,
    },
    '@media (min-width:1280px)': {
      fontSize: lg,
    },
  };
}

const FONT_PRIMARY = 'Manrope, sans-serif'; // Google Font

const iPhoneInput = {
  styleOverrides: {
    root: {
      '*': {
        '-webkit-user-select': 'text !important' /* Chrome, Opera, Safari */,
        '-moz-user-select': 'text !important' /* Firefox 2+ */,
        '-ms-user-select': 'text !important' /* IE 10+ */,
        'user-select': 'text !important' /* Standard syntax */,
      },
    },
  },
};

const theme = createTheme({
  components: {
    MuiTextField: iPhoneInput,
    MuiInput: iPhoneInput,
    MuiFilledInput: iPhoneInput,
    MuiOutlinedInput: iPhoneInput,
  },
});

const typography: TypographyOptions | ((palette: Palette) => TypographyOptions) = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  htmlFontSize: 10,
  h1: {
    fontWeight: 600,
    lineHeight: 'normal',
    fontSize: 25,
    ...responsiveFontSizes({ sm: 25, md: 40, lg: 64 }),
  },
  h2: {
    fontWeight: 400,
    lineHeight: 'normal',
    fontSize: 20,
    ...responsiveFontSizes({ sm: 20, md: 30, lg: 30 }),
    [theme.breakpoints.down('md')]: {
      lineHeight: '23px',
      fontSize: 25,
    },
  },
  h3: {
    lineHeight: 'normal',
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: '0.4px',
    ...responsiveFontSizes({ sm: 16, md: 20, lg: 20 }),
    '@media (min-width:600px)': {
      lineHeight: '30px',
    },
  },
  h4: {
    lineHeight: 1.4,
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: '0.28px',
  },
  h5: {
    lineHeight: '22px',
    fontWeight: 500,
    fontSize: 12,
  },
  subtitle1: {
    fontSize: 16,
    lineHeight: 1.5,
    fontWeight: 600,
  },
  subtitle2: {
    fontSize: 14,
    lineHeight: '22px',
    fontWeight: 600,
  },
  body1: {
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: '0.32px',
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      letterSpacing: '0.28px',
    },
  },
  body2: {
    fontSize: 14,
    lineHeight: 22 / 14,
  },
  caption: {
    fontSize: 12,
    lineHeight: 1.5,
  },
  overline: {
    fontSize: 12,
    lineHeight: 1.5,
    fontWeight: 700,
    letterSpacing: 1.2,
    textTransform: 'none',
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: 14,
    textTransform: 'none',
  },
} as const;

export default typography;
