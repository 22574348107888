import { useBitrix } from '@context/useBitrixUser/useBitrix';
import useBasket from '@pages/Basket/useBasket';
import { useEffect } from 'react';

function useBasketCounter() {
  const [{ bitrixUser }] = useBitrix().useBitrixUser().useState();
  const [order] = useBasket().useOrder().useState();

  const items = order.products.length;

  useEffect(() => {
    const basketCounterContainerNodes = document.querySelectorAll('[data-entity="basketCount"]');

    if (!basketCounterContainerNodes.length || !bitrixUser) return;

    if (!!items) {
      basketCounterContainerNodes.forEach((node) => {
        node.innerHTML = `<span class='counter'>${items}</span>`;
      });
    } else {
      basketCounterContainerNodes.forEach((node) => {
        node.innerHTML = '';
      });
    }
  }, [items, bitrixUser]);
}

export { useBasketCounter };
