import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import React from 'react';
// ----------------------------------------------------------------------

export default function Select() {
  return {
    MuiSelect: {
      defaultProps: {
        container: document.getElementById('onlog-widget-container'),
        IconComponent: (props: any) => (
          <ExpandMoreRoundedIcon
            id={'arrow-select'}
            {...props}
            style={{ top: 'unset', fontSize: 24 }}
          />
        ),
      },

      styleOverrides: {
        root: {
          // '& .MuiInputBase-input': {
          //   fontSize: '14px!important',
          //   color: ' rgb(56, 56, 56)!important',
          //   padding: '17px 20px!important',
          // },
        },
        '& .MuiSvgIcon-root': {
          fontSize: 16,
        },
      },
    },
  };
}
