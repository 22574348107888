import { useEffect } from 'react';

import { useYaMetrik } from '@context/useYaMetrik';
import { loggerFactory } from '@settings/services/logger';

import { delay } from 'src/utils/delay';

interface UseYaHitProps {
	condition?: boolean;
	delayMs?: number;
}

export const useYaHit = ({ delayMs = 500, condition = true }: UseYaHitProps) => {
  const { useYaCounterId } = useYaMetrik();

  const [yaCounterId] = useYaCounterId().useState();

  const logger = loggerFactory().make(`YaTargetWrapper`);

  useEffect(() => {
    const hitHandler = () => {
      try {
        if (typeof yaCounterId !== 'number') {
          return;
        }

        if ('ym' in window) {
          (window.ym as any)(yaCounterId, 'hit');
        }
      } catch (e) {
        logger.Error(`Error:`, e);
      }
    };

	const delayedHitHandler = delay(hitHandler, delayMs);

	if (condition) {
		delayedHitHandler();
	}
  }, [condition]);
};
