import { RouteWidgetBaseParameters } from '@pages/AdditionalServices/tabs/DeliveryForm/types';
import {
  CalculatedAllowanceOffer,
  CalculatedPrice,
  RouteStep,
  TransportOfferCondition,
  TransportTerminal,
  TransportTerminalLoadingUnloadingOffer,
  TransportTerminalOffer,
} from '@services/requests/orderService/types';
import {
  TransportLoadingCondition,
  TransportUnloadingCondition,
} from '@services/requests/searchLoaders/LoadUnloadConditionsLoader/interface';

export type MappedTerminalData = {
  loadingType: 'load' | 'unload';
  terminal: TransportTerminal;
  terminalAllowances: CalculatedAllowanceOffer[];
  terminalCalculatedPrice: CalculatedPrice;
  terminalLoadingUnloadingOffer: TransportTerminalLoadingUnloadingOffer | null;
  terminalOffer: TransportTerminalOffer;
  terminalPrice: TransportOfferCondition | null;
  loadingCondition: TransportLoadingCondition;
  unloadingCondition: TransportUnloadingCondition;
  isFirst: boolean;
  isLast: boolean;
  stepNum: number;
};

/**
 * Функция группирует терминалы по идентификатору для объединения расходов
 * по каждому терминалу.
 *
 * @param terminals
 */
const groupTerminals = (terminals: MappedTerminalData[]) => {
  const result: MappedTerminalData[][] = [];

  terminals.forEach((terminal: MappedTerminalData) => {
    if (result.find((group) => group.find((t) => t.terminal.id === terminal.terminal.id))) {
      result[result.length - 1].push(terminal);
    } else {
      result.push([terminal]);
    }
  });

  return result;
};

/**
 * Функция достает из переданных шагов маршрута все терминалы, входящие в него.
 *
 * @param steps
 * @param loadingTransportConditions
 * @param unloadingTransportConditions
 */
const getTerminalsFromStep = (
  steps: RouteStep[],
  loadingTransportConditions: TransportLoadingCondition[],
  unloadingTransportConditions: TransportUnloadingCondition[]
) => {
  const terminals: MappedTerminalData[] = [];
  steps.forEach((step) => {
    const loadingCondition = loadingTransportConditions.find(
      (c) => c.id === step.shoulderOffer.loading_condition_id
    );
    const unloadingCondition = unloadingTransportConditions.find(
      (c) => c.id === step.shoulderOffer.unloading_condition_id
    );

    if (!loadingCondition || !unloadingCondition) {
      return [];
    }

    if (step.startTerminal) {
      terminals.push({
        loadingType: 'load',
        terminal: step.startTerminal,
        terminalAllowances: [...step.startTerminalAllowances],
        terminalCalculatedPrice: step.startTerminalCalculatedPrice,
        terminalLoadingUnloadingOffer: step.startTerminalLoadingUnloadingOffer,
        terminalOffer: step.startTerminalOffer,
        terminalPrice: step.startTerminalPrice,
        loadingCondition,
        unloadingCondition,
        isFirst: step.stepNumber === 0,
        isLast: false,
        stepNum: terminals.length,
      });
    }

    if (step.endTerminal) {
      terminals.push({
        loadingType: 'unload',
        terminal: step.endTerminal,
        terminalAllowances: step.endTerminalAllowances,
        terminalCalculatedPrice: step.endTerminalCalculatedPrice,
        terminalLoadingUnloadingOffer: step.endTerminalLoadingUnloadingOffer,
        terminalOffer: step.endTerminalOffer,
        terminalPrice: step.endTerminalPrice,
        loadingCondition,
        unloadingCondition,
        isFirst: false,
        isLast: step.stepNumber === steps.length - 1,
        stepNum: terminals.length,
      });
    }

    return terminals.sort((a, b) => a.stepNum - b.stepNum);
  });
  return terminals;
};

const getTerminalsToDisplay = (steps: RouteStep[], realTimeBaseData: RouteWidgetBaseParameters) => {
  const { loadingTransportConditions = [], unloadingTransportConditions = [] } = realTimeBaseData;
  return groupTerminals(
    getTerminalsFromStep(steps, loadingTransportConditions, unloadingTransportConditions)
  );
};

export default getTerminalsToDisplay;
