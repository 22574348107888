import {ServiceFieldType} from "@onlog-public/additional-services-types";
import {optionsGeneratorProcessor} from "@pages/AdditionalServices/containers/services/propertyOptionsGenerator/types";
import {SelectOption} from "../optionsGenerator/types";
import {OptionsCache} from "../optionsLoader/types";
import {TaxData} from "@services/requests/taxLoader/TaxLoaderQuery";

/**
 * TaxProcessor реализует процессор генерации опций для свойств
 * услуг типа "Налог".
 */
export class TaxProcessor implements optionsGeneratorProcessor {
  /**
   * getType возвращает тип справочника, для которого реализован генератор
   */
  getType(): ServiceFieldType {
    return "tax"
  }

  /**
   * getOptions генерирует опции для вывода по переданным идентификаторам
   * и кэшу.
   * @param _
   * @param items
   * @param __
   * @param cache
   */
  getOptions(_: string, items: string[], __: string, cache: OptionsCache<any>): SelectOption[] {
    const taxData: TaxData[] = items
      .map(i => cache.cache[i])
      .filter(v => !!v)

    return taxData.map(c => ({
      value: parseInt(c.id),
      label: c.default_name,
    }))
  }
}