import { RouteWidgetBaseParameters } from '@pages/AdditionalServices/tabs/DeliveryForm/types';
import { baseDataLoader } from '@services/collectors/BaseDataService';
import { useStateSubscriber } from '@settings/core/stateContexts/useContextSubscriber';
import { BehaviorSubject } from 'rxjs';

interface Settings {
  primaryLanguageId: string; // Основной язык
  secondaryLanguageId: string; // Второстепенный язык
  currencyId: string; // Основная валюта
}

const $baseData = new BehaviorSubject<RouteWidgetBaseParameters | undefined>(undefined);
const $isLoading = new BehaviorSubject<boolean>(false);
const $isInit = new BehaviorSubject<boolean>(false);
const $settings = new BehaviorSubject<Settings>({
  currencyId: '',
  primaryLanguageId: '',
  secondaryLanguageId: '',
});

/**
 * Начальная инициализация виджета
 * @param primaryLanguageId
 * @param secondaryLanguageId
 * @param currencyId
 */
const init = async (primaryLanguageId: string, secondaryLanguageId: string, currencyId: string) => {
  $isLoading.next(true);
  $settings.next({
    currencyId,
    primaryLanguageId,
    secondaryLanguageId,
  });

  const baseData = await baseDataLoader().LoadBaseData();

  $baseData.next(baseData);
  $isLoading.next(false);
  $isInit.next(true);
};

const actions = {
  init,
};
const useBaseDataContext = () => {
  const useBaseData = () => useStateSubscriber($baseData);
  const useIsLoading = () => useStateSubscriber($isLoading);
  const useIsInit = () => useStateSubscriber($isInit);
  const useSettings = () => useStateSubscriber($settings);

  return {
    useBaseData,
    useIsLoading,
    useIsInit,
    useSettings,
    actions,
  };
};
export default useBaseDataContext;
