import {Service} from "@onlog-public/additional-services-types";
import {
  serviceToCalculateGeneratorProcessor
} from "@pages/AdditionalServices/containers/services/serviceToCalculateGenerator/interface";
import {ServicePriceCalculationItemsProps} from "@services/requests/servicesService/servicesServicesService/interface";
import {VerticalTableTemplateStore} from "@pages/AdditionalServices/components/Service/VerticalTableTemplate/types";

/**
 * VerticalTableProcessor реализует процессор генерации ЦП для вертикальных
 * таблиц. Генерирует ЦП для каждой из строк под каждую колоночную услугу.
 */
export class VerticalTableProcessor implements serviceToCalculateGeneratorProcessor {
  /**
   * isAvailable проверяет доступность процессора для переданной услуги.
   * @param service
   */
  isAvailable(service: Service): boolean {
    return service.template === "verticalTable"
  }

  /**
   * Generate выполняет генерацию запросов на расчет услуг
   * по переданным данным. Позволяет сформировать для услуги
   * сразу несколько запросов, например с разными значениями
   * полей.
   *
   * @param service
   * @param currency
   * @param fieldValues
   * @param serviceValues
   * @param serviceState
   */
  Generate(
    service: Service,
    currency: string,
    fieldValues: { [x: string]: number; },
    serviceValues: { [x: string]: number; },
    serviceState: any,
  ): ServicePriceCalculationItemsProps[] {
    const state: VerticalTableTemplateStore = serviceState
    const servicesToCalculate = service?.additionServices?.filter(s => s.template === "serviceInVerticalTable") ?? []
    if (0 === servicesToCalculate.length || 0 === Object.keys(state.rows).length) {
      return []
    }

    const mapVal = (values: { [x: string]: number; }) => (k: string) => ({
      key: k,
      value: String(values[k]),
    })

    const values = {...fieldValues, ...serviceValues}
    values[service.settings.TemplateSettings.VerticalTable.CostColumnSumField] = Object.keys(state.rows).reduce(
      (v, rowID) => v + state.rows[rowID],
      0
    )

    const result = Object.keys(state.rows).map(rowID => {
      const rowValues = {
        ...values,
        [service.settings.TemplateSettings.VerticalTable.CostColumnField]: state.rows[rowID]
      }

      return [
        ...servicesToCalculate
          .filter(s => !s.settings.TemplateSettings.ServiceInVerticalTable.IsNeedCalculateSingleValue)
          .map((s: Service): ServicePriceCalculationItemsProps => ({
            stateID: rowID,
            currency_id: currency,
            service_id: s.id,
            values: Object.keys(rowValues).map(mapVal(rowValues)),
          }))
      ]
    }).flat(1)

    result.push(...servicesToCalculate
      .filter(s => s.settings.TemplateSettings.ServiceInVerticalTable.IsNeedCalculateSingleValue)
      .map((s: Service): ServicePriceCalculationItemsProps => ({
        stateID: Object.keys(state.rows)[0],
        currency_id: currency,
        service_id: s.id,
        values: Object.keys(values).map(mapVal(values)),
      }))
    )

    result.push({
      stateID: Object.keys(state.rows)[0],
      currency_id: currency,
      service_id: service.id,
      values: Object.keys(values).map(mapVal(values)),
    })

    return result
  }
}