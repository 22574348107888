import {getLocalizationById} from '@context/useMyRouting/helpers';
import {Typography} from '@material-ui/core';
import {useTheme} from '@mui/material/styles';
import useBasket from '@pages/Basket/useBasket';
import React from 'react';
import {useTranslation} from 'react-i18next';

/**
 * Компонент вывода подсказок по заказу
 */
const OfferTipsTextBottom = () => {
  const [order] = useBasket().useOrder().useState();
  const langCode = getLocalizationById(order.language_id).countryCode;
  const {t} = useTranslation('OfferTipsText', {lng: langCode});
  const theme = useTheme();
  return (
    <>
      <Typography variant={'body2'} color={theme.palette.grey[300]} fontSize={13}>
        {t('OfferTipsText:Part4')}
      </Typography>
    </>
  );
};

export default OfferTipsTextBottom;
