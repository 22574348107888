import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const SearchIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 25 25" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.3884 17.7712L23.9016 22.3099C24.1529 22.5613 24.2943 22.9035 24.2943 23.2605C24.2943 23.6175 24.1529 23.9597 23.9016 24.2111C23.6524 24.4646 23.3131 24.6071 22.9592 24.6071C22.6052 24.6071 22.2659 24.4646 22.0167 24.2111L17.5168 19.659C15.6617 21.1277 13.3708 21.9246 11.0125 21.9217C5.14758 21.9217 0.393164 17.1263 0.393164 11.2108C0.393164 5.2954 5.14758 0.5 11.0125 0.5C16.8773 0.5 21.6317 5.2954 21.6317 11.2108C21.6347 13.5895 20.8446 15.9001 19.3884 17.7712ZM11.0126 3.17771C6.6139 3.17771 3.04809 6.77426 3.04809 11.2108C3.04809 15.6474 6.6139 19.244 11.0126 19.244C15.4112 19.244 18.977 15.6474 18.977 11.2108C18.977 6.77426 15.4112 3.17771 11.0126 3.17771Z"
      fill={'currentColor'}
    />
  </SvgIcon>
);

export default SearchIcon;
