import { Slider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import Container from './StyledComponents';

// Свойства компонента
export interface RangeSliderProps {
  steps?: number;
  ranges: {
    min: number;
    max: number;
  };
  value: {
    min: number;
    max: number;
  };

  tooltipGenerator: { (value: number, isToValue: boolean): React.ReactNode };
  onChange: { (value: { min: number; max: number }): void };
}

const RangeSlider: React.FC<RangeSliderProps> = ({
  steps,
  ranges,
  value,
  tooltipGenerator,
  onChange,
}) => {
  const [sliderValue, setSliderValue] = useState<[number, number]>([value.min, value.max]);

  useEffect(() => {
    setSliderValue([value.min, value.max]);
  }, [value]);

  const handleChangeValues = (_: Event, values: number | number[]) => {
    if (Array.isArray(values)) {
      setSliderValue([values[0], values[1]]);
    }
  };

  const handleChangeFinal = (_: Event, values: number | number[]) => {
    if (Array.isArray(values)) {
      const finalValue = { min: values[0], max: values[1] };
      setSliderValue([values[0], values[1]]);
      onChange(finalValue);
    }
  };

  return (
    <Container>
      <div className="wrapper-slider">
        <Slider
          value={sliderValue}
          onChange={handleChangeValues}
          onChangeCommitted={handleChangeFinal}
          valueLabelDisplay="off"
          step={steps}
          min={ranges.min}
          max={ranges.max}
        />
      </div>
      <div className={'container-slider'}>
        <div className={'value'}>{tooltipGenerator(sliderValue[0], false)}</div>
        <div className={'value'}>{tooltipGenerator(sliderValue[1], true)}</div>
      </div>
    </Container>
  );
};

export default RangeSlider;
