import { en } from './en';
import { ru } from './ru';

export const resources = {
  en: {
    ...en,
  },
  ru: {
    ...ru,
  },
};
