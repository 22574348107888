import React, {FC} from "react";
import {ServiceItem} from "@components/pages/Products/components/ServiceSectionDetail/types";
import SingleServiceItem from "@components/pages/Products/components/ServiceSectionDetail/SingleServiceItem";
import GroupServiceItem from "@components/pages/Products/components/ServiceSectionDetail/GroupServiceItem";

const ServiceData: FC<ServiceItem> = props => {
  if (props.product.product_type === "service") {
    return <SingleServiceItem {...props} />
  }

  return <GroupServiceItem {...props} />
}

export default ServiceData