import { $error } from '@settings/errorContext';
import getEnv from '@settings/getEnv';

import { Logger, LoggerFactory } from '../logger/Logger';
import { Collection } from '../types';
import { GraphQLClient, GraphQLQuery, RequestGraphQLClient } from './GraphQLClient';

const { REACT_APP_GRAPHQL_REFETCH_DELAY_MS, REACT_APP_MAX_REFETCH_CALCULATION_NUMBER } = getEnv();

/**
 * Реализация серверного клиента обработки ошибок
 * и повторного запуска запросов GraphQL
 */
export class RetryClient implements GraphQLClient {
  private readonly client: RequestGraphQLClient;

  private readonly logger: Logger;

  /**
   * Конструктор клиента
   *
   * @param client
   */
  constructor(loggerFactory: LoggerFactory, client: RequestGraphQLClient) {
    this.logger = loggerFactory.make('RetryClient');
    this.client = client;
  }

  async Query<V, Response>(query: GraphQLQuery<V>, headers: Collection<string>): Promise<Response> {
    return await this.RetryQuery(query, headers, 0);
  }

  async Mutation<V, Response>(
    query: GraphQLQuery<V>,
    headers: Collection<string>
  ): Promise<Response> {
    try {
      return await this.client.Mutation<V, Response>(query, headers);
    } catch (e) {
      this.logger.Error(
        'Request failed',
        e,
        'Query:',
        query.query.loc.source.body,
        'variables:',
        query.variables
      );

      $error.next(`${e}`);
    }
  }

  /**
   * Выполнение повторного запроса к серверу после ошибки
   *
   * @param query
   * @param headers
   * @param currentTry
   */
  private async RetryQuery<V, Response>(
    query: GraphQLQuery<V>,
    headers: Collection<string>,
    currentTry: number
  ): Promise<Response> {
    const initRefetchDelay = +REACT_APP_GRAPHQL_REFETCH_DELAY_MS;

    const calculatedDelay = Array.from({ length: currentTry }, (_, i) => i + 1).reduce(
      (prev, tryCount) => {
        if (tryCount === 1) {
          return prev;
        }

        return prev + prev;
      },
      initRefetchDelay
    );

    if (currentTry !== 0) {
      console.log(calculatedDelay);
      await new Promise((resolve) => setTimeout(resolve, calculatedDelay));
    }

    try {
      return await this.client.Query<V, Response>(query, headers);
    } catch (e) {
      this.logger.Error(
        'Request failed',
        e,
        'Query:',
        query.query.loc.source.body,
        'variables:',
        query.variables
      );

      if (currentTry >= +REACT_APP_MAX_REFETCH_CALCULATION_NUMBER) {
        $error.next('GraphQL request failed');
        return;
      }

      return await this.RetryQuery(query, headers, ++currentTry);
    }
  }
}
