import { GraphQLQuery } from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

import { TransportLoadingCondition } from '../interface';

/**
 * Результат ответа
 */
export interface LoadingConditionQueryResponse {
  items: TransportLoadingCondition[];
}

/**
 * Запрос загрузки данных по условиям погрузки
 */
export class LoadingConditionQuery implements GraphQLQuery<null> {
  readonly query: any;

  readonly variables: null;

  constructor(ids?: any[]) {
    this.variables = null;
    this.query = gql`
            query __LOAD_LOADING_CONDITIONS__ {
                items: transport_loading_condition_list(
                    limit: 1000000
                    ${
                      ids && ids.length > 0
                        ? `where:{id:{_in: [${ids.map((id) => `"${id}"`).join(',')}]}}`
                        : ``
                    }
                ) {
                    code
                    default_name
                    id
                    is_terminal_allowances_included
                    is_terminal_services_included_on_loading
                    is_terminal_services_included_on_unloading
                    localized_names
                    is_first_shoulder_only
                    is_loading_on_non_terminal_only
                }
            }
        `;
  }
}
