import { formatPrice } from '@pages/OfferDetail/helpers';
import { CalculatedPrice } from '@services/requests/orderService/types';
import { CurrencyData } from '@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery';

export interface ShoulderItemDetailsDTOProps {
  id: number;
  name: string;
  price: string;
  quantity: number;
  sum: string;
  course: string;
  costsTotal: string;
  tax: {
    price: string;
    name: string;
  };
  total: string;
}

export type RowProps = {
  price: CalculatedPrice;
  taxName: string;
  name: string;
  baseCurrency: CurrencyData;
  targetCurrency: CurrencyData;
};

const ShoulderItemDetailsDTO = (rows: RowProps[]): ShoulderItemDetailsDTOProps[] => {
  return rows.map(({ name, price, targetCurrency, baseCurrency, taxName }: RowProps, index) => ({
    id: index + 1,
    name,
    price: `${formatPrice(price.basePrice / price.computedAmount)} ${baseCurrency.code}`,
    quantity: price.computedAmount,
    sum: `${formatPrice(price.basePrice)} ${baseCurrency.code}`,
    course: formatPrice(
      ((1 * baseCurrency.course) / baseCurrency.nominal / targetCurrency.course) *
        targetCurrency.nominal
    ),
    costsTotal: `${formatPrice(price.priceInTargetCurrency)} ${targetCurrency.code}`,
    tax: {
      price: `${formatPrice(price.taxInTargetCurrency)} ${targetCurrency.code}`,
      name: `${taxName ? taxName : ''}`,
    },
    total: `${formatPrice(price.priceInTargetCurrency + price.taxInTargetCurrency)} ${
      targetCurrency.code
    }`,
  }));
};

export default ShoulderItemDetailsDTO;
