import breakpoints from '@assets/theme/breakpoints';
import palette from '@assets/theme/palette';
import { styled } from '@mui/material/styles';

export const CustomsPaymentTotalContainer = styled('section')`
  background-color: ${palette.light.grey[100]};
  border-radius: 18px;
  border: 1px solid #dbe0ec;
  margin-top: 10px;

  .cargo-desc {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: ${({ theme }) => theme.palette.common.white};
    background-color: ${palette.light.blue[400]};
    padding: 10px 15px;
    border-radius: 8px;
    font-size: 20px;
    box-sizing: border-box;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: 0.4px;
    text-transform: uppercase;

    @media (min-width: ${breakpoints.values.xsm}px) {
      padding: 20px 50px;
      border-radius: 18px;
    }

    &-item {
      display: flex;
      justify-content: space-between;
      padding: 19px 20px 18px 14px;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
    }

    &__text {
      font-size: 14px;
      font-weight: 500;
      line-height: 18.2px;
      letter-spacing: 0.02em;

      @media (min-width: ${breakpoints.values.xsm}px) {
        font-size: 20px;
        font-weight: 800;
      }
    }

    &__price {
      font-size: 16px;
      font-weight: 600;
      line-height: 23.46px;
      letter-spacing: 0.02em;

	  @media (min-width: ${breakpoints.values.xsm}px) {
        font-size: 20px;
        font-weight: 800;
      }
    }
  }

  .MuiTypography-root {
    color: ${({ theme }) => theme.palette.common.black};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .summary-list {
    list-style: none;
    display: grid;
    gap: ${({ theme }) => theme.spacing(4.5)};
    padding: 19px 20px 19px 14px;

    &.--with-border-bottom {
      border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]};
    }
    @media (max-width: ${breakpoints.values.laptop}px) {
      gap: ${({ theme }) => theme.spacing(2)};
    }
  }

  .summary-item {
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: space-between;

    &__cost-value {
      white-space: nowrap;
      margin-left: 4px;
    }

    &.--currency-detail {
      /* align-items: flex-start; */
      /* flex-wrap: wrap; */
    }
  }
`;
