import { useStateSubscriber } from '@settings/core/stateContexts/useContextSubscriber';
import { BehaviorSubject } from 'rxjs';

const $yaCounterId = new BehaviorSubject<number | undefined>(undefined);

/**
 * Начальная инициализация яндекс метрики
 */
const init = () => {
  const yaCounterId = (window as any)?.Ya?._metrika?.getCounters()?.[0]?.id;

  if (typeof yaCounterId === 'number') {
    $yaCounterId.next(yaCounterId);
  }
};

const actions = {
  init,
};

export const useYaMetrik = () => {
  const useYaCounterId = () => useStateSubscriber($yaCounterId);

  return {
    useYaCounterId,
    actions,
  };
};
