import Actions from './en/Actions.json';
import AdditionalService from './en/AdditionalService.json';
import AppSettings from './en/AppSettings.json';
import BenefitsOfWorking from './en/BenefitsOfWorking.json';
import BenefitsOfWorkingProfile from './en/BenefitsOfWorkingProfile.json';
import CartAsideBlock from './en/CartAsideBlock.json';
import CartComponent from './en/CartComponent.json';
import CartSummary from './en/CartSummary.json';
import Common from './en/Common.json';
import CustomsCalculator from './en/CustomsCalculator.json';
import CustomsPayments from './en/CustomsPayments.json';
import DeliveryForm from './en/DeliveryForm.json';
import DeliveryItem from './en/DeliveryItem.json';
import EmptyStyleSelect from './en/EmptyStyleSelect.json';
import FilterComponent from './en/FilterComponent.json';
import FooterBottomBar from './en/FooterBottomBar.json';
import LocationSelector from './en/LocationSelector.json';
import OfferContainers from './en/OfferContainers.json';
import OfferDetail from './en/OfferDetail.json';
import OfferTipsText from './en/OfferTipsText.json';
import OrderForm from './en/OrderForm.json';
import OrderList from './en/OrderList.json';
import PageNames from './en/PageNames.json';
import RoutesCalculationWidget from './en/RoutesCalculationWidget.json';
import RoutesListComponent from './en/RoutesListComponent.json';
import SortComponent from './en/SortComponent.json';
import TubsContainer from './en/TubsContainer.json';
import TYOrder from './en/TYOrder.json';

export const en = {
  Actions,
  AdditionalService,
  AppSettings,
  BenefitsOfWorking,
  BenefitsOfWorkingProfile,
  CartAsideBlock,
  CartComponent,
  CartSummary,
  Common,
  CustomsCalculator,
  CustomsPayments,
  DeliveryForm,
  DeliveryItem,
  EmptyStyleSelect,
  FilterComponent,
  FooterBottomBar,
  LocationSelector,
  OfferContainers,
  OfferDetail,
  OfferTipsText,
  OrderForm,
  OrderList,
  PageNames,
  RoutesCalculationWidget,
  RoutesListComponent,
  SortComponent,
  TubsContainer,
  TYOrder,
};
