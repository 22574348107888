import AdditionCostCaption from '@components/pages/Products/components/RouteProduct/components/AdditionCostCaption';
import {
  getCost,
  getRowsData,
} from '@components/pages/Products/components/RouteProduct/components/ProductRouteItem/helpers';
import RouteProductDetailSteps
  from '@components/pages/Products/components/RouteProduct/components/RouteProductDetailSteps';
import OfferDetailTableHeaderData
  from '@components/pages/Products/components/RouteProduct/components/ShoulderItemHeaderData';
import ShoulderItemTable from '@components/pages/Products/components/RouteProduct/components/ShoulderItemTable';
import TerminalsInfo from '@components/pages/Products/components/RouteProduct/components/TerminalsInfo';
import {getCargoParams} from '@components/pages/Products/components/RouteProduct/helpres';
import {RouteProductContextData} from '@components/pages/Products/components/RouteProduct/types';
import {getLocalizationById} from '@context/useMyRouting/helpers';
import {useYaHit} from '@context/useYaHit';
import {TaxNameProps} from '@helpers/getTaxNames';
import Typography from '@mui/material/Typography';
import {RouteWidgetBaseParameters} from '@pages/AdditionalServices/tabs/DeliveryForm/types';
import useBasket from '@pages/Basket/useBasket';
import ShoulderItemDetailsDTO from '@services/collectors/ProductService/ShoulderItemDetailsDTO';
import {
  ContainerParameters,
  CustomCargoParameters,
  RouteInGroup,
} from '@services/requests/orderService/types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import ServiceSummaryComp from "@components/pages/Products/components/ServiceSummary";
import getRouteSummary from "@components/pages/Products/components/RouteProduct/getRouteSummary";

/**
 * Пропсы компонента
 */
type ProductRouteItemProps = {
  cargo: string;
  route: RouteInGroup;
  taxNames: TaxNameProps[];
  containerParameters: ContainerParameters[];
  customCargoParameters: CustomCargoParameters[];
  realTimeBaseData: RouteWidgetBaseParameters;
} & RouteProductContextData;

/**
 * Компонент вывода продукта маршрута
 * @param props
 * @constructor
 */
const ProductRouteItem = (props: ProductRouteItemProps) => {
  const {route, containerParameters, customCargoParameters, taxNames, realTimeBaseData} =
    props;
  const [order] = useBasket().useOrder().useState();
  const langCode = getLocalizationById(order.language_id).countryCode;
  const {t} = useTranslation(['Common', 'OfferDetail'], {lng: langCode});

  useYaHit({});

  const {
    containerRent,
    containerRentCalculatedPrice,
    pickOn,
    pickOnCalculatedPrice,
    dropOff,
    dropOffCalculatedPrice,
  } = route.route;

  if (!realTimeBaseData) {
    return null;
  }

  const isContainer = !!route.containerParametersId;

  const {cargoParameters} = getCargoParams(
    isContainer,
    containerParameters,
    customCargoParameters,
    route,
    realTimeBaseData
  );

  if (!cargoParameters) {
    return null;
  }

  const steps = route.route.steps;
  const names = {
    pickOn: t('OfferDetail:CostIssuingContainer'),
    containerRent: t('OfferDetail:ContainerRentalCost'),
    dropOff: t('OfferDetail:ContainerReturnCost'),
  };

  const cost = getCost(containerRentCalculatedPrice, pickOnCalculatedPrice, dropOffCalculatedPrice);

  const currencyRoute = props.productData.baseData.currencies.find(
    (c) => props.currencyRoute.id === c.id
  );
  const targetCurrency = props.productData.baseData.currencies.find(
    (c) => props.currencyRoute.id === c.id
  );
  const pickOnCurrency =
    props.productData.baseData.currencies.find((c) => pickOn?.currency_id === c.id) ||
    targetCurrency;
  const dropOffCurrency =
    props.productData.baseData.currencies.find((c) => dropOff?.currency_id === c.id) ||
    targetCurrency;
  const containerRentCurrency =
    props.productData.baseData.currencies.find((c) => containerRent?.currency_id === c.id) ||
    targetCurrency;

  const column = OfferDetailTableHeaderData(currencyRoute);

  const rowsData = getRowsData({
    targetCurrency,
    realTimeBaseData,
    pickOn,
    pickOnCalculatedPrice,
    dropOff,
    dropOffCalculatedPrice,
    containerRent,
    containerRentCalculatedPrice,
    taxNames,
    names,
    pickOnCurrency,
    dropOffCurrency,
    containerRentCurrency,
  });

  const rows = ShoulderItemDetailsDTO(rowsData);

  return (
    <>
      <div>
        <Typography variant={'h2'} className={'heading'}>
          {t('Common:Delivery')}
        </Typography>
      </div>
      {!!steps?.length && <RouteProductDetailSteps steps={steps} taxNames={taxNames}/>}
      <TerminalsInfo steps={steps} taxNames={taxNames}/>
      <div className={'additional-service-table'}>
        {cost !== 0 && (
          <ShoulderItemTable
            header={<AdditionCostCaption cost={cost} targetCurrency={currencyRoute}/>}
            rows={rows}
            columns={column}
          />
        )}
      </div>
      <ServiceSummaryComp
        summary={getRouteSummary([route], props.productData.baseData.currencies, currencyRoute.code)}
        title={`${t('Common:Total')} ${t('Common:Delivery')} ${t('Common:Expenses')}`}
      />
    </>
  );
};

export default ProductRouteItem;
