import { $error } from '@settings/errorContext';
import { graphQLClient } from '@settings/services/graphQLClient';
import { GraphQLClient } from '@settings/services/graphQLClient/GraphQLClient';

import { LoaderInterface } from '../interface';
import {
  ShoulderTypesData,
  ShoulderTypesLoaderQuery,
  ShoulderTypesLoaderQueryResponse,
} from './ShoulderTypesLoaderQuery';

export class Loader implements LoaderInterface<ShoulderTypesData> {
  private readonly client: GraphQLClient;

  constructor(token?: string) {
    this.client = graphQLClient(token);
  }

  async Load(primaryKeys?: any[]): Promise<ShoulderTypesData[]> {
    try {
      const resp = await this.client.Query<null, ShoulderTypesLoaderQueryResponse>(
        new ShoulderTypesLoaderQuery(primaryKeys),
        {}
      );
      return resp.items;
    } catch (e) {
      $error.next(e);
    }
  }
}
