import {
  CustomsPaymentProducts
} from '@pages/AdditionalServices/tabs/CustomsPayments/components/CustomsPaymentsForm/context/types';
import {orderService} from '@services/requests/orderService';
import {Order} from '@services/requests/orderService/interface';
import {OrderProduct} from '@services/requests/orderService/orderProductsLoader/interface';
import {RouteResult} from '@services/requests/orderService/types';
import {useStateSubscriber} from '@settings/core/stateContexts/useContextSubscriber';
import {$error} from '@settings/errorContext';
import {BehaviorSubject} from 'rxjs';

import {
  checkCartExpireDate,
  getSavedCartState,
  getSavedCartStateByUserId,
  removeSavedCartStateByUserId,
  setSavedCartState,
} from './cart-helpers';
import BASKET_CONSTANTS from './consts';
import {ServiceData, SetUpServiceData} from "@pages/AdditionalServices/containers/methods/basket/types";

export const LOCALSTORAGE_CART_KEY = '__ON-LOG_CART__';
const $orderId = new BehaviorSubject<string | undefined>(undefined);
const $order = new BehaviorSubject<Order>(BASKET_CONSTANTS.order);
const $productsIds = new BehaviorSubject<string[]>([]);
const $isLoading = new BehaviorSubject<boolean>(false);

/**
 * Инициализация
 */
const init = async (language_id: string, currency_id: string, userId: string) => {
  checkCartExpireDate();
  const savedCartState = getSavedCartState();
  const foundCartByUserId = getSavedCartStateByUserId(savedCartState, userId);

  if (!foundCartByUserId) {
    const order = $order.getValue();

    $order.next({
      ...order,
      language_id,
      currency_id,
    });
    return;
  }

  const {orderId} = foundCartByUserId;

  try {
    const order = await orderService('').Load('preOrder', orderId);

    $order.next({
      ...order,
    });
    const productsIdsArray = order.products.map((p) => p.id);
    $productsIds.next(productsIdsArray);
    $orderId.next(orderId);
  } catch (e) {
    removeSavedCartStateByUserId(savedCartState, userId);
    $error.next(e);
  }
};

/**
 * Обработчик оформления заказа
 * @param id
 * @param name
 * @param email
 * @param phone
 */

const completeOrder = async (id: string, name: string, email: string, phone: string) => {
  const order = $order.getValue();
  const products = $productsIds.getValue();

  const selectedProducts = order.products.filter((p) => products.includes(p.id));

  const result = await orderService('').SaveOrder({
    ...order,
    products: selectedProducts,
    id: id,
    pre_order_id: order.id,
    customer_id: id,
    customer_name: name,
    customer_email: email,
    customer_phone: phone,
  });

  reInitCart(id);

  return result;
};

/**
 * Сброс состояния корзины
 */
const reInitCart = (userId: string) => {
  const savedCartState = getSavedCartState();
  removeSavedCartStateByUserId(savedCartState, userId);

  $orderId.next(undefined);
  $order.next(BASKET_CONSTANTS.order);
  $productsIds.next(BASKET_CONSTANTS.order.products.map((p) => p.id));
  $isLoading.next(false);
};

/**
 * Обработчик сохранения корзины в localStorage
 */
const saveCart = async (order: Order, userId: string): Promise<Order> => {
  const newOrder = await orderService('').SavePreOrder(order);
  setSavedCartState(userId, newOrder.id);

  $productsIds.next(newOrder.pre_order_products.map((p) => p.id));
  $orderId.next(newOrder.id);

  return {
    ...newOrder,
    products: newOrder?.pre_order_products || [],
  };
};

/**
 * Сравнение двух продуктов в зависимости от их типов
 * @param a
 * @param b
 */
export function isProductEquals(a: OrderProduct, b: OrderProduct) {
  const {product_type, data} = a;

  if (b.product_type !== product_type) {
    return false;
  }

  switch (product_type) {
    case 'route':
      const newRoute: RouteResult = JSON.parse(b.data);
      const currentRoute: RouteResult = JSON.parse(data);

      const isRouteGroupsEquals = newRoute.route.routes
        .map((r) => r.route.routeId)
        .map((id) => currentRoute.route.routes.map((r) => r.route.routeId).includes(id))
        .reduce((res: boolean, val: boolean): boolean => res && val, true);
      return (
        isRouteGroupsEquals && newRoute.route.routes.length === currentRoute.route.routes.length
      );
    default:
      return false;
  }
}

/**
 * Обработчик добавления товара в корзину
 * @param order
 * @param product
 */
const addProductToCart = (order: Order, product: OrderProduct): Order => {
  const existProduct = order.products.find((p) => isProductEquals(p, product));

  if (!existProduct) {
    return {
      ...order,
      products: [...order.products, product],
    };
  }

  $productsIds.next(order.products.map((p) => p.id));

  return {
    ...order,
    products: order.products.map((p) => {
      if (isProductEquals(p, product)) {
        p.amount += product.amount;
      }

      return p;
    }),
  };
};

/**
 * Обработчик добавления в корзину
 * @param product
 * @param userId
 */
const addToCart = async (product: OrderProduct, userId: string) => {
  const order = $order.getValue();

  $order.next(await saveCart(addProductToCart(JSON.parse(JSON.stringify(order)), product), userId));
};

/**
 * addToCartGroupOfServices принимает на вход предварительно подготовленные
 * данные услуг и формирует из них единый продукт для заказа.
 * @param services
 * @param userId
 */
const addToCartGroupOfServices = async (
  services: (ServiceData | SetUpServiceData)[],
  userId: string,
) => {
  if (0 === services.length) {
    return
  }

  const setUpService: SetUpServiceData = services.find((s: SetUpServiceData) => s?.IsSetupService) as SetUpServiceData
  if (!setUpService) {
    return
  }

  try {
    const serviceGroup = await orderService('').SavePreOrderGroup(
      services.map(s => {
        const price = s.service.price_offer_result.result.fullPriceInTargetCurrency
        return {
          amount: 1,
          price: price === -1 ? 0 : price,
          product_type: 'service',
          token: s.service.price_offer_result.token,
          data: JSON.stringify({
            ...s,
            sectionName: setUpService.ServicesPath["0"],
            serviceParent: setUpService.Variant,
            dateAddedToCart: (new Date()).toISOString(),
          }),
        }
      })
    )

    return await addToCart(serviceGroup, userId);
  } catch (e) {
    console.error(e)
  }
}

/**
 * addServiceToCart добавляет переданную услугу в корзину как отдельный товар
 * @param service
 * @param userId
 */
const addServiceToCart = async (
  service: SetUpServiceData,
  userId: string,
) => {
  try {
    const price = service.service.price_offer_result.result.fullPriceInTargetCurrency
    await addToCart({
      id: service.ServiceData.id,
      amount: 1,
      data: JSON.stringify({
        ...service,
        sectionName: service.ServicesPath["0"],
        serviceParent: service.Variant,
        dateAddedToCart: (new Date()).toISOString(),
      }),
      price: price === -1 ? 0 : price,
      product_type: "service",
      token: service.service.price_offer_result.token
    }, userId)
  } catch (e) {
    console.error(e)
  }
}

const addToCartCustomsPaymentsProduct = async (customsPaymentsProducts: CustomsPaymentProducts) => {
  const formattedProducts = customsPaymentsProducts.map((product) => ({
    ...product,
    type: 'customs-payments',
  }));

  //TODO: remove
  console.log(
    JSON.stringify({
      product_type: 'service',
      amount: 0,
      price: 0,
      token: 'LQt5F9w3aHHs3TrD3n8ZWXMDhLlgKSOtC25J2yZmfLMEeuJ0pRnRk8dAB689',
      data: JSON.stringify(formattedProducts),
    })
  );
  //   return await addToCart(preOrderCustomsPayments);
};

/**
 * Обработчик удаления продукта из корзины
 * @param product
 * @param userId
 */
const deleteFromCart = async (product: OrderProduct, userId: string) => {
  const savedCartState = getSavedCartState();
  const order = $order.getValue();

  const tmpOrder = {
    ...JSON.parse(JSON.stringify(order)),
    products: order.products.filter((p) => p.id !== product.id),
  };

  const products = tmpOrder.products
    .map((p: OrderProduct) => p.id)
    .filter(id => id !== product.id)

  $productsIds.next(products);
  $order.next(await saveCart(tmpOrder, userId));

  if (!tmpOrder.products.length) {
    removeSavedCartStateByUserId(savedCartState, userId);
  }
};

/**
 * Обработчик очистки корзины
 */
const clearCart = async (userId: string) => {
  const savedCartState = getSavedCartState();
  const order = $order.getValue();

  const tmpOrder = {
    ...JSON.parse(JSON.stringify(order)),
    products: [],
  };
  $productsIds.next([]);
  $order.next(await saveCart(tmpOrder, userId));

  if (!tmpOrder.products.length) {
    removeSavedCartStateByUserId(savedCartState, userId);
  }
};

/**
 * Обработчик создания временного предзаказа для возможности
 * формирования КП в pdf
 */
const createTempPreOrder = async (product: OrderProduct): Promise<Order> => {
  const {currency_id, language_id} = $order.getValue();
  const newPreOrder = await orderService('').SavePreOrder({
    ...BASKET_CONSTANTS.order,
    currency_id,
    language_id,
    id: '',
    products: [product],
  });

  $productsIds.next(newPreOrder!.pre_order_products.map((p) => p.id) || []);

  return {
    ...newPreOrder,
    products: newPreOrder!.pre_order_products || [],
  };
};

const actions = {
  completeOrder,
  reInitCart,
  saveCart,
  addProductToCart,
  addToCart,
  deleteFromCart,
  clearCart,
  addToCartCustomsPaymentsProduct,
  addToCartGroupOfServices,
  addServiceToCart,
  init,
  createTempPreOrder,
};

const useBasket = () => {
  const useIsLoading = () => useStateSubscriber<boolean>($isLoading);
  const useOrderId = () => useStateSubscriber<string | undefined>($orderId);
  const useOrder = () => useStateSubscriber<Order>($order);
  const useProductsIds = () => useStateSubscriber<string[]>($productsIds);

  return {
    useIsLoading,
    useOrderId,
    useOrder,
    useProductsIds,
    actions,
  };
};

export default useBasket;
