import {OrderProduct} from "@services/requests/orderService/orderProductsLoader/interface";
import {ServiceData, SetUpServiceData} from "@pages/AdditionalServices/containers/methods/basket/types";
import {
  ServiceSettings,
  ServiceFieldParameters,
  ServiceDirectorySettings,
  ServicePropertySettings,
  ServiceDirectory,
} from "@onlog-public/additional-services-types";

export interface ServiceRestoreResult {
  setUpService: SetUpServiceData
  otherServices: ServiceData[]
}

/**
 * restoreDirectory выполняет восстановление настроек директорий
 * @param dir
 */
const restoreDirectory = (dir: ServiceDirectory) => {
  return {
    ...dir,
    settings: ServiceDirectorySettings.RestoreSettings(dir.settings),
    fields: dir.fields.map(f => ({
      ...f,
      settings: ServiceFieldParameters.RestoreSettings(f.settings)
    })),
    properties: dir.properties.map(p => ({
      ...p,
      settings: ServicePropertySettings.RestoreSettings(p.settings)
    })),
  }
}

/**
 * Метод выполняет частичную валидацию и восстановление переданных данных
 * услуг. Проводит поверхностную проверку, что основные поля есть.
 *
 * TODO: Необходимо реализовать полную проверку всех восстанавливаемых свойств.
 *
 * @param services
 */
const restoreDataForServices = (
  services: ServiceData[]
): ServiceRestoreResult | undefined => {
  const validServices = services
    .filter(service => {
      return !!service.service
        && !!service.ServiceData
        && !!service.ServiceDataCache
        && !!service.ServiceFieldOptions
    })
    .map(s => ({
      ...s,
      ServiceData: {
        ...s.ServiceData,
        settings: ServiceSettings.RestoreSettings(s.ServiceData.settings),
        fields: s.ServiceData.fields.map(f => ({
          ...f,
          settings: ServiceFieldParameters.RestoreSettings(f.settings)
        }))
      },
    }))

  const setUpdService = validServices.find((s: SetUpServiceData) => s?.IsSetupService) as SetUpServiceData
  if (!setUpdService) {
    return
  }

  Object.keys(setUpdService.ServicesPath).map(k => {
    setUpdService.ServicesPath[k] = restoreDirectory(setUpdService.ServicesPath[k])
  })

  return {
    setUpService: {
      ...setUpdService,
      Variant: restoreDirectory(setUpdService.Variant),
    },
    otherServices: validServices.filter((s: SetUpServiceData) => !s?.IsSetupService)
  }
}

/**
 * restoreServiceData выполняет восстановление данных продукта в
 * типизированные данные дополнительных услуг. Если продукт
 * не подходит для восстановления, то возвращается пустой результат.
 * @param data
 */
const restoreServiceData = (
  data: OrderProduct,
): ServiceRestoreResult | undefined => {
  try {
    if (data.product_type === "service") {
      return restoreDataForServices([JSON.parse(data.data)])
    }

    if (data.product_type === "complex") {
      const products = (JSON.parse(data.data) as OrderProduct[])
          ?.filter(d => !!d?.data)
          .map(d => JSON.parse(d.data))
        ?? []

      return restoreDataForServices(products)
    }
  } catch (e) {
    console.error(e)
  }

  return undefined
}

export default restoreServiceData