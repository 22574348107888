export default function Popper() {
  return {
    MuiPopper: {
      defaultProps: {
        disablePortal: false,
        container: document.getElementById('onlog-widget-container'),
      },
      styleOverrides: {
        root: {
          maxWidth: '100vw',
        },
      },
    },
  };
}
