import { Theme } from '@material-ui/core/styles';

export default function MenuItem(theme: Theme) {
  return {
    MuiList: {
      defaultProps: {
        container: document.getElementById('onlog-widget-container'),
      },
      styleOverrides: {
        root: {
          padding: '5px 10px',
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        container: document.getElementById('onlog-widget-container'),
      },
      styleOverrides: {
        root: {
          padding: '8px 10px',
          borderRadius: 4,
          fontWeight: 600,
          '& .MuiSvgIcon-root': {
            fontSize: 16,
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.grey[100],
          },
          '&:hover': {
            backgroundColor: theme.palette.grey[100],
          },
        },
      },
    },
  };
}
