import {Service} from "@onlog-public/additional-services-types";
import {servicesFilterProcessor} from "@pages/AdditionalServices/containers/services/servicesFilter/types";
import {FilterPropertyData} from "../../types";

/**
 * SwitchFilter реализует фильтр по свойствам типа справочник
 */
export class SwitchFilter implements servicesFilterProcessor<"switch"> {
  /**
   * Возвращает тип свойства, для которого реализован процессор
   */
  getType(): "switch" {
    return "switch"
  }

  /**
   * Выполняет фильтрацию услуг по переданным параметрам фильтрации.
   * Если запрошена фильтрация по стоимости, то считает ее, исходя
   * из переданных расчетов по услугам.
   *
   * @param services
   * @param filterParams
   */
  Filter(
    services: Service[],
    filterParams: FilterPropertyData<"switch">,
  ): Service[] {
    const settings = filterParams.Property.settings.Filter.SwitchSettings
    const isNotNeedFilter = filterParams.FilterData.Type.length === 0 ||
      filterParams.FilterData.Current === "both" ||
      (settings.Variant === "single" && filterParams.FilterData.Current === "disabled")

    if (isNotNeedFilter) {
      return services
    }

    const availableValue = filterParams.FilterData.Current === "enabled" ? 1 : 0
    return services.filter(service => {
      const value = service.property_values.find(v => {
        return String(v.property_id) === filterParams.Property.id
      })

      if (!value) {
        return false
      }

      return value.value === availableValue
    })
  }
}