import palette from '@assets/theme/palette';
import Box from '@material-ui/core/Box/Box';
import { styled } from '@mui/material';

const Container = styled(Box)`
  .title {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.4px;
    margin-bottom: 20px;
  }

  .radio {
    svg {
      fill: ${({ theme }) => theme.palette.primary.main} !important;
    }
  }

  .radio-label {
    color: #000;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }

  .reset-all {
    color: ${palette.light.blue[400]};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    transition: 400ms;

    &:hover {
      color: #1e57bb;
    }
  }
`;

export default Container;
