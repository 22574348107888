import { GraphQLQuery } from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

import { LoadShortLinkQueryParams } from '../interface';

/**
 * Запрос на получение укороченной ссылки
 */
export class LoadShortLinkQuery implements GraphQLQuery<LoadShortLinkQueryParams> {
  readonly query: any;

  readonly variables: LoadShortLinkQueryParams;

  constructor(params: LoadShortLinkQueryParams) {
    this.variables = {
      ...params,
    };
    this.query = gql`
      query LoadShortLinkQuery($id: String!) {
        getShortLink(id: $id) {
          page
          data
          id
        }
      }
    `;
  }
}
