import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

export default function Button(theme: Theme) {
  return {
    MuiButton: {
      defaultProps: {
        container: document.getElementById('onlog-widget-container'),
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          fontSize: 16,
          fontWeight: 400,
        },
        sizeSmall: {
          fontWeight: '500',
          fontSize: 12,
          lineHeight: '15px',
          padding: '0 10px',
          borderRadius: 2,
          [theme.breakpoints.down('lg')]: {
            borderRadius: 4,
            lineHeight: 1,
            padding: '8px 12px',
          },
        },
        sizeMedium: {
          fontSize: 14,
          color: theme.palette.common.black,
        },
        sizeLarge: {
          fontWeight: 600,
          lineHeight: 'normal',
          padding: '11px 20px 12px',
          [theme.breakpoints.down('md')]: {
            padding: '10px 20px',
            fontSize: 14,
          },
        },
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          backgroundColor: theme.palette.success.main,
          boxShadow: theme.customShadows.primary,
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
