import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { resources } from '../../locales/resources';

export type Languages = 'en' | 'ru';
const languages: Languages[] = ['ru', 'en'];

i18n
  .use(Backend) // Укажите бэкенд для загрузки локализации
  .use(LanguageDetector) // Укажите детектор языка браузера для определения текущего языка
  .use(initReactI18next) // Подключите react-i18next
  .init({
    // @ts-ignore
    resources,
    lng: languages, // Укажите язык по умолчанию
    supportedLngs: languages, // Укажите поддерживаемые языки
    fallbackLng: 'ru',
    ns: [
      'Actions',
      'AppSettings',
      'BenefitsOfWorking',
      'BenefitsOfWorkingProfile',
      'CartAsideBlock',
      'CartComponent',
      'CartSummary',
      'Common',
      'CustomsCalculator',
      'DeliveryForm',
      'DeliveryItem',
      'EmptyStyleSelect',
      'FilterComponent',
      'FooterBottomBar',
      'LocationSelector',
      'OfferContainers',
      'OfferDetail',
      'OfferTipsText',
      'OrderForm',
      'OrderList',
      'PageNames',
      'RoutesCalculationWidget',
      'RoutesListComponent',
      'SortComponent',
      'TubsContainer',
      'TYOrder',
      'CustomsPayments',
    ], // Убедитесь, что указаны все ваши namespaces (если есть)
    debug: false, // Включите логирование для разработки
    // load: 'currentOnly', // Установите флаг для загрузки с сервера
    defaultNS: 'Common', // Установите ваш default namespace (если есть)
    initImmediate: false, // Установите флаг для отложенной инициализации
    interpolation: {
      // Установите опции интерполяции
      escapeValue: false, // Не обрабатывать HTML-теги
    },
    serializeConfig: false, // Установите флаг для сериализации опций бэкенда
    react: {
      useSuspense: false, // Установите флаг для использования Suspense для ожидания загрузки локализации
      wait: true, // Установите флаг для ожидания загрузки локализации перед рендерингом
    },
  });

export default i18n;
