import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface RouteInformationProps {
  routeInformations: string[];
  isDetailingOutput?: boolean;
}

/**
 * Компонент вывода списка справок о маршруте
 */
function RouteInformation({ routeInformations, isDetailingOutput }: RouteInformationProps) {
  const { t } = useTranslation('RoutesListComponent');

  return (
    <div className={clsx('route-info-wrap', { detailing: isDetailingOutput })}>
      <span className="route-info-title">{t('RoutesListComponent:ImportantInformation')}</span>
      {routeInformations.map((info, index) => (
        <div
          key={'route-information-' + index}
          className="route-infor-text"
          dangerouslySetInnerHTML={{ __html: info }}
        ></div>
      ))}
    </div>
  );
}

export { RouteInformation };
