import {ServiceDirectory} from "@onlog-public/additional-services-types";
import {
  ServicesServicePriceCalculationMutationProps
} from "@services/requests/servicesService/servicesServicesService/interface";

/**
 * getTotalPriceForDirectory возвращает полную стоимость услуг в рассчитанном
 * варианте. При расчете исключаются услуги с отрицательной стоимостью.
 *
 * @param directory
 * @param services
 * @param selectedServices
 */
const getTotalPriceForDirectory = (
  directory: ServiceDirectory,
  services: { [T in string]: ServicesServicePriceCalculationMutationProps[] },
  selectedServices: string[],
): number => {
  const servicesToCalculate = services[directory.id]?.filter(s => (
    selectedServices.includes(s.serviceId)
  ))

  if (!servicesToCalculate) {
    return -1
  }

  const isServiceWithPriceExists = !!servicesToCalculate.find(
    p => p.result.result.fullPriceInTargetCurrency > -1
  )

  if (!isServiceWithPriceExists) {
    return -1
  }

  return servicesToCalculate
    .reduce(
      (v, s) => s.result.result.fullPriceInTargetCurrency == -1
        ? v
        : v + s.result.result.fullPriceInTargetCurrency,
      0,
    )
}

export default getTotalPriceForDirectory