import { formatPrice } from '@pages/OfferDetail/helpers';
import { OrderProduct } from '@services/requests/orderService/orderProductsLoader/interface';
import { ContainerParameters, CustomCargoParameters } from '@services/requests/orderService/types';
import { ContainersData } from '@services/requests/searchLoaders/containersLoader/ContainersLoaderQuery';
import { UnitData } from '@services/requests/searchLoaders/unitLoader/UnitLoaderQuery';
import { useTranslation } from 'react-i18next';

export const getActionClassName = (product: OrderProduct) => {
  if (product.product_type === 'route') {
    return '';
  }

  if (product.product_type === 'service') {
    const data = JSON.parse(product.data);

    const productType = Array.isArray(data) ? data[0].type : data.type;

    if (productType === 'customs-payments') {
      return 'order-item__service-actions --top';
    }
  }

  return 'order-item__service-actions';
};

/**
 * Вычисление описания груза для контейнера
 * @param containers
 * @param cargo
 */
export const calculateCargoForContainer = (
  containers: ContainersData[],
  cargo: ContainerParameters,
  langCode?: string
): string => {
  const container = containers.find((c) => c.id === `${cargo.containerId}`);
  const { default_name = '?' } = container || {};
  const { t } = useTranslation('Common', { lng: langCode });

  return `${default_name} (${formatPrice(cargo.eachContainerWeight, 0)} ${t('Common:kg')}) × ${
    cargo.containerQuantity
  }`;
};

/**
 * Вычисление описания груза для контейнеров
 * @param containers
 * @param cargoData
 * @param langCode
 */
export const calculateCargoForContainers = (
  containers: ContainersData[],
  cargoData?: ContainerParameters[] | null,
  langCode?: string
): string[] => {
  if (!cargoData) {
    return [];
  }

  return cargoData.map((cargo) => calculateCargoForContainer(containers, cargo, langCode));
};

/**
 * Вычисление описания груза для одиночной сборки
 * @param units
 * @param cargo
 * @param langCode
 */
export const calculateCargoForSingleCustom = (
  units: UnitData[],
  cargo: CustomCargoParameters,
  langCode?: string
): string => {
  const { t } = useTranslation('Common', { lng: langCode });
  switch (cargo.parametersType) {
    case 'base':
      const { width, height, length, weight, quantity } = cargo.baseParameters;

      return `${t('Common:General')} (${t('Common:Width')}: ${width} ${t('common:m')} × ${t(
        'Common:Length'
      )}: ${length} ${t('common:m')} × ${t('Common:Height')}: ${height} ${t(
        'common:m'
      )} × ${weight} ${t('Common:kg')}) × ${quantity}`;
    case 'volume':
      const { volume, weight: party } = cargo.volumeParameters;

      return `${t('Common:Consignment')} (${volume} ${t('Common:m3')} × ${party} ${t(
        'Common:kg'
      )})`;
    case 'pallet':
      const {
        type,
        quantity: q,
        weight: pallet,
        normativeHeightOfStacking: n,
      } = cargo.palletParameters;

      const p = units.find((u) => u.id === type);
      const { length: l = '?', width: w = '?', default_name = '?' } = p || {};

      return `${default_name} (${t('Common:Width')}: ${w} ${t('Common:m')} × ${t(
        'Common:Length'
      )}: ${l} ${t('Common:m')} × ${t('Common:Height')}: ${n} ${t('Common:m')} × ${pallet} ${t(
        'Common:kg'
      )}) × ${q}`;
  }

  return '';
};

/**
 * Вычисление описания груза для сборки
 * @param cargoData
 * @param units
 * @param langCode
 */
export const calculateCargoForCustom = (
  units: UnitData[],
  cargoData?: CustomCargoParameters[] | null,
  langCode?: string
): string[] => {
  if (!cargoData) {
    return [];
  }

  return cargoData.map((cargo) => calculateCargoForSingleCustom(units, cargo, langCode));
};
