import { styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';

export const CalculationLimitDialogStyledContainer = styled(Dialog)`
  .close-button {
    position: absolute;
    top: 8px;
    right: 8px;
  }
`;

export const CalculationLimitStyledContainer = styled('section')`
  padding: 30px;
  gap: 15px;
  display: flex;
  flex-flow: column;
  width: 100%;
`;
