import { styled } from '@mui/material/styles';

const CargoTypeInformationStyledContainer = styled('div')`
  max-width: 100%;

  .title {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 15px 10px;
    background-color: #cedcf9;
    border-radius: 18px 18px 0 0;
  }

  .MuiDataGrid-iconButtonContainer {
    display: none;
  }

  .MuiDataGrid-root {
    border: 0;
  }

  .MuiDataGrid-row {
    &:last-child {
      .MuiDataGrid-cell {
        &:first-child {
          border-radius: 0 0 0 18px;
        }
        &:last-child {
          border-radius: 0 0 18px;
        }
      }
    }
  }
`;

export default CargoTypeInformationStyledContainer;
