import {Service} from "@onlog-public/additional-services-types";
import {ServicePriceCalculationItemsProps} from "@services/requests/servicesService/servicesServicesService/interface";
import {
  serviceToCalculateGeneratorProcessor
} from "@pages/AdditionalServices/containers/services/serviceToCalculateGenerator/interface";
import {v4} from "uuid";

/**
 * DefaultProcessor реализует процессор генерации запросов расчета
 * стоимости для услуг. Доступен для всех услуг по умолчанию.
 */
export class DefaultProcessor implements serviceToCalculateGeneratorProcessor {
  /**
   * isAvailable проверяет доступность процессора для переданной услуги.
   * @param _
   */
  isAvailable(_: Service): boolean {
    return true
  }

  /**
   * Generate выполняет генерацию запросов на расчет услуг
   * по переданным данным. Позволяет сформировать для услуги
   * сразу несколько запросов, например с разными значениями
   * полей.
   *
   * @param service
   * @param currency
   * @param fieldValues
   * @param serviceValues
   * @param _
   */
  Generate(
    service: Service,
    currency: string,
    fieldValues: { [x: string]: number; },
    serviceValues: { [x: string]: number; },
    _: any,
  ): ServicePriceCalculationItemsProps[] {
    const mapVal = (values: { [x: string]: number; }) => (k: string) => ({
      key: k,
      value: String(values[k]),
    })

    return [{
      stateID: v4(),
      currency_id: currency,
      service_id: service.id,
      values: [
        ...Object.keys(fieldValues).map(mapVal(fieldValues)),
        ...Object.keys(serviceValues).map(mapVal(serviceValues)),
      ],
    }]
  }
}