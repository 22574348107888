import { styled } from '@mui/material/styles';

export const TaxCellStyledContainer = styled('div', { name: 'TaxCellStyledContainer' })`
  display: grid;
  justify-content: center;
  width: 100%;
  text-align: center;
`;

export const TaxServiceCellStyledContainer = styled('div', { name: 'TaxCellStyledContainer' })`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-align: center;
  height: 100%;
`;
