import { GraphQLQuery } from '@settings/services/graphQLClient/GraphQLClient';
/**
 * Ответ на запрос получения дочерних локаций
 */
import gql from 'graphql-tag';

import { BranchItem } from '../locationsBranchSearchService/interfaces';

export type ChildrenLocationQueryResponse = {
  childLocation: BranchItem[];
};

/**
 * Запрос на получение дочерних локаций
 */
export class ChildrenLocationQuery implements GraphQLQuery<{ id: string }> {
  readonly query: any;

  readonly variables: { id: string };

  constructor(id: string) {
    this.variables = { id };
    this.query = gql`
      query getAllChildrenLocationsForPassedParent($id: ID!) {
        childLocation: getAllChildrenLocationsForPassedParent(location: $id) {
          id
          default_name
        }
      }
    `;
  }
}
