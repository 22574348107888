import PlusIcon from '@components/icons/PlusIcon';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check'
import { useMediaQuery } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import VideoInstruction from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/VideoInstruction';
import CargoSpecialConditional from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/сargoSpecialConditional';
import {
  ContainerParameters,
  ContainerRouteCalculationParameters,
  RouteWidgetBaseParameters,
} from '@pages/AdditionalServices/tabs/DeliveryForm/types';
import useDelivery from '@pages/AdditionalServices/tabs/DeliveryForm/useDelivery';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ContainerParametersItemComponent from './ContainerParametersItemComponent';

export interface ContainerParametersProps {
  BaseData: RouteWidgetBaseParameters;
  containerParameters: ContainerRouteCalculationParameters;
  mainLanguage: string; // Текущий основной язык
  systemLanguage: string; // Системный язык
  baseConditionsSlot?: React.ReactNode;
  resetSlot?: React.ReactNode;
  copyNotificationVisible: boolean;

  handleDeleteContainerParameters(index: number): void;
  onChangeCargoFieldsFilled: { (values: boolean[]): void };
}

const ContainerParametersComponent: FC<ContainerParametersProps> = (props) => {
  const {
    baseConditionsSlot,
    resetSlot,
    handleDeleteContainerParameters,
    onChangeCargoFieldsFilled,
    containerParameters: {
      containerParameters: cargoParameters,
      isCustomConditionsOpen,
      isDangerousCargo,
    },
	copyNotificationVisible
  } = props;

  const { useRouteParams } = useDelivery();
  const setRouteParameter = useRouteParams().setState;
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation('Actions');

  const handleAddNewContainer = () => {
    const { id = '', container_type = '', default_weight } = props.BaseData.containers[0];

    setRouteParameter((current) => ({
      ...current,
      containerParameters: {
        ...current.containerParameters,
        containerParameters: [
          ...current.containerParameters.containerParameters,
          {
            containerId: id,
            containerType: container_type,
            containerQuantity: 1,
            eachContainerWeight: default_weight,
          },
        ],
      },
    }));
  };

  /**
   * Обработка изменения параметров контейнера
   * @param containerParameters
   * @param index
   * @param field
   */
  const handleChangeContainerParameters = (
    containerParameters: ContainerParameters,
    field: keyof ContainerParameters,
    index: number
  ) => {
    setRouteParameter((current) => {
      // Создание копии массива containerParameters
      const updatedContainers = [...current.containerParameters.containerParameters];
      const containerProps = props.BaseData.containers.find(
        ({ id }) => id === containerParameters.containerId
      );

      if (field === 'containerId') {
        containerParameters.eachContainerWeight = containerProps.default_weight || 10000;
      }

      // Обновление элемента в скопированном массиве
      updatedContainers[index] = {
        ...containerParameters,
      };

      // Возвращение обновленного состояния
      return {
        ...current,
        containerParameters: {
          ...current.containerParameters,
          containerParameters: updatedContainers,
        },
      };
    });
  };

  /**
   * Обработка изменения состояния показа доп условий перевозки
   */
  const handleToggleCustomConditionsState = () => {
    setRouteParameter((current) => ({
      ...current,
      containerParameters: {
        ...current.containerParameters,
        isDangerousCargo: false,
        isCustomConditionsOpen: !current.containerParameters.isCustomConditionsOpen,
      },
    }));
  };

  /**
   * Обработка изменения состояния флага "Опасный груз"
   * @param status
   */
  const handleChangeDangerousCargoStatus = (status: boolean) => {
    setRouteParameter((current) => ({
      ...current,
      containerParameters: {
        ...current.containerParameters,
        isDangerousCargo: status,
      },
    }));
  };

  return (
    <>
      <section className="cargo-list">
        {cargoParameters.map((container, i) => (
          <div key={`list-item--${i}`} className={'cargo-item'}>
            <ContainerParametersItemComponent
              Position={1 + i}
              BaseData={props.BaseData}
              mainLanguage={props.mainLanguage}
              systemLanguage={props.systemLanguage}
              ContainerParameters={container}
              onChange={(params, field) => handleChangeContainerParameters(params, field, i)}
              isDeletable={cargoParameters.length > 1}
              onDelete={() => handleDeleteContainerParameters(i)}
              onChangeCargoFieldsFilled={onChangeCargoFieldsFilled}
            />
          </div>
        ))}
        <IconButton
          title={`+ ${t('Actions:Add')}`}
          className={'add-new-cargo'}
          onClick={() => handleAddNewContainer()}
        >
          <PlusIcon />
        </IconButton>
      </section>
      <div className="footer">
        <div className={'base-params'}>
          {baseConditionsSlot}
          <CargoSpecialConditional
            isNeedCustomCondition={isCustomConditionsOpen}
            onToggleCustomCondition={handleToggleCustomConditionsState}
            isDangerousCargo={isDangerousCargo}
            onChangeDangerousCargo={(status) => handleChangeDangerousCargoStatus(status)}
          />
        </div>
        <div className={'right'}>
          {isNotMobile && <VideoInstruction />}
          {resetSlot && (
            <div className={'routes-calculation-widget__footer-reset-container'}>
              <>
                {copyNotificationVisible && (
                  <Alert
                    icon={<CheckIcon fontSize="inherit" />}
                    severity="success"
                    className="copy-success-alert"
                  >
                    {t('RoutesCalculationWidget:CopyCalculationLinkSuccess')}
                  </Alert>
                )}
                {resetSlot}
              </>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ContainerParametersComponent;
