import { LocalizedMessage } from '@services/requests/localizedMessagesService/interfaces';
import { RoutesGroup } from '@services/requests/orderService/types';

/**
 * Расчет налога для переданной группы маршрутов
 * @param route
 */
export function calculateTax(route: RoutesGroup): number {
  let result = 0;
  route.routes.map((route) => {
    let tax =
      (route.route.containerRentCalculatedPrice
        ? route.route.containerRentCalculatedPrice.taxInTargetCurrency
        : 0) +
      (route.route.dropOffCalculatedPrice
        ? route.route.dropOffCalculatedPrice.taxInTargetCurrency
        : 0) +
      (route.route.pickOnCalculatedPrice
        ? route.route.pickOnCalculatedPrice.taxInTargetCurrency
        : 0);
    route.route.steps.map((step) => {
      let stepTax =
        step.endTerminalCalculatedPrice.taxInTargetCurrency +
        step.startTerminalCalculatedPrice.taxInTargetCurrency +
        step.shoulderOfferCalculatedPrice.taxInTargetCurrency;
      [
        ...step.startTerminalAllowances,
        ...step.endTerminalAllowances,
        ...step.shoulderAllowances,
      ].map((allowance) => {
        stepTax += allowance.calculatedPrice.taxInTargetCurrency;
      });

      tax += stepTax;
    });

    result += tax;
  });

  return Math.round(result * 100) / 100;
}

/**
 * Форматирование числа с пробелами
 * @param x
 */
export const formatPrice = (x: number) =>
  (Math.round(x * 100) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

/**
 * Генерация локализованного названия по переданным параметрам
 * @param defaultName
 * @param localizations
 * @param langId
 */
export function generateLocalizedName(
  defaultName: string,
  localizations: LocalizedMessage[],
  langId: string
): string {
  const localization = localizations.find((l) => l.lang_id === langId) || { message: `` };
  if (localization.message.trim().length === 0) {
    return defaultName;
  }

  return localization.message;
}
