import getEnv from '@settings/getEnv';
import Cookies from 'cookies-ts';

import { Collection } from '../types';
import { GraphQLClient, GraphQLQuery } from './GraphQLClient';

/**
 * Реализация серверного клиента GraphQL
 */
export class MainClient implements GraphQLClient {
  private readonly client: GraphQLClient;

  private readonly token?: string;

  /**
   * Конструктор клиента
   *
   * @param client
   * @param token
   */
  constructor(client: GraphQLClient, token?: string) {
    this.client = client;
    this.token = token;
  }

  async Query<V, Response>(
    query: GraphQLQuery<V>,
    headers: Collection<string>,
  ): Promise<Response> {
    headers = await this.getHeaders(headers);
    return await this.client.Query<V, Response>(query, headers);
  }

  async Mutation<V, Response>(
    query: GraphQLQuery<V>,
    headers: Collection<string>
  ): Promise<Response> {
    headers = await this.getHeaders(headers);
    return await this.client.Mutation<V, Response>(query, headers);
  }

  /**
   * Генерация базовых заголовков для запроса
   *
   * @param baseHeaders
   */
  private async getHeaders(baseHeaders: Collection<string>): Promise<Collection<string>> {
    let token = '';
    if (this.token) {
      token = this.token;
    }

    /**
     * Получаем токен авторизации пользователя
     */
    const cookies = new Cookies();
    const env = getEnv();
    const jwt = cookies.get(env.REACT_APP_AUTH_COOKIE);

    if (jwt?.length > 0) {
      baseHeaders['BITRIX_SM_ONLOG_LOC_USER_JWT_TOKEN'] = jwt;
    }

    return {
      ...baseHeaders,
      Authorization: token,
    };
  }
}
