export const getStringFormattedSearchParams = (
  params: Record<string, string | number | Date>,
  isRemoveQuotes: boolean = false
) => {
  const searchParams = new URLSearchParams();

  const formattedRouteSearchParams = Object.entries(params);

  for (const [key, value] of formattedRouteSearchParams) {
    if (value instanceof Date) {
      searchParams.set(key, value.toISOString());
    } else {
      searchParams.set(key, JSON.stringify(value));
    }
  }

  const stringFormattedSearchParams = searchParams.toString();

  if (!stringFormattedSearchParams.length) {
    return '';
  }

  return (
    '?' +
    (isRemoveQuotes ? stringFormattedSearchParams.replace(/%22/g, '') : stringFormattedSearchParams)
  );
};
