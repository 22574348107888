import { CircularProgress, ClickAwayListener, InputAdornment, TextField } from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CommonStep from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/LocationSelector/CommonStep';
import useGetOptions from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/LocationSelector/useGetOptions';
import { BranchItem } from '@services/requests/routeCalculator/locationsBranchSearchService/interfaces';
import { useDebounceStream } from '@settings/core/stateContexts/useDebounceStream';
import clsx from 'clsx';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useDelivery from '../../../useDelivery';
import { getLocationValidation } from './helpers';
import Container from './StyledComponents';

// Пропсы компонента поиска и выбора локации с картой
export type LocationSelectorProps = {
  location: BranchItem | undefined; // Текущая выбранная локация
  error: boolean; // Текущая выбранная локация
  onChangeLocation: (location: BranchItem | undefined) => void; // Обработчик выбранной локации
  label?: string; // Label поля поиска
  primaryLangId: string; // Основной язык
  secondaryLangId: string; // Дополнительный язык
  direction?: 'left' | 'right';
  isFrom?: boolean;
};

// Компонент поиска и выбора локации с картой
const LocationSelector = (props: LocationSelectorProps) => {
  const { t } = useTranslation(['Common', 'LocationSelector']);
  const [isConfigFromSearchParams] = useDelivery().useIsConfigFromSearchParams().useState();
  const {
    label,
    location,
    error,
    primaryLangId,
    secondaryLangId,
    onChangeLocation,
    direction,
    isFrom = true,
  } = props;
  const [isOpen, setOpenState] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [searchValue, value, setValue] = useDebounceStream('');

  useEffect(() => {
    if (!location) {
      return;
    }

    if (location?.main) {
      const name =
        location.localizedNamesArray.find((i) => i.lang_id === primaryLangId)?.message ||
        location.defaultName;
      setValue(`${name}, ${t(`LocationSelector:${location.visibleName}`)}`);
      return;
    }

    const name =
      location.localizedNamesArray.find((i) => i.lang_id === primaryLangId)?.message ||
      location.visibleName;
    if (location.type === 'terminal') {
      setValue(`${name} (${t('Common:Terminal')})`);
      return;
    }
    setValue(name);
  }, [location]);

  const handleClickAway = () => {
    setOpenState(false);
  };

  const handleChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    const newNeedle = event.target.value;
    onChangeLocation(undefined);
    setValue(newNeedle);
  };

  const onSelectHandle = (location: BranchItem | undefined) => {
    onChangeLocation(location);
    (location.type === 'terminal' || location.main) && setOpenState(false);
  };

  const onFocus = () => {
    setOpenState(true);
  };

  const onBlur = () => {
    if (!value.length) {
      return;
    }
    setIsTouched(true);
  };

  const { isLoading, items } = useGetOptions({
    location,
    isFrom,
    searchValue,
    primaryLangId,
    isConfigFromSearchParams,
  });

  useEffect(() => {
    if (isLoading) {
      setOpenState(true);
    }
  }, [isLoading]);

  const { isCityNotSelected, isError, isTerminalNotSelected } = getLocationValidation({
    isOpen,
    isTouched,
    location,
    value,
  });

  let locationErrorText = t('DeliveryForm:LocationErrors.CityNotSelected');

  if (!isCityNotSelected && isTerminalNotSelected) {
    locationErrorText = t('DeliveryForm:LocationErrors.TerminalNotSelected');
  }

  const endAdornment = (
    <InputAdornment position="end">
      {isLoading ? (
        <CircularProgress color="inherit" size={20} />
      ) : (
        <ExpandMoreIcon sx={{ fontSize: 24, color: 'grey.300' }} />
      )}
    </InputAdornment>
  );

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Container
        className={clsx({
          '--is-reverse': direction === 'right',
          //   "location-selector": true
        })}
        sx={{ position: 'relative' }}
      >
        {!isOpen && isError && <span className="location-error">{locationErrorText}</span>}
        <TextField
          fullWidth
          onChange={handleChangeValue}
          value={value}
          onFocus={onFocus}
          onBlur={onBlur}
          label={label}
          error={isError}
          className={'custom__select'}
          InputProps={{ endAdornment }}
        />
        {isOpen && (
          <CommonStep
            items={searchValue === '' ? [] : items}
            isLoading={isLoading}
            defaultValue={value}
            onSelectLocation={onSelectHandle}
            primaryLangId={primaryLangId}
            secondaryLangId={secondaryLangId}
            selectedLocation={location}
          />
        )}
      </Container>
    </ClickAwayListener>
  );
};

export default LocationSelector;
