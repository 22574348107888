import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9933 9.34559V12.1988C18.0541 12.5631 17.6898 12.9273 17.265 12.9273C16.9614 12.9273 16.6625 12.7452 16.4803 12.4417C16.116 11.6525 15.327 11.1061 14.3556 11.1061C13.2022 11.1061 12.2916 11.8953 11.9881 12.9273H7.5564C7.31349 11.8953 6.34225 11.1061 5.18873 11.1061C4.03537 11.1061 3.12476 11.8953 2.82122 12.9273H1V2H9.49903C10.1669 2 10.7132 2.54637 10.7132 3.21415V11.7132H11.3203V5.21749C11.3203 4.79254 11.6846 4.4283 12.1095 4.4283H14.8413C15.2662 4.4283 15.6911 4.67112 15.8733 5.03537L17.6898 8.13145C17.8721 8.49569 17.9933 8.92064 17.9933 9.34559ZM12.5344 5.64244V8.67781H16.7231L14.9627 5.64244H12.5344ZM5.24956 11.7131C4.21758 11.7131 3.42834 12.5023 3.42834 13.5343C3.42834 14.5663 4.21758 15.3555 5.24956 15.3555C6.28155 15.3555 7.07079 14.5663 7.07079 13.5343C7.07079 12.5023 6.28155 11.7131 5.24956 11.7131ZM5.24956 14.3842C4.76387 14.3842 4.39974 14.02 4.39974 13.5343C4.39974 13.0486 4.76391 12.6844 5.24956 12.6844C5.73522 12.6844 6.09939 13.0486 6.09939 13.5343C6.09939 14.02 5.73526 14.3842 5.24956 14.3842ZM14.3555 11.7131C13.3235 11.7131 12.5343 12.5023 12.5343 13.5343C12.5343 14.5663 13.3235 15.3555 14.3555 15.3555C15.3875 15.3555 16.1767 14.5663 16.1767 13.5343C16.1767 12.5023 15.3875 11.7131 14.3555 11.7131ZM14.3555 14.3842C13.8698 14.3842 13.5057 14.02 13.5057 13.5343C13.5057 13.0486 13.8699 12.6844 14.3555 12.6844C14.8412 12.6844 15.2053 13.0486 15.2053 13.5343C15.2053 14.02 14.8412 14.3842 14.3555 14.3842Z"
      fill="#B9C0CE"
    />
  </svg>
);
