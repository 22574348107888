import { localizedMessagesService } from '@services/requests/localizedMessagesService';
import { LoadListTax } from '@services/requests/taxService/queries/LoadQuery';
import { $error } from '@settings/errorContext';
import { graphQLClient } from '@settings/services/graphQLClient';
import { GraphQLClient } from '@settings/services/graphQLClient/GraphQLClient';
import { loggerFactory } from '@settings/services/logger';
import { Logger } from '@settings/services/logger/Logger';

import { LoadTaxesParams, LoadTaxesResponse, Tax, TaxServiceInterface } from './interface';

// Сервис загрузки данных заказов
export class TaxService implements TaxServiceInterface {
  private readonly logger: Logger;

  private readonly client: GraphQLClient;

  /**
   * Конструктор сервиса
   */
  constructor() {
    this.logger = loggerFactory().make(`Tax Service`);
    this.client = graphQLClient();
  }

  /**
   * Загрузка товаров заказа
   */
  async Load(params: LoadTaxesParams): Promise<Tax[]> {
    try {
      this.logger.Debug('Params:', params);
      const res = await this.client.Query<LoadTaxesParams, LoadTaxesResponse>(
        new LoadListTax(params),
        {}
      );

      async function processTaxes(array: Tax[]) {
        const localizedTaxes: Tax[] = [];

        for (const tax of array) {
          const localizedMessages = await localizedMessagesService().GetMessagesArray(
            tax.localized_names
          );
          const newTax: Tax = {
            ...tax,
            localized_names_array: localizedMessages,
          };
          localizedTaxes.push(newTax);
        }

        return localizedTaxes;
      }

      return processTaxes(res.tax_list).then((r) => {
        this.logger.Debug('Loaded taxes:', r);
        return r;
      });
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }
}
