import breakpoints from '@assets/theme/breakpoints';
import Box from '@material-ui/core/Box/Box';
import { styled } from '@mui/material';

const Container = styled(Box)`
  display: grid;
  grid-template-columns: 300px 1fr;
  align-items: flex-start;
  gap: 24px;
  max-width: 100%;

  @media (max-width: ${breakpoints.values.md}px) {
    grid-template-columns: 1fr;
  }

  .main-content-wrapper {
    max-width: 100%;
  }

  .wrapper__order {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: column;

    @media (max-width: ${breakpoints.values.md}px) {
      align-items: center;
    }
  }

  .params__order {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    @media (max-width: ${breakpoints.values.md}px) {
      margin-bottom: 20px;
      width: 100%;
      border-radius: 8px;
      background: #fff;
      box-shadow: 0 0 20px 0 rgb(0 5 115 / 7%);
      padding: 15px 0;
    }

    .mobile__title {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }
  }

  .test {
    grid-row: auto;
    position: sticky;
    top: 20px;
  }

  .close-button {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .routes-list__archive {
    border-radius: 18px;
    background: #fff;
    border: none;
    box-shadow: 0 0 20px 0 rgba(0, 5, 115, 0.07);

    &:hover {
      box-shadow: 0 0 20px 0 rgba(138, 141, 255, 0.28);
    }
  }
`;

export default Container;
