import {servicesFilterProcessor} from "@pages/AdditionalServices/containers/services/servicesFilter/types";
import {Service, ServicePropertyType} from "@onlog-public/additional-services-types";
import {FilterPropertyData, ListFilterData} from "../../types";

/**
 * ListFilter реализует процессор фильтрации значений по свойствам типа "Список"
 */
export class ListFilter<T extends Exclude<ServicePropertyType, "switch" | "number">> implements servicesFilterProcessor<T> {
  protected type: T

  /**
   * Конструктор процессора
   * @param type
   */
  constructor(type: T) {
    this.type = type
  }

  /**
   * Возвращает тип свойства, для которого реализован процессор
   */
  getType(): T {
    return this.type
  }

  /**
   * Выполняет фильтрацию услуг по переданным параметрам фильтрации.
   * Если запрошена фильтрация по стоимости, то считает ее, исходя
   * из переданных расчетов по услугам.
   *
   * @param services
   * @param filterParams
   */
  Filter(services: Service[], filterParams: FilterPropertyData<T>): Service[] {
    const filter = filterParams.FilterData as ListFilterData
    if (filter.Selected.length === 0) {
      return services
    }

    return services.filter(service => {
      const value = service.property_values.find(v => {
        return String(v.property_id) === filterParams.Property.id
      })

      if (!value) {
        return false
      }

      return filter.Selected.includes(value.value)
    })
  }
}