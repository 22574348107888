import { RoutesGroup } from '@services/requests/orderService/types';
import { BranchItem } from '@services/requests/routeCalculator/locationsBranchSearchService/interfaces';
import React, { SetStateAction, useEffect, useMemo, useState } from 'react';

import { RouteWidgetBaseParameters, RouteWidgetParams } from '../../../types';
import { generateLocalizedName } from '../helpers';
import { generateStepsData } from './helpers';
import RouteShoulder, { RouteShoulderProps } from './RouteShoulder';
import { useYaHit } from '@context/useYaHit';

/**
 * Просы компонента
 */
export type RouteDetailsProps = {
  routeParams: RouteWidgetParams;
  route: RoutesGroup;
  baseData: RouteWidgetBaseParameters;
  primaryLanguageId: string;
  terminals: BranchItem[];
  isFullView: boolean;

  setFullView(value: SetStateAction<boolean>): void;
};

function generateRouteStepsData(
  routeParams: RouteWidgetParams,
  isFullView: boolean,
  terminals: BranchItem[],
  baseData: RouteWidgetBaseParameters,
  primaryLanguageId: string,
  route: RoutesGroup
) {
  const { startLocation, endLocation } = routeParams;

  const steps = route.routes[0].route.steps.sort((a, b) => (a.stepNumber > b.stepNumber ? 1 : -1));

  if (!startLocation || !endLocation) {
    return [];
  }

  if (isFullView) {
    return generateStepsData(
      steps,
      startLocation,
      endLocation,
      terminals,
      baseData,
      primaryLanguageId
    );
  }

  const firstStep = steps.find((s) => s.stepNumber === 0);
  const lastStep = steps.find((s) => s.stepNumber === steps.length - 1);

  if (!firstStep || !lastStep) {
    return [];
  }

  const firstStepContractor = baseData.contractors.find(
    (c) => c.id === firstStep.shoulder.contractor_id
  );
  const lastStepContractor = baseData.contractors.find(
    (c) => c.id === lastStep.shoulder.contractor_id
  );

  if (!lastStepContractor || !firstStepContractor) {
    return [];
  }

  const stepTypes = steps
    .map((step) => {
      const stepShoulderType = baseData.shoulderTypes.find(
        (s) => s.id === step.shoulder.shoulder_type
      );
      if (!stepShoulderType) {
        return [''];
      }

      if (!stepShoulderType.transport_type) {
        const shoulderSteps = step.shoulder.shoulderSteps.sort((a, b) =>
          a.position < b.position ? -1 : 1
        );

        return shoulderSteps.map((s) => s.transportType.id);
      }

      return [`${stepShoulderType.transport_type}`];
    })
    .flat()
    .filter((t) => !!t);

  const endTerminal =
    (endLocation.type === 'terminal' && endLocation.id !== lastStep.endTerminal?.id
      ? terminals.find((t) => t.id === lastStep.endTerminal?.id)
      : endLocation) || endLocation;

  const startTerminal =
    (startLocation.type === 'terminal' && startLocation.id !== firstStep.startTerminal?.id
      ? terminals.find((t) => t.id === firstStep.startTerminal?.id)
      : startLocation) || startLocation;

  const shortStep: RouteShoulderProps = {
    startLocation: startTerminal,
    endLocation: endTerminal,
    deliveryDays: route.deliveryTime,
    endContractorName: generateLocalizedName(
      lastStepContractor.default_name,
      lastStepContractor.localized_names_data,
      primaryLanguageId
    ),
    startContractorName: generateLocalizedName(
      firstStepContractor.default_name,
      firstStepContractor.localized_names_data,
      primaryLanguageId
    ),
    routeStepTypes: stepTypes,
  };

  return [shortStep];
}

/**
 * Компонент вывода информации о маршруте
 * @param props
 */
const RouteDetails = (props: RouteDetailsProps) => {
  const { routeParams, route, baseData, primaryLanguageId, terminals, isFullView, setFullView } =
    props;

  const [localRouteParams] = useState(routeParams);

  useYaHit({ condition: isFullView });

  const routeSteps: RouteShoulderProps[] = useMemo(
    () =>
      generateRouteStepsData(
        localRouteParams,
        isFullView,
        terminals,
        baseData,
        primaryLanguageId,
        route
      ),
    [isFullView, localRouteParams, terminals, baseData, primaryLanguageId, route]
  );

  useEffect(() => {
    setFullView(false);
  }, [route.routes]);

  return (
    <div className={'steps'}>
      {routeSteps.map((props, i) => (
        <RouteShoulder key={`key-${i}`} {...props} />
      ))}
    </div>
  );
};

export default RouteDetails;
