import { OrderProductLoaderInterface } from './interface';
import { OrderProductLoader } from './OrderProductLoader';
import { OrderProductLoaderProcessor } from './OrderProductLoaderProcessor';
import { PreOrderProductLoaderProcessor } from './PreOrderProductLoaderProcessor';

// Фабрика сервиса
export const orderProductLoader: { (token: string): OrderProductLoaderInterface } = (token) =>
  new OrderProductLoader(
    new OrderProductLoaderProcessor(token),
    new PreOrderProductLoaderProcessor()
  );
