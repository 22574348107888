import {OrderProduct} from "@services/requests/orderService/orderProductsLoader/interface";
import React, {FC} from "react";
import restoreServiceData from "@pages/AdditionalServices/containers/methods/basket/restoreServiceData";
import ServiceDirectory from "@pages/AdditionalServices/components/ServiceDirectory";
import getDirectoryFieldsFromData from "@pages/AdditionalServices/containers/methods/basket/getDirectoryFieldsFromData";
import {
  ServicesServicePriceCalculationMutationProps
} from "@services/requests/servicesService/servicesServicesService/interface";
import {ServiceFieldType} from "@onlog-public/additional-services-types";
import {OptionsCache} from "@pages/AdditionalServices/containers/services/optionsLoader/types";
import {v4} from "uuid";

export interface GroupServiceDetailsProps {
  product: OrderProduct
  langID: string
}

const GroupServiceDetails: FC<GroupServiceDetailsProps> = props => {
  const {product, langID} = props

  const restoreResult = restoreServiceData(product)
  if (!restoreResult) {
    return null
  }

  const {setUpService, otherServices} = restoreResult
  const variantServices = [setUpService, ...otherServices]
    .map(s => s.ServiceData)
    .filter(s => String(s.parent) === setUpService.Variant.id)

  const globalFields = getDirectoryFieldsFromData(setUpService)
  const servicesFields = [
    ...setUpService.Variant.fields,
    ...[setUpService, ...otherServices]
      .map(s => s.ServiceData.fields)
      .flat(1)
  ]

  const globalProperties = Object.values(setUpService.ServicesPath)
    .map(d => d.properties)
    .flat(1)
    .filter((d, i, data) => data.map(d => d.id).indexOf(d.id) === i)

  const serviceCalculations: ServicesServicePriceCalculationMutationProps[] = [setUpService, ...otherServices]
    .map(s => ({
      stateID: v4(),
      currencyId: String(s.service.currency_id),
      serviceId: String(s.service.service_id),
      isPriceByRequest: s.service.is_price_on_request,
      result: s.service.price_offer_result,
    }))

  const serviceDataCache: { [T in ServiceFieldType]: OptionsCache<any> } = {
    number: {isPartialLoading: false, cache: {}},
    switch: {isPartialLoading: false, cache: {}},
    hidden: {isPartialLoading: false, cache: {}},
    tax: {isPartialLoading: false, cache: {}},
    location: {isPartialLoading: false, cache: {}},
    handbook: {isPartialLoading: false, cache: {}},
    currency: {isPartialLoading: false, cache: {}},
    consulting_contractor: {isPartialLoading: false, cache: {}},
    inspection_contractor: {isPartialLoading: false, cache: {}},
    customs_contractor: {isPartialLoading: false, cache: {}},
    certification_contractor: {isPartialLoading: false, cache: {}},
    insurance_contractor: {isPartialLoading: false, cache: {}}
  }

  Array.from([setUpService, ...otherServices]).map(s => {
    Object.keys(s.ServiceDataCache).map((t: ServiceFieldType) => {
      Object.keys(s.ServiceDataCache[t]).map(id => serviceDataCache[t][id] = s.ServiceDataCache[t][id])
    })
  })

  let options = {...setUpService.GlobalFieldOptions}
  Array.from([setUpService, ...otherServices]).map(s => {
    options = {...options, ...s.ServiceFieldOptions}
  })

  return (
    <ServiceDirectory
      langID={langID}
      currencyID={String(setUpService.service.currency_id)}
      selectedProductUUID={v4()}
      isServicesCalculating={false}
      variant={setUpService.Variant}
      mainDirectory={setUpService.ServicesPath["0"]}
      isOrderDisplay={true}
      isOptionsLoading={false}
      globalFiles={Object.values(setUpService.ServicesPath).map(s => s.file_id).flat(1)}
      options={options}
      services={variantServices}
      globalFields={globalFields}
      servicesFields={servicesFields}
      globalFieldValues={setUpService.FieldValues}
      servicesFieldValues={setUpService.VariantFieldValues}
      serviceCalculations={serviceCalculations}
      serviceDataCache={serviceDataCache}
      serviceTemplateStore={setUpService.TemporaryStore}
      globalProperties={globalProperties}
      serviceProperties={setUpService.Variant.properties}
      propertyValues={setUpService.PropertyValues}
      propertyOptions={setUpService.PropertyOptions}
      propertyFilterAndOrder={{
        Data: {},
        IsSortByProperty: false,
        PropertyToSort: "",
        SortDirection: "asc",
        IsPriceFilterAvailable: false,
        PriceFilter: {
          Min: 0,
          Max: 0,
          CurrentMin: 0,
          CurrentMax: 0
        },
        IsSortChanged: true,
        ServicePriority: {},
        ServiceVisibility: {},
      }}
      onChangeGlobalFieldValue={() => null}
      onChangeServiceFieldValue={() => null}
      onChangeServiceTemplateStore={() => null}
      onAddToCart={() => null}
      onAddGroupToCart={() => null}
      onChangeFilterAndOrder={() => null}
    />
  )
}

export default GroupServiceDetails