import {useEffect, useState} from "react";
import {
  serviceFilterAndOrderContext$,
} from "@pages/AdditionalServices/containers/bus/index";
import onChangeFilterData from "@pages/AdditionalServices/containers/methods/onChangeFilterData";
import {bufferTime} from "rxjs";

/**
 * usePropertyFilterAndOrder реализует хук для подключения к стейту
 * данных для вывода фильтра по дополнительным услугам.
 *
 * Стейт фильтра наполняется после того, как загрузились данные услуг,
 * а так же данные опций для услуг. Данные по стоимости в фильтре обновляются
 * каждый раз, когда происходит пересчет стоимости услуг.
 */
const usePropertyFilterAndOrder = () => {
  const [filterAndOrderData, setState] = useState(serviceFilterAndOrderContext$.getValue())
  useEffect(() => {
    const subscription = serviceFilterAndOrderContext$.pipe(bufferTime(100)).subscribe({
      next: nState => {
        if (nState.length === 0) {
          return
        }

        setState(nState[nState.length - 1])
      },
    })

    return () => {
      try {
        subscription?.unsubscribe()
      } catch (e) {
      }
    }
  }, [])

  return {filterAndOrderData, onChangeFilterData}
}

export default usePropertyFilterAndOrder