import { GraphQLQuery } from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

import { OrderProduct } from '../orderProductsLoader/interface';

// Мутация на группировку товаров или услуг в один.
export class CreatePreOrderGroupMutation
  implements
    GraphQLQuery<{
      products: OrderProduct[];
    }>
{
  readonly query: any;

  readonly variables: {
    products: OrderProduct[];
  };

  constructor(products: OrderProduct[]) {
    this.variables = {
      products: products.map(({ id: _, ...otherProps }) => ({
        product_type: otherProps.product_type,
        price: otherProps.price,
        amount: otherProps.amount,
        data: otherProps.data,
        token: otherProps.token,
      })),
    };

    this.query = gql`
      mutation __CREATE_PRE_ORDER__GROUP__($products: [Pre_Order_Product_Complete_Data]) {
        data: groupMultipleProductsToSinglePosition(products: $products) {
          amount
          data
          id
          pre_order_id
          price
          product_type
          token
        }
      }
    `;
  }
}
