import { $error } from '@settings/errorContext';
import { graphQLClient } from '@settings/services/graphQLClient';
import { GraphQLClient } from '@settings/services/graphQLClient/GraphQLClient';

import { localizedMessagesService } from '../../localizedMessagesService';
import { LocalizedMessagesServiceInterfaces } from '../../localizedMessagesService/interfaces';
import { LoaderInterface } from '../interface';
import { UnitData, UnitLoaderQuery, UnitLoaderQueryResponse } from './UnitLoaderQuery';

/**
 * Загрузчик данных по единицам измерения
 */
export class UnitLoader implements LoaderInterface<UnitData> {
  private readonly client: GraphQLClient;

  private readonly localizationLoader: LocalizedMessagesServiceInterfaces;

  constructor(token?: string) {
    this.client = graphQLClient(token);
    this.localizationLoader = localizedMessagesService(token);
  }

  async Load(primaryKeys?: any[]): Promise<UnitData[]> {
    try {
      const resp = await this.client.Query<null, UnitLoaderQueryResponse>(
        new UnitLoaderQuery(primaryKeys),
        {}
      );

      // Формируем общий список локализаций (ID)
      const localizedMessageIds = resp.items.reduce(
        (result: string[], item: UnitData): string[] => [
          ...result,
          ...item.localized_names,
          ...item.localized_unit_symbols,
        ],
        []
      );

      // Загружаем все локализованные тексты
      const messages = await this.localizationLoader.GetMessagesArray(localizedMessageIds);
      resp.items.map((item) => {
        item.localized_names_data = messages.filter(
          (m) => item.localized_names.indexOf(m.id) !== -1
        );
        item.localized_unit_symbols_data = messages.filter(
          (m) => item.localized_unit_symbols.indexOf(m.id) !== -1
        );
      });

      return resp.items;
    } catch (e) {
      $error.next(e);
    }
  }
}
