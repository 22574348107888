import LocationOrTerminalPoint from '@components/pages/LocationOrTerminalPoint';
import { getLocalizationById } from '@context/useMyRouting/helpers';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TransportTypeIcon from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesListComponent/TransportTypeIcon';
import useBasket from '@pages/Basket/useBasket';
import { formatPrice } from '@pages/OfferDetail/helpers';
import { BranchItem } from '@services/requests/routeCalculator/locationsBranchSearchService/interfaces';
import { CurrencyData } from '@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery';
import React from 'react';
import { useTranslation } from 'react-i18next';

/** *
 * Пропсы компонента
 */
export type ShoulderItemHeaderProps = {
  transportType: string[]; // тип транспорта для перевозки
  deliveryTime: number; // Время доставки
  currency: CurrencyData;
  fullPrice: number;
  locations: BranchItem[];
};

/**
 * Основная инфа плеча маршрута
 */
const ShoulderItemHeader = (props: ShoulderItemHeaderProps) => {
  const { transportType, deliveryTime, currency, fullPrice, locations } = props;
  const [order] = useBasket().useOrder().useState();
  const langCode = getLocalizationById(order.language_id).countryCode;
  const { t } = useTranslation(['OfferDetail', 'Common'], { lng: langCode });

  /**
   * Получение название плеча в зависимости от его типа
   * @param type
   */
  const getShoulderNameByType = (type: string[]) => {
    return `${type
      .map((type) => {
        switch (type) {
          case '1':
            return t('OfferDetail:SeaTransport');
          case '2':
            return t('OfferDetail:RailwayTransport');
          case '3':
            return t('OfferDetail:AirTransport');
          case '4':
            return t('OfferDetail:AutomobileTransport');
          default:
            return '';
        }
      })
      .join(' - ')}: `;
  };

  return (
    <>
      <div className={'offer-group-header'}>
        <Box className={'offer-group-header__title'}>
          <div className="route-shoulder--icons-container">
            {transportType.map((transportType, idx) => (
              <TransportTypeIcon transportType={`${transportType}`} key={idx} />
            ))}
          </div>
          <Typography variant={'subtitle1'} component={'div'}>
            {getShoulderNameByType(transportType)}
            {locations
              .filter((l) => !!l)
              .map((locationPoint, idx) => (
                <React.Fragment key={idx}>
                  <LocationOrTerminalPoint
                    value={locationPoint}
                    branchItem={locationPoint}
                    order={order}
                  >
                    {(_primary, secondary, _symbolCode) => {
                      const [location, ...parents] = secondary;
                      const isTerminal =
                        locationPoint.type === 'terminal' ? ` (${t('Common:Terminal')})` : '';

                      return (
                        <>
                          {location}
                          {isTerminal}, {parents.join(', ')}
                        </>
                      );
                    }}
                  </LocationOrTerminalPoint>
                  {idx < locations.length - 1 && ' - '}
                </React.Fragment>
              ))}
          </Typography>
        </Box>
        <div className="offer-group-header__right">
          {t('OfferDetail:TransitTime')}: {deliveryTime}{' '}
          {deliveryTime > 1
            ? t('Common:DeliveryTimeDays')
            : t('Common:DeliveryTimeDay')}
        </div>
        <Typography variant={'subtitle1'} className={'price'} component={'div'}>
          {fullPrice > 0 && `${formatPrice(fullPrice).replace(',', '.')} ${currency.code}`}
          {fullPrice <= 0 && <>{t('OfferDetail:Included')}</>}
        </Typography>
      </div>
    </>
  );
};

export default ShoulderItemHeader;
