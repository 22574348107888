import {serviceContext$} from "@pages/AdditionalServices/containers/bus";
import {clone} from "@pages/AdditionalServices/containers/methods/clone";

/**
 * onChangeServiceTemplateStore выполняет изменение временного хранилища
 * данных для услуг.
 *
 * @param variant
 * @param service
 * @param store
 */
const onChangeServiceTemplateStore = (
  variant: string,
  service: string,
  store: any,
) => {
  const currentState = clone(serviceContext$.getValue())
  if (!currentState.ServiceTemplateStore[variant]) {
    return
  }

  currentState.ServiceTemplateStore[variant] = {
    ...currentState.ServiceTemplateStore[variant],
    [service]: store,
  }

  serviceContext$.next(currentState)
}

export default onChangeServiceTemplateStore