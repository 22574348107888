import {CurrencyData} from "@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery";
import {
  ServicesServicePriceCalculationMutationProps
} from "@services/requests/servicesService/servicesServicesService/interface";
import {Service} from "@onlog-public/additional-services-types";
import {VerticalTableTemplate} from "@onlog-public/additional-services-types/src/templates/VerticalTableTemplate";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";

export interface ServiceSummaryRowProps {
  course: string
  settings: VerticalTableTemplate
  columns: Service[]
  targetCurrency: CurrencyData
  totalCostColumn: number
  serviceCalculations: ServicesServicePriceCalculationMutationProps[]
}

const ServiceSummaryRow: FC<ServiceSummaryRowProps> = props => {
  const {
    course,
    serviceCalculations,
    settings,
    columns,
    targetCurrency,
    totalCostColumn,
  } = props
  const {t} = useTranslation()

  const serviceSummary: { [T in string]: number } = {}
  let totalCost = 0
  let totalTax = 0
  let totalSum = 0

  columns.map(column => {
    serviceCalculations
      .filter(calc => calc.serviceId === column.id)
      .map(calc => {
        if (!serviceSummary[column.id]) {
          serviceSummary[column.id] = 0
        }

        serviceSummary[column.id] += calc.result.result.priceInTargetCurrency
        totalCost += calc.result.result.priceInTargetCurrency
        totalTax += calc.result.result.taxInTargetCurrency
        totalSum += calc.result.result.fullPriceInTargetCurrency
      })
  })

  const currency = targetCurrency.glyph.length ? targetCurrency.glyph : ` ` + targetCurrency.code

  return <>
    <div className="with-bold-text with-left-padding no-left-border">
      {t(settings.PositionSummaryText)}
    </div>
    <div className="with-bold-text center">{totalCostColumn.toLocaleString()}</div>
    {!settings.IsCourseColumnHidden && (
      <div className="with-bold-text center">{course}</div>
    )}
    {columns.map(column => (
      <div key={column.id} className="with-bold-text center">
        {(serviceSummary[column.id] ?? 0).toLocaleString()}{currency}
      </div>
    ))}
    <div className="with-bold-text center">
      {totalCost.toLocaleString()}{currency}
    </div>
    <div className="with-bold-text center">
      {totalTax.toLocaleString()}{currency}
    </div>
    <div className="with-bold-text center">
      {totalSum.toLocaleString()}{currency}
    </div>
  </>
}

export default ServiceSummaryRow