import Typography from '@mui/material/Typography';
import { OrderProduct } from '@services/requests/orderService/orderProductsLoader/interface';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCustomsPayments } from '../../context';
import { CustomsPaymentProduct } from '../../context/types';
import { CustomsPaymentsDetailingMain } from '../CustomsPaymentsDetailing/CustomsPaymentsDetailingMain';
import { CustomsPaymentsDetailingOverall } from '../CustomsPaymentsDetailing/CustomsPaymentsDetailingOverall';
import { CustomsPaymentsDetailingResult } from '../CustomsPaymentsDetailing/CustomsPaymentsDetailingResult';
import { CustomsPaymentsDetailingTotal } from '../CustomsPaymentsDetailing/CustomsPaymentsDetailingTotal';
import { CustomsPaymentsDetailingShortStyledContainer } from './StyledComponents';

interface CustomsPaymentsDetailingShortProps {
  product: OrderProduct;
}

function CustomsPaymentsDetailingShort({ product }: CustomsPaymentsDetailingShortProps) {
  const { t } = useTranslation(['CustomsPayments']);
  const { useBaseCustomsPaymentData } = useCustomsPayments();
  const [{ countries, currencies }] = useBaseCustomsPaymentData().useState();
  const data = JSON.parse(product.data);
  const customsPaymentsProducts: CustomsPaymentProduct[] = Array.isArray(data) ? data : [data];
  const CUSTOMS_MODES = [
    {
      value: '1',
      label: t('CustomsPayments:Import'),
    },
    {
      value: '2',
      label: t('CustomsPayments:Export'),
    },
  ];

  const { borderDelivery, customsMode, otherExpenses, contractCurrency } =
    customsPaymentsProducts[0];

  const formattedCustomsMode =
    customsMode === '1' ? CUSTOMS_MODES[0].label : CUSTOMS_MODES[1].label;

  return (
    <CustomsPaymentsDetailingShortStyledContainer>
      <Typography variant={'h2'} color={'common.black'} className={'section-title'}>
        {t('CustomsPayments:CustomsPayments')}
      </Typography>
      <CustomsPaymentsDetailingOverall
        customsRegime={formattedCustomsMode}
        contractCurrency={contractCurrency?.encode}
        borderDelivery={borderDelivery}
        otherExpenses={otherExpenses}
      />
      {customsPaymentsProducts.map((product, index) => (
        <React.Fragment key={'product-detailing-' + index}>
          <CustomsPaymentsDetailingMain
            product={product}
            index={index}
            currencies={currencies}
            countries={countries}
            contractCurrency={product.contractCurrency}
            isExport={customsMode === '2'}
          />
          <CustomsPaymentsDetailingResult
            isExport={customsMode === '2'}
            isSpecialFieldsVisible={true}
            product={product}
            borderDelivery={borderDelivery}
            otherExpenses={otherExpenses}
            currencies={currencies}
            contractCurrency={contractCurrency}
          />
        </React.Fragment>
      ))}
      <CustomsPaymentsDetailingTotal products={customsPaymentsProducts} />
    </CustomsPaymentsDetailingShortStyledContainer>
  );
}

export { CustomsPaymentsDetailingShort };
