import { OrderProductType, ServiceProductType } from '@services/requests/orderService/interface';
import { OrderProduct } from '@services/requests/orderService/orderProductsLoader/interface';
import { TFunction } from 'i18next';

/**
 * Получение названия группы товаров
 * @param type
 * @return string
 */
export const getProductNameByType = (type: OrderProductType): string => {
  switch (type) {
    case 'route':
      return 'CartComponent:Delivery';
  }
};

// ToDo
//  Создать единый способ группировки доп. услуг в корзине и детализации.
//  Вывести для них заголовок.
//  Прописать типизацию объекта с группой доп услуг из одной секции

export const getSectionServiceName = (
  products: OrderProduct[],
  t: TFunction<string[], undefined>,
  langId: string
) => {
  let sectionName = t(getProductNameByType(products[0].product_type));
  if (products[0].product_type !== 'route') {
    const serviceSection = JSON.parse(products[0].data);
    const serviceType: ServiceProductType = Array.isArray(serviceSection)
      ? serviceSection[0].type
      : serviceSection.type;

    if (serviceType === 'customs-payments') {
      sectionName = t('CustomsPayments:CustomsPayments');
    } else {
      const serviceSectionNames = Array.isArray(serviceSection)
        ? JSON.parse(serviceSection[0].data).sectionName
        : serviceSection.sectionName;

      sectionName =
        serviceSectionNames.localized_names.find((m) => m.lang_id === langId)?.message ||
        serviceSectionNames.name;
    }
  }
  return sectionName;
};
