import MCheckbox from '@assets/mui/@material-extend/MCheckbox';
import { FormControlLabel } from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Container, { ShowHideAll } from './StyledComponents';

// Свойства компонента
export interface CheckboxGridProps<T extends number | string> {
  isHiddenAvailable?: boolean;
  hiddenCheckboxQuantity?: number;
  disabled?: boolean;
  isCheckAllAvailable?: boolean;
  items: { value: T; title: React.ReactNode; description?: React.ReactNode; tooltip?: string }[];
  values: T[];
  onChange: (values: T[]) => void;
}

const CheckboxGrid = <T extends number | string>({
  isHiddenAvailable = true,
  hiddenCheckboxQuantity = 3,
  disabled = false,
  isCheckAllAvailable = false,
  items,
  values,
  onChange,
}: CheckboxGridProps<T>) => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation('Actions');
  const handleChangeValue = (item: { value: T; title: React.ReactNode }, state: boolean) => {
    if (!state) {
      handleUncheckValue(item);
      return;
    }
    handleCheckValue(item);
  };

  const handleCheckValue = (item: { value: T; title: React.ReactNode }) => {
    onChange([...values, item.value]);
  };

  const handleUncheckValue = (item: { value: T; title: React.ReactNode }) => {
    onChange(values.filter((v) => v !== item.value));
  };

  const handleToggleSelectAll = (isAllItemsChecked: boolean) => {
    const newValues = isAllItemsChecked ? [] : items.map((v) => v.value);
    onChange(newValues);
  };

  const checkedItems = values;
  const allItems = items.map((v) => v.value);
  const isAllItemsChecked = !allItems.find((i) => !checkedItems.includes(i));

  const isButtonVisible = isHiddenAvailable && items.length > hiddenCheckboxQuantity;

  return (
    <>
      <Container>
        {isCheckAllAvailable && (
          <div className={'choose-all'} onClick={() => handleToggleSelectAll(isAllItemsChecked)}>
            {isAllItemsChecked ? t('Actions:DisableAll') : t('Actions:EnableAll')}
          </div>
        )}
        {items
          .slice(0, isHiddenAvailable && !isVisible ? hiddenCheckboxQuantity : items.length)
          .map((item) => {
            const checked = values.includes(item.value);
            return (
              <FormControlLabel
                key={`checkbox-grid--${item.value}`}
                control={
                  <MCheckbox
                    size="small"
                    checked={checked}
                    onChange={() => handleChangeValue(item, !checked)}
                    sx={{
                      padding: '0 5px 0 0',
                      borderColor: '#CFD5E2',
                    }}
                  />
                }
                disabled={disabled}
                label={
                  <>
                    <Tooltip title={item.tooltip}>
                      <span>{item.title}</span>
                    </Tooltip>
                    {item.description && (
                      <>
                        <br />
                        <span className="MuiCheckbox--description">{item.description}</span>
                      </>
                    )}
                  </>
                }
                sx={{
                  '& .MuiTypography-root': {
                    fontSize: 12,
                    fontWeight: 500,
                    color: '#22262D',
                  },
                  '& .MuiCheckbox--description': {
                    fontSize: 10,
                    fontWeight: 500,
                    color: '#6C7484',
                  },
                }}
              />
            );
          })}
      </Container>
      {isButtonVisible && (
        <ShowHideAll onClick={() => setIsVisible(!isVisible)}>
          {isVisible ? t('Actions:Collapse') : t('Actions:ShowAll')}
        </ShowHideAll>
      )}
    </>
  );
};

export default CheckboxGrid;
