import React, { FC, ReactNode } from 'react';

import ShoulderItemTableBody, {
  AdditionalServicesTableBodyProps,
} from './components/ShoulderItemTableBody';
import ShoulderItemTableStyledContainer from './StyledComponents';

interface AdditionalServicesTableProps extends AdditionalServicesTableBodyProps {
  header: ReactNode;
  variant?: 'default';
}

const ShoulderItemTable: FC<AdditionalServicesTableProps> = (props) => {
  const { header, rows, columns, variant } = props;

  return (
    <>
      <ShoulderItemTableStyledContainer className={variant}>
        {header}
        <ShoulderItemTableBody rows={rows} columns={columns} variant={variant} />
      </ShoulderItemTableStyledContainer>
    </>
  );
};

export default ShoulderItemTable;
