import MButton from '@assets/mui/@material-extend/MButton';
import palette from '@assets/theme/palette';
import CloseIcon from '@components/icons/Close';
import CardWrapper from '@components/UI/CardWrapper';
import { useBitrix } from '@context/useBitrixUser/useBitrix';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CartUnauthorizedComponentStyledContainer } from './StyledComponent';

function UnauthorizedPlaceholder() {
  const { t } = useTranslation(['CartComponent', 'PageNames', 'Common']);

  const { showLogin } = useBitrix().actions;

  return (
    <CartUnauthorizedComponentStyledContainer component={'section'}>
      <CardWrapper className="services">
        <Typography variant={'h2'} color={'common.black'} className={'section-title'}>
          {t('CartComponent:SelectedServicesQuestion')}
        </Typography>
        <Typography variant={'body1'} color={'common.black'} className={'section-info'}>
          {t('CartComponent:SelectedServicesUnautorizedAction')}
        </Typography>
        <MButton
          className={'login-button '}
          onClick={showLogin}
          fullWidth={true}
          variant={'outlined'}
          startIcon={<CloseIcon />}
          size={'large'}
          color={'inherit'}
          sx={{
            color: palette.light.blue[400],
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          <span> {t('CartComponent:LoginOrSignIn')}</span>
        </MButton>
      </CardWrapper>
    </CartUnauthorizedComponentStyledContainer>
  );
}

export { UnauthorizedPlaceholder };
