import {RouteResult} from "@services/requests/orderService/types";
import {OrderProduct} from "@services/requests/orderService/orderProductsLoader/interface";
import getRouteSummary from "@components/pages/Products/components/RouteProduct/getRouteSummary";
import getSummaryForServices, {
  ServiceSummary
} from "@pages/AdditionalServices/containers/methods/basket/getSummaryForServices";

/**
 * getTotalSummary возвращает суммарную информацию о стоимости заказа и
 * об издержках. Собирает валютные расходы.
 * @param products
 * @param targetCurrency
 */
const getTotalSummary = (
  products: OrderProduct[],
  targetCurrency: string,
) => {
  const routes = products.filter(p => p.product_type === "route")
  const services = products.filter(p => p.product_type !== "route")
  const summary: ServiceSummary[] = [getSummaryForServices(services)]

  routes.map(r => JSON.parse(r.data) as RouteResult)
    .map(route => {
      summary.push(getRouteSummary(route.route.routes, route.baseData.currencies, targetCurrency))
    })

  return summary.reduce(
    (result, summary) => {
      Object.keys(summary.Currencies).map(currency => {
        if (!result.Currencies[currency]) {
          result.Currencies[currency] = 0
        }

        result.Currencies[currency] += summary.Currencies[currency]
      })

      result.Conversion += summary.Conversion
      result.Tax += summary.Tax
      result.Total += summary.Total
      result.Price += summary.Price

      return result
    },
    {
      Currencies: {},
      Price: 0,
      Conversion: 0,
      Tax: 0,
      Total: 0,
      TargetCurrency: targetCurrency,
    } as ServiceSummary
  )
}

export default getTotalSummary