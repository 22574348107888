import { authService } from '@services/AuthService';
import { BitrixUser } from '@services/AuthService/loadUserQuery';
import { useStateSubscriber } from '@settings/core/stateContexts/useContextSubscriber';
import getEnv from '@settings/getEnv';
import { checkEnvAvailability } from '@settings/getEnv/helpers';
import Cookies from 'cookies-ts';
import { BehaviorSubject } from 'rxjs';

import { createDefaultUser } from './DefaultUser';

const { REACT_APP_BITRIX_USER_IS_REQUIRED } = getEnv();

/**
 * Контекст bitrix пользователя
 */
export interface BitrixUserContext {
  /**
   * Флаг загрузки пользователя.
   * По умолчанию - true, по окончанию инциализации
   * уставливается в false
   */
  isUserLoading: boolean;
  /**
   * Флаг наличия авторизаванного пользователя.
   */
  isAuthorized: boolean;
  /**
   * Данные загруженного или дефолтного пользователя
   */
  bitrixUser: BitrixUser | null;
}

export const $bitrixUserContext = new BehaviorSubject<BitrixUserContext>({
  isUserLoading: true,
  isAuthorized: false,
  bitrixUser: null,
});

/**
 * Получить дефолтного пользователя bitrix.
 * Если функционал авторизаци включен - null,
 * если выключен - дефолтный пользователь с
 * предзаполненными данными.
 */
export const getBitrixUserOrDefault = () => {
  const isBitrixUserRequired = checkEnvAvailability(REACT_APP_BITRIX_USER_IS_REQUIRED);
  const defaultUser = createDefaultUser();

  return isBitrixUserRequired ? null : defaultUser;
};

/**
 * Получить id пользователя.
 * Если авторизация включена и id не пустой - id полученного пользователя
 * Если авторизация включана и id пустой - null
 * Если авторизация выключена и id не пустой - id полученного пользователя
 * Если авторизация выключена и id пустой - id дефолтного пользователя
 * @param userId
 */
export const getUserId = (): string | null => {
  const { bitrixUser, isUserLoading } = $bitrixUserContext.getValue();
  const userId = bitrixUser?.id;
  const defaultUser = createDefaultUser();

  const isBitrixUserRequired = checkEnvAvailability(REACT_APP_BITRIX_USER_IS_REQUIRED);
  const isUserIdEmpty = !userId || userId?.length === 0;

  if (isUserLoading) {
    return null;
  }

  if (isBitrixUserRequired) {
    return isUserIdEmpty ? null : userId;
  }

  if (!isBitrixUserRequired) {
    return isUserIdEmpty ? defaultUser.id : userId;
  }
};

/**
 * Функция открытия окна логина на встраиваемом сайте
 */
const showLogin = () => {
  window.dispatchEvent(new CustomEvent('openAuth'));

  const authLink = document.getElementById('auth_link');
  if (!authLink) {
    return;
  }

  authLink.click();
};

/**
 * Инициализация контекста bitrix пользователя.
 * При наличии токена происходит запрос текущего пользователя и инициализация контекста.
 * Если токена нет и авторизация выключена - инциализация дефолтным пользователем.
 * Если токена нет и авторизация включена - оставляем null.
 */
const init = async () => {
  const cookies = new Cookies();
  const env = getEnv();
  const jwt = cookies.get(env.REACT_APP_AUTH_COOKIE);

  let user = getBitrixUserOrDefault();
  let isAuthorized = !!user;

  try {
    if (jwt && jwt.length > 0) {
      user = await authService().LoadUser(jwt);
      isAuthorized = !!user;
    }
  } catch (error) {
    console.error(error?.message);
  } finally {
    $bitrixUserContext.next({
      ...$bitrixUserContext.getValue(),
      bitrixUser: user,
      isUserLoading: false,
      isAuthorized,
    });
  }
};

const actions = {
  init,
  getBitrixUserOrDefault,
  getUserId,
  showLogin,
};

export const useBitrix = () => {
  const useBitrixUser = () => useStateSubscriber<BitrixUserContext>($bitrixUserContext);

  return {
    useBitrixUser,
    actions,
  };
};
