import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloClient, ApolloQueryResult } from 'apollo-client';
import { FetchResult } from 'apollo-link';

import { Collection } from '../types';
import { ERROR_KEYS_TO_HANDLE } from './consts';
import { GraphQLQuery, RequestGraphQLClient } from './GraphQLClient';

/**
 * Реализация клиента GraphQL сервера на основе Apollo
 */
export class RequestClient implements RequestGraphQLClient {
  private readonly defaultHeaders: Collection<string>;

  private readonly client: ApolloClient<NormalizedCacheObject>;

  /**
   * Конструктор клиента
   *
   * @param loggerFactory
   * @param client
   * @param defaultHeaders
   */
  constructor(
    client: ApolloClient<NormalizedCacheObject>,
    defaultHeaders: Collection<string> = {}
  ) {
    this.client = client;
    this.defaultHeaders = defaultHeaders;
  }

  /**
   * Выполнение запроса к серверу
   *
   * @param query
   * @param headers
   * @param currentTry
   */
  async Query<V, Response>(query: GraphQLQuery<V>, headers: Collection<string>): Promise<Response> {
    const response = await this.client.query<Response, V>({
      query: query.query,
      variables: query.variables,
      context: {
        headers: {
          ...this.defaultHeaders,
          ...headers,
        },
      },
    });

    const errorKey = ERROR_KEYS_TO_HANDLE.find((key) => {
      return response.errors?.find((error) => error.message.includes(key));
    });

    const error = response.errors?.find((error) => error.message.includes(errorKey));

    if (!!error) {
      const requestCount = +error.message.split('::').at(-1);
      return { data: { errorType: errorKey, requestCount } } as Response;
    }

    if (response.errors !== undefined) {
      const error = { errors: response.errors };
      throw error;
    }

    return response.data;
  }

  /**
   * Выполнение запроса обновления к серверу
   *
   * @param query
   * @param headers
   */
  async Mutation<V, Response>(
    query: GraphQLQuery<V>,
    headers: Collection<string>
  ): Promise<Response> {
    const response = await this.client.mutate<Response, V>({
      mutation: query.query,
      variables: query.variables,
      context: {
        headers: {
          ...this.defaultHeaders,
          ...headers,
        },
      },
    });

    if (response.errors !== undefined) {
      throw new Error('GraphQL request failed');
    }

    if (!response.data) {
      throw new Error('Undefined data returns by GraphQL request');
    }

    return response.data;
  }
}
