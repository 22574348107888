import {
  ServicesServicePriceCalculationMutationProps
} from "@services/requests/servicesService/servicesServicesService/interface";

/**
 * getServiceTotalPrice вычисляет полную стоимость услуг
 * @param services
 */
const getServiceTotalTax = (
  services: ServicesServicePriceCalculationMutationProps[],
): number => {
  const isServiceWithPriceExists = !!services.find(
    p => p.result.result.fullPriceInTargetCurrency > -1
  )

  if (!isServiceWithPriceExists) {
    return -1
  }

  return services.reduce(
    (v, p) => p.result.result.taxInTargetCurrency > 0
      ? v + p.result.result.taxInTargetCurrency
      : v,
    0,
  )
}

export default getServiceTotalTax