import MCheckbox from '@assets/mui/@material-extend/MCheckbox';
import MRadio from '@assets/mui/@material-extend/MRadio';
import { BitrixDisableUserGuard } from '@components/guards/bitrixUserGuard/BitrixUserGuard';
import CheckboxGrid from '@components/UI/CheckboxGrid';
import CheckboxContainer from '@components/UI/CheckboxGrid/StyledComponents';
import HiddenContent from '@components/UI/HiddenContent';
import RangeSlider from '@components/UI/RangeSlider';
import { YaTargetWrapper } from '@components/YaTargetWrapper/YaTargetWrapper';
import { useYaHit } from '@context/useYaHit';
import { FormControlLabel, RadioGroup } from '@material-ui/core';
import TerminalsFilter from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesListComponent/DeliveryFilterContainer/TerminalsFilter';
import useDelivery from '@pages/AdditionalServices/tabs/DeliveryForm/useDelivery';
import { BranchItem } from '@services/requests/routeCalculator/locationsBranchSearchService/interfaces';
import { FilterData } from '@services/requests/routeCalculator/routesCalculationService/interfaces';
import { AggregationData } from '@services/requests/routeCalculator/routesCalculationService/types';
import { localizedMessagesLoader } from '@services/requests/searchLoaders/localizedMessagesLoader';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CargoType, RouteWidgetBaseParameters, RouteWidgetParams } from '../../../../types';
import Container from './StyledComponents';

// Свойства компонента
export interface RoutesListFilterProps {
  baseData: RouteWidgetBaseParameters | undefined;
  filterParameters: FilterData;
  baseFilterData: AggregationData | undefined;
  mainLanguage: string; // Текущий основной язык
  systemLanguage: string; // Системный язык
  terminals: BranchItem[]; // Терминалы, которые загружены для расчета
  startTerminals: BranchItem[]; // Терминалы, которые загружены для расчета
  endTerminals: BranchItem[]; // Терминалы, которые загружены для расчета
  routeParams: RouteWidgetParams | undefined;

  onChangeFilterData(filter: FilterData): void;

  resetAll(): void;
}

const RoutesListFilter: React.FC<RoutesListFilterProps> = (props) => {
  const {
    baseData,
    routeParams,
    baseFilterData,
    resetAll,
    filterParameters,
    terminals,
    startTerminals,
    endTerminals,
    onChangeFilterData,
    mainLanguage,
    systemLanguage,
  } = props;

  const { t: translate } = useTranslation(['RoutesListComponent', 'Common', 'FilterComponent']);
  const [isSingleShoulderRoutesOnly, setIsSingleShoulderRoutesOnly] = useState<boolean>(
    filterParameters.isSingleShoulderRoutesOnly
  );
  const [cargoType] = useState<CargoType>(routeParams.cargoType);
  const [isContainerRentAvailable, setIsContainerRentAvailable] = useState<boolean>(
    !!routeParams?.isNeedContainerRent
  );
  const [isPrecarageOptionsAvailable] = useState(routeParams.isNeedPrekeridge);
  const [cacheFilterParameters] = useDelivery().useCacheFilterParameters().useState();
  const [settings] = useDelivery().useSettings().useState();

  const { primaryLanguageId } = settings;

  const [localizedTransportingTypes, setLocalizedTransportingTypes] = useState<
    { value: number; title: string }[]
  >([]);

  useYaHit({});

  const getLocalizedMessages = useCallback(async (messageIds: string[]) => {
    const result = await localizedMessagesLoader().Load(messageIds);

    return result.filter((m) => m.lang_id === primaryLanguageId);
  }, []);

  useEffect(() => {
    const fetchLocalizedTransportingTypes = async () => {
      const result = await getLocalizedMessages(
        baseData.shoulderTypes.map((t) => t.localized_names).flat()
      );

      const filteredTransportingTypes = baseData.shoulderTypes.filter(
        (t) => baseFilterData.transportingTypes.indexOf(parseInt(t.id)) !== -1
      );

      const localizedTransportingTypes = filteredTransportingTypes.map((type) => {
        const foundLocalizedName = result.find((localization) =>
          type.localized_names.includes(localization.id)
        );
        return {
          ...type,
          default_name: foundLocalizedName ? foundLocalizedName.message : type.default_name,
        };
      });

      setLocalizedTransportingTypes(
        localizedTransportingTypes.map((t) => ({
          value: parseInt(t.id),
          title: t.default_name,
        }))
      );
    };

    fetchLocalizedTransportingTypes();
  }, []);

  useEffect(() => {
    setIsSingleShoulderRoutesOnly(filterParameters.isSingleShoulderRoutesOnly);
  }, [filterParameters.isSingleShoulderRoutesOnly]);

  useEffect(() => {
    setIsContainerRentAvailable(!!routeParams?.isNeedContainerRent);
  }, [routeParams?.isNeedContainerRent]);

  const handleChangeFilterValue = <T extends keyof FilterData>(key: T, value: FilterData[T]) => {
    const data = { ...filterParameters };
    data[key] = value;
    onChangeFilterData(data);
  };

  const numberWithSpaces = (x: number) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  if (!baseData || !baseFilterData) {
    return null;
  }

  const containerRentType = baseFilterData.containerRentType.map((t) => ({
    value: t,
    title:
      t === 'COC'
        ? translate('RoutesListComponent:OwnedByTheCarrier')
        : translate('RoutesListComponent:ContainerRental'),
    description:
      t === 'COC'
        ? translate('RoutesListComponent:ProvidedCarrier')
        : translate('RoutesListComponent:RentedSeparately'),
  }));

  const carriers = baseData.carriers
    .filter((c) => baseFilterData.carriers.indexOf(parseInt(c.id)) !== -1)
    .map((c) => ({
      value: parseInt(c.id),
      title: c.default_name,
    }));

  const isNeedPriceFilter = baseFilterData.price.max !== baseFilterData.price.min;
  const isNeedDeliveryTimeFilter =
    baseFilterData.deliveryTime.max !== baseFilterData.deliveryTime.min;

  const currency = baseData.currencies.find((c) => c.id === settings.currencyId);
  return (
    <YaTargetWrapper target="calculator_sorting" noAuthTarget="calculator_sorting_noreg">
      <BitrixDisableUserGuard>
        <Container>
          <div className={'title'}>{translate('RoutesListComponent:SearchOptions')}</div>
          {isNeedPriceFilter && (
            <HiddenContent
              title={translate('RoutesListComponent:ShippingCost')}
              defaultVisibility
              onReset={() => handleChangeFilterValue('price', cacheFilterParameters.price)}
            >
              <RangeSlider
                ranges={baseFilterData.price}
                value={filterParameters.price}
                onChange={(value) => handleChangeFilterValue('price', value)}
                tooltipGenerator={(value, isToValue) =>
                  `${
                    !isToValue
                      ? translate('RoutesListComponent:From')
                      : translate('RoutesListComponent:To')
                  } ${numberWithSpaces(value)} ${currency && currency.glyph}`
                }
              />
            </HiddenContent>
          )}
          {isNeedDeliveryTimeFilter && (
            <HiddenContent
              title={translate('RoutesListComponent:TravelTime')}
              defaultVisibility
              onReset={() =>
                handleChangeFilterValue('deliveryTime', cacheFilterParameters.deliveryTime)
              }
            >
              <RangeSlider
                ranges={baseFilterData.deliveryTime}
                value={filterParameters.deliveryTime}
                onChange={(value) => handleChangeFilterValue('deliveryTime', value)}
                tooltipGenerator={(value, isToValue) =>
                  `${
                    !isToValue
                      ? translate('RoutesListComponent:From')
                      : translate('RoutesListComponent:To')
                  } ${numberWithSpaces(value)} ${
                    value === 1 ? translate('Common:Day') : translate('Common:Days')
                  }`
                }
              />
            </HiddenContent>
          )}
          {localizedTransportingTypes.length > 0 && (
            <HiddenContent
              title={translate('RoutesListComponent:TypeTransportation')}
              defaultVisibility
              onReset={() =>
                handleChangeFilterValue(
                  'transportingTypes',
                  cacheFilterParameters.transportingTypes
                )
              }
            >
              <CheckboxGrid
                items={localizedTransportingTypes}
                values={filterParameters.transportingTypes}
                onChange={(values) => handleChangeFilterValue('transportingTypes', values)}
              />
            </HiddenContent>
          )}
          {isPrecarageOptionsAvailable && (
            <HiddenContent
              title={translate('RoutesListComponent:PrecarageOptions')}
              defaultVisibility
              onReset={() => {
                handleChangeFilterValue(
                  'isNeedPrekeridgeRoutes',
                  cacheFilterParameters.isNeedPrekeridgeRoutes
                );
                handleChangeFilterValue(
                  'isNeedRegularRoutes',
                  cacheFilterParameters.isNeedRegularRoutes
                );
              }}
            >
              <CheckboxContainer>
                <FormControlLabel
                  control={
                    <MCheckbox
                      size="small"
                      checked={filterParameters.isNeedRegularRoutes}
                      onChange={() =>
                        handleChangeFilterValue(
                          'isNeedRegularRoutes',
                          !filterParameters.isNeedRegularRoutes
                        )
                      }
                      sx={{
                        padding: '0 5px 0 0',
                        borderColor: '#CFD5E2',
                      }}
                    />
                  }
                  //   disabled={disabled}
                  label={<span>{translate('RoutesListComponent:IsNeedRegularRoutes')}</span>}
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: 12,
                      fontWeight: 500,
                      color: '#22262D',
                    },
                    '& .MuiCheckbox--description': {
                      fontSize: 10,
                      fontWeight: 500,
                      color: '#6C7484',
                    },
                  }}
                />
                <FormControlLabel
                  control={
                    <MCheckbox
                      size="small"
                      checked={filterParameters.isNeedPrekeridgeRoutes}
                      onChange={() =>
                        handleChangeFilterValue(
                          'isNeedPrekeridgeRoutes',
                          !filterParameters.isNeedPrekeridgeRoutes
                        )
                      }
                      sx={{
                        padding: '0 5px 0 0',
                        borderColor: '#CFD5E2',
                      }}
                    />
                  }
                  //   disabled={disabled}
                  label={<span>{translate('RoutesListComponent:IsNeedPrekeridgeRoutes')}</span>}
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: 12,
                      fontWeight: 500,
                      color: '#22262D',
                    },
                    '& .MuiCheckbox--description': {
                      fontSize: 10,
                      fontWeight: 500,
                      color: '#6C7484',
                    },
                  }}
                />
              </CheckboxContainer>
            </HiddenContent>
          )}
          {carriers.length > 0 && (
            <HiddenContent
              title={translate('RoutesListComponent:Transporter')}
              defaultVisibility
              onReset={() => handleChangeFilterValue('carriers', cacheFilterParameters.carriers)}
            >
              <CheckboxGrid
                items={carriers}
                isCheckAllAvailable={true}
                values={filterParameters.carriers}
                isHiddenAvailable
                hiddenCheckboxQuantity={3}
                onChange={(values) => handleChangeFilterValue('carriers', values)}
              />
            </HiddenContent>
          )}
          {startTerminals.filter((t) => t.type === 'terminal').length > 1 && (
            <TerminalsFilter
              terminals={startTerminals}
              handleChangeFilterValue={handleChangeFilterValue}
              values={filterParameters.startTerminals}
              systemLanguage={systemLanguage}
              mainLanguage={mainLanguage}
              cachedTerminals={cacheFilterParameters.startTerminals}
              name={translate('Common:DepartureTerminals')}
              filterKey={'startTerminals'}
            />
          )}
          {terminals.filter((t) => t.type === 'terminal').length > 1 && (
            <TerminalsFilter
              terminals={terminals}
              handleChangeFilterValue={handleChangeFilterValue}
              values={filterParameters.terminals}
              systemLanguage={systemLanguage}
              mainLanguage={mainLanguage}
              cachedTerminals={cacheFilterParameters.terminals}
              name={translate('Common:TransitTerminals')}
              filterKey={'terminals'}
            />
          )}
          {endTerminals.filter((t) => t.type === 'terminal').length > 1 && (
            <TerminalsFilter
              terminals={endTerminals}
              handleChangeFilterValue={handleChangeFilterValue}
              values={filterParameters.endTerminals}
              systemLanguage={systemLanguage}
              mainLanguage={mainLanguage}
              cachedTerminals={cacheFilterParameters.endTerminals}
              name={translate('Common:DestinationTerminals')}
              filterKey={'endTerminals'}
            />
          )}
          <HiddenContent
            title={translate('RoutesListComponent:CargoReloading')}
            defaultVisibility
            onReset={() =>
              handleChangeFilterValue(
                'isSingleShoulderRoutesOnly',
                cacheFilterParameters.isSingleShoulderRoutesOnly
              )
            }
          >
            <RadioGroup
              aria-label={translate('RoutesListComponent:CargoReloading')}
              name={'in-route-move'}
            >
              <FormControlLabel
                control={
                  <MRadio
                    size={'small'}
                    className={'radio'}
                    checked={!isSingleShoulderRoutesOnly}
                    onChange={() => handleChangeFilterValue('isSingleShoulderRoutesOnly', false)}
                    sx={{
                      padding: '5px 8px',
                    }}
                  />
                }
                classes={{
                  label: 'radio-label',
                }}
                label={translate('RoutesListComponent:DoesntMatter')}
              />
              <FormControlLabel
                control={
                  <MRadio
                    size={'small'}
                    className={'radio'}
                    checked={isSingleShoulderRoutesOnly}
                    onChange={() => handleChangeFilterValue('isSingleShoulderRoutesOnly', true)}
                    sx={{
                      padding: '5px 8px',
                    }}
                  />
                }
                classes={{
                  label: 'radio-label',
                }}
                label={translate('RoutesListComponent:WithoutLoadingUnloading')}
              />
            </RadioGroup>
          </HiddenContent>
          {containerRentType.length > 0 && cargoType === 'container' && (
            <HiddenContent
              title={translate('RoutesListComponent:ContainerOwnership')}
              defaultVisibility
              onReset={() =>
                handleChangeFilterValue(
                  'containerRentType',
                  cacheFilterParameters.containerRentType
                )
              }
            >
              <CheckboxGrid
                items={containerRentType}
                disabled={!isContainerRentAvailable}
                values={filterParameters.containerRentType}
                onChange={(values) => handleChangeFilterValue('containerRentType', values)}
              />
            </HiddenContent>
          )}
          <div className={'reset-all'} onClick={resetAll}>
            {translate('FilterComponent:ResetAllFilters')}
          </div>
        </Container>
      </BitrixDisableUserGuard>
    </YaTargetWrapper>
  );
};

export default RoutesListFilter;
