import { OrderProduct } from '@services/requests/orderService/orderProductsLoader/interface';
import { Route, RouteResult, RouteStep } from '@services/requests/orderService/types';
import dayjs from 'dayjs';

/**
 * Получение даты действительности расчета по продуктам
 * @param products
 */
export const getValidityCalcDate = (products: OrderProduct[], localization: string) => {
  if (!products.length) {
    return '';
  }

  const productsData: RouteResult[] = products.map((product) => JSON.parse(product.data));

  const dates = productsData
    .filter((p) => !!p.route)
    .map((p) => p.route.routes)
    .flat()
    .map((r) => r.route.steps)
    .reduce((acc, steps) => [...acc, ...steps], [])
    .map((s) => s.shoulderOffer.active_to)
    .map((ds) => new Date(ds).getTime());
  const nearestDate = Math.min(...dates);

  if (dates.length === 0) {
    return '';
  }

  let dateFormat = 'D MMMM YYYY ';

  if (localization === 'en') {
    dateFormat = 'MMMM D, YYYY ';
  }

  return dayjs(nearestDate)
    .locale(localization)
    .format(dateFormat + (localization === 'ru' ? 'г.' : ''));
};

/**
 * Генерация код оффера для маршрута
 */
export const getOfferCode = (steps: RouteStep[]) => steps.map((s) => s.shoulderOffer.id).join('-');

/**
 * Генерация код плеч для маршрута
 */
export const getShouldersCode = (steps: RouteStep[]) => steps.map((s) => s.shoulder.id).join('-');

/**
 * Генерация кода оффера и плеча
 * @param route
 */
export const getRouteCodes = (route?: Route) => {
  if (!route) {
    return {
      offersCode: '',
      shouldersCode: '',
    };
  }

  const steps = sortStepsByNumber(route.steps);

  return {
    offersCode: getOfferCode(steps),
    shouldersCode: getShouldersCode(steps),
  };
};

/**
 * Сортировка плечей, тк с бека могут прийти отсортированные по другому
 * @param steps
 */
export const sortStepsByNumber = (steps: RouteStep[]) =>
  steps.sort((a, b) => (a.stepNumber > b.stepNumber ? 1 : -1));

/**
 * Форматирование цены до двух знаков
 * @param price
 * @param minimumFractionDigits
 */
export const formatPrice = (price: number, minimumFractionDigits = 2) =>
  (Math.round(price * 100) / 100)
    .toLocaleString('RU-ru', { minimumFractionDigits })
    .replace(',', '.');
