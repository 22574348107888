import {BehaviorSubject, bufferTime, Subject} from "rxjs";
import {ServiceField, ServiceFieldType} from "@onlog-public/additional-services-types";
import {useEffect, useState} from "react";
import {SelectOption} from "@pages/AdditionalServices/containers/services/optionsGenerator/types";
import {OptionsCache} from "@pages/AdditionalServices/containers/services/optionsLoader/types";
import optionsCacheCheckerAndLoader from "@pages/AdditionalServices/containers/services/optionsCacheCheckerAndLoader";
import optionsGenerator from "@pages/AdditionalServices/containers/services/optionsGenerator";
import {v4} from "uuid";

/**
 * AdditionServicesOptionsState содержит данные стейта по селекторам полей
 * дополнительных услуг.
 */
export interface AdditionServicesOptionsState {
  /**
   * Поле содержит загруженные и подготовленные для отображения опции
   * селекторов для полей дополнительных услуг.
   * Поле формируется автоматически, исходя из переданных в шину полей
   * для загрузки данных.
   */
  FieldOptions: { [T in string]: SelectOption[] }

  /**
   * FieldsToCalculateState содержит данные полей, для которых необходимо
   * загрузить сущности опций и сформировать их.
   */
  FieldsToCalculateState: ServiceField[]

  /**
   * Язык, для которого необходимо выполнять формирование опций.
   */
  LangID: string

  /**
   * Кэш сущностей различного типа для использования в формировании опций
   * для полей дополнительных услуг.
   */
  Cache: { [T in ServiceFieldType]: OptionsCache<any> }
  Loadings: string[] // Идентификаторы загрузок сущностей.
}

const recalculateFieldOptions$ = new Subject<boolean>()
const loadingForType$ = new Subject<{ type: ServiceFieldType, id?: string[] }>()
export const optionsState$ = new BehaviorSubject<AdditionServicesOptionsState>({
  FieldOptions: {},
  FieldsToCalculateState: [],
  LangID: "",
  Loadings: [],
  Cache: {
    "switch": {isPartialLoading: true, cache: {}},
    "hidden": {isPartialLoading: true, cache: {}},
    "tax": {isPartialLoading: true, cache: {}},
    "location": {isPartialLoading: true, cache: {}},
    "number": {isPartialLoading: true, cache: {}},
    "handbook": {isPartialLoading: true, cache: {}},
    "currency": {isPartialLoading: true, cache: {}},
    "consulting_contractor": {isPartialLoading: true, cache: {}},
    "inspection_contractor": {isPartialLoading: true, cache: {}},
    "customs_contractor": {isPartialLoading: true, cache: {}},
    "certification_contractor": {isPartialLoading: true, cache: {}},
    "insurance_contractor": {isPartialLoading: true, cache: {}},
  }
})

/**
 * exportServiceFieldOptions выполняет экспорт опций полей для переданного
 * списка. Используется при формировании данных для добавления в коризу.
 *
 * @param fields
 * @param values
 */
export const exportServiceFieldOptions = (
  fields: ServiceField[],
  values: { [T in string]: number },
): { [T in string]: SelectOption[] } => {
  const result: { [T in string]: SelectOption[] } = {}

  const state = optionsState$.getValue()
  fields.map(f => {
    result[f.code] = state.FieldOptions[f.code]
      ?.filter(o => values[f.code] === o.value) ?? []

    if (result[f.code].length === 0 && !!values[f.code] && !['switch', 'number', 'hidden'].includes(f.type)) {
      throw new Error(`failed to get cache data for field: ${f.code} :: ${f.type}`)
    }
  })

  return result
}

/**
 * makeOptionsForFields реализует функционал загрузки опций для переданного
 * списка полей дополнительных услуг. Метод лишь инициирует загрузку.
 * Сама загрузка выполняется по шине loadingForType$
 *
 * @param fields
 */
export const makeOptionsForFields = (fields: ServiceField[]) => {
  fields.map(field => {
    if (["switch", "hidden", "number"].includes(field.type)) {
      return
    }

    let loadingID = field.handbook_limitations.length > 0
      ? field.handbook_limitations
      : undefined

    if (field.type === "handbook") {
      loadingID = [String(field.handbook_id)]
    }

    if (field.type === "location" && !loadingID) {
      loadingID = [String(field.value)]
    }

    loadingForType$.next({
      type: field.type,
      id: loadingID,
    })
  })

  optionsState$.next({
    ...optionsState$.getValue(),
    FieldsToCalculateState: [...fields],
  })
  recalculateFieldOptions$.next(true)
}

/**
 * makeOptionsForLocationChanges метод позволяет асинхронно подгружать
 * изменения по полям локаций. В данных полях используется поиск с
 * подсказкой и изначально кэш по ним не собирается. Данный метод
 * позволяет подгружать в кэш данные по выбранным в таких полях локациям.
 * @param field
 * @param value
 */
export const makeOptionsForLocationChanges = (
  field: ServiceField,
  value: number,
) => {
  if (field.type !== "location") {
    return
  }

  optionsState$.next({
    ...optionsState$.getValue(),
    FieldsToCalculateState: [{...field, value: value}]
  })
  recalculateFieldOptions$.next(true)
}

/**
 * loadCacheForType выполняет загрузку данных сущностей в кеш по переданному типу поля
 * и идентификаторам. Метод не загружает данные, а лишь инициирует загрузку.
 * Сама загрузка выполняется по шине loadingForType$
 *
 * @param type
 * @param id
 */
export const loadCacheForType = (type: ServiceFieldType, id: string[]) => {
  loadingForType$.next({
    type: type,
    id: id,
  })
}

export const initOptions = (langID: string) => {
  // Получаем все параметры и загружаем стейт по каждому.
  loadingForType$.pipe(bufferTime(50)).subscribe({
    next: batch => {
      if (batch.length == 0) {
        return
      }

      const existsCache = optionsState$.getValue()
      const id = v4()
      optionsState$.next({
        ...existsCache,
        Loadings: [...existsCache.Loadings, id]
      })

      Promise.all(
        batch.map(async conf => {
          return {
            type: conf.type,
            cache: await optionsCacheCheckerAndLoader().LoadCacheForNewData(
              conf.type,
              !existsCache.Cache[conf.type].isPartialLoading,
              Object.keys(existsCache.Cache[conf.type]),
              conf.id,
            )
          }
        }),
      ).then(values => {
        const state = optionsState$.getValue()
        const newState = {
          ...state,
          Cache: {
            ...state.Cache,
          },
          Loadings: state.Loadings.filter(l => l !== id)
        }

        let isNeedSave = false;
        values.map(val => {
          if (!val.cache.isPartialLoading) {
            isNeedSave = true
            newState.Cache[val.type] = val.cache
            return
          }

          if (Object.keys(val.cache.cache).length === 0) {
            return
          }

          if (!state.Cache[val.type].isPartialLoading) {
            return
          }

          isNeedSave = true
          newState.Cache[val.type] = {
            isPartialLoading: true,
            cache: {
              ...newState.Cache[val.type].cache,
              ...val.cache.cache,
            }
          }
        })

        if (!isNeedSave) {
          return optionsState$.next({
            ...state,
            Loadings: state.Loadings.filter(l => l !== id)
          })
        }

        optionsState$.next(newState)
        recalculateFieldOptions$.next(true)
      })
    }
  })

  // Подписка на пересчет опций для полей после загрузки данных сущностей.
  recalculateFieldOptions$.pipe(bufferTime(50)).subscribe({
    next: buffer => {
      if (0 == buffer.length) {
        return
      }

      const state = optionsState$.getValue()
      if (state.FieldsToCalculateState.length === 0) {
        return
      }

      const options: { [T in string]: SelectOption[] } = {...state.FieldOptions}
      state.FieldsToCalculateState.map(field => {
        options[field.code] = optionsGenerator().getOptions(
          field,
          state.LangID,
          state.Cache[field.type],
        )
      })

      optionsState$.next({
        ...state,
        FieldOptions: options,
      })
    }
  })

  optionsState$.next({
    ...optionsState$.getValue(),
    LangID: langID,
  })
}

const useAServiceOptions = () => {
  const [options, setState] = useState<AdditionServicesOptionsState>(optionsState$.getValue())
  useEffect(() => {
    const subscription = optionsState$.pipe().subscribe({
      next: nState => setState(nState),
    })

    return () => {
      try {
        subscription?.unsubscribe()
      } catch (e) {
      }
    }
  }, [])

  return {
    options,
    loadCacheForType,
  }
}

export default useAServiceOptions