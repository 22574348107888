import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

export default function ControlLabel(theme: Theme) {
  return {
    MuiFormControlLabel: {
      defaultProps: {
        container: document.getElementById('onlog-widget-container'),
      },
      styleOverrides: {
        label: {
          ...theme.typography.body1,
        },
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        container: document.getElementById('onlog-widget-container'),
      },
      styleOverrides: {
        root: {
          marginTop: theme.spacing(1),
        },
      },
    },
    MuiFormLabel: {
      defaultProps: {
        container: document.getElementById('onlog-widget-container'),
      },
      styleOverrides: {
        root: {
          overflow: 'hidden',
          width: '89%',
          textOverflow: 'ellipsis',
          color: theme.palette.text.disabled,
        },
      },
    },
  };
}
