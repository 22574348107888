import '../assets/styles/styles.scss';

import palette from '@assets/theme/palette';
import useMyRouting from '@context/useMyRouting/useMyRouting';
import { styled } from '@mui/material';
import getEnv from '@settings/getEnv';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ActiveTub {
  active: boolean;
}

const Container = styled('div')`
  display: flex;
  gap: 15px;
  align-items: center;
  cursor: pointer;
  transition: 400ms color;
  color: black;
  font-weight: ${({ active }: ActiveTub) => (active ? 600 : 400)};
  justify-content: center;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.32px;

  &:hover {
    color: ${palette.light.green[300]};
  }
`;

function PointMenuWidget() {
  const [page] = useMyRouting().usePage().useState();
  const { setPage } = useMyRouting().actions;

  const { t, ready } = useTranslation('CartComponent');
  const { REACT_APP_PATH } = getEnv();

  if (!ready) {
    return <></>;
  }

  const handleGoToBasket = () => {
    setPage('widget');

    if (window.location.pathname !== REACT_APP_PATH) {
      window.open(REACT_APP_PATH, '_self');
    }
  };

  const isActive = page === 'widget' && window.location.pathname === REACT_APP_PATH;

  return (
    <Container onClick={handleGoToBasket} active={isActive}>
      <span>{t('CartComponent:Calculator')} </span>
    </Container>
  );
}

export default PointMenuWidget;
