import PlaneIcon from '@components/icons/TransportIcons/PlaneIcon';
import ShipIcon from '@components/icons/TransportIcons/ShipIcon';
import TrainIcon from '@components/icons/TransportIcons/TrainIcon';
import TruckIcon from '@components/icons/TransportIcons/TruckIcon';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import LocationOrTerminalPoint from '@components/pages/LocationOrTerminalPoint';
import { BranchItem } from '@services/requests/routeCalculator/locationsBranchSearchService/interfaces';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { RouteShoulderContainer } from './StyledComponents';

/**
 * Пропсы компонента
 */
export type RouteShoulderProps = {
  startLocation: BranchItem;
  startContractorName: string;
  endLocation: BranchItem;
  endContractorName: string;
  deliveryDays: number;
  routeStepTypes: string[];
};

const TransportIcons = (t: string) => {
  switch (t) {
    case '1':
      return ShipIcon;
    case '2':
      return TrainIcon;
    case '3':
      return PlaneIcon;
  }

  return TruckIcon;
};

/**
 * Компонент вывода описания плеча маршрута
 * @param props
 */
const RouteShoulder = (props: RouteShoulderProps) => {
  const { startLocation, endLocation, deliveryDays, routeStepTypes = [] } = props;

  const { t } = useTranslation(['Common']);
  let startPoint = { ...startLocation };
  if (startLocation.type === 'terminal' && startLocation.symbolCode === 'ANY') {
    startPoint = {
      ...startLocation,
      type: 'location',
    };
  }

  let endPoint = { ...endLocation };
  if (endLocation.type === 'terminal' && endLocation.symbolCode === 'ANY') {
    endPoint = {
      ...endLocation,
      type: 'location',
    };
  }

  return (
    <RouteShoulderContainer>
      <LocationOrTerminalPoint value={startPoint} branchItem={startPoint}>
        {(primary, _secondary, symbolCode) => {
          return (
            <div className="place__wrapper left">
              <div className="short__name">
                {!symbolCode || symbolCode === '' ? primary[0] : symbolCode}
              </div>
              <div className="full__place">{primary.join(', ')}</div>
            </div>
          );
        }}
      </LocationOrTerminalPoint>
      <div className="line__wrapper">
        {deliveryDays > 0 && (
          <div className="days">
            {deliveryDays}{' '}
            {deliveryDays === 1 ? t('Common:DeliveryTimeDay') : t('Common:DeliveryTimeDays')}
          </div>
        )}
        <div className="line">
          <KeyboardArrowRightRoundedIcon />
        </div>
        <div className="types__of_transport">
          {routeStepTypes.map((t) => {
            const Icon = TransportIcons(t);

            return (
              <div key={`route-shoulder__icons--${t}`}>
                <Icon />
              </div>
            );
          })}
        </div>
      </div>
      <LocationOrTerminalPoint value={endPoint} branchItem={endPoint}>
        {(primary, _secondary, symbolCode) => {
          return (
            <div className="place__wrapper left">
              <div className="short__name">
                {!symbolCode || symbolCode === '' ? primary[0] : symbolCode}
              </div>
              <div className="full__place">{primary.join(', ')}</div>
            </div>
          );
        }}
      </LocationOrTerminalPoint>
    </RouteShoulderContainer>
  );
};

export default RouteShoulder;
