import {Service, ServiceField} from "@onlog-public/additional-services-types";
import {
  ServiceInitialStateGenerator, serviceInitialStateGeneratorProcessor
} from "@pages/AdditionalServices/containers/services/serviceInitialStateGenerator/types";

/**
 * BaseServiceInitialStateGenerator реализует сервис базовой генерации стейтов услуг
 */
export class BaseServiceInitialStateGenerator implements ServiceInitialStateGenerator {
  protected processors: serviceInitialStateGeneratorProcessor[]

  /**
   * Конструктор сервиса
   * @param processors
   */
  constructor(processors: serviceInitialStateGeneratorProcessor[]) {
    this.processors = processors
  }

  /**
   * Generate выполняет генерацию изначального стейта для шаблонов на
   * основе переданных полей и услуг.
   * @param services
   * @param globalFields
   * @param globalFieldValues
   * @param variantFields
   * @param variantFieldValues
   */
  Generate(
    services: { [x: string]: Service[]; },
    globalFields: ServiceField[],
    globalFieldValues: { [x: string]: number; },
    variantFields: { [x: string]: ServiceField[]; },
    variantFieldValues: { [x: string]: { [x: string]: number; }; },
  ): { [x: string]: { [x: string]: any; }; } {
    const result: { [x: string]: { [x: string]: any; }; } = {}

    Object.keys(services).map(variantID => {
      result[variantID] = {}
      const fields = [...globalFields, ...variantFields[variantID]]
      const values = {...globalFieldValues, ...variantFieldValues[variantID]}

      services[variantID].map(service => {
        result[variantID][service.id] = this.generateForService(service, fields, values)
      })
    })

    return result
  }

  /**
   * generateForService выполняет генерацию стейта по полям и услуге. Возвращает сам стейт.
   * @param service
   * @param fields
   * @param values
   */
  protected generateForService(
    service: Service,
    fields: ServiceField[],
    values: { [T in string]: number },
  ): any {
    const processor = this.processors.find(p => p.isAvailable(service))
    if (!processor) {
      return
    }

    return processor.generate(service, fields, values)
  }
}