import {
  GetTerminalsToDisplayType,
  TerminalsToDisplayType,
} from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesListComponent/DeliveryFilterContainer/Filter/types';
import { GetLocalizedNamesForLocationsOrTerminals } from '@settings/services/helpers/GetLocalizedNamesForLocationsOrTerminals';

export const getTerminalsToDisplay = ({
  terminals,
  systemLanguage,
  mainLanguage,
}: GetTerminalsToDisplayType): TerminalsToDisplayType[] => {
  return terminals
    .filter((t) => t.type === 'terminal')
    .map((terminal) => {
      const { primaryNames, symbolCode } = GetLocalizedNamesForLocationsOrTerminals(
        terminal,
        systemLanguage,
        mainLanguage,
      );

      const title = symbolCode === '' ? primaryNames.shift() : symbolCode;
      const tooltip = `${primaryNames.shift()} (${terminal.visibleName}) ${symbolCode}`;

      return {
        value: parseInt(terminal.id),
        title,
        tooltip,
      };
    });
};
