import CalculateDeliveryIcon from '@components/icons/CalculateDeliveryIcon';
import CardWrapper from '@components/UI/CardWrapper';
import { Stack } from '@material-ui/core';
import { Button, Skeleton } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import RoutesCalculationWidgetStyledContainer from './components/RoutesCalculationWidget/StyledComponents';

interface DeliveryFormSkeletonProps {
  isMobile: boolean;
}

const DeliveryFormSkeleton = (props: DeliveryFormSkeletonProps) => {
  const { isMobile } = props;
  const { t } = useTranslation();
  return (
    <CardWrapper className={'card-wrapper'}>
      {isMobile ? (
        <Button
          disabled
          variant={'contained'}
          fullWidth
          size={'large'}
          startIcon={<CalculateDeliveryIcon />}
          className={'show-form'}
        >
          {t('Actions:CalculateDelivery')}
        </Button>
      ) : (
        <RoutesCalculationWidgetStyledContainer>
          <div className={'form-wrapper'}>
            <div className={'form-wrapper__fields'}>
              <Stack spacing={3} direction={'row'}>
                <Stack spacing={1} direction={'column'} width={'100%'}>
                  <Skeleton variant="rounded" width={'100%'} height={60} />
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={200} />
                </Stack>
                <Stack spacing={1} direction={'column'} width={'100%'}>
                  <Skeleton variant="rounded" width={'100%'} height={60} />
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={200} />
                </Stack>
              </Stack>
              <Skeleton variant="rounded" width={'100%'} height={60} />
              <Skeleton variant="rounded" width={'100%'} height={60} />
            </div>
            <Skeleton variant="circular" width={50} height={50} />
          </div>
        </RoutesCalculationWidgetStyledContainer>
      )}
    </CardWrapper>
  );
};

export default DeliveryFormSkeleton;
