import React, {FC} from "react";
import {ServiceFieldSettings} from "@onlog-public/additional-services-types";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Tooltip from "@mui/material/Tooltip";
import TextDisplayField from "@pages/AdditionalServices/components/FieldDisplay/AdditionServiceField/TextDisplayField";
import InputLabel from "@mui/material/InputLabel";
import {CircularProgress} from "@material-ui/core";
import clsx from "clsx";
import {SelectOption} from "@pages/AdditionalServices/containers/services/optionsGenerator/types";
import FormHelperText from "@mui/material/FormHelperText";

export interface SelectFieldProps {
  fieldCode: string
  value?: number
  settings: ServiceFieldSettings
  isFieldsDisabled: boolean
  tooltip: string
  placeholder: string
  options?: SelectOption[]
  isOptionsLoading: boolean
  error?: string

  onChange: (value: number) => void
}

const SelectField: FC<SelectFieldProps> = props => {
  const {
    fieldCode,
    value,
    settings,
    tooltip,
    placeholder,
    options = [],
    error = "",
    isOptionsLoading,
    isFieldsDisabled,
    onChange,
  } = props

  const fieldSize = settings.FieldSize === "small" ? "small" : "medium"
  const loaderSize = settings.FieldSize === "small" ? 16 : 24
  const isWithDescription = !!options.find(o => !!o.description)
  const existOption = options.find(o => o.value === value)

  if (settings.IsNeedDisplayOnlyTextInfo) {
    return (
      <TextDisplayField
        tooltip={tooltip}
        value={String(existOption?.label ?? "")}
      />
    )
  }

  return (
    <Tooltip
      placement="top"
      title={tooltip.length > 0 && (
        <div dangerouslySetInnerHTML={{__html: tooltip}} className="a-html-content"/>
      )}
    >
      <FormControl variant="outlined" fullWidth={true} error={error.length > 0}>
        <InputLabel id={`${fieldCode}-label`}>{placeholder}</InputLabel>
        <Select
          labelId={`${fieldCode}-label`}
          id={`${fieldCode}-select`}
          value={String(existOption?.value || '')}
          className={clsx("a-service-field-selector", fieldSize, {
            "with-description": isWithDescription
          })}
          fullWidth
          size={fieldSize}
          label={placeholder}
          disabled={isFieldsDisabled || settings.IsNeedDisableField}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            }
          }}
          endAdornment={isOptionsLoading && (
            <div className={clsx("a-service-field-selector-loader", fieldSize)}>
              <CircularProgress color="inherit" size={loaderSize}/>
            </div>
          )}
          onChange={e => onChange(parseInt(e.target.value))}
        >
          {options.map((option) => (
            <MenuItem
              value={option.value}
              key={option.value}
              className="a-service-field-selector-option--root"
            >
              <div
                className={clsx("a-service-field-selector-option", {
                  "with-description": isWithDescription,
                  [fieldSize]: true,
                })}
              >
                <div className="option-label">{option.label}</div>
                {option.description && (
                  <div className="option-description">{option.description}</div>
                )}
              </div>
            </MenuItem>
          ))}
        </Select>
        {error.length > 0 && (
          <FormHelperText>{error}</FormHelperText>
        )}
      </FormControl>
    </Tooltip>
  )
}

export default SelectField