export function delay(callback: () => void, delayMs: number = 0) {
	let timerId: ReturnType<typeof setTimeout>;

	const delayDecorator = () => {
		timerId = setTimeout(callback, delayMs);
	}

	delayDecorator.cancel = () => {
		if (timerId) {
			clearTimeout(timerId);
		}
	};

	return delayDecorator;

}