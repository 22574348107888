import React, {FC} from "react";

const CollapseIcon: FC = () => {
  return (
    <svg width="20" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.75 9L10 2L1.25 9" stroke="#3460B4" strokeWidth="2"/>
    </svg>
  )
}

export default CollapseIcon