import { styled } from '@mui/material';

const DefaultTableStyledContainer = styled('div')`
  overflow: auto;
  max-width: 100%;
  width: 100%;

  .MuiDataGrid-row .MuiDataGrid-cell {
    line-height: normal;

    &:nth-of-type(n + 2) {
      padding: 10px 5px;
    }

    &:first-child {
      border-left: 1px solid ${({ theme }) => theme.palette.grey[200]};
    }
  }

  .MuiDataGrid-root .MuiDataGrid-columnHeader,
  .MuiDataGrid-root .MuiDataGrid-cell {
    padding: 10px 15px 10px 20px;
    justify-content: center;
    align-items: center;
  }

  .MuiDataGrid-root .MuiDataGrid-columnHeaders,
  .MuiDataGrid-root .MuiDataGrid-columnHeader {
    line-height: normal !important;
    border-radius: 0;
    border-color: ${({ theme }) => theme.palette.grey[200]};
  }

  .MuiDataGrid-columnHeaders {
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }

  .MuiDataGrid-root .MuiDataGrid-columnHeader {
    padding: 0 5px;
    color: ${({ theme }) => theme.palette.grey[900]};

    &:first-child {
      border-left: 1px solid ${({ theme }) => theme.palette.grey[200]};
    }
  }

  .MuiDataGrid-root .MuiDataGrid-columnSeparator {
    display: none;
  }

  .MuiDataGrid-root .MuiDataGrid-withBorderColor {
    border-color: ${({ theme }) => theme.palette.grey[200]};
    .MuiDataGrid-cellContent {
      color: black;
    }
  }

  .MuiDataGrid-columnHeaderTitle {
    text-overflow: initial;
    white-space: normal;
    text-align: center;
  }

  .MuiDataGrid-root {
    border-radius: 0;
    border-bottom: 0;
  }

  .default-table-container {
    .MuiDataGrid-cellContent {
      color: ${({ theme }) => theme.palette.common.black};
    }

    &__text-left {
      .MuiDataGrid-cellContent {
        text-align: left;
        width: 100%;
      }
    }

    &__text-center {
      .MuiDataGrid-cellContent {
        text-align: center;
        width: 100%;
      }
    }

    &__number-input {
      .MuiDataGrid-cellContent,
      .MuiDataGrid-editInputCell {
        display: flex;
        width: 50px;
        padding: 7px 0 6px 0;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid ${({ theme }) => theme.palette.grey[200]};
      }

      .MuiInputBase-input {
        padding: 0;
        text-align: center;
      }
    }
  }
`;

export default DefaultTableStyledContainer;
