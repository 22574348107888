import React from 'react';
import TubsContainer from 'src/pages/AdditionalServices/components/TubsContainer';

import FormWidget from '../AdditionalServices';
import RootContainer from './StyledComponents';

const Widget = () => {
  // iframe не удалять. Он используется для изолированного вычисления формул в
  // полях дополнительных услуг.
  return (
    <RootContainer>
      <iframe id="a-service-iframe"></iframe>
      <TubsContainer/>
      <FormWidget/>
    </RootContainer>
  );
};

export default Widget;
