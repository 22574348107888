import {ListingTemplateSettings} from "@onlog-public/additional-services-types/src/templates/ListingTemplateSettings";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import Tab from "@mui/material/Tab";
import clsx from "clsx";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

export interface SortTabByPriceProps {
  isSelected: boolean
  sortDirection: "asc" | "desc"
  position: number
  templateSettings: ListingTemplateSettings
  onSelect: () => void
}

const SortTabByPrice: FC<SortTabByPriceProps> = React.forwardRef<HTMLDivElement, SortTabByPriceProps>((props, ref) => {
  const {
    isSelected,
    sortDirection,
    position,
    templateSettings,
    onSelect,
  } = props
  const {t} = useTranslation('AdditionalService')

  let title = templateSettings.PriceAscDirectionCaption
  if (templateSettings.PriceAvailableDirection === "desc") {
    title = templateSettings.PriceDescDirectionCaption
  }

  if (isSelected && templateSettings.PriceAvailableDirection === "both" && sortDirection === "desc") {
    title = templateSettings.PriceDescDirectionCaption
  }

  const isNeedDisplayArrow = templateSettings.IsNeedDisplayDirectionArrow && isSelected

  return (
    <Tooltip placement="top" title={t('AdditionalService:Templates.list.changeDirection')}>
      <Tab
        ref={ref}
        label={(
          <div className="a-service-template-list-content--sort-tab">
            {templateSettings.IsNeedDisplayDirectionArrow && (
              <div
                className={clsx("arrow", {
                  "revert": sortDirection === "asc"
                })}
              >
                {isNeedDisplayArrow && (
                  <ArrowDownwardIcon/>
                )}
              </div>
            )}
            <div>{t(title)}</div>
          </div>
        )}
        onClick={onSelect}
        value={position}
        className={clsx("a-service-template-list-content-tab", {
          "a-service-template-list-content-tab--selected": isSelected,
        })}
      />
    </Tooltip>
  )
})

export default SortTabByPrice