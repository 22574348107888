import {GraphQLQuery} from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';
import {
  ServiceDirectoryLoadItem
} from "@services/requests/servicesService/servicesDirectoriesService/requests/ServiceDirectoryLoadByParent";

export interface ServiceDirectoryLoadByIDVariables {
  id: string
}

export interface ServiceDirectoryLoadByIDResponse {
  result: ServiceDirectoryLoadItem[]
}

/**
 * Запрос списка директорий дополнительных услуг по переданному идентификатору
 * директории.
 */
export class ServiceDirectoryLoadByIDQuery implements GraphQLQuery<ServiceDirectoryLoadByIDVariables> {
  readonly query: any;

  readonly variables: ServiceDirectoryLoadByIDVariables;

    constructor(params: ServiceDirectoryLoadByIDVariables) {
      this.variables = params;
      this.query = gql`
        query LoadServiceDirectoryByID(
            $id: ID!
        ) {
            result: servicedirectory_list(
                where: {id:{_equals:$id}}
            ) {
                id
                contractor_id
                contractor_type
                description
                file_id
                is_active
                is_variants
                localized_descriptions
                localized_names
                name
                parent
                settings
                template
                fields {
                    id
                    name
                    localized_names
                    placeholder
                    localized_placeholders
                    helper
                    localized_helpers
                    code
                    type
                    value
                    handbook_limitations
                    handbook_id
                    settings
                }
                childs {
                    id
                    contractor_id
                    contractor_type
                    description
                    file_id
                    is_active
                    is_variants
                    localized_descriptions
                    localized_names
                    name
                    parent
                    settings
                    template
                    fields {
                        id
                        name
                        localized_names
                        placeholder
                        localized_placeholders
                        helper
                        localized_helpers
                        code
                        type
                        value
                        handbook_limitations
                        handbook_id
                        settings
                    }
                    properties {
                        description
                        id
                        handbook_id
                        localized_descriptions
                        localized_names
                        name
                        ordinal
                        settings
                        type
                    }
                }
                properties {
                    description
                    id
                    handbook_id
                    localized_descriptions
                    localized_names
                    name
                    ordinal
                    settings
                    type
                }
            }
        }
    `;
    }
}
