import { $error } from '@settings/errorContext';
import { graphQLClient } from '@settings/services/graphQLClient';
import { GraphQLClient } from '@settings/services/graphQLClient/GraphQLClient';
import { loggerFactory } from '@settings/services/logger';
import { Collection } from '@settings/services/types';

import { GetMessagesQuery, GetMessagesQueryResponse } from './GetMessagesQuery';
import { InsertMessagesMutation, InsertMessagesMutationResponse } from './InsertMessagesMutation';
import { LocalizedMessage, LocalizedMessagesServiceInterfaces } from './interfaces';
import { UpdateMessageMutation, UpdateMessageMutationResponse } from './UpdateMessageMutation';

/**
 * Сервис работы с локализованными сообщениями
 */
export class LocalizedMessagesService implements LocalizedMessagesServiceInterfaces {
  private readonly client: GraphQLClient;

  private readonly logger = loggerFactory().make(`LocalizedMessagesService`);

  /**
   * Конструктор сервиса
   * @param token
   */
  constructor(token?: string) {
    this.client = graphQLClient(token);
  }

  /**
   * Получение листинга локализованных сообщений по ID.
   * В результатах возвращает картированный результат с ключем - langID.
   * @param ids
   */
  async GetMessages(ids: string[]): Promise<Collection<LocalizedMessage>> {
    try {
      if (ids.length === 0) {
        return {};
      }

      const response = await this.client.Query<null, GetMessagesQueryResponse>(
        new GetMessagesQuery(ids),
        {}
      );
      this.logger.Debug(`Loaded base data`, response);

      if (response.messages.length === 0) {
        return {};
      }

      const result = response.messages.reduce(
        (
          result: Collection<LocalizedMessage>,
          message: LocalizedMessage
        ): Collection<LocalizedMessage> => ({
          ...result,
          [message.lang_id]: message,
        }),
        {}
      );
      this.logger.Debug(`Parsed loaded data`, result);

      return result;
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  /**
   * Сохраняет сообщения и возвращает результат.
   * Если сообщение было создано - для него присваивается ID.
   * @param messages
   */
  async StoreMessages(
    messages: Collection<LocalizedMessage>
  ): Promise<Collection<LocalizedMessage>> {
    try {
      const messagesToUpdate: LocalizedMessage[] = [];
      const messagesToCreate: LocalizedMessage[] = [];

      Object.values(messages).map((message) => {
        if (!message.id || message.id.length === 0) {
          messagesToCreate.push(message);
        } else {
          messagesToUpdate.push(message);
        }
      });

      let result: Collection<LocalizedMessage> = {};
      const createPromise = this.CreateMessages(messagesToCreate).then((messages) => {
        result = { ...result, ...messages };
      });
      await Promise.all([
        createPromise,
        ...messagesToUpdate.map(async (message) => {
          const updMessage = await this.UpdateMessage(message);
          if (!updMessage) {
            return;
          }

          result[updMessage.lang_id] = message;
        }),
      ]);

      this.logger.Debug(`Store result`, result);

      return result;
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  /**
   * Создание новых сообщений
   * @param messagesToCreate
   */
  async CreateMessages(
    messagesToCreate: LocalizedMessage[]
  ): Promise<Collection<LocalizedMessage>> {
    if (messagesToCreate.length === 0) {
      return {};
    }

    const response = await this.client.Mutation<null, InsertMessagesMutationResponse>(
      new InsertMessagesMutation(messagesToCreate),
      {}
    );
    this.logger.Debug(`Message creation response`, response);
    if (response.messages.returning.length === 0) {
      return {};
    }

    return await this.GetMessages(response.messages.returning.map((item) => item.id));
  }

  /**
   * Обновление локализованного сообщения
   * @param messageToUpdate
   */
  async UpdateMessage(messageToUpdate: LocalizedMessage): Promise<LocalizedMessage | undefined> {
    const response = await this.client.Mutation<null, UpdateMessageMutationResponse>(
      new UpdateMessageMutation(messageToUpdate),
      {}
    );
    this.logger.Debug(`Message update response`, response);
    if (response.messages.returning.length === 0) {
      return undefined;
    }

    return {
      ...messageToUpdate,
      id: response.messages.returning[0].id,
    };
  }

  /**
   * Получение листинга локализованных сообщений по ID.
   * В результатах возвращает массив локализованных сообщений
   * @param ids
   */
  async GetMessagesArray(ids: string[]): Promise<LocalizedMessage[]> {
    try {
      if (ids.length === 0) {
        return [];
      }

      const response = await this.client.Query<null, GetMessagesQueryResponse>(
        new GetMessagesQuery(ids),
        {}
      );
      this.logger.Debug(`Loaded base data`, response);

      if (response.messages.length === 0) {
        return [];
      }

      this.logger.Debug(`Parsed loaded data`, response.messages);

      return response.messages;
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }
}
