import {serviceContext$, serviceFilterAndOrderContext$} from "@pages/AdditionalServices/containers/bus";
import servicesFilter from "@pages/AdditionalServices/containers/services/servicesFilter";
import servicesOrder from "@pages/AdditionalServices/containers/services/servicesOrder";
import {AServicePropertyFilterAndOrder} from "@pages/AdditionalServices/containers/types";
import {
  ServicesServicePriceCalculationMutationProps
} from "@services/requests/servicesService/servicesServicesService/interface";

/**
 * calculateServiceOrderAndFilter вычисляет порядок услуг и их видимость на основе
 * данных фильтрации для каждого из вариантов
 * @param isNeedRecalculateState
 * @param variantID
 * @param calculationResult
 */
const calculateServiceOrderAndFilter = (
  isNeedRecalculateState: boolean,
  variantID?: string,
  calculationResult: { [T in string]: ServicesServicePriceCalculationMutationProps[] } = undefined,
) => {
  const state = serviceFilterAndOrderContext$.getValue()
  if (!isNeedRecalculateState) {
    if (!variantID) {
      return
    }

    // Если запрос скипа пересчет пришел от конкретного варианта, то значит в нем
    // необходимо снять текущую сортировку, т.к. порядок поменялся.
    return serviceFilterAndOrderContext$.next({
      ...state,
      Variants: {
        ...state.Variants,
        [variantID]: {
          ...state.Variants[variantID],
          IsSortChanged: true,
        }
      }
    })
  }

  const {VariantServices, VariantsCalculationResult} = serviceContext$.getValue()
  const servicesResult = calculationResult ?? VariantsCalculationResult

  // Копируем стейт для фильтров
  const variants = {...state, Variants: {}} as AServicePropertyFilterAndOrder
  Object.keys(state.Variants).map(variantID => {
    variants.Variants[variantID] = {
      ...state.Variants[variantID],
      IsSortChanged: false,
      ServiceVisibility: {},
      ServicePriority: {},
    }
  })

  // Вычисляем видимость услуг для всех вариантов
  Object.keys(VariantServices).map(variantID => {
    VariantServices[variantID].map(s => {
      variants.Variants[variantID].ServiceVisibility[s.id] = false
    })

    // Фильтруем услуги, которые необходимо показывать и помечаем для показа
    servicesFilter()
      .Filter(VariantServices[variantID], servicesResult[variantID], state.Variants[variantID])
      .map(service => {
        variants.Variants[variantID].ServiceVisibility[service.id] = true
      })
  })

  // Вычисляем сортировку услуг для всех вариантов
  Object.keys(VariantServices).map(variantID => {
    VariantServices[variantID]
      .sort(servicesOrder().Order(state.Variants[variantID], servicesResult[variantID]))
      .map((service, i) => {
        variants.Variants[variantID].ServicePriority[service.id] = i
      })
  })

  serviceFilterAndOrderContext$.next(variants)
}

export default calculateServiceOrderAndFilter