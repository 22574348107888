import {serviceContext$} from "@pages/AdditionalServices/containers/bus";
import {clone} from "@pages/AdditionalServices/containers/methods/clone";
import {v4} from "uuid";

/**
 * onChangeVariant реализует функционал переключения выбранного варианта услуг
 * @param variant
 */
export const onChangeVariant = (variant: string) => {
  serviceContext$.next({
    ...clone(serviceContext$.getValue()),
    SelectedVariant: variant,
    SelectedProductUUID: v4(),
  })
}