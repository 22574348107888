import React, {FC} from "react";
import {ServiceDirectoryProps} from "@pages/AdditionalServices/components/ServiceDirectory/types";
import ListTypeTemplate from "@pages/AdditionalServices/components/ServiceDirectory/ListTypeTemplate";
import GroupTypeTemplate from "@pages/AdditionalServices/components/ServiceDirectory/GroupTypeTemplate";

/**
 * ServiceDirectory реализует вывод директорий дополнительных услуг.
 * Выбирает шаблон для вывода, в зависимости от того, какой установлен
 * в директории.
 *
 * @param props
 */
const ServiceDirectory: FC<ServiceDirectoryProps> = props => {
  switch (props.variant.template) {
    case "listing":
      return <ListTypeTemplate {...props} />
    case "group":
      return <GroupTypeTemplate {...props} />
  }

  return null
}

export default ServiceDirectory