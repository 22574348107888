import { Theme, alpha } from '@material-ui/core/styles';

import palette from '../palette';

// ----------------------------------------------------------------------

export default function Pickers(theme: Theme) {
  return {
    MuiPicker: {
      defaultProps: {
        container: document.getElementById('onlog-widget-container'),
        orientation: 'portrait',
      },
    },
    MuiPickersSlideTransition: {
      styleOverrides: {
        root: {
          minHeight: 228,
        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          width: 287,
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        weekContainer: {
          margin: 0,
          gap: '2px',
        },
        header: {
          gap: '2px',
        },
        monthContainer: {
          display: 'grid',
          gap: '2px',
        },
        weekDayLabel: {
          fontSize: 14,
          fontWeight: 600,
          lineHeight: '22px',
          color: theme.palette.grey[700],
          textTransform: 'uppercase',
          margin: 0,
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          marginTop: 12,
          marginBottom: 14,
          paddingLeft: 14,
          paddingRight: 4,
        },
        switchViewButton: {
          display: 'none',
        },
        label: {
          fontSize: 16,
          textTransform: 'capitalize',
        },
      },
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        spacer: {
          width: 28,
        },
        button: {
          fontSize: 28,
        },
      },
    },
    // Paper
    MuiPickersPopper: {
      defaultProps: {
        container: document.getElementById('onlog-widget-container'),
      },
      styleOverrides: {
        paper: {
          boxShadow: theme.customShadows.z24,
          borderRadius: theme.shape.borderRadiusMd,
        },
      },
    },

    // Dialog Actions
    MuiDialog: {
      defaultProps: {
        container: document.getElementById('onlog-widget-container'),
      },
    },

    // Days
    MuiPickersDay: {
      defaultProps: {
        container: document.getElementById('onlog-widget-container'),
      },
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 600,
          margin: 0,
          lineHeight: '22px',
          borderRadius: '100%!important',
          backgroundColor: 'transparent',
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
          },
          '&.Mui-selected': {
            color: 'white!important',
            backgroundColor: `${palette.light.green[300]}!important`,
          },
          '&:hover': {
            color: 'white!important',
            backgroundColor: `${palette.light.green[300]}!important`,
          },
        },
        today: {
          '&:not(.Mui-selected)': {
            border: 0,
            backgroundColor: theme.palette.grey[100],
          },
        },
      },
    },

    // Toolbar
    MuiPickersToolbar: {
      defaultProps: {
        container: document.getElementById('onlog-widget-container'),
      },
      styleOverrides: {
        root: {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.primary.main,
          '& .MuiTypography-root': {
            color: alpha(theme.palette.common.white, 0.72),
            '&.Mui-selected': {
              color: theme.palette.common.white,
            },
            '&.MuiDatePickerToolbar-dateTitleLandscape': {
              color: theme.palette.common.white,
            },
          },
        },
      },
    },

    // Tab
    MuiDateTimePickerTabs: {
      defaultProps: {
        container: document.getElementById('onlog-widget-container'),
      },
    },

    // Static
    MuiPickersStaticWrapper: {
      defaultProps: {
        container: document.getElementById('onlog-widget-container'),
      },
      styleOverrides: {
        root: {
          boxShadow: theme.customShadows.z24,
          borderRadius: theme.shape.borderRadiusMd,
          '& .MuiDateRangePickerViewDesktop-rangeCalendarContainer:not(:last-child)': {
            borderRightWidth: 1,
          },
        },
      },
    },

    // Clock
    MuiTimePickerToolbar: {
      defaultProps: {
        container: document.getElementById('onlog-widget-container'),
      },
    },
    MuiClock: {
      defaultProps: {
        container: document.getElementById('onlog-widget-container'),
      },
      styleOverrides: {
        root: {
          position: 'relative',
          margin: theme.spacing(5, 3),
        },
        clock: {
          backgroundColor: theme.palette.grey[500_24],
        },
        amButton: {
          left: theme.spacing(-1),
          bottom: theme.spacing(-3),
          backgroundColor: theme.palette.grey[500_24],
        },
        pmButton: {
          right: theme.spacing(-1),
          bottom: theme.spacing(-3),
          backgroundColor: theme.palette.grey[500_24],
        },
      },
    },
  };
}
