import breakpoints from '@assets/theme/breakpoints';
import palette from '@assets/theme/palette';
import Box from '@material-ui/core/Box/Box';
import { styled } from '@mui/material';

const Container = styled(Box)`
  margin-bottom: 24px;
  width: 100%;
  background: transparent;

  button {
    border: unset;
  }
  .item {
    text-transform: initial;
    font-weight: 500;
  }

  @media (max-width: ${breakpoints.values.sm}px) {
    .item {
      padding: 0 20px;
      color: ${palette.light.grey[800]};
    }
    .MuiTabs-root {
      min-height: 30px;
    }
    .MuiTab-root {
      min-height: 30px;
      margin-right: 10px;
      border: 1px solid ${palette.light.grey[390]};
      border-radius: 4px;
    }
    .MuiTabs-indicator {
      height: 100%;
      border-radius: 4px;
      border: 1px solid ${palette.light.green[300]};
      background: none;
    }
    .MuiTabScrollButton-root {
      width: 30px;
    }
    .Mui-selected {
      .item {
        color: ${palette.light.green[300]};
      }
      &.MuiTab-root {
        border: unset !important;
      }
    }
  }
`;

export default Container;
