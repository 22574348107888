import {ServiceFieldType} from "@onlog-public/additional-services-types";
import {OptionsCache} from "../optionsLoader/types";
import {
  _optionsCacheCheckerAndLoaderProcessor,
  OptionsCacheCheckerAndLoader
} from "@pages/AdditionalServices/containers/services/optionsCacheCheckerAndLoader/types";

/**
 * BaseOptionsCacheCheckerAndLoader реализует сервис вычисления и загрузки
 * недостающих данных для кэша опций полей.
 */
export class BaseOptionsCacheCheckerAndLoader implements OptionsCacheCheckerAndLoader {
  protected processors: _optionsCacheCheckerAndLoaderProcessor[]

  /**
   * Конструктор сервиса
   * @param processors
   */
  constructor(processors: _optionsCacheCheckerAndLoaderProcessor[]) {
    this.processors = processors
  }

  /**
   * LoadCacheForNewData выполняет проверку, есть ли данные в кэше
   * и выполняет загрузку недостающих данных.
   *
   * @param type
   * @param isFullStateLoaded
   * @param existInStateKeys
   * @param loadingID
   */
  async LoadCacheForNewData(
    type: ServiceFieldType,
    isFullStateLoaded: boolean,
    existInStateKeys: string[],
    loadingID?: string[],
  ): Promise<OptionsCache<any>> {
    const processor = this.processors.find(p => p.GetType() === type)
    if (!processor) {
      return {isPartialLoading: true, cache: []}
    }

    return await processor.LoadCacheForNewData(isFullStateLoaded, existInStateKeys, loadingID)
  }
}