import {LocalizedMessage} from '../../localizedMessagesService/interfaces';
import {BranchItem, LocationSymbolCode, SearchItem} from './interfaces';

// Опция локации или терминала для списка значений
export class LocationTerminalOption implements BranchItem {
  defaultName?: string;
  depthLevel: number;
  main?: boolean;
  coords?: { lat: number; lng: number };
  visibleName: string;
  id: string;
  isUserSearchable: boolean;
  iso: string;
  localizedNames: string[];
  localizedNamesArray: LocalizedMessage[];
  localizedIso: string[];
  localizedIsoArray: LocalizedMessage[];
  isWarehouse?: boolean = false;
  parentId: string | null;
  subItems: SearchItem[];
  symbolCode: LocationSymbolCode;
  type: 'location' | 'terminal';
  files: string[];
}
