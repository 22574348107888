import { CalculatedAllowanceOffer } from '@services/requests/orderService/types';

const getIncludedAllowance = (type: string): CalculatedAllowanceOffer => {
  return {
    allowanceType: {
      type: type,
      isIncluded: true,
    },
    allowanceOffer: {
      allowance: {
        allowance_group: type === 'load' ? '4' : '5',
      },
      allowance_id: '',
      is_invoice_allowance: false,
      id: '',
      offer_conditions: [],
    },
    allowanceOfferCondition: {
      currency: {
        course: 1,
        code: 'RUB',
      },
      currency_id: '',
      group_num: 0,
      id: '',
      information_price: 0,
      is_fixed_price: false,
      is_max_value_not_limited: false,
      is_min_value_not_limited: false,
      is_tax_included_in_price: false,
      max_value: 0,
      min_value: 0,
      minimal_payment_price: 0,
      price: 0,
      tax_id: null,
      unit_id: '',
    },
    calculatedPrice: {
      basePrice: 0,
      basePriceArray: [],
      currencyCode: '',
      computedAmount: 0,
      conversionFee: 0,
      fullPriceInTargetCurrency: 0,
      priceInTargetCurrency: 0,
      taxInTargetCurrency: 0,
    },
  };
};

export default getIncludedAllowance;
