import getEnv from '@settings/getEnv';

import { ConsoleLoggerFactory } from './ConsoleLoggerFactory';
import { LoggerFactory } from './Logger';

export const loggerFactory: { (): LoggerFactory } = () => {
  const { REACT_APP_DEBUG } = getEnv();
  if (REACT_APP_DEBUG === 'false') {
    return new ConsoleLoggerFactory(false);
  }
  return new ConsoleLoggerFactory(true);
};
