import {ServiceFieldType} from "@onlog-public/additional-services-types";
import {
  optionLoaderProcessor,
  OptionsCache,
  OptionsLoader
} from "@pages/AdditionalServices/containers/services/optionsLoader/types";

/**
 * BaseOptionsLoader реализует базовый сервис загрузки данных для
 * опций полей дополнительных услуг.
 */
export class BaseOptionsLoader implements OptionsLoader {
  protected processors: optionLoaderProcessor[]

  /**
   * Конструктор сервиса
   * @param processors
   */
  constructor(processors: optionLoaderProcessor[]) {
    this.processors = processors
  }

  /**
   * LoadOptionsCache выполняет загрузку кэша для поля переданного типа.
   * В качестве аргумента принимает тип поля и идентификаторы для загрузки.
   *
   * @param type
   * @param id
   */
  async LoadOptionsCache(type: ServiceFieldType, id?: string[]): Promise<OptionsCache<any>> {
    const processor = this.processors.find(p => p.GetType() === type)
    if (!processor) {
      return {isPartialLoading: true, cache: {}}
    }

    return await processor.LoadOptionsCache(id)
  }
}