import {GraphQLQuery} from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

import {FilterData, RouteCalculationParams, SortData} from './interfaces';

export class CustomCargoRoutesListQuery implements GraphQLQuery<null> {
  readonly query: any;

  readonly variables: null;

    constructor(
      params: RouteCalculationParams,
      isArchive: boolean,
      filterParameters: Partial<FilterData> | undefined,
      sort: SortData
    ) {
      this.variables = null;

      const customCargoParameters: string[] =
        params.customCargoParameters?.customCargoParameters.map((cargo) => {
          const type =
            cargo.parametersCargoCalcType === 'base'
              ? cargo.parametersType
              : cargo.parametersCargoCalcType;
          return `{
              parametersType: ${type},
              baseParameters: {
                quantity: ${cargo.baseParameters.quantity},
                length: ${cargo.baseParameters.length},
                width: ${cargo.baseParameters.width},
                height: ${cargo.baseParameters.height},
                weight: ${cargo.baseParameters.weight}
              },
              palletParameters:{
                type: ${cargo.palletParameters.type}
                quantity: ${cargo.palletParameters.quantity}
                normativeHeightOfStacking: ${cargo.palletParameters.normativeHeightOfStacking}
                weight: ${cargo.palletParameters.weight}
              },
              volumeParameters:{
                volume: ${cargo.volumeParameters.volume}
                weight: ${cargo.volumeParameters.weight}
              }
            }`;
        }) || [];

      const toLocationType =
        params.endLocation?.type === 'terminal' && params.endLocation?.symbolCode === 'ANY'
          ? 'location'
          : params.endLocation?.type;
      const fromLocationType =
        params.startLocation?.type === 'terminal' && params.startLocation?.symbolCode === 'ANY'
          ? 'location'
          : params.startLocation?.type;
      const customCargoParametersQuery = `{
            routePoints: {
               from: {${fromLocationType}: ${params.startLocation?.id}},
               to: {${toLocationType}: ${params.endLocation?.id}}
            },
            customCargoParameters: [
              ${customCargoParameters.join(',')}
            ],   
            date: "${params.startExpeditionDate?.toISOString()}",
            startTransportingConditionId: ${params.startTransportingCondition},
            endTransportingConditionId: ${params.endTransportingCondition},
            isDangerousCargo: ${params.customCargoParameters?.isDangerousCargo ? 'true' : 'false'},
            isArchiveCalculation: ${isArchive ? 'true' : 'false'},
            targetCurrencyId: ${params.customCargoParameters?.targetCurrencyId},
            isNeedPrekeridge: ${params.isNeedPrekeridge ? 'true' : 'false'},
            requiredTransportingTypes: [${params.requiredTransportingTypes.join(',')}], 
        }`;

      let filterData: string | undefined;
      if (filterParameters) {
        filterData = `{
                ${
          filterParameters.carriers
            ? `carriers: [${filterParameters.carriers.join(',')}],`
            : ''
        }
                ${
          filterParameters.contractors
            ? `contractors: [${filterParameters.contractors.join(',')}],`
            : ``
        }
                ${
          filterParameters.deliveryTime &&
          (filterParameters.deliveryTime.min > 0 || filterParameters.deliveryTime.max > 0)
            ? `deliveryTime: {
                  ${
              filterParameters.deliveryTime.max > 0
                ? `max: ${filterParameters.deliveryTime.max}`
                : ``
            }
                  ${
              filterParameters.deliveryTime.min > 0
                ? `min: ${filterParameters.deliveryTime.min}`
                : ``
            }
                },`
            : ``
        }
                ${
          filterParameters.price &&
          (filterParameters.price.min > 0 || filterParameters.price.max > 0)
            ? `price: {
                  ${filterParameters.price.max > 0 ? `max: ${filterParameters.price.max}` : ``}
                  ${filterParameters.price.min > 0 ? `min: ${filterParameters.price.min}` : ``}
                },`
            : ``
        }
                ${
          filterParameters.transportingTypes
            ? `transportingTypes: [${filterParameters.transportingTypes.join(',')}],`
            : ``
        }
                ${
          filterParameters.terminals
            ? `terminals: [${filterParameters.terminals.join(',')}],`
            : ``
        }          
                ${
          filterParameters.startTerminals
            ? `startTerminals: [${filterParameters.startTerminals.join(',')}],`
            : ``
        }      
                ${
          filterParameters.endTerminals
            ? `endTerminals: [${filterParameters.endTerminals.join(',')}],`
            : ``
        }
                isSingleShoulderRoutesOnly: ${
          filterParameters.isSingleShoulderRoutesOnly ? 'true' : 'false'
        },
                isNeedPrekeridgeRoutes: ${
          filterParameters.isNeedPrekeridgeRoutes ? 'true' : 'false'
        },
		            isNeedRegularRoutes: ${filterParameters.isNeedRegularRoutes ? 'true' : 'false'},
            }`;
      }

      this.query = gql`
            query __CUSTOM_CARGO_ROUTES_LIST_QUERY__ {
                data: customCargoRoutesList(
                    cargoParameters: ${customCargoParametersQuery}
                    sort: {
                        by: ${sort.by}
                        direction: ${sort.direction}
                    }
                    ${filterData ? `filter: ${filterData}` : ''}
                    limit: ${params.limit}
                    offset: ${params.offset}
                ) {
                    total
                    isNotCompleteResult
                    minPrice
                    minTime
                    groups {
                        routes {
                            route {
                                steps {
                                    endTerminal {
                                        default_abbreviation
                                        default_name
                                        id
                                        localized_abbreviations
                                        localized_names
                                        location_id
                                        files
                                        symbolCode: symbol_code
                                    }
                                    endTerminalAllowances {
                                        allowanceOffer {
                                            allowance {
                                              allowance_group
                                            }
                                            allowance_id
                                            is_invoice_allowance
                                            id
                                            offer_conditions
                                        }
                                        allowanceOfferCondition {
                                            currency_id
                                            group_num
                                            id
                                            information_price
                                            is_fixed_price
                                            is_max_value_not_limited
                                            is_min_value_not_limited
                                            is_tax_included_in_price
                                            max_value
                                            min_value
                                            minimal_payment_price
                                            price
                                            tax_id
                                            unit_id
                                        }
                                        calculatedPrice {
                                            basePrice
                                            computedAmount
                                            conversionFee
                                            fullPriceInTargetCurrency
                                            priceInTargetCurrency
                                            taxInTargetCurrency
                                        }
                                    }
                                    endTerminalCalculatedPrice {
                                        basePrice
                                        computedAmount
                                        conversionFee
                                        fullPriceInTargetCurrency
                                        priceInTargetCurrency
                                        taxInTargetCurrency
                                    }
                                    endTerminalLoadingUnloadingOffer {
                                        allowance_offers
                                        id
                                        is_loading_to_unknown_transport
                                        is_unloading_from_unknown_transport
                                        loading_shoulder_types
                                        offer_conditions
                                        service_type
                                        unloading_shoulder_types
                                    }
                                    endTerminalOffer {
                                        cargo_type_group
                                        containers
                                        delivery_modes
                                        id
                                        loading_offers
                                        terminal_id
                                    }
                                    endTerminalPrice {
                                        currency_id
                                        group_num
                                        id
                                        information_price
                                        is_fixed_price
                                        is_max_value_not_limited
                                        is_min_value_not_limited
                                        is_tax_included_in_price
                                        max_value
                                        min_value
                                        minimal_payment_price
                                        price
                                        tax_id
                                        unit_id
                                    }
                                    startTerminal {
                                        default_abbreviation
                                        default_name
                                        id
                                        localized_abbreviations
                                        localized_names
                                        location_id
                                        files
                                        symbolCode: symbol_code
                                    }
                                    startTerminalAllowances {
                                        allowanceOffer {
                                            allowance {
                                              allowance_group
                                            }
                                            allowance_id
                                            is_invoice_allowance
                                            id
                                            offer_conditions
                                        }
                                        allowanceOfferCondition {
                                            currency_id
                                            group_num
                                            id
                                            information_price
                                            is_fixed_price
                                            is_max_value_not_limited
                                            is_min_value_not_limited
                                            is_tax_included_in_price
                                            max_value
                                            min_value
                                            minimal_payment_price
                                            price
                                            tax_id
                                            unit_id
                                        }
                                        calculatedPrice {
                                            basePrice
                                            computedAmount
                                            conversionFee
                                            fullPriceInTargetCurrency
                                            priceInTargetCurrency
                                            taxInTargetCurrency
                                        }
                                    }
                                    startTerminalCalculatedPrice {
                                        basePrice
                                        computedAmount
                                        conversionFee
                                        fullPriceInTargetCurrency
                                        priceInTargetCurrency
                                        taxInTargetCurrency
                                    }
                                    startTerminalLoadingUnloadingOffer {
                                        allowance_offers
                                        id
                                        is_loading_to_unknown_transport
                                        is_unloading_from_unknown_transport
                                        loading_shoulder_types
                                        offer_conditions
                                        service_type
                                        unloading_shoulder_types
                                    }
                                    startTerminalOffer {
                                        cargo_type_group
                                        containers
                                        delivery_modes
                                        id
                                        loading_offers
                                        terminal_id
                                    }
                                    startTerminalPrice {
                                        currency_id
                                        group_num
                                        id
                                        information_price
                                        is_fixed_price
                                        is_max_value_not_limited
                                        is_min_value_not_limited
                                        is_tax_included_in_price
                                        max_value
                                        min_value
                                        minimal_payment_price
                                        price
                                        tax_id
                                        unit_id
                                    }
                                    shoulder {
                                        carrier_id
                                        contractor_id
                                        distance
                                        distance_unit
                                        from_location_ids
                                        from_terminal_ids
                                        id
                                        shoulder_type
                                        to_location_ids
                                        to_terminal_ids
                                        shoulderSteps {
                                            id
                                            position
                                            transportType {
                                              id
                                              default_name
                                              localized_names
                                              transporting_default_name
                                              transporting_names
                                            }
                                            startTerminal {
                                              default_abbreviation
                                              default_name
                                              id
                                              localized_abbreviations
                                              localized_names
                                              location_id
                                              files
                                              symbolCode: symbol_code
                                            }
                                            endTerminal {
                                              default_abbreviation
                                              default_name
                                              id
                                              localized_abbreviations
                                              localized_names
                                              location_id
                                              files
                                              symbolCode: symbol_code
                                            }
                                          }
                                    }
                                    shoulderAllowances {
                                        allowanceOffer {
                                            allowance {
                                              allowance_group
                                            }
                                            allowance_id
                                            is_invoice_allowance
                                            id
                                            offer_conditions
                                        }
                                        allowanceOfferCondition {
                                            currency_id
                                            group_num
                                            id
                                            information_price
                                            is_fixed_price
                                            is_max_value_not_limited
                                            is_min_value_not_limited
                                            is_tax_included_in_price
                                            max_value
                                            min_value
                                            minimal_payment_price
                                            price
                                            tax_id
                                            unit_id
                                        }
                                        calculatedPrice {
                                            basePrice
                                            computedAmount
                                            conversionFee
                                            fullPriceInTargetCurrency
                                            priceInTargetCurrency
                                            taxInTargetCurrency
                                        }
                                    }
                                    shoulderOffer {
                                        active_from
                                        active_to
                                        allowance_offers
                                        cargo_type_group
                                        container_affiliation_id
                                        container_nominal_weight
                                        containers
                                        delivery_modes
                                        delivery_time
                                        free_time_for_container_usage_on_start_terminal
                                        free_time_for_container_usage_on_end_terminal
                                        id
                                        is_danger_cargo_allowed
                                        is_empty_container_collecting_included
                                        is_empty_container_returning_included
                                        loading_condition_id
                                        offer_conditions
                                        shoulder_id
                                        unloading_condition_id
                                    }
                                    shoulderOfferCalculatedPrice {
                                        basePrice
                                        computedAmount
                                        conversionFee
                                        fullPriceInTargetCurrency
                                        priceInTargetCurrency
                                        taxInTargetCurrency
                                    }
                                    shoulderOfferPriceCondition {
                                        currency {
                                          course
                                          code
                                        }
                                        currency_id
                                        group_num
                                        id
                                        information_price
                                        is_fixed_price
                                        is_max_value_not_limited
                                        is_min_value_not_limited
                                        is_tax_included_in_price
                                        max_value
                                        min_value
                                        minimal_payment_price
                                        price
                                        tax_id
                                        unit_id
                                    }
                                    stepNumber
                                }
                                routeId
                                fullPrice
                            }
                            customCargoParametersId
                        }
                        fullPrice
                        routeToken
                        deliveryTime
                    }
                    cargoParameters {
                        customCargoParameters {
                            id
                            parametersType
                            baseParameters {
                                height
                                length
                                width
                                weight
                                quantity
                            }
                            palletParameters {
                                normativeHeightOfStacking
                                type
                                quantity
                                weight
                            }
                            volumeParameters {
                                volume
                                weight
                            }
                        }
                        endTransportingConditionId
                        startTransportingConditionId
                    }
                }
            }
        `;
    }
}
