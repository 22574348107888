import {ServiceFieldType, ServiceField} from "@onlog-public/additional-services-types";
import {
  optionsGeneratorProcessor,
  SelectOption
} from "@pages/AdditionalServices/containers/services/optionsGenerator/types";
import {OptionsCache} from "../optionsLoader/types";
import {CurrencyData} from "@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery";

/**
 * CurrencyProcessor реализует процессор генерации опций валют
 */
export class CurrencyProcessor implements optionsGeneratorProcessor {
  /**
   * getType возвращает тип поля, для которого реализован процессор
   */
  getType(): ServiceFieldType {
    return "currency"
  }

  /**
   * getOptions генерирует опции для селектора поля дополнительных услуг
   * @param field
   * @param _
   * @param cache
   */
  getOptions(field: ServiceField, _: string, cache: OptionsCache<any>): SelectOption[] {
    const currencyData: CurrencyData[] = field.handbook_limitations.length > 0
      ? field.handbook_limitations.map(id => cache.cache[id])
        .filter(v => !!v)
      : Object.values(cache.cache)

    return currencyData.map(c => ({
      value: parseInt(c.id),
      label: c.code + (c.country.length > 0 ? ` (${c.country})` : ``),
    }))
  }
}