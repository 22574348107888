import { GraphQLQuery } from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

import { GenerateShortLinkMutationParams } from '../interface';

/**
 * Агрегирующий запрос сущностей директорий дополнительных услуг
 */
export class GenerateShortLinkMutation implements GraphQLQuery<GenerateShortLinkMutationParams> {
  readonly query: any;

  readonly variables: GenerateShortLinkMutationParams;

  constructor(params: GenerateShortLinkMutationParams) {
    this.variables = {
      ...params,
    };
    this.query = gql`
      mutation GenerateShortLinkMutation($page: String!, $data: String!) {
        createShortLink(page: $page, data: $data)
      }
    `;
  }
}
