import { GraphQLQuery } from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

import { LocalizedMessage } from '../../localizedMessagesService/interfaces';

export interface StartTransportingCondition {
  id: string;
  default_name: string;
  code: string;
  is_prekeridge_available: boolean;
  localized_names: string[];
  localized_names_data: LocalizedMessage[];
}

export interface StartTransportingConditionsQueryResponse {
  items: StartTransportingCondition[];
}

/**
 * Запрос загрузки данных по условиям начала перевозки
 */
export class StartTransportingConditionsQuery implements GraphQLQuery<null> {
  readonly query: any;

  readonly variables: null;

  constructor(ids?: any[]) {
    this.variables = null;
    this.query = gql`
            query LoadDeliveryModes {
                items: transport_start_transporting_condition_list(
                    limit: 1000000
                    ${
                      ids && ids.length > 0
                        ? `where:{id:{_in: [${ids.map((id) => `"${id}"`).join(',')}]}}`
                        : ``
                    }
                    order:{by:id, direction: desc}
                ) {
                    id
                    default_name
                    code
                    is_prekeridge_available
                    localized_names
                }
            }
        `;
  }
}
