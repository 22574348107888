import {
  BasePropertyFilterGenerator
} from "@pages/AdditionalServices/containers/services/propertyFilterGenerator/BasePropertyFilterGenerator";
import {ListProcessor} from "@pages/AdditionalServices/containers/services/propertyFilterGenerator/listProcessor";
import {SwitchProcessor} from "@pages/AdditionalServices/containers/services/propertyFilterGenerator/switchProcessor";
import {NumberProcessor} from "@pages/AdditionalServices/containers/services/propertyFilterGenerator/numberProcessor";

const propertyFilterGenerator = () => new BasePropertyFilterGenerator([
  new ListProcessor("tax"),
  new ListProcessor("location"),
  new ListProcessor("handbook"),
  new ListProcessor("currency"),
  new ListProcessor("consulting_contractor"),
  new ListProcessor("inspection_contractor"),
  new ListProcessor("insurance_contractor"),
  new ListProcessor("customs_contractor"),
  new ListProcessor("certification_contractor"),
  new SwitchProcessor,
  new NumberProcessor,
])

export default propertyFilterGenerator