import {ServicesProperty} from "@onlog-public/additional-services-types";
import {FilterPropertyData, VariantFilter} from "@pages/AdditionalServices/containers/types";
import {
  serviceFilterAndOrderContext$,
  servicePropertyValueCacheContext$
} from "@pages/AdditionalServices/containers/bus";
import {SelectOption} from "@pages/AdditionalServices/containers/services/optionsGenerator/types";
import propertyFilterGenerator from "@pages/AdditionalServices/containers/services/propertyFilterGenerator";
import calculateServiceOrderAndFilter
  from "@pages/AdditionalServices/containers/subscribers/calculateServiceOrderAndFilter";

/**
 * makePropertyFilterData реализует генерацию значений фильтра по
 * свойствам дополнительных услуг.
 *
 * Генерирует фильтр для переданного свойства. Внутри используется
 * строгая типизация для правильной генерации фильтра.
 *
 * @param property
 * @param propertyValues
 * @param propertyOptions
 */
const makePropertyFilterData = (
  property: ServicesProperty,
  propertyValues: number[],
  propertyOptions: SelectOption[],
): FilterPropertyData<any> => {
  return propertyFilterGenerator().Generate(
    property,
    propertyValues,
    propertyOptions,
  )
}

/**
 * makeFilterAndOrderData генерирует данные для фильтра по свойствам дополнительных
 * услуг. Вызывается при загрузке данных услуг.
 * @param globalProperties
 * @param variantProperties
 * @param propertyValues
 * @param isNeedResetServicesOrder
 */
const makeFilterAndOrderData = async (
  globalProperties: ServicesProperty[],
  variantProperties: { [T in string]: ServicesProperty[] },
  propertyValues: { [T in string]: { [K in string]: number[] } },
  isNeedResetServicesOrder: boolean = true,
) => {
  const filterData: { [T in string]: VariantFilter } = {}
  const options = servicePropertyValueCacheContext$.getValue()

  // Собираем все доступные свойства услуг в мапу по ID
  const allProperties: { [T in string]: ServicesProperty } = {}
  const props = [
    ...globalProperties,
    ...Object.values(variantProperties).flat(1)
  ]
  props.map(p => allProperties[p.id] = p)

  // Для каждого варианта генерируем базовые данные по фильтру
  Object.keys(propertyValues).map(variantID => {
    filterData[variantID] = {
      IsSortChanged: false,
      ServicePriority: {},
      ServiceVisibility: {},
      Data: {},
      IsPriceFilterAvailable: false,
      IsSortByProperty: false,
      PriceFilter: {
        Min: 0,
        Max: 0,
        CurrentMin: 0,
        CurrentMax: 0
      },
      PropertyToSort: "",
      SortDirection: "asc"
    }

    // Обходим все свойства, выводимые в варианте и генерируем для
    // каждого данные по фильтрации.
    Object.keys(propertyValues[variantID]).map(propID => {
      const property = allProperties[propID]
      if (!property || !options.Options[variantID]) {
        return
      }

      filterData[variantID].Data[property.id] = makePropertyFilterData(
        property,
        propertyValues[variantID][property.id],
        options.Options[variantID][property.id] ?? [],
      )
    })
  })

  serviceFilterAndOrderContext$.next({
    ...serviceFilterAndOrderContext$.getValue(),
    Variants: filterData,
  })

  calculateServiceOrderAndFilter(isNeedResetServicesOrder)
}

export default makeFilterAndOrderData