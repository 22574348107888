import {ServiceDirectory} from "@onlog-public/additional-services-types";
import {
  ServicesServicePriceCalculationMutationProps
} from "@services/requests/servicesService/servicesServicesService/interface";

/**
 * getTotalPriceForDirectory возвращает полную стоимость услуг в рассчитанном
 * варианте. При расчете исключаются услуги с отрицательной стоимостью.
 *
 * @param directory
 * @param services
 */
const getTotalPriceForDirectory = (
  directory: ServiceDirectory,
  services: { [T in string]: ServicesServicePriceCalculationMutationProps[] },
): number => {
  if (!services[directory.id]) {
    return -1
  }

  const isServiceWithPriceExists = !!services[directory.id].find(
    p => p.result.result.fullPriceInTargetCurrency > -1
  )

  if (!isServiceWithPriceExists) {
    return -1
  }

  return services[directory.id].reduce(
    (v, s) => s.result.result.fullPriceInTargetCurrency == -1
      ? v
      : v + s.result.result.fullPriceInTargetCurrency,
    0,
  )
}

export default getTotalPriceForDirectory