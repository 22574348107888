import { GraphQLQuery } from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

/**
 * Результат выполнения запроса
 */
export interface TerminalByIdQueryResult {
  transport_terminal_list: {
    id: string;
    default_name: string;
    location_id: string;
    localized_names: string[];
    iso: string;
    iso_localization: string[];
    lat: number;
    lng: number;
    files: string[];
  }[];
}

/**
 * Запрос получения терминала по переданному ID
 */
export class TerminalByIdQuery implements GraphQLQuery<{ id: string[] }> {
  readonly query: any;

  readonly variables: { id: string[] };

  constructor(terminalIds: string[]) {
    this.variables = { id: terminalIds };
    this.query = gql`
            query SearchTerminal($id:[ID]) {
              transport_terminal_list(where:{id:{_in: $id}}, limit: ${terminalIds.length}) {
                id
                default_name
                location_id
                localized_names
                iso_localization: localized_abbreviations
                iso: symbol_code
                lat: latitude
                lng: longitude
                files
              }
            }
        `;
  }
}
