import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import SvgIcon from '@mui/material/SvgIcon';
import CargoSpecialConditionalStyledContainer from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/сargoSpecialConditional/StyledComponents';
import CargoSpecialConditionalProps from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/сargoSpecialConditional/types';
import { isEqualsForMemo } from '@settings/services/types';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

const isEquals: isEqualsForMemo<CargoSpecialConditionalProps> = (prevProps, nextProps) =>
  JSON.stringify([prevProps.isNeedCustomCondition, prevProps.isDangerousCargo]) ===
  JSON.stringify([nextProps.isNeedCustomCondition, nextProps.isDangerousCargo]);

/**
 * Компонент включения и конфигурации особых условий транспортировки
 * @param props
 */
const CargoSpecialConditional = (props: CargoSpecialConditionalProps) => {
  const {
    isDangerousCargo,
    isNeedCustomCondition,
    onToggleCustomCondition,
    onChangeDangerousCargo,
  } = props;
  const { t } = useTranslation('DeliveryForm');

  return (
    <CargoSpecialConditionalStyledContainer>
      <FormControlLabel
        label={t('DeliveryForm:SpecialConditions')}
        className={'conditional-title'}
        control={
          <Checkbox
            size={'small'}
            checked={isNeedCustomCondition}
            onChange={onToggleCustomCondition}
          />
        }
      />
      {isNeedCustomCondition && (
        <div className={'button-wrapper'}>
          <Button
            variant={isDangerousCargo ? 'contained' : 'outlined'}
            color={isDangerousCargo ? 'error' : 'inherit'}
            size={'small'}
            sx={{
              lineHeight: '26px',
              fontSize: 14,
              height: '40px',
              letterSpacing: '0.28px',
              border: `1px solid ${isDangerousCargo ? 'transparent' : '#919EAB'}`,
            }}
            onClick={() => onChangeDangerousCargo(!isDangerousCargo)}
            startIcon={
              <SvgIcon viewBox={'0 0 20 20'}>
                <path
                  fill={isDangerousCargo ? '#ffffff' : '#9199AA'}
                  d="M17.6676 14.1139L12.0155 3.72401C11.1074 2.19523 8.89378 2.1932 7.98447 3.72401L2.33272 14.1139C1.4044 15.676 2.52831 17.6542 4.34778 17.6542H15.652C17.47 17.6542 18.5959 15.6776 17.6676 14.1139ZM10 15.7792C9.48319 15.7792 9.0625 15.3585 9.0625 14.8417C9.0625 14.3249 9.48319 13.9042 10 13.9042C10.5168 13.9042 10.9375 14.3249 10.9375 14.8417C10.9375 15.3585 10.5168 15.7792 10 15.7792ZM10.9375 12.0292C10.9375 12.546 10.5168 12.9667 10 12.9667C9.48319 12.9667 9.0625 12.546 9.0625 12.0292V7.34173C9.0625 6.82492 9.48319 6.40423 10 6.40423C10.5168 6.40423 10.9375 6.82492 10.9375 7.34173V12.0292Z"
                />
              </SvgIcon>
            }
          >
            {t('DeliveryForm:DangerousGoods')}
          </Button>
        </div>
      )}
    </CargoSpecialConditionalStyledContainer>
  );
};

export default memo(CargoSpecialConditional, isEquals);
