import { LoadTaxesParams } from '@services/requests/taxService/interface';
import { GraphQLQuery } from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

export class LoadListTax implements GraphQLQuery<LoadTaxesParams> {
  readonly query: any;

  readonly variables: LoadTaxesParams;

  constructor(params: LoadTaxesParams) {
    this.variables = {
      ...params,
    };

    this.query = gql`
      query ($limit: Int = 30, $offset: Int = 0, $where: tax_query_where_object) {
        tax_list(
          limit: $limit
          offset: $offset
          where: $where
          order: [{ by: id, direction: desc, priority: 1 }]
        ) {
          default_name
          localized_names
          amount
          code
          is_default
          id
        }
      }
    `;
  }
}
