import { GraphQLQuery } from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

import { RouteCalculationParams } from './interfaces';

// Запрос агрегации данных по контейнерной перевозке
export class ContainerRoutesAggregationQuery implements GraphQLQuery<null> {
  readonly query: any;

  readonly variables: null;

  constructor(params: RouteCalculationParams, isArchive: boolean) {
    this.variables = null;
    const containerParameters: string[] =
      params.containerParameters?.containerParameters.map(
        (container) => `{
                containerId: ${container.containerId},
                containerQuantity: ${container.containerQuantity},
                eachContainerWeight: ${container.eachContainerWeight},
            }`
      ) || [];

    const toLocationType =
      params.endLocation?.type === 'terminal' && params.endLocation?.symbolCode === 'ANY'
        ? 'location'
        : params.endLocation?.type;
    const fromLocationType =
      params.startLocation?.type === 'terminal' && params.startLocation?.symbolCode === 'ANY'
        ? 'location'
        : params.startLocation?.type;
    this.query = gql`
            query __CONTAINER_ROUTES_AGGREGATION_QUERY_ {
                data: containerRoutesAggregation(
                    routePoints: {
                        from: {${fromLocationType}: ${params.startLocation?.id}},
                    to: {${toLocationType}: ${params.endLocation?.id}}
                    },
                    containerParameters: [
                        ${containerParameters.join(',')}
                    ],
                    date: "${params.startExpeditionDate?.toISOString()}",
                    startTransportingConditionId: ${params.startTransportingCondition},
                    endTransportingConditionId: ${params.endTransportingCondition},
                    isNeedContainerRent: ${params.isNeedContainerRent ? 'true' : 'false'},
                    isDangerousCargo: ${
                      params.containerParameters?.isDangerousCargo ? 'true' : 'false'
                    },
                    isArchiveCalculation: ${isArchive ? 'true' : 'false'},
                    targetCurrencyId: ${params.containerParameters?.targetCurrencyId},
                    isNeedPrekeridge: ${params.isNeedPrekeridge ? 'true' : 'false'},   
                    requiredTransportingTypes: [${params.requiredTransportingTypes.join(
                      ','
                    )}],                         
                ) {
                    carriers
                    contractors
                    deliveryTime {
                        max
                        min
                    }
                    price {
                        max
                        min
                    }
                    transportingTypes
                    terminals
                    startTerminals
                    endTerminals
                }
            }
  `;
  }
}
