import TerminalsInfoWithFullIncludedAllowance from '@components/pages/Products/components/RouteProduct/components/TerminalsInfo/components/TerminalsInfoWithFullIncludedAllowance';
import { getFullPriceFromTerminals } from '@components/pages/Products/components/RouteProduct/components/TerminalsInfo/helpers';
import getIncludedAllowance from '@components/pages/Products/components/RouteProduct/components/TerminalsInfo/mocks';
import { WithRouteProductContextData } from '@components/pages/Products/components/RouteProduct/types';
import WithRouteProductContext from '@components/pages/Products/components/RouteProduct/WithRouteProductContext';
import { TaxNameProps } from '@helpers/getTaxNames';
import { MappedTerminalData } from '@helpers/getTerminalsFromStep';
import TerminalsInfoWithAllowance from '@pages/Basket/OrderList/components/OfferDetail/Products/RouteProduct/TerminalsInfo/components/TerminalsInfoWithAllowances';
import { RowProps } from '@services/collectors/ProductService/ShoulderItemDetailsDTO';
import { CalculatedAllowanceOffer } from '@services/requests/orderService/types';
import { BranchItem } from '@services/requests/routeCalculator/locationsBranchSearchService/interfaces';
import { AllowanceData } from '@services/requests/searchLoaders/allowanceLoader/AllowanceLoaderQuery';
import { CurrencyData } from '@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery';
import { getLocalization } from '@settings/services/helpers/getLocalization';
import React from 'react';
import useBasket from '@pages/Basket/useBasket';

type TerminalsGroupInfoProps = WithRouteProductContextData<{
  terminalGroup: MappedTerminalData[];
  terminals: BranchItem[];
  taxNames: TaxNameProps[];
  endTransportingConditionId: number;
  startTransportingConditionId: number;
}>;

/**
 * Компонент выводит данные по одному терминалу. На вход принимает все
 * расходы, которые были по этому терминалу.
 */
const TerminalsGroupInfo = WithRouteProductContext((props: TerminalsGroupInfoProps) => {
  const {
    currencyRoute,
    terminalGroup,
    terminals,
	primaryLanguageId,
    realTimeBaseData: { allowancesData = [] },
    taxNames,
    endTransportingConditionId,
    startTransportingConditionId,
  } = props;

  const [order] = useBasket().useOrder().useState();
//   const langCode = getLocalizationById(order.language_id).countryCode;

  const [terminal] = terminalGroup;
  const totalPrice = getFullPriceFromTerminals(terminalGroup);
  const firstTerminal = terminalGroup.find((t) => t.isFirst);
  const lastTerminal = terminalGroup.find((t) => t.isLast);

  let primaryLangId = primaryLanguageId;

  if (order) {
    primaryLangId = order.language_id;
  }

  const allowances = [
    ...terminalGroup
      .map((t) => {
        let allowancesItems = [...t.terminalAllowances];
        if (allowancesItems.length === 0 && !firstTerminal && !lastTerminal) {
          allowancesItems = [getIncludedAllowance(t.loadingType)];
        }
        return allowancesItems;
      })
      .flat(),
  ];

  const allowancesRowsData: RowProps[] = [];

  allowances.forEach((item: CalculatedAllowanceOffer) => {
    if (!item.allowanceOffer || item.allowanceType) {
      return null;
    }

    const baseCurrency = props.productData.baseData.currencies.find(
      (c) => c.id === item.allowanceOfferCondition.currency_id
    ) as CurrencyData;

    const allowanceInstant = allowancesData.find(
      (a) => a.id === item.allowanceOffer.allowance_id
    ) as AllowanceData;

    const allowanceName = getLocalization(
      allowanceInstant.default_name,
      allowanceInstant.localized_names_data,
      primaryLangId
    );

    if (baseCurrency) {
      allowancesRowsData.push({
        price: item.calculatedPrice,
        name: allowanceName,
        baseCurrency,
        taxName: taxNames.length
          ? taxNames.find((i) => +i.taxId === item.allowanceOfferCondition.tax_id)?.localizedName ||
            ''
          : '',
        targetCurrency: currencyRoute,
      } as RowProps);
    }
    return null;
  });

  const isIncluded =
    (!!firstTerminal && startTransportingConditionId === 2) ||
    (!!lastTerminal && endTransportingConditionId === 2) ||
    (!firstTerminal && !lastTerminal && terminal.terminalLoadingUnloadingOffer === null);

  if (totalPrice === 0) {
    return (
      <TerminalsInfoWithFullIncludedAllowance
        isIncluded={isIncluded}
        id={terminal.terminal.id}
        branchItem={terminals.find((t) => t.id === terminal.terminal.id)}
        firstTerminal={!!firstTerminal}
        lastTerminal={!!lastTerminal}
      />
    );
  }

  return <TerminalsInfoWithAllowance {...props} allowancesRowsData={allowancesRowsData} />;
});

export default TerminalsGroupInfo;
