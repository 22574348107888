import {ServiceField} from "@onlog-public/additional-services-types";
import {ServiceData, SetUpServiceData} from "@pages/AdditionalServices/containers/methods/basket/types";
import {SelectOption} from "@pages/AdditionalServices/containers/services/optionsGenerator/types";
import getLocalizationByArgs from "@helpers/getLocalizationByArgs";

/**
 * getFieldValue возвращает значение для переданного поля, если оно доступно для
 * вывода.
 * @param langID
 * @param field
 * @param fieldOptions
 * @param fieldValues
 */
const getFieldValue = (
  langID: string,
  field: ServiceField,
  fieldOptions: { [T in string]: SelectOption[] },
  fieldValues: { [T in string]: number },
): SelectOption | undefined => {
  const value = fieldValues[field.code]
  if (!value) {
    return
  }

  switch (field.type) {
    case "number":
      return {value: value, label: value.toLocaleString(), origin: value}
    case "switch":
      return value === 1
        ? {
          value: value,
          label: getLocalizationByArgs(langID, field.placeholder, field.localized_placeholders),
          origin: value
        }
        : undefined
    case "handbook":
    case "tax":
    case "location":
    case "currency":
    case "consulting_contractor":
    case "inspection_contractor":
    case "customs_contractor":
    case "certification_contractor":
    case "insurance_contractor":
      const option = fieldOptions[field.code]?.find(o => o.value === value)
      if (!option) {
        return
      }

      return option
  }
}

/**
 * getFieldValuesFromData формирует список полей услуги для которых есть
 * значения. Так же метод выгружает сами значения для этих полей.
 * Если значение для поля не найдено - поле исключается из выдачи.
 *
 * @param langID
 * @param fields
 * @param setUpService
 * @param otherServices
 */
const getFieldValuesFromData = (
  langID: string,
  fields: ServiceField[],
  setUpService: SetUpServiceData,
  otherServices: ServiceData[] = [],
): { fields: ServiceField[], values: { [T in string]: string } } => {
  const fieldOptions = {...setUpService.ServiceFieldOptions, ...setUpService.GlobalFieldOptions}
  otherServices.map(s => {
    Object.keys(s.ServiceFieldOptions).map(o => {
      if (!fieldOptions[o]) {
        fieldOptions[o] = []
      }

      fieldOptions[o].push(...s.ServiceFieldOptions[o])
    })
  })

  const fieldDisplayValue: { [T in string]: string } = {}
  fields.map(field => {
    if (field.settings.DefaultSettings.IsFieldHidden || field.type === "hidden") {
      return
    }

    const value = getFieldValue(langID, field, fieldOptions, {...setUpService.FieldValues, ...setUpService.VariantFieldValues})
    if (!value || value.label.length === 0) {
      return
    }

    fieldDisplayValue[field.code] = [value.label, value.description ?? ""].filter(s => s.length).join(", ")
  })

  return {
    fields: fields.filter(f => fieldDisplayValue[f.code] !== undefined),
    values: fieldDisplayValue,
  }
}

export default getFieldValuesFromData