import React, {FC} from "react";
import {ServiceProps} from "@pages/AdditionalServices/components/Service/types";
import {useTranslation} from "react-i18next";
import clsx from "clsx";

export type SelectionButtonProps = ServiceProps & {
  className: string
}

const SelectionButton: FC<SelectionButtonProps> = props => {
  const {
    className,
    service,
    selectedServices,
    onSelectService,
    onUnSelectService,
  } = props
  const {t} = useTranslation()

  const isSelected = selectedServices.includes(service.id)
  const buttonText = !isSelected
    ? t('AdditionalService:AddToCartSelection')
    : t('AdditionalService:RemoveFromCartSelection')

  const handleToggle = () => {
    if (isSelected) {
      return onUnSelectService(service)
    }

    onSelectService(service)
  }

  return (
    <div className={className}>
      <div
        className={clsx("a-service-add-to-basket-button", {
          "remove": isSelected,
        })}
        onClick={handleToggle}
      >
        {buttonText}
      </div>
    </div>
  )
}

export default SelectionButton