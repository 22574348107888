import palette from '@assets/theme/palette';
import { styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Pagination from '@mui/material/Pagination';

const RoutesListRoutesGridStyledContainer = styled(Dialog)`
  .close-button {
    position: absolute;
    top: 8px;
    right: 8px;
  }
`;

export const CustomPaginationContainer = styled(Pagination)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  .MuiButtonBase-root {
    border-radius: 5px;
    color: black !important;

    svg {
      fill: black !important;
    }

    &:hover {
      color: white !important;
      background: ${palette.light.green[300]} !important;

      svg {
        fill: white !important;
      }
    }
  }

  .Mui-selected {
    color: black !important;
    transition: 400ms;
    background: #fff !important;
    box-shadow: 0 0 20px 0 rgb(0 5 115 / 7%);
  }
`;

export default RoutesListRoutesGridStyledContainer;
