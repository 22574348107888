import {
  ServicesServicePriceCalculationMutationProps
} from "@services/requests/servicesService/servicesServicesService/interface";
import {ServiceData, SetUpServiceData} from "@pages/AdditionalServices/containers/methods/basket/types";
import {
  serviceContext$,
  servicePropertyValueCacheContext$
} from "@pages/AdditionalServices/containers/bus";
import {
  exportServiceFieldOptions,
} from "@pages/AdditionalServices/containers/additionServicesOptionsContext";
import {getCacheData} from "@pages/AdditionalServices/containers/methods/loadServiceDataCache";

/**
 * makeServiceData сформирует данные услуг, готовые для добавления в корзину.
 * Если передать ЦП вторым параметром, то будут сформированы данные только
 * под переданные ЦП. Если ЦП не переданы, то автоматически генерируются
 * данные для всех услуг в варианте.
 *
 * @param variantID
 * @param partialServices
 */
const makeServiceData = (
  variantID: string,
  partialServices?: ServicesServicePriceCalculationMutationProps[]
): (ServiceData | SetUpServiceData)[] => {
  const state = serviceContext$.getValue()
  const propertiesCache = servicePropertyValueCacheContext$.getValue()

  const allServices = [
    ...state.VariantServices[variantID],
    ...state.VariantServices[variantID].map(s => s.additionServices ?? []).flat(1)
  ]

  const serviceOffers = partialServices ?? state.VariantsCalculationResult[variantID]
  const services: ServiceData[] = serviceOffers.map(offer => {
    const service = allServices.find(
      s => s.id === offer.serviceId
    )

    if (!service) {
      return undefined
    }

    return {
      service: {
        service_id: parseInt(offer.serviceId),
        currency_id: parseInt(offer.currencyId),
        price_offer_result: offer.result,
        is_price_on_request: offer.isPriceByRequest,
      },
      ServiceData: service,
      ServiceDataCache: getCacheData(service, offer),
      ServiceFieldOptions: exportServiceFieldOptions(service.fields, {...state.FieldValues, ...state.VariantFieldValues[variantID]})
    }
  }).filter(s => !!s)

  if (services.length === 0) {
    return []
  }

  const variant = state.Variants.find(v => v.id === variantID)
  if (!variant) {
    return []
  }

  // noinspection UnnecessaryLocalVariableJS
  const setUpService: SetUpServiceData = {
    ...services[0],
    FieldValues: {...state.FieldValues, ...state.VariantFieldValues[variantID]},
    GlobalFieldOptions: exportServiceFieldOptions(
      [...state.FieldsToDisplay, ...variant.fields],
      {...state.FieldValues, ...state.VariantFieldValues[variantID]},
    ),
    IsSetupService: true,
    PropertyOptions: propertiesCache.Options[variantID],
    PropertyValues: state.PropertyValues[variantID],
    ServicesPath: state.ServicesPath,
    SelectedProductUUID: state.SelectedProductUUID,
    TemporaryStore: state.ServiceTemplateStore[variantID],
    Variant: variant,
    VariantFieldValues: state.VariantFieldValues[variantID],
  }

  services[0] = setUpService

  return services
}

export default makeServiceData