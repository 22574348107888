import Actions from './ru/Actions.json';
import AdditionalService from './ru/AdditionalService.json';
import AppSettings from './ru/AppSettings.json';
import BenefitsOfWorking from './ru/BenefitsOfWorking.json';
import BenefitsOfWorkingProfile from './ru/BenefitsOfWorkingProfile.json';
import CartAsideBlock from './ru/CartAsideBlock.json';
import CartComponent from './ru/CartComponent.json';
import CartSummary from './ru/CartSummary.json';
import Common from './ru/Common.json';
import CustomsCalculator from './ru/CustomsCalculator.json';
import CustomsPayments from './ru/CustomsPayments.json';
import DeliveryForm from './ru/DeliveryForm.json';
import DeliveryItem from './ru/DeliveryItem.json';
import EmptyStyleSelect from './ru/EmptyStyleSelect.json';
import FilterComponent from './ru/FilterComponent.json';
import FooterBottomBar from './ru/FooterBottomBar.json';
import LocationSelector from './ru/LocationSelector.json';
import OfferContainers from './ru/OfferContainers.json';
import OfferDetail from './ru/OfferDetail.json';
import OfferTipsText from './ru/OfferTipsText.json';
import OrderForm from './ru/OrderForm.json';
import OrderList from './ru/OrderList.json';
import PageNames from './ru/PageNames.json';
import RoutesCalculationWidget from './ru/RoutesCalculationWidget.json';
import RoutesListComponent from './ru/RoutesListComponent.json';
import SortComponent from './ru/SortComponent.json';
import TubsContainer from './ru/TubsContainer.json';
import TYOrder from './ru/TYOrder.json';

export const ru = {
  Actions,
  AdditionalService,
  AppSettings,
  BenefitsOfWorking,
  BenefitsOfWorkingProfile,
  CartAsideBlock,
  CartComponent,
  CartSummary,
  Common,
  CustomsCalculator,
  CustomsPayments,
  DeliveryForm,
  DeliveryItem,
  EmptyStyleSelect,
  FilterComponent,
  FooterBottomBar,
  LocationSelector,
  OfferContainers,
  OfferDetail,
  OfferTipsText,
  OrderForm,
  OrderList,
  PageNames,
  RoutesCalculationWidget,
  RoutesListComponent,
  SortComponent,
  TubsContainer,
  TYOrder,
};
