import {OrderProduct} from "@services/requests/orderService/orderProductsLoader/interface";
import restoreServiceData from "@pages/AdditionalServices/containers/methods/basket/restoreServiceData";
import {CurrencyData} from "@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery";

export type ServiceSummary = {
  Currencies: { [T in string]: number }
  Price: number
  Conversion: number
  Tax: number
  Total: number
  TargetCurrency: string
}

/**
 * getSummaryForServices вычисляет итоговую стоимость услуг, со сбором
 * всех валютных расходов.
 * @param services
 */
const getSummaryForServices = (
  services: OrderProduct[]
): ServiceSummary => {
  const result: ServiceSummary = {
    Currencies: {},
    Price: 0,
    Conversion: 0,
    Tax: 0,
    Total: 0,
    TargetCurrency: ""
  }

  services.map(s => restoreServiceData(s))
    .filter(d => !!d)
    .map(d => [d.setUpService, ...d.otherServices])
    .flat(1)
    .map(r => {
      if ((r.service?.price_offer_result?.result?.fullPriceInTargetCurrency ?? -1) <= 0) {
        return
      }

      const targetCurrency: CurrencyData = r.ServiceDataCache.currency.cache[String(r.service.currency_id ?? "")]
      const sourceCurrency: CurrencyData = r.ServiceDataCache.currency.cache[String(r.service.price_offer_result?.data?.currency_id ?? "")]

      if (!targetCurrency || !sourceCurrency) {
        return
      }

      if (!result.Currencies[sourceCurrency.code]) {
        result.Currencies[sourceCurrency.code] = 0
      }

      result.Currencies[sourceCurrency.code] += r.service.price_offer_result.result.basePrice
      result.Conversion += r.service.price_offer_result.result.conversionFee
      result.Tax += r.service.price_offer_result.result.taxInTargetCurrency
      result.Total += r.service.price_offer_result.result.fullPriceInTargetCurrency
      result.Price += r.service.price_offer_result.result.priceInTargetCurrency
      result.TargetCurrency = targetCurrency.code
    })

  return result
}

export default getSummaryForServices