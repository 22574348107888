import {ServiceFieldType} from "@onlog-public/additional-services-types";
import {OptionsCache} from "../optionsLoader/types";
import {
  _optionsCacheCheckerAndLoaderProcessor
} from "@pages/AdditionalServices/containers/services/optionsCacheCheckerAndLoader/types";
import optionsLoader from "@pages/AdditionalServices/containers/services/optionsLoader";

/**
 * HandbookProcessor реализует процессор для заполнения опций доп. справочников.
 */
export class HandbookProcessor implements _optionsCacheCheckerAndLoaderProcessor {
  /**
   * Возвращает тип поля, для которого реализован процессор.
   */
  GetType(): ServiceFieldType {
    return "handbook"
  }

  /**
   * LoadCacheForNewData выполняет проверку, есть ли данные в кэше
   * и выполняет загрузку недостающих данных.
   *
   * @param _
   * @param existInStateKeys
   * @param loadingID
   */
  async LoadCacheForNewData(
    _: boolean,
    existInStateKeys: string[],
    loadingID?: string[],
  ): Promise<OptionsCache<any>> {
    if (!loadingID) {
      return {isPartialLoading: true, cache: {}}
    }

    const needLoad = loadingID.filter(c => !existInStateKeys.includes(c)) ?? []
    if (needLoad.length === 0) {
      return {isPartialLoading: true, cache: {}}
    }

    return optionsLoader().LoadOptionsCache("handbook", needLoad)
  }
}