import React, {FC} from "react";
import {FilterPropertyData, VariantFilter} from "@pages/AdditionalServices/containers/types";
import {ListingTemplateSettings} from "@onlog-public/additional-services-types";
import {Tabs} from "@material-ui/core";
import SortTabByProperty
  from "@pages/AdditionalServices/components/ServiceDirectory/ListTypeTemplate/SortTabByProperty";
import SortTabByPrice from "@pages/AdditionalServices/components/ServiceDirectory/ListTypeTemplate/SortTabByPrice";

export interface SortContainerProps {
  filter: VariantFilter
  templateSettings: ListingTemplateSettings
  onChangeFilter: (c: { (state: VariantFilter): VariantFilter }) => void
}

const SortContainer: FC<SortContainerProps> = props => {
  const {
    filter,
    templateSettings,
    onChangeFilter,
  } = props

  const sortProperties = Object.values(filter.Data)
    .filter(p => p.Property.type === "number")
    .filter(p => p.Property.settings.Sort.IsSortEnable)

  const priceSortPriority = templateSettings.PriceDirectionPriority
  const leftProps = sortProperties
    .filter(p => p.Property.settings.Sort.DirectionPosition < priceSortPriority)
    .sort((a, b) => {
      const aPriority = a.Property.settings.Sort.DirectionPosition
      const bPriority = b.Property.settings.Sort.DirectionPosition

      return aPriority > bPriority ? 1 : -1
    })

  const rightProps = sortProperties
    .filter(p => p.Property.settings.Sort.DirectionPosition >= priceSortPriority)
    .sort((a, b) => {
      const aPriority = a.Property.settings.Sort.DirectionPosition
      const bPriority = b.Property.settings.Sort.DirectionPosition

      return aPriority > bPriority ? 1 : -1
    })

  const leftSelected = leftProps.findIndex(p => p.Property.id === filter.PropertyToSort)
  const rightSelected = rightProps.findIndex(p => p.Property.id === filter.PropertyToSort)

  const positionSwap = templateSettings.IsPriceDirectionAvailable ? leftProps.length : leftProps.length - 1
  const selectedVariant = filter.IsSortChanged
    ? false
    : !filter.IsSortByProperty
      ? positionSwap
      : leftSelected !== -1 ? leftSelected
        : rightSelected !== -1
          ? rightSelected + positionSwap + 1
          : positionSwap

  const handleSelectProperty = (property: FilterPropertyData<"number">) => {
    if (filter.IsSortChanged) {
      return onChangeFilter(c => ({
        ...c,
        IsSortByProperty: true,
        PropertyToSort: property.Property.id,
        SortDirection: property.Property.settings.Sort.AvailableDirection === "desc" ? "desc" : "asc",
      }))
    }

    if (!filter.IsSortByProperty || filter.PropertyToSort !== property.Property.id) {
      return onChangeFilter(c => ({
        ...c,
        IsSortByProperty: true,
        PropertyToSort: property.Property.id,
        SortDirection: property.Property.settings.Sort.AvailableDirection === "desc" ? "desc" : "asc",
      }))
    }

    if (filter.PropertyToSort === property.Property.id && property.Property.settings.Sort.AvailableDirection === "both") {
      return onChangeFilter(c => ({
        ...c,
        IsSortByProperty: true,
        PropertyToSort: property.Property.id,
        SortDirection: filter.SortDirection === "desc" ? "asc" : "desc",
      }))
    }
  }

  if (sortProperties.length === 0 && !templateSettings.IsPriceDirectionAvailable) {
    return null
  }

  return (
    <div>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={selectedVariant}
        className="a-service-data-variants a-service-template-list-content--sort"
      >
        {leftProps.map((p, i) => (
          <SortTabByProperty
            key={i}
            position={i}
            property={p}
            isArrowEnabled={templateSettings.IsNeedDisplayDirectionArrow}
            sortDirection={filter.SortDirection}
            isSelected={selectedVariant === i}
            onSelect={() => handleSelectProperty(p)}
          />
        ))}
        {templateSettings.IsPriceDirectionAvailable && (
          <SortTabByPrice
            position={positionSwap}
            templateSettings={templateSettings}
            sortDirection={filter.SortDirection}
            isSelected={selectedVariant === positionSwap}
            onSelect={() => onChangeFilter(c => ({
              ...c,
              IsSortByProperty: false,
              PropertyToSort: "",
              SortDirection: templateSettings.PriceAvailableDirection === "desc"
                ? "desc"
                : templateSettings.PriceAvailableDirection === "asc"
                  ? "asc"
                  : filter.IsSortChanged
                    ? "asc"
                    : filter.IsSortByProperty || filter.SortDirection === "desc"
                      ? "asc"
                      : "desc",
            }))}
          />
        )}
        {rightProps.map((p, i) => (
          <SortTabByProperty
            key={i + positionSwap + 1}
            position={i + positionSwap + 1}
            property={p}
            sortDirection={filter.SortDirection}
            isArrowEnabled={templateSettings.IsNeedDisplayDirectionArrow}
            isSelected={selectedVariant === (i + positionSwap + 1)}
            onSelect={() => handleSelectProperty(p)}
          />
        ))}
      </Tabs>
    </div>
  )
}

export default SortContainer