import {HandbookVersion, ServiceFieldType} from "@onlog-public/additional-services-types";
import {optionsGeneratorProcessor} from "@pages/AdditionalServices/containers/services/propertyOptionsGenerator/types";
import {SelectOption} from "../optionsGenerator/types";
import {OptionsCache} from "../optionsLoader/types";
import getLocalizationByArgs from "@helpers/getLocalizationByArgs";

/**
 * HandbookProcessor реализует процессор для генерации опций для
 * свойств типа "Справочник"
 */
export class HandbookProcessor implements optionsGeneratorProcessor {
  /**
   * getType возвращает тип справочника, для которого реализован генератор
   */
  getType(): ServiceFieldType {
    return "handbook"
  }

  /**
   * getOptions генерирует опции для вывода по переданным идентификаторам
   * и кэшу.
   * @param langID
   * @param items
   * @param handbookID
   * @param cache
   */
  getOptions(langID: string, items: string[], handbookID: string, cache: OptionsCache<any>): SelectOption[] {
    const handbookData = cache.cache[handbookID] as HandbookVersion | undefined
    if (!handbookData) {
      return
    }

    return handbookData.items
      .filter(i => items.includes(i.id))
      .map(d => ({
        value: parseInt(d.id),
        label: getLocalizationByArgs(langID, d.name, d.localized_names),
        origin: d,
      }))
  }
}