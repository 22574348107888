import { swapEvent$ } from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/Locations';
import {
  LocationSelectorItem,
  LocationSelectorItemList,
} from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/LocationSelector/components/LocationSelectorItem/types';
import { locationsBranchService } from '@services/requests/routeCalculator/locationsBranchSearchService';
import { BranchItem } from '@services/requests/routeCalculator/locationsBranchSearchService/interfaces';
import { terminalByLocationLoader } from '@services/requests/routeCalculator/TerminalByLocationLoader';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BehaviorSubject } from 'rxjs';
import { v4 } from 'uuid';

interface UseGetOptions {
  isFrom: boolean;
  location?: BranchItem | undefined;
  primaryLangId: string;
  searchValue: string;
  isConfigFromSearchParams: boolean;
}

// Эта функция удаляет дубликаты из массива объектов BranchItem
const removeDuplicates = (locations: BranchItem[]) => {
  const uniqueIds = new Map<string, BranchItem>();

  locations.forEach((location) => {
    // Используем id и visibleName для определения уникальности
    const identifier = `${location.id}-${location.visibleName}`;
    if (!uniqueIds.has(identifier)) {
      uniqueIds.set(identifier, location);
    }
  });

  return Array.from(uniqueIds.values());
};

const searchLocationsText = async (newSearchNeedle: string, requestId: string) => {
  const preLocations = await locationsBranchService().SearchBranches(newSearchNeedle);

  if (preLocations.length === 0) {
    return {
      locations: [],
      requestId,
    };
  }

  const locationWithParent = preLocations.filter((i) => i.parentId);
  const ids = preLocations.map((l) => l.id);

  const items = await terminalByLocationLoader().Load(ids);

  const searchResult = items.map((l) => ({
    id: l.id,
    type: l.type,
  }));
  const locations = await locationsBranchService().getLocationWithParentTree(
    searchResult,
    await locationsBranchService().loadLocalization(items)
  );

  return {
    locations: removeDuplicates([...locations, ...locationWithParent]),
    requestId,
  };
};

const $lastSearchValue = new BehaviorSubject<string>(v4());

const useGetOptions = (p: UseGetOptions) => {
  const { isFrom, location, searchValue, primaryLangId, isConfigFromSearchParams } = p;
  const { t } = useTranslation(['LocationSelector', 'Common']);
  const storeList = JSON.parse(localStorage.getItem(`${isFrom ? 'from' : 'to'}-items`)) || [];
  const storeDefaultList =
    JSON.parse(localStorage.getItem(`${isFrom ? 'from' : 'to'}-default-items`)) || [];
  const storeNeedRefresh =
    JSON.parse(localStorage.getItem(`${isFrom ? 'from' : 'to'}-needRefresh`)) === '1';
  const [isLoading, setLoadingState] = useState(false);
  const [items, setItems] = useState<LocationSelectorItem[]>(storeList);
  const [defaultItems, setDefaultItems] = useState<LocationSelectorItem[]>(storeDefaultList);
  const [needRefresh, setNeedRefresh] = useState<boolean>(storeNeedRefresh);

  useEffect(() => {
    // TODO проработать лучший вариант по обновлению данных при смене локаций местами
    const s = swapEvent$.subscribe(() => {
      const storeList = JSON.parse(localStorage.getItem(`${isFrom ? 'from' : 'to'}-items`)) || [];
      const storeDefaultList =
        JSON.parse(localStorage.getItem(`${isFrom ? 'from' : 'to'}-default-items`)) || [];
      const storeNeedRefresh =
        JSON.parse(localStorage.getItem(`${isFrom ? 'from' : 'to'}-needRefresh`)) === '1';

      setItems(storeList);
      setDefaultItems(storeDefaultList);
      setNeedRefresh(storeNeedRefresh);
    });

    return () => {
      s.unsubscribe();
    };
  });

  useEffect(() => {
    if (!location) return;

    if (location.type === 'location') {
      const current = defaultItems
        .filter((i) => i.type !== 'subtitle')
        .find((i) => i.option.id === location.id);
      const terminals = defaultItems
        .filter((i) => i?.option?.type === 'terminal' && i.type !== 'subtitle')
        .filter((i) => i.defaultOption.id !== location.id);

      const terminalsTemp: LocationSelectorItem[] =
        terminals.length !== 0
          ? [
              {
                subtitle: t('Common:Terminals'),
                type: 'subtitle',
              },
              ...terminals,
            ]
          : [];

      const currentTemp = current ? current.defaultOption : { ...location };

      const newItems: LocationSelectorItem[] = [
        {
          subtitle:
            currentTemp.localizedNamesArray.find((i) => i.lang_id === primaryLangId)?.message ||
            currentTemp.visibleName,
          type: 'subtitle',
          defaultOption: {
            ...currentTemp,
          },
        },
        {
          type: 'location',
          option: {
            ...currentTemp,
            main: true,
            defaultName: currentTemp.visibleName,
            visibleName: isFrom ? 'ShippersWarehouse' : 'ConsigneesWarehouse',
            isWarehouse: true,
          },
          defaultOption: {
            ...currentTemp,
          },
        },
        {
          type: 'terminal',
          option: {
            ...currentTemp,
            main: true,
            type: 'terminal',
            symbolCode: 'ANY',
            defaultName: currentTemp.visibleName,
            visibleName: 'CarrierTerminal',
          },
          defaultOption: {
            ...currentTemp,
          },
        },
        ...terminalsTemp,
      ];
      localStorage.setItem(`${isFrom ? 'from' : 'to'}-items`, JSON.stringify(newItems));
      localStorage.setItem(`${isFrom ? 'from' : 'to'}-needRefresh`, JSON.stringify('0'));

      setItems(newItems);
      setNeedRefresh(false);
    } else {
      if (!needRefresh) return;

      let firstLocation = location;
      if (location.parentId !== null) {
        const elementsWithOptions = defaultItems.filter((i) => i.option !== undefined);
        const current = elementsWithOptions.find(
          (l) => l.option.id === location.parentId && l.type === 'location'
        );

        if (current && current.defaultOption) {
          firstLocation = current.defaultOption;
        }
      }

      const terminals = defaultItems.filter(
        (i) => i?.option?.type === 'terminal' && i.type !== 'subtitle'
      );

      const locations = defaultItems
        .filter((i) => i?.option?.type === 'location' && i.type !== 'subtitle')
        .filter((i) => i.defaultOption.id !== location.id);

      const locationsTemp: LocationSelectorItem[] =
        locations.length !== 0
          ? [
              {
                subtitle: t('Common:Locations'),
                type: 'subtitle',
              },
              ...locations,
            ]
          : [];

      const terminalsTemp: LocationSelectorItem[] =
        terminals.length !== 0
          ? [
              {
                subtitle: t('Common:Terminals'),
                type: 'subtitle',
              },
              ...terminals,
            ]
          : [];

      const header = firstLocation.main
        ? [
            {
              subtitle:
                firstLocation.localizedNamesArray.find((i) => i.lang_id === primaryLangId)
                  ?.message || t(`LocationSelector:${firstLocation.visibleName}`),
              type: 'subtitle',
              defaultOption: {
                ...firstLocation,
              },
            },
            {
              type: 'location',
              option: {
                ...firstLocation,
                main: true,
                defaultName: firstLocation.visibleName,
                visibleName: isFrom
                  ? `${
                      firstLocation.localizedNamesArray.find((i) => i.lang_id === primaryLangId)
                        ?.message || t(`LocationSelector:${firstLocation.visibleName}`)
                    } ${t('LocationSelector:ShippersWarehouse')}`
                  : `${
                      firstLocation.localizedNamesArray.find((i) => i.lang_id === primaryLangId)
                        ?.message || t(`LocationSelector:${firstLocation.visibleName}`)
                    } ${t('LocationSelector:ConsigneesWarehouse')}`,
                isWarehouse: true,
              },
              defaultOption: {
                ...firstLocation,
              },
            },
            {
              type: 'terminal',
              option: {
                ...firstLocation,
                main: true,
                type: 'terminal',
                symbolCode: 'ANY',
                defaultName: firstLocation.visibleName,
                visibleName: 'CarrierTerminal',
              },
              defaultOption: {
                ...firstLocation,
              },
            },
          ]
        : [];

      //@ts-ignore
      const newItems: LocationSelectorItem[] = [...header, ...locationsTemp, ...terminalsTemp];
      localStorage.setItem(`${isFrom ? 'from' : 'to'}-items`, JSON.stringify(newItems));
      localStorage.setItem(`${isFrom ? 'from' : 'to'}-needRefresh`, JSON.stringify('0'));

      setItems(newItems);
      setNeedRefresh(false);
    }
  }, [location]);

  useEffect(() => {
    if (searchValue === '' && !isConfigFromSearchParams) {
      setItems([]);
      setDefaultItems([]);
      setLoadingState(false);
      setNeedRefresh(true);
      return;
    }

    if (location !== undefined) {
      return;
    }
    setLoadingState(true);

    $lastSearchValue.next(v4());
    searchLocationsText(searchValue, $lastSearchValue.getValue()).then((result) => {
      const lastSearchId = $lastSearchValue.getValue();

      if (result.requestId !== lastSearchId) {
        setLoadingState(false);
        setNeedRefresh(true);
        return;
      }

      const listItems: LocationSelectorItemList = result.locations
        .filter((l) => l.isUserSearchable)
        .map((l) => ({
          type: l.type,
          option: l,
          defaultOption: l,
        }));

      localStorage.setItem(`${isFrom ? 'from' : 'to'}-default-items`, JSON.stringify(listItems));
      localStorage.setItem(`${isFrom ? 'from' : 'to'}-items`, JSON.stringify(listItems));
      localStorage.setItem(`${isFrom ? 'from' : 'to'}-needRefresh`, JSON.stringify('1'));

      const splitItems = (items: LocationSelectorItem[]): LocationSelectorItemList => {
        if (items.length === 0) return [];

        const terminals = items.filter(
          (i) => i?.option?.type === 'terminal' && i.type !== 'subtitle'
        );
        const locations = items.filter(
          (i) => i?.option?.type === 'location' && i.type !== 'subtitle'
        );

        const locationsTemp: LocationSelectorItem[] =
          locations.length !== 0
            ? [
                {
                  subtitle: t('Common:Locations'),
                  type: 'subtitle',
                },
                ...locations,
              ]
            : [];

        const terminalsTemp: LocationSelectorItem[] =
          terminals.length !== 0
            ? [
                {
                  subtitle: t('Common:Terminals'),
                  type: 'subtitle',
                },
                ...terminals,
              ]
            : [];

        return [...locationsTemp, ...terminalsTemp];
      };

      setItems(splitItems(listItems));
      setDefaultItems(splitItems(listItems));
      setLoadingState(false);
      setNeedRefresh(true);
    });
  }, [searchValue]);

  return { isLoading, items };
};

export default useGetOptions;
