import React, {FC} from "react";
import {ProductSummaryProps} from "@services/requests/orderService/types";
import restoreServiceData from "@pages/AdditionalServices/containers/methods/basket/restoreServiceData";
import getLocalizationByArgs from "@helpers/getLocalizationByArgs";
import ServicePropertyValue
  from "@pages/Basket/components/ServicesList/components/OrderList/components/OrderListItem/components/ServiceBasketItem/ServicePropertyValue";
import ServiceFieldsList
  from "@pages/Basket/components/ServicesList/components/OrderList/components/OrderListItem/components/ServiceBasketItem/ServiceFieldsList";
import getServicePathFromData from "@pages/AdditionalServices/containers/methods/basket/getServicePathFromData";
import getDirectoryFieldsFromData from "@pages/AdditionalServices/containers/methods/basket/getDirectoryFieldsFromData";

const ServiceBasketItem: FC<ProductSummaryProps> = props => {
  const {
    product,
    langId,
  } = props

  const restoreResult = restoreServiceData(product)
  if (!restoreResult) {
    return null
  }

  const {setUpService} = restoreResult

  const path = getServicePathFromData(langId, setUpService)
  const name = getLocalizationByArgs(langId, setUpService.ServiceData.name, setUpService.ServiceData.localized_names)
  const fullName = [path, name].filter(s => s.length > 0).join(" / ")

  const properties = Array.from([...Object.values(setUpService.ServicesPath), setUpService.Variant])
    .map(d => d.properties)
    .flat(1)
    .filter((d, i, data) => data.map(d => d.id).indexOf(d.id) === i)
    .filter(p => !p.settings.Visuality.IsShowValueInDescription)

  const fields = [
    ...setUpService.ServiceData.fields,
    ...getDirectoryFieldsFromData(setUpService),
  ]

  return (
    <div className="a-service-basket">
      <div className="caption">{fullName}</div>
      <div className="properties">
        {properties.map(property => (
          <ServicePropertyValue
            key={property.id}
            langID={langId}
            service={setUpService.ServiceData}
            property={property}
            propertyOptions={setUpService.PropertyOptions}
          />
        ))}
      </div>
      <ServiceFieldsList
        fields={fields}
        langID={langId}
        setUpService={setUpService}
      />
    </div>
  )
}

export default ServiceBasketItem