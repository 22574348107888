import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const ArchiveIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M3 4.227A1.227 1.227 0 0 1 4.227 3h15.546A1.227 1.227 0 0 1 21 4.227v1.637a1.227 1.227 0 0 1-1.227 1.227H4.227A1.227 1.227 0 0 1 3 5.864V4.227Zm.818 4.091h16.364v8.796A3.886 3.886 0 0 1 16.296 21H7.705a3.887 3.887 0 0 1-3.887-3.886V8.318Zm6.341 2.455a.614.614 0 0 0 0 1.227h3.682a.614.614 0 0 0 0-1.227h-3.682Z" />
  </SvgIcon>
);

export default ArchiveIcon;
