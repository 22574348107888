import {AdditionServicesState} from "@pages/AdditionalServices/containers/types";

/**
 * clone выполняет глубокое копирование стейта для корректой работы шины.
 * @param state
 */
export const clone = (state: AdditionServicesState): AdditionServicesState => {
  return {
    RootServices: [...state.RootServices],
    LastServiceInPath: state.LastServiceInPath,
    ServicesPath: {
      ...state.ServicesPath,
    },
    IsLastLevelSelected: state.IsLastLevelSelected,
    IsLoadingDirectoriesForPath: state.IsLoadingDirectoriesForPath,
    LoadedDirectoriesCache: {
      ...state.LoadedDirectoriesCache,
    },
    FieldsToDisplay: [...state.FieldsToDisplay],
    FieldValues: {...state.FieldValues},
    IsNeedCalculateServices: state.IsNeedCalculateServices,
    IsServicesLoading: state.IsServicesLoading,
    Variants: [...state.Variants],
    SelectedVariant: state.SelectedVariant,
    SelectedProductUUID: state.SelectedProductUUID,
    VariantServices: {...state.VariantServices},
    VariantSelectedServices: {...state.VariantSelectedServices},
    VariantFields: {...state.VariantFields},
    VariantFieldValues: {...state.VariantFieldValues},
    IsServicesCalculating: state.IsServicesCalculating,
    LastServiceCalculationID: state.LastServiceCalculationID,
    ServiceTemplateStore: {...state.ServiceTemplateStore},
    CurrencyID: state.CurrencyID,
    LanguageID: state.LanguageID,
    VariantsCalculationResult: {...state.VariantsCalculationResult},
    VariantProperties: {...state.VariantProperties},
    PropertyValues: {...state.PropertyValues},
    PropertiesToDisplay: [...state.PropertiesToDisplay],
  }
}