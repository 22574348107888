import {ServiceFieldType, ServiceField, HandbookVersion} from "@onlog-public/additional-services-types";
import {
  optionsGeneratorProcessor,
  SelectOption
} from "@pages/AdditionalServices/containers/services/optionsGenerator/types";
import {OptionsCache} from "../optionsLoader/types";
import getLocalizationByArgs from "@helpers/getLocalizationByArgs";

/**
 * HandbookProcessor реализует процессор генерации опций для доп. справочников
 */
export class HandbookProcessor implements optionsGeneratorProcessor {
  /**
   * getType возвращает тип поля, для которого реализован процессор
   */
  getType(): ServiceFieldType {
    return "handbook"
  }

  /**
   * getOptions генерирует опции для селектора поля дополнительных услуг
   * @param field
   * @param langID
   * @param cache
   */
  getOptions(field: ServiceField, langID: string, cache: OptionsCache<any>): SelectOption[] {
    const handbookData = cache.cache[field.handbook_id] as HandbookVersion | undefined
    if (!handbookData) {
      return
    }

    const handbookItems = [...handbookData.items].sort(
      (a, b) => a.ordinal > b.ordinal ? 1 : -1
    )

    const handbookOptionsData = field.handbook_limitations.length > 0
      ? handbookItems.filter(i => field.handbook_limitations.includes(i.id))
      : handbookItems

    return handbookOptionsData.map(d => ({
      value: parseInt(d.id),
      label: getLocalizationByArgs(langID, d.name, d.localized_names),
      origin: d,
    }))
  }
}