import { $error } from '@settings/errorContext';
import { graphQLClient } from '@settings/services/graphQLClient';
import { GraphQLClient } from '@settings/services/graphQLClient/GraphQLClient';
import { loggerFactory } from '@settings/services/logger';
import { Logger } from '@settings/services/logger/Logger';

import { localizedMessagesService } from '../../localizedMessagesService';
import { LocalizedMessagesServiceInterfaces } from '../../localizedMessagesService/interfaces';
import { LoaderInterface } from '../interface';
import {
  AllowanceData,
  AllowanceLoaderQuery,
  AllowanceLoaderQueryResponse,
} from './AllowanceLoaderQuery';

/**
 * Загрузчик данных по налогам
 */
export class AllowanceLoader implements LoaderInterface<AllowanceData> {
  private readonly client: GraphQLClient;
  private readonly logger: Logger;
  private readonly localizationLoader: LocalizedMessagesServiceInterfaces;

  constructor(token?: string) {
    this.client = graphQLClient(token);
    this.logger = loggerFactory().make(`AllowanceLoader`);
    this.localizationLoader = localizedMessagesService(token);
  }

  async Load(primaryKeys?: any[]): Promise<AllowanceData[]> {
    try {
      const resp = await this.client.Query<null, AllowanceLoaderQueryResponse>(
        new AllowanceLoaderQuery(primaryKeys),
        {}
      );

      // Загружаем все локализованные тексты
      const localizedMessageIds = resp.items.map((i) => i.localized_names).flat();
      const messages = await this.localizationLoader.GetMessagesArray(localizedMessageIds);
      resp.items.map((item) => {
        item.localized_names_data = messages.filter(
          (m) => item.localized_names.indexOf(m.id) !== -1
        );
      });

      return resp.items;
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }
}
