import React, {FC} from "react";
import {AdditionListTemplate} from "@onlog-public/additional-services-types";
import {useTranslation} from "react-i18next";

export interface TableHeaderProps {
  settings: AdditionListTemplate
}

const TableHeader: FC<TableHeaderProps> = props => {
  const {settings} = props
  const {t} = useTranslation()

  const nameColumn = t('AdditionalService:Templates.AdditionList.NameColumn')
  const quantityColumnName = t(settings.QuantityColumnName)
  const priceColumn = t('AdditionalService:Templates.AdditionList.PriceColumn')
  const unitColumnName = t(settings.UnitColumnName)
  const sumColumn = t('AdditionalService:Templates.AdditionList.SumColumn')
  const taxColumn = t('AdditionalService:Templates.AdditionList.TaxColumn')
  const totalColumn = t('AdditionalService:Templates.AdditionList.TotalColumn')

  return <>
    <div className="header no-left-border">{nameColumn}</div>
    <div className="header">{priceColumn}</div>
    <div className="header">{quantityColumnName}</div>
    <div className="header">{unitColumnName}</div>
    <div className="header">{sumColumn}</div>
    <div className="header tax">{taxColumn}</div>
    <div className="header">{totalColumn}</div>
  </>
}

export default TableHeader