import { CurrencyResponse, ExciseItem } from '@services/requests/customsTaxService/interface';

import { CustomsPaymentProduct } from '../components/CustomsPaymentsForm/context/types';
import { getCalculation } from '../components/CustomsPaymentsForm/helpers/calculation';

interface UseExciseCalcProps {
  selectedExcise: ExciseItem;
  localizedMobileHeaderTableLabels: LocalizedLabels;
  contractCurrency: CurrencyResponse[number];
  product: CustomsPaymentProduct;
  title: string;
}

interface LocalizedLabels {
  rateLabel: string;
  rublesAmountLabel: string;
  currencyAmountLabel: string;
}

function getExciseCalc({
  selectedExcise,
  product,
  contractCurrency,
  localizedMobileHeaderTableLabels,
  title,
}: UseExciseCalcProps) {
  const { productsCount, contractCurrencyCost, weight, maximumRetailPackagePrice, packagesCount } =
    product;

  if (!selectedExcise) {
    return null;
  }

  const count = +selectedExcise.ValueDetail.ValueCount;
  const unit = selectedExcise.ValueDetail.ValueUnit;
  const localCurrency = selectedExcise.ValueDetail.ValueCurrency;
  const feeBase = unit.indexOf('т.шт') !== -1 ? +productsCount : +weight;
  const cost = +contractCurrencyCost;
  const currencyRate = +contractCurrency.rate.toString();
  const rubCost = cost * currencyRate;

  let result = getCalculation(count, unit, localCurrency, currencyRate, rubCost, feeBase);

  if (selectedExcise.ValueDetailAdd) {
    const feeBase3 = +weight;
    const count3 = +selectedExcise.ValueDetailAdd.ValueCount;
    const unit3 = selectedExcise.ValueDetailAdd.ValueUnit;
    const localCurrency3 = selectedExcise.ValueDetailAdd.ValueCurrency;
    if (selectedExcise.Prim) {
      const exciseSpecialPrice = +maximumRetailPackagePrice;
      const exciseSpecialCount =
        selectedExcise.Measure.Razm.indexOf('т.шт') !== -1 ? +packagesCount * 1000 : +packagesCount;
      const specialCost = exciseSpecialPrice * exciseSpecialCount;

      result += getCalculation(count3, unit3, localCurrency3, currencyRate, specialCost, feeBase3);
    } else {
      result += getCalculation(count3, unit3, localCurrency3, currencyRate, rubCost, feeBase3);
    }
  }

  const sumCurrency = (result / currencyRate).toString();

  if (selectedExcise?.Value !== '0' && selectedExcise.ValueDetail.ValueCount2) {
    const feeBase2 = +productsCount;
    const count2 = +selectedExcise.ValueDetail.ValueCount2;
    const unit2 = selectedExcise.ValueDetail.ValueUnit2;
    const localCurrency2 = selectedExcise.ValueDetail.ValueCurrency2;

    const result2 = getCalculation(count2, unit2, localCurrency2, currencyRate, rubCost, feeBase2);
    const sumCurrency2 = (result2 / currencyRate).toString();

    return {
      id: 'excise',
      name: title,
      rate: `${selectedExcise.ValueDetail.ValueCount2}${selectedExcise.ValueDetail.ValueCurrency2}/${selectedExcise.ValueDetail.ValueUnit2}`,
      rublesAmount: result < result2 ? result2.toString() : result.toString(),
      currencyAmount: result < result2 ? sumCurrency2 : sumCurrency,
      contractCurrency,
      ...localizedMobileHeaderTableLabels,
    };
  }

  return {
    id: 'excise',
    name: title,
    rate: `${selectedExcise.ValueDetail.ValueCount}${selectedExcise.ValueDetail.ValueCurrency}/${selectedExcise.ValueDetail.ValueUnit}`,
    rublesAmount: result.toString(),
    currencyAmount: sumCurrency,
    contractCurrency,
    ...localizedMobileHeaderTableLabels,
  };
}

export { getExciseCalc };
