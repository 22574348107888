import MButton from '@assets/mui/@material-extend/MButton';
import palette from '@assets/theme/palette';
import CloseIcon from '@components/icons/Close';
import { BreadCrumbProps } from '@components/UI/breadcrumbs';
import CardWrapper from '@components/UI/CardWrapper';
import useMyRouting from '@context/useMyRouting/useMyRouting';
import { useYaHit } from '@context/useYaHit';
import { useMediaQuery } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useDelivery from '@pages/AdditionalServices/tabs/DeliveryForm/useDelivery';
import { EmptyBasketPlaceholder } from '@pages/Basket/EmptyBasketPlaceholder';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CALUCLATOR_LINK_BY_LANG_ID } from '@settings/localization/consts';


import { SwipeableThanksDrawerStyledContainer } from './StyledComponents';

const TYOrder = () => {
  const { t } = useTranslation(['TYOrder', 'CartComponent', 'PageNames']);
  const textTy = t('TYOrder:ThankYou');
  const textContact = t('TYOrder:OurManagerWillContactYou');
  const { setPage } = useMyRouting().actions;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { useAggregationData } = useDelivery();
  const [aggregationData] = useAggregationData().useState();
  const [settings] = useDelivery().useSettings().useState();

  const { primaryLanguageId } = settings;

  const link = CALUCLATOR_LINK_BY_LANG_ID[primaryLanguageId] || CALUCLATOR_LINK_BY_LANG_ID.default;

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true);

  useYaHit({});

  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const breadCrumbs: BreadCrumbProps[] = [
    {
      id: 1,
      name: t('PageNames:MainPage'),
      url: 'widget',
    },
    {
      id: 2,
      name: t('PageNames:MyOrder'),
      url: 'basket',
    },
    {
      id: 3,
      name: t('PageNames:OrderProcessed'),
    },
  ];

  const goToCalculator = () => {
    window.open(link, '_self');
    setPage('widget');
  };

  const closeDrawerHandler = () => {
    setIsDrawerOpen(false);
  };

  return (
    <>
      {!isMobile && (
        <EmptyBasketPlaceholder
          breadCrumbs={breadCrumbs}
          titleText={t('TYOrder:Title')}
          subTitleText={t('TYOrder:ThankYou')}
          infoText={t('TYOrder:OurManagerWillContactYou')}
          calculatorBtnText={t('CartComponent:SelectedServiceButton')}
        />
      )}

      {isMobile && (
        <>
          <EmptyBasketPlaceholder
            breadCrumbs={breadCrumbs}
            titleText={t('CartComponent:SelectedServices')}
            subTitleText={t('CartComponent:SelectedServicesQuestion')}
            infoText={t('CartComponent:SelectedServicesAction')}
            calculatorBtnText={t('CartComponent:SelectedServiceButton')}
          />
          <SwipeableThanksDrawerStyledContainer
            anchor="bottom"
            SwipeAreaProps={{
              disablePortal: true,
            }}
            open={isDrawerOpen}
            onClose={closeDrawerHandler}
            onOpen={() => {}}
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
            swipeAreaWidth={aggregationData ? 70 : undefined}
          >
            <CardWrapper className={'card-wrapper'}>
              <div className="drawer-control">
                <IconButton color="primary" className="close-drawer" onClick={closeDrawerHandler}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="7"
                    viewBox="0 0 17 7"
                    fill="none"
                    stroke="none"
                  >
                    <path d="M16 6L8.5 0.642856L1 6" stroke="black" fill="none" />
                  </svg>
                </IconButton>
              </div>
              <Typography variant={'h2'} color={'common.black'} className={'section-title'}>
                {textTy}
              </Typography>
              <Typography variant={'body1'} color={'common.black'} className={'section-info'}>
                {textContact}
              </Typography>
              <MButton
                className={'calculation-link'}
                onClick={goToCalculator}
                fullWidth={true}
                variant={'outlined'}
                startIcon={<CloseIcon />}
                size={'large'}
                color={'inherit'}
                sx={{
                  color: palette.light.blue[400],
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                <span>{t('CartComponent:SelectedServiceButton')}</span>
              </MButton>
            </CardWrapper>
          </SwipeableThanksDrawerStyledContainer>
        </>
      )}
    </>
  );
};

export default TYOrder;
