import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

const ContainerTypeTableHeaderData = (): GridColDef[] => {
  const { t } = useTranslation(['RoutesCalculationWidget']);

  return [
    {
      field: 'default_name',
      headerName: t('RoutesCalculationWidget:ContainerType'),
      width: 200,
      sortable: true,
      headerAlign: 'center',
      cellClassName: 'default-table-container__text-left',
    },
    {
      field: 'estimated_volume',
      headerName: t('RoutesCalculationWidget:EstimatedVolume'),
      sortable: true,
      flex: 2,
      minWidth: 130,
      headerAlign: 'center',
      cellClassName: 'default-table-container__text-center',
    },
    {
      field: 'use_full_volume',
      type: 'number',
      headerName: t('RoutesCalculationWidget:UseFullVolume'),
      sortable: true,
      flex: 1,
      minWidth: 200,
      headerAlign: 'center',
      cellClassName: 'default-table-container__text-center',
    },
    {
      field: 'gross_weight',
      headerName: t('RoutesCalculationWidget:GrossWeight'),
      type: 'number',
      sortable: true,
      flex: 2,
      minWidth: 130,
      headerAlign: 'center',
      cellClassName: 'default-table-container__text-center',
    },
    {
      field: 'net_weight',
      headerName: t('RoutesCalculationWidget:NetWeight'),
      type: 'string',
      sortable: true,
      flex: 1,
      minWidth: 130,
      headerAlign: 'center',
      cellClassName: 'default-table-container__text-center',
    },
    {
      field: 'boxing',
      headerName: t('RoutesCalculationWidget:Boxing'),
      flex: 2,
      sortable: true,
      minWidth: 130,
      headerAlign: 'center',
      cellClassName: 'default-table-container__text-center default-table-container__last-cell',
    },
  ];
};

export default ContainerTypeTableHeaderData;
