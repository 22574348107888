import {
  ServicesAggregateProps,
  ServicesQueryParamsProps,
} from '@services/requests/servicesService/interface';
import {
  ServicesDirectoriesAggregateQuery
} from '@services/requests/servicesService/servicesDirectoriesService/requests/ServicesDirectoriesAggregateQuery';
import {
  ServicesDirectoriesQuery
} from '@services/requests/servicesService/servicesDirectoriesService/requests/ServicesDirectoriesQuery';
import {
  ServicesRootsDirectoriesQuery
} from '@services/requests/servicesService/servicesDirectoriesService/requests/ServicesRootsDirectoriesQuery';
import {$error} from '@settings/errorContext';
import {graphQLClient} from '@settings/services/graphQLClient';
import {GraphQLClient} from '@settings/services/graphQLClient/GraphQLClient';
import {loggerFactory} from '@settings/services/logger';
import {Logger} from '@settings/services/logger/Logger';
import {
  LoadAllServicesDirectoriesProps,
  ServicesDirProps,
  ServicesDirectoriesAggregateQueryResponseProps,
  ServicesDirectoriesQueryResponseProps,
  ServicesDirectoriesServiceProps,
} from './interface';
import {
  ServiceDirectory,
  ServiceDirectorySettings,
  ServiceFieldParameters,
  ServiceTemplateType,
  ServicePropertySettings,
} from '@onlog-public/additional-services-types';
import {
  ServiceDirectoryLoadByParentQuery, ServiceDirectoryLoadByParentResponse, ServiceDirectoryLoadByParentVariables,
  ServiceDirectoryLoadItem
} from "@services/requests/servicesService/servicesDirectoriesService/requests/ServiceDirectoryLoadByParent";
import {
  ServiceDirectoryLoadByIDQuery, ServiceDirectoryLoadByIDResponse, ServiceDirectoryLoadByIDVariables
} from "@services/requests/servicesService/servicesDirectoriesService/requests/ServiceDirectoryLoadByID";
import {localizedMessagesService} from "@services/requests/localizedMessagesService";
import {Collection} from "@settings/services/types";
import {LocalizedMessage} from "@services/requests/localizedMessagesService/interfaces";
import {filesService} from "@services/requests/filesService";
import {FileData} from "@services/requests/filesService/interface";

// Сервис загрузки директорий дополнительных услуг
class ServicesDirectoriesService implements ServicesDirectoriesServiceProps {
  private readonly logger: Logger;

  private readonly client: GraphQLClient;

  /**
   * Конструктор сервиса
   */
  constructor(token?: string) {
    this.logger = loggerFactory().make(`ServiceDirectoryLoader`);
    this.client = graphQLClient(token);
  }

  /**
   * Агрегирующий запрос сущностей директорий дополнительных услуг
   */
  async LoadAggregate(): Promise<ServicesAggregateProps> {
    try {
      const response = await this.client.Query<
        null,
        ServicesDirectoriesAggregateQueryResponseProps
      >(new ServicesDirectoriesAggregateQuery(), {});

      this.logger.Debug(`Loaded aggregate info of services directories`, response);

      return response.servicesdir_aggregate[0];
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  /**
   * Загрузка массива директорий по переданному фильтру params
   * @param params
   */
  async Load(params: ServicesQueryParamsProps): Promise<ServicesDirProps[]> {
    try {
      const response = await this.client.Query<
        ServicesQueryParamsProps,
        ServicesDirectoriesQueryResponseProps
      >(new ServicesDirectoriesQuery(params), {});

      this.logger.Debug(`Loaded list of services directories`, response);

      return response.servicedirectory_list;
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  /**
   * Загрузка массива корневых директорий
   */
  async LoadRoots(): Promise<ServicesDirProps[]> {
    try {
      const response = await this.client.Query<
        ServicesQueryParamsProps,
        ServicesDirectoriesQueryResponseProps
      >(new ServicesRootsDirectoriesQuery(), {});

      this.logger.Debug(`Loaded root list of services`, response);

      return response.servicedirectory_list;
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  /**
   * Загрузка всех доступных директорий.
   * Сначала мы получаем агрегацию, это дает нам общее количество. Затем мы должны установить лимит в запросе
   * @param limit
   * @param offset
   */
  async LoadAll(limit?: number, offset?: number): Promise<LoadAllServicesDirectoriesProps> {
    try {
      const params: ServicesQueryParamsProps = {
        limit,
        offset,
      };

      const allServicesCount = await this.LoadAggregate();
      const response = await this.Load(params);

      return {
        count: allServicesCount.count,
        items: response,
      };
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  /**
   * Загружает все директории по переданному идентификатору родителя
   * @param parentID
   */
  async GetServicesByPath(parentID?: string): Promise<ServiceDirectory[]> {
    try {
      const response = await this.client.Query<
        ServiceDirectoryLoadByParentVariables,
        ServiceDirectoryLoadByParentResponse
      >(new ServiceDirectoryLoadByParentQuery({
        parentID,
      }), {});

      this.logger.Debug(`Loaded service directories list by parent ID`, response);

      const [locales, files] = await Promise.all([
        await this.getLocalesForDirectories(response.result),
        await filesService().LoadFilesById(this.getFileID(response.result)),
      ])

      return response.result.map(i => this.parseServiceDirectoryItem(i, locales, files));
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  /**
   * Загружает директорию дополнительных услуг по переданному идентификатору
   * @param id
   */
  async GetServiceByID(id: string): Promise<ServiceDirectory> {
    try {
      const response = await this.client.Query<
        ServiceDirectoryLoadByIDVariables,
        ServiceDirectoryLoadByIDResponse
      >(new ServiceDirectoryLoadByIDQuery({
        id,
      }), {});

      this.logger.Debug(`Loaded service directories list by parent ID`, response);

      const [locales, files] = await Promise.all([
        await this.getLocalesForDirectories(response.result),
        await filesService().LoadFilesById(this.getFileID(response.result)),
      ])

      const items = response.result.map(i => this.parseServiceDirectoryItem(i, locales, files))
      if (items.length === 0) {
        throw new Error(`Item not found by ID`);
      }

      return items[0];
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  /**
   * getFileID возвращает все идентификаторы файлов, которые установлены в директориях
   * @param items
   * @protected
   */
  protected getFileID(items: ServiceDirectoryLoadItem[]): string[] {
    if (!items || 0 === items.length) {
      return []
    }

    return items.map(i => [
      ...i.file_id,
      ...this.getFileID(i.childs)
    ]).flat(1)
  }

  /**
   * getLocalesForDirectories загружает данные локализации для директорий.
   * @param items
   * @protected
   */
  protected async getLocalesForDirectories(items: ServiceDirectoryLoadItem[]): Promise<Collection<LocalizedMessage>> {
    const ids = this.getLocalizationsFromDirectories(items)
    const messages = await localizedMessagesService().GetMessagesArray(ids)

    const result: Collection<LocalizedMessage> = {}
    messages.map(m => result[m.id] = m)

    return result
  }

  /**
   * getLocalizationsFromDirectories рекурсивно обходит переданные базовые данные директорий
   * и возвращает локализации, которые необходимо догрузить.
   * @param items
   * @protected
   */
  protected getLocalizationsFromDirectories(items: ServiceDirectoryLoadItem[]): string[] {
    return items.map(i => {
      const localizations: string[] = [
        i.localized_names,
        i.localized_descriptions,
        i.fields.map(f => {
          return [
            f.localized_names,
            f.localized_placeholders,
            f.localized_helpers,
          ];
        }).flat(2),
        i.properties.map(p => {
          return [
            p.localized_names,
            p.localized_descriptions,
          ];
        }).flat(2),
        this.getLocalizationsFromDirectories(i.childs ?? []),
      ].flat(1);

      return localizations
    }).flat(1)
  }

  /**
   * parseServiceDirectoryItem преобразует ответ от API в формат системы.
   * Конвертирует данные директорий дополнительных услуг.
   *
   * @param item
   * @param localization
   * @param files
   * @protected
   */
  protected parseServiceDirectoryItem(
    item: ServiceDirectoryLoadItem,
    localization: Collection<LocalizedMessage>,
    files: FileData[],
  ): ServiceDirectory {
    const {
      childs = [],
      contractor_type,
      contractor_id,
      localized_names,
      localized_descriptions,
      ...itemVal
    } = item

    return {
      ...itemVal,
      localized_names: localized_names.map(m => localization[m]).filter(v => !!v),
      localized_descriptions: localized_descriptions.map(m => localization[m]).filter(v => !!v),
      contractor_type: contractor_type ?? undefined,
      contractor_id: contractor_id ?? undefined,
      template: itemVal.template as unknown as ServiceTemplateType,
      settings: ServiceDirectorySettings.RestoreSettings(itemVal.settings),
      file_id: files.filter(file => itemVal.file_id.includes(file.id)),
      fields: item.fields.map(i => ({
        ...i,
        localized_names: i.localized_names.map(m => localization[m]).filter(v => !!v),
        localized_placeholders: i.localized_placeholders.map(m => localization[m]).filter(v => !!v),
        localized_helpers: i.localized_helpers.map(m => localization[m]).filter(v => !!v),
        settings: ServiceFieldParameters.RestoreSettings(i.settings),
      })),
      properties: item.properties.map(p => ({
        ...p,
        localized_names: p.localized_names.map(m => localization[m]).filter(v => !!v),
        localized_descriptions: p.localized_descriptions.map(m => localization[m]).filter(v => !!v),
        settings: ServicePropertySettings.RestoreSettings(p.settings),
      })),
      children: childs.map(i => this.parseServiceDirectoryItem(i, localization, files))
    }
  }
}

export default ServicesDirectoriesService;
