import { BranchItem } from '@services/requests/routeCalculator/locationsBranchSearchService/interfaces';
import { GetLocalizedNamesForLocationsOrTerminals } from '@settings/services/helpers/GetLocalizedNamesForLocationsOrTerminals';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import useMyRouting from '@context/useMyRouting/useMyRouting';
import useLocationTerminalSearch, { LocationOrTerminalValue } from '@components/pages/LocationOrTerminalPoint/components/LocationTerminalSearch';
import { Order } from '@services/requests/orderService/interface';

/**
 * Пропсы компонента
 */
export type Props = {
  value: LocationOrTerminalValue;
  branchItem?: BranchItem;
  order?: Order;
  children?: (primary: string[], secondary: string[], symbolCode: string) => ReactNode;
};

/**
 * Дефолтный рендер
 * @param primary
 * @param secondary
 * @param symbolCode
 */
const defaultRenderComponent = (primary: string[], secondary: string[], symbolCode: string) => {
  const [primaryName, ...parentPrimaryNames] = primary;
  const [secondaryName, ...parentSecondaryNames] = secondary;

  return (
    <div>
      <div>
        [ {symbolCode} ] {secondaryName} / {primaryName}
      </div>
      <div>
        {parentSecondaryNames.join(', ')} / {parentPrimaryNames.join(', ')}
      </div>
    </div>
  );
};

/**
 * Компонент вывода информации о локации или терминале
 * @param props
 */
const LocationOrTerminalPoint = (props: Props) => {
  const {
    value,
    branchItem,
	order,

    children = defaultRenderComponent,
  } = props;

  const { getLocationByValue, getSyncLocationByValue } = useLocationTerminalSearch().actions;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [locationData, setLocationData] = useState<BranchItem | undefined>(
    getSyncLocationByValue(value)
  );

  const [settings] = useMyRouting().useSettings().useState();

  const { primaryLanguageId, secondaryLanguageId } = settings;

  let primaryLangId = primaryLanguageId;
  let secondaryLangId = secondaryLanguageId;

  if (order) {
    primaryLangId = order.language_id;
    secondaryLangId = order.language_id;
  }

  // Загрузка данных локации для отображения
  const loader = useCallback(async () => {
    setIsLoading(true);

    try {
      const locationData = branchItem ?? (await getLocationByValue(value));

      setLocationData(locationData);
    } catch (e) {
      setLocationData(undefined);
    }

    setIsLoading(false);
  }, [value]);

  // Загружаем данные, при монтировании
  useEffect(() => {
    if (locationData) {
      return;
    }

    loader();
  }, []);

  // Загружаем данные, при обновлении значения компонента
  useEffect(() => {
    loader();
  }, [value]);

  if (!locationData) {
    return null;
  }

  // Получение локализованных названий по переданным данным
  const { primaryNames, symbolCode, secondaryNames } = GetLocalizedNamesForLocationsOrTerminals(
    locationData,
    primaryLangId,
    secondaryLangId
  );

  return <>{!isLoading && <>{children(primaryNames, secondaryNames, symbolCode)}</>}</>;
};

export default LocationOrTerminalPoint;
