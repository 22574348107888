import { Theme } from '@material-ui/core/styles';
import { Popper } from '@mui/material';
import React from 'react';

// ----------------------------------------------------------------------

export default function Tooltip(theme: Theme) {
  const shadow = '0 0 28px 0 rgba(0, 5, 115, 0.15)';

  return {
    MuiTooltip: {
      defaultProps: {
        PopperComponent: function CustomPopper(props) {
          // Используем свой контейнер для портала
          return <Popper {...props} />;
        },
      },
      styleOverrides: {
        tooltip: {
          whiteSpace: 'pre-wrap',
          backgroundColor: theme.palette.common.white,
          borderRadius: 8,
          padding: 20,
          maxWidth: 500,
          fontSize: 14,
          overflowWrap: 'break-word',
          fontWeight: 400,
          lineHeight: 1.5,
          letterSpacing: '0.32px',
          color: theme.palette.common.black,
          boxShadow: shadow,
        },
        arrow: {
          color: theme.palette.common.white,
          boxShadow: shadow,
        },
      },
    },
  };
}
