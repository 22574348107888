import { $error } from '@settings/errorContext';
import { graphQLClient } from '@settings/services/graphQLClient';
import { GraphQLClient } from '@settings/services/graphQLClient/GraphQLClient';
import { loggerFactory } from '@settings/services/logger';
import { Logger } from '@settings/services/logger/Logger';

import { localizedMessagesService } from '../../localizedMessagesService';
import { LocalizedMessagesServiceInterfaces } from '../../localizedMessagesService/interfaces';
import {
  LoadUnloadConditionsLoaderInterface,
  TransportLoadingCondition,
  TransportUnloadingCondition,
} from './interface';
import {
  LoadingConditionQuery,
  LoadingConditionQueryResponse,
} from './queries/LoadingConditionQuery';
import {
  UnloadingConditionQuery,
  UnloadingConditionResponse,
} from './queries/UnloadingConditionQuery';

/**
 * Сервис по загрузке условий погрузки и загрузки
 */
export class LoadUnloadConditionsLoader implements LoadUnloadConditionsLoaderInterface {
  private readonly client: GraphQLClient;
  private readonly logger: Logger;
  private readonly localizationLoader: LocalizedMessagesServiceInterfaces;

  constructor(token?: string) {
    this.client = graphQLClient(token);
    this.logger = loggerFactory().make(`LoadUnloadConditionsLoader`);
    this.localizationLoader = localizedMessagesService(token);
  }

  /**
   * Загрузка условий погрузки
   */
  async getLoadingConditions(ids?: string[]): Promise<TransportLoadingCondition[]> {
    try {
      const resp = await this.client.Query<null, LoadingConditionQueryResponse>(
        new LoadingConditionQuery(ids),
        {}
      );

      const localizedMessageIds = resp.items.map((i) => i.localized_names).flat();

      const messages = await this.localizationLoader.GetMessagesArray(localizedMessageIds);

      resp.items.map((item) => {
        item.localized_names_data = messages.filter((m) => item.localized_names.includes(m.id));
      });

      return resp.items;
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  /**
   * Загрузка условий разгрузки
   */
  async getUnloadingConditions(ids?: string[]): Promise<TransportUnloadingCondition[]> {
    try {
      const resp = await this.client.Query<null, UnloadingConditionResponse>(
        new UnloadingConditionQuery(ids),
        {}
      );

      const localizedMessageIds = resp.items.map((i) => i.localized_names).flat();

      const messages = await this.localizationLoader.GetMessagesArray(localizedMessageIds);

      resp.items.map((item) => {
        item.localized_names_data = messages.filter((m) => item.localized_names.includes(m.id));
      });

      return resp.items;
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }
}
