import { customShadows } from '@assets/theme/shadows';
import { styled } from '@mui/material';

const Container = styled('div')`
  border-radius: 20px;
  background: #fff;
  box-shadow: ${customShadows.light.z20};
  max-width: 100%;
`;

export default Container;
