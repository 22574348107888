import { ServicesQueryParamsProps } from '@services/requests/servicesService/interface';
import { GraphQLQuery } from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

/**
 * Запрос списка директорий дополнительных услуг
 */
export class ServicesDirectoriesQuery implements GraphQLQuery<ServicesQueryParamsProps> {
  readonly query: any;

  readonly variables: ServicesQueryParamsProps;

  constructor(params: ServicesQueryParamsProps) {
    this.variables = {
      ...params,
    };
    this.query = gql`
      query ServicesDirectoriesQuery(
        $limit: Int = 30
        $offset: Int = 0
        $where: servicedirectory_query_where_object
      ) {
        servicedirectory_list(
          limit: $limit
          offset: $offset
          where: $where
          order: [{ by: id, direction: asc, priority: 1 }]
        ) {
          id
          parent
          name
          localized_names
          description
          localized_descriptions
          template
          is_variants
          fields {
            id
            name
            localized_names
            placeholder
            localized_placeholders
            helper
            localized_helpers
            code
            type
            value
            handbook_limitations
            handbook_id
            settings
          }
          fields_id
          childs {
            id
            parent
            name
            localized_names
            description
            localized_descriptions
            template
            is_variants
            is_active
            fields {
              id
              name
              localized_names
              placeholder
              localized_placeholders
              helper
              localized_helpers
              code
              type
              value
              handbook_limitations
              handbook_id
              settings
            }
            fields_id
            childs_id
            properties {
              description
              id
              localized_descriptions
              localized_names
              name
              ordinal
              settings
              type
            }
          }
          childs_id
          properties {
            description
            id
            localized_descriptions
            localized_names
            name
            ordinal
            settings
            type
          }
        }
      }
    `;
  }
}
