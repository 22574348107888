import React, { CSSProperties } from 'react';

// Свойства компонента
export interface TransportTypeIconProps {
  transportType: string;
  style?: CSSProperties;
  className?: string;
}

// Компонент вывода иконки для требуемого типа транспорта
class TransportTypeIcon extends React.PureComponent<TransportTypeIconProps> {
  /**
   * Рендеринг нужной иконки
   */
  renderIcon() {
    switch (this.props.transportType) {
      case '1':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clipPath="url(#clip0_2140_4026)">
              <path
                d="M17.1562 3.375H6.84375C6.45544 3.375 6.14062 3.68981 6.14062 4.07812V9.83461L11.1968 7.75261C11.453 7.64719 11.7232 7.59375 12 7.59375C12.2768 7.59375 12.547 7.64719 12.803 7.75256L17.8594 9.83461V4.07812C17.8594 3.68981 17.5446 3.375 17.1562 3.375ZM9.1875 6.1875C8.79919 6.1875 8.48438 5.87269 8.48438 5.48438C8.48438 5.09606 8.79919 4.78125 9.1875 4.78125C9.57581 4.78125 9.89062 5.09606 9.89062 5.48438C9.89062 5.87269 9.57581 6.1875 9.1875 6.1875ZM12 6.1875C11.6117 6.1875 11.2969 5.87269 11.2969 5.48438C11.2969 5.09606 11.6117 4.78125 12 4.78125C12.3883 4.78125 12.7031 5.09606 12.7031 5.48438C12.7031 5.87269 12.3883 6.1875 12 6.1875ZM14.8125 6.1875C14.4242 6.1875 14.1094 5.87269 14.1094 5.48438C14.1094 5.09606 14.4242 4.78125 14.8125 4.78125C15.2008 4.78125 15.5156 5.09606 15.5156 5.48438C15.5156 5.87269 15.2008 6.1875 14.8125 6.1875Z"
                fill="#0EAB70"
              />
              <path
                d="M15.0469 0.703125C15.0469 0.314812 14.7321 0 14.3438 0H9.65625C9.26794 0 8.95312 0.314812 8.95312 0.703125V1.96875H15.0469V0.703125Z"
                fill="#0EAB70"
              />
              <path
                d="M8.92809 17.7899C9.17194 17.9654 9.17428 17.9651 9.41775 17.7898C9.79308 17.5406 10.3736 17.1551 11.2971 17.0188V9.23242L3.76378 12.3344C3.41156 12.4794 3.23887 12.8785 3.3743 13.2345L4.88512 17.2063C5.2777 17.0686 5.7584 16.9689 6.34945 16.9689C7.69195 16.9689 8.46576 17.4828 8.92809 17.7899Z"
                fill="#0EAB70"
              />
              <path
                d="M14.752 17.9039C14.8628 17.9826 14.9571 17.8628 15.0644 17.7897C15.527 17.4826 16.301 16.9689 17.6434 16.9689C18.2356 16.9689 18.7171 17.0689 19.1101 17.2069L20.6257 13.2352C20.7615 12.8791 20.589 12.4796 20.2365 12.3344L12.7031 9.23242V17.02C13.6219 17.1574 14.2004 17.5414 14.5746 17.79C14.6372 17.8316 14.713 17.882 14.752 17.9039Z"
                fill="#0EAB70"
              />
              <path
                d="M20.4701 24C19.5514 24 19.0617 23.6751 18.6682 23.4141C18.3489 23.2023 18.1381 23.0625 17.6434 23.0625C17.1494 23.0625 16.9387 23.2023 16.6199 23.4139C16.2265 23.675 15.737 24 14.8186 24C13.9008 24 13.4115 23.6749 13.0184 23.4138C12.7 23.2022 12.4896 23.0625 11.9963 23.0625C11.5025 23.0625 11.292 23.2023 10.9733 23.4139C10.58 23.675 10.0906 24 9.17245 24C8.25445 24 7.76512 23.675 7.37189 23.4138C7.05333 23.2022 6.84291 23.0625 6.34931 23.0625C5.85581 23.0625 5.64534 23.2022 5.32683 23.4138C4.93355 23.675 4.44422 24 3.52622 24C2.60827 24 2.11894 23.675 1.7257 23.4138C1.40714 23.2022 1.19667 23.0625 0.703125 23.0625C0.314812 23.0625 0 22.7477 0 22.3594V19.0781C0 18.6898 0.314812 18.375 0.703125 18.375C1.62112 18.375 2.11045 18.7 2.50369 18.9612C2.82225 19.1728 3.03267 19.3125 3.52622 19.3125C4.01977 19.3125 4.23023 19.1728 4.54875 18.9612C4.94198 18.7 5.43131 18.375 6.34927 18.375C7.26731 18.375 7.75664 18.7 8.14987 18.9612C8.46844 19.1728 8.67886 19.3125 9.17241 19.3125C9.66619 19.3125 9.8767 19.1727 10.1954 18.9611C10.5887 18.7 11.0781 18.375 11.9962 18.375C12.9141 18.375 13.4034 18.7001 13.7965 18.9612C14.1149 19.1728 14.3252 19.3125 14.8186 19.3125C15.3127 19.3125 15.5233 19.1727 15.8421 18.9611C16.2355 18.7 16.725 18.375 17.6434 18.375C18.5621 18.375 19.0519 18.6999 19.4454 18.9609C19.7646 19.1727 19.9755 19.3125 20.4701 19.3125C20.9647 19.3125 21.1756 19.1727 21.4948 18.9609C21.8883 18.6999 22.3781 18.375 23.2968 18.375C23.6851 18.375 23.9999 18.6898 23.9999 19.0781V22.3594C23.9999 22.7477 23.6851 23.0625 23.2968 23.0625C22.8021 23.0625 22.5912 23.2023 22.272 23.4141C21.8786 23.6751 21.3888 24 20.4701 24Z"
                fill="#0EAB70"
              />
            </g>
            <defs>
              <clipPath id="clip0_2140_4026">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      case '2':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M19.0898 4.2436C18.6612 1.79281 16.5348 0.00350243 14.0468 0H9.95268C7.4647 0.00350243 5.33834 1.79281 4.9097 4.2436C3.64542 4.65641 2.78949 5.83481 2.7879 7.16477V17.4002C2.7879 19.096 4.16268 20.4708 5.85853 20.4708H6.2495L4.94243 23.0839L6.77353 24L8.53813 20.4708H15.4614L17.226 23.9989L19.0571 23.0829L17.75 20.4708H18.141C19.8368 20.4708 21.2116 19.096 21.2116 17.4002V7.16477C21.21 5.83481 20.3541 4.65641 19.0898 4.2436ZM14.0468 4.09415H16.0939V6.14125H14.0468V4.09415ZM7.90558 4.09415H9.95268V6.14125H7.90558V4.09415ZM7.90558 17.4002C7.3403 17.4002 6.88205 16.942 6.88205 16.3767C6.88205 15.8114 7.3403 15.3532 7.90558 15.3532C8.47086 15.3532 8.9291 15.8114 8.9291 16.3767C8.9291 16.9419 8.47086 17.4002 7.90558 17.4002ZM10.9762 13.306H6.88205V8.18835H10.9762V13.306ZM10.9762 3.07062H13.0233V6.14125H10.9762V3.07062ZM15.0704 17.4002C14.5051 17.4002 14.0468 16.942 14.0468 16.3767C14.0468 15.8114 14.5051 15.3532 15.0704 15.3532C15.6356 15.3532 16.0939 15.8114 16.0939 16.3767C16.0939 16.9419 15.6356 17.4002 15.0704 17.4002ZM17.1174 13.306H13.0233V8.18835H17.1174V13.306Z"
              fill="#0EAB70"
            />
          </svg>
        );
      case '3':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.4772 1.46403C18.105 2.09184 18.0944 3.1129 17.4538 3.72756L7.36052 13.4105L8.02508 16.4567L6.50009 17.9817L4.49234 14.4489L0.959595 12.4412L2.48461 10.9162L5.5308 11.5808L15.2137 1.48751C15.8284 0.84682 16.8494 0.836228 17.4772 1.46403ZM10.2579 5.21411L9.22517 4.9026L10.3388 3.78893L9.6345 3.08459L8.14292 4.57618L6.88196 4.19583L8.05138 3.02642L7.34704 2.32208L5.79971 3.86938L2.73267 2.94428L0.941162 4.73575L7.04854 8.55952L10.2579 5.21411ZM15.0714 13.1411L16.6187 11.5938L15.9143 10.8895L14.7449 12.0589L14.3646 10.7979L15.8562 9.30633L15.1518 8.60199L14.0382 9.71566L13.7266 8.68291L10.3812 11.8923L14.205 17.9997L15.9965 16.2082L15.0714 13.1411Z"
              fill="#0EAB70"
            />
          </svg>
        );
      case '4':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M23.9906 16.9709V12.9428C23.9906 12.3428 23.8194 11.7429 23.5621 11.2287L20.9976 6.85774C20.7404 6.34351 20.1406 6.00069 19.5407 6.00069H15.684C15.0841 6.00069 14.5698 6.51492 14.5698 7.11485V16.2852H13.7127V4.2866C13.7127 3.34385 12.9415 2.57251 11.9986 2.57251H0V17.9993H2.57114C2.99966 16.5423 4.28523 15.4282 5.91351 15.4282C7.542 15.4282 8.91317 16.5423 9.25609 17.9993H15.5126C15.9411 16.5423 17.2267 15.4282 18.8549 15.4282C20.2263 15.4282 21.3403 16.1995 21.8546 17.3137C22.1118 17.7422 22.5338 17.9993 22.9623 17.9993C23.562 17.9993 24.0764 17.4851 23.9906 16.9709ZM16.2839 12V7.71478H19.712L22.1974 12H16.2839Z"
              fill="#0EAB70"
            />
            <path
              d="M18.855 16.2852C17.3981 16.2852 16.2839 17.3993 16.2839 18.8563C16.2839 20.3133 17.3981 21.4274 18.855 21.4274C20.3119 21.4274 21.4261 20.3133 21.4261 18.8563C21.4261 17.3993 20.3119 16.2852 18.855 16.2852ZM18.855 20.0562C18.1693 20.0562 17.6553 19.5419 17.6553 18.8563C17.6553 18.1707 18.1694 17.6564 18.855 17.6564C19.5407 17.6564 20.0548 18.1707 20.0548 18.8563C20.0548 19.5419 19.5407 20.0562 18.855 20.0562Z"
              fill="#0EAB70"
            />
            <path
              d="M5.9993 16.2852C4.54237 16.2852 3.42816 17.3993 3.42816 18.8563C3.42816 20.3133 4.54237 21.4274 5.9993 21.4274C7.45622 21.4274 8.57043 20.3133 8.57043 18.8563C8.57043 17.3993 7.45622 16.2852 5.9993 16.2852ZM5.9993 20.0562C5.31361 20.0562 4.79954 19.5419 4.79954 18.8563C4.79954 18.1707 5.31366 17.6564 5.9993 17.6564C6.68493 17.6564 7.19905 18.1707 7.19905 18.8563C7.19905 19.5419 6.68499 20.0562 5.9993 20.0562Z"
              fill="#0EAB70"
            />
          </svg>
        );
      case 'terminal':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <g clipPath="url(#clip0_2140_4111)">
              <path
                d="M20.0032 0.0137634C19.9803 0.0119171 19.961 0 19.9372 0H10.0831C8.56691 0 7.33307 1.23384 7.33307 2.75V3.66661C7.33307 4.17267 7.74362 4.58339 8.24968 4.58339C8.75574 4.58339 9.16629 4.17267 9.16629 3.66661V2.75C9.16629 2.24495 9.57785 1.83339 10.0831 1.83339H14.3538L14.0741 1.92688C13.3317 2.18352 12.8331 2.88293 12.8331 3.66661V17.4166H10.0831C9.57785 17.4166 9.16629 17.0051 9.16629 16.5V14.6666C9.16629 14.1607 8.75574 13.75 8.24968 13.75C7.74362 13.75 7.33307 14.1607 7.33307 14.6666V16.5C7.33307 18.0162 8.56691 19.25 10.0831 19.25H12.8331V20.1666C12.8331 21.1777 13.6552 22 14.6663 22C14.8625 22 15.0486 21.9716 15.2502 21.9092L20.7576 20.0731C21.501 19.8165 21.9997 19.1171 21.9997 18.3334V1.83339C21.9997 0.764542 21.0784 -0.073349 20.0032 0.0137634Z"
                fill="#0EAB70"
              />
              <path
                d="M9.81467 8.51865L6.14806 4.85203C5.88588 4.58986 5.49178 4.51097 5.14886 4.65314C4.80696 4.79513 4.58339 5.12982 4.58339 5.50009V8.25009H0.916611C0.410721 8.25009 0 8.66081 0 9.1667C0 9.67276 0.410721 10.0835 0.916611 10.0835H4.58339V12.8335C4.58339 13.2037 4.80696 13.5383 5.14886 13.6804C5.49178 13.8224 5.88588 13.7437 6.14806 13.4815L9.81467 9.81476C10.1732 9.4564 10.1732 8.87717 9.81467 8.51865Z"
                fill="#0EAB70"
              />
            </g>
            <defs>
              <clipPath id="clip0_2140_4111">
                <rect width="22" height="22" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
    }

    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M23.9906 16.9708V12.9427C23.9906 12.3428 23.8194 11.7428 23.5621 11.2286L20.9976 6.85768C20.7404 6.34345 20.1406 6.00063 19.5407 6.00063H15.684C15.0841 6.00063 14.5698 6.51486 14.5698 7.11479V16.2852H13.7127V4.28654C13.7127 3.34379 12.9415 2.57245 11.9986 2.57245H0V17.9993H2.57114C2.99966 16.5423 4.28523 15.4281 5.91351 15.4281C7.542 15.4281 8.91317 16.5423 9.25609 17.9993H15.5126C15.9411 16.5423 17.2267 15.4281 18.8549 15.4281C20.2263 15.4281 21.3403 16.1995 21.8546 17.3136C22.1118 17.7422 22.5338 17.9993 22.9623 17.9993C23.562 17.9993 24.0764 17.485 23.9906 16.9708ZM16.2839 12V7.71472H19.712L22.1974 12H16.2839Z" />
        <path d="M18.8553 16.2852C17.3984 16.2852 16.2842 17.3993 16.2842 18.8563C16.2842 20.3133 17.3984 21.4274 18.8553 21.4274C20.3122 21.4274 21.4265 20.3133 21.4265 18.8563C21.4265 17.3993 20.3122 16.2852 18.8553 16.2852ZM18.8553 20.0562C18.1696 20.0562 17.6556 19.5419 17.6556 18.8563C17.6556 18.1707 18.1697 17.6564 18.8553 17.6564C19.541 17.6564 20.0551 18.1707 20.0551 18.8563C20.0551 19.5419 19.541 20.0562 18.8553 20.0562Z" />
        <path d="M5.99887 16.2852C4.54195 16.2852 3.42773 17.3993 3.42773 18.8563C3.42773 20.3133 4.54195 21.4274 5.99887 21.4274C7.45579 21.4274 8.57 20.3133 8.57 18.8563C8.57 17.3993 7.45579 16.2852 5.99887 16.2852ZM5.99887 20.0562C5.31318 20.0562 4.79911 19.5419 4.79911 18.8563C4.79911 18.1707 5.31323 17.6564 5.99887 17.6564C6.6845 17.6564 7.19862 18.1707 7.19862 18.8563C7.19862 19.5419 6.68456 20.0562 5.99887 20.0562Z" />
      </svg>
    );
  }

  /**
   * Рендеринг компонента
   */
  render() {
    return <div className="transport-type-icon">{this.renderIcon()}</div>;
  }
}

// Экспортируем компонент
export default TransportTypeIcon;
