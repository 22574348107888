import {NumberFilterData} from "@pages/AdditionalServices/containers/types";
import React, {FC, useEffect, useState} from "react";
import {Slider} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {
  ServicePropertySliderSettings
} from "@onlog-public/additional-services-types";

export interface SliderFieldProps {
  data: NumberFilterData
  settings: ServicePropertySliderSettings
  onChange: (data: NumberFilterData) => void
}

const SliderField: FC<SliderFieldProps> = props => {
  const {data, settings, onChange} = props
  const [values, setValues] = useState([data.CurrentMin, data.CurrentMax])

  const {t} = useTranslation('AdditionalService')
  const fromPrefix = settings.FromValueMarkPrefix.length > 0
    ? t(settings.FromValueMarkPrefix)
    : t('AdditionalService:Templates.list.from')
  const fromSuffix = settings.FromValueMarkSuffix.length > 0
    ? t(settings.FromValueMarkSuffix)
    : ''
  const toPrefix = settings.ToValueMarkPrefix.length > 0
    ? t(settings.ToValueMarkPrefix)
    : t('AdditionalService:Templates.list.to')
  const toSuffix = settings.ToValueMarkSuffix.length > 0
    ? t(settings.ToValueMarkSuffix)
    : ''

  useEffect(() => {
    setValues([data.CurrentMin, data.CurrentMax])
  }, [data]);

  const from = [fromPrefix, values[0].toLocaleString(), fromSuffix]
    .filter(s => s.length > 0)
    .join(" ")
  const to = [toPrefix, values[1].toLocaleString(), toSuffix]
    .filter(s => s.length > 0)
    .join(" ")

  return (
    <div className="a-service-template-list-filter-slider-field">
      <div>
        <Slider
          value={values}
          onChange={(_, values: number[]) => setValues(values)}
          onChangeCommitted={(_, value: number[]) => onChange({
            ...data,
            CurrentMin: value[0],
            CurrentMax: value[1],
          })}
          valueLabelDisplay={settings.ValueLabelDisplay}
          step={settings.IsByMarksOnly ? null : settings.SliderStep}
          min={data.Min}
          max={data.Max}
          marks={settings.SliderPoints.map(p => ({
            value: p.value,
            label: t(p.label)
          }))}
        />
      </div>
      {settings.IsNeedShowCurrentValues && (
        <>
          <div className="caption">{from}</div>
          <div className="caption right">{to}</div>
        </>
      )}
    </div>
  )
}

export default SliderField