import { LocalizedMessage } from '@services/requests/localizedMessagesService/interfaces';
import { locationsBranchService } from '@services/requests/routeCalculator/locationsBranchSearchService';
import {
  BranchItem,
  SearchItem,
} from '@services/requests/routeCalculator/locationsBranchSearchService/interfaces';
import { localizedMessagesLoader } from '@services/requests/searchLoaders/localizedMessagesLoader';
import { useStateSubscriber } from '@settings/core/stateContexts/useContextSubscriber';
import { BehaviorSubject } from 'rxjs';

// Значение локации или терминала для передачи значения
export interface LocationOrTerminalValue {
  id: string;
  type: 'location' | 'terminal';
}

const $cache = new BehaviorSubject<BranchItem[]>([]);
const $cachedMessages = new BehaviorSubject<LocalizedMessage[]>([]);

/**
 * Поиск значений по переданной строке
 * @param searchString
 * @param types
 */
const onSearchValues = async (
  searchString: string,
  types: ('location' | 'terminal')[]
): Promise<BranchItem[]> => {
  const result = await locationsBranchService().SearchBranches(searchString);
  if (result.length === 0) {
    return [];
  }

  const cache = $cache.getValue();
  $cache.next([...cache, ...result]);

  return result.filter((item) => types.includes(item.type));
};

/**
 * Получение опции по переданному значению поля
 * @param value
 */
const getLocationByValue = async (value: LocationOrTerminalValue): Promise<BranchItem> => {
  const cache = $cache.getValue();
  const foundResult = cache.find((c) => c.id === value.id && c.type === value.type);
  if (foundResult) {
    return JSON.parse(JSON.stringify(foundResult));
  }

  const result = await locationsBranchService().GetItemByValue(value.id, value.type);
  const newCache = $cache.getValue();
  $cache.next([...newCache, JSON.parse(JSON.stringify(result))]);

  return result;
};

/**
 * Получение опции по переданному значению поля
 * @param value
 */
const getSyncLocationByValue = (value: LocationOrTerminalValue): BranchItem | undefined => {
  const cache = $cache.getValue();
  return cache.find((c) => c.id === value.id && c.type === value.type);
};

/**
 * Получение локализованных сообщений для переданных значений
 * @param values
 */
const getLocalizedMessagesForValues = async (values: SearchItem[]): Promise<LocalizedMessage[]> => {
  const messagesToLoad: string[] = [];
  values.map((value) => {
    messagesToLoad.push(...value.localizedNames);
  });

  const cachedMessages = $cachedMessages.getValue();

  const foundMessages: LocalizedMessage[] = JSON.parse(
    JSON.stringify(cachedMessages.filter((m) => messagesToLoad.includes(m.id)))
  );
  const foundMessagesIds = foundMessages.map((m) => m.id);

  const anotherMessagesToLoad = messagesToLoad.filter((m) => !foundMessagesIds.includes(m));
  if (anotherMessagesToLoad.length === 0) {
    return foundMessages;
  }

  const messages = await localizedMessagesLoader().Load(anotherMessagesToLoad);
  $cachedMessages.next([...$cachedMessages.getValue(), ...JSON.parse(JSON.stringify(messages))]);

  return [...foundMessages, ...messages];
};

/**
 * Получение локализованных сообщений для переданных значений
 * @param values
 */
export const getSyncLocalizedMessagesForValues = (values: SearchItem[]): LocalizedMessage[] => {
  const messagesToLoad: string[] = [];
  values.map((value) => {
    messagesToLoad.push(...value.localizedNames);
  });

  const cachedMessages = $cachedMessages.getValue();
  return JSON.parse(JSON.stringify(cachedMessages.filter((m) => messagesToLoad.includes(m.id))));
};

const actions = {
  onSearchValues,
  getLocationByValue,
  getLocalizedMessagesForValues,
  getSyncLocalizedMessagesForValues,
  getSyncLocationByValue,
};
const useLocationTerminalSearch = () => {
  const useCache = () => useStateSubscriber($cache);
  const useCachedMessages = () => useStateSubscriber($cachedMessages);

  return {
    useCache,
    useCachedMessages,
    actions,
  };
};

export default useLocationTerminalSearch;
