import {ServicesProperty, ServiceFieldType} from "@onlog-public/additional-services-types";
import {servicePropertyValueCacheContext$} from "@pages/AdditionalServices/containers/bus";
import {OptionsCache} from "@pages/AdditionalServices/containers/services/optionsLoader/types";
import optionsLoader from "@pages/AdditionalServices/containers/services/optionsLoader";
import propertyOptionsGenerator from "@pages/AdditionalServices/containers/services/propertyOptionsGenerator";
import {SelectOption} from "@pages/AdditionalServices/containers/services/optionsGenerator/types";

/**
 * getUnloadedValues возвращает все не загруженные идентификаторы для
 * всех базовых типов справочников. Вторым результатом возвращает флаг,
 * что загрузка данных требуется и кэш не полный.
 *
 * @param globalProperties
 * @param variantProperties
 * @param propertyValues
 */
const getUnloadedValues = (
  globalProperties: ServicesProperty[],
  variantProperties: { [T in string]: ServicesProperty[] },
  propertyValues: { [T in string]: { [K in string]: number[] } }
): { id: { [K in ServiceFieldType]: number[] }, isNeedLoad: boolean } => {
  const result: { [K in ServiceFieldType]: number[] } = {
    number: [],
    switch: [],
    hidden: [],
    tax: [],
    location: [],
    handbook: [],
    currency: [],
    consulting_contractor: [],
    inspection_contractor: [],
    customs_contractor: [],
    certification_contractor: [],
    insurance_contractor: []
  }

  /**
   * Получаем текущий кэш и формируем мапу всех свойств из переданных
   * данных. Свойства нужны для того, чтобы понять, какой кэш нужен.
   */
  const cache = servicePropertyValueCacheContext$.getValue().Cache
  const allProperties: { [T in string]: ServicesProperty } = {}

  const props = [
    ...globalProperties,
    ...Object.values(variantProperties).flat(1)
  ]

  props.map(p => allProperties[p.id] = p)

  /**
   * Выгружаем все не загруженные идентификаторы из свойств типа справочники.
   * По итогу получаем в результатах не уникальные идентификаторы, которые
   * надо загрузить.
   */
  Object.values(propertyValues).map(props => {
    Object.keys(props).map(propertyID => {
      const property = allProperties[propertyID]
      if (!property) {
        return
      }

      if (['switch', "number", "hidden"].includes(property.type)) {
        return
      }

      let values = props[propertyID]
      if (property.type === "handbook") {
        values = [property.handbook_id]
      }

      const currentCache: OptionsCache<any> = cache[property.type] ?? {isPartialLoading: true, cache: {}}

      return result[property.type] = [
        ...(result[property.type] ?? []),
        ...values.filter(id => !currentCache.cache[id]),
      ]
    })
  })

  /**
   * Получаем только уникальные идентификаторы справочников, которые надо
   * загрузить. Так же проверяем, что вообще данные для загрузки есть.
   */
  let isNeedLoad = false
  Object.keys(result).map((type: ServiceFieldType) => {
    if (result[type].length > 0) {
      isNeedLoad = true
    }

    result[type] = result[type].filter((d, i, data) => data.indexOf(d) === i)
  })

  return {
    id: result,
    isNeedLoad: isNeedLoad,
  }
}

/**
 * loadDataToCache выполняет загрузку переданных идентификаторов в кэш
 * стейта. После загрузки стейт обновится и только тогда промис закроется.
 * @param id
 */
const loadDataToCache = async (id: { [K in ServiceFieldType]: number[] }) => {
  const result = await Promise.all(
    Object.keys(id).map(async (type: ServiceFieldType) => {
      const keys = id[type].map(id => String(id))

      return {
        type: type,
        items: await optionsLoader().LoadOptionsCache(type, keys)
      }
    })
  )

  const state = servicePropertyValueCacheContext$.getValue()
  const newCache = {...state.Cache}

  result.map(r => {
    newCache[r.type] = {
      isPartialLoading: true,
      cache: {
        ...(newCache[r.type]?.cache ?? {}),
        ...r.items.cache,
      }
    }
  })

  servicePropertyValueCacheContext$.next({
    ...state,
    Cache: newCache,
  })
}

/**
 * makeOptionsForProperties формирует опции для свойств услуг.
 * Генерируемое значение пишется в стейт.
 *
 * @param globalProperties
 * @param variantProperties
 * @param propertyValues
 */
const makeOptionsForProperties = (
  globalProperties: ServicesProperty[],
  variantProperties: { [T in string]: ServicesProperty[] },
  propertyValues: { [T in string]: { [K in string]: number[] } }
) => {
  const state = servicePropertyValueCacheContext$.getValue()

  const allProperties: { [T in string]: ServicesProperty } = {}
  const props = [
    ...globalProperties,
    ...Object.values(variantProperties).flat(1)
  ]
  props.map(p => allProperties[p.id] = p)

  const optionsState: { [T in string]: { [K in string]: SelectOption[] } } = {}
  Object.keys(propertyValues).map(variantID => {
    optionsState[variantID] = {}
    Object.keys(propertyValues[variantID]).map(propertyID => {
      const property = allProperties[propertyID]
      if (!property) {
        return
      }

      if (['switch', "number", "hidden"].includes(property.type)) {
        return
      }

      const values = propertyValues[variantID][propertyID]
      optionsState[variantID][propertyID] = propertyOptionsGenerator().getOptions(
        property.type,
        state.LangID,
        String(property.handbook_id),
        values.map(v => String(v)),
        state.Cache[property.type],
      )
    })
  })

  servicePropertyValueCacheContext$.next({
    ...state,
    Options: optionsState,
  })
}

/**
 * loadServicePropertyValueCache загружает отсутствующие в кэше опции
 * свойств дополнительных услуг и генерирует их в стейте.
 *
 * @param globalProperties
 * @param variantProperties
 * @param propertyValues
 */
export const loadServicePropertyValueCache = async (
  globalProperties: ServicesProperty[],
  variantProperties: { [T in string]: ServicesProperty[] },
  propertyValues: { [T in string]: { [K in string]: number[] } }
) => {
  // Подгружаем недостающие данные в кэш, если это требуется
  const {id, isNeedLoad} = getUnloadedValues(globalProperties, variantProperties, propertyValues)
  if (isNeedLoad) {
    await loadDataToCache(id)
  }

  return makeOptionsForProperties(globalProperties, variantProperties, propertyValues)
}