import { LocationSelectorItemSubtitleProps } from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/LocationSelector/components/LocationSelectorItem/types';
import { getLocationLocalizedNames } from '@services/requests/localizedMessagesService/helpers/getLocationsLocalizedPath';
import { isEqualsForMemo } from '@settings/services/types';
import React, { forwardRef, memo } from 'react';

/**
 * контролируем перерендеринг
 * @param prevProps
 * @param nextProps
 */
const isEquals: isEqualsForMemo<LocationSelectorItemSubtitleProps> = (prevProps, nextProps) =>
  JSON.stringify([
    prevProps.option,
    prevProps.primaryLangId,
    prevProps.secondaryLangId,
    prevProps.subtitle,
  ]) ===
  JSON.stringify([
    nextProps.option,
    nextProps.primaryLangId,
    nextProps.secondaryLangId,
    nextProps.subtitle,
  ]);

/**
 * Компонент разделитель групп
 */
const LocationSelectorItemSubtitle = (props: LocationSelectorItemSubtitleProps) => {
  const { primaryLangId, secondaryLangId, option, subtitle } = props;

  const names = option ? getLocationLocalizedNames(option, primaryLangId, secondaryLangId) : '';
  let iso = option?.iso;
  if (option?.type === 'terminal' && option?.symbolCode !== 'ANY') {
    iso = option.subItems[0].iso;
  }

  if (names) {
    return <li className="subtitle">{`${names[secondaryLangId]} ${!!iso && `(${iso})`}`}</li>;
  }

  return <>{!!subtitle && <li className="subtitle">{subtitle}</li>}</>;
};

export default memo(LocationSelectorItemSubtitle, isEquals);
