import LocationSelectorItemOption from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/LocationSelector/components/LocationSelectorItem/LocationSelectorItemOption';
import LocationSelectorItemSubtitle from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/LocationSelector/components/LocationSelectorItem/LocationSelectorItemSubtitle';
import { LocationSelectorItemProps } from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/LocationSelector/components/LocationSelectorItem/types';
import React from 'react';

type ComponentProps = typeof LocationSelectorItemOption | typeof LocationSelectorItemSubtitle;

/**
 * Компонент обертка вывода элементов списка.
 * Определяет какой компонент выводить на основании типа элемента списка.
 * @param props
 */
const LocationSelectorItem = (props: LocationSelectorItemProps) => {
  const { type, onMouseEnter, onMouseLeave, ...restProps } = props;

  let Component: ComponentProps;

  switch (type) {
    case 'terminal':
    case 'location':
      Component = LocationSelectorItemOption;
      break;
    case 'subtitle':
      Component = LocationSelectorItemSubtitle;
      break;
  }

  return <Component {...restProps} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />;
};

export default LocationSelectorItem;
