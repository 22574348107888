import {ServiceFieldSettings} from "@onlog-public/additional-services-types";
import React, {FC} from "react";
import Checkbox from '@mui/material/Checkbox';
import MySwitch from "@components/MyMaterialUI/MySwitch";
import {FormControlLabel} from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";

interface CheckboxFieldProps {
  checked: boolean
  settings: ServiceFieldSettings
  isFieldsDisabled: boolean
  tooltip: string
  placeholder: string

  onChange: () => void
}

const CheckboxField: FC<CheckboxFieldProps> = props => {
  const {
    checked,
    settings,
    tooltip,
    placeholder,
    onChange,
    isFieldsDisabled,
  } = props

  const fieldSize = settings.FieldSize === "small" ? "small" : "medium"

  if (settings.SwitchFieldSettings.SwitchFieldVariant === "checkbox") {
    return <>
      <Tooltip
        placement="top"
        title={tooltip.length > 0 && (
          <div dangerouslySetInnerHTML={{__html: tooltip}} className="a-html-content"/>
        )}
      >
        <FormControlLabel
          className="a-service-field-checkbox"
          checked={checked}
          disabled={isFieldsDisabled || settings.IsNeedDisableField || settings.IsNeedDisplayOnlyTextInfo}
          onChange={onChange}
          classes={{
            label: "checkbox-label"
          }}
          control={
            <Checkbox
              size={fieldSize}
              checked={checked}
              onChange={onChange}
            />
          }
          label={placeholder}
        />
      </Tooltip>
    </>
  }

  return <>
    <Tooltip title={tooltip}>
      <FormControlLabel
        className="a-service-field-checkbox"
        checked={checked}
        disabled={settings.IsNeedDisableField || settings.IsNeedDisplayOnlyTextInfo}
        onChange={onChange}
        classes={{
          label: "checkbox-label"
        }}
        control={
          <MySwitch size={fieldSize}/>
        }
        label={placeholder}
      />
    </Tooltip>
  </>
}

export default CheckboxField