import { LocalizedMessage } from '../interfaces';

/**
 * Получение списка локализованных имен
 * @param messages
 * @param defaultName
 * @param languagesIds
 */
export const getLocalizedName: {
  <T extends string, U = { [K in T]: string }>(
    messages: LocalizedMessage[],
    defaultName: string,
    languagesIds: T[]
  ): U;
} = (messages, defaultName, languagesIds) =>
  languagesIds.reduce((result, lang) => {
    const currentMessage = messages.find((m) => m.lang_id === lang);
    result[lang] = currentMessage?.message || defaultName;
    return result;
  }, {} as any);
