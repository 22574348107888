import '@progress/kendo-theme-default/dist/all.css';

import createCache from '@emotion/cache';
import { StylisElement } from '@emotion/cache/dist/declarations/types';
import { CacheProvider } from '@emotion/react';
import { CssBaseline } from '@material-ui/core';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import { ThemeOptions, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import React, { ReactNode, useMemo } from 'react';

import breakpoints from './breakpoints';
import GlobalStyles from './globalStyles';
import componentsOverride from './overrides';
import palette from './palette';
import shadows, { customShadows } from './shadows';
import shape from './shape';
import typography from './typography';
import useSettings from './useSettings';

type ThemeConfigProps = {
  children: ReactNode;
};

const globalSelectorsPlugin = (element: StylisElement) => {
  const isNeed =
    typeof element.props !== 'string' && element.type === 'rule' && element.props[0].includes('.');
  element.props = isNeed ? ['#onlog-widget-container ' + element.props] : element.props;
};

// Создайте кастомный экземпляр кэша стилей с префиксом
const cacheWithPrefix = createCache({
  key: 'w-', // Укажите свой желаемый префикс здесь
  stylisPlugins: [globalSelectorsPlugin],
});

export default function ThemeConfig({ children }: ThemeConfigProps) {
  const { themeMode, themeDirection } = useSettings();
  const isLight = themeMode === 'light';

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: isLight ? { ...palette.light, mode: 'light' } : { ...palette.dark, mode: 'dark' },
      typography,
      shape,
      breakpoints,
      direction: themeDirection,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    }),
    [isLight, themeDirection]
  );

  const theme = createMuiTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        <CacheProvider value={cacheWithPrefix}>{children}</CacheProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
