import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TransportTypeIcon from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesListComponent/TransportTypeIcon';
import { CurrencyData } from '@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatPrice } from '@pages/OfferDetail/helpers';

// AdditionCostCaptionProps описывает свойства компонента вывода
// заголовочной части доп услуг перевозки
type AdditionCostCaptionProps = {
  cost: number;
  targetCurrency: CurrencyData;
};

// Компонент вывода заголовочной части доп услуг перевозки
const AdditionCostCaption = ({ cost, targetCurrency }: AdditionCostCaptionProps) => {
  const { t } = useTranslation(['RoutesListComponent']);
  return (
    <div className={'offer-group-header offer-group-header__container-rental'}>
      <Box className="offer-group-header__title">
        <div className="route-shoulder--icons-container">
          <TransportTypeIcon transportType={'terminal'} />
        </div>
        <Typography variant={'subtitle1'} component={'div'}>
          {t('RoutesListComponent:ContainerRental')}
        </Typography>
      </Box>
      <Typography variant={'subtitle1'} className={'price'} component={'div'}>
        {formatPrice(cost)} {targetCurrency.code}
      </Typography>
    </div>
  );
};

export default AdditionCostCaption;
