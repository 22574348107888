import {servicePropertyValueCacheContext$} from "@pages/AdditionalServices/containers/bus";

/**
 * initPropertyValueOptions реализует функционал инициализации шины
 * кэша опций свойств услуг.
 * @param langID
 */
const initPropertyValueOptions = (langID: string) => {
  servicePropertyValueCacheContext$.next({
    ...servicePropertyValueCacheContext$.getValue(),
    LangID: langID,
  })
}

export default initPropertyValueOptions