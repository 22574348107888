import { Theme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

export default function Switch(theme: Theme) {
  return {
    MuiSwitch: {
      defaultProps: {
        container: document.getElementById('onlog-widget-container'),
        color: 'primary',
      },
      styleOverrides: {
        root: {
          height: '20px',
          width: '46px',
          padding: '3px 12px',
        },
        FormControlLabel: {
          marginLeft: 0,
        },
        thumb: {
          boxShadow: theme.customShadows.z1,
        },
        track: {
          opacity: 1,
          backgroundColor: theme.palette.grey[500],
        },
        switchBase: {
          left: 0,
          right: 'auto',
          padding: 0,
          '&:not(:.Mui-checked)': {
            color: theme.palette.grey[100],
          },
          '&.Mui-checked.Mui-disabled, &.Mui-disabled': {
            color: theme.palette.grey[400],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.action.disabledBackground,
          },
        },
      },
    },
  };
}
