import {clone} from "@pages/AdditionalServices/containers/methods/clone";
import {serviceContext$} from "@pages/AdditionalServices/containers/bus";
import {v4} from "uuid";

/**
 * resetSelectedDirectory снимает выбранные директории пользователем, если
 * он ушел из дополнительных услуг.
 */
export const resetSelectedDirectory = () => {
  const {...state} = clone(serviceContext$.getValue())
  serviceContext$.next({
    ...state,
    ServicesPath: {},
    IsNeedCalculateServices: false,
    Variants: [],
    SelectedVariant: "",
    SelectedProductUUID: v4(),
    VariantServices: {},
    VariantSelectedServices: {},
    VariantFields: {},
    VariantFieldValues: {},
    ServiceTemplateStore: {},
    VariantsCalculationResult: {},
    LastServiceInPath: undefined,
    IsLoadingDirectoriesForPath: false,
  })
}
