import { $error } from '@settings/errorContext';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

const ErrorController = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState<any>(undefined);

  useEffect(() => {
    $error.subscribe((error) => {
      if (error !== undefined) {
        enqueueSnackbar(error, { variant: 'error' });
      }
      setError(error);
    });
  }, []);

  useEffect(() => {
    if (
      error === undefined ||
      error.config.url.includes('/graphql?get_currency') ||
      error.config.url.includes('/graphql?countries')
    ) {
      return;
    }

    throw new Error(`Error: ${error}`);
  }, [error]);

  return <></>;
};

export default ErrorController;
