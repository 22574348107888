import {Service, ServiceField} from "@onlog-public/additional-services-types";
import {VerticalTableTemplateStore} from "@pages/AdditionalServices/components/Service/VerticalTableTemplate/types";
import {
  serviceInitialStateGeneratorProcessor
} from "@pages/AdditionalServices/containers/services/serviceInitialStateGenerator/types";
import {v4} from "uuid";

/**
 * VerticalTableTemplateProcessor создает базовый стейт для услуг типа "Вертикальная таблица"
 */
export class VerticalTableTemplateProcessor implements serviceInitialStateGeneratorProcessor {
  /**
   * isAvailable проверяет доступность процессора для генерации
   * @param service
   */
  isAvailable(service: Service): boolean {
    return service.template === "verticalTable"
  }

  /**
   * generate выполняет генерацию стейта по полям и услуге. Возвращает сам стейт.
   * @param service
   * @param fields
   * @param values
   */
  generate(service: Service, fields: ServiceField[], values: { [x: string]: number; }) {
    const result: VerticalTableTemplateStore = {
      rows: {},
      rowsDirection: {},
    }

    const costField = fields.find(f => f.code === service.settings.TemplateSettings.VerticalTable.CostColumnField)
    const qtyField = fields.find(f => f.code === service.settings.TemplateSettings.VerticalTable.RowQuantityField)

    if (!qtyField || !costField || !values[qtyField.code]) {
      return result
    }

    for (let i = 0; i < values[qtyField.code]; i++) {
      const rowID = v4()
      result.rows[rowID] = values[costField.code] ?? 0
      result.rowsDirection[rowID] = i
    }

    return result
  }

}

