// ----------------------------------------------------------------------

export default function Grid() {
  return {
    MuiGrid: {
      defaultProps: {
        container: document.getElementById('onlog-widget-container'),
      },
      styleOverrides: {},
    },
  };
}
