import React, {FC} from "react";
import {PropertyFieldDisplayProps} from "@pages/AdditionalServices/components/ServiceDirectory/ListTypeTemplate/types";
import {ListFilterData} from "@pages/AdditionalServices/containers/types";
import ListDisplay from "@pages/AdditionalServices/components/ServiceDirectory/ListTypeTemplate/ListDisplay";

const ListFieldFilter: FC<PropertyFieldDisplayProps<"location">> = props => {
  const {data, onChange} = props
  const {Options, Selected} = (data.FilterData as ListFilterData)
  const settings = props.data.Property.settings.Filter.ListSettings

  return (
    <ListDisplay
      options={Options}
      selected={Selected}
      settings={settings}
      onChange={selected => {
        onChange({
          ...data,
          FilterData: {
            ...data.FilterData,
            Selected: selected
          }
        })
      }}
    />
  )
}

export default ListFieldFilter