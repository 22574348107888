import { GraphQLQuery } from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

import { LocalizedMessage } from '../../localizedMessagesService/interfaces';

// Данные перевозчика
export interface ContainerTypeData {
  id: string;
  default_name: string;
  localized_names: string[];
  localized_names_data: LocalizedMessage[];
}

export interface ContainerTypeLoaderQueryResponse {
  items: ContainerTypeData[];
}

/**
 * Запрос загрузки данных по перевозчикам
 */
export class ContainerTypeLoaderQuery implements GraphQLQuery<null> {
  readonly query: any;

  readonly variables: null;

  constructor(ids?: any[]) {
    this.variables = null;
    this.query = gql`
            query LoadContainerTypes {
                items: transport_container_type_list(
                    limit: 1000000
                    ${
                      ids && ids.length > 0
                        ? `where:{id:{_in: [${ids.map((id) => `"${id}"`).join(',')}]}}`
                        : ``
                    }
                ) {
                    id
                    default_name
                    localized_names
                }
            }
        `;
  }
}
