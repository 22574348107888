import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const FavoriteIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} fill="none">
    <path
      stroke="currentColor"
      fill="none"
      strokeWidth="1.5"
      d="M19.79 10.282a.155.155 0 0 1 .087.27l-3.71 3.253-.336.294.099.436 1.093 4.816a.153.153 0 0 1-.059.16.153.153 0 0 1-.17.008l-4.23-2.53-.385-.23-.385.23-4.232 2.53a.152.152 0 0 1-.167-.007h-.001a.153.153 0 0 1-.06-.16l1.094-4.817.099-.436-.336-.294-3.71-3.253a.155.155 0 0 1 .088-.27l4.907-.446.446-.04.176-.412 1.94-4.54a.154.154 0 0 1 .283.001l1.94 4.54.175.41.446.041 4.908.446Z"
    />
  </SvgIcon>
);

export default FavoriteIcon;
