import React, {useState} from "react";
import CardWrapper from "@components/UI/CardWrapper";
import Fab from "@mui/material/Fab";
import SearchIcon from "@components/icons/SearchIcon";
import DirectorySelector from "@pages/AdditionalServices/components/DirectorySelector";
import useAdditionServicesContext from "@pages/AdditionalServices/containers/additionServicesContext";
import useMyRouting from "@context/useMyRouting/useMyRouting";
import AdditionServiceFields from "@pages/AdditionalServices/components/FieldDisplay/AdditionServiceFields";
import useAServiceOptions from "@pages/AdditionalServices/containers/additionServicesOptionsContext";
import {BitrixDisableUserGuard} from "@components/guards/bitrixUserGuard/BitrixUserGuard";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import LinkIcon from "@mui/icons-material/Link";
import palette from "@assets/theme/palette";
import {ShareMenu} from "@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/ShareMenu";
import CloseIcon from "@material-ui/icons/Close";
import {useTranslation} from "react-i18next";
import clsx from "clsx";

const AServiceWidgetContainer = () => {
  const {
    state,
    setSelectedDirectory,
    setFieldValue,
    onCalculateServices,
  } = useAdditionServicesContext()
  const [settings] = useMyRouting().useSettings().useState();
  const {options} = useAServiceOptions()
  const {t} = useTranslation([
    'RoutesCalculationWidget',
    'Actions',
    'LocationSelector',
    'Common',
    'AdditionalService',
  ]);

  const isNoDataForDirectories = Object.keys(state.ServicesPath).length < 2
    && state.IsLastLevelSelected

  /**
   * Стейт валидации значений полей виджета. Все списочные поля должны быть заполнены.
   */
  const [validation, setValidation] = useState<{ [T in string]: string }>()
  const isValidationHasError = (validation?: { [T in string]: string }) => {
    if (!validation) {
      return false
    }

    let isError = false
    Object.keys(validation).map(field => {
      isError = isError || validation[field].length > 0
    })

    return isError
  }

  /**
   * Обработчик кнопки поиска услуг. Проверяет заполненность полей и вызывает поиск,
   * если валидация успешна.
   */
  const handleSearchServices = () => {
    const validationResult: { [T in string]: string } = {}
    state.FieldsToDisplay.map(field => {
      if (['switch', 'hidden', 'number'].includes(field.type)) {
        return
      }

      if (state.FieldValues[field.code] <= 0) {
        validationResult[field.code] = t('AdditionalService:NeedChooseValue')
      }
    })

    if (isValidationHasError(validationResult)) {
      return setValidation(validationResult)
    }

    onCalculateServices()
  }

  /**
   * При изменении значения поля сбрасывается и валидация для него.
   * @param field
   * @param value
   * @param isFieldChange
   */
  const handleChangeFieldValue = (
    field: string,
    value: number,
    isFieldChange: boolean = false,
  ) => {
    setValidation(v => ({...v, [field]: ""}))
    setFieldValue(field, value, isFieldChange)
  }

  return (
    <CardWrapper sx={{padding: '30px 40px'}}>
      <div className="a-service-widget-form">
        {!isNoDataForDirectories && (
          <div className="selectors">
            <DirectorySelector
              langID={settings.primaryLanguageId}
              isLoading={state.IsLoadingDirectoriesForPath}
              selectedPath={state.ServicesPath}
              onChange={setSelectedDirectory}
            />
          </div>
        )}
        <div className="buttons">
          <Fab
            color={'primary'}
            className={'form-wrapper__search'}
            onClick={handleSearchServices}
            disabled={!state.IsLastLevelSelected && !isValidationHasError(validation)}
            size={'medium'}
          >
            <SearchIcon sx={{fontSize: 25, color: 'common.white'}}/>
          </Fab>
        </div>
        {state.IsLastLevelSelected && (
          <>
            <div className={clsx("fields", {
              "single-row": isNoDataForDirectories
            })}>
              <AdditionServiceFields
                fields={state.FieldsToDisplay}
                fieldValues={state.FieldValues}
                langID={settings.primaryLanguageId}
                onChange={handleChangeFieldValue}
                isOptionsLoading={options.Loadings.length > 0}
                options={options.FieldOptions}
                templateSettings={{}}
                isFieldsDisabled={false}
                validation={validation}
              />
            </div>
            <div className="share">
              <BitrixDisableUserGuard>
                <Tooltip title={t('RoutesCalculationWidget:CopyCalculationLink')}>
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => null}
                  >
                    <LinkIcon
                      sx={{transform: 'rotate(-45deg)'}}
                      style={{color: palette.light.grey[700]}}
                    />
                  </IconButton>
                </Tooltip>
              </BitrixDisableUserGuard>
              <ShareMenu/>
              <Tooltip title={t('RoutesCalculationWidget:ResetCalculationOptions')}>
                <IconButton color="primary" size="small" onClick={() => {
                  setSelectedDirectory(0, state.ServicesPath['0'])
                }}>
                  <CloseIcon style={{color: palette.light.error.main}}/>
                </IconButton>
              </Tooltip>
            </div>
          </>
        )}
      </div>
    </CardWrapper>
  )
}

export default AServiceWidgetContainer