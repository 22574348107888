import {ServiceField} from "@onlog-public/additional-services-types";
import {SetUpServiceData} from "@pages/AdditionalServices/containers/methods/basket/types";

/**
 * getDirectoryFieldsFromData извлекает поля родительских директорий для
 * переданной услуги. По сути возвращает все поля, что выводились в
 * виджете, а так же все поля выбранного варианта.
 *
 * @param setUpService
 */
const getDirectoryFieldsFromData = (
  setUpService: SetUpServiceData,
): ServiceField[] => {
  return Array.from([...Object.values(setUpService.ServicesPath), setUpService.Variant])
    .map(d => d.fields)
    .flat(1)
    .filter((d, i, data) => data.map(d => d.id).indexOf(d.id) === i)
}

export default getDirectoryFieldsFromData