import React, {FC} from "react";
import {
  AdditionServiceFieldsProps
} from "@pages/AdditionalServices/components/FieldDisplay/AdditionServiceFields/types";
import AdditionServiceField from "@pages/AdditionalServices/components/FieldDisplay/AdditionServiceField";
import {ServiceField, ServiceFieldSettings} from "@onlog-public/additional-services-types";

/**
 * sortFields реализует функцию сортировки полей по приоритету.
 * Если у полей одинаковый приоритет, то они сортируются по ID.
 * @param templateSettings
 */
const sortFields = (templateSettings: { [T in string]: ServiceFieldSettings }) => {
  return (a: ServiceField, b: ServiceField) => {
    const aSettings = templateSettings[a.code] ?? a.settings.DefaultSettings
    const bSettings = templateSettings[b.code] ?? b.settings.DefaultSettings

    if (aSettings.PositionInRow === bSettings.PositionInRow) {
      return parseInt(a.id) > parseInt(b.id) ? 1 : -1
    }

    return aSettings.PositionInRow > bSettings.PositionInRow ? 1 : -1
  }
}

const AdditionServiceFieldsRow: FC<AdditionServiceFieldsProps> = props => {
  const {
    templateSettings,
    options,
    langID,
    fields,
    fieldValues,
    onChange,
    isOptionsLoading,
    isFieldsDisabled,
    validation = {},
  } = props

  const fieldsToDisplay = fields.sort(sortFields(templateSettings))

  return (
    <div className="addition-service-fields-row">
      {fieldsToDisplay.map(f => (
        <AdditionServiceField
          key={f.id}
          field={f}
          value={fieldValues[f.code]}
          templateSettings={templateSettings[f.code]}
          isOptionsLoading={isOptionsLoading}
          isFieldsDisabled={isFieldsDisabled}
          langID={langID}
          onChange={v => onChange(f.code, v)}
          options={options[f.code]}
          error={validation[f.code]}
        />
      ))}
    </div>
  )
}

export default AdditionServiceFieldsRow