import './settings/localization/i18n';

import PointInit from '@app/PointInit';
import PointMenuWidget from '@app/PointMenuWidget';
import PointPrimaryLang from '@app/PointPrimaryLang';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReportHandler } from 'web-vitals';

import PointWidget from './app';
import PointCurrency from './app/PointCurrency';

const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

const initContainer = document.getElementById('onlog-widget-init');

if (initContainer) {
  const initWidget = ReactDOM.createRoot(initContainer);
  initWidget.render(<PointInit />);
}

const widgetContainer = document.getElementById('onlog-widget-container');

if (widgetContainer) {
  const mainWidget = ReactDOM.createRoot(widgetContainer);
  mainWidget.render(<PointWidget />);
}

const widgetMenuContainer = document.getElementById('onlog-menu-widget-container');

if (widgetMenuContainer) {
  const menuWidget = ReactDOM.createRoot(widgetMenuContainer);
  menuWidget.render(<PointMenuWidget />);
}

const currencySelector = document.getElementById('currencySelector');
const currencyController = document.getElementById('currencyController');

if (currencySelector && currencyController) {
  const currencyDom = ReactDOM.createRoot(currencyController);
  currencySelector.addEventListener('change', (event) => {
    // @ts-ignore
    const currentCurrency = event.target.value;
    currencyDom.render(<PointCurrency currency={currentCurrency} />);
  });
}

const primaryLangSelector = document.getElementById('primaryLangSelector');
const primaryLangController = document.getElementById('primaryLangController');

if (primaryLangSelector && primaryLangController) {
  const currencyDom = ReactDOM.createRoot(primaryLangController);
  primaryLangSelector.addEventListener('change', (event) => {
    // @ts-ignore
    const primaryLanguageId = event.target.value;
    currencyDom.render(<PointPrimaryLang primaryLanguageId={primaryLanguageId} />);
  });
}

const secondaryLangSelector = document.getElementById('secondaryLangSelector');
const secondaryLangController = document.getElementById('secondaryLangController');

if (secondaryLangSelector && secondaryLangController) {
  const currencyDom = ReactDOM.createRoot(secondaryLangController);
  secondaryLangSelector.addEventListener('change', (event) => {
    // @ts-ignore
    const currentCurrency = event.target.value;
    currencyDom.render(<PointCurrency currency={currentCurrency} />);
  });
}

reportWebVitals();
