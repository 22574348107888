import React, {FC} from "react";
import useAdditionServicesContext from "@pages/AdditionalServices/containers/additionServicesContext";
import ServiceListLoading from "@pages/AdditionalServices/components/ServiceListLoading";
import VariantsTabs from "@pages/AdditionalServices/components/VariantsTabs";
import ServiceDescription from "@pages/AdditionalServices/components/ServiceDescription";
import ServiceDirectory from "@pages/AdditionalServices/components/ServiceDirectory";
import useAServiceOptions from "@pages/AdditionalServices/containers/additionServicesOptionsContext";
import useBasket from "@pages/Basket/useBasket";
import makeServiceData from "@pages/AdditionalServices/containers/methods/basket/makeServiceData";
import {SetUpServiceData} from "@pages/AdditionalServices/containers/methods/basket/types";
import {Service} from "@onlog-public/additional-services-types";

const AServiceDataContainer: FC = () => {
  const {
    state,
    onChangeVariant,
    setFieldValue,
    onChangeServiceFieldValue,
    onChangeServiceTemplateStore,
    onChangeServiceSelectionState,
    useAdditionServiceCache,
    useServicePropertyValueCache,
    usePropertyFilterAndOrder,
  } = useAdditionServicesContext()
  const {cache} = useAdditionServiceCache()
  const {options} = useAServiceOptions()
  const {propertyValueOptions} = useServicePropertyValueCache()
  const {filterAndOrderData, onChangeFilterData} = usePropertyFilterAndOrder()
  const {actions: {addToCartGroupOfServices, addServiceToCart}, useOrder} = useBasket()
  const [order] = useOrder().useState()

  if (!state.IsNeedCalculateServices) {
    return null
  }

  const selectedVariant = state.Variants.find(v => v.id === state.SelectedVariant)
  if (!selectedVariant || state.IsServicesLoading) {
    return (
      <div className="a-service-data">
        <ServiceListLoading/>
      </div>
    )
  }

  if (
    !propertyValueOptions.Options[state.SelectedVariant] ||
    !filterAndOrderData.Variants[state.SelectedVariant]
  ) {
    return (
      <div className="a-service-data">
        <ServiceListLoading/>
      </div>
    )
  }

  // Обработки снятия включенности услуги в заказ
  const handleUnSelectService = (service: Service) => {
    const services = [service.id, ...service.additionServices.map(s => s.id)]

    onChangeServiceSelectionState(state.SelectedVariant, selections => {
      return selections.filter(selection => !services.includes(selection))
    })
  }

  // Обработчик включения услуг в заказ
  const handleSelectService = (service: Service) => {
    const services = [service.id, ...service.additionServices.map(s => s.id)]

    onChangeServiceSelectionState(state.SelectedVariant, selections => {
      return [...selections, ...services]
    })
  }

  return (
    <div className="a-service-data">
      {state.LastServiceInPath.is_variants && (
        <VariantsTabs
          langID={state.LanguageID}
          currency={cache.Cache.currency.cache[state.CurrencyID]}
          isServicesLoading={state.IsServicesCalculating}
          services={state.VariantsCalculationResult}
          variants={state.Variants}
          selectedServices={state.VariantSelectedServices}
          selectedVariant={state.SelectedVariant}
          onSelectVariant={v => onChangeVariant(v)}
        />
      )}
      <ServiceDescription
        langID={state.LanguageID}
        variant={state.SelectedVariant}
        variants={state.Variants}
        servicesPath={state.ServicesPath}
        contractorsCache={cache.Cache}
      />
      <ServiceDirectory
        langID={state.LanguageID}
        currencyID={state.CurrencyID}
        order={order}
        selectedProductUUID={state.SelectedProductUUID}
        isServicesCalculating={state.IsServicesCalculating}
        variant={selectedVariant}
        mainDirectory={state.ServicesPath["0"]}
        isOrderDisplay={false}
        isOptionsLoading={options.Loadings.length > 0}
        globalFiles={Object.values(state.ServicesPath).map(s => s.file_id).flat(1)}
        options={options.FieldOptions}
        services={state.VariantServices[state.SelectedVariant]}
        globalFields={state.FieldsToDisplay}
        servicesFields={state.VariantFields[state.SelectedVariant]}
        globalFieldValues={state.FieldValues}
        servicesFieldValues={state.VariantFieldValues[state.SelectedVariant]}
        serviceCalculations={state.VariantsCalculationResult[state.SelectedVariant]}
        serviceDataCache={cache.Cache}
        serviceTemplateStore={state.ServiceTemplateStore[state.SelectedVariant]}
        globalProperties={state.PropertiesToDisplay}
        serviceProperties={state.VariantProperties[state.SelectedVariant]}
        propertyValues={state.PropertyValues[state.SelectedVariant]}
        propertyOptions={propertyValueOptions.Options[state.SelectedVariant]}
        propertyFilterAndOrder={filterAndOrderData.Variants[state.SelectedVariant]}
        onChangeGlobalFieldValue={(field, value) => setFieldValue(field, value, true)}
        onChangeServiceFieldValue={onChangeServiceFieldValue}
        onChangeServiceTemplateStore={onChangeServiceTemplateStore}
        onAddToCart={async services => {
          try {
            const data = makeServiceData(state.SelectedVariant, services)
            if (data.length === 0) {
              return
            }

            const service = data[0] as SetUpServiceData
            if (!service?.IsSetupService) {
              return
            }

            await addServiceToCart(service)
          } catch (e) {
            // TODO: Ошибка искуственная, генерится, когда данные еще не долетели.
            //       придумать и реализовать переотправку запроса с таймаутами в 1-2 сек.
            console.warn(e)
          }
        }}
        onAddGroupToCart={async services => {
          try {
            await addToCartGroupOfServices(
              makeServiceData(
                state.SelectedVariant,
                (services ?? state.VariantsCalculationResult[state.SelectedVariant]).filter(
                  s => state.VariantSelectedServices[state.SelectedVariant].includes(s.serviceId)
                ),
              )
            )
          } catch (e) {
            // TODO: Тут тоже
            console.warn(e)
          }
        }}
        onChangeFilterAndOrder={callback => {
          onChangeFilterData(state.SelectedVariant, callback)
        }}
        selectedServices={state.VariantSelectedServices[state.SelectedVariant]}
        onSelectService={handleSelectService}
        onUnSelectService={handleUnSelectService}
      />
    </div>
  )
}

export default AServiceDataContainer