import React, {FC} from "react";
import {ServiceProps} from "@pages/AdditionalServices/components/Service/types";
import getLocalizationByArgs from "@helpers/getLocalizationByArgs";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import PriceColumnsList
  from "@pages/AdditionalServices/components/Service/ServiceInCargoDeclarationTemplate/PriceColumnsList";
import ServiceFieldsList from "@pages/AdditionalServices/components/Service/ServiceFieldsList";
import CostColumn from "@pages/AdditionalServices/components/Service/CostColumn";

const ServiceInCargoDeclarationTemplate: FC<ServiceProps> = props => {
  const {
    langID,
    service,
  } = props

  const name = getLocalizationByArgs(langID, service.name, service.localized_names)
  const description = getLocalizationByArgs(langID, service.description, service.localized_descriptions)

  return <>
    <div className="a-service-template-serviceInCargoDeclaration-name-column no-left-border">
      <div>
        {description.length > 0 && (
          <Tooltip
            placement="top"
            title={(<div dangerouslySetInnerHTML={{__html: description}} className="a-html-content"/>)}
          >
            <IconButton sx={{width: 16, height: 16, backgroundColor: '#EFF3FB'}}>
              <QuestionMarkIcon sx={{fontSize: 8, color: "#707070"}}/>
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div>
        <Tooltip
          placement="top"
          title={description.length > 0 && (
            <div dangerouslySetInnerHTML={{__html: description}} className="a-html-content"/>
          )}
        >
          <span>{name}</span>
        </Tooltip>
      </div>
    </div>
    <CostColumn {...props} />
    <div className="a-service-template-serviceInCargoDeclaration-quantity">
      <ServiceFieldsList {...props}/>
    </div>
    <PriceColumnsList {...props}/>
  </>
}

export default ServiceInCargoDeclarationTemplate