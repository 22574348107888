import Actions from './es/Actions.json';
import AdditionalService from './es/AdditionalService.json';
import AppSettings from './es/AppSettings.json';
import BenefitsOfWorking from './es/BenefitsOfWorking.json';
import BenefitsOfWorkingProfile from './es/BenefitsOfWorkingProfile.json';
import CartAsideBlock from './es/CartAsideBlock.json';
import CartComponent from './es/CartComponent.json';
import CartSummary from './es/CartSummary.json';
import Common from './es/Common.json';
import CustomsCalculator from './es/CustomsCalculator.json';
import CustomsPayments from './es/CustomsPayments.json';
import DeliveryForm from './es/DeliveryForm.json';
import DeliveryItem from './es/DeliveryItem.json';
import EmptyStyleSelect from './es/EmptyStyleSelect.json';
import FilterComponent from './es/FilterComponent.json';
import FooterBottomBar from './es/FooterBottomBar.json';
import LocationSelector from './es/LocationSelector.json';
import OfferContainers from './es/OfferContainers.json';
import OfferDetail from './es/OfferDetail.json';
import OfferTipsText from './es/OfferTipsText.json';
import OrderForm from './es/OrderForm.json';
import OrderList from './es/OrderList.json';
import PageNames from './es/PageNames.json';
import RoutesCalculationWidget from './es/RoutesCalculationWidget.json';
import RoutesListComponent from './es/RoutesListComponent.json';
import SortComponent from './es/SortComponent.json';
import TubsContainer from './es/TubsContainer.json';
import TYOrder from './es/TYOrder.json';

export const es = {
  Actions,
  AdditionalService,
  AppSettings,
  BenefitsOfWorking,
  BenefitsOfWorkingProfile,
  CartAsideBlock,
  CartComponent,
  CartSummary,
  Common,
  CustomsCalculator,
  CustomsPayments,
  DeliveryForm,
  DeliveryItem,
  EmptyStyleSelect,
  FilterComponent,
  FooterBottomBar,
  LocationSelector,
  OfferContainers,
  OfferDetail,
  OfferTipsText,
  OrderForm,
  OrderList,
  PageNames,
  RoutesCalculationWidget,
  RoutesListComponent,
  SortComponent,
  TubsContainer,
  TYOrder,
};
