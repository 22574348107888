import React, {FC} from "react";
import {ServiceItem} from "@components/pages/Products/components/ServiceSectionDetail/types";
import {useTranslation} from "react-i18next";
import restoreServiceData from "@pages/AdditionalServices/containers/methods/basket/restoreServiceData";
import getServicePathFromData from "@pages/AdditionalServices/containers/methods/basket/getServicePathFromData";
import getLocalizationByArgs from "@helpers/getLocalizationByArgs";
import getFieldValuesFromData from "@pages/AdditionalServices/containers/methods/basket/getFieldValuesFromData";
import getDirectoryFieldsFromData from "@pages/AdditionalServices/containers/methods/basket/getDirectoryFieldsFromData";
import {CurrencyData} from "@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery";
import GroupServiceDetails
  from "@pages/Basket/components/ServicesList/components/OrderList/components/OrderListItem/components/ServiceBasketItem/GroupServiceDetails";
import getContractorFromDirectory
  from "@pages/AdditionalServices/components/ServiceDescription/getContractorFromDirectory";

const GroupServiceItem: FC<ServiceItem> = props => {
  const {
    product,
    langID,
  } = props
  const {t} = useTranslation('OfferDetail')

  const restoreResult = restoreServiceData(product)
  if (!restoreResult) {
    return null
  }

  const {setUpService, otherServices} = restoreResult

  const path = getServicePathFromData(langID, setUpService)

  const {fields, values: fieldValues} = getFieldValuesFromData(langID, [
    ...setUpService.ServiceData.fields,
    ...getDirectoryFieldsFromData(setUpService),
  ], setUpService)

  const targetCurrency: CurrencyData = setUpService.ServiceDataCache.currency.cache[String(setUpService.service.currency_id ?? "")]
  const isOnRequest = setUpService.service.price_offer_result.result.fullPriceInTargetCurrency <= 0

  if (!isOnRequest && !targetCurrency) {
    return null
  }

  const totalTax = [setUpService, ...otherServices].reduce(
    (v, s) => v + s.service.price_offer_result.result.taxInTargetCurrency,
    0,
  )

  const totalConversion = [setUpService, ...otherServices].reduce(
    (v, s) => v + s.service.price_offer_result.result.conversionFee,
    0,
  )

  const contractor = getContractorFromDirectory(langID, setUpService.Variant, setUpService.ServiceDataCache)

  return <>
    <div className="a-service-basket-group">
      <div className="group-caption">
        <div>{path}</div>
        <div className="fields">
          <div><span>{t('AdditionalService:ServiceDescriptionContractor')}:</span> {contractor}</div>
          {fields.map(field => {
            let displayFieldName = getLocalizationByArgs(langID, field.name, field.localized_names)
            if (field.type !== "switch") {
              if (
                field.settings.DefaultSettings.IsNeedHideName ||
                displayFieldName.length === 0
              ) {
                displayFieldName = getLocalizationByArgs(langID, field.placeholder, field.localized_placeholders)
              }
            }

            return (
              <div key={field.code}><span>{displayFieldName}:</span> {fieldValues[field.code]}</div>
            )
          })}
        </div>
      </div>
      <div className="group-price">
        <div>{t('OfferDetail:services.simpleService.total')}: {product.price.toLocaleString()} {targetCurrency.code}</div>
        <div>{t('OfferDetail:services.simpleService.tax')}: <span>{totalTax.toLocaleString()} {targetCurrency.code}</span>
        </div>
        <div>{t('OfferDetail:services.simpleService.conversion')}: <span>{totalConversion.toLocaleString()} {targetCurrency.code}</span>
        </div>
      </div>
    </div>
    <GroupServiceDetails
      product={product}
      langID={langID}
    />
  </>
}

export default GroupServiceItem