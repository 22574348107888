import {VariantFilter} from "@pages/AdditionalServices/containers/types";
import React, {FC} from "react";
import SliderField from "@pages/AdditionalServices/components/ServiceDirectory/ListTypeTemplate/SliderField";
import {ServiceFieldType, ServicePropertySliderSettings} from "@onlog-public/additional-services-types";
import {useTranslation} from "react-i18next";
import {OptionsCache} from "@pages/AdditionalServices/containers/services/optionsLoader/types";
import {CurrencyData} from "@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery";

export interface PriceFilterProps {
  currencyID: string
  serviceDataCache: { [T in ServiceFieldType]: OptionsCache<any> }
  filter: VariantFilter
  onChangeFilter: (c: { (state: VariantFilter): VariantFilter }) => void
}

const PriceFilter: FC<PriceFilterProps> = props => {
  const {
    filter,
    currencyID,
    serviceDataCache,
    onChangeFilter,
  } = props

  const {t} = useTranslation('AdditionalService')
  const title = t('AdditionalService:Templates.list.price')

  if (!filter.IsPriceFilterAvailable) {
    return <></>
  }

  const currency = serviceDataCache.currency.cache[currencyID] as CurrencyData | undefined
  const currencyCode = !!currency
    ? (currency.glyph.length > 0 ? currency.glyph : currency.code)
    : ""

  const settings = ServicePropertySliderSettings.RestoreSettings({})
  settings.FromValueMarkSuffix = currencyCode
  settings.ToValueMarkSuffix = currencyCode
  settings.ValueLabelDisplay = "off"

  return (
    <div className="a-service-template-list-filter-item">
      <div className="title">
        <span>{title}</span>
      </div>
      <div className="collapse"/>
      <div className="filter-field">
        <SliderField
          data={filter.PriceFilter}
          settings={settings}
          onChange={data => onChangeFilter(c => ({
            ...c,
            PriceFilter: data,
          }))}
        />
      </div>
    </div>
  )
}

export default PriceFilter