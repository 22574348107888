import {
  propertyFilterGeneratorProcessor
} from "@pages/AdditionalServices/containers/services/propertyFilterGenerator/types";
import {ServicePropertyType, ServicesProperty} from "@onlog-public/additional-services-types";
import {FilterPropertyTypeData, ListFilterData} from "../../types";
import {SelectOption} from "../optionsGenerator/types";

/**
 * ListProcessor реализует универсальный процессор для списочных свойств.
 */
export class ListProcessor<T extends Exclude<ServicePropertyType, "switch" | "number">> implements propertyFilterGeneratorProcessor<T> {
  protected type: T

  /**
   * Конструктор процессора
   * @param type
   */
  constructor(type: T) {
    this.type = type
  }

  /**
   * Возвращает тип свойства, для которого реализована генерация.
   */
  getType(): T {
    return this.type
  }

  /**
   * Возвращает статус доступности фильтрации по свойству. Если фильтр
   * пустой, то будет false.
   * @param _
   * @param __
   * @param propertyOptions
   */
  isFilterAvailable(
    _: ServicesProperty,
    __: number[],
    propertyOptions: SelectOption[],
  ): boolean {
    return propertyOptions.length > 1
  }

  /**
   * generate выполняет генерацию строго типизированных данных для фильтра
   * по переданному свойству известного типа.
   * @param _
   * @param __
   * @param propertyOptions
   */
  generate(
    _: ServicesProperty,
    __: number[],
    propertyOptions: SelectOption[],
  ): FilterPropertyTypeData<T> {
    const options: { [T in number]: SelectOption } = {}
    propertyOptions.map(o => options[o.value] = o)

    return {
      Options: Object.values(options)
        .map(o => ({
          ...o,
          label: o.label,
        }))
        .sort(
          (a, b) => a.label > b.label ? 1 : -1,
        )
      ,
      Selected: [],
    } as ListFilterData as FilterPropertyTypeData<T>
  }
}