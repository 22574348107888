export const onCopyToClipboard = (
  text: string,
  successCallback: () => void,
  errorCallback?: () => void
) => {
	if (navigator.clipboard) {
		navigator.clipboard.writeText(text).then(successCallback, errorCallback);
	} else {
        const textArea = document.createElement("textarea");
        textArea.value = text

        textArea.style.position = "absolute";
        textArea.style.left = "-999999px";

        document.body.prepend(textArea);
        textArea.select();

        try {
            document.execCommand('copy');
        } catch (error) {
            console.error(error);
        } finally {
            textArea.remove();
        }
	}
};
