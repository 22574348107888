import { RouteWidgetBaseParameters } from '@pages/AdditionalServices/tabs/DeliveryForm/types';
import {
  calculateCargoForContainers,
  calculateCargoForCustom,
} from '@pages/Basket/components/ServicesList/components/OrderList/components/OrderListItem/helpers';
import {
  ContainerParameters,
  CustomCargoParameters,
  RouteInGroup,
  RouteResult,
} from '@services/requests/orderService/types';

/**
 * Получение описания для груза на основании типа груза и их параметров
 * @param isContainer
 * @param containerParameters
 * @param customCargoParameters
 * @param route
 * @param baseData
 */
export const getCargoParams = <T extends boolean>(
  isContainer: T,
  containerParameters: ContainerParameters[],
  customCargoParameters: CustomCargoParameters[],
  route: RouteInGroup,
  baseData: RouteWidgetBaseParameters
) => {
  if (isContainer) {
    const cargoParameters = containerParameters.find(
      (c) => c.id === route.containerParametersId
    ) as ContainerParameters;

    return {
      cargoParameters,
      cargoDescription:
        cargoParameters && calculateCargoForContainers(baseData.containers, [cargoParameters]).join("; "),
    };
  }

  const cargoParameters = customCargoParameters.find(
    (c) => c.id === route.customCargoParametersId
  ) as CustomCargoParameters;

  return {
    cargoParameters,
    cargoDescription: cargoParameters && calculateCargoForCustom(baseData.units, [cargoParameters]).join("; "),
  };
};

export const getCargoDescription = <T extends boolean>(
  realTimeBaseData,
  route: RouteResult,
  routeGroup: RouteInGroup,
  langCode?: string
) => {
  if (route.type === 'container') {
    const containersInfo = route.cargoParameters.containerParameters.find(
      (i) => routeGroup.containerParametersId === i.id
    );

    return calculateCargoForContainers(realTimeBaseData.containers, [containersInfo], langCode).join("; ");
  }

  const containersInfo = route.cargoParameters.customCargoParameters.find(
    (i) => routeGroup.customCargoParametersId === i.id
  );

  return calculateCargoForCustom(realTimeBaseData.units, [containersInfo as CustomCargoParameters], langCode).join("; ");
};
