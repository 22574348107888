import { $error } from '@settings/errorContext';
import { graphQLClient } from '@settings/services/graphQLClient';
import { GraphQLClient } from '@settings/services/graphQLClient/GraphQLClient';
import { loggerFactory } from '@settings/services/logger';
import { Logger } from '@settings/services/logger/Logger';

import {
  OrderBaseData,
  _OrderBaseDataLoaderProcessorInterface,
  _OrderQueryResult,
} from './interface';
import { PreOrderBaseDataLoaderProcessorQuery } from './PreOrderBaseDataLoaderProcessorQuery';

// Процессор загрузки данных пред заказов
export class PreOrderBaseDataLoaderProcessor implements _OrderBaseDataLoaderProcessorInterface {
  private readonly client: GraphQLClient;

  private readonly logger: Logger;

  /**
   * Конструктор процессора
   */
  constructor() {
    this.client = graphQLClient();
    this.logger = loggerFactory().make('PreOrderBaseDataLoaderProcessor');
  }

  /**
   * Проверка доступности процессора
   * @param type
   */
  IsAvailable(type: 'order' | 'preOrder'): boolean {
    return type === 'preOrder';
  }

  /**
   * Загрузка данных заказа
   * @param orderId
   */
  async Load(orderId: string): Promise<OrderBaseData | undefined> {
    try {
      const resp = await this.client.Query<{ id: string }, _OrderQueryResult>(
        new PreOrderBaseDataLoaderProcessorQuery(orderId),
        {}
      );

      this.logger.Debug(`Loaded pre order data`, resp);
      if ((resp?.data || []).length === 0) {
        $error.next('Empty data response');
      }

      return {
        ...resp.data[0],
        customer_name: '-',
        customer_email: '-',
        customer_phone: '-',
      };
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }
}
