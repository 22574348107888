import React, {FC} from "react";
import {Service, ServicesProperty} from "@onlog-public/additional-services-types";
import {SelectOption} from "@pages/AdditionalServices/containers/services/optionsGenerator/types";
import {useTranslation} from "react-i18next";
import type {TFunction} from "i18next";
import getLocalizationByArgs from "@helpers/getLocalizationByArgs";
import Tooltip from "@mui/material/Tooltip";
import clsx from "clsx";

/**
 * getValueForProperty возвращает сгенерированное значение для свойства
 * по его настройкам.
 *
 * @param t
 * @param service
 * @param property
 * @param propertyOptions
 */
const getValueForProperty = (
  t: TFunction,
  service: Service,
  property: ServicesProperty,
  propertyOptions: { [K in string]: SelectOption[] },
): SelectOption | undefined => {
  const value = service.property_values.find(v => String(v.property_id) === property.id)
  if (!value) {
    return
  }

  const settings = property.settings.Visuality
  switch (property.type) {
    case "switch":
      return {
        value: value.value,
        label: [
          t(settings.PropertyValuePrefix),
          t(
            value.value === 1
              ? settings.ValueForSelectedSwitch.length > 0 ? settings.ValueForSelectedSwitch : "Да"
              : settings.ValueForUnselectedSwitch.length > 0 ? settings.ValueForUnselectedSwitch : "Нет",
          ),
          t(settings.PropertyValueSuffix),
        ].filter(v => v.length > 0).join(" "),
        origin: value.value,
      }
    case "number":
      return {
        value: value.value,
        label: [
          t(settings.PropertyValuePrefix),
          value.value.toLocaleString(),
          t(settings.PropertyValueSuffix),
        ].filter(v => v.length > 0).join(" "),
        origin: value.value,
      }
  }

  const options = propertyOptions[property.id]
  if (!options) {
    return
  }

  const option = options.find(o => o.value === value.value)
  if (!option) {
    return
  }

  return {
    value: value.value,
    label: [
      t(settings.PropertyValuePrefix),
      option.label,
      t(settings.PropertyValueSuffix),
    ].filter(v => v.length > 0).join(" "),
    description: settings.IsNeedShowDescriptionForOption
      ? option.description
      : undefined,
    origin: option.origin,
  }
}

export interface ServicePropertyValueProps {
  langID: string
  service: Service
  property: ServicesProperty
  propertyOptions: { [K in string]: SelectOption[] }
}

const ServicePropertyValue: FC<ServicePropertyValueProps> = props => {
  const {
    langID,
    service,
    propertyOptions,
    property,
  } = props
  const {t} = useTranslation('AdditionalService')

  const name = getValueForProperty(t, service, property, propertyOptions)
  if (!name) {
    return null
  }

  const settings = property.settings.Visuality
  const propertyName = getLocalizationByArgs(langID, property.name, property.localized_names)
  const propertyDescription = getLocalizationByArgs(langID, property.description, property.localized_descriptions)

  if (settings.OptionDescriptionPosition === "inner") {
    name.label = [
      name.label,
      name.description ?? "",
    ].filter(v => v.length > 0).join(", ")
  }

  const isNeedShowDescriptionOnBottom = settings.OptionDescriptionPosition === "bottom" && !!name?.description
  const isNeedShowTopCaption = !settings.IsNeedHideCaption && settings.CaptionPosition === "top"
  const isNeedShowLeftCaption = !settings.IsNeedHideCaption && settings.CaptionPosition === "left"

  return (
    <div className="property">
      {isNeedShowTopCaption && (
        <div className="description">
          <Tooltip
            placement="top"
            title={propertyDescription.length > 0 && (
              <div dangerouslySetInnerHTML={{__html: propertyDescription}} className="a-html-content"/>
            )}
          >
            <span>{propertyName}:</span>
          </Tooltip>
        </div>
      )}
      <div className="name">
        {isNeedShowLeftCaption && (
          <div className="description">
            <Tooltip
              placement="top"
              title={propertyDescription.length > 0 && (
                <div dangerouslySetInnerHTML={{__html: propertyDescription}} className="a-html-content"/>
              )}
            >
              <span>{propertyName}:</span>
            </Tooltip>
          </div>
        )}
        <div
          className={clsx({
            "description": settings.ValueDisplayVariant === "small"
          })}
        >
          {name.label}
        </div>
      </div>
      {isNeedShowDescriptionOnBottom && (
        <div className="description">
          {name.description}
        </div>
      )}
    </div>
  )
}

export default ServicePropertyValue