import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const BuildingIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24" fill={'none'}>
    <mask id="a" width="22" height="20" x="1" y="2" maskUnits="userSpaceOnUse">
      <path fill="#fff" d="M22.25 2H1v20h21.25V2Z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill={props.color}
        d="M19.412 2.625H4.119c-.255 0-.463.195-.463.436v19.128c0 .24.208.436.463.436h4.484c.255 0 .462-.195.462-.436v-3.466h5.4v3.466c0 .24.207.436.463.436h4.483c.256 0 .463-.195.463-.436V3.061c0-.241-.207-.436-.462-.436ZM8.776 15.475c0 .18-.155.326-.347.326H6.366c-.192 0-.348-.146-.348-.326v-1.943c0-.18.156-.326.348-.326h2.063c.192 0 .347.146.347.326v1.943Zm0-4.111c0 .18-.155.326-.347.326H6.366c-.192 0-.348-.146-.348-.326V9.42c0-.18.156-.326.348-.326h2.063c.192 0 .347.146.347.326v1.943Zm0-4.111c0 .18-.155.326-.347.326H6.366c-.192 0-.348-.146-.348-.326V5.31c0-.18.156-.326.348-.326h2.063c.192 0 .347.146.347.326v1.943Zm4.368 8.221c0 .181-.155.327-.347.327h-2.064c-.191 0-.347-.146-.347-.326v-1.943c0-.18.156-.326.348-.326h2.063c.192 0 .347.146.347.326v1.943Zm0-4.11c0 .18-.155.326-.347.326h-2.064c-.191 0-.347-.146-.347-.326V9.42c0-.18.156-.326.348-.326h2.063c.192 0 .347.146.347.326v1.943Zm0-4.111c0 .18-.155.326-.347.326h-2.064c-.191 0-.347-.146-.347-.326V5.31c0-.18.156-.326.348-.326h2.063c.192 0 .347.146.347.326v1.943Zm4.368 8.221c0 .181-.155.327-.347.327H15.1c-.191 0-.347-.146-.347-.326v-1.943c0-.18.156-.326.347-.326h2.064c.192 0 .347.146.347.326v1.943Zm0-4.11c0 .18-.155.326-.347.326H15.1c-.191 0-.347-.146-.347-.326V9.42c0-.18.156-.326.347-.326h2.064c.192 0 .347.146.347.326v1.943Zm0-4.111c0 .18-.155.326-.347.326H15.1c-.191 0-.347-.146-.347-.326V5.31c0-.18.156-.326.347-.326h2.064c.192 0 .347.146.347.326v1.943Z"
      />
    </g>
  </SvgIcon>
);

export default BuildingIcon;
