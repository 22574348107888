import {FilterPropertyData} from "@pages/AdditionalServices/containers/types";
import React, {FC, useState} from "react";
import getLocalizationByArgs from "@helpers/getLocalizationByArgs";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Collapse} from "@mui/material";
import PropertyFieldDisplay
  from "@pages/AdditionalServices/components/ServiceDirectory/ListTypeTemplate/PropertyFieldDisplay";
import {useTranslation} from "react-i18next";
import getResetStateForPropertyField
  from "@pages/AdditionalServices/components/ServiceDirectory/ListTypeTemplate/getResetStateForPropertyField";

export interface PropertyFilterGroupProps {
  langID: string
  data: FilterPropertyData<any>
  onChange: (data: FilterPropertyData<any>) => void
}

const PropertyFilterGroup: FC<PropertyFilterGroupProps> = props => {
  const {
    langID,
    data,
    onChange,
  } = props

  const settings = data.Property.settings.Filter
  const {t} = useTranslation('AdditionalService')
  const [isExpanded, setIsExpanded] = useState(!settings.IsExpandable ? true : settings.IsExpanded)
  const name = getLocalizationByArgs(langID, data.Property.name, data.Property.localized_names)
  const description = getLocalizationByArgs(langID, data.Property.description, data.Property.localized_descriptions)

  // handleReset реализует сброс значений фильтра
  const handleReset = () => {
    onChange(getResetStateForPropertyField(data))
  }

  if (!data.IsFilterAvailable) {
    return <></>
  }

  return (
    <div className="a-service-template-list-filter-item">
      <div className="title" onClick={() => setIsExpanded(!settings.IsExpandable ? true : !isExpanded)}>
        <Tooltip
          placement="top"
          title={description.length > 0 && (
            <div dangerouslySetInnerHTML={{__html: description}} className="a-html-content"/>
          )}
        >
          <span>{name}</span>
        </Tooltip>
      </div>
      <div className="collapse">
        {settings.IsExpandable && (
          <Tooltip title={
            isExpanded
              ? t('AdditionalService:Templates.list.collapse')
              : t('AdditionalService:Templates.list.expand')
          }>
            <IconButton
              size="small"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded && (
                <ExpandLessIcon/>
              )}
              {!isExpanded && (
                <ExpandMoreIcon/>
              )}
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className="filter-field">
        <Collapse in={isExpanded}>
          <div className="filter-field-collapse">
            <div>
              <PropertyFieldDisplay
                langID={langID}
                data={data}
                onChange={onChange}
              />
            </div>
            {settings.IsClearEnabled && (
              <div>
                <div className="filter-field-reset" onClick={handleReset}>
                  {t('AdditionalService:Templates.list.reset')}
                </div>
              </div>
            )}
          </div>
        </Collapse>
      </div>
    </div>
  )
}

export default PropertyFilterGroup