import { en } from './en';
import { ru } from './ru';
import { cn } from './cn';
import { es } from './es';

export const resources = {
  en: {
    ...en,
  },
  ru: {
    ...ru,
  },
  cn: {
    ...cn,
  },
  es: {
    ...es,
  }
};
