import {ServiceFileData} from "@onlog-public/additional-services-types";
import React, {FC} from "react";
import {defaultStyles, FileIcon} from "react-file-icon";
import {filesService} from "@services/requests/filesService";

export interface FileItemProps {
  file: ServiceFileData
}

const FileItem: FC<FileItemProps> = props => {
  const {file} = props

  const parts = file.name.split('.');
  const fileExtension = parts[parts.length - 1];

  const filePath = filesService().GetFileUrl(file);

  return (
    <div className="a-service-file-item">
      <a href={filePath} target="_blank" rel="noreferrer" className={'link'}>
        <FileIcon
          id={file.name}
          size={48}
          extension={fileExtension}
          // @ts-ignore
          {...(defaultStyles[fileExtension] || {})}
        />
        <span>{file.name_original}</span>
      </a>
    </div>
  )
}

export default FileItem