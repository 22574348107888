import {
  optionsGeneratorProcessor,
  SelectOption
} from "@pages/AdditionalServices/containers/services/optionsGenerator/types";
import {ServiceField, ServiceFieldType} from "@onlog-public/additional-services-types";
import {OptionsCache} from "../optionsLoader/types";
import {ContractorData} from "@services/requests/searchLoaders/contractorLoader/queries/ContractorLoaderQuery";
import getLocalizationByArgs from "@helpers/getLocalizationByArgs";

/**
 * ContractorProcessor реализует процессор генерации опций для подрядчиков
 */
export class ContractorProcessor implements optionsGeneratorProcessor {
  protected type: ServiceFieldType;

  /**
   * Конструктор сервиса
   * @param type
   */
  constructor(type: ServiceFieldType) {
    this.type = type
  }

  /**
   * getType возвращает тип поля, для которого реализован процессор
   */
  getType(): ServiceFieldType {
    return this.type
  }

  /**
   * getOptions генерирует опции для селектора поля дополнительных услуг
   * @param field
   * @param langID
   * @param cache
   */
  getOptions(field: ServiceField, langID: string, cache: OptionsCache<any>): SelectOption[] {
    const contractorData: ContractorData[] = field.handbook_limitations.length > 0
      ? field.handbook_limitations.map(id => cache.cache[id])
        .filter(v => !!v)
      : Object.values(cache.cache)

    return contractorData.map(d => ({
      value: parseInt(d.id),
      label: getLocalizationByArgs(langID, d.default_name, d.localized_names_data),
      origin: d,
    }))
  }
}