import { BranchItem } from '@services/requests/routeCalculator/locationsBranchSearchService/interfaces';

import { getLocalization } from './getLocalization';

/**
 * Получение локализованных названий
 * @param value
 * @param primaryLang
 * @param secondaryLang
 */
export const GetLocalizedNamesForLocationsOrTerminals = (
  value: BranchItem,
  primaryLang: string,
  secondaryLang: string
) => {
  const messages = [
    value.localizedNamesArray,
    ...value.subItems.map((i) => i.localizedNamesArray),
  ].flat();
  const messagesIso = [
    value.localizedIsoArray,
    ...value.subItems.map((i) => i.localizedIsoArray),
  ].flat();

  const localizations = value.subItems.map((item) => ({
    primaryLang: getLocalization(
      item.visibleName,
      messages.filter((m) => item.localizedNames.includes(m.id)),
      primaryLang
    ),
    secondaryLang: getLocalization(
      item.visibleName,
      messages.filter((m) => item.localizedNames.includes(m.id)),
      secondaryLang
    ),
  }));
  const primaryNames = [
    getLocalization(
      value.visibleName,
      messages.filter((m) => value.localizedNames.includes(m.id)),
      primaryLang
    ),
    ...localizations.map((l) => l.primaryLang),
  ];

  const secondaryNames = [
    getLocalization(
      value.visibleName,
      messages.filter((m) => value.localizedNames.includes(m.id)),
      secondaryLang
    ),
    ...localizations.map((l) => l.secondaryLang),
  ];
  const [parent] = value.subItems;

  const visibleName =
    value.localizedNamesArray.find((m) => m.lang_id === primaryLang)?.message ||
    value.visibleName;

  let symbolCode =
    messagesIso.find((m) => m.lang_id === primaryLang)?.message || value.symbolCode;

  if (!symbolCode || symbolCode === '') {
    symbolCode = visibleName;
  }

  return {
    primaryNames,
    secondaryNames,
    symbolCode:
      value.type === 'terminal' ? symbolCode ?? parent?.symbolCode : visibleName || symbolCode,
  };
};
