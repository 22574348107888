import {AuthServiceInterface} from "@services/AuthService/interface";
import {loggerFactory} from "@settings/services/logger";
import {GraphQLClient} from "@settings/services/graphQLClient/GraphQLClient";
import {BitrixUser, LoadBitrixUserQuery, LoadUserQueryResponse} from "@services/AuthService/loadUserQuery";
import {graphQLClient} from "@settings/services/graphQLClient";

class AuthService implements AuthServiceInterface {
  private readonly logger = loggerFactory().make(`AuthService`);
  private readonly client: GraphQLClient;
  constructor(token?: string) {
    this.client = graphQLClient(token);
  }
  async LoadUser(token?:string): Promise<BitrixUser | null> {
    if(!token) {
      return null;
    }

    try {
      const {BitrixUserProfile} = await this.client.Query<null, LoadUserQueryResponse>(
        new LoadBitrixUserQuery(),
        {}
      );

      this.logger.Debug(`Load bitrix user`, BitrixUserProfile);

      return BitrixUserProfile;
    } catch (e) {
      this.logger.Error(e);

      throw e;
    }
  }
}

export default AuthService