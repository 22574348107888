import { ServiceField } from '@onlog-public/additional-services-types';

import { ServiceData, SetUpServiceData } from './types';

/**
 * getServiceFieldsByRows извлекает все поля для
 * переданных установочной и других услуг и
 * группирует их по строкам заданным в настройках.
 * Результатом явялется объект, в качестве ключа - номер
 * строки, в качестве значения - массив полей.
 * @param setUpService
 * @param otherServices
 */
export const getServiceFieldsByRows = (
  setUpService: SetUpServiceData,
  otherServices: ServiceData[]
): { [T in number]: ServiceField[] } => {
  const rows: { [T in number]: ServiceField[] } = {};

  [setUpService, ...otherServices]
    .map((s) => [
      ...s.ServiceData.fields,
      ...(s.ServiceData.additionServices?.map((s) => s.fields)?.flat(1) ?? []),
    ])
    .flat(1)
    .filter((d, i, data) => data.map((d) => d.id).indexOf(d.id) === i)
    .filter((f) => f.settings.DefaultSettings.IsNeedShowFieldInCart)
    .forEach((field) => {
      const isRowExist = !!rows[field.settings.DefaultSettings.RowNumber];

      isRowExist
        ? rows[field.settings.DefaultSettings.RowNumber].push(field)
        : (rows[field.settings.DefaultSettings.RowNumber] = [field]);
    });

  return rows;
};
