import { RouteStep } from '@services/requests/orderService/types';
import React from 'react';

import ShoulderItem from 'src/components/pages/Products/components/RouteProduct/components/ShoulderItem';
import {TaxNameProps} from '@helpers/getTaxNames';

/**
 * Пропсы компонента
 */
type RouteProductDetailStepsProps = {
  steps: RouteStep[];
  taxNames: TaxNameProps[];
};

/**
 * Компонент вывода шагов маршрута с описанием плечей и терминалов
 * @param props
 */
const RouteProductDetailSteps = (props: RouteProductDetailStepsProps) => {
  const { steps, taxNames } = props;

  return (
    <>
      {steps.map((step) => (
        <ShoulderItem key={step.shoulder.id} step={step} taxNames={taxNames} />
      ))}
    </>
  );
};

export default RouteProductDetailSteps;
