import {FilterPropertyData, FilterPropertyTypeData} from "@pages/AdditionalServices/containers/types";

/**
 * getResetStateForPropertyField возвращает сброшенные данные для переданного
 * свойства фильтра услуг.
 * @param data
 */
const getResetStateForPropertyField = (
  data: FilterPropertyData<any>
): FilterPropertyData<any> => {
  switch (data.Property.type) {
    case "switch":
      const filter = data.FilterData as FilterPropertyTypeData<"switch">
      return {
        ...data,
        FilterData: {
          ...filter,
          Current: filter.Type.length === 1 ? filter.Type[0] : "both"
        } as FilterPropertyTypeData<"switch">
      }
    case "number":
      const numberFilter = data.FilterData as FilterPropertyTypeData<"number">
      return {
        ...data,
        FilterData: {
          Slider: {
            ...numberFilter.Slider,
            CurrentMax: numberFilter.Slider.Max,
            CurrentMin: numberFilter.Slider.Min,
          },
          List: {
            ...numberFilter.List,
            Selected: [],
          }
        } as FilterPropertyTypeData<"number">
      }
  }

  const listFilter = data.FilterData as FilterPropertyTypeData<"location">
  return {
    ...data,
    FilterData: {
      ...listFilter,
      Selected: []
    } as FilterPropertyTypeData<"location">
  }
}

export default getResetStateForPropertyField