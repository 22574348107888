import React from 'react';

interface Props {
  currency: string;
}

function PointCurrency({ currency }: Props) {
  console.log(currency);
  return <></>;
}

export default PointCurrency;
