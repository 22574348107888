import {ServiceDirectory} from "@onlog-public/additional-services-types";
import React, {FC} from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import getLocalizationByArgs from "@helpers/getLocalizationByArgs";
import {
  ServicesServicePriceCalculationMutationProps
} from "@services/requests/servicesService/servicesServicesService/interface";
import getTotalPriceForDirectory from "@pages/AdditionalServices/components/VariantsTabs/getTotalPriceForDirectory";
import {CircularProgress} from "@material-ui/core";
import {CurrencyData} from "@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery";

export interface VariantsTabsProps {
  langID: string
  currency?: CurrencyData
  isServicesLoading: boolean
  variants: ServiceDirectory[]
  services: { [T in string]: ServicesServicePriceCalculationMutationProps[] }
  selectedVariant: string
  onSelectVariant: (variant: string) => void
}

/**
 * VariantsTabs выводит доступные для выбора варианты стоимости услуг
 * @param props
 * @constructor
 */
const VariantsTabs: FC<VariantsTabsProps> = props => {
  const {
    langID,
    currency,
    isServicesLoading,
    variants,
    services,
    selectedVariant,
    onSelectVariant,
  } = props

  const variantsToDisplay = variants.filter(v => v.is_active)

  return (
    <div>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={selectedVariant}
        onChange={(_, v) => onSelectVariant(v)}
        className="a-service-data-variants"
      >
        {variantsToDisplay.map(d => {
          const title = getLocalizationByArgs(langID, d.name, d.localized_names)
          const price = getTotalPriceForDirectory(d, services)
          const isHasPrice = !isServicesLoading && price > 0

          return (
            <Tab
              key={d.id}
              label={(
                <div className="a-service-data-variants-tab--content">
                  <div>{title}</div>
                  {isHasPrice && (
                    <div>{price.toLocaleString()} {currency?.code}</div>
                  )}
                  {isServicesLoading && (
                    <div className="a-service-data-variants-tab--content-progress">
                      <CircularProgress color="inherit" size={16}/>
                    </div>
                  )}
                </div>
              )}
              value={d.id}
              className="a-service-data-variants-tab"
            />
          )
        })}
      </Tabs>
    </div>
  )
}

export default VariantsTabs