import {ServiceFieldType} from "@onlog-public/additional-services-types";
import {optionsGeneratorProcessor} from "@pages/AdditionalServices/containers/services/propertyOptionsGenerator/types";
import {SelectOption} from "../optionsGenerator/types";
import {OptionsCache} from "../optionsLoader/types";
import {ContractorData} from "@services/requests/searchLoaders/contractorLoader/queries/ContractorLoaderQuery";
import getLocalizationByArgs from "@helpers/getLocalizationByArgs";

/**
 * ContractorProcessor реализует процессор генерации опций для
 * подрядчиков
 */
export class ContractorProcessor implements optionsGeneratorProcessor {
  protected type: ServiceFieldType;

  /**
   * Конструктор сервиса
   * @param type
   */
  constructor(type: ServiceFieldType) {
    this.type = type
  }

  /**
   * getType возвращает тип справочника, для которого реализован генератор
   */
  getType(): ServiceFieldType {
    return this.type
  }

  /**
   * getOptions генерирует опции для вывода по переданным идентификаторам
   * и кэшу.
   * @param langID
   * @param items
   * @param _
   * @param cache
   */
  getOptions(langID: string, items: string[], _: string, cache: OptionsCache<any>): SelectOption[] {
    const contractorData: ContractorData[] = items
      .map(i => cache.cache[i])
      .filter(v => !!v)

    return contractorData.map(d => ({
      value: parseInt(d.id),
      label: getLocalizationByArgs(langID, d.default_name, d.localized_names_data),
      origin: d,
    }))
  }
}