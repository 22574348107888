import {VariantFilter} from "@pages/AdditionalServices/containers/types";
import {serviceFilterAndOrderContext$} from "@pages/AdditionalServices/containers/bus";
import calculateServiceOrderAndFilter
  from "@pages/AdditionalServices/containers/subscribers/calculateServiceOrderAndFilter";

/**
 * onChangeFilterData выполняет сохранение изменений стейта фильтра
 * конкретного варианта в основном стейте.
 * @param variantID
 * @param c
 */
const onChangeFilterData = (
  variantID: string,
  c: { (state: VariantFilter): VariantFilter },
) => {
  const state = serviceFilterAndOrderContext$.getValue()
  serviceFilterAndOrderContext$.next({
    ...state,
    Variants: {
      ...state.Variants,
      [variantID]: c({...state.Variants[variantID]}),
    }
  })

  calculateServiceOrderAndFilter(true)
}

export default onChangeFilterData