import React, {FC} from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import {useTranslation} from "react-i18next";

/**
 * ServiceListLoading реализует компонент вывода прогресс бара загрузки
 * данных услуг.
 * @constructor
 */
const ServiceListLoading: FC = () => {
  const { t } = useTranslation(['AdditionalService']);

  return (
    <div className="a-service-data-loading">
      <LinearProgress/>
      <Alert color={'info'} severity={'info'}>
        <AlertTitle>{t('AdditionalService:ServiceListLoading')}</AlertTitle>
        {t('AdditionalService:ServiceListLoadingDescription')}
      </Alert>
    </div>
  )
}

export default ServiceListLoading