export function getCalculation(
  count: number,
  unit: string,
  currencyUnit: string,
  currencyRate: number,
  price: number,
  base: number
) {
  let res = 0;

  if (unit === '%') {
    res = count * price * 0.01;
  } else if (currencyUnit !== 'руб') {
    res = count * currencyRate * base;
  } else {
    res = count * base;
  }

  return res;
}
