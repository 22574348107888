import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5361 1.46403C18.1639 2.09184 18.1533 3.1129 17.5126 3.72756L7.41936 13.4105L8.08392 16.4567L6.55893 17.9817L4.55117 14.4489L1.01843 12.4412L2.54345 10.9162L5.58963 11.5808L15.2725 1.48751C15.8872 0.84682 16.9083 0.836228 17.5361 1.46403ZM10.3168 5.21411L9.28401 4.9026L10.3977 3.78893L9.69334 3.08459L8.20176 4.57618L6.9408 4.19583L8.11022 3.02642L7.40588 2.32208L5.85855 3.86938L2.79151 2.94428L1 4.73575L7.10738 8.55952L10.3168 5.21411ZM15.1302 13.1411L16.6775 11.5938L15.9732 10.8895L14.8038 12.0589L14.4234 10.7979L15.915 9.30633L15.2107 8.60199L14.097 9.71566L13.7855 8.68291L10.4401 11.8923L14.2638 17.9997L16.0553 16.2082L15.1302 13.1411Z"
      fill="#B9C0CE"
    />
  </svg>
);
