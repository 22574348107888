import {ServiceFieldSettings} from "@onlog-public/additional-services-types";
import {LocalizedMessage} from "@services/requests/localizedMessagesService/interfaces";
import React, {FC} from "react";
import clsx from "clsx";
import getLocalizationByArgs from "@helpers/getLocalizationByArgs";

interface FieldCaptionProps {
  fieldSettings: ServiceFieldSettings
  fieldName: string
  fieldLocalization: LocalizedMessage[]
  langID: string
}

const FieldCaption: FC<FieldCaptionProps> = props => {
  const {
    fieldSettings,
    fieldLocalization,
    fieldName,
    langID,
  } = props

  const text = getLocalizationByArgs(langID, fieldName, fieldLocalization)
  if (fieldSettings.IsNeedHideName || text.length === 0) {
    return <></>
  }

  return <>
    <div
      className={clsx({
        "a-service-field-caption": true,
        "direction-left": fieldSettings.FieldNameDirection === "left",
        "direction-right": fieldSettings.FieldNameDirection === "right",
        "direction-center": fieldSettings.FieldNameDirection === "center",
      })}
    >
      {text}
    </div>
  </>
}

export default FieldCaption