import {BehaviorSubject} from "rxjs";
import {AdditionServicesState} from "@pages/AdditionalServices/containers/types";
import {clone} from "@pages/AdditionalServices/containers/methods/clone";

/**
 * makeDirectoryPropertiesSubscriber собирает все свойства выбранных
 * в виджете директорий и генерирует для них базовый стейт.
 * @param serviceContext$
 */
const makeDirectoryPropertiesSubscriber = (
  serviceContext$: BehaviorSubject<AdditionServicesState>,
) => (
  states: AdditionServicesState[]
) => {
  if (states.length == 0) {
    return
  }

  const state = states[states.length - 1]
  if (!state.IsLastLevelSelected) {
    return
  }

  // Получаем все свойства из выбранных директорий. Сортируем их
  // по приоритету и ID.
  const properties = Object.values(state.ServicesPath)
    .map(s => s.properties)
    .flat(1)
    .sort((a, b) => {
      if (a.ordinal === b.ordinal) {
        return a.id < b.id ? 1 : -1
      }

      return a.ordinal < b.ordinal ? 1 : -1
    })

  /**
   * Вычисляем расхождение свойств директорий.
   * Если свойства не изменились, то оставляем все как есть.
   */
  const currentProperties = state.PropertiesToDisplay
    .map(p => p.id)
    .sort((a, b) => a < b ? 1 : -1)
    .join("|")

  const newProperties = properties
    .map(p => p.id)
    .sort((a, b) => a < b ? 1 : -1)
    .join("|")

  if (currentProperties === newProperties) {
    return
  }

  serviceContext$.next({
    ...clone(state),
    PropertiesToDisplay: properties,
    PropertyValues: {},
  })
}

export default makeDirectoryPropertiesSubscriber