import HiddenContent from '@components/UI/HiddenContent';
import { TerminalsFilterType } from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesListComponent/DeliveryFilterContainer/TerminalsFilter/types';
import TerminalsGroupFilter from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesListComponent/DeliveryFilterContainer/TerminalsGroupFilter';
import { BranchItem } from '@services/requests/routeCalculator/locationsBranchSearchService/interfaces';
import React, { FC } from 'react';

const TerminalsFilter: FC<TerminalsFilterType> = ({
  handleChangeFilterValue,
  terminals,
  values,
  systemLanguage,
  mainLanguage,
  cachedTerminals,
  name,
  filterKey,
}) => {
  const terminalsGroup = terminals.reduce((acc: { [key: string]: BranchItem[] }, item) => {
    if (item.type === 'terminal') {
      return {
        ...acc,
        [item.parentId]: acc[item.parentId] ? [...acc[item.parentId], item] : [item],
      };
    }
    return acc;
  }, {});

  return (
    <HiddenContent
      title={name}
      defaultVisibility
      onReset={() => handleChangeFilterValue(filterKey, cachedTerminals)}
    >
      {Object.keys(terminalsGroup).map((key) => {
        return (
          <TerminalsGroupFilter
            key={`transit-terminals-${key}`}
            terminals={terminalsGroup[key]}
            values={values}
            parent={terminals.find((t) => t.id === key)}
            systemLanguage={systemLanguage}
            mainLanguage={mainLanguage}
            handleChangeFilterValue={handleChangeFilterValue}
            filterKey={filterKey}
          />
        );
      })}
    </HiddenContent>
  );
};

export default TerminalsFilter;
