import { ServicePriceCalculationParametersProps } from '@services/requests/servicesService/servicesServicesService/interface';
import { GraphQLQuery } from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

/**
 * Агрегирующий запрос сущностей директорий дополнительных услуг
 */
export class ServicesServicePriceCalculationMutation
  implements GraphQLQuery<ServicePriceCalculationParametersProps>
{
  readonly query: any;

  readonly variables: ServicePriceCalculationParametersProps;

  constructor(params: ServicePriceCalculationParametersProps) {
    this.variables = {
      services: params.services,
    };
    this.query = gql`
      mutation calculateServicePrice($services: [ServicePriceCalculationParameters]) {
        servicesServicePriceCalculation(services: $services) {
          stateID
          currencyId
          result {
            data {
              currency_id
              service_id
              tax_id
              formula
              is_include_tax
              fields_with_limitations {
                id
                limitations
                price_offer_id
                services_field_id
              }
              fields_with_limitations_ids
              id
              is_active
              is_default
            }
            result {
              basePrice
              priceInTargetCurrency
              taxInTargetCurrency
              computedAmount
              conversionFee
			        fullPriceInTargetCurrency
			        innerCurrency
            }
            token
          }
          serviceId
          isPriceByRequest
        }
      }
    `;
  }
}
