import { CreatePreOrderGroupMutation } from '@services/requests/orderService/mutations/CreatePreOrderGroupMutation';
import { $error } from '@settings/errorContext';
import { graphQLClient } from '@settings/services/graphQLClient';
import { GraphQLClient } from '@settings/services/graphQLClient/GraphQLClient';
import { loggerFactory } from '@settings/services/logger';
import { Logger } from '@settings/services/logger/Logger';

import {
  Order,
  OrderServiceInterface,
  OrderServiceMutationResponse,
  ProductServiceMutationResponse,
} from './interface';
import { CreateOrderMutation, _OrderMutationVar } from './mutations/CreateOrderMutation';
import { CreatePreOrderMutation, _PreOrderMutationVar } from './mutations/CreatePreOrderMutation';
import { UpdatePreOrderMutation } from './mutations/UpdatePreOrderMutation';
import { OrderBaseDataLoaderInterface } from './orderBaseDataLoader/interface';
import { OrderProduct, OrderProductLoaderInterface } from './orderProductsLoader/interface';

// Сервис загрузки данных заказов
export class OrderService implements OrderServiceInterface {
  private readonly baseLoader: OrderBaseDataLoaderInterface;

  private readonly productLoader: OrderProductLoaderInterface;

  private readonly logger: Logger;

  private readonly client: GraphQLClient;

  /**
   * Конструктор сервиса
   * @param baseLoader
   * @param productLoader
   */
  constructor(
    baseLoader: OrderBaseDataLoaderInterface,
    productLoader: OrderProductLoaderInterface
  ) {
    this.baseLoader = baseLoader;
    this.productLoader = productLoader;
    this.logger = loggerFactory().make(`OrderLoader`);
    this.client = graphQLClient();
  }

  /**
   * Загрузка товаров заказа
   * @param type
   * @param orderId
   */
  async Load(type: 'order' | 'preOrder', orderId: string): Promise<Order> {
    try {
      const [baseOrder, products] = await Promise.all([
        this.baseLoader.Load(type, orderId),
        this.productLoader.Load(type, orderId),
      ]);

      const order: Order = { ...baseOrder, products };
      this.logger.Debug(`Loaded order`, order);

      return order;
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  /**
   * Сохранение предзаказа
   * @param order
   */
  async SavePreOrder(order: Order): Promise<Order> {
    try {
      if (order.id) {
        return this.updatePreOrder(order);
      }

      return this.createPreOrder(order);
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  /**
   * Сохранение предзаказа
   * @param products
   */
  async SavePreOrderGroup(products: OrderProduct[]): Promise<OrderProduct> {
    try {
      return this.createPreOrderGroup(products);
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  /**
   * Создание заказа
   * @param order
   */
  async SaveOrder(order: Order): Promise<Order> {
    try {
      this.logger.Debug('start create order');

      const result = await this.client.Mutation<
        {
          preOrderId: number;
          order: _OrderMutationVar;
        },
        OrderServiceMutationResponse
      >(new CreateOrderMutation(order.pre_order_id, order), {});

      this.logger.Debug('complete create order', result.data);

      return result.data;
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  /**
   * Создание предзаказа
   *
   * @param preOrder
   */
  private async createPreOrder(preOrder: Order): Promise<Order> {
    this.logger.Debug('start create preorder');
    // @ts-ignore
    const result = await this.client.Mutation<
      {
        preOrder: _PreOrderMutationVar;
      },
      OrderServiceMutationResponse
    >(new CreatePreOrderMutation(preOrder), {});
    this.logger.Debug('complete create preorder', result.data);

    return result.data;
  }

  /**
   * Группировка товаров или услуг в одну сущность
   *
   * @param products
   */
  private async createPreOrderGroup(products: OrderProduct[]): Promise<OrderProduct> {
    this.logger.Debug('start create preorder group');
    // @ts-ignore
    const result = await this.client.Mutation<
      {
        products: OrderProduct[];
      },
      ProductServiceMutationResponse
    >(new CreatePreOrderGroupMutation(products), {});
    this.logger.Debug('complete create preorder group', result.data);

    return result.data;
  }

  /**
   * Обновление предзаказа
   *
   * @param order
   */
  private async updatePreOrder(order: Order): Promise<Order> {
    this.logger.Debug('start create preorder');
    const result = await this.client.Mutation<
      {
        id: number;
        preOrderProducts: OrderProduct[];
      },
      OrderServiceMutationResponse
    >(new UpdatePreOrderMutation(order.id, order.products), {});
    this.logger.Debug('complete create preorder', result.data);

    return result.data;
  }
}
