import CellModesModel from '@components/UI/DefaultTable/helpers';
import { DataGrid, GridCellModesModel, GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { FC, MouseEvent, useCallback, useState } from 'react';

import ShoulderItemTableBodyStyledContainer from './StyledComponents';

export type AdditionalServicesTableBodyProps = {
  rows: any[];
  columns: GridColDef[];
  variant?: 'default';
};

const ShoulderItemTableBody: FC<AdditionalServicesTableBodyProps> = ({
  rows,
  columns,
  variant,
}) => {
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});

  const handleCellClick = useCallback((params: GridCellParams, event: MouseEvent) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if (!event.currentTarget.contains(event.target as Element)) {
      return;
    }

    setCellModesModel((prevModel: GridCellModesModel) => CellModesModel(prevModel, params));
  }, []);

  const handleCellModesModelChange = useCallback((newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  }, []);

  return (
    <ShoulderItemTableBodyStyledContainer className={variant}>
      <DataGrid
        rows={rows}
        columns={columns}
        hideFooter
        disableColumnMenu
        getRowHeight={() => 'auto'}
        showCellVerticalBorder
        showColumnVerticalBorder
        sx={{
          border: '1px solid #dbe0ec',
          borderTop: 0,
          borderRadius: '0 0 20px 20px',
          '& .MuiDataGrid-cell': {
            borderColor: '#dbe0ec',
          },
          '& .MuiDataGrid-columnHeader': {
            borderColor: '#dbe0ec',
          },
          '& .MuiDataGrid-row': {
            borderBottomColor: '#dbe0ec',
          },
          '& .MuiDataGrid-withBorderColor': {
            borderBottomColor: '#dbe0ec',
          },
        }}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
      />
    </ShoulderItemTableBodyStyledContainer>
  );
};

export default ShoulderItemTableBody;
