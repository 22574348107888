import React, {FC} from "react";
import {ServiceProps} from "@pages/AdditionalServices/components/Service/types";
import {Service, VerticalTableTemplate} from "@onlog-public/additional-services-types";
import {useTranslation} from "react-i18next";
import ColumnServiceFields
  from "@pages/AdditionalServices/components/Service/VerticalTableTemplate/ColumnServiceFields";

export interface TitleRowProps {
  columns: Service[]
  settings: VerticalTableTemplate
  serviceProps: ServiceProps
}

const TitleRow: FC<TitleRowProps> = props => {
  const {
    columns,
    settings,
    serviceProps,
  } = props

  const {t} = useTranslation()

  /**
   * Определяем, какие колонки самостоятельные, а какие приклеены к
   * соседям.
   */
  const columnSize: { [T in number]: boolean } = {}
  columns.map((c, i) => {
    if (i === 0 || !c.settings.TemplateSettings.ServiceInVerticalTable.CollapseTitleRowCellWithLeft) {
      return columnSize[i] = false
    }
    columnSize[i] = true
  })

  /**
   * Собираем в массивы реальных колонок, которые надо выводить.
   * По количеству колонок можно будет вычислить ширину колонки.
   */
  const columnsToDisplay: { [T in number]: Service[] } = {}
  let currentColumn = 0
  columns.map((column, position) => {
    if (!!columnSize[position]) {
      return columnsToDisplay[currentColumn].push(column)
    }

    currentColumn = position
    columnsToDisplay[position] = [column]
  })

  return <>
    <div className="no-left-border"/>
    <div/>
    {!settings.IsCourseColumnHidden && (
      <div/>
    )}
    {Object.values(columnsToDisplay).map((columns, i) => {
      if (0 === columns.length) {
        return null
      }

      const isDuplicateFieldsToDisplay = columns.map(c => c.settings.TemplateSettings.ServiceInVerticalTable.DuplicateFields.Fields)
          .map(f => Object.values(f))
          .flat(1)
          .length > 0

      const isNeedDisplayFields = isDuplicateFieldsToDisplay ||
        columns.map(c => c.fields).flat(1).filter(
          f => f.type !== "hidden" && !f.settings.DefaultSettings.IsFieldHidden
        ).length > 0

      const columnsText = columns
        .map(c => (
          c.settings.TemplateSettings.ServiceInVerticalTable.TitleRowText
        ))
        .filter(t => t.length > 0)
        .map(text => t(text))
        .join(" ")

      return (
        <div key={i} className={`center verticalTable-column-fields span-size-${columns.length}`}>
          {!isNeedDisplayFields && columnsText}
          {isNeedDisplayFields && (
            <ColumnServiceFields
              columns={columns}
              serviceProps={serviceProps}
            />
          )}
        </div>
      )
    })}
    <div/>
    <div/>
    <div/>
  </>
}

export default TitleRow