import {ServiceField} from "@onlog-public/additional-services-types";
import React, {FC} from "react";
import {
  AdditionServiceFieldsProps
} from "@pages/AdditionalServices/components/FieldDisplay/AdditionServiceFields/types";
import AdditionServiceFieldsRow
  from "@pages/AdditionalServices/components/FieldDisplay/AdditionServiceFields/AdditionServiceFieldsRow";


const AdditionServiceFields: FC<AdditionServiceFieldsProps> = props => {
  const {
    templateSettings,
    options,
    langID,
    fields,
    fieldValues,
    isOptionsLoading,
    isFieldsDisabled,
    onChange,
    validation,
  } = props

  const rows: { [T in number]: { idx: number, fields: ServiceField[] } } = {}
  fields.map(f => {
    const settings = templateSettings[f.code] ?? f.settings.DefaultSettings

    if (f.type === "hidden" || settings.IsFieldHidden) {
      return
    }

    if (!rows[settings.RowNumber]) {
      rows[settings.RowNumber] = {idx: settings.RowNumber, fields: []}
    }

    rows[settings.RowNumber].fields.push(f)
  })

  const rowsToDisplay = Object.values(rows)
    .sort((a, b) => a.idx > b.idx ? 1 : -1)

  return (
    <div className="addition-service-fields-list">
      {rowsToDisplay.map(r => (
        <AdditionServiceFieldsRow
          key={r.idx}
          templateSettings={templateSettings}
          langID={langID}
          fields={r.fields}
          fieldValues={fieldValues}
          onChange={onChange}
          options={options}
          validation={validation}
          isFieldsDisabled={isFieldsDisabled}
          isOptionsLoading={isOptionsLoading}
        />
      ))}
    </div>
  )
}

export default AdditionServiceFields