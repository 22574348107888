import {Service} from "@onlog-public/additional-services-types";

/**
 * getServiceIncludeState возвращает начальное состояние включенности услуги в
 * групповой расчет. Если услуга может включаться по кнопке, то изначально
 * она не включена в расчет.
 * @param service
 */
const getServiceIncludeState = (service: Service) => {
  switch (service.template) {
    case "verticalTable":
      return !service.settings.TemplateSettings.VerticalTable.IsNeedShowAddToBasketButton
    case "additionList":
      return !service.settings.TemplateSettings.AdditionList.IsNeedShowAddToBasketButton
    case "cargoDeclaration":
      return !service.settings.TemplateSettings.CargoDeclaration.IsNeedShowAddToBasketButton
  }

  return true
}

export default getServiceIncludeState