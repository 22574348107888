import {ContractorLoaderInterface} from '@services/requests/searchLoaders/contractorLoader/interface';
import {
  ContractorData,
  ContractorLoaderQuery,
  ContractorLoaderQueryResponse,
} from '@services/requests/searchLoaders/contractorLoader/queries/ContractorLoaderQuery';
import {
  DivisionContractorLoaderQuery
} from '@services/requests/searchLoaders/contractorLoader/queries/DivisionContractorLoaderQuery';
import {$error} from '@settings/errorContext';
import {graphQLClient} from '@settings/services/graphQLClient';
import {GraphQLClient} from '@settings/services/graphQLClient/GraphQLClient';
import {loggerFactory} from '@settings/services/logger';
import {Logger} from '@settings/services/logger/Logger';

import {localizedMessagesService} from '../../localizedMessagesService';
import {LocalizedMessagesServiceInterfaces} from '../../localizedMessagesService/interfaces';

/**
 * Загрузчик данных по подрядчикам
 */
export class ContractorLoader implements ContractorLoaderInterface {
  private readonly client: GraphQLClient;
  private readonly logger: Logger;
  private readonly localizationLoader: LocalizedMessagesServiceInterfaces;

  constructor(token?: string) {
    this.client = graphQLClient(token);
    this.logger = loggerFactory().make(`ContractorLoader`);
    this.localizationLoader = localizedMessagesService(token);
  }

  async Load(primaryKeys?: any[]): Promise<ContractorData[]> {
    try {
      const resp = await this.client.Query<null, ContractorLoaderQueryResponse>(
        new ContractorLoaderQuery(primaryKeys),
        {}
      );

      return await this.loadLocalization(resp.items)
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  /**
   * Метод выполняет загрузку данных для подрядчика переданного типа.
   * Если переданы первичные ключи, то дополнительно фильтрует данные
   * по ним.
   *
   * @param division
   * @param primaryKeys
   */
  async LoadDivisionContractorsByID(
    division: string,
    primaryKeys?: any[],
  ): Promise<ContractorData[]> {
    try {
      const resp = await this.client.Query<null, ContractorLoaderQueryResponse>(
        new DivisionContractorLoaderQuery(division, primaryKeys),
        {}
      );

      return await this.loadLocalization(resp.items)
    } catch (e) {
      this.logger.Error(`Error:`, e);
      $error.next(e);
    }
  }

  /**
   * loadLocalization выполняет загрузку данных локализации для подрядчиков
   *
   * @param contractors
   * @protected
   */
  protected async loadLocalization(contractors: ContractorData[]): Promise<ContractorData[]> {
    // Формируем общий список локализаций (ID)
    const localizedMessageIds = contractors.reduce(
      (result: string[], item: ContractorData): string[] => [...result, ...item.localized_names],
      []
    );

    // Загружаем все локализованные тексты
    const messages = await this.localizationLoader.GetMessagesArray(localizedMessageIds);
    contractors.map((item) => {
      item.localized_names_data = messages.filter(
        (m) => item.localized_names.indexOf(m.id) !== -1
      );
    });

    return contractors;
  }
}
