import MIconButton from '@assets/mui/@material-extend/MIconButton';
import SettingIcon from '@components/icons/SettingIcon';
import CardWrapper from '@components/UI/CardWrapper';
import { Slide, Typography, useMediaQuery } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTheme } from '@mui/material/styles';
import { FilterData } from '@services/requests/routeCalculator/routesCalculationService/interfaces';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useDelivery from '../../../useDelivery';
import RoutesListFilter from './Filter/RoutesListFilter';
import Container, { DialogContainer } from './StyledComponents';

const Transition = React.forwardRef(
  (props: TransitionProps & { children?: React.ReactElement }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  )
);

interface Props {
  open: boolean;

  handleCloseFilter(): void;
}

const DeliveryFilterContainer = ({ open, handleCloseFilter }: Props) => {
  const { t } = useTranslation(['RoutesListComponent']);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {
    useAggregationData,
    useTerminals,
    useStartTerminals,
    useEndTerminals,
    useFilterParameters,
    useBaseDate,
    useRouteParams,
    useCacheFilterParameters,
    useLimit,
    useOffset,
    useSettings,
    actions: { recalculateRoutes },
  } = useDelivery();

  const [aggregationData] = useAggregationData().useState();
  const [terminals] = useTerminals().useState();
  const [startTerminals] = useStartTerminals().useState();
  const [endTerminals] = useEndTerminals().useState();
  const [filterParameters, setFilterParameters] = useFilterParameters().useState();
  const [cacheFilterParameters] = useCacheFilterParameters().useState();
  const [baseData] = useBaseDate().useState();
  const [routeParams] = useRouteParams().useState();
  const setLimit = useLimit().setState;
  const setOffset = useOffset().setState;
  const [{ primaryLanguageId, secondaryLanguageId }] = useSettings().useState([
    'primaryLanguageId',
    'secondaryLanguageId',
  ]);
  const setFilterData = (parameters: FilterData) => {
    setLimit(10);
    setOffset(0);
    setFilterParameters((current) => ({
      ...current,
      ...parameters,
    }));
    recalculateRoutes(false);
  };

  const resetAll = () => {
    setLimit(10);
    setOffset(0);
    setFilterParameters(cacheFilterParameters);
    recalculateRoutes(false);
  };

  return (
    <>
      {!isMobile && (
        <CardWrapper className={'test'} sx={{ padding: '20px' }}>
          <RoutesListFilter
            baseData={baseData}
            filterParameters={filterParameters}
            baseFilterData={aggregationData}
            onChangeFilterData={setFilterData}
            resetAll={resetAll}
            mainLanguage={secondaryLanguageId}
            systemLanguage={primaryLanguageId}
            terminals={terminals}
            startTerminals={startTerminals}
            endTerminals={endTerminals}
            routeParams={routeParams}
          />
        </CardWrapper>
      )}
      {isMobile && (
        <DialogContainer
          fullScreen
          open={open}
          onClose={handleCloseFilter}
          TransitionComponent={Transition}
          sx={{ m: 0 }}
        >
          <Container>
            <div className={'mobile__title'}>
              <SettingIcon />
              <Typography variant="h3">{t('RoutesListComponent:ByParameters')}</Typography>
            </div>
            <MIconButton
              size={'small'}
              edge="end"
              color="inherit"
              onClick={handleCloseFilter}
              aria-label="close"
            >
              <KeyboardArrowDownIcon sx={{ color: '#707070', fontSize: '25px' }} />
            </MIconButton>
          </Container>
          <CardWrapper className={'test'} sx={{ padding: '20px' }}>
            <RoutesListFilter
              baseData={baseData}
              filterParameters={filterParameters}
              baseFilterData={aggregationData}
              onChangeFilterData={setFilterData}
              resetAll={resetAll}
              mainLanguage={secondaryLanguageId}
              systemLanguage={primaryLanguageId}
              terminals={terminals}
              startTerminals={startTerminals}
              endTerminals={endTerminals}
              routeParams={routeParams}
            />
          </CardWrapper>
        </DialogContainer>
      )}
    </>
  );
};

export default DeliveryFilterContainer;
