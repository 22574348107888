import { LOCALIZATION_DEPENDENCY } from '@context/useMyRouting/consts';

interface Fields {
  id: string;
  needPrint: string;
  token: string;
  selectedProduct: string[]
}

export function parseUrl(url: string, search: string): Fields {
  const urlObject = new URL(url);
  const urlParams = new URLSearchParams(search);
  const [_link, id] = urlObject.pathname.split('/').filter(Boolean);

  return {
    id,
    needPrint: urlParams.get('needPrint'),
    token: urlParams.get('token'),
    selectedProduct: urlParams.getAll('selectedProduct[]'),
  };
}

export function getOuterLanguage() {
  const lang = document.documentElement.lang;

  return lang;
}

export function getOuterCurrencyCode() {
	const currencyCode = document.documentElement.dataset.currency;

	return currencyCode || LOCALIZATION_DEPENDENCY[0].currencyCode;
}

export function getLocalizationById(langId: string) {
  return LOCALIZATION_DEPENDENCY.find((local) => local.id === langId);
}
