import React, {FC} from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {ServiceDirectorySettings} from "@onlog-public/additional-services-types";
import InputLabel from "@mui/material/InputLabel";
import {useTranslation} from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import clsx from "clsx";

/**
 * DirectorySelectorOption описывает типизацию директорий, доступных
 * для выбора в селекторе виджета.
 */
export interface DirectorySelectorOption {
  value: string
  label: string
}

interface DirectorySelectorFieldProps {
  code: string
  value?: string
  options: DirectorySelectorOption[]
  settings: ServiceDirectorySettings
  onChange: (value: string) => void
}

/**
 * DirectorySelector реализует компонент вывода селектора для директорий дополнительных услуг.
 * @param props
 */
const DirectorySelectorField: FC<DirectorySelectorFieldProps> = props => {
  const {
    code,
    value,
    options,
    settings,
    onChange,
  } = props
  const {t} = useTranslation();

  const name = t(settings.SelectName)
  const placeholder = t(settings.SelectPlaceholder)
  const fieldSize = settings.SelectSize === "small" ? "small" : "medium"

  return <>
    <div className={clsx("a-service-widget-form-directory-selector-block", `size-${settings.SelectBlockSize}`)}>
      <div className={clsx("a-service-widget-form-directory-selector-container", `size-${settings.SelectWidth}`)}>
        {name.length > 0 && (
          <div className={clsx(
            "a-service-widget-form-directory-selector-name",
            `direction-${settings.SelectNameDirection}`,
          )}>
            {name}
          </div>
        )}
        <div className="a-service-widget-form-directory-selector">
          <Tooltip placement="top" title={t(settings.SelectHelper)}>
            <FormControl variant="outlined" fullWidth={true}>
              <InputLabel id={`a-service-directory-${code}-label`}>{placeholder}</InputLabel>
              <Select
                value={value || ''}
                label={placeholder}
                labelId={`a-service-directory-${code}-label`}
                size={fieldSize}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  }
                }}
                onChange={e => onChange(e.target.value)}
              >
                {options.map((option) => (
                  <MenuItem
                    value={option.value}
                    key={option.value}
                    className="a-service-widget-form-directory-selector-option"
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Tooltip>
        </div>
      </div>
    </div>
  </>
}

export default DirectorySelectorField