import { GraphQLQuery } from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

import { Order } from '../interface';

export type _PreOrderMutationVar = {
  pre_order_products: {
    price: number;
    amount: number;
    token: string;
    data: string;
    product_type: string;
  }[];
  currency_id: number;
  language_id: number;
};

// Мутация на создание предзаказа
export class CreatePreOrderMutation implements GraphQLQuery<{ preOrder: _PreOrderMutationVar }> {
  readonly query: any;

  readonly variables: { preOrder: _PreOrderMutationVar };

  constructor(preOrder: Order) {
    this.variables = {
      preOrder: {
        currency_id: Number(preOrder.currency_id),
        language_id: Number(preOrder.language_id),
        pre_order_products: preOrder.products.map(({ id: _, ...otherProps }) => ({
          product_type: otherProps.product_type,
          price: otherProps.price,
          amount: otherProps.amount,
          data: otherProps.data,
          token: otherProps.token,
        })),
      },
    };

    this.query = gql`
      mutation __CREATE_PRE_ORDER__($preOrder: Pre_Order_Complete_Data!) {
        data: pre_order_create(pre_order: $preOrder) {
          currency_id
          currency_nominal
          currency_rate
          date
          id
          language_id
          order_price
          pre_order_products {
            amount
            data
            id
            pre_order_id
            price
            product_type
            token
          }
        }
      }
    `;
  }
}
