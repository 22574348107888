import getContractorFromDirectory from '@pages/AdditionalServices/components/ServiceDescription/getContractorFromDirectory';
import getDirectoryFieldsFromData from '@pages/AdditionalServices/containers/methods/basket/getDirectoryFieldsFromData';
import { getServiceFieldsByRows } from '@pages/AdditionalServices/containers/methods/basket/getServiceFieldsByRows';
import getServicePathFromData from '@pages/AdditionalServices/containers/methods/basket/getServicePathFromData';
import restoreServiceData from '@pages/AdditionalServices/containers/methods/basket/restoreServiceData';
import ServiceFieldsList from '@pages/Basket/components/ServicesList/components/OrderList/components/OrderListItem/components/ServiceBasketItem/ServiceFieldsList';
import { ProductSummaryProps } from '@services/requests/orderService/types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const ServiceGroupBasketItem: FC<ProductSummaryProps> = (props) => {
  const { product, langId } = props;

  const { t } = useTranslation(['AdditionalService']);

  const restoreResult = restoreServiceData(product);
  if (!restoreResult) {
    return null;
  }

  const { setUpService, otherServices } = restoreResult;

  const path = getServicePathFromData(langId, setUpService);
  const contractor = getContractorFromDirectory(
    langId,
    setUpService.Variant,
    setUpService.ServiceDataCache
  );
  const directoryFields = getDirectoryFieldsFromData(setUpService);

  const serviceFieldsToDisplay = Object.values(
    getServiceFieldsByRows(setUpService, otherServices)
  ).flat();

  return (
    <div className="a-service-basket">
      <div className="caption">{path}</div>
      <div className="properties">
        <div className="property">
          <div className="name">
            <div className="description">
              {t('AdditionalService:ServiceDescriptionContractor')}:
            </div>
            <div>{contractor}</div>
          </div>
        </div>
      </div>
      <ServiceFieldsList
        fields={[...directoryFields, ...serviceFieldsToDisplay]}
        langID={langId}
        setUpService={setUpService}
        otherServices={otherServices}
      />
    </div>
  );
};

export default ServiceGroupBasketItem;
