import {ServicesProperty} from "@onlog-public/additional-services-types";
import {
  propertyFilterGeneratorProcessor
} from "@pages/AdditionalServices/containers/services/propertyFilterGenerator/types";
import {NumberPropertyData} from "../../types";
import {SelectOption} from "../optionsGenerator/types";

/**
 * NumberProcessor реализует процессор генерации параметров фильтра
 * для числовых полей.
 */
export class NumberProcessor implements propertyFilterGeneratorProcessor<"number"> {
  /**
   * Возвращает тип свойства, для которого реализована генерация.
   */
  getType(): "number" {
    return "number"
  }

  /**
   * Возвращает статус доступности фильтрации по свойству. Если фильтр
   * пустой, то будет false.
   * @param __
   * @param propertyValues
   * @param _
   */
  isFilterAvailable(
    __: ServicesProperty,
    propertyValues: number[],
    _: SelectOption[],
  ): boolean {
    return propertyValues.filter(
      (d, i, data) => data.indexOf(d) === i
    ).length > 1
  }

  /**
   * generate выполняет генерацию строго типизированных данных для фильтра
   * по переданному свойству известного типа.
   * @param property
   * @param propertyValues
   * @param __
   */
  generate(
    property: ServicesProperty,
    propertyValues: number[],
    __: SelectOption[],
  ): NumberPropertyData {
    const {SliderSettings} = property.settings.Filter.NumberSettings

    const result: NumberPropertyData = {
      Slider: {
        Min: SliderSettings.IsLimitsFixed ? SliderSettings.MinValue : 0,
        Max: SliderSettings.IsLimitsFixed ? SliderSettings.MaxValue : 0,
        CurrentMin: SliderSettings.IsLimitsFixed ? SliderSettings.MinValue : 0,
        CurrentMax: SliderSettings.IsLimitsFixed ? SliderSettings.MaxValue : 0
      },
      List: {
        Options: [],
        Selected: []
      }
    }

    if (0 === propertyValues.length) {
      return result
    }

    const uniqueValues = propertyValues
      .filter(
        (d, i, data) => data.indexOf(d) === i
      )
      .sort((a, b) => a > b ? 1 : -1)

    result.List.Options = uniqueValues.map(v => ({
      value: v,
      label: v.toLocaleString(),
      origin: v,
    }))

    if (!SliderSettings.IsLimitsFixed) {
      result.Slider = {
        CurrentMax: uniqueValues[uniqueValues.length - 1],
        CurrentMin: uniqueValues[0],
        Max: uniqueValues[uniqueValues.length - 1],
        Min: uniqueValues[0],
      }
    }

    return result
  }
}