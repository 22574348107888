import {Service} from "@onlog-public/additional-services-types";

/**
 * getCostConfiguration возвращает настройки колонки стоимости, если они
 * заданы для шаблона.
 * @param s
 */
const getCostConfiguration = (s: Service) => {
  switch (s.template) {
    case "serviceInAdditionList":
      return s.settings.TemplateSettings.ServiceInAdditionList.CostColumn
    case "serviceInCargoDeclaration":
      return s.settings.TemplateSettings.ServiceInCargoDeclaration.CostColumn
  }

  return undefined
}

export default getCostConfiguration