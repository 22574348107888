import React, {FC} from "react";
import {ServiceProps} from "@pages/AdditionalServices/components/Service/types";
import CargoDeclarationTemplate from "@pages/AdditionalServices/components/Service/CargoDeclarationTemplate";
import ServiceInCargoDeclarationTemplate
  from "@pages/AdditionalServices/components/Service/ServiceInCargoDeclarationTemplate";
import AdditionListTemplate from "@pages/AdditionalServices/components/Service/AdditionListTemplate";
import ServiceInAdditionListTemplate from "@pages/AdditionalServices/components/Service/ServiceInAdditionListTemplate";
import ServiceInListingTemplate from "@pages/AdditionalServices/components/Service/ServiceInListingTemplate";
import {ServiceFileData} from "@onlog-public/additional-services-types";

/**
 * ServiceComp реализует компонент вывода услуг
 * @param props
 */
const ServiceComp: FC<ServiceProps> = props => {
  /**
   * Подмешиваем в глобальные файлы те, которые привязаны к
   * открытому варианту.
   */
  const {variant, globalFiles, ...other} = props
  const filesMap: { [K in string]: ServiceFileData } = {}
  Array.from([...globalFiles, ...variant.file_id]).map(f => filesMap[f.id] = f)

  const childProps: ServiceProps = {
    ...other,
    variant,
    globalFiles: Object.values(filesMap),
  }

  switch (props.service.template) {
    case "cargoDeclaration":
      return <CargoDeclarationTemplate {...childProps} />
    case "serviceInCargoDeclaration":
      return <ServiceInCargoDeclarationTemplate {...childProps} />
    case "additionList":
      return <AdditionListTemplate {...childProps} />
    case "serviceInAdditionList":
      return <ServiceInAdditionListTemplate {...childProps} />
    case "serviceInListing":
      return <ServiceInListingTemplate {...childProps} />
  }

  return null
}

export default ServiceComp