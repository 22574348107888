import {
  BaseOptionsGenerator
} from "@pages/AdditionalServices/containers/services/optionsGenerator/BaseOptionsGenerator";
import {HandbookProcessor} from "@pages/AdditionalServices/containers/services/optionsGenerator/handbookProcessor";
import {CurrencyProcessor} from "@pages/AdditionalServices/containers/services/optionsGenerator/currencyProcessor";
import {TaxProcessor} from "@pages/AdditionalServices/containers/services/optionsGenerator/taxProcessor";
import {ContractorProcessor} from "@pages/AdditionalServices/containers/services/optionsGenerator/contractorProcessor";
import {LocationProcessor} from "@pages/AdditionalServices/containers/services/optionsGenerator/locationProcessor";

// optionsGenerator реализует фабрику сервиса
const optionsGenerator = () => new BaseOptionsGenerator([
  new HandbookProcessor,
  new CurrencyProcessor,
  new TaxProcessor,
  new ContractorProcessor('certification_contractor'),
  new ContractorProcessor('consulting_contractor'),
  new ContractorProcessor('insurance_contractor'),
  new ContractorProcessor('inspection_contractor'),
  new ContractorProcessor('customs_contractor'),
  new LocationProcessor,
])

export default optionsGenerator