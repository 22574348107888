import 'dayjs/locale/ru';

import FullLogoIcon from '@components/icons/FullLogoIcon';
import { getLocalizationById } from '@context/useMyRouting/helpers';
import useMyRouting from '@context/useMyRouting/useMyRouting';
import { TaxNameProps } from '@helpers/getTaxNames';
import { Typography } from '@material-ui/core';
import { RouteWidgetBaseParameters } from '@pages/AdditionalServices/tabs/DeliveryForm/types';
import ProductList from '@pages/OfferDetail/components/ProductList';
import { FileData } from '@services/requests/filesService/interface';
import { Order } from '@services/requests/orderService/interface';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getValidityCalcDate } from './helpers';
import OfferDetailStyledContainer from './StyledComponent';

/**
 * Пропсы компонента
 */
type OfferDetailProps = {
  title: string;
  order: Order;
  taxNames: TaxNameProps[];
  files: FileData[];
  baseData: RouteWidgetBaseParameters;
};

/**
 * Компонент вывода детально информации по КП
 */
const OfferDetail = (props: OfferDetailProps) => {
  const langCode = getLocalizationById(props.order.language_id).countryCode;
  const { t } = useTranslation(['OfferDetail', 'Common'], { lng: langCode });
  const { order, baseData, title, files, taxNames } = props;

  let dateFormat = 'D MMMM YYYY ';

  if (langCode === 'en') {
    dateFormat = 'MMMM D, YYYY ';
  }

  const dateCalculation = dayjs(order?.date || new Date())
    .locale(langCode)
    .format(dateFormat + (langCode === 'ru' ? 'г.' : ''));

  const validDateText = useMemo(() => {
    return getValidityCalcDate(order.products, langCode);
  }, [order.products]);

  if (!baseData) {
    return <h3>{t('OfferDetail:FailedData')}</h3>;
  }

  return (
    <OfferDetailStyledContainer>
      <div className="logo">
        <FullLogoIcon />
      </div>
      <>
        <div className="header">
          <Typography variant={'h2'} className="heading">
            {title}
          </Typography>
          <Typography variant={'subtitle1'} className="header__order" component={'div'}>
            {order.id && `№ ${order.id}`} {t('Common:From')} {dateCalculation}
          </Typography>
          {validDateText.length !== 0 && (
            <Typography variant={'subtitle1'} className="header__until-date" component={'div'}>
              {t('OfferDetail:CalculationValid')} {validDateText}
            </Typography>
          )}
        </div>
        <ProductList order={order} files={files} realTimeBaseData={baseData} taxNames={taxNames} />
      </>
    </OfferDetailStyledContainer>
  );
};

export default OfferDetail;
