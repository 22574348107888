import React, {FC} from "react";
import Tooltip from "@mui/material/Tooltip";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import IconButton from "@mui/material/IconButton";

interface TextDisplayFieldProps {
  value: string
  tooltip: string
}

const TextDisplayField: FC<TextDisplayFieldProps> = props => {
  const {
    value,
    tooltip,
  } = props

  return <>
    <div className="a-service-field-text-impl">
      <span>{value}</span>
      {tooltip.length > 0 && (
        <span>
          <Tooltip
            placement="top"
            title={tooltip.length > 0 && (
              <div dangerouslySetInnerHTML={{__html: tooltip}} className="a-html-content"/>
            )}
          >
            <IconButton sx={{width: 16, height: 16, backgroundColor: '#EFF3FB'}}>
              <QuestionMarkIcon sx={{fontSize: 8, color: "#707070"}}/>
            </IconButton>
          </Tooltip>
        </span>
      )}
    </div>
  </>
}

export default TextDisplayField