import getEnv from '@settings/getEnv';
import axios from 'axios';

import { Collection, FetchClient } from './interface';

export class Client implements FetchClient {
  private baseUrl: string;

  constructor(baseUrl?: string) {
    const { REACT_APP_GRAPHQL_SERVER } = getEnv();

    this.baseUrl = baseUrl ?? REACT_APP_GRAPHQL_SERVER;
  }

  /**
   * Формирование базовых заголовоком с токеном авторизации
   * @param baseHeaders
   * @private
   */
  private getHeaders(baseHeaders: Collection<string>) {
    // const token: string | undefined = getCookie('token') as string;
    //
    // if (!token || token.length === 0) {
    //   return {
    //     ...baseHeaders,
    //   };
    // }

    return {
      ...baseHeaders,
      // Authorization: `${token}`,
    };
  }

  /**
   * Выполнение запроса к серверу
   * @param url
   */
  async Get<Response>(url: string, externalHeaders?: Collection<string>): Promise<Response> {
    const commonHeaders: Collection<string> = { ...externalHeaders };
    const urlWithServer = `${this.baseUrl}${url}`;

    const headers = this.getHeaders(commonHeaders);

    return axios({
      method: 'GET',
      url: urlWithServer,
      headers,
    }).then((json) => {
      if (json.data) {
        return json.data;
      }

      return json;
    });
  }

  async Delete<Response>(url: string): Promise<Response> {
    const commonHeaders: Collection<string> = {};
    const urlWithServer = `${this.baseUrl}${url}`;

    const headers = this.getHeaders(commonHeaders);

    return axios({
      method: 'DELETE',
      url: urlWithServer,
      headers,
    })
      .then((response) => response)
      .then((json) => {
        if (json.data) {
          return json.data;
        }

        return json;
      });
  }

  /**
   * Выполнение запроса к серверу
   *
   * @param url
   * @param data
   */
  async Post<V, Response>(url: string, data: V): Promise<Response> {
    const commonHeaders: Collection<string> = {};
    const urlWithServer = `${this.baseUrl}${url}`;

    const headers = this.getHeaders(commonHeaders);

    return axios({
      method: 'POST',
      url: urlWithServer,
      headers,
      data,
    }).then((json) => json.data);
  }
}
