import React, {FC} from "react";
import {ProductSummaryProps} from "@services/requests/orderService/types";
import restoreServiceData from "@pages/AdditionalServices/containers/methods/basket/restoreServiceData";
import ServiceFieldsList
  from "@pages/Basket/components/ServicesList/components/OrderList/components/OrderListItem/components/ServiceBasketItem/ServiceFieldsList";
import getContractorFromDirectory
  from "@pages/AdditionalServices/components/ServiceDescription/getContractorFromDirectory";
import {useTranslation} from "react-i18next";
import getServicePathFromData from "@pages/AdditionalServices/containers/methods/basket/getServicePathFromData";
import getDirectoryFieldsFromData from "@pages/AdditionalServices/containers/methods/basket/getDirectoryFieldsFromData";

const ServiceGroupBasketItem: FC<ProductSummaryProps> = props => {
  const {
    product,
    langId,
  } = props

  const {t} = useTranslation(['AdditionalService']);

  const restoreResult = restoreServiceData(product)
  if (!restoreResult) {
    return null
  }

  const {setUpService, otherServices} = restoreResult

  const path = getServicePathFromData(langId, setUpService)
  const contractor = getContractorFromDirectory(langId, setUpService.Variant, setUpService.ServiceDataCache)
  const directoryFields = getDirectoryFieldsFromData(setUpService)
  const serviceFieldsToDisplay = [setUpService, ...otherServices]
    .map(s => [
      ...s.ServiceData.fields,
      ...s.ServiceData.additionServices?.map(s => s.fields)?.flat(1) ?? []
    ])
    .flat(1)
    .filter((d, i, data) => data.map(d => d.id).indexOf(d.id) === i)
    .filter(f => f.settings.DefaultSettings.IsNeedShowFieldInCart)

  return (
    <div className="a-service-basket">
      <div className="caption">{path}</div>
      <div className="properties">
        <div className="property">
          <div className="name">
            <div className="description">
              {t('AdditionalService:ServiceDescriptionContractor')}:
            </div>
            <div>{contractor}</div>
          </div>
        </div>
      </div>
      <ServiceFieldsList
        fields={[...directoryFields, ...serviceFieldsToDisplay]}
        langID={langId}
        setUpService={setUpService}
      />
    </div>
  )
}

export default ServiceGroupBasketItem