import React, {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {SelectOption} from "@pages/AdditionalServices/containers/services/optionsGenerator/types";
import {FormControlLabel} from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import {Collapse} from "@mui/material";
import {
  ServicePropertyListSettings
} from "@onlog-public/additional-services-types";
import clsx from "clsx";

export interface ListDisplayProps {
  options: SelectOption[]
  selected: number[]
  settings: ServicePropertyListSettings
  onChange: (selected: number[]) => void
}

/**
 * ListDisplay реализует компонент вывода списка по базовым параметрам.
 * Используется для полей типа справочник и число.
 * @param props
 * @constructor
 */
const ListDisplay: FC<ListDisplayProps> = props => {
  const {options, selected, onChange, settings} = props
  const {t} = useTranslation('AdditionalService')

  const handleSelectAll = () => {
    onChange(options.map(o => o.value))
  }

  const [isExpanded, setIsExpanded] = useState(settings.IsExpanded)

  let firstItems = options
  let lastItems: SelectOption[] = []

  if (settings.IsExpandable) {
    firstItems = options.slice(0, settings.AlreadyVisibleElements)
    lastItems = options.slice(settings.AlreadyVisibleElements)
  }

  const showAll = t('AdditionalService:Templates.list.showAll')
  const hideAll = t('AdditionalService:Templates.list.hideAll')

  // handleCheck реализует обработчик выбора опции
  const handleCheck = (option: SelectOption) => {
    if (selected.includes(option.value)) {
      return onChange(selected.filter(v => v !== option.value))
    }

    onChange([...selected, option.value])
  }

  const prefix = t(settings.ListItemPrefix)
  const suffix = t(settings.ListItemSuffix)

  return (
    <div className="a-service-template-list-filter-list-field">
      {settings.IsSelectAllEnabled && (
        <div>
          <div
            className="a-service-template-list-filter-list-field-take-all"
            onClick={handleSelectAll}
          >
            {t('AdditionalService:Templates.list.selectAll')}
          </div>
        </div>
      )}
      <div className={clsx("a-service-template-list-filter-list-field-options", {
        "with-description": settings.IsDescriptionEnabled,
      })}>
        {firstItems.map((option, i) => {
          const isNeedDescription = !!option.description && settings.IsDescriptionEnabled
          const label = [prefix, option.label, suffix].filter(v => v.length > 0).join(" ")

          return (
            <FormControlLabel
              key={i}
              className="a-service-template-list-filter-list-field-option-checkbox"
              checked={selected.includes(option.value)}
              onChange={() => handleCheck(option)}
              classes={{
                label: "checkbox-label"
              }}
              control={
                <Checkbox
                  size="small"
                  checked={selected.includes(option.value)}
                />
              }
              label={(
                <div className={clsx("label-container", {
                  "with-description": settings.IsDescriptionEnabled,
                })}>
                  <div>{label}</div>
                  {isNeedDescription && (
                    <div>{option.description}</div>
                  )}
                </div>
              )}
            />
          )
        })}
      </div>
      <div>
        {lastItems.length > 0 && (
          <Collapse in={isExpanded}>
            <div className="a-service-template-list-filter-list-field-options collapse">
              {lastItems.map((option, i) => {
                const isNeedDescription = !!option.description && settings.IsDescriptionEnabled
                const label = [prefix, option.label, suffix].filter(v => v.length > 0).join(" ")

                return (
                  <FormControlLabel
                    key={i}
                    className="a-service-template-list-filter-list-field-option-checkbox"
                    checked={selected.includes(option.value)}
                    onChange={() => handleCheck(option)}
                    classes={{
                      label: "checkbox-label"
                    }}
                    control={
                      <Checkbox
                        size="small"
                        checked={selected.includes(option.value)}
                      />
                    }
                    label={(
                      <div className="label-container">
                        <div>{label}</div>
                        {isNeedDescription && (
                          <div>{option.description}</div>
                        )}
                      </div>
                    )}
                  />
                )
              })}
            </div>
          </Collapse>
        )}
        {lastItems.length > 0 && (
          <div
            className="a-service-template-list-filter-list-field-expand"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? hideAll : showAll}
          </div>
        )}
      </div>
    </div>
  )
}

export default ListDisplay