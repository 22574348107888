import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const AirIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M19.5 4.5c-.6-.7-1.6-.6-2.2 0l-3.2 3.4-8-2.7L4.4 7 11 11l-3.4 3.5-2.2-.3L4 15.7l3.2 1.1 1 3.2 1.6-1.5-.3-2.2L13 13l4 6.7 1.8-1.7-2.7-8 3.4-3.2c.6-.6.7-1.6 0-2.2z"
      fill={'currentColor'}
    />
  </SvgIcon>
);

export default AirIcon;
