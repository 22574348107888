import breakpoints from '@assets/theme/breakpoints';
import { styled } from '@mui/material';

const CargoSpecialConditionalStyledContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  align-items: center;

  @media (max-width: ${breakpoints.values.md}px) {
    flex: 0 0 100%;
  }

  @media (max-width: ${breakpoints.values.sm}px) {
    gap: 20px;
    margin-bottom: 30px;
  }

  .button-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 13px;

    .MuiButton-root {
      height: 28px;
      border-radius: 4px;

      @media (max-width: ${breakpoints.values.md}px) {
        height: 40px;
      }
      @media (max-width: ${breakpoints.values.sm}px) {
        height: 38px;
      }
    }

    @media (max-width: ${breakpoints.values.md}px) {
      flex: 0 0 100%;
    }

    @media (max-width: ${breakpoints.values.sm}px) {
      gap: 10px;
    }
  }
`;

export default CargoSpecialConditionalStyledContainer;
