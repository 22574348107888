import { GraphQLQuery } from '@settings/services/graphQLClient/GraphQLClient';
import gql from 'graphql-tag';

/**
 * Агрегирующий запрос сущностей директорий дополнительных услуг
 */
export class ServicesDirectoriesAggregateQuery implements GraphQLQuery<null> {
  readonly query: any;

  readonly variables: null;

  constructor() {
    this.variables = null;
    this.query = gql`
      query ServicesDirectoriesAggregateQuery {
        servicesdir_aggregate {
          count
        }
      }
    `;
  }
}
