import { styled } from '@mui/material';

const RootContainer = styled('div', { name: 'Widget' })`
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr;
  gap: 30px;
`;

export default RootContainer;
