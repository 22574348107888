import { getLocalizedName } from '@services/requests/localizedMessagesService/helpers/getLocalizedName';
import { BranchItem } from '@services/requests/routeCalculator/locationsBranchSearchService/interfaces';

/**
 * Генерация локализованного пути по переданной локации.
 * Генерирует строку в формате: Локация, Родитель, Родитель родителя ...
 *
 * @param location
 * @param langId
 */
export function getLocationsPath(location: BranchItem, langId: string): string {
  if (!location) {
    return '';
  }

  return location.subItems
    .map((item) => getLocalizedName(item.localizedNamesArray, item.visibleName, [langId])[langId])
    .join(', ');
}

/**
 * Получение локализованных сообщений для названия локации или терминала
 * @param location
 * @param langIds
 */
export const getLocationLocalizedNames = (location: BranchItem, ...langIds: string[]) =>
  getLocalizedName(location.localizedNamesArray, location.visibleName, langIds);
