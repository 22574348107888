// ----------------------------------------------------------------------

export default function Snackbar() {
  return {
    MuiSnackbarContent: {
      defaultProps: {
        container: document.getElementById('onlog-widget-container'),
      },
      styleOverrides: {
        root: {},
      },
    },
  };
}
