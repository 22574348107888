import {useEffect, useState} from "react";
import { AServiceCache} from "@pages/AdditionalServices/containers/types";
import {serviceCacheContext$} from "@pages/AdditionalServices/containers/bus/index";

/**
 * useAdditionServiceCache реализует хук для получения доступа к
 * кэшированным данным для отображения услуг.
 *
 * Кэш содержит следующие сущности:
 *  - tax - налоги
 *  - currency - валюты
 *  - contractor - все типы подрядчиков
 *
 * Кэш заполняется автоматически на основе выбранных директория,
 * загруженных услуг и рассчитанных данных.
 */
const useAdditionServiceCache = () => {
  const [cache, setCache] = useState<AServiceCache>(serviceCacheContext$.getValue())
  useEffect(() => {
    const subscription = serviceCacheContext$.pipe().subscribe({
      next: nState => setCache(nState),
    })

    return () => {
      try {
        subscription?.unsubscribe()
      } catch (e) {
      }
    }
  }, [])

  return {cache}
}

export default useAdditionServiceCache