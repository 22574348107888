import {BaseServicesFilter} from "@pages/AdditionalServices/containers/services/servicesFilter/BaseServicesFilter";
import {NumberFilter} from "@pages/AdditionalServices/containers/services/servicesFilter/numberFilter";
import {SwitchFilter} from "@pages/AdditionalServices/containers/services/servicesFilter/switchFilter";
import {ListFilter} from "@pages/AdditionalServices/containers/services/servicesFilter/listFilter";

// servicesFilter реализует фабрику сервиса
const servicesFilter = () => new BaseServicesFilter([
  new NumberFilter,
  new SwitchFilter,
  new ListFilter('tax'),
  new ListFilter('location'),
  new ListFilter('handbook'),
  new ListFilter('currency'),
  new ListFilter('consulting_contractor'),
  new ListFilter('inspection_contractor'),
  new ListFilter('insurance_contractor'),
  new ListFilter('customs_contractor'),
  new ListFilter('certification_contractor'),
])

export default servicesFilter