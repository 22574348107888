import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const PlusIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="#0EAB70" />
    <rect x="11" y="5" width="2" height="14" fill="white" />
    <rect x="19" y="11" width="2" height="14" transform="rotate(90 19 11)" fill="white" />
  </SvgIcon>
);

export default PlusIcon;
