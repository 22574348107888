import Typography from '@mui/material/Typography';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import { getLocalizedMobileResultTableLabels } from '@pages/AdditionalServices/tabs/CustomsPayments/consts';
import { getAntiDumpingCalc } from '@pages/AdditionalServices/tabs/CustomsPayments/helpers/getAntiDumpingCalc';
import { getExciseCalc } from '@pages/AdditionalServices/tabs/CustomsPayments/helpers/getExciseCalc';
import { getFeeCalc } from '@pages/AdditionalServices/tabs/CustomsPayments/helpers/getFeeCalc';
import { getTotalCalc } from '@pages/AdditionalServices/tabs/CustomsPayments/helpers/getTotalCalc';
import { getVatCalc } from '@pages/AdditionalServices/tabs/CustomsPayments/helpers/getVatCalc';
import { getFormattedPrice } from '@pages/AdditionalServices/tabs/CustomsPayments/helpers/price';
import { CurrencyResponse } from '@services/requests/customsTaxService/interface';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Cost, CustomsPaymentProduct } from '../../context/types';
import { Row, getResultTableColumns } from '../CustomsPaymentsProductItem/tableColumns';

interface CustomsPaymentsDetailingResultProps {
  isExport: boolean;
  isSpecialFieldsVisible: boolean;
  borderDelivery: Cost;
  otherExpenses: Cost;
  product: CustomsPaymentProduct;
  currencies: CurrencyResponse;
  contractCurrency: CurrencyResponse[number];
}

const objectStyles = {
  color: '#000',
  fontSize: '14px',
};

function CustomsPaymentsDetailingResult({
  isExport,
  borderDelivery,
  otherExpenses,
  product,
  contractCurrency,
  currencies,
}: CustomsPaymentsDetailingResultProps) {
  const { t } = useTranslation(['CustomsPayments']);

  const {
    productsCount,
    packagesCount,
    maximumRetailPackagePrice,
    selectedFee,
    selectedExcise,
    selectedExportFee,
    selectedVat,
  } = product;

  const localizedMobileHeaderTableLabels = getLocalizedMobileResultTableLabels(
    t('CustomsPayments:RateColumnTitle'),
    t('CustomsPayments:RubPriceColumnTitle'),
    t('CustomsPayments:CurrencyPriceColumnTitle')
  );

  const getCellClassName: DataGridProps<Row>['getCellClassName'] = ({ row }) => {
    if (row.id === 'total') {
      return 'total-text';
    }
    return '';
  };

  const getRowClassName: DataGridProps<Row>['getRowClassName'] = ({ row }) => {
    if (row.id === 'total') {
      return 'wide-row';
    }
    return '';
  };

  const [feeRowData, exportFeeRowData] = getFeeCalc({
    contractCurrency,
    currencies,
    localizedMobileHeaderTableLabels,
    product,
    selectedExportFee,
    selectedFee,
    title: t('CustomsPayments:DutyGroupTitle'),
  });
  const exciseRowData = getExciseCalc({
    contractCurrency,
    localizedMobileHeaderTableLabels,
    product,
    selectedExcise,
    title: t('CustomsPayments:ExciseGroupTitle'),
  });

  const vatRowData = getVatCalc({
    contractCurrency,
    exciseRowData,
    feeRowData,
    localizedMobileHeaderTableLabels,
    product,
    selectedVat,
    title: t('CustomsPayments:VatGroupTitle'),
  });
  const antiDumpingRowData = getAntiDumpingCalc();
  const result = getTotalCalc({
    antiDumpingRowData,
    exciseRowData,
    exportFeeRowData,
    feeRowData,
    vatRowData,
    localizedMobileHeaderTableLabels,
    isExport,
    contractCurrency,
    title: t('CustomsPayments:TotalCustomsPaymentsRowTitle'),
  });

  const isSpecialFieldsVisible = typeof selectedExcise?.Prim === 'string';
  return (
    <div className="result-wrap">
      <div className="result-item">
        <div className="result-item__row">
          <div className="result-item__col">
            {!isExport && (
              <div className="result-item__param">
                <Typography sx={objectStyles} component={'span'} variant={'h5'}>
                  {t('CustomsPayments:ProductQuantityLabel')}
                </Typography>

                <Typography sx={objectStyles} component={'span'} variant={'h5'} className="">
                  {productsCount}
                </Typography>
              </div>
            )}
            {isSpecialFieldsVisible && (
              <div className="result-item__param">
                <Typography sx={objectStyles} component={'span'} variant={'h5'}>
                  {t('CustomsPayments:PackagesCount')}
                </Typography>

                <Typography sx={objectStyles} component={'span'} variant={'h5'} className="">
                  {packagesCount}
                </Typography>
              </div>
            )}
          </div>
          {isSpecialFieldsVisible && (
            <div className="result-item__col">
              <div className="result-item__param">
                <Typography sx={objectStyles} component={'span'} variant={'h5'}>
                  {t('CustomsPayments:PackageMaxPriceLabel')}
                </Typography>

                <Typography sx={objectStyles} component={'span'} variant={'h5'} className="">
                  {maximumRetailPackagePrice}
                </Typography>
              </div>
            </div>
          )}
        </div>
        <div className="result-item__row">
          <>
            <div className="result-item__col">
              <span className="result-item__cost-title result-item__cost">
                {t('CustomsPayments:BorderShippingCosts')}
              </span>
              <span className="result-item__cost-title result-item__cost">
                {t('CustomsPayments:OtherExpenses')}
              </span>
            </div>
            <div className="result-item__col">
              <span className="result-item__cost-value result-item__cost">
                {getFormattedPrice(+borderDelivery.cost, 2)} {borderDelivery.currency?.encode}
              </span>
              <span className="result-item__cost-value result-item__cost">
                {getFormattedPrice(+otherExpenses.cost, 2)} {otherExpenses.currency?.encode}
              </span>
            </div>
          </>
        </div>
      </div>
      <div className="result-item">
        <div className="result-item__table">
          <DataGrid
            rows={result}
            columns={getResultTableColumns(
              t('CustomsPayments:PaymentTypeColumntTitle'),
              t('CustomsPayments:RateColumnTitle'),
              t('CustomsPayments:RubPriceColumnTitle'),
              t('CustomsPayments:CurrencyPriceColumnTitle')
            )}
            hideFooter
            getCellClassName={getCellClassName}
            getRowClassName={getRowClassName}
            disableColumnMenu
            disableRowSelectionOnClick
            disableDensitySelector
            disableColumnSelector
            showCellVerticalBorder
            disableVirtualization
            disableColumnFilter
            showColumnVerticalBorder
            getRowHeight={() => {
              return 50;
            }}
            sx={{
              border: '1px solid #dbe0ec',
              borderTop: 0,
              borderRadius: '0 0 20px 20px',
              '& .MuiDataGrid-cell': {
                borderColor: '#dbe0ec',
              },
              '& .MuiDataGrid-columnHeader': {
                borderColor: '#dbe0ec',
              },
              '& .MuiDataGrid-row': {
                borderBottomColor: '#dbe0ec',
              },
              '& .MuiDataGrid-withBorderColor': {
                borderBottomColor: '#dbe0ec',
              },
              '& .MuiDataGrid-virtualScrollerRenderZone': {
                position: 'static',
              },
              '& .MuiDataGrid-virtualScrollerContent': {
                height: 'auto !important',
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}

export { CustomsPaymentsDetailingResult };
