import {useEffect, useState} from "react";
import {AdditionServicesState} from "@pages/AdditionalServices/containers/types";
import {clone} from "@pages/AdditionalServices/containers/methods/clone";
import {setFieldValue} from "@pages/AdditionalServices/containers/methods/setFieldValue";
import {setSelectedDirectory} from "@pages/AdditionalServices/containers/methods/setSelectedDirectory";
import {resetSelectedDirectory} from "@pages/AdditionalServices/containers/methods/resetSelectedDirectory";
import {initialization} from "@pages/AdditionalServices/containers/methods/initialization";
import {serviceContext$} from "@pages/AdditionalServices/containers/bus";
import {onCalculateServices} from "@pages/AdditionalServices/containers/methods/onCalculateServices";
import {onChangeVariant} from "@pages/AdditionalServices/containers/methods/onChangeVariant";
import useAdditionServiceCache from "@pages/AdditionalServices/containers/bus/useAdditionServiceCache";
import onChangeServiceFieldValue from "@pages/AdditionalServices/containers/methods/onChangeServiceFieldValue";
import onChangeServiceTemplateStore from "@pages/AdditionalServices/containers/methods/onChangeServiceTemplateStore";
import useServicePropertyValueCache from "@pages/AdditionalServices/containers/bus/useServicePropertyValueCache";
import usePropertyFilterAndOrder from "@pages/AdditionalServices/containers/bus/usePropertyFilterAndOrder";
import onChangeServiceSelectionState from "@pages/AdditionalServices/containers/methods/onChangeServiceSelectionState";

/**
 * useAdditionServicesContext реализует хук для подключения стейта дополнительных услуг.
 *
 * Сам стейт разнесен по нескольким директориям:
 *  - bus - инициализация контекстов и шин, используемых в обработке
 *  - methods - основные методы, которыми реализуется работа со стейтом.
 *              Директория так же содержит основной метод инициализации стейта - initialization.
 *  - subscribers - реализации подписок на шины и изменения данных. Реализуют основную логику контекста.
 *
 * Для хранения значений опций полей дополнительных услуг из стейта был выделен отдельный
 * контекст: AdditionServicesOptionsState. Данный контекст автоматически подгружает
 * все необходимые опции для всех полей и услуг и директорий.
 *
 * Для бесшовного отображения услуг был добавлен дополнительный стейт с кэшем
 * данных - useAdditionServiceCache. В этом кэше находятся данные валют, налогов
 * и всех подрядчиков для дополнительных услуг.
 */
const useAdditionServicesContext = () => {
  const [state, setState] = useState<AdditionServicesState>(serviceContext$.getValue())
  useEffect(() => {
    const subscription = serviceContext$.pipe().subscribe({
      next: nState => setState(clone(nState)),
    })

    return () => {
      try {
        subscription?.unsubscribe()
      } catch (e) {
      }
    }
  }, [])

  return {
    state,
    setSelectedDirectory,
    setFieldValue,
    resetSelectedDirectory,
    initialization,
    onCalculateServices,
    onChangeVariant,
    onChangeServiceFieldValue,
    onChangeServiceTemplateStore,
    onChangeServiceSelectionState,
    useAdditionServiceCache,
    useServicePropertyValueCache,
    usePropertyFilterAndOrder,
  }
}

export default useAdditionServicesContext