import { useBitrix } from '@context/useBitrixUser/useBitrix';
import { Link } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CalculationLimitDialogStyledContainer,
  CalculationLimitStyledContainer,
} from './StyledComponents';
import { $isCalculationLimitExceeded } from '..';

// Свойства компонента
export interface CalculationLimitProps {
  isVisible: boolean;
}

const CalculationLimit: FC<CalculationLimitProps> = ({ isVisible }) => {
  const { t } = useTranslation(['RoutesCalculationWidget']);

  const { actions, useBitrixUser } = useBitrix();
  const [{ bitrixUser }] = useBitrixUser().useState();
  const { showLogin } = actions;

  const isAuthenticated = !!bitrixUser;

  const authClickHandler = (event: any) => {
    event.preventDefault();

    $isCalculationLimitExceeded.next(false);
    showLogin();
  };

  return (
    <>
      <CalculationLimitDialogStyledContainer
        fullWidth
        maxWidth={'sm'}
        open={isVisible}
        onClose={() => $isCalculationLimitExceeded.next(false)}
        scroll={'body'}
      >
        <Box className="close-button">
          <IconButton onClick={() => $isCalculationLimitExceeded.next(false)}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
        <CalculationLimitStyledContainer>
          <Typography variant={'h2'}>
            {t('RoutesCalculationWidget:CalculationLimit.Title')}
          </Typography>
          <Typography variant={'subtitle1'}>
            {isAuthenticated
              ? t('RoutesCalculationWidget:CalculationLimit.AuthInfo')
              : t('RoutesCalculationWidget:CalculationLimit.UnauthInfo')}
          </Typography>
          <Typography variant={'subtitle1'} component={'div'}>
            {isAuthenticated ? (
              t('RoutesCalculationWidget:CalculationLimit.AuthAction')
            ) : (
              <>
                {t('RoutesCalculationWidget:CalculationLimit.UnauthActionStart')}
                <Link href={'/'} underline="hover" onClick={authClickHandler}>
                  {t('RoutesCalculationWidget:CalculationLimit.UnauthActionSignin')}
                </Link>
                {t('RoutesCalculationWidget:CalculationLimit.UnauthActionMiddle')}
                <Link href={'/'} underline="hover" onClick={authClickHandler}>
                  {t('RoutesCalculationWidget:CalculationLimit.UnauthActionSignup')}
                </Link>
                {t('RoutesCalculationWidget:CalculationLimit.UnauthActionEnd')}
              </>
            )}
            {isAuthenticated && (
              <Link href={'mailto:help@onlogsystem.com'} underline="hover">
                {t('RoutesCalculationWidget:CalculationLimit.LinkUs')}
              </Link>
            )}
          </Typography>
        </CalculationLimitStyledContainer>
      </CalculationLimitDialogStyledContainer>
    </>
  );
};

export default CalculationLimit;
