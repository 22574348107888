import InitWidget from '@pages/index';
import ErrorBoundary from '@settings/ErrorBoundary';
import { SnackbarProvider } from 'notistack';
import React from 'react';

function PointInit() {
  return (
    <ErrorBoundary needJSX={false}>
      <SnackbarProvider maxSnack={10} preventDuplicate={true} autoHideDuration={3000}>
        <InitWidget />
      </SnackbarProvider>
    </ErrorBoundary>
  );
}

export default PointInit;
