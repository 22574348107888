import useMyRouting from '@context/useMyRouting/useMyRouting';
import i18n from 'i18next';
import React, { useEffect } from 'react';

interface Props {
  primaryLanguageId: string;
}

function PointPrimaryLang({ primaryLanguageId }: Props) {
  const { useSettings, useLocalizationDeps } = useMyRouting();

  const [_, changeSettings] = useSettings().useState();
  const [localizationsDeps] = useLocalizationDeps().useState();

  useEffect(() => {
	const foundLocalization = localizationsDeps.find( dep => dep.countryCode === primaryLanguageId );

	if (!foundLocalization) {
		return;
	}

	const { countryCode, currencyId, secondaryLanguageId, id } = foundLocalization;

    changeSettings(({ countryCode, currencyId, primaryLanguageId: id, secondaryLanguageId }));

    i18n.changeLanguage(primaryLanguageId);
  }, [primaryLanguageId]);

  return <></>;
}

export default PointPrimaryLang;
