import React, {FC} from "react";
import {ServiceProps} from "@pages/AdditionalServices/components/Service/types";
import AdditionServiceFields from "@pages/AdditionalServices/components/FieldDisplay/AdditionServiceFields";
import getDuplicateFieldsConfiguration
  from "@pages/AdditionalServices/components/Service/getDuplicateFieldsConfiguration";
import getCostConfiguration from "@pages/AdditionalServices/components/Service/getCostConfiguration";

/**
 * ServiceFieldsList реализует контейнерную обертку для вывода полей
 * услуг. Реализует функционал дублирования полей, а так же
 * проброса шаблонных настроек в поля.
 *
 * Компонент принимает полный набор свойств услуги и преобразует их
 * в свойства компонента полей.
 *
 * @param props
 */
const ServiceFieldsList: FC<ServiceProps> = props => {
  const {
    langID,
    service,
    globalFields,
    servicesFields,
    globalFieldValues,
    servicesFieldValues,
    isOptionsLoading,
    options,
    isOrderDisplay,
    onChangeServiceFieldValue,
    onChangeGlobalFieldValue,
  } = props

  const templateSettings = getDuplicateFieldsConfiguration(service)
  const costSettings = getCostConfiguration(service)
  let serviceFieldCodes = service.fields.map(f => f.code)
  const additionFieldsCodes: string[] = [];

  if (!!templateSettings) {
    additionFieldsCodes.push(...Object.keys(templateSettings.Fields))
  }

  // Если колонки услуги выводятся в колонке стоимость, то их необходимо
  // исключить из других колонок.
  if (!!costSettings) {
    const costCodes = [costSettings.CurrencyFieldCode, costSettings.PriceFieldCode]
    serviceFieldCodes = serviceFieldCodes.filter(c => !costCodes.includes(c))
  }

  if (service.template === "verticalTable") {
    const settings = service.settings.TemplateSettings.VerticalTable
    const costFields = [settings.CostColumnField, settings.CostColumnSumField]

    serviceFieldCodes = serviceFieldCodes.filter(c => !costFields.includes(c))
  }

  const fieldsToDisplay = [
    ...servicesFields.filter(f => serviceFieldCodes.includes(f.code)),
    ...servicesFields.filter(f => additionFieldsCodes.includes(f.code)),
    ...globalFields.filter(f => additionFieldsCodes.includes(f.code)),
  ]

  return (
    <AdditionServiceFields
      fields={fieldsToDisplay}
      fieldValues={{...globalFieldValues, ...servicesFieldValues}}
      langID={langID}
      onChange={(field, value) => {
        if (serviceFieldCodes.includes(field)) {
          return onChangeServiceFieldValue(field, value)
        }

        onChangeGlobalFieldValue(field, value)
      }}
      isOptionsLoading={isOptionsLoading}
      options={options}
      templateSettings={templateSettings?.Fields ?? {}}
      isFieldsDisabled={isOrderDisplay}
    />
  )
}

export default ServiceFieldsList