import {ServiceField} from "@onlog-public/additional-services-types";
import {OptionsCache} from "../optionsLoader/types";
import {
  OptionsGenerator,
  optionsGeneratorProcessor,
  SelectOption
} from "@pages/AdditionalServices/containers/services/optionsGenerator/types";

/**
 * BaseOptionsGenerator реализует сервис генерации опций для полей дополнительных услуг.
 */
export class BaseOptionsGenerator implements OptionsGenerator {
  protected processors: optionsGeneratorProcessor[]

  /**
   * Конструктор сервиса
   * @param processors
   */
  constructor(processors: optionsGeneratorProcessor[]) {
    this.processors = processors
  }

  /**
   * getOptions генерирует опции для селектора поля дополнительных услуг
   * @param field
   * @param langID
   * @param cache
   */
  getOptions(field: ServiceField, langID: string, cache: OptionsCache<any>): SelectOption[] {
    const processor = this.processors.find(p => p.getType() === field.type)
    if (!processor) {
      return []
    }

    return processor.getOptions(field, langID, cache)
  }
}