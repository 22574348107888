import {LocalizedMessage} from "@services/requests/localizedMessagesService/interfaces";

/**
 * getLocalizationByArgs реализует функцию для получения локализованного текста по
 * переданным параметрам
 *
 * @param langID
 * @param text
 * @param localization
 */
const getLocalizationByArgs = (langID: string, text: string, localization: LocalizedMessage[]) => {
  const existLocalization = localization.find(l => l.lang_id === langID)
  if (!!existLocalization) {
    return existLocalization.message
  }

  return text
}

export default getLocalizationByArgs