import {
  BaseOptionsCacheCheckerAndLoader
} from "@pages/AdditionalServices/containers/services/optionsCacheCheckerAndLoader/baseOptionsCacheCheckerAndLoader";
import {
  TotalLoadingProcessor
} from "@pages/AdditionalServices/containers/services/optionsCacheCheckerAndLoader/totalLoadingProcessor";
import {
  LocationProcessor
} from "@pages/AdditionalServices/containers/services/optionsCacheCheckerAndLoader/locationProcessor";
import {
  HandbookProcessor
} from "@pages/AdditionalServices/containers/services/optionsCacheCheckerAndLoader/handbookProcessor";

// optionsCacheCheckerAndLoader реализует фабрику сервиса
const optionsCacheCheckerAndLoader = () => new BaseOptionsCacheCheckerAndLoader([
  new HandbookProcessor,
  new TotalLoadingProcessor('currency'),
  new TotalLoadingProcessor('tax'),
  new TotalLoadingProcessor('consulting_contractor'),
  new TotalLoadingProcessor('certification_contractor'),
  new TotalLoadingProcessor('inspection_contractor'),
  new TotalLoadingProcessor('customs_contractor'),
  new TotalLoadingProcessor('insurance_contractor'),
  new LocationProcessor,
])

export default optionsCacheCheckerAndLoader