import { TaxNameProps } from '@helpers/getTaxNames';
import getTerminalsToDisplay, { MappedTerminalData } from '@helpers/getTerminalsFromStep';
import { RouteWidgetBaseParameters } from '@pages/AdditionalServices/tabs/DeliveryForm/types';
import { formatPrice } from '@pages/OfferDetail/helpers';
import { RowProps } from '@services/collectors/ProductService/ShoulderItemDetailsDTO';
import {
  CalculatedPrice,
  RouteStep,
  TransportContainerRent,
  TransportDropOff,
  TransportPickOn,
} from '@services/requests/orderService/types';
import { CurrencyData } from '@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery';

const getAvailablePrices = (
  containerRentCalculatedPrice: CalculatedPrice,
  pickOnCalculatedPrice: CalculatedPrice,
  dropOffCalculatedPrice: CalculatedPrice
) => {
  return [containerRentCalculatedPrice, pickOnCalculatedPrice, dropOffCalculatedPrice].filter(
    (price) => !!price
  );
};

const getFullConversionTaxPriceFromTerminals = (terminals: MappedTerminalData[]) =>
  terminals
    .map((terminal) => [
      ...terminal.terminalAllowances.map((allowance) => allowance.calculatedPrice.conversionFee),
      terminal.terminalCalculatedPrice.conversionFee,
    ])
    .flat()
    .reduce((sum, val) => sum + val, 0);

export const getCost = (
  containerRentCalculatedPrice: CalculatedPrice,
  pickOnCalculatedPrice: CalculatedPrice,
  dropOffCalculatedPrice: CalculatedPrice
) => {
  const availablePrices = getAvailablePrices(
    containerRentCalculatedPrice,
    pickOnCalculatedPrice,
    dropOffCalculatedPrice
  );

  return availablePrices
    .map((price) => price?.priceInTargetCurrency + price?.taxInTargetCurrency ?? 0)
    .reduce((result, price) => result + price, 0);
};

export interface RowsData {
  targetCurrency: CurrencyData;
  realTimeBaseData: RouteWidgetBaseParameters;
  pickOn: TransportPickOn;
  pickOnCalculatedPrice: CalculatedPrice;
  dropOff: TransportDropOff;
  dropOffCalculatedPrice: CalculatedPrice;
  containerRent: TransportContainerRent;
  containerRentCalculatedPrice: CalculatedPrice;
  taxNames: TaxNameProps[];
  names: { pickOn: string; containerRent: string; dropOff: string };
  pickOnCurrency: CurrencyData;
  dropOffCurrency: CurrencyData;
  containerRentCurrency: CurrencyData;
}

export const getRowsData = (data: RowsData) => {
  const {
    targetCurrency,
    pickOn,
    pickOnCalculatedPrice,
    dropOff,
    dropOffCalculatedPrice,
    containerRent,
    containerRentCalculatedPrice,
    taxNames,
    names,
    pickOnCurrency,
    dropOffCurrency,
    containerRentCurrency,
  } = data;
  const rowsData: RowProps[] = [];
  if (pickOnCalculatedPrice && pickOn) {
    rowsData.push({
      baseCurrency: pickOnCurrency,
      price: pickOnCalculatedPrice,
      taxName: taxNames.length
        ? taxNames.find((i) => +i.taxId === pickOn.tax_id)?.localizedName || ''
        : '',
      targetCurrency: targetCurrency,
      name: names.pickOn,
    });
  }
  if (containerRentCalculatedPrice && containerRent) {
    rowsData.push({
      baseCurrency: containerRentCurrency,
      price: containerRentCalculatedPrice,
      taxName: taxNames.length
        ? taxNames.find((i) => +i.taxId === containerRent.tax_id)?.localizedName || ''
        : '',
      targetCurrency: targetCurrency,
      name: names.containerRent,
    });
  }
  if (dropOffCalculatedPrice && dropOff) {
    rowsData.push({
      baseCurrency: dropOffCurrency,
      price: dropOffCalculatedPrice,
      taxName: taxNames.length
        ? taxNames.find((i) => +i.taxId === dropOff.tax_id)?.localizedName || ''
        : '',
      targetCurrency: targetCurrency,
      name: names.dropOff,
    });
  }

  return rowsData;
};

export const getAllConversionFee = (
  steps: RouteStep[],
  realTimeBaseData,
  containerRentCalculatedPrice: CalculatedPrice,
  pickOnCalculatedPrice: CalculatedPrice,
  dropOffCalculatedPrice: CalculatedPrice
) => {
  let allConversionFee = 0;

  steps.forEach((step) => {
    allConversionFee += step.shoulderAllowances.reduce(
      (sum, allowance) => sum + allowance.calculatedPrice.conversionFee,
      step.shoulderOfferCalculatedPrice.conversionFee
    );
  });

  const availablePrices = getAvailablePrices(
    containerRentCalculatedPrice,
    pickOnCalculatedPrice,
    dropOffCalculatedPrice
  );

  const terminalsToDisplay = getTerminalsToDisplay(steps, realTimeBaseData);

  const totalConversionTerminals = (terminals: MappedTerminalData[][]) =>
    terminals.map(getFullConversionTaxPriceFromTerminals).reduce((sum, val) => sum + val, 0);

  allConversionFee += totalConversionTerminals(terminalsToDisplay);

  allConversionFee += availablePrices
    .map((price) => price?.conversionFee ?? 0)
    .reduce((result, price) => result + price, 0);

  return formatPrice(allConversionFee);
};
