import breakpoints from '@assets/theme/breakpoints';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const CartEmptyComponentStyledContainer = styled(Box)`
  position: relative;
  margin-bottom: 40px;
  color: ${({ theme }) => theme.palette.grey[700]};
  letter-spacing: normal;
  padding: 0;
  width: 100%;

  @media (max-width: ${breakpoints.values.lg}px) {
    grid-gap: 20px;
    padding: 0 20px 90px 20px;
  }
  @media (max-width: ${breakpoints.values.md}px) {
    grid-template-columns: 1fr;
    padding: 0;
  }
  @media (max-width: ${breakpoints.values.xsm}px) {
    grid-template-columns: 1fr;
    padding: 0;
  }

  .title {
    margin: 7px 0 50px;
    color: ${({ theme }) => theme.palette.common.black};
    font-size: 40px;
    font-weight: 400;
    line-height: normal;

    @media (max-width: ${breakpoints.values.md}px) {
      margin: 7px 0 20px;
    }
  }

  .section-title {
    margin: 0 0 10px;
    font-size: 30px;
    font-weight: 400;
    line-height: normal;

    @media (max-width: ${breakpoints.values.md}px) {
      text-align: center;
    }
  }

  .services {
    padding: 30px 50px 50px 50px;
    background-color: ${({ theme }) => theme.palette.common.white};

    @media (max-width: ${breakpoints.values.xsm}px) {
      padding: 30px 30px 40px;
    }
  }

  .summary {
    margin-top: 40px;
  }

  .reset-cart {
    margin-top: 30px;
  }

  .section-info {
    margin: 0 0 50px;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  }

  .calculation-link {
    border-radius: 18px;
    background: #fff;
    border: none;
    box-shadow: 0px 0px 20px 0px rgba(0, 5, 115, 0.07);

    &:hover {
      box-shadow: 0px 0px 20px 0px rgba(138, 141, 255, 0.28);
    }
  }
`;
