import { getLocalizationById } from '@context/useMyRouting/helpers';
import { TaxNameProps } from '@helpers/getTaxNames';
import { RouteWidgetBaseParameters } from '@pages/AdditionalServices/tabs/DeliveryForm/types';
import OfferDetail from '@pages/OfferDetail';
import { FileData } from '@services/requests/filesService/interface';
import { Order } from '@services/requests/orderService/interface';
import React from 'react';
import { useTranslation } from 'react-i18next';

// Пропсы компонента
export type OfferInCartContainerProps = {
  order: Order;
  files: FileData[];
  taxNames: TaxNameProps[];
  baseData: RouteWidgetBaseParameters;
  selectedProducts: string[]
};

/**
 * Компонент обертка вывода информации о КП в корзине
 * @param props
 */
const OfferInCartContainer = ({ order, files, taxNames, baseData, selectedProducts }: OfferInCartContainerProps) => {
  const langCode = getLocalizationById(order.language_id).countryCode;
  const { t } = useTranslation('OfferContainers', { lng: langCode });

  return (
    <OfferDetail
      order={order}
      files={files}
      taxNames={taxNames}
      baseData={baseData}
      selectedProducts={selectedProducts}
      title={t('OfferContainers:OfferDetailTitle')}
    />
  );
};

export default OfferInCartContainer;
