import { Box, CircularProgress, Paper, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@mui/material/styles';
import { LocationSelectorItem } from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/LocationSelector/components/LocationSelectorItem/types';
import LocationsYMap from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesCalculationWidget/LocationSelector/LocationsYMap';
import { BranchItem } from '@services/requests/routeCalculator/locationsBranchSearchService/interfaces';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LocationSelectorList from './LocationSelectorList';

interface Props {
  isFrom?: boolean;
  primaryLangId: string;
  defaultValue: string;
  secondaryLangId: string;
  items: LocationSelectorItem[];
  isLoading: boolean;
  selectedLocation: BranchItem | undefined;

  onSelectLocation(location: BranchItem, closeDialog: boolean): void;
}

/**
 * Компонент второго шага выбора локации
 * @param props
 * @constructor
 */
const CommonStep = (props: Props) => {
  const {
    items,
    isLoading,
    defaultValue,
    onSelectLocation,
    primaryLangId,
    secondaryLangId,
    selectedLocation,
  } = props;
  const { t } = useTranslation(['LocationSelector']);

  const itemsWithCoords = items.filter((item) => item.option?.coords);

  const [hoveredItem, setHoveredItem] = useState<BranchItem | null>(null);
  const [isListBlocked, setIsListBlocked] = useState(false);

  const mouseEnterIntoItemHandler = useCallback(
    (item: BranchItem): void => {
      setHoveredItem(item);
    },
    [items]
  );

  const mouseLeaveFromItemHandler = useCallback((): void => {
    setHoveredItem(null);
  }, [items]);

  const mouseEnterPointItemHandler = useCallback(
    (item: BranchItem) => {
      const foundItem = document.querySelector(`li[data-id="${item.id}"]`);
      const container = document.querySelector('.location-list');

      if (foundItem) {
        container.scrollTop = (foundItem as HTMLLIElement).offsetTop;
        foundItem.classList.add('--is-selected');
      }
    },
    [items]
  );

  const mouseLeaveFromPointItemHandler = useCallback((item: BranchItem) => {
    const foundItem = document.querySelector(`li[data-id="${item.id}"]`);

    if (foundItem) {
      foundItem.classList.remove('--is-selected');
    }
  }, []);

  const listBlockHandler = (state: boolean) => {
    setIsListBlocked(state);
  };

  const theme = useTheme();
  const showMap = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Paper elevation={4} className={'terminal-location-paper --second-step'}>
      <Box
        className={clsx('location-selector__list-wrapper', {
          'location-selector__list-wrapper--empty': !isLoading && items.length === 0,
          'location-selector__list-wrapper--blocked': isListBlocked,
        })}
      >
        {isLoading && (
          <span className={'loader'}>
            <CircularProgress size={60} />
          </span>
        )}
        {!isLoading && items.length === 0 && defaultValue === '' && (
          <Typography
            variant={'body1'}
            color={'textSecondary'}
            alignSelf={'center'}
            justifySelf={'center'}
            textAlign={'center'}
          >
            {t('LocationSelector:EnterSearchString')}
          </Typography>
        )}
        {!isLoading && items.length === 0 && defaultValue !== '' && (
          <Typography
            variant={'body1'}
            color={'textSecondary'}
            alignSelf={'center'}
            justifySelf={'center'}
            textAlign={'center'}
          >
            {t('LocationSelector:NoResults')}
          </Typography>
        )}
        {!isLoading && items.length > 0 && (
          <LocationSelectorList
            items={items}
            onSelect={onSelectLocation}
            primaryLangId={primaryLangId}
            secondaryLangId={secondaryLangId}
            value={selectedLocation}
            onMouseEnter={mouseEnterIntoItemHandler}
            onMouseLeave={mouseLeaveFromItemHandler}
          />
        )}
      </Box>
      {showMap && (
        <div className={'location-selector__list-map'}>
          <LocationsYMap
            items={itemsWithCoords}
            value={selectedLocation}
            onSelect={onSelectLocation}
            primaryLangId={primaryLangId}
            secondaryLangId={secondaryLangId}
            hoveredItem={hoveredItem}
            onMouseEnter={isListBlocked ? mouseEnterPointItemHandler : () => {}}
            onMouseLeave={isListBlocked ? mouseLeaveFromPointItemHandler : () => {}}
            onListBlockChange={listBlockHandler}
          />
        </div>
      )}
    </Paper>
  );
};

export default CommonStep;
