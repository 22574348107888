// ----------------------------------------------------------------------

export default function Container() {
  return {
    MuiContainer: {
      defaultProps: {
        container: document.getElementById('onlog-widget-container'),
      },
      styleOverrides: {
        root: {},
      },
    },
  };
}
