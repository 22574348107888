import {ServiceSummary} from "@pages/AdditionalServices/containers/methods/basket/getSummaryForServices";
import {RouteInGroup} from "@services/requests/orderService/types";
import {CurrencyData} from "@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery";

/**
 * getRouteSummary вычисляет итоги по маршруту. Используется для вывода итоговой
 * информации по маршруту.
 * @param routes
 * @param currencies
 * @param targetCurrency
 * @param includeTax
 */
const getRouteSummary = (
  routes: RouteInGroup[],
  currencies: CurrencyData[],
  targetCurrency: string,
  includeTax: boolean = false,
): ServiceSummary => {
  const result: ServiceSummary = {
    Currencies: {},
    Price: 0,
    Conversion: 0,
    Tax: 0,
    Total: 0,
    TargetCurrency: targetCurrency,
  }

  routes.map(route => {
    route.route.steps.map(step => {
      const conditions = [
        {cond: step.endTerminalPrice, val: step.endTerminalCalculatedPrice},
        {cond: step.startTerminalPrice, val: step.startTerminalCalculatedPrice},
        {cond: step.shoulderOfferPriceCondition, val: step.shoulderOfferCalculatedPrice},
        ...Array.from([
          ...(step.endTerminalAllowances ?? []),
          ...(step.startTerminalAllowances ?? []),
          ...(step.shoulderAllowances ?? []),
        ]).map(allowance => ({
          cond: allowance.allowanceOfferCondition,
          val: allowance.calculatedPrice
        }))
      ]

      conditions.map(({cond, val}) => {
        if (!cond || !val) {
          return
        }

        const sourceCurrency = currencies.find((c) => c.id === cond.currency_id)

        if (!!sourceCurrency) {
          if (!result.Currencies[sourceCurrency.code]) {
            result.Currencies[sourceCurrency.code] = 0
          }

          result.Currencies[sourceCurrency.code] += val.basePrice
          if (includeTax) {
            if (!result.Currencies[targetCurrency]) {
              result.Currencies[targetCurrency] = 0
            }

            result.Currencies[targetCurrency] += val.taxInTargetCurrency
          }

          result.Conversion += val.conversionFee
          result.Tax += val.taxInTargetCurrency
          result.Total += val.fullPriceInTargetCurrency
          result.Price += val.priceInTargetCurrency
        }
      })
    })

    if (!!route.route?.containerRentCalculatedPrice && !!route.route?.containerRent) {
      const sourceCurrency = currencies.find((c) => c.id === route.route?.containerRent.currency_id)

      if (!!sourceCurrency) {
        if (!result.Currencies[sourceCurrency.code]) {
          result.Currencies[sourceCurrency.code] = 0
        }

        result.Currencies[sourceCurrency.code] += route.route.containerRentCalculatedPrice.basePrice
        if (includeTax) {
          if (!result.Currencies[targetCurrency]) {
            result.Currencies[targetCurrency] = 0
          }

          result.Currencies[targetCurrency] += route.route.containerRentCalculatedPrice.taxInTargetCurrency
        }

        result.Conversion += route.route.containerRentCalculatedPrice.conversionFee
        result.Tax += route.route.containerRentCalculatedPrice.taxInTargetCurrency
        result.Total += route.route.containerRentCalculatedPrice.fullPriceInTargetCurrency
        result.Price += route.route.containerRentCalculatedPrice.priceInTargetCurrency
      }
    }

    if (!!route.route?.dropOffCalculatedPrice && !!route.route?.dropOff) {
      const sourceCurrency = currencies.find((c) => c.id === route.route?.dropOff.currency_id)

      if (!!sourceCurrency) {
        if (!result.Currencies[sourceCurrency.code]) {
          result.Currencies[sourceCurrency.code] = 0
        }

        result.Currencies[sourceCurrency.code] += route.route.dropOffCalculatedPrice.basePrice
        if (includeTax) {
          if (!result.Currencies[targetCurrency]) {
            result.Currencies[targetCurrency] = 0
          }

          result.Currencies[targetCurrency] += route.route.dropOffCalculatedPrice.taxInTargetCurrency
        }

        result.Conversion += route.route.dropOffCalculatedPrice.conversionFee
        result.Tax += route.route.dropOffCalculatedPrice.taxInTargetCurrency
        result.Total += route.route.dropOffCalculatedPrice.fullPriceInTargetCurrency
        result.Price += route.route.dropOffCalculatedPrice.priceInTargetCurrency
      }
    }

    if (!!route.route?.pickOnCalculatedPrice && !!route.route?.pickOn) {
      const sourceCurrency = currencies.find((c) => c.id === route.route?.pickOn.currency_id)

      if (!!sourceCurrency) {
        if (!result.Currencies[sourceCurrency.code]) {
          result.Currencies[sourceCurrency.code] = 0
        }

        result.Currencies[sourceCurrency.code] += route.route.pickOnCalculatedPrice.basePrice
        if (includeTax) {
          if (!result.Currencies[targetCurrency]) {
            result.Currencies[targetCurrency] = 0
          }

          result.Currencies[targetCurrency] += route.route.pickOnCalculatedPrice.taxInTargetCurrency
        }

        result.Conversion += route.route.pickOnCalculatedPrice.conversionFee
        result.Tax += route.route.pickOnCalculatedPrice.taxInTargetCurrency
        result.Total += route.route.pickOnCalculatedPrice.fullPriceInTargetCurrency
        result.Price += route.route.pickOnCalculatedPrice.priceInTargetCurrency
      }
    }
  })

  return result
}

export default getRouteSummary