import { Theme } from '@material-ui/core/styles';
import { Popper } from '@mui/material';
import React from 'react';
// ----------------------------------------------------------------------

export default function Autocomplete(theme: Theme) {
  return {
    MuiAutocomplete: {
      defaultProps: {
        container: document.getElementById('onlog-widget-container'),
        PopperComponent: function CustomPopper(props) {
          // Используем свой контейнер для портала
          return (
            <Popper {...props} container={document.getElementById('onlog-widget-container')} />
          );
        },
      },
      styleOverrides: {
        root: {
          fontFamily: 'Manrope, sans-serif',
        },
        paper: {
          boxShadow: theme.customShadows.z20,
          borderRadius: '9px',
          lineHeight: 1.4,
          fontWeight: 600,
          fontSize: 14,
          padding: '2px 10px',
        },
        option: {
          borderRadius: '4px',
          padding: 1.25,
          '&.MuiAutocomplete-option.Mui-focused:hover': {
            backgroundColor: '#EAEFF9',
          },
        },
        hasPopupIcon: {
          '.MuiAutocomplete-inputRoot': {
            padding: '9px 20px 12px 15px',
            paddingRight: 59,
            '.MuiAutocomplete-endAdornment': {
              right: 14,
            },
            color: theme.palette.common.black,
            fontStyle: 'normal',
            lineHeight: '22px',
            fontWeight: 600,
            fontSize: 14,
          },
        },
        popupIndicator: {
          '.MuiSvgIcon-root': {
            fontSize: 24,
          },
        },
      },
    },
  };
}
