import {
  BaseServiceInitialStateGenerator
} from "@pages/AdditionalServices/containers/services/serviceInitialStateGenerator/baseServiceInitialStateGenerator";
import {
  VerticalTableTemplateProcessor
} from "@pages/AdditionalServices/containers/services/serviceInitialStateGenerator/verticalTableTemplateProcessor";

// Фабрика сервиса
const serviceInitialStateGenerator = () => new BaseServiceInitialStateGenerator([
  new VerticalTableTemplateProcessor,
])

export default serviceInitialStateGenerator