import MCheckbox from '@assets/mui/@material-extend/MCheckbox';
import CheckboxGrid from '@components/UI/CheckboxGrid';
import { FormControlLabel } from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
import { getTerminalsToDisplay } from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesListComponent/DeliveryFilterContainer/Filter/helpers';
import TerminalsGroupFilterStyledContainer from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesListComponent/DeliveryFilterContainer/TerminalsFilter/StyledComponents';
import { TerminalsGroupFilterType } from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesListComponent/DeliveryFilterContainer/TerminalsGroupFilter/types';
import { GetLocalizedNamesForLocationsOrTerminals } from '@settings/services/helpers/GetLocalizedNamesForLocationsOrTerminals';
import React, { FC, useState } from 'react';

const TerminalsGroupFilter: FC<TerminalsGroupFilterType> = ({
  handleChangeFilterValue,
  terminals,
  parent,
  values,
  systemLanguage,
  mainLanguage,
  filterKey,
}) => {
  const [checked, setChecked] = useState(true);

  const { primaryNames } = GetLocalizedNamesForLocationsOrTerminals(
    parent,
    systemLanguage,
    mainLanguage,
  );
  const locationName = primaryNames.join(', ');
  const terminalsToShow = terminals;
  const terminalsToDisplay = getTerminalsToDisplay({
    terminals: terminalsToShow,
    systemLanguage,
    mainLanguage,
  });
  const terminalsChildren = terminalsToShow.map((i) => +i.id);
  const onChange = () => {
    setChecked(!checked);
    const removeAll = values.filter((v) => !terminalsChildren.includes(v));
    const addAll = [...removeAll, ...terminalsChildren];
    handleChangeFilterValue(filterKey, checked ? removeAll : addAll);
  };

  return (
    <TerminalsGroupFilterStyledContainer>
      <FormControlLabel
        control={
          <MCheckbox
            size="small"
            checked={checked}
            onChange={onChange}
            className={'location-checkbox'}
          />
        }
        label={
          <Tooltip title={locationName}>
            <span>{locationName}</span>
          </Tooltip>
        }
        className={'location-filter'}
      />
      <div className={'checkbox-grid'}>
        <CheckboxGrid
          items={terminalsToDisplay}
          values={values}
          isHiddenAvailable
          hiddenCheckboxQuantity={3}
          onChange={(values) => handleChangeFilterValue(filterKey, values)}
        />
      </div>
    </TerminalsGroupFilterStyledContainer>
  );
};

export default TerminalsGroupFilter;
