import { ClickAwayListener } from '@material-ui/core';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import ruLocale from 'date-fns/locale/ru';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Пропсы компонента
export type CargoDateProps = {
  onChangeDate: (date: Date | undefined) => void;
  currentDate: Date | undefined;
};

/**
 * Компонент выбора даты расчета пути
 * @param props
 */
const CargoDate = (props: CargoDateProps) => {
  const { currentDate, onChangeDate } = props;
  const { t } = useTranslation('Common');
  const [open, setOpen] = useState<boolean>(false);

  return (
    <ClickAwayListener onClickAway={() => open && setOpen(false)}>
      <div onClick={() => !open && setOpen(true)}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
          <DesktopDatePicker
            label={t('Common:Date')}
            value={currentDate}
            open={open}
            sx={{ width: '100%' }}
            onChange={(newValue) => {
              onChangeDate(newValue || undefined);
              setOpen(false);
            }}
            format="dd.MM.yyyy"
            minDate={new Date()}
            dayOfWeekFormatter={(day) => day}
            className="date-picker"
            slotProps={{
              popper: { disablePortal: true },
              inputAdornment: { position: 'start' },
              openPickerButton: {
                color: 'secondary',
                size: 'small',
                className: 'date-picker__button',
              },
            }}
          />
        </LocalizationProvider>
      </div>
    </ClickAwayListener>
  );
};

export default CargoDate;
