import { styled } from '@mui/material';
import Box from '@mui/material/Box';

const Container = styled(Box)`
  .root-services-tab {
    &__item {
      padding: 13px 0;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
    }
  }
`;

export default Container;
