import { ServiceField } from '@onlog-public/additional-services-types';
import { sortFields } from '@pages/AdditionalServices/components/FieldDisplay/AdditionServiceFields/AdditionServiceFieldsRow';
import { SetUpServiceData } from '@pages/AdditionalServices/containers/methods/basket/types';

/**
 * getDirectoryFieldsFromData извлекает поля родительских директорий для
 * переданной услуги. По сути возвращает все поля, что выводились в
 * виджете, а так же все поля выбранного варианта. Результат отсортирован
 * по номеру строк вывода и по позиции поля в строке.
 *
 * @param setUpService
 */
const getDirectoryFieldsFromData = (setUpService: SetUpServiceData): ServiceField[] => {
  const rows: { [T in number]: ServiceField[] } = {};

  Array.from([...Object.values(setUpService.ServicesPath), setUpService.Variant])
    .map((d) => d.fields)
    .flat(1)
    .filter((d, i, data) => data.map((d) => d.id).indexOf(d.id) === i)
    .forEach((field) => {
      const isRowExist = !!rows[field.settings.DefaultSettings.RowNumber];

      isRowExist
        ? rows[field.settings.DefaultSettings.RowNumber].push(field)
        : (rows[field.settings.DefaultSettings.RowNumber] = [field]);
    });

  return Object.values(rows)
    .map((fields) => fields.slice().sort(sortFields({})))
    .flat();
};

export default getDirectoryFieldsFromData;
