import {serviceContext$, serviceLoadingBus$} from "@pages/AdditionalServices/containers/bus";
import {clone} from "@pages/AdditionalServices/containers/methods/clone";

/**
 * Метод посылает в шину сигнал о том, что необходимо загрузить дополнительные
 * услуги для выбранных директорий. Метод устанавливает флаг расчета стоимости
 * дополнительных услуг.
 */
export const onCalculateServices = (state: boolean = true) => {
  serviceLoadingBus$.next(state)
  serviceContext$.next({
    ...clone(serviceContext$.getValue()),
    IsNeedCalculateServices: true,
    IsServicesLoading: true,
  })
}