import React, {FC} from "react";
import {ServiceItem} from "@components/pages/Products/components/ServiceSectionDetail/types";
import restoreServiceData from "@pages/AdditionalServices/containers/methods/basket/restoreServiceData";
import getServicePathFromData from "@pages/AdditionalServices/containers/methods/basket/getServicePathFromData";
import getLocalizationByArgs from "@helpers/getLocalizationByArgs";
import getDirectoryFieldsFromData from "@pages/AdditionalServices/containers/methods/basket/getDirectoryFieldsFromData";
import getFieldValuesFromData from "@pages/AdditionalServices/containers/methods/basket/getFieldValuesFromData";
import {CurrencyData} from "@services/requests/searchLoaders/currencyLoader/CurrencyLoaderQuery";
import {TaxData} from "@services/requests/taxLoader/TaxLoaderQuery";
import {round} from "lodash";
import {useTranslation} from "react-i18next";

const SingleServiceItem: FC<ServiceItem> = props => {
  const {
    product,
    langID,
  } = props
  const {t} = useTranslation('OfferDetail')

  const restoreResult = restoreServiceData(product)
  if (!restoreResult) {
    return null
  }

  const {setUpService} = restoreResult

  const path = getServicePathFromData(langID, setUpService)
  const name = getLocalizationByArgs(langID, setUpService.ServiceData.name, setUpService.ServiceData.localized_names)
  const fullName = [path, name].filter(s => s.length > 0).join(" / ")

  const {fields, values: fieldValues} = getFieldValuesFromData(langID, [
    ...setUpService.ServiceData.fields,
    ...getDirectoryFieldsFromData(setUpService),
  ], setUpService)

  const targetCurrency: CurrencyData = setUpService.ServiceDataCache.currency.cache[String(setUpService.service.currency_id ?? "")]
  const sourceCurrency: CurrencyData = setUpService.ServiceDataCache.currency.cache[String(setUpService.service.price_offer_result?.data?.currency_id ?? "")]
  const tax: TaxData = setUpService.ServiceDataCache.tax.cache[String(setUpService.service.price_offer_result?.data?.tax_id ?? "")]
  const isOnRequest = setUpService.service.price_offer_result.result.fullPriceInTargetCurrency <= 0

  // Если запрос был валиден, то должны быть обе валюты для вывода
  if (!isOnRequest) {
    if (!targetCurrency || !sourceCurrency) {
      return null
    }
  }

  let course = "-"
  if (!!targetCurrency && !!sourceCurrency) {
    const convertedCourse = sourceCurrency.course / sourceCurrency.nominal * targetCurrency.nominal / targetCurrency.course
    course = round(convertedCourse, 2).toLocaleString()
  }

  return (
    <div className="a-service-basket-single-service">
      <div className="caption">
        <div>
          <div>{fullName}</div>
          {fields.length > 0 && (
            <div className="fields">
              {fields.map(field => {
                let displayFieldName = getLocalizationByArgs(langID, field.name, field.localized_names)
                if (field.type !== "switch") {
                  if (
                    field.settings.DefaultSettings.IsNeedHideName ||
                    displayFieldName.length === 0
                  ) {
                    displayFieldName = getLocalizationByArgs(langID, field.placeholder, field.localized_placeholders)
                  }
                }

                return (
                  <div key={field.code}>
                    <div className="field-name">{displayFieldName}:</div>
                    <div>{fieldValues[field.code]}</div>
                  </div>
                )
              })}
            </div>
          )}
        </div>
        {isOnRequest && (
          <div>{t('OfferDetail:services.simpleService.byRequest')}</div>
        )}
        {!isOnRequest && (
          <div>{product.price.toLocaleString()} {targetCurrency.code}</div>
        )}
      </div>
      <div className="table-data">
        <div className="no-left-border table-data-header"></div>
        <div className="table-data-header">{t('OfferDetail:services.simpleService.cost')}</div>
        <div className="table-data-header">{t('OfferDetail:services.simpleService.course')}</div>
        <div className="table-data-header">{t('OfferDetail:services.simpleService.conversion')}</div>
        <div className="table-data-header">{t('OfferDetail:services.simpleService.price')}</div>
        <div className="table-data-header tax">{t('OfferDetail:services.simpleService.tax')}</div>
        <div className="table-data-header">{t('OfferDetail:services.simpleService.total')}</div>

        {isOnRequest && <>
            <div className="no-left-border service-name">{name}</div>
            <div className="by-request">{t('OfferDetail:services.simpleService.byRequest')}</div>
        </>}

        {!isOnRequest && <>
            <div className="no-left-border service-name">{name}</div>
            <div>{setUpService.service.price_offer_result.result.basePrice.toLocaleString()} {sourceCurrency.code}</div>
            <div>{course}</div>
            <div>{setUpService.service.price_offer_result.result.conversionFee.toLocaleString()} {targetCurrency.code}</div>
            <div>{setUpService.service.price_offer_result.result.priceInTargetCurrency.toLocaleString()} {targetCurrency.code}</div>
            <div>{tax?.amount ?? 0}%</div>
            <div>{setUpService.service.price_offer_result.result.taxInTargetCurrency.toLocaleString()} {targetCurrency.code}</div>
            <div>{setUpService.service.price_offer_result.result.fullPriceInTargetCurrency.toLocaleString()} {targetCurrency.code}</div>
        </>}
      </div>
    </div>
  )
}

export default SingleServiceItem