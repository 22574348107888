import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const ContainerTypeIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 17 16" fill="none">
    <path
      d="M16.3682 2H1.36816C1.09216 2 0.868164 2.30106 0.868164 2.672V12.528C0.868164 12.8989 1.09216 13.2 1.36816 13.2H16.3682C16.6442 13.2 16.8682 12.8989 16.8682 12.528V2.672C16.8682 2.30106 16.6442 2 16.3682 2ZM3.7015 10.288C3.7015 10.6589 3.4775 10.96 3.2015 10.96C2.9255 10.96 2.7015 10.6589 2.7015 10.288V4.912C2.7015 4.54106 2.9255 4.24 3.2015 4.24C3.4775 4.24 3.7015 4.54106 3.7015 4.912V10.288ZM6.36816 10.288C6.36816 10.6589 6.14416 10.96 5.86816 10.96C5.59216 10.96 5.36816 10.6589 5.36816 10.288V4.912C5.36816 4.54106 5.59216 4.24 5.86816 4.24C6.14416 4.24 6.36816 4.54106 6.36816 4.912V10.288ZM9.36816 10.288C9.36816 10.6589 9.14416 10.96 8.86816 10.96C8.59216 10.96 8.36816 10.6589 8.36816 10.288V4.912C8.36816 4.54106 8.59216 4.24 8.86816 4.24C9.14416 4.24 9.36816 4.54106 9.36816 4.912V10.288ZM12.3682 10.288C12.3682 10.6589 12.1442 10.96 11.8682 10.96C11.5922 10.96 11.3682 10.6589 11.3682 10.288V4.912C11.3682 4.54106 11.5922 4.24 11.8682 4.24C12.1442 4.24 12.3682 4.54106 12.3682 4.912V10.288ZM15.0348 10.288C15.0348 10.6589 14.8108 10.96 14.5348 10.96C14.2588 10.96 14.0348 10.6589 14.0348 10.288V4.912C14.0348 4.54106 14.2588 4.24 14.5348 4.24C14.8108 4.24 15.0348 4.54106 15.0348 4.912V10.288Z"
      fill={props.htmlColor}
    />
  </SvgIcon>
);

export default ContainerTypeIcon;
