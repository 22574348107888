import { styled } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { getFormattedPrice } from '@pages/AdditionalServices/tabs/CustomsPayments/helpers/price';
import React from 'react';

const CostCellContainer = styled('div')`
  width: 100%;
  overflow: hidden;
  text-align: center;
`;

const RubbleCostCell = (props: GridRenderCellParams<any, any, any>) => {
  const { row } = props;

  return (
    <CostCellContainer>
      {getFormattedPrice(+(+row.rublesAmount).toFixed(2), 2)} RUB
    </CostCellContainer>
  );
};

export { RubbleCostCell };
