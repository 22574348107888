import {Service} from "@onlog-public/additional-services-types";

/**
 * getServicePriority возвращает приоритет услуги на основе настроек
 * @param s
 */
const getServicePriority = (s: Service) => {
  switch (s.template) {
    case "cargoDeclaration":
      return s.settings.TemplateSettings.CargoDeclaration.PositionInList
    case "serviceInCargoDeclaration":
      return s.settings.TemplateSettings.ServiceInCargoDeclaration.PositionInList
    case "additionList":
      return s.settings.TemplateSettings.AdditionList.PositionInList
    case "serviceInAdditionList":
      return s.settings.TemplateSettings.ServiceInAdditionList.PositionInList
  }

  return 9999
}

/**
 * serviceSortFunc реализует функцию сортировки отображаемых услуг
 * @param a
 * @param b
 */
const serviceSortFunc = (
  a: Service,
  b: Service,
) => {
  const aPos = getServicePriority(a)
  const bPos = getServicePriority(b)

  if (aPos === bPos) {
    return parseInt(a.id) > parseInt(b.id) ? 1 : -1
  }

  return aPos > bPos ? 1 : -1
}

export default serviceSortFunc