import Actions from './cn/Actions.json';
import AdditionalService from './cn/AdditionalService.json';
import AppSettings from './cn/AppSettings.json';
import BenefitsOfWorking from './cn/BenefitsOfWorking.json';
import BenefitsOfWorkingProfile from './cn/BenefitsOfWorkingProfile.json';
import CartAsideBlock from './cn/CartAsideBlock.json';
import CartComponent from './cn/CartComponent.json';
import CartSummary from './cn/CartSummary.json';
import Common from './cn/Common.json';
import CustomsCalculator from './cn/CustomsCalculator.json';
import CustomsPayments from './cn/CustomsPayments.json';
import DeliveryForm from './cn/DeliveryForm.json';
import DeliveryItem from './cn/DeliveryItem.json';
import EmptyStyleSelect from './cn/EmptyStyleSelect.json';
import FilterComponent from './cn/FilterComponent.json';
import FooterBottomBar from './cn/FooterBottomBar.json';
import LocationSelector from './cn/LocationSelector.json';
import OfferContainers from './cn/OfferContainers.json';
import OfferDetail from './cn/OfferDetail.json';
import OfferTipsText from './cn/OfferTipsText.json';
import OrderForm from './cn/OrderForm.json';
import OrderList from './cn/OrderList.json';
import PageNames from './cn/PageNames.json';
import RoutesCalculationWidget from './cn/RoutesCalculationWidget.json';
import RoutesListComponent from './cn/RoutesListComponent.json';
import SortComponent from './cn/SortComponent.json';
import TubsContainer from './cn/TubsContainer.json';
import TYOrder from './cn/TYOrder.json';

export const cn = {
  Actions,
  AdditionalService,
  AppSettings,
  BenefitsOfWorking,
  BenefitsOfWorkingProfile,
  CartAsideBlock,
  CartComponent,
  CartSummary,
  Common,
  CustomsCalculator,
  CustomsPayments,
  DeliveryForm,
  DeliveryItem,
  EmptyStyleSelect,
  FilterComponent,
  FooterBottomBar,
  LocationSelector,
  OfferContainers,
  OfferDetail,
  OfferTipsText,
  OrderForm,
  OrderList,
  PageNames,
  RoutesCalculationWidget,
  RoutesListComponent,
  SortComponent,
  TubsContainer,
  TYOrder,
};
